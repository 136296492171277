import { FormField, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

import { filterDeactivatedDeletedOffers } from './util/ArtPatchUtil'

const AddOfferArtPatch = ({
  addOfferArtPatch,
  setAddOfferArtPatch,
  putOffer,
}) => {
  // Exclude placeholder offers from art patch selection.
  let offers = filterDeactivatedDeletedOffers(addOfferArtPatch?.offerElements)

  const wipeState = () => {
    setAddOfferArtPatch({
      body: {},
      show: false,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        artPatchSelected: [],
        baseOfferSelected: null,
      }}
      onSubmit={(values, funcs) => {
        const artPatches = offers
          .filter((offer) => values.artPatchSelected.includes(offer.id))
          .map((follower) => ({
            id: follower.id,
            ivy_offer_id: follower.ivy_offer_id,
            ivy_umbrella_id: follower.ivy_umbrella_id,
            offer_art_patch_leader_id: values.baseOfferSelected,
            offer_order: follower.offer_order,
            project_id: follower.project_id,
            umbrella_id: follower.umbrella_id,
          }))

        putOffer(artPatches)
        funcs.resetForm()
        wipeState()
      }}
    >
      {({ values, handleReset, handleSubmit, setFieldValue }) => {
        const handleDialogClose = () => {
          wipeState()
          handleReset()
        }
        return (
          <Modal
            isVisible={addOfferArtPatch.show}
            onRefuse={wipeState}
            headingText="Link Art Patch"
          >
            <div className="hc-pa-normal">
              <Grid.Container justify="center" direction="row">
                <Grid.Item xs={6}>
                  <FormField
                    required
                    id="baseOfferSelected"
                    label="Choose Base Offer First:"
                    type="radio"
                    value={values.baseOfferSelected}
                    options={offers
                      .filter((offer) => !offer.artPatch)
                      .map((offer) => ({
                        label: offer.name,
                        name: offer.name,
                        value: offer.id,
                      }))}
                    onUpdate={(_, baseOfferOption) => {
                      setFieldValue('artPatchSelected', [])
                      setFieldValue('baseOfferSelected', baseOfferOption)
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <FormField
                    id="artPatchSelected"
                    label="Choose Art Patches(s):"
                    type="checkboxes"
                    value={values.artPatchSelected}
                    options={offers
                      .filter((offer) => !offer.artPatch)
                      .map((offer) => ({
                        disabled: offer.id === values.baseOfferSelected,
                        label: offer.name,
                        name: offer.name,
                        value: offer.id,
                      }))}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                submitDisabled={
                  isEmpty(values.artPatchSelected) ||
                  values.baseOfferSelected === undefined
                }
                handleSubmit={handleSubmit}
                handleDialogClose={handleDialogClose}
                canDelete={false}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  )
}
export default AddOfferArtPatch
