import divisionMapper from './divisionMapper'
import storyMapper from './storyMapper'

const pageMapper = (page) => ({
  artDirector: page.artDirector,
  bookOneOldPageNumber: page.bookOneOldPageNumber,
  bookOnePageNumber: page.bookOnePageNumber,
  bookOnePageType: page.bookOnePageType,
  bookTwoOldPageNumber: page.bookTwoOldPageNumber,
  bookTwoPageNumber: page.bookTwoPageNumber,
  bookTwoPageType: page.bookTwoPageType,
  businessObjectives: page.businessObjectives,
  copyWriter: page.copyWriter,
  deletable: page.deletable,
  deleted: page.deleted,
  divisions: page.divisions
    ? page.divisions.map(divisionMapper).sort((a, b) => a.id - b.id)
    : [],
  id: page.id,
  lastUpdatedTimestamp: page.lastUpdatedTimestamp,
  lockedOut: page.lockedOut,
  lockoutDate: page.lockoutDate,
  messaging: page.messaging,
  pageOrdering: page.pageOrdering,
  postMprCopyWriter: page.postMprCopyWriter || [],
  stories: page.stories
    ? page.stories
        .map(storyMapper)
        .filter((story) => story.deleted === false)
        .sort((a, b) => a.priority - b.priority)
    : [],
})
export default pageMapper
