import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MarketSelect from '../components/MarketSelect'
import { marketsToString } from '../util/helpers'

const ContentDetailMarkets = ({
  value,
  setMarketsSelected,
  marketOptions,
  disabled,
  setChangeDetected,
  setEditMarkets,
  editMarkets,
  mediaUnitId,
}) => (
  <Grid.Container
    className="category-container-row"
    data-testid="media-unit-markets"
  >
    <Grid.Item className="category-label" xs={2}>
      Markets{' '}
      <span
        onClick={() => {
          setEditMarkets(!editMarkets)
        }}
        data-testid="edit-media-unit-markets-btn"
        className="media-unit-market-edit"
      >
        <FontAwesomeIcon
          icon={faEdit}
          color={editMarkets ? '#cc0000' : '#000000'}
        />
      </span>
    </Grid.Item>

    <Grid.Item className="category-input" xs={10}>
      {editMarkets ? (
        <MarketSelect
          marketsSelected={value}
          setMarketsSelected={setMarketsSelected}
          marketOptions={marketOptions}
          disabled={disabled}
          setChangeDetected={setChangeDetected}
        />
      ) : (
        <Form.Field
          id={mediaUnitId + '-markets'}
          value={marketsToString(value)}
          disabled={true}
        />
      )}
    </Grid.Item>
  </Grid.Container>
)

export default ContentDetailMarkets
