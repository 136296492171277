import axios from 'axios'
import { useEffect, useState } from 'react'

import appConfig from '../config/appConfig'

const usePutDWAOffer = (setLoading, setDWA) => {
  const config = appConfig()
  const [request, setRequest] = useState(null)
  const url = config.backend.root + config.backend.dwa + config.backend.dwaOffer

  useEffect(() => {
    let mounted = true
    const getCategory = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.put(url, request.body)
        setDWA((oldDWA) => {
          const oldOffers = oldDWA[request?.categoryId].offers
          oldOffers.forEach((oldOffer, oldOfferIdx) => {
            const hit = response?.data?.find(
              (newOffer) => oldOffer.id === newOffer.id
            )
            if (hit) {
              oldOffers[oldOfferIdx] = hit
            }
          })

          return [
            ...oldDWA.slice(0, request.categoryId),
            {
              ...oldDWA[request?.categoryId],
              offers: oldOffers.sort((a, b) => a.priority - b.priority),
            },
            ...oldDWA.slice(request.categoryId + 1, 19),
          ]
        })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
    }
    if (request !== null) {
      getCategory()
    }

    return () => {}
  }, [setLoading, url, request, setRequest, setDWA])

  return [setRequest]
}
export default usePutDWAOffer
