import isEmpty from 'lodash.isempty'

export const PROJECT_ITEMS_PAGE_SIZE = 50

export function setFilterFromQuery(query) {
  const filterQueryOptions = []
  const filters = ['pyramids', 'imageCollected', 'pyramidName']
  filters.forEach((filterName) => {
    if (!isEmpty(query.getAll(filterName))) {
      const elements = query.getAll(filterName)[0].split(',')
      elements.forEach((elem) => {
        const option = filterOptions.find(
          ({ filter, value }) => filter === filterName && value === elem
        )
        option && filterQueryOptions.push(option)
      })
    }
  })
  return filterQueryOptions
}

export const fetchDivisionFromQuery = (
  query,
  divisionFilterOptions,
  filters
) => {
  const filterQueryOptions = []
  filters.forEach((filterName) => {
    if (!isEmpty(query.getAll(filterName))) {
      const elements = query.getAll(filterName)[0].split(',')
      elements.forEach((elem) => {
        const option = divisionFilterOptions.find(
          ({ filter, value }) =>
            filter === filterName && value === parseInt(elem)
        )
        option && filterQueryOptions.push(option)
      })
    }
  })
  return filterQueryOptions
}

export const makeProjectItemsPayload = ({
  channelIds,
  projectId,
  dpci,
  division,
  imageCollected,
  pyramidName,
  pyramidType,
  pageNum = 0,
  sortBy,
  sortOrder = 'ASC',
}) => {
  let payload = {
    page_num: pageNum,
    per_page: PROJECT_ITEMS_PAGE_SIZE,
    project_id: projectId,
    sort_order: sortOrder,
  }

  if (dpci) {
    payload.dpci = dpci
  }

  if (imageCollected || imageCollected === '') {
    payload.image_collected = imageCollected?.split(',')
  }

  if (pyramidType) {
    payload.pyramid_type = pyramidType?.split(',')
  }

  if (division) {
    payload.division = division?.split(',')
  }

  if (pyramidName) {
    payload.pyramid_name = pyramidName
  }

  if (sortBy) {
    payload.sort_by = sortBy
  }

  if (channelIds !== undefined && channelIds.length > 0) {
    payload.channel_ids = channelIds
  }

  return payload
}

export const isLastPage = (pageNumber = 0, totalItems = []) => {
  const totalPages =
    totalItems < PROJECT_ITEMS_PAGE_SIZE
      ? 1
      : Math.ceil(totalItems / PROJECT_ITEMS_PAGE_SIZE)
  return pageNumber + 1 >= totalPages
}

export const getPageNumber = (changePage, pageNumber = 0) => {
  if (changePage && changePage > 0) {
    return pageNumber + 1
  } else if (pageNumber > 0 && changePage && changePage < 0) {
    return pageNumber - 1
  }
  return 0
}

export const formatChannels = (channels = []) => {
  let formattedChannels = channels?.map((channel = {}) => ({
    id: channel.channel_id ? channel.channel_id : channel.id,
    label: channel.channel_name ? channel.channel_name : channel.label,
    value: channel.channel_name ? channel.channel_name : channel.value,
  }))

  return formattedChannels
}

export const formatDivisionOptions = (divisions = []) => {
  let divisionOptions = divisions?.map((division = {}, index) => ({
    filter: 'division',
    id: 'divisionFilter' + index,
    label: division.division_name,
    value: division.division_id,
  }))

  return divisionOptions
}

export const formatPyramidNameOptions = (pyramidNames = []) => {
  let pyramidOptions = pyramidNames?.map((pyramidName = '') => ({
    disabled: false,
    id: 'PyramidName',
    label: pyramidName,
    pyramidName: 'pyramidName',
    value: pyramidName,
  }))

  pyramidOptions.unshift({
    disabled: false,
    id: 'PyramidName',
    label: 'Select Priority',
    pyramidName: 'pyramidName',
    value: '',
  })

  return pyramidOptions
}

export const filterOptions = [
  {
    filter: 'imageCollected',
    id: 'imageCollectedNo',
    label: 'ImageCollected: No',
    value: 'No',
  },
  {
    filter: 'imageCollected',
    id: 'imageCollectedYes',
    label: 'ImageCollected: Yes',
    value: 'Yes',
  },
  {
    filter: 'imageCollected',
    id: 'imageCollected2',
    label: 'ImageCollected: Empty',
    value: '',
  },
  {
    filter: 'pyramids',
    id: 'pyramid1',
    label: 'Pyramid: Apparel/Access',
    value: 'a&a',
  },
  {
    filter: 'pyramids',
    id: 'pyramid2',
    label: 'Pyramid: Beauty',
    value: 'bty',
  },
  {
    filter: 'pyramids',
    id: 'pyramid3',
    label: 'Pyramid: Essentials',
    value: 'ess',
  },
  {
    filter: 'pyramids',
    id: 'pyramid4',
    label: 'Pyramid: Food & Beverages',
    value: 'f&b',
  },
  {
    filter: 'pyramids',
    id: 'pyramid5',
    label: 'Pyramid: Hardlines',
    value: 'hrdl',
  },

  {
    filter: 'pyramids',
    id: 'pyramid6',
    label: 'Pyramid: Home',
    value: 'home',
  },
]
