const commentMapper = (comment) => ({
  commentText: comment.commentText,
  createdBy: comment.createdBy,
  createdTimestamp: comment.createdTimestamp,
  cutId: comment.cutId,
  deleted: comment.deleted,
  id: comment.id,
  lastUpdatedTimestamp: comment.lastUpdatedTimestamp,
  parentCommentId: comment.parentCommentId,
})
export default commentMapper
