import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import propItemMapper from '../mappers/propItemMapper'

const usePostPropItem = (setLoading, setWeeklyAd) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.props)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const postPropItem = async () => {
      mounted && setLoading(true)
      try {
        let response = await axios.post(url.current, request.body)
        let thisPropItem = propItemMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            w.pages
              .find((page) => page.id === request.pageId)
              .stories.find((story) => story.id === request.storyId)
              .cuts.find((cut) => cut.id === request.cutId)
              .props.push(thisPropItem)
            return w
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      postPropItem()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd])

  return setRequest
}
export default usePostPropItem
