import { Button, Card } from '@enterprise-ui/canvas-ui-react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import '../scss/Alert.scss'

const Alert = (props) => {
  // visible controls alert visibility state as defined in Alert.scss
  const [visible, setVisible] = useState('alert--shown')
  const [faded, setFaded] = useState(false)

  // timer controls fade timer
  let _timer

  // Did mount
  useEffect(() => {
    setTimer() // eslint-disable-next-line
  }, [setTimer])

  // Will Unmount
  useEffect(
    () => () => {
      clearTimeout(_timer)
    },
    [_timer]
  )

  // monitor faded for close
  useEffect(() => {
    if (faded && props.onClose !== undefined) {
      props.onClose()
    }
  }, [faded, props])

  // Did Update
  const mounted = useRef()
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (!faded) {
        setTimer()
      }
    }
  })

  // Handle fade away
  // eslint-disable-next-line
  const setTimer = () => {
    // dont hide if fade set to none
    if (!props.fade) {
      return
    }

    // clear any existing timer
    if (_timer != null) {
      clearTimeout(_timer)
    }

    // hide after `delay` milliseconds
    _timer = setTimeout(() => {
      setVisible('alert--transition')
      _timer = null
      setFaded(true)
      // Wait 400 ms for transition animation to finish
      setTimeout(() => setVisible('alert--hidden'), 400)
    }, props.delay)
  }

  // Handles transitions and hiding on toggle button click
  const toggle = () => {
    setVisible('alert--transition')
    // Wait 400 ms for transition animation to finish
    setTimeout(() => setVisible('alert--hidden'), 400)
    setFaded(true)
  }

  return visible === 'alert--shown' || visible === 'alert--transition' ? (
    <div className="alert">
      <div className={visible}>
        <Card elevation="0" corners="none">
          <div className={'alert--theme-' + props.color}>
            <div className="hc-pa-dense">
              <div className="alert__element">
                <div className="alert__text" data-testid="alert-message">
                  {props.children}
                </div>
                <div
                  className={`${
                    props.toggle
                      ? 'alert__toggle--shown'
                      : 'alert__toggle--hidden'
                  }`}
                  onClick={toggle}
                >
                  <Button iconOnly aria-label="dismiss">
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  ) : null
}

Alert.propTypes = {
  color: PropTypes.string,
  delay: PropTypes.number,
  fade: PropTypes.bool,
  onClose: PropTypes.func,
  toggle: PropTypes.bool,
}

Alert.defaultProps = {
  color: 'primary',
  delay: 3000,
  fade: false,
  toggle: true,
}

export default Alert
