import cutPatchMapper from './cutPatchMapper'
import divisionMapper from './divisionMapper'
import dummyMapper from './dummyMapper'
import itemMapper from './itemMapper'
import marketMapper from './marketMapper'
import offerMapper from './offerMapper'
import propItemMapper from './propItemMapper'

const cutMapper = (cut) => ({
  artPatch: cut.artPatch,
  artPatchLeaderId: cut.artPatchLeaderId,
  coreDataLastUpdatedField: cut.coreDataLastUpdatedField,
  coreDataLastUpdatedTimestamp: cut.coreDataLastUpdatedTimestamp,
  cutHasInvalidOffer: cut.cutHasInvalidOffer,
  cutPatches: cut.cutPatches
    ? cut.cutPatches
        .map(cutPatchMapper)
        .filter((cutPatch) => cutPatch.deleted === false)
        .sort((a, b) => a.priority - b.priority)
    : [],
  deleted: cut.deleted,
  division: cut.divisionId ? divisionMapper({ id: cut.divisionId }) : null,
  divisionId: cut.divisionId,
  dummies: cut.dummies
    ? cut.dummies
        .map(dummyMapper)
        .filter((dummy) => dummy.deleted === false)
        .sort((a, b) => a.id - b.id)
    : [],
  id: cut.id,
  items: cut.items
    ? cut.items
        .map(itemMapper)
        .filter((item) => item.deleted === false)
        .sort((a, b) => a.priority - b.priority)
    : [],
  lastUpdatedTimestamp: cut.lastUpdatedTimestamp,
  linerOffers: cut.linerOffers
    ? cut.linerOffers.map(offerMapper).sort((a, b) => a.name - b.name)
    : [],
  markets: cut.markets
    ? cut.markets.map(marketMapper).sort((a, b) => a.priority - b.priority)
    : [],
  name: cut.name,
  offerLastLinkedTimestamp: cut.offerLastLinkedTimestamp,
  offers: cut.offers
    ? cut.offers.map(offerMapper).sort((a, b) => a.name - b.name)
    : [],
  priority: cut.priority,
  promoUnitRetailLastUpdatedTimestamp: cut.promoUnitRetailLastUpdatedTimestamp,
  props: cut.props
    ? cut.props
        .map(propItemMapper)
        .filter((prop) => prop.deleted === false)
        .sort((a, b) => a.id - b.id)
    : [],
  storyId: cut.storyId,
  valid: cut.valid,
})
export default cutMapper
