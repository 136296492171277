// pass in a string and extract an array of potential email addresses "@first.last"

export const extractEmailAddresses = (text) => {
  const emailCheckRegEx = /@[\w-]+\.[\w-]+/gm
  if (emailCheckRegEx.test(text)) {
    let sendToArray = [...text.match(emailCheckRegEx)]
    sendToArray = sendToArray.map((s) => s.slice(1))
    sendToArray = sendToArray.map((i) => i + '@target.com')
    return sendToArray
  } else return []
}
