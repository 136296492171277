import { sortBy } from 'lodash'

export const displayStoriesByChannel = (
  stories,
  displayedStories,
  selectedMarketingChannelFilter
) => {
  stories.forEach((story) => {
    story.umbrellas.forEach((umbrella) => {
      umbrella.channels.forEach((channel) => {
        selectedMarketingChannelFilter.forEach((channelFilter) => {
          if (
            channelFilter.label.includes(channel.channel_name) &&
            !displayedStories.includes(story)
          ) {
            displayedStories.push(story)
          }
        })
      })
    })
  })
}

export const sortStoriesByOrder = (stories = []) =>
  sortBy(stories, (a) => a.story_order) ?? []
