import { Grid, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect } from 'react'

import PyramidApi from '../../api/creative-hub/PyramidApi'

import InlineEditInput from './InlineEditInput'
import StoryBucket from './StoryBucket'
import '../scss/PyramidDetails.scss'

const PyramidDetails = (props) => {
  const {
    addChannelToUmbrella,
    removeChannelFromUmbrella,
    channelFilters,
    channels,
    setRefreshData,
    setRefreshPyramidData,
    pyramid = {},
    projectId = '',
    projectStartDate,
    projectTitle = '',
    pyramidType,
    setIsPyramidDraggable,
    expandedPyramids,
    expandAllPyramids,
    session,
    setUmbrellaIsDropped,
    setSelectedUmbrellas,
  } = props
  const [pyramidDetails, setPyramidDetails] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const pyramidApi = new PyramidApi()
    setLoading(true)
    pyramidApi
      .getPyramidDetails(pyramid.pyramid_id, projectId)
      .then((response) => {
        setPyramidDetails(response)
        response && setLoading(false)
      })
  }, [pyramid, projectId])

  return (
    <div>
      {loading &&
      (expandedPyramids?.includes(pyramid.pyramid_id) || expandAllPyramids) ? (
        <ProgressBar
          className="progress-bar"
          indeterminate
          data-testid="progressBar"
        />
      ) : (
        <>
          {(expandedPyramids?.includes(pyramid.pyramid_id) ||
            expandAllPyramids) && (
            <>
              <Grid.Container
                data-testid="pyramidDetails"
                xs={12}
                className="pyramidDetails"
              >
                {pyramidType === 'ent' ? (
                  <>
                    <Grid.Item xs={12}>
                      <h4>Guest Outcome</h4>
                      <InlineEditInput
                        data={pyramidDetails}
                        field="guest_outcomes"
                        value={pyramidDetails?.guest_outcomes || ''}
                        multiline={false}
                        placeholder="outcome details..."
                        setRefreshPyramidData={setRefreshPyramidData}
                      />
                    </Grid.Item>
                  </>
                ) : (
                  <></>
                )}
                <Grid.Item xs={12}>
                  <h4>Additional Planner Notes</h4>
                  <InlineEditInput
                    data={pyramidDetails}
                    field="planner_notes"
                    testCy="plannerNotes"
                    value={pyramidDetails?.planner_notes || ''}
                    multiline={false}
                    placeholder="planner notes"
                    setRefreshPyramidData={setRefreshPyramidData}
                  />
                </Grid.Item>
              </Grid.Container>
              <StoryBucket
                addChannelToUmbrella={addChannelToUmbrella}
                removeChannelFromUmbrella={removeChannelFromUmbrella}
                channels={channels}
                pyramid={pyramid}
                pyramidType={pyramidType}
                pyramidDetails={pyramidDetails}
                setPyramidDetails={setPyramidDetails}
                setRefreshData={setRefreshData}
                setIsPyramidDraggable={setIsPyramidDraggable}
                channelFilters={channelFilters}
                setUmbrellaIsDropped={setUmbrellaIsDropped}
                setSelectedUmbrellas={setSelectedUmbrellas}
                session={session}
                projectStartDate={projectStartDate}
                projectTitle={projectTitle}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default PyramidDetails
