import axios from 'axios'

import appConfig from '../../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../../creativeHubGlobal/util/AuthUtil'
import offerCommentMapper from '../../../mappers/offerCommentMapper'

export default class CommentApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_comment_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getComments = async (offerId) =>
    await this.init_comment_api()
      .get(`${this.config.markethub.url}comment?offerId=${offerId}`)
      .then((res) => res.data.map((comment) => offerCommentMapper(comment)))
      .catch((e) => console.log(e))

  postComment = async (commentData) =>
    await this.init_comment_api()
      .post(`${this.config.markethub.url}comment`, commentData)
      .then((res) => res.data.map((comment) => offerCommentMapper(comment)))
      .catch((e) => console.log(e))

  putComment = async (commentData) =>
    await this.init_comment_api()
      .put(`${this.config.markethub.url}comment`, commentData)
      .then((res) => res.data.map((comment) => offerCommentMapper(comment)))
      .catch((e) => console.log(e))

  deleteComment = async (commentId) =>
    await this.init_comment_api()
      .delete(`${this.config.markethub.url}comment/` + commentId)
      .catch((e) => console.log(e))
}
