const isSelectedOption = function (option, selectedOptions) {
  if (option && selectedOptions) {
    for (let selectedO of selectedOptions) {
      if (selectedO === option || option.id === selectedO.id) {
        return true
      }
    }
  }
  return false
}

const applyFilters = function (filters, options, searchText, selected) {
  const filterGroup = filters.map(() => [])
  options.forEach((option) => {
    if (!isSelectedOption(option, selected)) {
      // check each filter for a match
      for (let i = 0; i < filters.length; i++) {
        let filter = filters[i]
        const isValid = filter(searchText, option)
        // Matches this filter.
        // Add to to result group and break loop
        if (isValid) {
          filterGroup[i].push(option)
          break
        }
      }
    }
  })

  // Join filter groups
  const filterResults = filterGroup.reduce((all, group) => {
    all.push.apply(all, group)
    return all
  }, [])

  return filterResults
}

// this prevents duplicate values from being added
const checkCurrentValuesForCustomValue = (searchText, availableOptions) => {
  let containsMatchingValue = false
  availableOptions.map((option) => {
    if (option.label.toUpperCase() === searchText.toUpperCase()) {
      containsMatchingValue = true
    }

    return true
  })
  return containsMatchingValue
}

export default function createVisibleOptionsList(optionFilter) {
  const filters = Array.isArray(optionFilter) ? optionFilter : [optionFilter]

  return (searchText, availableOptions, usedOptions, allowCustomInputValue) => {
    let filterArray = filters
    const noSearchText =
      searchText === null || searchText === undefined || !searchText.length

    // if there's no search text, don't filter, just return options
    if (noSearchText) {
      filterArray = [() => true]
    }

    // get the currently visible options
    let visibleOptions = applyFilters(
      filterArray,
      availableOptions,
      searchText,
      usedOptions
    )

    // if custom input options are allowed, and there is search text entered
    if (allowCustomInputValue && !noSearchText) {
      // check to see if option value is already in the options list
      const doesOptionExist = checkCurrentValuesForCustomValue(
        searchText,
        availableOptions
      )
      if (!doesOptionExist) {
        // and add it to the visible options array
        visibleOptions.unshift({
          id: '@@' + searchText + '@@',
          isNew: true,
          label: searchText,
          value: searchText,
        })
      }
    }

    // return the visible options, with the custom input value if applicable
    return visibleOptions
  }
}
