import { ExpandableSection, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect, useRef } from 'react'

import { generateOfferCommentEmaillink } from '../../../creativeHubGlobal/util/OfferUtil'
import { User, Email } from '../../../util/AuthUtils'
import CommentApi from '../../api/projectExecution/CommentApi'
import '../../../scss/CutComment.scss'

import AddComment from './AddComment'
import CommentItem from './CommentItem'

const OfferComment = (props) => {
  const api = new CommentApi()
  const currentUserName = User()
  const currentUserEmail = Email()
  const {
    canEdit = false,
    canExpandOffer = false,
    offer,
    projectId,
    umbrella,
    outcomePriorityId,
    storyId,
  } = props
  const [expanded, setExpandState] = useState(true)
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState([])
  const [showAddComment, setShowAddComment] = useState(false)
  const [editCommentId, setEditCommentId] = useState(null)
  const commentFieldRef = useRef(offer.ivyOfferId)

  useEffect(() => {
    if (expanded) {
      getComments()
    }

    // scroll to the comment section when the user lands from the notification email link.
    if (canExpandOffer) {
      commentFieldRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, canExpandOffer])

  const onAddCommentClose = () => setShowAddComment(false)

  const onAddCommentOpen = (evt) => {
    evt.stopPropagation()
    setShowAddComment(true)
  }

  const onAddComment = (commentText, toEmailList) => {
    if (toEmailList.length > 0) toEmailList.push(currentUserEmail)
    api
      .postComment({
        comment_text: commentText,
        created_by: currentUserName,
        initiator_email_address: currentUserEmail,
        link: generateOfferCommentEmaillink(
          projectId,
          outcomePriorityId,
          storyId,
          umbrella.planning_umbrella_id,
          offer.id
        ),
        offer_comment: true,
        offer_id: offer.id,
        offer_name: offer.name,
        project_id: projectId,
        pyramid_id: outcomePriorityId,
        story_id: storyId,
        to_email_list: toEmailList,
        umbrella_id: umbrella.planning_umbrella_id,
        umbrella_name: umbrella.name,
      })
      .then((comments) => {
        setComments(comments)
      })
  }

  const getComments = () => {
    setLoading(true)
    api.getComments(offer.id).then((res) => {
      setComments(res)
      setLoading(false)
    })
  }

  const updateComment = (commentId, commentData) => {
    setLoading(true)
    api.putComment(commentData).then((updatedComments) => {
      setComments(updatedComments)
      setLoading(false)
    })
  }

  const deleteComment = (commentId) => {
    setLoading(true)
    try {
      api.deleteComment(commentId).then(() => {
        const newComments = comments.filter(
          (comment) => comment.id !== commentId
        )

        setComments(newComments)
        setLoading(false)
      })
    } catch (e) {
      console.log(e)
    }
  }

  const onExpand = () => {
    if (!expanded) {
      getComments()
    }
    setExpandState((val) => !val)
  }

  return (
    <>
      <ExpandableSection padding="none" expanded={expanded} onExpand={onExpand}>
        <Grid.Container align="center">
          <Grid.Item xs={10} className="hc-ws-pre-wrap" ref={commentFieldRef}>
            Comments {expanded ? `(${comments.length})` : ''}
          </Grid.Item>
          {canEdit ? (
            <Grid.Item xs={1}>
              <Button
                className="C-MenuButton"
                onClick={onAddCommentOpen}
                iconOnly
                aria-label="create comment"
              >
                <FontAwesomeIcon color="black" icon={faComment} />
              </Button>
            </Grid.Item>
          ) : null}
        </Grid.Container>
        <ExpandableSection.Content className="hc-mb-dense hc-pb-dense hc-ml-dense hc-pl-dense hc-mr-dense hc-pr-dense">
          <Grid.Container
            direction="column"
            align="flex-start"
            className="hc-ml-xs hc-mt-xs"
          >
            {loading
              ? 'Loading....'
              : comments.length > 0 &&
                comments.map((comment) => (
                  <CommentItem
                    key={comment.id}
                    currentUserName={currentUserName}
                    comment={comment}
                    offer={offer}
                    umbrella={umbrella}
                    outcomePriorityId={outcomePriorityId}
                    editCommentId={editCommentId}
                    setEditCommentId={setEditCommentId}
                    updateComment={updateComment}
                    deleteComment={deleteComment}
                    canEdit={canEdit}
                  />
                ))}
          </Grid.Container>
        </ExpandableSection.Content>
      </ExpandableSection>
      {showAddComment && (
        <AddComment
          handleDialogClose={onAddCommentClose}
          onAddComment={onAddComment}
        />
      )}
    </>
  )
}
export default OfferComment
