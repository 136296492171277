import React, { useRef } from 'react'

export const SingleInput = (
  {
    disabled,
    'data-testid': dataTestId,
    id,
    isExpanded,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    searchText,
    selectedOption,
  },
  ref
) => {
  const innerRef = useRef(null)
  if (ref) {
    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  }

  return (
    <input
      autoComplete="off"
      data-test={id}
      aria-controls={`ariaControls_${id}`}
      data-testid={dataTestId ? `${dataTestId}_input` : null}
      className="C-Autocomplete__Input"
      ref={innerRef}
      id={id}
      disabled={disabled}
      value={searchText}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      maxLength={maxLength}
      placeholder={placeholder}
      type="text"
      role="combobox"
      aria-expanded={isExpanded}
      aria-activedescendant={selectedOption ? selectedOption.id : ''}
    />
  )
}

SingleInput.displayName = 'SingleInput'

export default React.forwardRef(SingleInput)
