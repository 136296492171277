const contactMapper = (contact) => ({
  aahomeProductProducer: contact.aahomeProductProducer,
  apparelIhp: contact.apparelIhp,
  apparelKids: contact.apparelKids,
  apparelMens: contact.apparelMens,
  apparelRtw: contact.apparelRtw,
  babyMerchantDivisionCaptain: contact.babyMerchantDivisionCaptain,
  copyWriter: contact.copyWriter,
  cscSupport: contact.cscSupport,
  deleted: contact.deleted,
  digitalEp: contact.digitalEp,
  digitalWriters: contact.digitalWriters,
  epManager: contact.epManager,
  epTeam: contact.epTeam,
  frequencyhlProductProducer: contact.frequencyhlProductProducer,
  hardlinesElec: contact.hardlinesElec,
  hardlinesEnt: contact.hardlinesEnt,
  hardlinesSprt: contact.hardlinesSprt,
  hardlinesToys: contact.hardlinesToys,
  homeDec: contact.homeDec,
  homeKtn: contact.homeKtn,
  homeStat: contact.homeStat,
  id: contact.id,
  projectManager: contact.projectManager,
})
export default contactMapper
