import { Button, Grid, Table, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { faCamera, faTshirt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import '../scss/POP-table.scss'

const PaginationTable = (props) => {
  const { toSampleUrl, wads, archive } = props
  const columnData = [
    { id: 'startDate', label: 'Start Date', size: 2 },
    { id: 'projectName', label: 'Project Name', size: 3 },
    { id: 'eventWeek', label: 'Event Week', size: 3 },
    { id: 'creativeKickoffDate', label: 'Kickoff Meeting', size: 2 },
  ]

  return (
    <div className="hc-pa-normal hc-pt-none">
      <Table>
        <Table.Head>
          <Table.Row>
            {columnData.map((col) => (
              <Table.Header key={col.id} xs={col.size}>
                {col.label}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body className="POP-table">
          {wads?.map((weeklyad) => (
            <Table.Row
              id={weeklyad.id}
              key={weeklyad.id}
              align="center"
              clickable
              as={Link}
              to={`/weeklyad/${weeklyad.id}?hasRun=${archive}`}
              archive={archive}
            >
              <Table.Data xs={2}>
                <DateFormatter
                  date={weeklyad.startDate}
                  format="ddd MMM DD YYYY"
                />
              </Table.Data>
              <Table.Data xs={3}>{weeklyad.projectName}</Table.Data>
              <Table.Data xs={3}>{weeklyad.eventWeek}</Table.Data>
              <Table.Data xs={2}>
                <DateFormatter
                  date={weeklyad.creativeKickoffDate}
                  format="ddd MMM DD YYYY"
                />
              </Table.Data>
              <Table.Data xs={2}>
                <Grid.Container spacing="dense">
                  <Grid.Item>
                    <Tooltip content="Merch View" location="top">
                      <Button
                        type="primary"
                        iconOnly
                        aria-label="merch view"
                        as={Link}
                        to={`/weeklyad/${weeklyad.id}/merchant`}
                      >
                        <FontAwesomeIcon icon={faTshirt} />
                      </Button>
                    </Tooltip>
                  </Grid.Item>
                  <Grid.Item>
                    <Tooltip content="Sample View" location="top">
                      <Button
                        type="primary"
                        aria-label="sample view"
                        iconOnly
                        as={Link}
                        to={toSampleUrl(weeklyad.id, archive)}
                      >
                        <FontAwesomeIcon icon={faCamera} />
                      </Button>
                    </Tooltip>
                  </Grid.Item>
                </Grid.Container>
              </Table.Data>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}
export default PaginationTable
