import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import '../../src/scss/ExpandableMarkets.scss'

const ExpandableMarkets = (props) => {
  const { weeklyAd } = props

  const [expandBookOne, setExpandBookOne] = useState(false)
  const [expandBookTwo, setExpandBookTwo] = useState(false)

  return (
    <div className="hc-pa-none hc-ta-left">
      <div className="market-one-container" padding="none">
        <div className="expandable-market-one">
          {' '}
          <FontAwesomeIcon
            display={'inline-block'}
            className={`clickable market-expander ${
              expandBookOne
                ? 'rotate-clockwise-45'
                : 'rotate-counter-clockwise-45'
            }`}
            icon={faAngleDown}
            onClick={(e) => {
              e.preventDefault()
              setExpandBookOne(!expandBookOne)
            }}
            size="lg"
            data-testid="expand-markets"
          />
          {weeklyAd.insertPageCount ? (
            <p style={{ paddingLeft: '10px' }}>Book 1 Markets</p>
          ) : (
            <p style={{ paddingLeft: '10px' }}>Markets</p>
          )}
        </div>
        {expandBookOne && (
          <div className={'hc-ta-center'}>
            <p>{weeklyAd.bookOneMarkets}</p>
          </div>
        )}
      </div>
      {weeklyAd.insertPageCount ? (
        <div className="market-two-container" padding="none">
          <div className="expandable-market-two">
            {' '}
            <FontAwesomeIcon
              display={'inline-block'}
              className={`clickable market-expander ${
                expandBookTwo
                  ? 'rotate-clockwise-45'
                  : 'rotate-counter-clockwise-45'
              }`}
              icon={faAngleDown}
              onClick={(e) => {
                e.preventDefault()
                setExpandBookTwo(!expandBookTwo)
              }}
              size="lg"
              data-testid="expand-markets"
            />
            <p style={{ paddingLeft: '10px' }}>Book 2 Markets</p>
          </div>

          {expandBookTwo ?? (
            <div className={'hc-ta-center'}>
              <p>{weeklyAd.bookTwoMarkets}</p>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}
export default ExpandableMarkets
