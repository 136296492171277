import {
  Card,
  Grid,
  Heading,
  ProgressBar,
  Form,
  Button,
  Pagination,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useState, useEffect, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { useParams, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import CatalogApi from '../../api/pop-catalog/CatalogApi'
import ExportApi from '../../api/pop-catalog/ExportApi'
import ProductApi from '../../api/pop-catalog/ProductApi'
import CategoryFilter from '../../components/CategoryFilter'
import appConfig from '../../config/appConfig'
import AddMultipleProduct from '../../forms/AddMultipleProduct'
import AddProduct from '../../forms/AddProduct'
import ProductList from '../../tables/ProductList'
import { CATEGORY_LIST } from '../../util/CategoryColorMap'
import { isAdminOrMerch } from '../../util/CheckRole'
import {
  canEditUpcomingCatalogs,
  canEditPastCatalogs,
} from '../../util/CheckRole'
import { setPageFilterFromQuery } from '../../util/filterOptions'
import { STATUS_LIST } from '../../util/FormConstants'
import { isFutureDate } from '../../util/MomentUtil'
import { PRODUCT_EXPORT_URL } from '../../util/urlUtil'
import './scss/Catalog.scss'

const ProductListView = (props) => {
  const { role = [] } = props
  const search = useLocation()?.search

  const query = useMemo(() => new URLSearchParams(search), [search])
  const [cookie, setCookie] = useCookies(['category'])

  const catalogId = useParams()?.catalogId
  const config = appConfig()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [catalog, setCatalog] = useState({})
  const [products, setProducts] = useState([])
  const [transport, setTransport] = useState(0)
  const [addProduct, setAddProduct] = useState({})
  const [refreshData, setRefreshData] = useState(true)
  const [productData, setProductData] = useState({})
  const [isStatusUpdate, setStatusUpdate] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState({})
  const [distinctVendors, setDistinctVendors] = useState({})
  const [isSelectAll, setSelectAll] = useState(false)
  const [newCategory, setCategory] = useState()
  const [approvedVendors, setApprovedVendors] = useState({})
  const [isActiveCatalog, setIsActiveCatalog] = useState(false)

  const onCategoryChange = (newCategory) => {
    setCookie('category', newCategory, {
      maxAge: 3.154e7,
      path: '/',
    })
  }

  const catalogApi = new CatalogApi()
  const exportApi = new ExportApi()
  const productApi = new ProductApi()

  const vendorName = 'Target Enterprise Inc'

  const canEdit = isActiveCatalog
    ? role.some((r) => canEditUpcomingCatalogs(r))
    : role.some((r) => canEditPastCatalogs(r))

  // Pagination Utility useEffect
  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage.toString())
      setTransport(0)
    }
  }, [transport, query])

  useEffect(() => {
    const ids = cookie.category
    if (!isEmpty(ids) && ids !== 'null') {
      setCategory(ids)
      if (query.get('category') === null) {
        query.set('category', ids.value)
      }
    } else {
      setCategory()
      query.delete('category')
    }
  }, [cookie, query])
  useEffect(() => {
    setLoading(true)
    catalogApi
      .getCatalog(catalogId)
      .then((res) => {
        setCatalog(res)
        setIsActiveCatalog(isFutureDate(res.runDate))
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [setCatalog, setLoading])

  useEffect(() => {
    setLoading(true)
    if (refreshData) {
      catalogApi
        .getDistinctVendors(catalogId)
        .then((res) => {
          setDistinctVendors(res)
        })
        .catch((e) => console.log(e))
        .finally(setRefreshData(false))
      productApi
        .getProductBatch(query, catalogId)
        .then((res) => {
          setProductData(res)
          setProducts(res.products)
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false))
      catalogApi.getApprovedVendors().then((res) => setApprovedVendors(res))
    }
    // eslint-disable-next-line
  }, [
    setDistinctVendors,
    setApprovedVendors,
    setLoading,
    refreshData,
    setProducts,
  ])
  const pageList = formatPages(catalog?.pages)

  const vendorList = !isEmpty(distinctVendors) ? distinctVendors : []

  const vendorChangerOptions =
    vendorList &&
    vendorList.map((vendor) => ({
      id: vendor,
      label: vendor,
      value: vendor,
    }))
  const onBulkAccept = () => {
    productApi
      .putAcceptProducts({
        products: Object.values(selectedProducts),
        status: 'accepted',
      })
      .then((res = {}) => {
        if (res.data && res.data.length) {
          const newProducts = [...products]

          newProducts.forEach((prod = {}, idx) => {
            const updatedProduct = res.data.find((r) => r.id === prod.id)

            if (updatedProduct) {
              newProducts[idx] = updatedProduct
            }
          })

          setProducts(newProducts)
        }
      })
      .catch((err) => console.log(err))
    setSelectedProducts({})
    setStatusUpdate(false)
  }

  const onBulkSelectAll = () => {
    if (isSelectAll) {
      setSelectedProducts({})
    } else {
      setSelectedProducts(
        products
          .filter((prod) => prod.status !== 'accepted')
          .reduce((acc, { id, last_updated_timestamp }) => {
            acc[id] = {
              id,
              last_updated_timestamp: last_updated_timestamp,
            }
            return acc
          }, {})
      )
    }
    setSelectAll((status) => !status)
  }

  return productData ? (
    <Formik
      enableReinitialize
      initialValues={{
        buyer: query.get('buyer') ? query.get('buyer') : '',
        category: query.get('category')
          ? CATEGORY_LIST.find(
              (category) => String(category.value) === query.get('category')
            )
          : '',
        dpci: query.get('dpci') ? query.get('dpci') : '',
        page: query.get('pageId')
          ? setPageFilterFromQuery(query, pageList)
          : [],
        productName: query.get('product_name') ? query.get('product_name') : '',
        query: query.toString(),
        status: query.get('status')
          ? STATUS_LIST.find((status) => status.value === query.get('status'))
          : '',
        vendorName: query.get('vendorName')
          ? vendorChangerOptions?.find(
              (vendor) => String(vendor.id) === query.get('vendorName')
            )
          : '',
      }}
      onSubmit={(values) => {
        query.delete('dpci')
        query.delete('buyer')
        query.delete('pageId')
        query.delete('status')
        query.delete('pageNo')
        query.delete('category')
        query.delete('perPage')
        query.delete('vendorName')
        query.delete('product_name')
        !isEmpty(values.dpci)
          ? query.set('dpci', values.dpci)
          : query.delete('dpci')

        !isEmpty(values.buyer)
          ? query.set('buyer', values.buyer)
          : query.delete('buyer')

        !isEmpty(values.page)
          ? query.set('pageId', values.page.map((page) => page.id).join(','))
          : query.delete('pageId')

        !isEmpty(values.productName)
          ? query.set('product_name', values.productName)
          : query.delete('product_name')

        !isEmpty(values.status)
          ? query.set('status', values.status?.value)
          : query.delete('status')

        !isEmpty(values.category)
          ? query.set('category', values.category?.value)
          : query.delete('category')

        !isEmpty(values.vendorName)
          ? query.set('vendorName', values?.vendorName?.value)
          : query.delete('vendorName')
        navigate(`/catalog/${catalogId}/product-list?${query.toString()}`)
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
        <>
          <div
            className="hc-pa-normal hc-pa-none"
            data-testid="productListView"
          >
            <Grid.Container
              padding="dense"
              spacing="none"
              justify="flex-end"
              align="flex-end"
            >
              <Grid.Item>
                <Card elevation={2} corners="top">
                  {productData && (
                    <Pagination
                      currentPage={productData?.pageNumber + 1}
                      totalPages={productData?.totalPages}
                      onRequestNext={
                        productData.isLastPage
                          ? null
                          : () => {
                              query.set('pageNo', productData?.pageNumber + 1)
                              navigate(
                                `/catalog/${catalogId}/product-list?${query.toString()}`
                              )
                            }
                      }
                      onRequestPrev={
                        productData.isFirstPage
                          ? null
                          : () => {
                              query.set('pageNo', productData?.pageNumber - 1)
                              navigate(
                                `/catalog/${catalogId}/product-list?${query.toString()}`
                              )
                            }
                      }
                    />
                  )}
                </Card>
              </Grid.Item>
              <Grid.Item xs={12}>
                <Card>
                  {loading ? (
                    <div className="hc-pa-normal">
                      <ProgressBar indeterminate />
                    </div>
                  ) : (
                    <div className="hc-pa-normal">
                      <Grid.Container align="center" justify="center">
                        <Grid.Item xs={12} className="hc-ta-center hc-pb-dense">
                          <Heading>{catalog?.name}</Heading>
                          <p>Run Date: {catalog?.runDate}</p>
                        </Grid.Item>

                        <Grid.Item xs={12} className="hc-pa-none hc-ta-center">
                          {canEdit && (
                            <>
                              <Button
                                className="hc-mr-normal"
                                type="primary"
                                onClick={() => {
                                  setAddProduct({
                                    catalogId: catalogId,
                                    show: true,
                                    vendorName: vendorName,
                                  })
                                }}
                              >
                                Add Product
                              </Button>
                              <Button
                                className="hc-ma-normal"
                                type="primary"
                                onClick={() => {
                                  setAddProduct({
                                    catalogId: catalogId,
                                    displayBulkProductModal: true,
                                    vendorName: vendorName,
                                  })
                                }}
                              >
                                Add Multiple Products
                              </Button>
                            </>
                          )}

                          {canEdit && (
                            <Button
                              className="hc-ma-normal"
                              type="primary"
                              onClick={() => setStatusUpdate(true)}
                            >
                              Bulk Approve
                            </Button>
                          )}
                          {isStatusUpdate && (
                            <Button
                              className="hc-ma-normal"
                              type="primary"
                              onClick={onBulkSelectAll}
                            >
                              Select All
                            </Button>
                          )}
                          <Button
                            type="secondary"
                            className="hc-ma-normal"
                            onClick={() => {
                              const newQ = new URLSearchParams(values?.query)
                              newQ.set('key', config.catapi.apikey)
                              exportApi.getExport(
                                PRODUCT_EXPORT_URL(catalogId) + newQ.toString()
                              )
                            }}
                          >
                            Export
                          </Button>
                        </Grid.Item>
                        <Grid.Container
                          align="center"
                          className="hc-ma-min hc-ml-expanded"
                        >
                          <Grid.Item xs={2}>
                            <CategoryFilter
                              category={newCategory}
                              setCategory={(category) => {
                                onCategoryChange(category)
                                handleSubmit()
                              }}
                              setFieldValue={setFieldValue}
                            />
                            <Autocomplete
                              id="status"
                              onUpdate={(id, statusObj) => {
                                setFieldValue(id, statusObj)
                                handleSubmit()
                              }}
                              chipHeight="normal"
                              value={values.status}
                              options={STATUS_LIST}
                              placeholder="Status"
                            />
                          </Grid.Item>
                          <Grid.Item xs={2}>
                            <Autocomplete
                              id="page"
                              multiselect
                              chipHeight="dense"
                              onUpdate={(_, value) => {
                                setFieldValue('page', value)
                                value.length !== values.page.length &&
                                  handleSubmit()
                              }}
                              value={values.page}
                              options={pageList}
                              placeholder="Page"
                            />
                            <Form.Field
                              id="buyer"
                              placeholder="Buyer Name"
                              value={values.buyer}
                              onChange={handleChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSubmit()
                                }
                                if (e.key === 'Escape') {
                                  setFieldValue('buyer', '')
                                  handleSubmit()
                                }
                                if (e.key === 'Tab') {
                                  e.target.value.length > 0 && handleSubmit()
                                }
                              }}
                              className="bottom-input-spacing"
                            />
                          </Grid.Item>
                          <Grid.Item xs={2}>
                            <Autocomplete
                              id="vendorName"
                              placeholder="Vendor"
                              chipHeight="normal"
                              onUpdate={(id, vendor) => {
                                setFieldValue(id, vendor)
                                handleSubmit()
                              }}
                              value={values.vendorName}
                              options={vendorChangerOptions}
                            />
                            <Form.Field
                              id="dpci"
                              placeholder="DPCI"
                              value={values.dpci}
                              onChange={handleChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSubmit()
                                }
                              }}
                              className="plv-input-alignment bottom-input-spacing"
                            />
                          </Grid.Item>
                          <Grid.Item xs={2}>
                            <Form.Field
                              id="productName"
                              placeholder="Product Name"
                              value={values.productName}
                              onChange={handleChange}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSubmit()
                                }
                                if (e.key === 'Tab') {
                                  handleSubmit()
                                }
                              }}
                              className="plv-input-alignment"
                            />
                          </Grid.Item>
                          <Grid.Item xs={4}>
                            <Button
                              type="secondary"
                              onClick={() => {
                                onCategoryChange('')
                                navigate(`/catalog/${catalogId}/product-list`)
                                handleReset()
                              }}
                            >
                              Clear
                            </Button>
                          </Grid.Item>
                        </Grid.Container>

                        <ProductList
                          approvedVendors={approvedVendors}
                          canEdit={canEdit}
                          catalog={catalog}
                          products={products}
                          setProducts={setProducts}
                          role={role}
                          view="product"
                          isStatusUpdate={isStatusUpdate}
                          selectedProducts={selectedProducts}
                          setSelectedProducts={setSelectedProducts}
                        />
                      </Grid.Container>
                    </div>
                  )}
                </Card>
              </Grid.Item>
              <AddProduct
                addProduct={addProduct}
                setAddProduct={setAddProduct}
                isVendor={false}
                isAdminOrMerch={isAdminOrMerch(role)}
                setProducts={setProducts}
                view="product"
                approvedVendors={approvedVendors}
              />
              {isStatusUpdate ? (
                <div
                  style={{
                    alignItems: 'center',
                    background: '#FFF',
                    borderRadius: 5,
                    bottom: 20,
                    boxShadow: '0px 0px 5px 5px #e2e2e2',
                    display: 'flex',
                    height: 50,
                    justifyContent: 'space-around',
                    position: 'fixed',
                    width: 200,
                    zIndex: 2,
                  }}
                >
                  <Button
                    type="secondary"
                    onClick={() => {
                      setSelectedProducts({})
                      setStatusUpdate(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    disabled={!Object.keys(selectedProducts).length}
                    onClick={onBulkAccept}
                  >
                    Submit
                  </Button>
                </div>
              ) : null}

              <AddMultipleProduct
                addProduct={addProduct}
                setAddProduct={setAddProduct}
                isVendor={false}
                isAdminOrMerch={isAdminOrMerch(role)}
                view="product"
                catalog={catalog}
                products={products}
                role={role}
                setRefreshData={setRefreshData}
              />
            </Grid.Container>
          </div>
        </>
      )}
    </Formik>
  ) : null
}

function formatPages(pages) {
  return !isEmpty(pages)
    ? pages
        ?.map((page) => ({
          id: page.id,
          label: `Page ${page.pageOrdering + 1}`,
          value: page.id,
        }))
        .concat([{ id: -1, label: 'TBD', value: -1 }])
    : []
}
export default ProductListView
