import axios from 'axios'
import { useEffect, useState } from 'react'

import appConfig from '../config/appConfig'

const usePutSampleProp = (setLoading, query, wadId, setPropItems) => {
  const config = appConfig()
  const url = config.backend.root + config.backend.props
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const putSampleProp = async () => {
      try {
        mounted && setLoading(true)
        const id = request.body.id
        const response = await axios.put(url + id, request.body)
        mounted &&
          setPropItems((propItems) => {
            propItems.props = propItems.props.map((propItem) => {
              if (propItem.id === id) {
                return response.data
              }
              return propItem
            })
            return propItems
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setRequest(null)
      mounted && setLoading(false)
    }

    if (request !== null) {
      putSampleProp()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setPropItems, url])

  return setRequest
}
export default usePutSampleProp
