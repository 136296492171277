import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class MerchandiseApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_merchandise_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getDivisions = async () =>
    await this.init_merchandise_api()
      .get(`${this.config.markethub.url}merchandise/division`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })
}
