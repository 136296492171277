import { Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import ExpandableMoveCutStory from '../components/ExpandableMoveCutStory'

const MoveCut = (props) => {
  const { moveCut, setMoveCut, setEditCut, weeklyAd, getPage, putMoveCut } =
    props

  const [availablePagesForCut, setAvailablePagesForCut] = useState([])

  const wipeState = () => {
    setMoveCut({ show: false })
    setAvailablePagesForCut([])
  }

  useEffect(() => {
    if (weeklyAd?.pages && moveCut?.cut?.body?.divisionId) {
      const otherPages = weeklyAd?.pages
        .filter(
          (page) =>
            !isEmpty(
              page.divisions.filter(
                (division) => division.id === moveCut?.cut?.body?.divisionId
              )
            ) && page.id !== moveCut?.cut?.page?.id
        )
        .filter((page) =>
          FindCommonDivisions(
            moveCut?.cut?.followers.map((follower) => follower.division.id),
            page.divisions.map((division) => division.id)
          )
        )
      const thisPage = weeklyAd?.pages.find(
        (page) => page.id === moveCut?.cut?.page?.id
      )
      // If there is only 1 story in the current page the cut is on,
      // don't show the current page because it can't be moved.
      setAvailablePagesForCut(
        thisPage?.stories.length > 1
          ? [thisPage].concat(otherPages)
          : otherPages
      )
    }
  }, [setAvailablePagesForCut, moveCut, weeklyAd])

  function FindCommonDivisions(artPatchDivisions, pageDivisions) {
    for (const artPatchDivision of artPatchDivisions) {
      if (!pageDivisions.includes(artPatchDivision)) {
        return false
      }
    }
    return true
  }

  return !isEmpty(availablePagesForCut) ? (
    <Formik
      enableReinitialize
      initialValues={{ checked: '' }}
      onSubmit={(values, funcs) => {
        const sender = moveCut?.cut?.body
        putMoveCut({
          body: [
            {
              id: sender?.id,
              lastUpdatedTimestamp: moveCut.cutTimestampRef.current,
              oldStoryId: moveCut?.cut?.storyId,
              storyId: moveCut?.cut?.storyId,
            },
            ...(moveCut?.cut?.followers.map((cut) => ({
              id: cut.id,
              lastUpdatedTimestamp: cut.lastUpdatedTimestamp,
              oldStoryId: cut.storyId,
              storyId: cut.storyId,
            })) || {}),
          ],
          id: values.checked,
        })
        funcs.resetForm()
        wipeState()
        setEditCut({ body: {}, pageId: null, show: false, storyId: null })
      }}
    >
      {({ values, setFieldValue, handleSubmit, handleReset }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={moveCut.show}
            onRefuse={handleDialogClose}
            headingText="Stories Available for Cut"
            dense
          >
            <div className="hc-pa-dense">
              {availablePagesForCut.map((page) => (
                <ExpandableMoveCutStory
                  key={page.id}
                  pageId={page.id}
                  weeklyAd={weeklyAd}
                  getPage={getPage}
                  cutMoving={moveCut?.cut}
                  checked={values.checked}
                  setFieldValue={setFieldValue}
                />
              ))}
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!values.checked}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : (
    <Modal
      isVisible={moveCut.show}
      onRefuse={wipeState}
      headingText="No Spot Available for Cut"
      dense
    >
      <div className="hc-pa-dense">
        <CancelSubmitButtons handleDialogClose={wipeState} />
      </div>
    </Modal>
  )
}
export default MoveCut
