import {
  Card,
  Grid,
  Heading,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'

import PlatformApi from '../../api/platform-notifications/EmailVerification'
import AdminCenterApi from '../../api/pop-weekly-ad/AdminCenterApi'
import CancelSubmitButtons from '../../components/CancelSubmitButtons'
import {
  emailSchema,
  validateWeeklyAdEmails,
} from '../../forms/EmailVerificationHelper'
import { mapPmm } from '../../util/mapPmmHelper'

const AdminCenter = (props) => {
  const platformApi = new PlatformApi()
  const adminCenterApi = new AdminCenterApi()

  const makeToast = ToastProvider.useToaster()

  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [defaultContacts, setDefaultContacts] = useState({})

  useEffect(() => {
    adminCenterApi.getDefaultContacts().then((data) => {
      setInitialLoading(false)
      setDefaultContacts(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    aahomeProductProducer,
    apparelIhp,
    apparelKids,
    apparelMens,
    apparelRtw,
    audioAcd,
    babyMerchantDivisionCaptain,
    cscSupport,
    digitalEp,
    digitalWriters,
    epManager,
    epTeam,
    frequencyhlProductProducer,
    hardlinesElec,
    hardlinesEnt,
    hardlinesSprt,
    hardlinesToys,
    homeDec,
    homeKtn,
    homeStat,
    plannerAa,
    plannerBeauty,
    plannerEssentials,
    plannerFb,
    plannerHardlines,
    plannerHome,
    pmmApparel,
    pmmBeauty,
    pmmEssentials,
    pmmFoodBev,
    pmmHardlines,
    pmmHome,
    projectManager,
    socialDigitalDwaAcd,
    headlineWriter,
    epCaptain,
    videoAcd,
  } = defaultContacts

  return (
    <Card>
      {initialLoading ? (
        <Grid.Container justify="center" className="hc-pa-dense">
          <Spinner />
        </Grid.Container>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            aahomeProductProducer: mapPmm(aahomeProductProducer),
            apparelIhp: mapPmm(apparelIhp),
            apparelKids: mapPmm(apparelKids),
            apparelMens: mapPmm(apparelMens),
            apparelRtw: mapPmm(apparelRtw),
            audioAcd: mapPmm(audioAcd),
            babyMerchantDivisionCaptain: mapPmm(babyMerchantDivisionCaptain),
            cscSupport: mapPmm(cscSupport),
            digitalEp: mapPmm(digitalEp),
            digitalWriters: mapPmm(digitalWriters),
            epCaptain:
              (epCaptain && [{ label: epCaptain, value: epCaptain }]) || [],
            epManager: mapPmm(epManager),
            epTeam: mapPmm(epTeam),
            frequencyhlProductProducer: mapPmm(frequencyhlProductProducer),
            hardlinesElec: mapPmm(hardlinesElec),
            hardlinesEnt: mapPmm(hardlinesEnt),
            hardlinesSprt: mapPmm(hardlinesSprt),
            hardlinesToys: mapPmm(hardlinesToys),
            headlineWriter:
              (headlineWriter && [
                { label: headlineWriter, value: headlineWriter },
              ]) ||
              [],
            homeDec: mapPmm(homeDec),
            homeKtn: mapPmm(homeKtn),
            homeStat: mapPmm(homeStat),
            invalidEmails: [],
            plannerAa: mapPmm(plannerAa),
            plannerBeauty: mapPmm(plannerBeauty),
            plannerEssentials: mapPmm(plannerEssentials),
            plannerFb: mapPmm(plannerFb),
            plannerHardlines: mapPmm(plannerHardlines),
            plannerHome: mapPmm(plannerHome),
            pmmApparel: mapPmm(pmmApparel),
            pmmBeauty: mapPmm(pmmBeauty),
            pmmEssentials: mapPmm(pmmEssentials),
            pmmFoodBev: mapPmm(pmmFoodBev),
            pmmHardlines: mapPmm(pmmHardlines),
            pmmHome: mapPmm(pmmHome),
            projectManager: mapPmm(projectManager),
            socialDigitalDwaAcd: mapPmm(socialDigitalDwaAcd),
            spellCheck: false,
            videoAcd: mapPmm(videoAcd),
          }}
          validationSchema={emailSchema}
          onSubmit={(values = {}, funcs) => {
            const {
              aahomeProductProducer,
              apparelIhp,
              apparelKids,
              apparelMens,
              apparelRtw,
              babyMerchantDivisionCaptain,
              cscSupport,
              digitalEp,
              digitalWriters,
              epManager,
              epTeam,
              frequencyhlProductProducer,
              hardlinesElec,
              hardlinesEnt,
              hardlinesSprt,
              hardlinesToys,
              homeDec,
              homeKtn,
              homeStat,
              pmmApparel,
              pmmBeauty,
              pmmEssentials,
              pmmFoodBev,
              pmmHardlines,
              pmmHome,
              projectManager,
              headlineWriter,
              epCaptain,
              plannerAa,
              plannerBeauty,
              plannerEssentials,
              plannerFb,
              plannerHardlines,
              plannerHome,
              socialDigitalDwaAcd,
              videoAcd,
              audioAcd,
            } = values

            setLoading(true)
            adminCenterApi
              .putDefaultContacts(defaultContacts.id, {
                aahomeProductProducer: aahomeProductProducer.map(
                  (option) => option.value
                ),
                apparelIhp: apparelIhp.map((option) => option.value),
                apparelKids: apparelKids.map((option) => option.value),
                apparelMens: apparelMens.map((option) => option.value),
                apparelRtw: apparelRtw.map((option) => option.value),
                audioAcd: audioAcd.map((option) => option.value),
                babyMerchantDivisionCaptain: babyMerchantDivisionCaptain.map(
                  (option) => option.value
                ),
                cscSupport: cscSupport.map((option) => option.value),
                digitalEp: digitalEp.map((option) => option.value),
                digitalWriters: digitalWriters.map((option) => option.value),
                epCaptain: (epCaptain.length && epCaptain[0].value) || '',
                epManager: epManager.map((option) => option.value),
                epTeam: epTeam.map((option) => option.value),
                frequencyhlProductProducer: frequencyhlProductProducer.map(
                  (option) => option.value
                ),
                hardlinesElec: hardlinesElec.map((option) => option.value),
                hardlinesEnt: hardlinesEnt.map((option) => option.value),
                hardlinesSprt: hardlinesSprt.map((option) => option.value),
                hardlinesToys: hardlinesToys.map((option) => option.value),
                headlineWriter:
                  (headlineWriter.length && headlineWriter[0].value) || '',
                homeDec: homeDec.map((option) => option.value),
                homeKtn: homeKtn.map((option) => option.value),
                homeStat: homeStat.map((option) => option.value),
                plannerAa: plannerAa.map((option) => option.value),
                plannerBeauty: plannerBeauty.map((option) => option.value),
                plannerEssentials: plannerEssentials.map(
                  (option) => option.value
                ),
                plannerFb: plannerFb.map((option) => option.value),
                plannerHardlines: plannerHardlines.map(
                  (option) => option.value
                ),
                plannerHome: plannerHome.map((option) => option.value),
                pmmApparel: pmmApparel.map((option) => option.value),
                pmmBeauty: pmmBeauty.map((option) => option.value),
                pmmEssentials: pmmEssentials.map((option) => option.value),
                pmmFoodBev: pmmFoodBev.map((option) => option.value),
                pmmHardlines: pmmHardlines.map((option) => option.value),
                pmmHome: pmmHome.map((option) => option.value),
                projectManager: projectManager.map((option) => option.value),
                socialDigitalDwaAcd: socialDigitalDwaAcd.map(
                  (option) => option.value
                ),
                videoAcd: videoAcd.map((option) => option.value),
              })
              .then((data) => {
                setDefaultContacts(data)
                setLoading(false)
                makeToast({
                  heading: 'Contacts are updated.',
                  isVisible: true,
                  message: 'Nice.',
                  type: 'success',
                })
              })
              .catch(() => {
                makeToast({
                  heading: 'Failed to update contacts..',
                  isVisible: true,
                  message: 'Something went wrong, try again...',
                  type: 'error',
                })
              })
          }}
        >
          {({
            values = {},
            errors,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => {
            const {
              headlineWriter,
              epCaptain,
              pmmBeauty,
              pmmEssentials,
              pmmFoodBev,
              pmmApparel,
              pmmHardlines,
              pmmHome,
              apparelMens,
              apparelKids,
              apparelRtw,
              apparelIhp,
              audioAcd,
              babyMerchantDivisionCaptain,
              hardlinesElec,
              hardlinesEnt,
              hardlinesSprt,
              hardlinesToys,
              homeKtn,
              homeStat,
              homeDec,
              digitalWriters,
              projectManager,
              epManager,
              epTeam,
              digitalEp,
              cscSupport,
              aahomeProductProducer,
              frequencyhlProductProducer,
              plannerAa,
              plannerBeauty,
              plannerEssentials,
              plannerFb,
              plannerHardlines,
              plannerHome,
              socialDigitalDwaAcd,
              videoAcd,
            } = values

            const onSingleSelectAutocompleteChange = (id, value) => {
              if (value.length <= 1) {
                handleEmailFieldUpdate(id, value)
              }
            }

            const verifyEmails = async function (values) {
              const response = await validateWeeklyAdEmails(values, platformApi)
              setFieldValue('invalidEmails', response)
            }

            const handleEmailFieldUpdate = (id, value) => {
              setFieldValue(id, value)
              let valuesCopy = { ...values }
              valuesCopy[id] = value
              verifyEmails(valuesCopy)
            }

            return (
              <div className="hc-pa-normal">
                <Grid.Container spacing="dense">
                  <Grid.Item className="hc-ta-center" xs={12}>
                    <Heading size={4}>Merch Contacts</Heading>
                    <div className="hc-pa-normal">
                      <Grid.Container spacing="dense">
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="apparelIhp"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={apparelIhp}
                            options={[]}
                            label="Apparel IHP"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="apparelMens"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={apparelMens}
                            options={[]}
                            label="Apparel Mens"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="apparelKids"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={apparelKids}
                            options={[]}
                            label="Apparel Kids"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="apparelRtw"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={apparelRtw}
                            options={[]}
                            label="Apparel RTW"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="babyMerchantDivisionCaptain"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={babyMerchantDivisionCaptain}
                            options={[]}
                            label="Baby Merchant Division Captain"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="homeDec"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={homeDec}
                            options={[]}
                            label="Home Dec"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="homeKtn"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={homeKtn}
                            options={[]}
                            label="Home KTN"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="homeStat"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={homeStat}
                            options={[]}
                            label="Home Stat"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="hardlinesSprt"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={hardlinesSprt}
                            options={[]}
                            label="Hardlines Sporting Goods"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="hardlinesToys"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={hardlinesToys}
                            options={[]}
                            label="Hardlines Toys"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="hardlinesElec"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={hardlinesElec}
                            options={[]}
                            label="Hardlines Elec"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="hardlinesEnt"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={hardlinesEnt}
                            options={[]}
                            label="Hardlines Ent"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                      </Grid.Container>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="hc-ta-center" xs={12}>
                    <Heading size={4}>
                      Marketing &#38; Creative Contacts
                    </Heading>
                    <div className="hc-pa-normal">
                      <Grid.Container spacing="dense">
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmApparel"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmApparel}
                            options={[]}
                            label="A&#38;A PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmHardlines"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmHardlines}
                            options={[]}
                            label="Hardlines PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmHome"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmHome}
                            options={[]}
                            label="Home PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmFoodBev"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmFoodBev}
                            options={[]}
                            label="F&#38;B PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmBeauty"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmBeauty}
                            options={[]}
                            label="Beauty PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmEssentials"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmEssentials}
                            options={[]}
                            label="Essentials PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="frequencyhlProductProducer"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={frequencyhlProductProducer}
                            options={[]}
                            label="Frequency/HL Product Producer"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="aahomeProductProducer"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={aahomeProductProducer}
                            options={[]}
                            label="A&#38;A/Home Product Producer"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="digitalWriters"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={digitalWriters}
                            options={[]}
                            label="Digital Writers"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="epCaptain"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={onSingleSelectAutocompleteChange}
                            value={epCaptain}
                            options={[]}
                            label="EP Captain"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>

                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="projectManager"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={projectManager}
                            options={[]}
                            label="Project Manager"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="headlineWriter"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={onSingleSelectAutocompleteChange}
                            value={headlineWriter}
                            options={[]}
                            label="Headline Writer"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="cscSupport"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={cscSupport}
                            options={[]}
                            label="DWA CSC Support"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="epTeam"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={epTeam}
                            options={[]}
                            label="EP Team"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="digitalEp"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={digitalEp}
                            options={[]}
                            label="Digital EP"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="epManager"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={epManager}
                            options={[]}
                            label="EP Manager"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="socialDigitalDwaAcd"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={socialDigitalDwaAcd}
                            options={[]}
                            label="Social/Digital/DWA ACD"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="videoAcd"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={videoAcd}
                            options={[]}
                            label="Video ACD"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="audioAcd"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={audioAcd}
                            options={[]}
                            label="Audio ACD"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                      </Grid.Container>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="hc-ta-center" xs={12}>
                    <Heading size={4}>Marketing Planners</Heading>
                    <div className="hc-pa-normal">
                      <Grid.Container spacing="dense">
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="plannerAa"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={plannerAa}
                            options={[]}
                            label="A&#38;A Planner"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="plannerBeauty"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={plannerBeauty}
                            options={[]}
                            label="Beauty Planner"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="plannerEssentials"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={plannerEssentials}
                            options={[]}
                            label="Essentials Planner"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="plannerFb"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={plannerFb}
                            options={[]}
                            label="F&#38;B Planner"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="plannerHardlines"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={plannerHardlines}
                            options={[]}
                            label="Hardlines Planner"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="plannerHome"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={plannerHome}
                            options={[]}
                            label="Home Planner"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                      </Grid.Container>
                    </div>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item>
                    <p className="errorEmailMessage">{errors.invalidEmails}</p>
                    <ul className="errorEmailList">
                      {values.invalidEmails.map((email) => (
                        <li>{email}</li>
                      ))}
                    </ul>
                  </Grid.Item>
                </Grid.Container>
                <CancelSubmitButtons
                  canDelete={false}
                  handleSubmit={handleSubmit}
                  handleDialogClose={resetForm}
                  submitDisabled={true}
                  cancelDisabled={loading}
                />
              </div>
            )
          }}
        </Formik>
      )}
    </Card>
  )
}
export default AdminCenter
