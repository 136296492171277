import marketMapper from './marketMapper'

const offerDetailMapper = (offerDetail) => ({
  deleted: offerDetail.deleted,
  //offerDetail.index refers to the discount value, map to offerDetail.marketIndex
  index: offerDetail.marketIndex,
  markets: offerDetail.markets
    ? offerDetail.markets
        .map(marketMapper)
        .sort((a, b) => a.priority - b.priority)
    : [],
  offerId: offerDetail.offerId,
  previousRewardValue: offerDetail.previousRewardValue,
  priority: offerDetail.priority,
  promoType: offerDetail.promoType,
  promoUnitRetail: offerDetail.promoUnitRetail,
  rewardType: offerDetail.rewardType,
  rewardValue: offerDetail.rewardValue,
  rewardValueUpdatedTimestamp: offerDetail.rewardValueUpdatedTimestamp,
  suggestedMultipleAmount: offerDetail.suggestedMultipleAmount,
  suggestedMultipleQuantity: offerDetail.rewardValue,

  thresholdType: offerDetail.thresholdType,
})
export default offerDetailMapper
