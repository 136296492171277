// import '@enterprise-ui/canvas-ui-react/utils/Array.find'
// import '@enterprise-ui/canvas-ui-react/utils/Array.from'
// import '@enterprise-ui/canvas-ui-react/utils/Array.findIndex'
import { Input, InputInfo, Overlay } from '@enterprise-ui/canvas-ui-react'
import classnames from 'classnames'
import {
  KEY_BACK_SPACE,
  KEY_DELETE,
  KEY_ESCAPE,
  KEY_DOWN,
  KEY_UP,
  KEY_ENTER,
  KEY_RETURN,
  KEY_TAB,
} from 'keycode-js'
import throttle from 'lodash.throttle'
import React, { useMemo, useState, useRef, useEffect, useCallback } from 'react'

// Filters
import IconArea from './components/IconArea'
import MultipleInput from './components/MultipleInput'
import OptionsList from './components/OptionsList'
import SingleInput from './components/SingleInput'
import containsFilter from './filters/contains'
import fuzzyFilter from './filters/fuzzy'
import noneFilter from './filters/none'
import startsWithFilter from './filters/startsWith'
// Renderers
import defaultChipRenderer from './renderers/defaultChipRenderer'
import defaultOptionRenderer from './renderers/defaultOptionRenderer'
// Utilities
import createVisibleOptionsList from './utils/createVisibleOptionsList'
import OptionsManager from './utils/OptionsManager'

export const OPTIONS_STATUS = {
  FAILED: 'FAILED',
  LOADING: 'LOADING',
  NOT_READY: 'NOT_READY',
  READY: 'READY',
  STATIC: 'STATIC',
}

export const Autocomplete = ({
  allowCustomInputValue = false,
  alignOptionsList = 'right',
  className,
  chipHeight,
  'data-testid': dataTestId,
  disabled,
  disableFieldInfo,
  error,
  errorText,
  filter = fuzzyFilter,
  highlight,
  hintText,
  id,
  label,
  loadOnce,
  maxOptionsReachedText,
  maxSelectedOptions,
  maxSelectedOptionsText,
  multiselect,
  maxLength,
  renderChip = defaultChipRenderer,
  renderOption = defaultOptionRenderer,
  rightContent,
  onChange,
  onEnter,
  onUpdate,
  options,
  optionHeight,
  optionsListNoMinWidth = false,
  placeholder,
  required,
  showSearchIcon,
  throttle: throttleTime,
  value: propsValue,
  noResultsMessage = 'No Matching Results',
}) => {
  const autocompleteInputRef = useRef(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isShowingOptions, setIsShowingOptions] = useState(false)
  const [availableOptions, setAvailableOptions] = useState([])
  const optionsStatus = useRef(OPTIONS_STATUS.NOT_READY)
  const [preferredOption, setPreferredOption] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState()
  const latestAsyncOptionsIndex = useRef(0)
  const [visibleOptions, setVisibleOptions] = useState([])
  const [maxOptionsReached, setMaxOptionsReached] = useState(false)

  const visibleOptionsHandler = useMemo(
    () => createVisibleOptionsList(filter),
    [filter]
  )

  const getVisibleOptions = useCallback(() => {
    if (maxOptionsReached) {
      setVisibleOptions([])
      return
    }

    let visibleOptions = visibleOptionsHandler(
      searchText,
      availableOptions,
      multiselect ? value : undefined,
      allowCustomInputValue
    )

    setVisibleOptions(visibleOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowCustomInputValue,
    availableOptions,
    maxOptionsReached,
    multiselect,
    searchText,
    value,
  ])
  // force sets value even if propsValue is changed to null
  useEffect(() => {
    setValue(propsValue)
  }, [propsValue])

  // sets availableOptions, re-runs every time we get new options, or searchText
  // sets initial availableOptions
  useEffect(() => {
    if (Array.isArray(options)) {
      setAvailableOptions(options)
    }
  }, [options, searchText])

  // gets the options the user should be seeing
  useEffect(() => {
    if (typeof options !== 'function') {
      getVisibleOptions()
    }
  }, [getVisibleOptions, options])

  // clears searchText if value is null or undefined for the single-select
  // sets searchText if there is a value for the single-select
  useEffect(() => {
    if (!multiselect && !propsValue) {
      setSearchText('')
    } else if (!multiselect && propsValue) {
      setSearchText(propsValue.label)
    } else if (multiselect && !propsValue) {
      // If this is a multiselect with no passed value, set default to empty array. This is for form validation purposes so that it never passes out undefined, always [] if there is no value.
      setValue([])
    }
  }, [propsValue, multiselect])

  // determines if max selected options has been reached
  useEffect(() => {
    const maxSelectedBool =
      multiselect &&
      maxSelectedOptions &&
      maxSelectedOptions === (value && value.length)
    setMaxOptionsReached(maxSelectedBool)
  }, [multiselect, maxSelectedOptions, value])

  const finalClasses = classnames(
    'C-Autocomplete',
    {
      isDisabled: disabled,
      isError: error,
      isFocused: isFocused || isShowingOptions,
      isHighlighted: highlight,
    },
    className
  )

  // Get the input component
  const AutocompleteInput = multiselect ? MultipleInput : SingleInput

  // Do not display placeholder for multiselect with value added
  const displayPlaceholder = useMemo(
    () => !multiselect || !value || value.length === 0,
    [multiselect, value]
  )

  // choose maxSelectedText
  const maxSelectedText = useMemo(
    () =>
      maxSelectedOptions
        ? maxSelectedOptionsText || `Select at most ${maxSelectedOptions}`
        : ``,
    [maxSelectedOptions, maxSelectedOptionsText]
  )

  // allow for hintText, maxSelectedText, or both
  const hint = useMemo(
    () => [hintText, maxSelectedText].filter(Boolean).join(' '),
    [hintText, maxSelectedText]
  )

  const noResultsMessageComputed = useMemo(() => {
    if (maxOptionsReached) {
      return maxOptionsReachedText
        ? maxOptionsReachedText
        : `You selected ${maxSelectedOptions} option${
            maxSelectedOptions === 1 ? '' : 's'
          }. Please remove one before selecting another.`
    }
    return noResultsMessage
  }, [
    maxOptionsReached,
    maxSelectedOptions,
    noResultsMessage,
    maxOptionsReachedText,
  ])

  /*
    Options Management
  */
  const callUpdate = useCallback(
    (nextValue) => {
      // eslint-disable-next-line no-restricted-globals
      onUpdate(name || id, nextValue)
    },
    [id, onUpdate]
  )

  const handleOptionSelectMulti = useCallback(
    (option) => {
      setPreferredOption(null)
      setSearchText('')
      onChange('')
      autocompleteInputRef.current.focus()
      const nextValue = OptionsManager.add(value, option)
      setValue(nextValue)
      callUpdate(nextValue)
    },
    [callUpdate, onChange, value]
  )

  const handleOptionSelectSingle = useCallback(
    (option) => {
      setIsShowingOptions(false)
      setPreferredOption(null)
      setSearchText(option.label)
      callUpdate(option)
    },
    [callUpdate]
  )

  const handleOptionSelect = useCallback(
    (option) => () => {
      if (multiselect) {
        handleOptionSelectMulti(option)
      } else {
        handleOptionSelectSingle(option)
      }
    },
    [handleOptionSelectMulti, handleOptionSelectSingle, multiselect]
  )
  const handleOptionRemove = useCallback(
    (option) => () => {
      const nextValue = OptionsManager.remove(value, option)
      setValue(nextValue)
      callUpdate(nextValue)
    },
    [callUpdate, value]
  )
  const handleOptionsClose = useCallback(() => {
    const directMatchedOption = visibleOptions.find(
      (option) => option.label.toLowerCase() === searchText.toLowerCase()
    )
    if (!multiselect && directMatchedOption) {
      handleOptionSelectSingle(directMatchedOption)
      return
    }
    // call update with null if entered text has no matching option so form can error
    if (!multiselect && !directMatchedOption) {
      callUpdate(null)
    }
    // close options list nomatter what
    setIsShowingOptions(false)
    setPreferredOption(null)

    if (directMatchedOption) {
      setSearchText('')
      onChange('')
    } else {
      setSearchText(searchText || '')
      onChange(searchText)
    }
  }, [
    callUpdate,
    handleOptionSelectSingle,
    multiselect,
    onChange,
    searchText,
    visibleOptions,
  ])

  // Handle Key Input
  const handleKeyDown = useCallback(
    (event) => {
      switch (event.keyCode) {
        case KEY_BACK_SPACE:
        case KEY_DELETE: {
          if (!multiselect) break
          if (searchText.length !== 0) break
          const lastOption = value[value.length - 1]
          if (lastOption) {
            handleOptionRemove(lastOption)()
          }
          break
        }

        // Handle confirm & remove selection
        case KEY_ENTER:
        case KEY_RETURN: {
          /*
           * Autocomplete will be "focused" when selecting options. If so, cancel the key event and take over.
           * It will not be focused when tabbing to previously selected chips. Allow these key events to occur,
           * this allows for "removing" chips via key board navigation.
           */

          if (isFocused) {
            event.preventDefault()
          }

          if (preferredOption && !preferredOption.disabled) {
            handleOptionSelect(preferredOption)()
          } else if (typeof onEnter === 'function') {
            // A user has entered a value, but not selected an option, and hit "enter"
            onEnter(event, value)
          }
          break
        }

        // Changing selected input
        case KEY_DOWN:
        case KEY_UP: {
          event.preventDefault()
          if (visibleOptions.length === 0) break

          let currentIndex = -1
          if (preferredOption !== null) {
            currentIndex = OptionsManager.findIndex(
              visibleOptions,
              preferredOption
            )
          }

          const step = event.keyCode === KEY_DOWN ? 1 : -1
          const nextOption = visibleOptions[currentIndex + step]

          if (nextOption) {
            setPreferredOption(nextOption)
          }
          break
        }

        // Handle leaving field
        case KEY_ESCAPE:
        case KEY_TAB: {
          handleOptionsClose()
          break
        }
        default:
      }
    },
    [
      handleOptionRemove,
      handleOptionSelect,
      handleOptionsClose,
      isFocused,
      multiselect,
      preferredOption,
      onEnter,
      searchText.length,
      value,
      visibleOptions,
    ]
  )

  // Renderers
  const handleChipRender = (option) =>
    renderChip(option, handleOptionRemove(option), disabled, chipHeight)

  const prepareOptionsNoThrottle = useCallback(() => {
    // Check if options need to be regenerated
    if (optionsStatus.current === OPTIONS_STATUS.STATIC) {
      return
    }

    // Test if array
    if (Array.isArray(options)) {
      setAvailableOptions(options)
      optionsStatus.current = OPTIONS_STATUS.STATIC
      return
    }

    // If not a function inform developer of improper implementation
    if (typeof options !== 'function') {
      throw new TypeError('options must be an array or function')
    }

    // Get results
    const functionResults = options(searchText, options)

    // If it is a promise wait for it to finish and use loading state
    if (functionResults.then instanceof Function) {
      const asyncOptionsIndex = latestAsyncOptionsIndex.current + 1
      const moreLatestAsyncOptionsIndex = asyncOptionsIndex
      setIsLoading(true)
      optionsStatus.current = OPTIONS_STATUS.LOADING

      return functionResults.then(
        (returnedOptions) => {
          if (autocompleteInputRef === false) return
          // Prevents stale requests overwriting latest request
          // See https://git.target.com/PDEX/canvas-ui/issues/704
          if (moreLatestAsyncOptionsIndex === asyncOptionsIndex) {
            const filteredOptions = visibleOptionsHandler(
              searchText,
              returnedOptions,
              multiselect ? value : undefined,
              allowCustomInputValue
            )
            setVisibleOptions(filteredOptions)
            setIsLoading(false)
            optionsStatus.current = loadOnce
              ? OPTIONS_STATUS.STATIC
              : OPTIONS_STATUS.READY
          }
        },
        (error) => {
          console.error(error)
          setAvailableOptions([])
          setIsLoading(false)
          optionsStatus.current = OPTIONS_STATUS.FAILED
        }
      )
    } else if (Array.isArray(functionResults)) {
      const filteredOptions = visibleOptionsHandler(
        searchText,
        functionResults,
        multiselect ? value : undefined,
        allowCustomInputValue
      )
      setVisibleOptions(filteredOptions)
      optionsStatus.current = loadOnce
        ? OPTIONS_STATUS.STATIC
        : OPTIONS_STATUS.READY
    }
  }, [
    allowCustomInputValue,
    loadOnce,
    multiselect,
    options,
    searchText,
    value,
    visibleOptionsHandler,
  ])

  const prepareOptionsThrottled = useMemo(
    () =>
      throttle(prepareOptionsNoThrottle, throttleTime, {
        trailing: true,
      }),
    [prepareOptionsNoThrottle, throttleTime]
  )
  const prepareOptions = useMemo(
    () => (throttleTime ? prepareOptionsThrottled : prepareOptionsNoThrottle),
    [prepareOptionsNoThrottle, prepareOptionsThrottled, throttleTime]
  )

  // gets all options
  useEffect(() => {
    prepareOptions()
  }, [prepareOptions])

  // Input Management
  const handleInputChange = (event) => {
    onChange(event.target.value)
    if (!multiselect) {
      setIsShowingOptions(true)
      setSearchText(event.target.value || '')
      prepareOptions()
    } else {
      setSearchText(event.target.value || '')
      prepareOptions()
    }
  }

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
    setIsShowingOptions(true)
    setPreferredOption(null)
  }, [])

  const handleInputBlur = useCallback(
    (nextValue) => {
      setIsFocused(false)
      multiselect && callUpdate(nextValue)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [callUpdate, multiselect]
  )

  const handleInputClear = useCallback(() => {
    onChange('')
    setSearchText('')
    prepareOptions()
    autocompleteInputRef.current.focus()
    if (!multiselect) {
      callUpdate(null)
    }
  }, [callUpdate, multiselect, onChange, prepareOptions])

  const handleOptionRender = useCallback(
    (option) => renderOption(option, renderOption),
    [renderOption]
  )

  const screenReaderText = useMemo(() => {
    if (!isShowingOptions) {
      return ''
    } else if (isShowingOptions && visibleOptions.length !== 0) {
      return `${visibleOptions.length} results showing. Use up and down arrow to review options and enter to select.`
    } else {
      return noResultsMessageComputed
    }
  }, [isShowingOptions, noResultsMessageComputed, visibleOptions.length])

  return (
    <React.Fragment>
      {/* overlay to close options on click elsewhere */}
      <Overlay
        isVisible={isShowingOptions}
        onClick={handleOptionsClose}
        className={'--autocomplete'}
      />
      {/* normal form label */}
      <Input.Label
        error={error}
        disabled={disabled}
        required={required}
        htmlFor={id}
        data-testid={dataTestId ? `${dataTestId}_label` : null}
        rightContent={rightContent}
      >
        {label}
      </Input.Label>
      {/* fake input container with outline style */}
      <div
        data-testid={dataTestId}
        className={finalClasses}
        onKeyDown={handleKeyDown}
      >
        {/* screenreader will read out alerts onupdate */}
        <div
          role="alert"
          aria-live="polite"
          aria-label={screenReaderText}
          className="hc-sr-only"
        />
        {/* inputs are generated above, single and multi select are different */}
        <AutocompleteInput
          id={id}
          data-testid={dataTestId}
          selectedOption={preferredOption}
          disabled={disabled}
          searchText={searchText}
          renderChip={handleChipRender}
          value={value}
          maxLength={maxLength}
          placeholder={displayPlaceholder && placeholder ? placeholder : ''}
          onChange={handleInputChange}
          ref={autocompleteInputRef}
          onFocus={handleInputFocus}
          onBlur={() => handleInputBlur(value)}
          isExpanded={isShowingOptions}
        />
        {/* icons for all states */}
        <IconArea
          showSearchIcon={showSearchIcon}
          isLoading={isLoading}
          disabled={disabled}
          searchText={searchText}
          onClear={handleInputClear}
        />
        {/* options dropdown */}
        <OptionsList
          alignOptionsList={alignOptionsList}
          id={id}
          optionsListNoMinWidth={optionsListNoMinWidth}
          data-testid={dataTestId}
          optionSize={optionHeight}
          options={visibleOptions}
          selectedOption={preferredOption}
          isLoading={isLoading}
          isShowingOptions={isShowingOptions}
          onOptionClick={handleOptionSelect}
          renderOption={handleOptionRender}
          noResultsMessage={noResultsMessageComputed}
        />
      </div>
      {!disableFieldInfo && (
        <InputInfo disabled={disabled} error={error}>
          {error ? errorText : hint}
        </InputInfo>
      )}
    </React.Fragment>
  )
}

Autocomplete.displayName = 'Autocomplete'
export default Autocomplete

// Prepackaged filters
export const filters = {
  contains: containsFilter,
  fuzzy: fuzzyFilter,
  none: noneFilter,
  startsWith: startsWithFilter,
}

Autocomplete.filters = filters
