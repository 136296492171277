import axios from 'axios'

import appConfig from '../../config/appConfig'
import emailVerificationMapper from '../../mappers/emailVerificationMapper'

export default class PlatformApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_platform_api = () => {
    this.client = axios.create({
      baseURL: this.config.platform.url,
      timeout: 31000,
    })
    return this.client
  }

  getEmailData = async (query) => {
    query = query.toString() + `&key=${this.config.platform.key}`
    return await this.init_platform_api()
      .get(this.config.platform.url + `?email_ids=${query}`)
      .then((res) => emailVerificationMapper(res.data))
      .catch((e) => console.log(e))
  }

  getContactsByEntitlement = async (query) => {
    query = query.toString() + `&key=${this.config.platform.key}`
    return await this.init_platform_api()
      .get(
        this.config.platform.ldap +
          `?name=${query}&key=${this.config.platform.key}`
      )
      .then((res) => res.data)
      .catch((e) => console.log(e))
  }
}
