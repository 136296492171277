import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import pageMapper from '../mappers/pageMapper'
import ReplaceFirst from '../util/ReplaceFirst'

const useGetPage = (setLoading, setWeeklyAd, setToast) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.page)
  const [id, setId] = useState(null)

  useEffect(() => {
    let mounted = true
    const getPage = async () => {
      mounted && setLoading(true)
      try {
        let response = await axios.get(url.current + id)
        let thisPage = pageMapper(response.data)

        mounted &&
          setWeeklyAd((w) => {
            ReplaceFirst(w.pages, id, thisPage)
            return w
          })
      } catch (e) {
        if (setToast && mounted) {
          setToast({
            heading: 'Error',
            isVisible: true,
            message: 'Something went wrong, try refreshing the page.',
            type: 'error',
          })
        }
      }
      mounted && setLoading(false)
      mounted && setId(null)
    }

    if (id !== null) {
      getPage()
    }
    return () => {
      mounted = false
    }
  }, [id, setLoading, setWeeklyAd, setToast])

  return setId
}
export default useGetPage
