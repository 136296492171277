import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import weeklyAdMapper from '../mappers/weeklyAdMapper'

const useGetWeeklyAd = (wadId, setLoading, setToast) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.weeklyAd + wadId)
  const [wad, setWad] = useState({})

  useEffect(() => {
    let mounted = true
    const getWad = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(url.current)
        mounted && setWad(weeklyAdMapper(response.data))
      } catch (e) {
        console.log(e)
        if (setToast && mounted) {
          setToast({
            heading: 'Error',
            isVisible: true,
            message: 'Unable to get weekly ad, try refreshing',
            type: 'error',
          })
        }
      }
      mounted && setLoading(false)
    }

    getWad()
    return () => {
      mounted = false
    }
  }, [setLoading, setToast])

  return [wad, setWad]
}
export default useGetWeeklyAd
