import { ExpandableSection, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '@praxis/component-auth'
import { useState, useEffect } from 'react'

import CommentApi from '../../api/pop-weekly-ad/CommentsApi'
import { Email } from '../../util/AuthUtils'
import '../../scss/CutComment.scss'

import AddComment from './AddComment'
import CommentItem from './CommentItem'

const CutComment = (props) => {
  const api = new CommentApi()
  const { session } = useAuth()
  const currentUserName = session?.userInfo?.fullName
  const currentUserEmail = Email()
  const { cut, page, story, eventWeek, hasEditPrivileges } = props
  const [expanded, setExpandState] = useState(true)
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState([])
  const [showAddComment, setShowAddComment] = useState(false)
  const [editCommentId, setEditCommentId] = useState(null)

  useEffect(() => {
    if (expanded) {
      getComments()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAddCommentClose = () => setShowAddComment(false)

  const onAddCommentOpen = (evt) => {
    evt.stopPropagation()
    setShowAddComment(true)
  }

  const onAddComment = (commentText, toEmailList) => {
    api
      .postComment({
        commentText,
        createdBy: currentUserName,
        cutId: cut.id,
        cutNumber: cut.priority + 1,
        eventWeek: eventWeek,
        initiatorEmailAddress: currentUserEmail,
        link: window.location.href,
        page: page.bookOnePageNumber,
        storyNumber: story.priority + 1,
        toEmailList: toEmailList,
      })
      .then(() => {
        getComments()
      })
  }

  const getComments = () => {
    setLoading(true)
    api.getComments(cut.id).then((comments) => {
      setComments(comments)
      setLoading(false)
    })
  }

  const replaceComment = (array, id, obj) => {
    const idx = array.findIndex((i) => i.id === id)

    if (idx !== -1) {
      array.splice(idx, 1, obj)
    }

    return array
  }

  const updateComment = (commentId, commentData) => {
    setLoading(true)
    api.putComment(commentId, commentData).then((updatedComment) => {
      const newComments = replaceComment(
        [...comments],
        commentId,
        updatedComment
      )
      setComments(newComments)
      setLoading(false)
    })
  }

  const deleteComment = (commentId) => {
    setLoading(true)
    api.deleteComment(commentId).then(() => {
      const newComments = comments.filter((comment) => comment.id !== commentId)

      setComments(newComments)
      setLoading(false)
    })
  }

  const onExpand = () => {
    if (!expanded) {
      getComments()
    }
    setExpandState((val) => !val)
  }

  return (
    <>
      <ExpandableSection padding="none" expanded={expanded} onExpand={onExpand}>
        <Grid.Container align="center">
          <Grid.Item xs={11} className="hc-ws-pre-wrap">
            Comments {expanded ? `(${comments.length})` : ''}
          </Grid.Item>
          <Grid.Item xs={1}>
            <Button
              className="C-MenuButton"
              onClick={onAddCommentOpen}
              iconOnly
              aria-label="create comment"
              disabled={!hasEditPrivileges}
            >
              <FontAwesomeIcon color="black" icon={faComment} />
            </Button>
          </Grid.Item>
        </Grid.Container>
        <ExpandableSection.Content className="hc-mb-dense hc-pb-dense hc-ml-dense hc-pl-dense hc-mr-dense hc-pr-dense">
          <Grid.Container
            direction="column"
            align="flex-start"
            className="hc-ml-xs hc-mt-xs"
          >
            {loading
              ? 'Loading....'
              : comments.length > 0 &&
                comments.map((comment) => (
                  <CommentItem
                    key={comment.id}
                    currentUserName={currentUserName}
                    comment={comment}
                    editCommentId={editCommentId}
                    setEditCommentId={setEditCommentId}
                    updateComment={updateComment}
                    deleteComment={deleteComment}
                    hasEditPrivileges={hasEditPrivileges}
                  />
                ))}
          </Grid.Container>
        </ExpandableSection.Content>
      </ExpandableSection>
      {showAddComment && (
        <AddComment
          handleDialogClose={onAddCommentClose}
          onAddComment={onAddComment}
        />
      )}
    </>
  )
}
export default CutComment
