import {
  Card,
  Grid,
  Heading,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import { useContext, useEffect, useState, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import './../scss/ProjectSummary.scss'

import ProjectApi from '../../api/creative-hub/ProjectApi'
import { BreadcrumbContext } from '../../context/Context'
import { sortPyramids } from '../../creativeHubGlobal/util/CreativeHubGlobalUtil'

const ProjectSummary = (props) => {
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const { search } = useLocation()
  const projectApi = useMemo(() => new ProjectApi(), [])
  const query = useMemo(() => new URLSearchParams(search), [search])

  const [loading, setLoading] = useState(false)
  const [summaryData, setSummaryData] = useState(false)
  const [selectedProjectId] = useState(useParams().projectId)

  const currentParams = Object.fromEntries(query)
  const selectedChannels = currentParams?.channelIds || []

  const { project_description, start_date, end_date, pyramids } = summaryData
  const sortedPyramids = sortPyramids(pyramids)

  const fetchProjectSummary = (projectId, selectedChannels) => {
    setLoading(true)
    projectApi
      .fetchProjectSummary(projectId, selectedChannels)
      .then((data) => {
        setLoading(false)
        setSummaryData(data)
        setBreadcrumbContext({
          row: {
            project_description: data.project_description,
            project_id: selectedProjectId,
          },
          setBreadcrumbContext,
        })
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (selectedProjectId) {
      fetchProjectSummary(selectedProjectId, selectedChannels)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId])

  return selectedProjectId ? (
    <div data-testid="projectSummaryPage">
      {loading ? (
        <Grid.Container>
          <Grid.Item className="hc-pt-expanded" xs={12}>
            <ProgressBar indeterminate />
          </Grid.Item>
        </Grid.Container>
      ) : (
        <Card className="summaryContainer">
          <Grid.Item
            className="hc-pt-expanded"
            xs={12}
            key="project_description"
          >
            <Heading size={5}>
              Project Description: {project_description}
            </Heading>
          </Grid.Item>
          <Grid.Item className="projectDate" key="run_dates">
            Run Dates: {start_date} - {end_date}
          </Grid.Item>
          {sortedPyramids?.map((pyramid = {}, ind) => {
            const {
              pyramid_type = '',
              pyramid_name = '',
              priority_order = 0,
              stories = [],
            } = pyramid
            return (
              <Grid.Container
                spacing="none"
                align="center"
                justify="flex-start"
                xs={12}
              >
                <Heading size={5}>
                  {pyramid_type === 'ent'
                    ? 'EGO'
                    : `${pyramid_type?.toUpperCase()} MP`}
                  {priority_order}: {pyramid_name}
                </Heading>
                <Grid.Item xs={12} key={`${priority_order}-${ind}`}>
                  {stories?.map((story = {}, ind) => {
                    const {
                      story_order = 0,
                      story_name = '',
                      umbrellas = [],
                    } = story
                    return (
                      <Grid.Item
                        className="offerName"
                        key={`${story_order}-${ind}`}
                      >
                        <Grid.Item>
                          <Heading size={6}>
                            {' '}
                            S{story_order}: {story_name}
                          </Heading>
                        </Grid.Item>
                        {umbrellas?.map((umbrella = {}, ind) => {
                          const {
                            umbrella_name = '',
                            offers = [],
                            umbrella_order = 0,
                          } = umbrella
                          return (
                            <Grid.Item
                              className="offerName"
                              key={`${umbrella_order}-${ind}`}
                            >
                              <Grid.Item>
                                {' '}
                                U{umbrella_order}: {umbrella_name}
                              </Grid.Item>
                              {offers?.map((offer, ind) => {
                                const {
                                  offer_name = '',
                                  offer_order = 0,
                                  division_name = '',
                                } = offer
                                return (
                                  <Grid.Item
                                    className="offerName"
                                    key={`${offer_order}-${ind}`}
                                  >
                                    O{offer_order}: {offer_name} :{' '}
                                    {division_name}
                                  </Grid.Item>
                                )
                              })}
                            </Grid.Item>
                          )
                        })}
                      </Grid.Item>
                    )
                  })}
                </Grid.Item>
              </Grid.Container>
            )
          })}
        </Card>
      )}
    </div>
  ) : null
}
export default ProjectSummary
