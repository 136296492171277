import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import appConfig from '../config/appConfig'

const useGetAllCategories = (setLoading, setDWA) => {
  const config = appConfig()
  const wadId = useParams().wadId
  const [ready, setReady] = useState(null)
  const url = config.backend.root + config.backend.dwa + wadId

  useEffect(() => {
    let mounted = true
    const getAllCategories = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(url + '/all')
        mounted &&
          setDWA((w) => {
            mapResponseToDWA(w, response.data)
            return w
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
    }
    if (ready) {
      getAllCategories()
    }

    return () => {}
  }, [setLoading, url, ready, setDWA])

  return [setReady]
}

const mapResponseToDWA = (dwa, response) => {
  dwa.forEach((category) => {
    let dbCategory = response.find(
      (responseCategory) => responseCategory.categoryId === category.categoryId
    )
    category.id = dbCategory.id
    category.eaNotes = dbCategory.eaNotes
  })
}
export default useGetAllCategories
