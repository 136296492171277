import {
  Button,
  Card,
  Chip,
  ExpandableSection,
  Grid,
} from '@enterprise-ui/canvas-ui-react'
import { faPencilAlt, faGlasses } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ExpandableDummy = (props) => {
  const {
    page,
    dummy,
    cut,
    setEditDummy,
    expands,
    setExpands,
    ids,
    hasEditPrivileges,
  } = props

  if (expands.dummy[dummy.id] === undefined) {
    expands.dummy[dummy.id] = expands.page[page.id]
  }

  const expanded = expands.dummy[dummy.id]

  return (
    <Card className="hc-ma-dense hc-bg-contrast hc-clr-contrast-weak">
      <Grid.Container spacing="none" align="center">
        <Grid.Item xs={1} />
        <Grid.Item xs={11}>
          <ExpandableSection
            padding="none"
            expanded={expanded}
            onExpand={() => {
              let exp = Object.assign({}, expands)
              exp.dummy[dummy.id] = !expanded
              setExpands(exp)
            }}
          >
            <Grid.Container align="center" spacing="dense">
              <Grid.Item
                xs={11}
              >{`Placeholder Item: ${dummy.description}`}</Grid.Item>
              <Grid.Item xs={1}>
                <Button
                  iconOnly
                  onClick={(event) => {
                    event.stopPropagation()
                    setEditDummy({
                      body: {
                        description: dummy.description,
                        id: dummy.id,
                        keyNotes: dummy.keyNotes,
                        lastUpdatedTimestamp: dummy.lastUpdatedTimestamp,
                        regPriceCallout: dummy.regPriceCallout,
                        regUnitRetail: dummy.regUnitRetail,
                      },
                      cutLastUpdatedTimestamp: cut.lastUpdatedTimestamp,
                      hasEditPrivileges,
                      show: true,
                      ...ids,
                    })
                  }}
                >
                  <FontAwesomeIcon
                    icon={hasEditPrivileges ? faPencilAlt : faGlasses}
                    color="#000000"
                  />
                </Button>
              </Grid.Item>
            </Grid.Container>
            <ExpandableSection.Content className="hc-mb-dense hc-pb-dense hc-ml-dense hc-pl-dense hc-mr-dense hc-pr-dense">
              <Grid.Container align="center">
                <Grid.Item xs={6}>Reg $: {dummy.regUnitRetail}</Grid.Item>
                <Grid.Item xs={6}>
                  <Chip
                    style={{
                      backgroundColor: dummy.regPriceCallout ? 'red' : 'grey',
                      border: '0px',
                      color: 'white',
                    }}
                    size="dense"
                  >
                    {dummy.regPriceCallout ? 'Reg $' : 'No Reg $'}
                  </Chip>
                </Grid.Item>
                <Grid.Item xs={12}>Keynotes: {dummy.keyNotes}</Grid.Item>
              </Grid.Container>
            </ExpandableSection.Content>
          </ExpandableSection>
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}
export default ExpandableDummy
