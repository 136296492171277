import { Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

let addPropItemSchema = yup.object().shape({
  add_prop_description: yup.string().required('Required'),
  add_prop_dpci: yup
    .string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, {
      message: 'DPCI in format 000-00-0000',
    })
    .required('DPCI is required'),
  add_prop_sample: yup
    .string()
    .oneOf(['inventory', 'turnIn', 'missing'])
    .required('Required'),
  add_prop_sampleId: yup.string().when('sample', {
    is: (sample) => sample === 'inventory',
    otherwise: yup.string(),
    then: yup.string().required('SampleID required when status: inventory'),
  }),
})

const AddPropItem = (props) => {
  const { addPropItem, setAddPropItem, postPropItem, closeEditCutModal } = props

  const wipeState = () => {
    setAddPropItem({
      body: {},
      cutId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={addPropItemSchema}
      initialValues={{
        add_prop_description: '',
        add_prop_dpci: '',
        add_prop_keyNotes: '',
        add_prop_sample: '',
        add_prop_sampleId: '',
      }}
      onSubmit={(values, funcs) => {
        postPropItem({
          body: {
            cutId: addPropItem.cutId,
            description: values.add_prop_description,
            dpci: values.add_prop_dpci,
            keyNotes: values.add_prop_keyNotes,
            sample: values.add_prop_sample,
            sampleId: values.add_prop_sampleId,
          },
          cutId: addPropItem.cutId,
          pageId: addPropItem.pageId,
          storyId: addPropItem.storyId,
        })

        wipeState()
        closeEditCutModal()
        funcs.resetForm()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={addPropItem.show}
            headingText={`Add Prop Item`}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <Grid.Container align="center" spacing="dense">
                <Grid.Item xs={12}>
                  <Form.Field
                    id="add_prop_description"
                    value={values.add_prop_description}
                    label="Prop Item Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.add_prop_description &&
                      errors.add_prop_description
                    }
                    errorText={errors.add_prop_description}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    id="add_prop_dpci"
                    value={values.add_prop_dpci}
                    label="DPCI"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.add_prop_dpci && errors.add_prop_dpci}
                    errorText={errors.add_prop_dpci}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    type="select"
                    id="add_prop_sample"
                    label="Sample Status"
                    value={values.add_prop_sample}
                    onUpdate={setFieldValue}
                    options={[
                      { label: '', value: '' },
                      { label: 'Inventory', value: 'inventory' },
                      { label: 'Turn-in', value: 'turnIn' },
                      { label: 'Missing', value: 'missing' },
                    ]}
                    onBlur={handleBlur}
                    error={touched.add_prop_sample && errors.add_prop_sample}
                    errorText={errors.add_prop_sample}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="add_prop_sampleId"
                    value={values.add_prop_sampleId}
                    label="Sample ID"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.add_prop_sampleId && errors.add_prop_sampleId
                    }
                    errorText={errors.add_prop_sampleId}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    type="textarea"
                    grow
                    id="add_prop_keyNotes"
                    value={values.add_prop_keyNotes}
                    label="Key Notes"
                    onChange={handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  )
}
export default AddPropItem
