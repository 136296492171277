import appConfig from '../config/appConfig'

const config = appConfig()

export function CATALOG_PAGINATION_EXPORT_URL(catalogId) {
  return (
    config.backend.catalog +
    config.backend.catalogs.exportCatalog +
    catalogId +
    '?key=' +
    config.catapi.apikey
  )
}

export function PRODUCT_EXPORT_URL(catalogId) {
  return config.backend.catalog + `exports/products/${catalogId}?`
}

export function VENDOR_EXPORT_URL(catalogId) {
  return config.backend.catalog + `exports/vendors/${catalogId}?`
}

export function CATALOG_SAMPLE_EXPORT_URL(catalogId) {
  return (
    config.backend.catalog +
    `exports/products/creative/${catalogId}?key=` +
    config.catapi.apikey
  )
}

export function HISTORY_EXPORT_URL(catalogId) {
  return config.backend.catalog + `exports/history/${catalogId}?`
}
