import {
  Button,
  Divider,
  Input,
  Grid,
  Heading,
  Image,
  Modal,
  ProgressBar,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import moment from 'moment'
import { useState, useEffect } from 'react'

import '../scss/EditItemImages.scss'
import AssetHubApi from '../api/pop-weekly-ad/AssetHubApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import noImagePlaceholder from '../media/NoImagePlaceholder.png'

const assetHubApi = new AssetHubApi()

const EditItemImages = (props) => {
  const { parentSetFieldValue, parentValues, editItem, disabled } = props
  const [alternateImages, setAlternateImages] = useState()
  const [isSelectImagesVisible, setSelectImagesVisible] = useState(false)
  const [primaryImageSelected, setPrimaryImageSelected] = useState()
  const [primaryDBImage, setPrimaryDBImage] = useState()
  const [weeklyAdToggle, setWeeklyAdToggle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentActiveTab, setCurrentActiveTab] = useState('primaryImage')
  const [originalTCINImageUrl, setOriginalTCINImageUrl] = useState()

  useEffect(() => {
    setPrimaryImageSelected(
      parentValues.edit_item_image_file_name
        ? parentValues.edit_item_image_file_name
        : parentValues.edit_item_original_image
    )
    setPrimaryDBImage(parentValues.edit_item_image_file_name)
    setOriginalTCINImageUrl(parentValues.edit_item_original_image)
  }, [editItem, parentValues])

  const fulfilledAssetHub = async (page = 0, weeklyAdToggle = false) => {
    setLoading(true)
    await assetHubApi
      .postSearch(editItem.body.dpci, page, 21, weeklyAdToggle)
      .then((res) => {
        setAlternateImages(createAlternateImageArray(res.search, parentValues))
      })
      .catch((err) => {
        setAlternateImages(createAlternateImageArray([], parentValues))
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const cleanup = () => {
    setWeeklyAdToggle(false)
    setAlternateImages()
    setCurrentActiveTab('primaryImage')
    setSelectImagesVisible(false)
  }

  const findImage = (alternateImages) =>
    alternateImages?.find((result) => primaryDBImage === result.imageUrl)

  const formatAssetHubResults = (alternateImages) =>
    alternateImages?.map(
      (result) =>
        ({
          assetKey: result.assetId,
          deleted: true,
          fileName: result.fileName,
          id: null,
          imageUrl: assetHubApi.storageUrlKey(
            result.storageUrl,
            result.fileName,
            result.importDate
          ),
          importDate: result.importDate,
          itemId: editItem.body.id,
          storageUrl: result.storageUrl,
        }) || []
    )

  const createAlternateImageArray = (alternateImages, parentValues) => {
    let imageArray = formatAssetHubResults(alternateImages)
    imageArray.forEach((image) => {
      let found = parentValues?.edit_item_alternate_images?.find(
        (it) => it.imageUrl === image.imageUrl
      )
      if (found) {
        image.id = found.id
        image.deleted = found.deleted
      }
    })
    return imageArray
  }

  const setImageChecked = (assetKey, value) => {
    let newImagesArray = Array.from(alternateImages)
    newImagesArray.forEach((image) => {
      if (image.assetKey === assetKey) {
        image.deleted = value
      }
    })
    setAlternateImages(newImagesArray)
  }

  const handleDownload = () => {
    //assetHub alternate Images
    alternateImages.forEach((image) => {
      if (!image.deleted) {
        downloadImage(image.imageUrl, image.fileName)
      }
    })
    //assetHub image as primary image
    if (
      primaryImageSelected.match('(/assethub_assets/[^ "]+$)+') ||
      primaryImageSelected.match('(/vault_digital_assets/[^ "]+$)+')
    ) {
      const foundImage = alternateImages?.find(
        (result) => primaryImageSelected === result.imageUrl
      )
      downloadImage(foundImage.imageUrl, foundImage.fileName)
    }
  }

  const downloadImage = (url, filename) => {
    if (url.match('(https://target.scene7.com/is/image/Target/[^ "]+$)+')) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = filename
          a.click()
        })
    } else {
      window.open(url + '&is_download=true', '_blank')
    }
  }

  return (
    <div className="hc-pa-normal">
      <Button
        type="secondary"
        onClick={() => {
          setSelectImagesVisible(true)
          fulfilledAssetHub()
        }}
        disabled={!editItem.body.dpci || disabled}
      >
        Select Item Images
      </Button>
      <Formik
        initialValues={{
          alternateImages: alternateImages || [],
        }}
        onSubmit={(values) => {
          parentSetFieldValue('edit_item_image', primaryImageSelected)
          parentSetFieldValue('edit_item_image_file_name', primaryImageSelected)
          parentSetFieldValue(
            'edit_item_alternate_images',
            alternateImages.filter(
              (image) => !(image.deleted === true && image.id === null)
            )
          )
          cleanup()
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Modal
            isVisible={isSelectImagesVisible && alternateImages}
            onRefuse={() => {
              cleanup()
            }}
            className="larger_modal"
          >
            <Tabs
              activeTab={currentActiveTab}
              onTabSelect={(event, tab) => {
                setCurrentActiveTab(tab.name)
              }}
            >
              <Tabs.Item name="primaryImage">Primary Image</Tabs.Item>
              <Tabs.Item name="alternateImages">Alternate Images</Tabs.Item>
              <Divider />
            </Tabs>
            <div className="hc-pa-normal image_modal">
              <Heading>
                {currentActiveTab === 'primaryImage'
                  ? `Select Primary Image`
                  : `Select Alternate Images`}
              </Heading>
              <Heading size={4}>{`DPCI ${editItem.body.dpci}`}</Heading>
              <Input.Toggle
                id="assethub_non_weeklyad_toggle"
                label="Non-Weekly Ad Images Search"
                checked={weeklyAdToggle}
                onChange={() => {
                  fulfilledAssetHub(0, !weeklyAdToggle)
                  setWeeklyAdToggle(!weeklyAdToggle)
                }}
              />
              {currentActiveTab === 'primaryImage' ? (
                //Primary Image Tab
                <>
                  <div className="hc-pa-normal">
                    <Grid.Container className="imageGridContainer" align="top">
                      {originalTCINImageUrl ? (
                        <Grid.Item
                          key="originalImage"
                          className="imageContainer"
                        >
                          <Grid.Container className="imageInfo">
                            <Image
                              position="top-right"
                              width={'100%'}
                              height={'100%'}
                              type={'contain'}
                              src={originalTCINImageUrl}
                              alt="filename"
                            />
                            <Grid.Item xs="6">
                              <Input.Checkbox
                                id="originalImageUrl"
                                className="image_check"
                                disabled={
                                  primaryImageSelected &&
                                  primaryImageSelected !== originalTCINImageUrl
                                }
                                onChange={(event) => {
                                  if (event.target.checked === true) {
                                    setPrimaryImageSelected(
                                      originalTCINImageUrl
                                    )
                                  } else {
                                    setPrimaryImageSelected(null)
                                  }
                                }}
                                checked={
                                  primaryImageSelected &&
                                  primaryImageSelected === originalTCINImageUrl
                                }
                              />
                            </Grid.Item>
                            <Grid.Item xs="6">
                              <div
                                className="downloadButton"
                                onClick={() =>
                                  downloadImage(
                                    originalTCINImageUrl,
                                    originalTCINImageUrl.split('/').pop() +
                                      '.jpg'
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  className="download"
                                  icon={faFileDownload}
                                  size="2x"
                                  color="grey"
                                />
                              </div>
                            </Grid.Item>
                            <p className="caption">TCIN Original Image</p>
                          </Grid.Container>
                        </Grid.Item>
                      ) : (
                        <Grid.Item
                          key="originalImage"
                          className="imageContainer"
                        >
                          <Grid.Container className="imageInfo">
                            <Image
                              className="noImageThumbnail"
                              position="top-left"
                              width={'100%'}
                              height={'100%'}
                              type={'contain'}
                              src={noImagePlaceholder}
                              alt="filename"
                            />
                            <Grid.Item xs="6">
                              <Input.Checkbox
                                id="originalImageUrl"
                                className="image_check"
                                disabled={true}
                              />
                            </Grid.Item>
                            <p className="caption">TCIN Original Image</p>
                          </Grid.Container>
                        </Grid.Item>
                      )}
                      {loading ? (
                        <Grid.Item className="hc-pt-expanded" xs={12}>
                          <ProgressBar indeterminate />
                        </Grid.Item>
                      ) : !loading && alternateImages?.length > 0 ? (
                        <>
                          {!findImage(alternateImages) &&
                            primaryDBImage !== originalTCINImageUrl &&
                            (primaryImageSelected !== '' ||
                              primaryImageSelected) &&
                            primaryDBImage && (
                              <>
                                <Grid.Item
                                  key="primaryImage"
                                  className="imageContainer"
                                >
                                  <Grid.Container className="imageInfo">
                                    <Image
                                      position="top-right"
                                      width={'100%'}
                                      height={'100%'}
                                      type={'contain'}
                                      src={primaryDBImage}
                                      alt="filename"
                                    />
                                    <Grid.Item xs="6">
                                      <Input.Checkbox
                                        id="primarySelectedUrl"
                                        className="image_check"
                                        disabled={
                                          primaryImageSelected &&
                                          primaryImageSelected !==
                                            primaryDBImage
                                        }
                                        onChange={(event) => {
                                          if (event.target.checked === true) {
                                            setPrimaryImageSelected(
                                              primaryDBImage
                                            )
                                          } else {
                                            setPrimaryImageSelected(null)
                                          }
                                        }}
                                        checked={
                                          primaryImageSelected &&
                                          primaryImageSelected ===
                                            primaryDBImage
                                        }
                                      />
                                    </Grid.Item>
                                    <Grid.Item xs="6">
                                      <div
                                        className="downloadButton"
                                        onClick={() =>
                                          downloadImage(
                                            primaryDBImage,
                                            primaryDBImage.split('/').pop() +
                                              '.jpg'
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="download"
                                          icon={faFileDownload}
                                          size="2x"
                                          color="grey"
                                        />
                                      </div>
                                    </Grid.Item>
                                    <p className="caption">
                                      Current Primary Image
                                    </p>
                                    <div className="break" />
                                    {primaryDBImage ? (
                                      <p className="caption">
                                        File Name:
                                        <br />
                                        {primaryDBImage}
                                      </p>
                                    ) : null}
                                  </Grid.Container>
                                </Grid.Item>
                              </>
                            )}
                          {alternateImages?.map((result) => (
                            <Grid.Item
                              key={result.assetKey}
                              className="imageContainer"
                            >
                              <Grid.Container className="imageInfo">
                                <Image
                                  position="top-right"
                                  width={'100%'}
                                  height={'100%'}
                                  type={'contain'}
                                  src={result.imageUrl}
                                  alt={result.fileName}
                                />
                                <Grid.Item xs="6">
                                  <Input.Checkbox
                                    id={result.assetKey}
                                    className="image_check"
                                    disabled={
                                      primaryImageSelected &&
                                      primaryImageSelected !== result.imageUrl
                                    }
                                    onChange={(event) => {
                                      if (event.target.checked === true) {
                                        setPrimaryImageSelected(result.imageUrl)
                                      } else {
                                        setPrimaryImageSelected(null)
                                      }
                                    }}
                                    checked={
                                      primaryImageSelected &&
                                      primaryImageSelected === result.imageUrl
                                    }
                                  />
                                </Grid.Item>
                                <Grid.Item xs="6">
                                  <div
                                    className="downloadButton"
                                    onClick={() =>
                                      downloadImage(
                                        result.imageUrl,
                                        result.fileName
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="download"
                                      icon={faFileDownload}
                                      size="2x"
                                      color="grey"
                                    />
                                  </div>
                                </Grid.Item>
                                {result.importDate ? (
                                  <p className="caption">
                                    Upload Date:
                                    <br />
                                    {moment(result.importDate).format(
                                      'MMM D, YYYY h:mm a'
                                    )}
                                  </p>
                                ) : null}
                                <div className="break" />
                                {result.fileName ? (
                                  <p className="caption">
                                    File Name:
                                    <br />
                                    {result.fileName}
                                  </p>
                                ) : null}
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </>
                      ) : (
                        <>
                          {primaryImageSelected !== originalTCINImageUrl &&
                            primaryDBImage !== originalTCINImageUrl &&
                            (primaryImageSelected !== '' ||
                              primaryImageSelected) &&
                            primaryDBImage && (
                              <Grid.Item
                                key="primaryImage"
                                className="imageContainer"
                              >
                                <Grid.Container className="imageInfo">
                                  <Image
                                    position="top-right"
                                    width={'100%'}
                                    height={'100%'}
                                    type={'contain'}
                                    src={primaryDBImage}
                                    alt="filename"
                                  />
                                  <Grid.Item xs="6">
                                    <Input.Checkbox
                                      id="primarySelectedUrl"
                                      className="image_check"
                                      disabled={
                                        primaryImageSelected &&
                                        primaryImageSelected !== primaryDBImage
                                      }
                                      onChange={(event) => {
                                        if (event.target.checked === true) {
                                          setPrimaryImageSelected(
                                            primaryDBImage
                                          )
                                        } else {
                                          setPrimaryImageSelected(null)
                                        }
                                      }}
                                      checked={
                                        primaryImageSelected &&
                                        primaryImageSelected === primaryDBImage
                                      }
                                    />
                                  </Grid.Item>
                                  <Grid.Item xs="6">
                                    <div
                                      className="downloadButton"
                                      onClick={() =>
                                        downloadImage(
                                          primaryDBImage,
                                          primaryDBImage
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className="download"
                                        icon={faFileDownload}
                                        size="2x"
                                        color="grey"
                                      />
                                    </div>
                                  </Grid.Item>
                                  <p className="caption">
                                    Current Primary Image
                                  </p>
                                  <div className="break" />
                                  {primaryDBImage ? (
                                    <p className="caption">
                                      File Name:
                                      <br />
                                      {primaryDBImage}
                                    </p>
                                  ) : null}
                                </Grid.Container>
                              </Grid.Item>
                            )}
                          <Grid.Container>
                            <div className="hc-pa-normal noAssets">
                              No AssetHub Results Found
                            </div>
                          </Grid.Container>
                        </>
                      )}
                    </Grid.Container>
                  </div>
                </>
              ) : (
                //Alternate Images Tab
                <>
                  <div className="hc-pa-normal">
                    <Grid.Container className="imageGridContainer">
                      {loading ? (
                        <Grid.Item className="hc-pt-expanded" xs={12}>
                          <ProgressBar indeterminate />
                        </Grid.Item>
                      ) : !loading && alternateImages?.length > 0 ? (
                        <>
                          {alternateImages?.map((result) => (
                            <Grid.Item
                              className="imageContainer"
                              key={result.assetKey}
                            >
                              <Grid.Container className="imageInfo">
                                <Image
                                  position="top-right"
                                  width={'100%'}
                                  height={'100%'}
                                  type={'contain'}
                                  src={result.imageUrl}
                                  alt={result.fileName}
                                />
                                <Grid.Item xs="6">
                                  <Input.Checkbox
                                    id={result.assetKey}
                                    className="image_check"
                                    onChange={(event) => {
                                      event.target.checked === true
                                        ? setImageChecked(
                                            result.assetKey,
                                            false
                                          )
                                        : setImageChecked(result.assetKey, true)
                                    }}
                                    checked={!result.deleted}
                                  />
                                </Grid.Item>
                                <Grid.Item xs="6">
                                  <div
                                    className="downloadButton"
                                    onClick={() =>
                                      downloadImage(
                                        result.imageUrl,
                                        result.fileName
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="download"
                                      icon={faFileDownload}
                                      size="2x"
                                      color="grey"
                                    />
                                  </div>
                                </Grid.Item>
                                {result.importDate ? (
                                  <p className="caption">
                                    Upload Date:
                                    <br />
                                    {moment(result.importDate).format(
                                      'MMM D, YYYY h:mm a'
                                    )}
                                  </p>
                                ) : null}
                                <div className="break" />
                                {result.fileName ? (
                                  <p className="caption">
                                    File Name:
                                    <br />
                                    {result.fileName}
                                  </p>
                                ) : null}
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </>
                      ) : (
                        <>
                          <Grid.Container>
                            <div className="hc-pa-normal noAssets">
                              No AssetHub Results Found
                            </div>
                          </Grid.Container>
                        </>
                      )}
                    </Grid.Container>
                  </div>
                </>
              )}
              <Grid.Container>
                <div className="hc-pa-normal">
                  <CancelSubmitButtons
                    handleDownload={handleDownload}
                    downloadText="Download Selected Images"
                    showDownload={true}
                    handleDialogClose={cleanup}
                    submitDisabled={!primaryImageSelected}
                    handleSubmit={handleSubmit}
                    canDelete={false}
                  />
                </div>
              </Grid.Container>
            </div>
          </Modal>
        )}
      </Formik>
    </div>
  )
}
export default EditItemImages
