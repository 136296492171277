import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import storyMapper from '../mappers/storyMapper'
import ReplaceFirst from '../util/ReplaceFirst'

const usePutStory = (setLoading, setWeeklyAd) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.story)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const putStory = async () => {
      mounted && setLoading(true)
      try {
        const id = request.body.id
        const response = await axios.put(url.current + id, request.body)
        const thisStory = storyMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            ReplaceFirst(
              w.pages.find((page) => page.id === request.pageId).stories,
              id,
              thisStory
            )
            return w
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      putStory()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd])

  return setRequest
}
export default usePutStory
