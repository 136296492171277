import { Button, Grid, GridItem, Heading } from '@enterprise-ui/canvas-ui-react'
import '../scss/Category.scss'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Formik } from 'formik'
import { ModalDrawer } from 'nicollet-react'
import { useState } from 'react'

import { groupBy, missingContacts, unGroup } from '../util/helpers.js'

const MessageContacts = ({
  setIsDrawerOpen,
  isDrawerOpen,
  setMissingFields,
  categoryName,
  contactOptions,
  setContactRolesInfo,
  contactRolesInfo,
  setUpdateCategory,
}) => {
  //const canEdit = role?.some((r) => canEditSystemAndProjectContacts(r))
  const [formUpdates, setFormUpdates] = useState(false)
  const [formValues, setFormValues] = useState()

  const {
    Bcm = [],
    CreativeArt = [],
    CreativeProd = [],
    Copywriter = [],
    Marketing = [],
  } = contactOptions

  return (
    <ModalDrawer
      isOpen={isDrawerOpen}
      iconButtonCloseOnClick={() => setIsDrawerOpen(false)}
      scroll={true}
      onRequestClose={() => {
        if (formUpdates) {
          setContactRolesInfo(unGroup(formValues))
          setUpdateCategory(true)
        }

        setIsDrawerOpen(false)
      }}
      headingText={
        <div>
          <Heading size={3}>{categoryName.toUpperCase()}</Heading>
          <Heading size={5}>Marketing Captains</Heading>
        </div>
      }
    >
      <Formik
        enableReinitialize
        initialValues={groupBy(contactRolesInfo, 'role_name')}
        onSubmit={(values) => {
          setContactRolesInfo(unGroup(values))
          setUpdateCategory(true)
          setIsDrawerOpen(false)
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => {
          setFormValues(values)
          const handleContactFieldUpdate = async (id, value) => {
            try {
              setFormUpdates(true)
              setFieldValue(id, value)
              setMissingFields(missingContacts(values))
            } catch (e) {
              console.log('e', e)
            }
          }

          return (
            <div className="hc-pa-normal">
              <GridItem xs={12}>
                <Autocomplete
                  id={'bcm'}
                  multiselect
                  allowCustomInputValue={true}
                  className="hc-mb-dense hc-mt-dense"
                  // disabled={!canEdit}
                  onUpdate={(id, val) => handleContactFieldUpdate(id, val)}
                  value={values['bcm']}
                  options={Bcm}
                  label={'BCM'}
                  noResultsMessage="Use Outlook Name"
                  error={values['bcm']?.length === 0}
                />
              </GridItem>
              <GridItem xs={12}>
                <Autocomplete
                  id={'ea manager'}
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  // disabled={!canEdit}
                  onUpdate={(id, val) => handleContactFieldUpdate(id, val)}
                  value={values['ea manager']}
                  options={Marketing}
                  label={'E&A Manager'}
                  noResultsMessage="Use Outlook Name"
                  error={values['ea manager']?.length === 0}
                />
              </GridItem>
              <GridItem xs={12}>
                <Autocomplete
                  id={'target creative'}
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  // disabled={!canEdit}
                  onUpdate={(id, val) => handleContactFieldUpdate(id, val)}
                  value={values['target creative']}
                  options={CreativeArt}
                  label={'target creative'}
                  noResultsMessage="Use Outlook Name"
                  error={values['target creative']?.length === 0}
                />
              </GridItem>
              <GridItem xs={12}>
                <Autocomplete
                  id={'art director'}
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  // disabled={!canEdit}
                  onUpdate={(id, val) => handleContactFieldUpdate(id, val)}
                  value={values['art director']}
                  options={CreativeArt}
                  label={'Art Director (AD)'}
                  noResultsMessage="Use Outlook Name"
                  error={values['art director']?.length === 0}
                />
              </GridItem>
              <GridItem xs={12}>
                <Autocomplete
                  id={'promo production design'}
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  // disabled={!canEdit}
                  onUpdate={(id, val) => handleContactFieldUpdate(id, val)}
                  value={values['promo production design']}
                  options={CreativeProd}
                  label={'Promo Production'}
                  noResultsMessage="Use Outlook Name"
                  error={values['promo production design']?.length === 0}
                />
              </GridItem>
              <GridItem xs={12}>
                <Autocomplete
                  id={'copy writer'}
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  // disabled={!canEdit}
                  onUpdate={(id, val) => handleContactFieldUpdate(id, val)}
                  value={values['copy writer']}
                  options={Copywriter}
                  label={'Copy Writer'}
                  noResultsMessage="Use Outlook Name"
                  error={values['copy writer']?.length === 0}
                />
              </GridItem>
              <GridItem xs={12}>
                <Autocomplete
                  id={'assistant creative director'}
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  // disabled={!canEdit}
                  onUpdate={(id, val) => handleContactFieldUpdate(id, val)}
                  value={values['assistant creative director']}
                  options={CreativeArt}
                  label={'Assistant Creative Director (ACD)'}
                  noResultsMessage="Use Outlook Name"
                  error={values['assistant creative director']?.length === 0}
                />
              </GridItem>

              <Grid.Container>
                <Grid.Item>
                  <p className="errorEmailMessage">{errors.invalidEmails}</p>
                  <ul className="errorEmailList">
                    {values?.invalidEmails?.map((email, i) => (
                      <li key={i}>{email}</li>
                    ))}
                  </ul>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Button type="primary" onClick={handleSubmit} fullWidth>
                    Save
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </div>
          )
        }}
      </Formik>
    </ModalDrawer>
  )
}

export default MessageContacts
