import {
  Card,
  Grid,
  Heading,
  Form,
  Table,
  Spinner,
  Tooltip,
  Button,
  ButtonGroup,
} from '@enterprise-ui/canvas-ui-react'
import { faCamera, faTshirt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import PaginationWidget from '../../components/PaginationWidget'
import useDPCISearch from '../../hooks/useDPCISearch'
import usePopCookies from '../../hooks/usePopCookies'

const columnData = [
  { id: 'eventWeek', label: 'Event Week', size: 2 },
  {
    getValue: (value) => value + 1,
    id: 'pageOrdering',
    label: 'Page ID',
    size: 1,
  },
  {
    getValue: (value) => value + 1,
    id: 'storyPriority',
    label: 'Story ID',
    size: 1,
  },
  {
    getValue: (value) => value + 1,
    id: 'cutPriority',
    label: 'Cut ID',
    size: 1,
  },
  { id: 'imageFileName', label: 'Image File Name', size: 3 },
  { id: 'sampleId', label: 'Sample ID', size: 2 },
]

export default function DPCISearch() {
  const { toSampleUrl } = usePopCookies()
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const [loading, setLoading] = useState(false)
  const currentDPCI = query.get('dpci') ? query.get('dpci') : ''
  const archive = query.get('hasRun')
  const [DPCI, setDPCI] = useState(currentDPCI)
  const [searchResults, setSearchResults] = useState({})
  const [transport, setTransport] = useState(0)
  const getResults = useDPCISearch(setLoading, query)

  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage.toString())
      setTransport(0)
      navigate(`/weeklyad/dpci?${query.toString()}`)
    }
  }, [query, navigate, transport])

  useEffect(() => {
    getResults({ dpci: DPCI, setResults: setSearchResults })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    if (DPCI.length) {
      query.set('dpci', DPCI)
    } else {
      query.delete('dpci')
    }
    navigate(`/weeklyad/dpci?${query.toString()}`)
    getResults({ dpci: DPCI, setResults: setSearchResults })
  }

  const handleChange = (evt) => setDPCI(evt.target.value)

  return (
    <div className="hc-pa-none">
      <Grid.Container spacing="dense" justify="flex-end" align="flex-end">
        <Grid.Item className="hc-pb-none">
          <PaginationWidget
            corners="top"
            pages={searchResults}
            setTransport={setTransport}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Card elevation={2} className="hc-pa-dense">
            <Grid.Container>
              <Grid.Item xs={12}>
                <Heading size={2} className="hc-ta-center">
                  DPCI Search
                </Heading>
              </Grid.Item>
              <Grid.Item xs={12}>
                <Grid.Container className="hc-pa-dense" justify="center">
                  <Grid.Item xs={4}>
                    <Form.Field
                      id="dpci"
                      placeholder="DPCI search"
                      value={DPCI}
                      onChange={handleChange}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <ButtonGroup className="hc-mb-none hc-mt-none">
                      <Button
                        type="secondary"
                        onClick={() => {
                          if (query.get('dpci')) {
                            query.delete('dpci')
                          }
                          navigate(`/weeklyad/dpci?${query.toString()}`)
                          setLoading(true)
                          setDPCI('')
                          getResults({ dpci: '', setResults: setSearchResults })
                        }}
                      >
                        Clear
                      </Button>
                      <Button type="primary" onClick={handleSubmit}>
                        Apply
                      </Button>
                    </ButtonGroup>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item xs={12}>
                <Table>
                  <Table.Head>
                    <Table.Row>
                      {columnData.map((col) => (
                        <Table.Header
                          key={col.id}
                          xs={col.size}
                          className="hc-ta-center"
                        >
                          {col.label}
                        </Table.Header>
                      ))}
                    </Table.Row>
                  </Table.Head>
                  <Table.Body className="POP-table">
                    {loading ? (
                      <Table.Row className="hc-pa-dense" justify="center">
                        <Spinner size="dense" />
                      </Table.Row>
                    ) : searchResults?.content?.length ? (
                      searchResults.content.map((res, idx) => (
                        <Table.Row key={idx} justify="center">
                          {columnData.map((col, colIdx) => (
                            <Table.Data
                              xs={col.size}
                              key={colIdx}
                              className="hc-ta-center"
                            >
                              {col.getValue
                                ? col.getValue(res[col.id])
                                : res[col.id]}
                            </Table.Data>
                          ))}
                          <Table.Data xs={2}>
                            <Grid.Container spacing="dense">
                              <Grid.Item>
                                <Tooltip content="Merch View" location="top">
                                  <Button
                                    aria-label="Merch view"
                                    type="primary"
                                    iconOnly
                                    as={Link}
                                    to={`/weeklyad/${res.weeklyAdId}/merchant`}
                                  >
                                    <FontAwesomeIcon icon={faTshirt} />
                                  </Button>
                                </Tooltip>
                              </Grid.Item>
                              <Grid.Item>
                                <Tooltip content="Sample View" location="top">
                                  <Button
                                    aria-label="sample view"
                                    type="primary"
                                    iconOnly
                                    as={Link}
                                    to={toSampleUrl(res.weeklyAdId, archive)}
                                  >
                                    <FontAwesomeIcon icon={faCamera} />
                                  </Button>
                                </Tooltip>
                              </Grid.Item>
                            </Grid.Container>
                          </Table.Data>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row className="hc-pa-dense" justify="center">
                        <Table.Data xs={12} className="hc-ta-center">
                          No results found
                        </Table.Data>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Grid.Item>
            </Grid.Container>
          </Card>
        </Grid.Item>
        <Grid.Item className="hc-pb-none">
          <PaginationWidget
            corners="top"
            pages={searchResults}
            setTransport={setTransport}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}
