import {
  Button,
  Card,
  Grid,
  ProgressBar,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import CatalogApi from '../../api/pop-catalog/CatalogApi'
import PaginationWidget from '../../components/PaginationWidget'
import AddCatalog from '../../forms/AddCatalog'
import CatalogTable from '../../tables/CatalogTable'
import { canEditUpcomingCatalogs } from '../../util/CheckRole'
import {
  handleFireflyInit,
  handleFireflyFailure,
  handleFireflySuccess,
} from '../../util/FireflyHelper'

const CatalogHome = (props) => {
  const { role, archive, session = {} } = props
  const [loading, setLoading] = useState(false)
  const [catalogs, setCatalogs] = useState([])
  const [catalogData, setCatalogData] = useState({})
  const [showCreateForm, setCreateForm] = useState(false)
  const [transport, setTransport] = useState(0)
  const { search } = useLocation()
  const api = new CatalogApi()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()
  const viewName = 'Catalog'

  useEffect(() => {
    setLoading(true)
    query.set('hasRun', archive ? true : false)
    handleFireflyInit('pageload', viewName, 'success', session)
    api
      .getCatalogBatch(query)
      .then((res) => {
        setCatalogData(res)
        setCatalogs(res?.catalogs)
        handleFireflySuccess('pageload', viewName, 'success', session)
      })
      .catch((e) => {
        console.log(e)
        handleFireflyFailure('pageload', viewName, 'error', session)
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [setCatalogs, setLoading, transport])

  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage.toString())
      setTransport(0)
      query.delete('hasRun')
      const url = !archive
        ? `/catalog?${query.toString()}`
        : `/past-catalogs?${query.toString()}`
      navigate(url)
    }
  }, [transport, query, archive, navigate])

  return (
    <>
      <Grid.Container
        className={`${
          (archive || role.some((r) => !canEditUpcomingCatalogs(r))) &&
          'hc-pt-expanded'
        } `}
        justify="flex-end"
        align="flex-end"
      >
        {!archive && role.some((r) => canEditUpcomingCatalogs(r)) && (
          <Grid.Item className="hc-mb-dense" align="right">
            <Button type="primary" onClick={() => setCreateForm(true)}>
              Create Catalog
            </Button>
          </Grid.Item>
        )}
        <Grid.Item className="hc-ta-right">
          <PaginationWidget
            pages={catalogData}
            corners="top"
            setTransport={setTransport}
          />
        </Grid.Item>
      </Grid.Container>
      <Card>
        {loading ? (
          <div className="hc-pa-normal">
            <ProgressBar indeterminate />
          </div>
        ) : (
          <div className="hc-pa-dense">
            <Grid.Container spacing="none" justify="flex-end" align="flex-end">
              <Grid.Item className="hc-ta-center" xs={12}>
                <Heading>
                  {archive ? 'Past Catalogs' : 'Upcoming Catalogs'}
                </Heading>
              </Grid.Item>
              <Grid.Item xs={12} className="hc-ov-auto">
                <CatalogTable
                  catalogs={catalogs}
                  archive={archive ? archive : false}
                />
              </Grid.Item>
            </Grid.Container>
          </div>
        )}
      </Card>
      <AddCatalog
        setShow={setCreateForm}
        show={showCreateForm}
        setCatalogs={setCatalogs}
      />
    </>
  )
}
export default CatalogHome
