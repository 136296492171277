import { Button, Grid, Heading, Form } from '@enterprise-ui/canvas-ui-react'
import '../scss/Category.scss'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { ModalDrawer } from 'nicollet-react'
import { useState } from 'react'

const MessageSettings = ({
  setUpdateCategory,
  setIsSettingsDrawerOpen,
  isSettingsDrawerOpen,
  label,
  bannerIntro,
  setBannerIntro,
  bgColor,
  setBgColor,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
  introCopy,
  setIntroCopy,
  introCopy160600,
  setIntroCopy160600,
  srText,
  setSrText,
}) => {
  const [formUpdated, setFormUpdated] = useState(false)

  const updateField = (initialValue, updateFunction, value) => {
    if (initialValue !== value) {
      updateFunction(value)
      setFormUpdated(true)
    }
  }

  return (
    <ModalDrawer
      isOpen={isSettingsDrawerOpen}
      iconButtonCloseOnClick={() => setIsSettingsDrawerOpen(false)}
      scroll={true}
      onRequestClose={() => {
        if (formUpdated) {
          setUpdateCategory(true)
        }
        setIsSettingsDrawerOpen(false)
      }}
      headingText={
        <div>
          <Heading size={3}>{label + ' Settings'}</Heading>
        </div>
      }
    >
      <div className="bold-header">Message Date and Time</div>

      <Grid.Container>
        <Grid.Item xs={6}>
          <DatePicker
            data-test="message-start-date"
            value={startDate}
            id="startDate"
            label="Start Date"
            onUpdate={(id, value) => {
              updateField(startDate, setStartDate, value)
            }}
            errorText="Oops, please enter a date"
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <Form.Field
            value={'03:00:00'}
            disabled={true}
            id="start_time"
            label="Start Time"
            type="time"
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <DatePicker
            value={endDate}
            id="end_date"
            label="End Date"
            onUpdate={(id, value) => {
              updateField(endDate, setEndDate, value)
            }}
            errorText="Oops, please enter a date"
          />
        </Grid.Item>
        <Grid.Item xs={6}>
          <Form.Field
            value={'02:59:00'}
            disabled={true}
            id="end_time"
            label="End Time"
            type="time"
          />
        </Grid.Item>
      </Grid.Container>

      <hr className="modal-divder" />

      <div className="bold-header">Message Intro and Content</div>
      <Grid.Container>
        <Grid.Item xs={12}>
          <Form.Field
            data-testid="banner-intro"
            id="banner_intro"
            label="Banner Intro"
            type="select"
            value={bannerIntro}
            defaultValue={true}
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            onUpdate={(id, value) => {
              updateField(id, setBannerIntro, value)
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Form.Field
            data-testid="bg-color"
            id="bg_color"
            label="Background Gallery Color"
            onChange={(e) => updateField(bgColor, setBgColor, e.target.value)}
            type="color"
            value={bgColor}
            hintText="This is for the color of the background on the product gallery."
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Form.Field
            data-testid="intro-copy"
            id="intro_copy"
            value={introCopy}
            label="Intro Copy"
            onChange={(e) => {
              updateField(introCopy, setIntroCopy, e.target.value)
            }}
            type="text"
            hintText="Intro copy for all sizes except 160x600."
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Form.Field
            data-testid="intro-copy-160-600"
            value={introCopy160600}
            id="intro_copy_160_600"
            onChange={(e) => {
              updateField(introCopy160600, setIntroCopy160600, e.target.value)
            }}
            label="Intro Copy for 160x600"
            type="text"
            hintText="Intro copy for the 160x600 ad."
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Form.Field
            data-testid="sr-text"
            value={srText}
            id="sr_text"
            onChange={(e) => {
              updateField(srText, setSrText, e.target.value)
            }}
            label="Screen Reader Text"
            type="textarea"
            hintText="Screen reader instructions. Copy the text from another ad and paste into the new ad."
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Button
            data-testid="message-modal-save-btn"
            className="modal-button"
            type="primary"
            onClick={() => {
              setUpdateCategory(true)
              setFormUpdated(false)
            }}
            fullWidth
            disabled={!formUpdated}
          >
            Save
          </Button>
        </Grid.Item>
      </Grid.Container>
    </ModalDrawer>
  )
}

export default MessageSettings
