import {
  Button,
  Card,
  Grid,
  Heading,
  Tabs,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'

import LoadingSpinner from '../../components/LoadingSpinner'
import PaginationWidget from '../../components/PaginationWidget'
import PropTable from '../../components/PropTable'
import appConfig from '../../config/appConfig'
import EditSampleProp from '../../forms/EditSampleProp'
import useExport from '../../hooks/useGetExport'
import useGetPropList from '../../hooks/useGetPropList'
import useGetWeeklyAd from '../../hooks/useGetWeeklyAd'
import usePutSampleProp from '../../hooks/usePutSampleProp'

const PropView = (props) => {
  const { toSampleUrl } = props
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const wadId = useParams().wadId
  const [loading, setLoading] = useState(false)
  const [wadLoading, setWadLoading] = useState(false)

  const makeToast = ToastProvider.useToaster()

  const [weeklyAd] = useGetWeeklyAd(wadId, setWadLoading, makeToast)
  const [getExport] = useExport(makeToast)
  const [disableExport, setDisableExport] = useState(false)
  const [propItems, setLoadPropItems, setPropItems] = useGetPropList(
    setLoading,
    query,
    wadId
  )
  const putSampleProp = usePutSampleProp(setLoading, query, wadId, setPropItems)
  const [editSampleProp, setEditSampleProp] = useState({
    body: {},
    show: false,
  })
  const [transport, setTransport] = useState(0)
  const [currentActiveTab, setCurrentActiveTab] = useState('props')

  const config = appConfig()

  let hasRun = query.get('hasRun')

  const sampleExportUrl =
    config.backend.root + config.backend.export + config.backend.samples + wadId
  const navigate = useNavigate()
  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage)
      query.set('hasRun', hasRun)
      setTransport(0)
      setLoadPropItems(true)
      navigate(`/weeklyad/${wadId}/sample/prop?${query.toString()}`)
    }
  }, [navigate, transport, query, wadId, setLoadPropItems, hasRun])

  return (
    <div className="hc-pa-none">
      <Grid.Container
        spacing="none"
        direction="row-reverse"
        justify="space-between"
        align="flex-end"
      >
        <Grid.Item>
          <PaginationWidget
            corners="top"
            pages={propItems}
            setTransport={setTransport}
            archive={hasRun}
          />
        </Grid.Item>
        <Grid.Item>
          <Card corners="top" className="hc-bg-grey07">
            <Tabs
              disableAdaptive={true}
              activeTab={currentActiveTab}
              onTabSelect={(event, tab) => {
                setCurrentActiveTab(tab.name)
              }}
            >
              <Tabs.Item as={Link} to={toSampleUrl(wadId, hasRun)} name="items">
                Items
              </Tabs.Item>

              <Tabs.Item name="props">Props</Tabs.Item>

              <Tabs.Item
                as={Link}
                to={`/weeklyad/${wadId}/sample/late-additions`}
                name="late-additions"
              >
                Late Additions
              </Tabs.Item>
            </Tabs>
          </Card>
        </Grid.Item>
        <Grid.Item xs={12}>
          <Card
            elevation={2}
            corners="bottom"
            className="hc-pa-dense"
            style={{ borderBottomRightRadius: 0 }}
          >
            <Heading className="hc-ta-center" size={2}>
              {weeklyAd?.eventWeek}
            </Heading>
            <Grid.Container align="center" justify="center" spacing="dense">
              <Grid.Item className="hc-ta-center hc-pt-dense">
                <Button
                  disabled={disableExport}
                  type="secondary"
                  onClick={() =>
                    getExport({
                      setDisable: setDisableExport,
                      url: sampleExportUrl,
                    })
                  }
                  className="hc-bg-contrast-weak"
                >
                  Sample Export
                </Button>
              </Grid.Item>
            </Grid.Container>
            <PropTable data={propItems} setEditSampleProp={setEditSampleProp} />
          </Card>
        </Grid.Item>
        <Grid.Item>
          <PaginationWidget
            corners="bottom"
            pages={propItems}
            setTransport={setTransport}
          />
        </Grid.Item>
      </Grid.Container>
      <EditSampleProp
        editSampleProp={editSampleProp}
        setEditSampleProp={setEditSampleProp}
        putSampleProp={putSampleProp}
      />
      <LoadingSpinner show={loading || wadLoading} />
    </div>
  )
}
export default PropView
