import axios from 'axios'

import appConfig from '../../config/appConfig'

export default class AdminCenterApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_defaultContacts_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.root,
      timeout: 31000,
    })
    return this.client
  }

  getDefaultContacts = async () =>
    await this.init_defaultContacts_api()
      .get(this.config.backend.defaultContacts)
      .then((res) => (res.data.length ? res.data[0] : {}))
      .catch((e) => {
        console.log(e)
        return []
      })

  putDefaultContacts = async (id, payload) =>
    await this.init_defaultContacts_api()
      .put(`${this.config.backend.defaultContacts}/${id}`, payload)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })
}
