import {
  ExpandableSection,
  Grid,
  Chip,
  ProgressBar,
  Button,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import ExportApi from '../api/pop-catalog/ExportApi'
import ProductApi from '../api/pop-catalog/ProductApi'
import appConfig from '../config/appConfig'
import { CategoryColorMap } from '../util/CategoryColorMap'

import SortableProductContainer from './SortableProductContainer'

const ExpandableCreativePage = (props) => {
  const { page, catalog, role } = props
  const config = appConfig()
  const catalogId = useParams().catalogId
  const exportUrl =
    config.backend.catalog + `exports/products/catalog_creative/${catalogId}?`
  const triggerReorder = () => {
    setProducts([])
    setLoading(true)
    productApi
      .getProductPageJoins(page.id)
      .then((res) => {
        setProducts(res.products)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }
  const [isPageDataAvailable, setPageDataStatus] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const [loading, setLoading] = useState(false)

  const [products, setProducts] = useState([])
  //const query = new URLSearchParams(useLocation().search)

  const productApi = new ProductApi()
  const exportApi = new ExportApi()

  const handleExpand = () => {
    setExpanded(!expanded)
    if (!isPageDataAvailable) {
      setLoading(true)
      productApi
        .getProductPageJoins(page.id)
        .then((res) => {
          setProducts(res.products)
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false))

      setPageDataStatus(true)
    }
  }

  return (
    <>
      <ExpandableSection
        className="hc-ma-dense"
        padding="none"
        expanded={expanded}
        onExpand={handleExpand}
      >
        <Grid.Container align="center" spacing="none">
          <Grid.Item xs={2}>
            <strong>Page:</strong> {page.pageOrdering + 1}
          </Grid.Item>
          <Grid.Item xs={3}>
            {page.category.map((cat) => (
              <Chip
                size="dense"
                style={{
                  backgroundColor: CategoryColorMap[cat],
                  border: '0px',
                  color: 'white',
                }}
              >
                {cat}
              </Chip>
            ))}
          </Grid.Item>
          <Grid.Item xs={6}>
            <strong>Section: </strong> {page.section.toString()}
          </Grid.Item>
          <Grid.Item xs={1}>
            <Tooltip location="top" content="Page export">
              <Button iconOnly>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  color="#000000"
                  onClick={(event) => {
                    event.stopPropagation()
                    exportApi.getExport(
                      exportUrl +
                        `key=${config.catapi.apikey}&pageId=${page.id}`
                    )
                  }}
                />
              </Button>
            </Tooltip>
          </Grid.Item>
        </Grid.Container>
        <ExpandableSection.Content>
          {loading ? (
            <Grid.Container>
              <Grid.Item className="hc-pt-expanded" xs={12}>
                <ProgressBar indeterminate />
              </Grid.Item>
            </Grid.Container>
          ) : (
            <SortableProductContainer
              catalog={catalog}
              products={products}
              setProducts={setProducts}
              page={page}
              triggerReorder={triggerReorder}
              role={role}
              calltheProductApi={triggerReorder}
            />
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </>
  )
}
export default ExpandableCreativePage
