import {
  Button,
  Card,
  Grid,
  Heading,
  ProgressBar,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import PaginationTable from '../../components/PaginationTable'
import PaginationWidget from '../../components/PaginationWidget'
import AddWad from '../../forms/AddWad'
import useGetWeeklyAdList from '../../hooks/useGetWeeklyAdList'
import usePostWeeklyAd from '../../hooks/usePostWeeklyAd'
import {
  canEditWeeklyAdsPast,
  canEditWeeklyAdsUpcoming,
} from '../../util/CheckRole'
import { MONTH_LIST } from '../../util/MonthLookup'

const WeeklyAdHome = (props) => {
  const { role, archive, toSampleUrl } = props
  const currentLocation = useLocation()
  const search = currentLocation.search
  const query = useMemo(() => new URLSearchParams(search), [search])

  const [transport, setTransport] = useState(0)
  const [loading, setLoading] = useState(false)
  const isCreateEnabled = archive
    ? role.some((r) => canEditWeeklyAdsPast(r))
    : role.some((r) => canEditWeeklyAdsUpcoming(r))

  const navigate = useNavigate()

  const makeToast = ToastProvider.useToaster(navigate, true)

  const [paginations, addWeeklyAd] = useGetWeeklyAdList(
    setLoading,
    archive,
    useLocation()
  )
  const postWad = usePostWeeklyAd(setLoading, makeToast, addWeeklyAd)
  const [showCreateWad, setShowCreateWad] = useState(false)
  let hasRun = query.get('hasRun')?.includes('true') ? 'true' : 'false'
  useEffect(() => {
    if (transport !== 0) {
      const newPage = `${transport + (parseInt(query.get('pageNo')) || 0)}`
      query.set('pageNo', newPage)
      query.set('hasRun', hasRun)
      setTransport(0)
      const url = archive
        ? `/pastads?${query.toString()}`
        : `/weeklyad?${query.toString()}`
      navigate(url)
    }
  }, [archive, navigate, query, transport, hasRun])

  return (
    <>
      <Grid.Container spacing="dense" justify="flex-end" align="flex-end">
        {isCreateEnabled ? (
          <Grid.Item className="hc-mr-expanded">
            <Button type="primary" onClick={setShowCreateWad}>
              Create Weekly Ad
            </Button>
            <Link to="/weeklyad/dpci?hasRun=false">
              <Button type="primary" className="hc-ml-dense hc-mr-dense">
                DPCI Search
              </Button>
            </Link>
          </Grid.Item>
        ) : (
          <Grid.Item>
            <Link to="/weeklyad/dpci?hasRun=true">
              <Button type="primary" className="hc-ml-dense hc-mr-dense">
                DPCI Search
              </Button>
            </Link>
          </Grid.Item>
        )}
        <Grid.Item className="hc-pb-none">
          <PaginationWidget
            pages={paginations}
            corners="top"
            setTransport={setTransport}
            archive={hasRun}
          />
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pt-none hc-pb-none">
          <Card elevation={2} className="hc-pa-dense" corners="left">
            <Grid.Container direction="column" align="center">
              <Grid.Item className="hc-pt-expanded">
                <Heading size={2}>
                  {query.get('hasRun')?.includes('true')
                    ? 'Past Ads'
                    : 'Upcoming Ads'}
                </Heading>
              </Grid.Item>
              <Grid.Item className="hc-pa-none">
                <Grid.Container
                  className="hc-pt-none"
                  justify="center"
                  spacing="dense"
                >
                  {query && (
                    <Grid.Item>
                      <Autocomplete
                        id="months"
                        chipHeight="dense"
                        className="hc-mb-dense hc-mt-dense"
                        onUpdate={(_, value) => {
                          value
                            ? navigate({
                                pathname: currentLocation.pathname,
                                search: `?eventWeek=${value.value}&hasRun=${hasRun}`,
                              })
                            : navigate({
                                pathname: currentLocation.pathname,
                                search: `&hasRun=${hasRun}`,
                              })
                        }}
                        options={MONTH_LIST}
                        value={MONTH_LIST.find(
                          (month) => month.value === query.get('eventWeek')
                        )}
                        placeholder="Filter Months"
                      />
                    </Grid.Item>
                  )}
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            {loading ? (
              <ProgressBar indeterminate />
            ) : (
              <PaginationTable
                wads={paginations?.wads}
                toSampleUrl={toSampleUrl}
                archive={hasRun}
                role={role}
              />
            )}
          </Card>
        </Grid.Item>
        <Grid.Item className="hc-pt-none">
          <PaginationWidget
            pages={paginations}
            corners="top"
            setTransport={setTransport}
            archive={hasRun}
          />
        </Grid.Item>
      </Grid.Container>

      <AddWad
        show={showCreateWad}
        setShow={setShowCreateWad}
        postWad={postWad}
      />
    </>
  )
}
export default WeeklyAdHome
