import { Card, ProgressBar, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { faLink, faUmbrella } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useRef, useEffect, useContext } from 'react'

import { getPyramidColorByGroupName } from '../../../src/planning/util/ProjectDetailsUtil'
import {
  CanEditContext,
  EffectiveRoleContext,
  EnterpriseLinkedUmbrellasContext,
} from '../../context/Context'
import { canEditCatAmp } from '../../util/CheckRole'
import { filteredUmbrellasOrOffers } from '../util/UmbrellaUtil'

import OfferBasic from './OffersBasic.js'

import '../scss/OfferBasic.scss'

const OffersBasicBucket = (props) => {
  const { umbrellasWithOffers, searchInput, selectedProject } = props

  const { effectiveRole } = useContext(EffectiveRoleContext)
  const [canEdit, setCanEdit] = useState(false)
  const enterpriseLinkedUmbrellas = useContext(EnterpriseLinkedUmbrellasContext)
  const isEnterpriseLinked = (ivyUmbrellaId) =>
    enterpriseLinkedUmbrellas?.linkedUmbrellas?.find(
      (entLinkedUmbrella) => entLinkedUmbrella.ivy_umbrella_id === ivyUmbrellaId
    )

  const invalidDates = (ivyUmbrellaId) =>
    enterpriseLinkedUmbrellas?.linkedUmbrellas?.find(
      (entLinkedUmbrella) =>
        entLinkedUmbrella.invalid_dates === true &&
        entLinkedUmbrella.ivy_umbrella_id === ivyUmbrellaId
    )

  useEffect(() => {
    setCanEdit(effectiveRole?.some((role) => canEditCatAmp(role)))
  }, [effectiveRole, setCanEdit])

  const draggingItem = useRef()

  const handleDragStart = (event, position, offer) => {
    event.dataTransfer.setData('drag-offer', JSON.stringify([offer]))
    draggingItem.current = position
  }

  const handleUmbrellaDragStart = (event, umbrella) => {
    const offers = umbrella.offers
    event.dataTransfer.setData('drag-umbrella', JSON.stringify(offers))
  }

  const filterReducer = searchInput.length
    ? filteredUmbrellasOrOffers(umbrellasWithOffers, searchInput)
    : umbrellasWithOffers

  const findStyle = (umbrella) => {
    const gName = umbrella?.merchant_category?.group_name
      ? umbrella?.merchant_category?.group_name
      : null

    return { backgroundColor: getPyramidColorByGroupName(gName).colorCode }
  }

  return (
    <CanEditContext.Provider value={{ canEdit }}>
      {umbrellasWithOffers ? (
        umbrellasWithOffers.length ? (
          <div className="publishing_offer_list">
            {filterReducer.map((umbrella, index) => (
              <div key={`umbrella-${umbrella.ivy_umbrella_id}-${index}`}>
                <div
                  className="offerUmbrellaHeader"
                  style={findStyle(umbrella)}
                  key={umbrella.merchant_category.group_name}
                  draggable={canEdit}
                  onDragStart={(event) =>
                    handleUmbrellaDragStart(event, umbrella)
                  }
                >
                  {isEnterpriseLinked(umbrella.ivy_umbrella_id) ? (
                    <Tooltip
                      content="This umbrella is linked to an Enterprise Guest Outcome"
                      location="top-left"
                      style={{ zIndex: 1 }}
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        className="fa-icon linked"
                        size="sm"
                      />
                    </Tooltip>
                  ) : null}
                  <FontAwesomeIcon color="#fff" icon={faUmbrella} />
                  <span
                    className={
                      invalidDates(umbrella.ivy_umbrella_id)
                        ? 'invalidDatesPublishing'
                        : null
                    }
                  >
                    {' '}
                    <span className="umb-name-spacer">
                      {umbrella.name}
                    </span>{' '}
                  </span>
                  {invalidDates(umbrella.ivy_umbrella_id) ? (
                    <span className="invalidDatesTextPublishing">
                      INVALID DATES
                    </span>
                  ) : null}
                </div>
                <div
                  key={umbrella.id}
                  className="publishing-offer-holder"
                  xs={5}
                  data-testid="offerContainer"
                >
                  {umbrella.offers.map((umbrellaOffer, index) => (
                    <Card
                      className={`simpleBucketItem`}
                      key={`offer- ${umbrellaOffer.id}  ${index}`}
                      onDragStart={
                        canEdit &&
                        umbrellaOffer.deleted === false &&
                        umbrellaOffer.offer_order !== -2
                          ? (event) =>
                              handleDragStart(event, index, umbrellaOffer)
                          : null
                      }
                      data-testid={'offerBucket-' + umbrellaOffer.umbrellaId}
                      draggable={
                        canEdit &&
                        umbrellaOffer.deleted === false &&
                        umbrellaOffer.offer_order !== -2
                      }
                    >
                      <OfferBasic
                        offer={umbrellaOffer}
                        selectedProject={selectedProject}
                      />
                    </Card>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          'No Offers for This Project and Channel'
        )
      ) : (
        <ProgressBar indeterminate />
      )}
    </CanEditContext.Provider>
  )
}

export default OffersBasicBucket
