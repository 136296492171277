import {
  Card,
  Grid,
  Heading,
  ProgressBar,
  Form,
  Button,
  Pagination,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useState, useEffect, useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import CatalogApi from '../../api/pop-catalog/CatalogApi'
import ExportApi from '../../api/pop-catalog/ExportApi'
import ProductApi from '../../api/pop-catalog/ProductApi'
import ProductListTable from '../../tables/ProductListTable'
import {
  imageSampleFilters,
  setPageFilterFromQuery,
  setImageSampleFilterFromQuery,
} from '../../util/filterOptions'
import { CATALOG_SAMPLE_EXPORT_URL } from '../../util/urlUtil'

const CatalogSampleView = () => {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])

  const catalogId = useParams().catalogId
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [productData, setProductData] = useState({})

  const catalogApi = new CatalogApi()
  const [catalog, setCatalog] = useState({})

  const productApi = new ProductApi()
  const exportApi = new ExportApi()
  const [products, setProducts] = useState([])

  const [transport, setTransport] = useState(0)

  // Pagination Utility useEffect
  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage.toString())
      setTransport(0)
    }
  }, [transport, query])

  useEffect(() => {
    setLoading(true)
    catalogApi
      .getCatalog(catalogId)
      .then((res) => setCatalog(res))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [setCatalog, setLoading])

  useEffect(() => {
    setLoading(true)
    productApi
      .getProductBatch(query, catalogId)
      .then((res) => {
        setProductData(res)
        setProducts(res.products)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [setLoading, setProducts])
  // }, [setProducts, productData, transport, query])

  const pageList = formatPages(catalog?.pages)

  return productData && catalog ? (
    <Formik
      enableReinitialize
      initialValues={{
        dpci: query.get('dpci') ? query.get('dpci') : '',
        filters: setImageSampleFilterFromQuery(query),
        page: query.get('pageId')
          ? setPageFilterFromQuery(query, pageList)
          : [],
        productName: query.get('product_name') ? query.get('product_name') : '',
        query: query.toString(),
      }}
      onSubmit={(values) => {
        query.delete('pageNo')
        query.delete('perPage')
        query.delete('category')
        query.delete('fig_model')
        query.delete('sample_submitted')
        query.delete('sample_status')
        query.delete('art_director_request')
        query.delete('image_sample_collected')
        query.delete('image_approved')
        query.delete('product_name')
        !isEmpty(values.dpci)
          ? query.set('dpci', values.dpci)
          : query.delete('dpci')

        !isEmpty(values.page)
          ? query.set('pageId', values.page.map((page) => page.id).join(','))
          : query.delete('pageId')

        !isEmpty(values.productName)
          ? query.set('product_name', values.productName)
          : query.delete('product_name')

        const allFilters = {
          art_director_request: [],
          category: [],
          fig_model: [],
          image_approved: [],
          image_sample_collected: [],
          sample_status: [],
          sample_submitted: [],
        }
        values.filters.forEach((fil) => allFilters[fil.filter].push(fil.value))
        Object.keys(allFilters).forEach((filterType) => {
          if (!isEmpty(allFilters[filterType])) {
            query.set(filterType, allFilters[filterType])
          }
        })

        navigate(`/catalog/${catalogId}/sample?${query.toString()}`)
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
        <>
          <div className="hc-pa-normal hc-pa-none">
            <Grid.Container
              padding="dense"
              spacing="none"
              justify="flex-end"
              align="flex-end"
            >
              <Grid.Item>
                <Card elevation={2} corners="top">
                  {productData && (
                    <Pagination
                      currentPage={productData?.pageNumber + 1}
                      totalPages={productData?.totalPages}
                      onRequestNext={
                        productData.isLastPage
                          ? null
                          : () => {
                              query.set('pageNo', productData?.pageNumber + 1)
                              navigate(
                                `/catalog/${catalogId}/sample?${query.toString()}`
                              )
                            }
                      }
                      onRequestPrev={
                        productData.isFirstPage
                          ? null
                          : () => {
                              query.set('pageNo', productData?.pageNumber - 1)
                              navigate(
                                `/catalog/${catalogId}/sample?${query.toString()}`
                              )
                            }
                      }
                    />
                  )}
                </Card>
              </Grid.Item>
              <Grid.Item xs={12}>
                <Card>
                  {loading ? (
                    <div className="hc-pa-normal">
                      <ProgressBar indeterminate />
                    </div>
                  ) : (
                    <div className="hc-pa-normal">
                      <Grid.Container
                        spacing="dense"
                        align="center"
                        // justify="center"
                      >
                        <Grid.Item xs={12} className="hc-ta-center">
                          <Heading>{catalog.name}</Heading>
                          <p>Run Date: {catalog.runDate}</p>
                        </Grid.Item>

                        <Grid.Item xs={3} className="hc-mt-dense">
                          <Autocomplete
                            multiselect
                            id="filters"
                            chipHeight="dense"
                            onUpdate={(_, value) => {
                              setFieldValue('filters', value)
                              value.length !== values.filters.length &&
                                handleSubmit()
                            }}
                            value={values.filters}
                            options={imageSampleFilters}
                            placeholder="Filters"
                          />
                        </Grid.Item>
                        <Grid.Item xs={2} className="hc-mt-dense">
                          <Autocomplete
                            id="page"
                            multiselect
                            chipHeight="dense"
                            onUpdate={(_, value) => {
                              setFieldValue('page', value)
                              value.length !== values.page.length &&
                                handleSubmit()
                            }}
                            value={values.page}
                            options={pageList}
                            placeholder="Page"
                          />
                        </Grid.Item>
                        <Grid.Item xs={2} className="hc-mt-normal">
                          <Form.Field
                            id="dpci"
                            chipHeight="dense"
                            placeholder="DPCI"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSubmit()
                              }
                            }}
                            value={values.dpci}
                            onChange={handleChange}
                          />
                        </Grid.Item>
                        <Grid.Item xs={2} className="hc-mt-normal">
                          <Form.Field
                            id="productName"
                            placeholder="Product Name"
                            value={values.productName}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSubmit()
                              }
                            }}
                            className="plv-input-alignment"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3} className="hc-mt-normal">
                          <Grid.Container
                            align="center"
                            justify="center"
                            padding="none"
                          >
                            <Grid.Item xs={6} justify="left">
                              <Button
                                type="secondary"
                                onClick={() => {
                                  navigate(`/catalog/${catalogId}/sample`)
                                  handleReset()
                                }}
                              >
                                Clear
                              </Button>
                            </Grid.Item>
                            <Grid.Item xs={6}>
                              <Button
                                type="secondary"
                                className="hc-ml-expanded"
                                onClick={() => {
                                  exportApi.getExport(
                                    CATALOG_SAMPLE_EXPORT_URL(catalogId)
                                  )
                                }}
                              >
                                Export
                              </Button>
                            </Grid.Item>
                          </Grid.Container>
                        </Grid.Item>
                        <ProductListTable
                          products={products}
                          setLoading={setLoading}
                          setProducts={setProducts}
                        />
                      </Grid.Container>
                    </div>
                  )}
                </Card>
              </Grid.Item>
              <Grid.Item>
                <Card elevation={2} corners="top">
                  {productData && (
                    <Pagination
                      currentPage={productData?.pageNumber + 1}
                      totalPages={productData?.totalPages}
                      onRequestNext={
                        productData.isLastPage
                          ? null
                          : () => {
                              query.set('pageNo', productData?.pageNumber + 1)
                              navigate(
                                `/catalog/${catalogId}/sample?${query.toString()}`
                              )
                            }
                      }
                      onRequestPrev={
                        productData.isFirstPage
                          ? null
                          : () => {
                              query.set('pageNo', productData?.pageNumber - 1)
                              navigate(
                                `/catalog/${catalogId}/sample?${query.toString()}`
                              )
                            }
                      }
                    />
                  )}
                </Card>
              </Grid.Item>
            </Grid.Container>
          </div>
        </>
      )}
    </Formik>
  ) : null
}
export default CatalogSampleView

function formatPages(pages) {
  return !isEmpty(pages)
    ? pages
        ?.map((page) => ({
          id: page.id,
          label: `Page ${page.pageOrdering + 1}`,
          value: page.id,
        }))
        .concat([{ id: -1, label: 'TBD', value: -1 }])
    : []
}
