import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import offerMapper from '../mappers/offerMapper'

const useGetItem = (setLoading) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.cut)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const getOptions = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(url.current + request.cutId + '/items')
        mounted &&
          request.setItemOffers(
            response.data.map((offer) => offerMapper(offer))
          )
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
    }
    if (request !== null) {
      let thenFunc =
        typeof request.then === 'function' ? request.then : () => {}
      getOptions().then(() => thenFunc())
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading])

  return setRequest
}
export default useGetItem
