import { Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

let addItemPatchSchema = yup.object().shape({
  add_itemPatch_marketString: yup.string(),
  add_itemPatch_regPriceCallout: yup.bool(),
  add_itemPatch_regUnitRetail: yup.string().matches(/^\d*(?:\.(?!00)\d{2})?$/, {
    message: 'Retail Price in format 99.99',
  }),
})

const AddItemPatch = (props) => {
  const { addItemPatch, setAddItemPatch, postItemPatch } = props

  const wipeState = () => {
    setAddItemPatch({
      body: {},
      cutId: null,
      itemId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
  }

  return addItemPatch.body ? (
    <Formik
      enableReinitialize
      validationSchema={addItemPatchSchema}
      initialValues={{
        add_itemPatch_marketString: addItemPatch.body.marketString || '',
        add_itemPatch_regPriceCallout: Boolean(
          addItemPatch.body.regPriceCallout
        ),
        add_itemPatch_regUnitRetail: addItemPatch.body.regUnitRetail || '',
      }}
      onSubmit={(values, funcs) => {
        postItemPatch({
          body: {
            itemId: addItemPatch.body.itemId,
            lastUpdatedTimestamp: addItemPatch.body.lastUpdatedTimestamp,
            marketString: values.add_itemPatch_marketString,
            regPriceCallout: values.add_itemPatch_regPriceCallout,
            regUnitRetail: values.add_itemPatch_regUnitRetail,
          },
          cutId: addItemPatch.cutId,
          itemId: addItemPatch.itemId,
          pageId: addItemPatch.pageId,
          storyId: addItemPatch.storyId,
        })
        funcs.resetForm()
        wipeState()
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleReset,
        handleSubmit,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={addItemPatch.show}
            headingText={'Add Item Patch'}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <Grid.Container
                align="center"
                justify="space-between"
                spacing="dense"
              >
                <Grid.Item xs={2}>
                  <Form.Field
                    id="add_itemPatch_regUnitRetail"
                    value={values.add_itemPatch_regUnitRetail}
                    label="Reg Unit Retail"
                    onChange={handleChange}
                    error={errors.add_itemPatch_regUnitRetail}
                    errorText={errors.add_itemPatch_regUnitRetail}
                  />
                </Grid.Item>
                <Grid.Item xs={9}>
                  <Form.Field
                    id="add_itemPatch_regPriceCallout"
                    type="toggle"
                    value={values.add_itemPatch_regPriceCallout}
                    label="Reg $ callout"
                    onChange={() =>
                      setFieldValue(
                        'add_itemPatch_regPriceCallout',
                        !values.add_itemPatch_regPriceCallout
                      )
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    type="textarea"
                    grow
                    id="add_itemPatch_marketString"
                    value={values.add_itemPatch_marketString}
                    label="Markets"
                    onChange={handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default AddItemPatch
