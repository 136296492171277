import { Card, Heading } from '@enterprise-ui/canvas-ui-react'
import { Link } from '@mui/material'
import { useAuth } from '@praxis/component-auth'

import { handleFireflySuccess } from '../../util/FireflyHelper'

import '../../scss/NoAccess.scss'

const GoNoAccess = () => {
  const { session } = useAuth()
  handleFireflySuccess(
    'pageload',
    'Grouped Offer No Access',
    'success',
    session
  )
  return (
    <Card>
      <div className="hc-pa-normal">
        <div className="unauthorized">
          <img
            src="https://corporate.target.com/_media/TargetCorp/Press/PDF-tn-FPO.jpg"
            alt="TargetLogo"
            className="logo"
          />
          <p>
            <Heading size={5}>
              You are unauthorized to access the <br />
              Grouped Offers application.
            </Heading>
          </p>
          <p>
            To use this application users must have one of the following
            entitlements:
          </p>
          <p>
            <ul>
              <li>APP-PAO-Write-Prod</li>
            </ul>
          </p>
          <p>
            Please request the appropriate entitlements in{' '}
            <Link href="https://myaccess.target.com/" underline="hover">
              MyAccess
            </Link>
            {'. '}
            If you need need additional assistance, please contact{' '}
            <Link href={'mailto:EmilyPearl@target.com'} underline="hover">
              Emily Pearl
            </Link>{' '}
            or{' '}
            <Link href={'mailto:MichaelSnyder@target.com'} underline="hover">
              Michael Snyder
            </Link>
            {'.'}
          </p>
        </div>
      </div>
    </Card>
  )
}

export default GoNoAccess
