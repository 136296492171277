import { Spinner, Table, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { faCopy, faLink, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../scss/UmbrellaChannelSelector.scss'
import { useState, useEffect, Fragment, useContext } from 'react'

import { EnterpriseLinkedUmbrellasContext } from '../../context/Context'
import { EffectiveRoleContext } from '../../context/Context'
import { isInCanEditPlanningChannelGrid } from '../../util/CheckRole'
import {
  copyUmbrellaLink,
  filterUmbrellasByChannel,
  trackSelectedChannel,
  checkedChannels,
} from '../util/UmbrellaUtil'

import PyramidChip from './PyramidChip'

const UmbrellaChannelSelector = ({
  handleChannelClick,
  handleSelectAllClick,
  title = '',
  umbrellas = [],
  channels = [],
  channelFilters = [],
  assigned = [],
  isLoading = false,
  projectId,
  pyramidType,
}) =>
  isLoading ? (
    <div className="center-container">
      <Spinner />
    </div>
  ) : (
    <Table cellPadding="dense" alternateRowColor={false}>
      <TableHeader channels={channels} title={`${title}`} />
      {umbrellas.length > 0 ? (
        <>
          <TableBody
            assigned={assigned}
            channels={channels}
            channelFilters={channelFilters}
            umbrellas={umbrellas}
            handleChannelClick={handleChannelClick}
            handleSelectAllClick={handleSelectAllClick}
            title={title}
            projectId={projectId}
            pyramidType={pyramidType}
          />
        </>
      ) : (
        <Table.Body>
          <Table.Row>
            <Table.Data>No umbrellas present</Table.Data>
          </Table.Row>
        </Table.Body>
      )}
    </Table>
  )

const TableHeader = ({ channels, title }) => (
  <Table.Head className="table-sticky">
    <Table.Row key="row-1" justify="left">
      <Table.Header key="head-1" className="table-header" xs={6}>
        {title}
      </Table.Header>
      <Table.Header key="head-2" className="table-header" xs={6}>
        Marketing Channels
      </Table.Header>
    </Table.Row>
    <Table.Row key="row-2" align="center">
      <Table.Header key="title-1" xs={3}>
        Umbrella
      </Table.Header>
      <>
        {channels.map((channel, ind) => (
          <Table.Header
            className="column-text-heading"
            width="70px"
            key={`${channel.channel_name}-${ind}`}
            xs={true}
            justify="center"
          >
            {channel.channel_name}
          </Table.Header>
        ))}
      </>
    </Table.Row>
  </Table.Head>
)

const TableBody = ({
  assigned,
  channels,
  handleChannelClick,
  handleSelectAllClick,
  pyramidType,
  projectId,
  channelFilters = [],
  umbrellas = [],
  title,
}) => {
  let pyramidId = ''
  let storyId = ''
  let umbrellaCount = {}
  const enterpriseLinkedUmbrellas = useContext(EnterpriseLinkedUmbrellasContext)
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const isReadOnly = !isInCanEditPlanningChannelGrid(effectiveRole)
  const filteredUmbrellas = filterUmbrellasByChannel(umbrellas, channelFilters)
  const [currentSelectedChannel, setCurrentSelectedChannel] = useState({})

  useEffect(() => {
    trackSelectedChannel(
      channels,
      umbrellaCount,
      umbrellas,
      setCurrentSelectedChannel
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigned, channels, umbrellas])

  return (
    <Table.Body>
      <Table.Row key="row-3" align="center">
        <Table.Data
          key="umbrella-name"
          className="umbrella-style"
          justify="flex-start"
          xs={3}
        >
          {' '}
          <b>Select All</b>
        </Table.Data>
        {channels.map((channel, index) => {
          const handleClickEvent = (e) => {
            handleSelectAllClick(
              channel.channel_id,
              projectId,
              pyramidType,
              !currentSelectedChannel[channel.channel_id],
              umbrellas
            )
            setCurrentSelectedChannel({
              ...currentSelectedChannel,
              [channel.channel_id]: !currentSelectedChannel[channel.channel_id],
            })
            e.preventDefault()
          }
          return (
            <Table.Data
              key={channel.channel_id}
              justify="center"
              xs={true}
              className="radio-container"
              onClick={handleClickEvent}
              data-testid={channel.channel_id}
              width="70px"
              style={{ borderLeft: index === 0 ? '1px solid' : 'none' }}
            >
              <CustomCheckBox
                isChecked={checkedChannels(
                  channel.channel_id,
                  assigned,
                  umbrellaCount,
                  umbrellas
                )}
                isReadOnly={isReadOnly}
              />
            </Table.Data>
          )
        })}
      </Table.Row>
      {filteredUmbrellas.map((umbrella) => {
        const isNewStory = storyId !== umbrella.story_id
        const outcomes = umbrella.guest_outcomes
        const guestOutcomeName = umbrella.guest_outcome_name
        const isNewOutcomes = !!outcomes && pyramidId !== umbrella.pyramid_id
        const storyName = umbrella.story_name
        const isKey = umbrella.key
        const deactivated = umbrella.status === 'DEACTIVATED' ? true : false

        const isEnterpriseLinked =
          enterpriseLinkedUmbrellas?.linkedUmbrellas?.find(
            (entLinkedUmbrella) =>
              entLinkedUmbrella.ivy_umbrella_id === umbrella.ivy_umbrella_id
          )
        storyId = umbrella.story_id
        pyramidId = umbrella.pyramid_id
        return (
          <Fragment key={`${umbrella.ivy_umbrella_id}-frag`}>
            {isNewOutcomes ? (
              <Table.Row
                key="row-new-outcomes"
                className="outcome-style"
                align="center"
              >
                <Table.Data xs={3 + channels.length}>
                  {guestOutcomeName}: {outcomes}
                </Table.Data>
              </Table.Row>
            ) : null}
            {isNewStory ? (
              <Table.Row
                key="row-new-story"
                className={isNewOutcomes ? `story-style` : 'story-lone-style'}
                align="center"
              >
                <Table.Data xs={3 + channels.length}>
                  <b>Story Name:</b> {storyName}
                </Table.Data>
              </Table.Row>
            ) : null}
            <Table.Row key="row-3" align="center">
              <Table.Data
                key="umbrella-name"
                className="umbrella-style"
                justify="flex-start"
                xs={3}
              >
                {isEnterpriseLinked && title !== 'Enterprise' ? (
                  <Tooltip
                    content="This umbrella is also linked to an Enterprise Guest Outcome"
                    location="top-left"
                  >
                    <FontAwesomeIcon
                      icon={faLink}
                      className="fa-icon linked"
                      size="lg"
                    />
                  </Tooltip>
                ) : null}
                {title === 'Enterprise' ? (
                  <PyramidChip
                    groupName={umbrella.merchant_category?.group_name}
                    isKey={isKey}
                  />
                ) : (
                  <>
                    {isKey ? (
                      <FontAwesomeIcon
                        icon={faKey}
                        className="fa-duotone key"
                        size="lg"
                      />
                    ) : null}
                  </>
                )}
                <span
                  className={
                    umbrella.invalid_dates || deactivated
                      ? 'invalidDates'
                      : null
                  }
                >
                  {' '}
                  {umbrella.name}{' '}
                </span>
                {umbrella.invalid_dates ? (
                  <span className="invalidDatesText"> INVALID DATES </span>
                ) : null}
                <s className={'deactivatedName'}>
                  {deactivated ? ` - DEACTIVATED` : null}
                </s>
                <FontAwesomeIcon
                  data-testid="umbrellaCopyBtn"
                  className="umbrella-copy-btn clickable"
                  icon={faCopy}
                  onClick={() => copyUmbrellaLink(umbrella)}
                />
              </Table.Data>
              {channels.map((channel, index) => {
                const isChecked =
                  assigned?.find(
                    (assignee) =>
                      assignee.channel_id === channel.channel_id &&
                      assignee.umbrella_id === umbrella.id
                  ) !== undefined
                const key = `${umbrella.planning_umbrella_id}-${channel.channel_id}-radio`
                const handleClickEvent = (e) => {
                  if (isReadOnly) {
                    return
                  }
                  handleChannelClick(
                    channel.channel_id,
                    umbrella.planning_umbrella_id
                  )
                  e.preventDefault()
                }
                return (
                  <Table.Data
                    key={key}
                    justify="center"
                    xs={true}
                    className="radio-container"
                    onClick={handleClickEvent}
                    data-testid={key}
                    width="70px"
                    style={{ borderLeft: index === 0 ? '1px solid' : 'none' }}
                  >
                    <CustomCheckBox
                      isChecked={isChecked}
                      isReadOnly={isReadOnly}
                    />
                  </Table.Data>
                )
              })}
            </Table.Row>
          </Fragment>
        )
      })}
    </Table.Body>
  )
}

const CustomCheckBox = ({ isChecked = false, isReadOnly }) => (
  <div
    className={`radio-${isChecked ? 'checked' : 'unchecked'} radio-${
      isChecked ? 'checked' : 'unchecked'
    }-color${isReadOnly ? '-readonly not-allowed' : ''}`}
  />
)

export default UmbrellaChannelSelector
