import catalogPageMapper from './catalogPageMapper'

const catalogMapper = (catalog) => ({
  creativeCaptain: catalog.creative_captain || [],
  deleted: catalog.deleted,
  endDate: catalog.end_date,
  id: catalog.id,
  lastUpdatedTimestamp: catalog.last_updated_timestamp,
  merchantCaptain: catalog.merchant_captain || [],
  name: catalog.name,
  pages: catalog.pages
    ? catalog.pages
        .sort((a, b) => a.page_ordering - b.page_ordering)
        .filter((page) => page.deleted === false)
        .map((page) => catalogPageMapper(page))
    : [],
  projectCaptain: catalog.project_captain || [],
  runDate: catalog.run_date,
})
export default catalogMapper
