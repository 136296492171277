import { Table } from '@enterprise-ui/canvas-ui-react'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState, useMemo } from 'react'

import '../../planning/scss/UmbrellaChannelSelector.scss'
import { EffectiveRoleContext } from '../../context/Context'
import { isAdminOrMarketing } from '../../util/CheckRole'
import OfferChannelApi from '../api/projectExecution/OfferChannelApi'
import {
  isChannelAvailable,
  isChannelSelected,
  updateChannelSelection,
} from '../util/ChannelUtil'

const OfferChannelSelector = ({
  assignedChannels = [],
  channels = [],
  ivyOfferId = -1,
  offerId = '',
  offerName = '',
  projectId = '',
  abEmail = '',
  umbrellaName = '',
  keyValue,
  selectedStoryId = '',
}) => {
  const [assignedOfferChannels, setAssignedOfferChannels] =
    useState(assignedChannels)
  const offerChannelApi = useMemo(() => new OfferChannelApi(), [])
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const isReadOnly = !isAdminOrMarketing(effectiveRole)
  const handleChannelClick = (channelId, offerId, assignedChannels = []) => {
    const channelSelected = isChannelSelected(assignedOfferChannels, channelId)
    const channelAvailable = isChannelAvailable(
      assignedOfferChannels,
      channelId
    )
    if (!channelAvailable) {
      return
    }

    if (channelSelected) {
      setAssignedOfferChannels((prev) =>
        updateChannelSelection(prev, channelId, false)
      )

      offerChannelApi.updateOfferChannel({
        abEmail,
        channelId,
        ivyOfferId,
        offerId,
        selected: false,
        selectedStoryId,
        umbrellaName,
      })
    } else {
      setAssignedOfferChannels((prev) =>
        updateChannelSelection(prev, channelId, true)
      )
      offerChannelApi.updateOfferChannel({
        abEmail,
        channelId,
        ivyOfferId,
        offerId,
        selected: true,
        selectedStoryId,
        umbrellaName,
      })
    }
  }

  return (
    <Table.Row align="center" key={offerId} data-testid={offerId}>
      <Table.Data className="umbrella-style" justify="flex-end" xs={3}>
        {keyValue ? (
          <FontAwesomeIcon icon={faKey} className="fa-duotone key" size="lg" />
        ) : null}
        {offerName}
      </Table.Data>
      {channels.map(({ channel_id: channelId = '' }, index) => (
        <Table.Data
          key={`${offerId}-${channelId}-radio`}
          justify="center"
          xs={true}
          className="radio-container"
          onClick={(e) => {
            if (isReadOnly) {
              return
            }
            handleChannelClick(channelId, offerId, assignedChannels)
            e.preventDefault()
          }}
          data-testid={`${offerId}-${channelId}-radio`}
          width="70px"
          style={{ borderLeft: index === 0 ? '1px solid' : 'none' }}
        >
          <CustomCheckBox
            isAvailable={isChannelAvailable(assignedOfferChannels, channelId)}
            isChecked={isChannelSelected(assignedOfferChannels, channelId)}
            isReadOnly={isReadOnly}
          />
        </Table.Data>
      ))}
    </Table.Row>
  )
}

const CustomCheckBox = ({
  isAvailable = false,
  isChecked = false,
  isReadOnly = false,
}) => (
  <div
    className={`radio-${isChecked ? 'checked' : 'unchecked'} radio-${
      isChecked ? 'checked' : 'unchecked'
    }-color${isAvailable && !isReadOnly ? '' : '-readonly not-allowed'}`}
  />
)

export default OfferChannelSelector
