import { Modal, Input, Card } from '@enterprise-ui/canvas-ui-react'
import isEmpty from 'lodash.isempty'
import { useState } from 'react'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

const ChangePageType = (props) => {
  const { changePages, setChangePagesModal, setChangePages, putWad, weeklyAd } =
    props
  const [checked, setChecked] = useState({ id: '', page: {} })

  const handleClose = () => {
    setChangePagesModal({
      digitalPages: [],
      page: {},
      printPages: [],
      show: false,
    })
    setChecked({ id: '', page: {} })
    setChangePages(false)
  }

  const handleSubmit = () => {
    var pages = []
    if (checked.id === '-1') {
      pages = [
        {
          id: changePages?.page?.id,
          lastUpdatedTimestamp: changePages?.page?.lastUpdatedTimestamp,
          lockoutDate: changePages?.page?.lockoutDate,
          pageFlip: true,
        },
      ]
    } else {
      pages = [
        {
          bookOneOldPageNumber: checked?.page?.bookOnePageNumber,
          id: changePages?.page?.id,
          lastUpdatedTimestamp: changePages?.page?.lastUpdatedTimestamp,
          lockoutDate: changePages?.page?.lockoutDate,
          pageFlip: true,
        },
        {
          bookOneOldPageNumber: changePages?.page?.bookOnePageNumber,
          id: checked?.page?.id,
          lastUpdatedTimestamp: checked?.page?.lastUpdatedTimestamp,
          lockoutDate: checked?.page?.lockoutDate,
          pageFlip: true,
        },
      ]
    }

    putWad({
      contactId: weeklyAd.contact.id,
      id: weeklyAd.id,
      lastUpdatedTimestamp: weeklyAd.lastUpdatedTimestamp,
      pages: pages,
    })
    handleClose()
  }

  const isDigitalPage =
    changePages?.page?.bookOnePageType === 'DIGITAL' &&
    changePages?.page?.bookOnePageNumber !== '-'

  return (
    <Modal
      headingText={`Change page ${changePages?.page?.bookOnePageNumber}`}
      isVisible={changePages.show}
      onApproveModal={handleClose}
      onRefuse={handleClose}
    >
      <div className="hc-pa-normal">
        {
          <Card className="hc-pa-dense hc-ma-dense" key={'-1'}>
            <Input.Checkbox
              id={'-1'}
              checked={checked.id === '-1'}
              label={`Change into ${isDigitalPage ? 'Print' : 'Digital'} Page`}
              value={'-1'}
              onChange={(event) => {
                event.target.checked
                  ? setChecked({ id: event.target.value, page: {} })
                  : setChecked('')
              }}
            />
          </Card>
        }
        {isDigitalPage
          ? changePages?.printPages?.map((printPage) => (
              <Card className="hc-pa-dense hc-ma-dense" key={printPage.id}>
                <Input.Checkbox
                  id={printPage.id}
                  checked={checked.id === String(printPage.id)}
                  label={`Switch with Page ${printPage.bookOnePageNumber}`}
                  value={printPage.id}
                  onChange={(event) => {
                    event.target.checked
                      ? setChecked({ id: event.target.value, page: printPage })
                      : setChecked({ id: '', page: {} })
                  }}
                />
              </Card>
            ))
          : changePages?.digitalPages?.map((digitalPage) => (
              <Card className="hc-pa-dense hc-ma-dense" key={digitalPage.id}>
                <Input.Checkbox
                  id={digitalPage.id}
                  checked={checked.id === String(digitalPage.id)}
                  label={`Switch with Page ${digitalPage.bookOnePageNumber}`}
                  value={digitalPage.id}
                  onChange={(event) => {
                    event.target.checked
                      ? setChecked({
                          id: event.target.value,
                          page: digitalPage,
                        })
                      : setChecked({ id: '', page: {} })
                  }}
                />
              </Card>
            ))}
        <CancelSubmitButtons
          handleDialogClose={handleClose}
          handleSubmit={handleSubmit}
          submitDisabled={isEmpty(checked)}
          canDelete={false}
        />
      </div>
    </Modal>
  )
}
export default ChangePageType
