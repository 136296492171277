import '../scss/Category.scss'

import {
  Card,
  Chip,
  Grid,
  ProgressBar,
  Tooltip,
  Form,
} from '@enterprise-ui/canvas-ui-react'
import {
  faAngleDown,
  faPlusCircle,
  faArrowRotateRight,
  faVideoCamera,
  faFilePdf,
  faGear,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useMemo, useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import PublishingApi from '../../api/creative-hub/PublishingApi'
import {
  convertCategoryDate,
  missingContacts,
  prepCategoryDateForUpdate,
} from '../util/helpers.js'

import BCMDetails from './BCMDetails.js'
import MediaUnit from './MediaUnit'
import MessageContacts from './MessageContacts'
import MessageSettings from './MessageSettings.js'
import MessagingDetails from './MessagingDetails.js'
import ExportPDFMediaUnit from './PDF/ExportPDFMediaUnit'

const Category = ({
  showEssence,
  mediaUnitCount,
  categoryInfo,
  projectId,
  marketOptions,
  categoryOptions,
  index,
  setSelectedOffer,
  setIsOfferDrawerOpen,
  selectedProject,
  setIsItemImageModalOpen,
  setItemSelected,
  updatedItemImage,
  canEdit = false,
  contactOptions,
  isPriorityCategory,
}) => {
  const api = useMemo(() => new PublishingApi(), [])
  const { label } = categoryInfo
  const [isVisible] = useState(true)
  const categoryId = useParams().categoryId
  const [isExpanded, setIsExpanded] = useState(categoryId == categoryInfo.id)

  const [isLoading, setIsLoading] = useState(false)
  const [creatingNewMediaUnit, setCreatingNewMediaUnit] = useState(false)
  const [mediaUnitData, setMediaUnitData] = useState(null)
  const [mediaUnitsLength, setMediaUnitsLength] = useState()
  const [isMediaUnitDraggable, setIsMediaUnitDraggable] = useState(true)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isBcmDrawerOpen, setIsBcmDrawerOpen] = useState(false)
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false)
  const [missingFields, setMissingFields] = useState()
  const [contactRolesInfo, setContactRolesInfo] = useState(
    categoryInfo.project_category_roles
      ? categoryInfo.project_category_roles
      : []
  )
  const [updateCategory, setUpdateCategory] = useState(false)
  const draggingItem = useRef()
  const dragOverItem = useRef()

  const {
    banner_intro = '',
    bcm_category = '',
    bg_color = '',
    end_date = '',
    intro_copy = '',
    intro_copy_160_600 = '',
    sr_text,
    landing_url = '',
    start_date = '',
    story_id = '',
    title = '',
  } = categoryInfo

  const [bannerIntro, setBannerIntro] = useState(banner_intro)
  const [bcmCategory, setBcmCategory] = useState(bcm_category)
  const [bgColor, setBgColor] = useState(bg_color)
  const [endDate, setEndDate] = useState(
    convertCategoryDate(end_date, selectedProject.end_date)
  )
  const [introCopy, setIntroCopy] = useState(intro_copy)
  const [introCopy160600, setIntroCopy160600] = useState(intro_copy_160_600)
  const [srText, setSrText] = useState(sr_text)
  const [landingUrl, setLandingUrl] = useState(landing_url)
  const [startDate, setStartDate] = useState(
    convertCategoryDate(start_date, selectedProject.start_date)
  )
  const [storyId, setStoryId] = useState(story_id)
  const [titleInput, setTitleInput] = useState(title)

  const createNewMediaUnit = async () => {
    try {
      if (creatingNewMediaUnit) {
        return
      }
      setCreatingNewMediaUnit(true)
      const categories = [{ id: categoryInfo.id.toString() }]
      const channels = isPriorityCategory
        ? [{ channel_id: 6, state: '' }]
        : [{ channel_id: 8, state: '' }] // Auto-populate with channel 8 (Category Amplification/Banner) or 6 (PriorityOffers)

      await api.createMediaUnit(
        mediaUnitsLength + 1,
        projectId,
        categories,
        channels,
        prepCategoryDateForUpdate(startDate),
        prepCategoryDateForUpdate(endDate)
      )

      await fetchMediaUnits()
      setCreatingNewMediaUnit(false)
      return
    } catch (e) {
      console.log('error', e)
    }
  }

  const fetchMediaUnits = async () => {
    try {
      setIsLoading(true)
      const categoryApiData = await api.getMediaUnitsLite(
        projectId,
        categoryInfo.id
      )
      setMediaUnitData(categoryApiData)
      setCreatingNewMediaUnit(false)
      setMediaUnitsLength(categoryApiData?.length)
    } catch (error) {
      setIsLoading(false)
      console.log('e', error)
    }
    setIsLoading(false)
  }

  const fetchMediaUnitsForPDF = async () => {
    const categoryApiData = await api.getMediaUnitsLite(
      projectId,
      categoryInfo.id
    )
    setMediaUnitData(categoryApiData)
    //setIsPDFLoading(false)
  }

  useEffect(() => {
    setMissingFields(missingContacts(contactRolesInfo))
    fetchMediaUnitsForPDF()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dragEnter = (event) => {
    event.dataTransfer.types.includes('media-unit') && event.preventDefault()
  }

  const dragOver = (event, index) => {
    event.dataTransfer.types.includes('media-unit') && event.preventDefault()
  }

  const handleDragStart = (event, position) => {
    event.dataTransfer.setData('media-unit', true)
    draggingItem.current = position
  }

  const handleDrop = (event, position, elementType) => {
    if (event.dataTransfer.types.includes('media-unit')) {
      dragOverItem.current = position
      const categoryDataCopy = [...mediaUnitData]
      const draggingItemContent = categoryDataCopy[draggingItem.current]
      if (
        categoryDataCopy[dragOverItem.current] &&
        categoryDataCopy[draggingItem.current] &&
        draggingItem.current !== dragOverItem.current
      ) {
        categoryDataCopy.splice(draggingItem.current, 1)
        categoryDataCopy.splice(dragOverItem.current, 0, draggingItemContent)
        let end, start
        if (draggingItem.current < dragOverItem.current) {
          start = draggingItem.current
          end = dragOverItem.current
        } else {
          start = dragOverItem.current
          end = draggingItem.current
        }
        //only update changed indicies
        let updatedMediaUnitList = []

        for (let index = start; index <= end; index++) {
          if (categoryDataCopy[index] !== undefined) {
            categoryDataCopy[index].order = index + 1
            updatedMediaUnitList.push(categoryDataCopy[index])
          }
        }

        api.reorderMediaUnits(updatedMediaUnitList, categoryInfo.id)
        setMediaUnitData(categoryDataCopy)
      }
    }
  }

  const handleCategorySave = async () => {
    try {
      const payload = {
        banner_intro: bannerIntro ? bannerIntro : false,
        bcm_category: bcmCategory,
        bg_color: bgColor,
        end_date: prepCategoryDateForUpdate(endDate),
        id: categoryInfo.id ? categoryInfo.id : '',
        intro_copy: introCopy,
        intro_copy_wide: introCopy160600,
        landing_url: landingUrl,
        language_tag: categoryInfo.language_tag,
        permutate: categoryInfo.permutate,
        project_category_roles: contactRolesInfo ? contactRolesInfo : [],
        sr_text: srText,
        start_date: prepCategoryDateForUpdate(startDate),
        story_id: storyId,
        title: titleInput,
      }

      await api.updateProjectCategory(payload, categoryInfo.id, projectId)
    } catch (e) {
      console.log('e', e)
    }
  }

  const handleExpandCategory = () => {
    if (!isExpanded) {
      setIsExpanded(true)
      let nextURL
      nextURL = `/publishing/${projectId}/${categoryInfo.id}`
      return window.history.replaceState(null, '', nextURL)
    } else {
      let nextURL
      nextURL = `/publishing/${projectId}`
      window.history.replaceState(null, '', nextURL)
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    if (updateCategory === true) {
      handleCategorySave()
      setUpdateCategory(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCategory])

  const category = () => {
    if (mediaUnitData?.length) {
      return mediaUnitData.map((mediaUnit, index) => (
        <div
          key={`media-unit-div-${mediaUnit.id}`}
          data-testid="media-units-for-category"
        >
          <Card
            onDragStart={(event) => {
              handleDragStart(event, index)
            }}
            onDragEnter={(event) => dragEnter(event)}
            onDragOver={(event) => dragOver(event, index)}
            onDrop={(event) =>
              handleDrop(
                event,
                index,
                event.dataTransfer.types.includes('media-unit')
              )
            }
            style={{ background: 'none' }}
            data-testid={'mediaUnit-' + mediaUnit.go_id}
            draggable={isMediaUnitDraggable}
            key={'mediaUnit-' + categoryInfo + '-' + mediaUnit.id}
            className="media-unit-card"
          >
            <MediaUnit
              projectId={projectId}
              mediaUnitData={mediaUnit}
              fetchMediaUnits={fetchMediaUnits}
              categoryTypeId={categoryInfo.id}
              marketOptions={marketOptions}
              categoryOptions={categoryOptions}
              key={index}
              index={index}
              setSelectedOffer={setSelectedOffer}
              setIsOfferDrawerOpen={setIsOfferDrawerOpen}
              setIsMediaUnitDraggable={setIsMediaUnitDraggable}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setItemSelected={setItemSelected}
              updatedItemImage={updatedItemImage}
              canEdit={canEdit}
              selectedProject={selectedProject}
              isPriorityCategory={isPriorityCategory}
            />
          </Card>
        </div>
      ))
    }
    if (!creatingNewMediaUnit) {
      return <div style={{ paddingLeft: '10px' }}>No Media Unit data</div>
    }
  }

  return (
    <div
      className={
        isVisible
          ? `visible-bucket ${isExpanded ? 'category-expanded' : ''}`
          : 'hidden-bucket'
      }
      data-testid="category-card"
      index={index}
    >
      <Grid.Container className={'category-text'} justify="space-between">
        <Grid.Item xs={8} className="category-title-container">
          <h2 className="category-title-container">
            <FontAwesomeIcon
              style={{ fill: 'black' }}
              display={'inline-block'}
              className={`clickable category-expander ${
                isExpanded
                  ? 'rotate-clockwise-45'
                  : 'rotate-counter-clockwise-45'
              }`}
              icon={faAngleDown}
              onClick={handleExpandCategory}
              size="lg"
              data-testid="expand-category"
            />
            <span className="category-name">{label}</span>
            {isPriorityCategory ? (
              <span className="category-title">
                <Form.Field
                  id={'title'}
                  value={titleInput}
                  onChange={(e) => setTitleInput(e.target.value)}
                  onBlur={(event) => {
                    if (event.target.value !== categoryInfo.title) {
                      setUpdateCategory(true)
                    }
                  }}
                  className="category-title-input"
                />
              </span>
            ) : null}
            {mediaUnitCount ? (
              <span className="media-unit-count">
                ({mediaUnitsLength ? mediaUnitsLength : mediaUnitCount.count})
              </span>
            ) : null}
            {showEssence &&
            mediaUnitCount !== undefined &&
            mediaUnitCount.video !== undefined &&
            mediaUnitCount.video ? (
              <Chip size="dense" className="media-unit-count">
                {
                  <div>
                    <FontAwesomeIcon
                      icon={faVideoCamera}
                      size="sm"
                      className="volume-up-icon"
                    />{' '}
                    Video
                  </div>
                }
              </Chip>
            ) : null}
          </h2>
        </Grid.Item>
        <Grid.Item
          xs={4}
          id="category-icon-bucket"
          align="right"
          justify="space-betwen"
          className="category-icons"
        >
          {isExpanded ? (
            <FontAwesomeIcon
              className="fa-solid fa-rotate-right category-icon"
              size="lg"
              icon={faArrowRotateRight}
              onClick={fetchMediaUnits}
            />
          ) : null}

          {isPriorityCategory ? (
            <FontAwesomeIcon
              data-testid="message-settings-modal-button"
              className="fa-light fa-gear category-icon"
              size="lg"
              icon={faGear}
              onClick={(e) => {
                setIsSettingsDrawerOpen(!isSettingsDrawerOpen)
              }}
            />
          ) : null}

          {contactOptions ? (
            <FontAwesomeIcon
              data-testid="user-modal-button"
              className="fa-user-group category-icon"
              style={{
                color: missingFields ? '#a81f1f' : '',
              }}
              size="lg"
              icon={faUserGroup}
              onClick={(e) => {
                setIsDrawerOpen(!isDrawerOpen)
              }}
            />
          ) : null}
          {mediaUnitData?.length && !isLoading ? (
            <PDFDownloadLink
              document={
                <ExportPDFMediaUnit
                  selectedCategory={label}
                  mediaUnitData={mediaUnitData}
                  selectedProject={selectedProject}
                />
              }
              className="category-icon"
              fileName={`CreativeHub_Publishing_${
                selectedProject?.project_name
                  ? selectedProject.project_name + '_'
                  : ''
              }${label}_${new Date().toLocaleDateString()}`}
            >
              <FontAwesomeIcon
                data-testid="pdf-modal-button"
                className="faFilePdf category-icon"
                size="lg"
                icon={faFilePdf}
              />
            </PDFDownloadLink>
          ) : null}
          {canEdit ? (
            <>
              <Tooltip content={`Add Media Unit`} location="left">
                <FontAwesomeIcon
                  className={
                    creatingNewMediaUnit ? 'fa-disabled' : 'category-icon'
                  }
                  icon={faPlusCircle}
                  size="lg"
                  onClick={createNewMediaUnit}
                  id="addBucket"
                  data-testid="add-media-unit"
                  data-cy={`addBucket-${index}`}
                />
              </Tooltip>
            </>
          ) : null}
        </Grid.Item>
      </Grid.Container>
      {isPriorityCategory && isExpanded ? (
        <MessagingDetails
          setLandingUrl={setLandingUrl}
          landingUrl={landingUrl}
          categoryInfo={categoryInfo}
          setUpdateCategory={setUpdateCategory}
          startDate={startDate}
          endDate={endDate}
          setStoryId={setStoryId}
          storyId={storyId}
          setBcmCategory={setBcmCategory}
          bcmCategory={bcmCategory}
          isBcmDrawerOpen={isBcmDrawerOpen}
          setIsBcmDrawerOpen={setIsBcmDrawerOpen}
        />
      ) : null}
      {isExpanded ? (
        isLoading ? (
          <ProgressBar
            className="progress-bar"
            indeterminate
            data-testid="progressBar"
          />
        ) : (
          category()
        )
      ) : null}
      {isDrawerOpen ? (
        <MessageContacts
          key={'contacts_' + categoryInfo.id}
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          setMissingFields={setMissingFields}
          categoryName={categoryInfo.name}
          contactOptions={contactOptions}
          setContactRolesInfo={setContactRolesInfo}
          contactRolesInfo={contactRolesInfo}
          setUpdateCategory={setUpdateCategory}
        />
      ) : null}
      {isBcmDrawerOpen ? (
        <BCMDetails
          setIsBcmDrawerOpen={setIsBcmDrawerOpen}
          isBcmDrawerOpen={isBcmDrawerOpen}
        />
      ) : null}
      {isSettingsDrawerOpen ? (
        <MessageSettings
          setUpdateCategory={setUpdateCategory}
          setIsSettingsDrawerOpen={setIsSettingsDrawerOpen}
          isSettingsDrawerOpen={isSettingsDrawerOpen}
          label={label}
          bannerIntro={bannerIntro}
          setBannerIntro={setBannerIntro}
          bgColor={bgColor}
          setBgColor={setBgColor}
          endDate={endDate}
          setEndDate={setEndDate}
          startDate={startDate}
          setStartDate={setStartDate}
          introCopy={introCopy}
          setIntroCopy={setIntroCopy}
          introCopy160600={introCopy160600}
          setIntroCopy160600={setIntroCopy160600}
          srText={srText}
          setSrText={setSrText}
        />
      ) : null}
    </div>
  )
}

export default Category
