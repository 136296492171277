import axios from 'axios'
import isEmpty from 'lodash.isempty'

import appConfig from '../../config/appConfig'
import offerMapper from '../../mappers/offerMapper'

export default class OfferApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_offer_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.root,
      timeout: 31000,
    })
    return this.client
  }

  getOffers = async (email, division, startDate) => {
    // const OFFER_STATUS_PARAM =
    //   '?statuses=live,ready,messaging,draft_coe,draft_merchant,review_merchant,review_coe'

    const START_DATE_PARAM = `?startDate=${startDate}`
    const AB_EMAIL_PARAM = email ? `&abEmail=${email}` : ''
    const DIVISION_PARAM = !isEmpty(division) ? `&divisionIds=${division}` : ''
    return await this.init_offer_api()
      .get(
        this.config.backend.offer +
          START_DATE_PARAM +
          DIVISION_PARAM +
          AB_EMAIL_PARAM
      )
      .then((res) => res.data.map((offer) => offerMapper(offer)))
      .catch((e) => {
        console.log(e)
        return []
      })
  }
}
