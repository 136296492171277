import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import paginationMapper from '../mappers/paginationMapper'

const useDPCISearch = (setLoading, query) => {
  const config = appConfig()
  const url = useRef(
    config.backend.root + config.backend.item + config.backend.dpciSearch
  )
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const getOptions = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(url.current + `?${query.toString()}`)
        mounted &&
          request.setResults(
            paginationMapper(response.data, (cont) => cont, 'content')
          )
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
    }
    if (request !== null) {
      let thenFunc =
        typeof request.then === 'function' ? request.then : () => {}
      getOptions().then(() => thenFunc())
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line
  }, [request])

  return setRequest
}
export default useDPCISearch
