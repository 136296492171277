import { addEmail } from '../forms/EmailVerificationHelper'

export const camelize = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '')

export const createSectionObj = (contactDetails) => {
  const flatContactDetails = {}
  const keyRef = {}
  contactDetails.forEach((categorySection) => {
    categorySection.contacts.forEach((contact) => {
      const { department, email_ids: emailIds, role } = contact

      keyRef[camelize(department) + role] = department
      flatContactDetails[camelize(department) + role] =
        Array.isArray(emailIds) && emailIds.length ? emailIds : []
    })
  })
  return { flatContactDetails, keyRef }
}

export const mapForSections = (arr) => {
  const size = 4
  const chunkedArray = []

  for (let i = 0; i < arr?.length; i++) {
    const last = chunkedArray[chunkedArray.length - 1]
    if (!last || last.length === size) {
      chunkedArray.push([arr[i]])
    } else {
      last.push(arr[i])
    }
  }
  return chunkedArray
}

export const stringFromEmailArr = (emailArr) => {
  const emailString = emailArr.reduce((acc, emailObj) => {
    const { value } = emailObj
    acc.push(addEmail(value))
    return acc
  }, [])
  return emailString
}
