export function divisionsToDivisionIds(divisions = []) {
  if (divisions) {
    return divisions.map((division) => division.id)
  } else {
    return []
  }
}

export function divisionIdsToDivisions(divisionIds = []) {
  if (divisionIds) {
    return divisionIds.map((id) =>
      DIVISION_LIST.find((element) => element.id === id)
    )
  }
}

const AA_COLOR = '#3838a3'
const HARDLINES_COLOR = '#b92c09'
const ESSENTIALS_COLOR = '#6e23b3'
const FOOD_COLOR = '#00796b'
const HOME_COLOR = '#118f9c'
const DEFAULT_COLOR = '#b3b3b3'

export const DIVISION_LIST = [
  { color: AA_COLOR, id: 1, label: 'Baby', shortCode: 'Baby' },
  { color: ESSENTIALS_COLOR, id: 18, label: 'Beauty', shortCode: 'Bty' },
  { color: FOOD_COLOR, id: 23, label: 'Beverages', shortCode: 'Bev' },
  { color: DEFAULT_COLOR, id: 35, label: 'Company Space', shortCode: 'Cspg' },
  { color: ESSENTIALS_COLOR, id: 19, label: 'Cosmetics', shortCode: 'Csm' },
  {
    color: HOME_COLOR,
    id: 27,
    label: 'Decorative Home',
    shortCode: 'Decfurn',
  },
  { color: HOME_COLOR, id: 28, label: 'Domestics', shortCode: 'Dom' },
  {
    color: FOOD_COLOR,
    id: 24,
    label: 'Dry Grocery/Dairy Frozen',
    shortCode: 'Ddf',
  },
  {
    color: HARDLINES_COLOR,
    id: 13,
    label: 'Entertainment',
    shortCode: 'Ent',
  },
  {
    color: HARDLINES_COLOR,
    id: 12,
    label: 'Home Electronics',
    shortCode: 'Elec',
  },
  {
    color: ESSENTIALS_COLOR,
    id: 20,
    label: 'Household/Paper',
    shortCode: 'Hsp',
  },
  {
    color: AA_COLOR,
    id: 2,
    label: 'Intimates/Hosiery/Sleepwear',
    shortCode: 'Ihs',
  },
  { color: AA_COLOR, id: 3, label: 'Jewelry/Accessories', shortCode: 'Jac' },
  { color: AA_COLOR, id: 4, label: 'Kids', shortCode: 'Kids' },
  { color: HOME_COLOR, id: 29, label: 'Kitchen', shortCode: 'Ktn' },
  { color: AA_COLOR, id: 5, label: 'Mens', shortCode: 'Mens' },
  {
    color: HARDLINES_COLOR,
    id: 14,
    label: 'Mobile/Services/Portable Electronics',
    shortCode: 'Mob',
  },
  {
    color: AA_COLOR,
    id: 6,
    label: 'Newborns Infants Toddlers',
    shortCode: 'Nit',
  },
  {
    color: ESSENTIALS_COLOR,
    id: 21,
    label: 'Over The Counter',
    shortCode: 'Otc',
  },
  { color: HOME_COLOR, id: 33, label: 'Outdoor Living', shortCode: 'Odl' },
  { color: AA_COLOR, id: 7, label: 'Performance', shortCode: 'Perf' },
  { color: ESSENTIALS_COLOR, id: 22, label: 'Pets', shortCode: 'Pets' },
  {
    color: FOOD_COLOR,
    id: 26,
    label: 'Produce/Floral/Bakery/Meat/Deli',
    shortCode: 'Fresh',
  },
  { color: AA_COLOR, id: 8, label: 'Ready to Wear', shortCode: 'Rtw' },
  { color: HOME_COLOR, id: 30, label: 'Seasonal', shortCode: 'Ssl' },
  { color: AA_COLOR, id: 9, label: 'Shoes', shortCode: 'Sho' },
  { color: FOOD_COLOR, id: 25, label: 'Snacks/Candy', shortCode: 'Snc' },
  {
    color: HARDLINES_COLOR,
    id: 15,
    label: 'Sporting Goods',
    shortCode: 'Sprt',
  },
  { color: HOME_COLOR, id: 31, label: 'Stationery', shortCode: 'Stat' },
  { color: HOME_COLOR, id: 32, label: 'Storage/Utility', shortCode: 'Stu' },
  { color: AA_COLOR, id: 10, label: 'Swim', shortCode: 'Swm' },
  { color: HARDLINES_COLOR, id: 16, label: 'Toys', shortCode: 'Toys' },
  { color: HARDLINES_COLOR, id: 17, label: 'Video Games', shortCode: 'Vgam' },
  { color: AA_COLOR, id: 11, label: 'Young Contemporary', shortCode: 'Yco' },
]
