export const weekOptions = [
  {
    disabled: false,
    label: 'Week 1',
    value: '1',
  },
  {
    disabled: false,
    label: 'Week 2',
    value: '2',
  },
  {
    disabled: false,
    label: 'Week 3',
    value: '3',
  },
  {
    disabled: false,
    label: 'Week 4',
    value: '4',
  },
  {
    disabled: false,
    label: 'Week 5',
    value: '5',
  },
]

export const quarterOptions = [
  {
    disabled: false,
    label: 'Q1',
    value: 'Q1',
  },
  {
    disabled: false,
    label: 'Q2',
    value: 'Q2',
  },
  {
    disabled: false,
    label: 'Q3',
    value: 'Q3',
  },
  {
    disabled: false,
    label: 'Q4',
    value: 'Q4',
  },
]

export const monthOptions = [
  {
    disabled: false,
    label: 'January',
    value: 'January',
  },
  {
    disabled: false,
    label: 'February',
    value: 'February',
  },
  {
    disabled: false,
    label: 'March',
    value: 'March',
  },
  {
    disabled: false,
    label: 'April',
    value: 'April',
  },
  {
    disabled: false,
    label: 'May',
    value: 'May',
  },
  {
    disabled: false,
    label: 'June',
    value: 'June',
  },
  {
    disabled: false,
    label: 'July',
    value: 'July',
  },
  {
    disabled: false,
    label: 'August',
    value: 'August',
  },
  {
    disabled: false,
    label: 'September',
    value: 'September',
  },
  {
    disabled: false,
    label: 'October',
    value: 'October',
  },
  {
    disabled: false,
    label: 'November',
    value: 'November',
  },
  {
    disabled: false,
    label: 'December',
    value: 'December',
  },
]

export const groups = {
  'APP-OAUTH2-CrtvHub-BCM': 'Bcm',
  'APP-OAUTH2-CrtvHub-CopyWriter': 'Copywriter',
  'APP-OAUTH2-CrtvHub-CreativeArt': 'CreativeArt',
  'APP-OAUTH2-CrtvHub-CreativeProd': 'CreativeProd',
  'APP-OAUTH2-CrtvHub-Marketing': 'Marketing',
  'APP-OAUTH2-CrtvHub-PROJMGR': 'ProjMgr',
}

export const formatContacts = (contactsByEntitlement) => {
  const flatContactDetails = {}
  contactsByEntitlement?.forEach((entitlement = {}) => {
    const { group = '' } = entitlement
    const listofMemberNames = []
    entitlement?.members?.forEach((contact) => {
      const { display_name } = contact
      listofMemberNames.push(display_name)
    })
    if (group) {
      flatContactDetails[groups[group]] = [
        { id: 0, label: 'Not Applicable', value: 'Not Applicable' },
        ...mapPmm(listofMemberNames),
      ]
    }
  })

  return flatContactDetails
}

export const mapPmm = (pmmArray = []) =>
  pmmArray.map((option, index) => ({
    id: index + 1,
    label: option,
    value: option,
  }))
