import axios from 'axios'

import appConfig from '../../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../../creativeHubGlobal/util/AuthUtil'

export default class ItemApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_item_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 361000,
    })
    return this.client
  }

  postItemsByOfferUUID = async (offerUUID, items, storyId) =>
    await this.init_item_api()
      .post(
        `${this.config.markethub.url}item/offer/${offerUUID}/story/${storyId}`,
        items
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  getCreativeSummary = async (
    projectId,
    storyId,
    channelIds = [],
    pageNumber = 1
  ) => {
    let creativeSummaryApiURL = `${this.config.markethub.url}item/story/${projectId}/${storyId}?page=${pageNumber}&size=5&allRecords=false`
    if (channelIds.length > 0) {
      creativeSummaryApiURL = `${creativeSummaryApiURL}&channelIds=${channelIds}`
    }
    return await this.init_item_api()
      .get(creativeSummaryApiURL)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return e.response.status
      })
  }

  getUmbrellaCreativeSummary = async (
    projectId,
    storyId,
    umbrellaId,
    channelIds = []
  ) => {
    let creativeSummaryApiURL = `${this.config.markethub.url}item/umbrella/${projectId}/${storyId}/${umbrellaId}`
    if (channelIds.length > 0) {
      creativeSummaryApiURL = `${creativeSummaryApiURL}?channelIds=${channelIds}`
    }
    return await this.init_item_api()
      .get(creativeSummaryApiURL)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return null
      })
  }
}
