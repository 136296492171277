import { Spinner, Table } from '@enterprise-ui/canvas-ui-react'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment, useEffect, useMemo, useState } from 'react'

import '../../planning/scss/UmbrellaChannelSelector.scss'
import { displayOffersByChannel } from '../../../src/creativeHubGlobal/util/OfferUtil'
import { getPyramidFullName } from '../../planning/util/ProjectDetailsUtil'
import OfferChannelApi from '../api/projectExecution/OfferChannelApi'

import OfferChannelSelector from './OfferChannelSelector'

const UmbrellaOfferChannelSelector = ({
  channels = [],
  isLoading = false,
  marketingChannelFilter = [],
  selectedStoryId = '',
  pyramidType = '',
  projectId = '',
  story = {},
}) => {
  const [umbrellas, setUmbrellas] = useState([])
  const offerChannelApi = useMemo(() => new OfferChannelApi(), [])
  const pyramidTitle = pyramidType ? getPyramidFullName(pyramidType) : ''
  const { story_name = '', story_order } = story
  const channelIds = marketingChannelFilter?.map((channel) => channel.id)
  useEffect(() => {
    if (selectedStoryId) {
      offerChannelApi
        .getOfferChannels(projectId, selectedStoryId, channelIds)
        .then((response) => {
          let umbrellasResponse = response
          if (
            channelIds.length > 0 &&
            umbrellasResponse.length > 0 &&
            marketingChannelFilter.length > 0
          ) {
            for (let i = 0; i < umbrellasResponse.length; i++) {
              const offersByChannelFilter = displayOffersByChannel(
                umbrellasResponse[i].offer_responses,
                marketingChannelFilter
              )
              umbrellasResponse[i].offer_responses = offersByChannelFilter
            }
            setUmbrellas(umbrellasResponse)
          } else {
            setUmbrellas(umbrellasResponse)
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoryId])

  return isLoading ? (
    <div className="center-container">
      <Spinner />
    </div>
  ) : (
    <Table cellPadding="dense" alternateRowColor={false}>
      <TableHeader
        channels={channels}
        title={pyramidTitle}
        storyName={story_name}
        story_order={story_order}
      />
      {umbrellas.length > 0 ? (
        <>
          <TableBody
            channels={channels}
            umbrellas={umbrellas}
            projectId={projectId}
            selectedStoryId={selectedStoryId}
          />
        </>
      ) : (
        <Table.Body>
          <Table.Row>
            <Table.Data>No umbrellas present</Table.Data>
          </Table.Row>
        </Table.Body>
      )}
    </Table>
  )
}

const TableHeader = ({
  channels = [],
  title = '',
  storyName = '',
  story_order = '',
}) => (
  <Table.Head className="table-sticky">
    <Table.Row justify="left">
      <Table.Header className="table-header" xs={6}>
        {title}
      </Table.Header>
      <Table.Header className="table-header" xs={6}>
        Marketing Channels
      </Table.Header>
    </Table.Row>
    <Table.Row align="center">
      <Table.Header xs={3}>
        Story{' ' + story_order + ': ' + storyName}
      </Table.Header>
      <>
        {channels.map((channel) => (
          <Table.Header
            className="column-text-heading"
            width="70px"
            key={channel.channel_name}
            xs={true}
            justify="center"
          >
            {channel.channel_name}
          </Table.Header>
        ))}
      </>
    </Table.Row>
  </Table.Head>
)

const TableBody = ({
  channels = [],
  projectId = '',
  selectedStoryId = '',
  umbrellas = [],
}) => (
  <Table.Body>
    {umbrellas.map((umbrella = {}, ind) => (
      <Fragment key={`${umbrella.ivy_umbrella_id}-frag`}>
        <Table.Row className={'story-lone-style'} align="center">
          <Table.Data xs={3 + channels.length}>
            <b>Umbrella Name: </b>
            {umbrella.key ? (
              <FontAwesomeIcon
                icon={faKey}
                className="fa-duotone key"
                size="lg"
                color="black"
              />
            ) : null}
            {umbrella.name}
          </Table.Data>
        </Table.Row>
        {umbrella.offer_responses?.length > 0 ? (
          <>
            {umbrella.offer_responses?.map(
              ({
                id = '',
                name = '',
                channels: assignedChannels = [],
                ivy_offer_id = -1,
                is_key = false,
                offer_order = -1,
                owner_email_address = '',
              }) =>
                offer_order !== -1 ? (
                  <OfferChannelSelector
                    assignedChannels={assignedChannels}
                    channels={channels}
                    ivyOfferId={ivy_offer_id}
                    projectId={projectId}
                    offerId={id}
                    offerName={name}
                    abEmail={owner_email_address}
                    selectedStoryId={selectedStoryId}
                    umbrellaName={umbrella.name}
                    keyValue={is_key}
                  />
                ) : null
            )}
          </>
        ) : (
          <Table.Row className={'center-container '} align="center">
            <Table.Data>No offers are associated to this umbrella.</Table.Data>
          </Table.Row>
        )}
      </Fragment>
    ))}
  </Table.Body>
)

export default UmbrellaOfferChannelSelector
