const propItemMapper = (prop) => ({
  cutId: prop.cutId,
  deleted: prop.deleted,
  description: prop.description,
  dpci: prop.dpci,
  id: prop.id,
  keyNotes: prop.keyNotes,
  lastUpdatedTimestamp: prop.lastUpdatedTimestamp,
  notes: prop.notes,
  pageNumber: prop.pageNumber,
  sample: prop.sample,
  sampleId: prop.sampleId,
  samplePulled: prop.samplePulled,
})
export default propItemMapper
