import {
  Modal,
  Dialog,
  Grid,
  Form,
  Chip,
  Heading,
  Input,
  Tooltip,
  Spinner,
  GridContainer,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import {
  faExclamationTriangle,
  faCheck,
  faExclamationCircle,
  faImage,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import * as yup from 'yup'

import PlatformApi from '../api/platform-notifications/EmailVerification'
import ProductApi from '../api/pop-catalog/ProductApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import CatalogEditProductAssetHubModal from '../execution/components/CatalogEditProductAssetHubModal'
import { formatContacts } from '../planning/util/CreateEditProjectUtil'
import { CATEGORY_LIST } from '../util/CategoryColorMap'
import {
  inVendorView,
  inCreativePageView,
  inProductListView,
} from '../util/CheckLocation'
import { isAdminOrMerch, isVendor } from '../util/CheckRole'
import {
  convertImagetoBase64,
  errorCheckImage,
} from '../util/ConvertProductImage'
import { finalCopyString } from '../util/finalCopyUtil'
import { formatToServer, formatToUi } from '../util/MomentUtil'
import ReplaceFirst from '../util/ReplaceFirst'

import { formCategory, formatDPCI } from './util/ProductUtil'
import '../scss/modal_size.scss'

const dpciRegex = /^\d{3}-\d{2}-\d{4}$/

let productSchema = yup.object().shape({
  buyer: yup.string().required('Required'),
  category: yup.object().required('Required'),
  dpci: yup.string().required('Required').matches(dpciRegex, {
    message: 'DPCI in format 000-00-0000',
  }),
  name: yup.string().required('Required').nullable(),
  reg_unit_retail: yup
    .string()
    .required('Required')
    .matches(/(^\$(\d*)$)|(\d*(\.\d{2})$)/, {
      message: 'Reg unit retail format is either $99 or $99.99',
    }),
  vendor_contact_email: yup.object().required('Required'),
  vendor_name: yup.object().required('Required'),
  view: yup.string(),
})

const EditProduct = (props) => {
  const {
    editProduct = {},
    setEditProduct,
    view = '',
    setProducts,
    approvedVendors = [],
    role,
    calltheProductApi,
  } = props
  const platformApi = new PlatformApi()
  const [isDeleteDialog, setDeleteDialog] = useState(false)
  const wipeState = () => {
    setDeleteDialog(false)
    setEditProduct({ body: {}, show: false })
    setApprovedVendor()
    setApprovedVendorEmails([])
    setSingleVendorEmail()
  }
  const product = editProduct.body || {}
  const contactEmail = product?.vendor_contact_email || ''

  const editVendor = {
    id: product?.vendor_name || '',
    label: product?.vendor_name || '',
    value: product?.vendor_name || '',
    vendorEmails: [
      {
        id: contactEmail,
        label: contactEmail,
        value: contactEmail,
      },
    ],
  }
  const creativeProdUsers = ['APP-OAUTH2-CrtvHub-CreativeProd']
  const isAdminOrMerchAccess = isAdminOrMerch(role)
  const isVendorAccess = isVendor(role)

  const [contactsByEntitlement, setContactsByEntitlement] = useState()
  const entitlements = []
  const contactOptions = formatContacts(contactsByEntitlement)
  const { CreativeProd = [] } = contactOptions

  useEffect(() => {
    async function fetchData() {
      for (let i = 0; i < creativeProdUsers.length; i++) {
        await platformApi
          .getContactsByEntitlement(creativeProdUsers[i])
          .then((data) => {
            entitlements.push(data)
          })
          .catch((e) => console.log('error', e))
      }

      setContactsByEntitlement(entitlements)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [assetHubModalOpen, setAssetHubModalOpen] = useState(false)
  const [queryingCorona, setQueryingCorona] = useState(false)
  const [curRefImage, setCurRefImage] = useState(null)
  const [approvedVendor, setApprovedVendor] = useState()
  const [approvedVendorEmails, setApprovedVendorEmails] = useState([])
  const [singleVendorEmail, setSingleVendorEmail] = useState()
  const [copyIncludePrice, setCopyIncludePrice] = useState(false)

  useEffect(() => {
    setApprovedVendor(editVendor)
    setSingleVendorEmail(editVendor.vendorEmails[0])
    // eslint-disable-next-line
  }, [product.vendor_name])

  const productApi = new ProductApi()
  var imageErrors = []
  const query = new URLSearchParams()
  if (product?.reference_image === null && curRefImage === null) {
    productApi
      .getProductImage(query, product.id)
      .then((res) => {
        setCurRefImage(res.data?.reference_image)
      })
      .catch((e) => console.log(e))
  }

  const {
    age_range = '',
    art_director_notes = '',
    assembly_required = '',
    assorted = false,
    available_instore = false,
    available_online = false,
    brand = '',
    buyer = '',
    category = '',
    coupon_retail = '',
    deleted = false,
    dpci = '',
    features = '',
    fig_model = false,
    final_copy = '',
    final_image_origin = '',
    final_product_name = '',
    gender_age_preference = '',
    id: product_id = '',
    is_existing_image_has_talent = false,
    is_existing_target_asset = false,
    is_top_toy = false,
    is_usage_rights_current = false,
    is_vendor_image_approved = false,
    is_final_image_has_talent = false,
    ivy_offer_id = '',
    list_reg_retail = false,
    last_updated_timestamp = '',
    map_floor = '',
    mapped_item = false,
    model_needs = '',
    name = '',
    page_type = '',
    photo_notes = '',
    product_designer = '',
    promo_retail = '',
    prop_needs = '',
    reference_image = '',
    reg_unit_retail = '',
    sample_id = '',
    sample_status,
    sample_submitted = false,
    sample_turn_in_date = '',
    set_date = '',
    size_range = '',
    status = '',
    target_exclusive = false,
    tcin = '',
    tcin_type = '',
    validated_dpci = false,
    vendor_comments = '',
    vendor_funded = false,
    view: productView = '',
    vendor_contact_email = '',
    vendor_image_denied_reason = '',
    vendorName = '',
    depth = '',
    width = '',
    height = '',
    units = '',
  } = product
  return (
    !isEmpty(product) && (
      <Formik
        enableReinitialize
        validationSchema={productSchema}
        initialValues={{
          age_range,
          art_director_notes,
          assembly_required,
          assorted,
          available_instore,
          available_online,
          brand,
          buyer,
          category: formCategory(category),
          coupon_retail,
          deleted,
          depth,
          dpci,
          features,
          fig_model,
          final_copy,
          final_image_origin,
          final_product_name,
          gender_age_preference,
          height,
          is_existing_image_has_talent,
          is_existing_target_asset,
          is_final_image_has_talent,
          is_top_toy,
          is_usage_rights_current,
          is_vendor_image_approved,
          ivy_offer_id,
          list_reg_retail,
          map_floor,
          mapped_item,
          model_needs,
          name,
          nullImageError: false,
          page_type,
          photo_notes,
          product_designer,
          promo_retail,
          prop_needs,
          reference_image,
          reg_unit_retail,
          sample_id,
          sample_status,
          sample_submitted,
          sample_turn_in_date: formatToUi(sample_turn_in_date),
          set_date: formatToUi(set_date),
          sizeImageError: false,
          size_range,
          status,
          target_exclusive,
          tcin,
          tcin_type,
          units,
          validated_dpci,
          vendor_comments,
          vendor_contact_email: singleVendorEmail || vendor_contact_email,
          vendor_funded,
          vendor_image_denied_reason,
          vendor_name: approvedVendor || vendorName,
          view: productView,
          width,
        }}
        onSubmit={(values = {}, funcs) => {
          if (isEmpty(imageErrors)) {
            const {
              category = {},
              reference_image = '',
              sample_turn_in_date = '',
              set_date = '',
              product_designer,
            } = values
            productApi
              .putProduct({
                ...values,
                category: category.value,
                final_copy: finalCopyString(values, copyIncludePrice, ''),
                id: product_id,
                last_updated_timestamp,
                product_designer: product_designer?.value || '',
                reference_image,
                sample_turn_in_date: formatToServer(sample_turn_in_date),
                set_date: formatToServer(set_date),
                vendor_contact_email: singleVendorEmail.value,
                vendor_name: approvedVendor.value,
              })
              .then((thisProduct) =>
                setProducts((products) => {
                  ReplaceFirst(products, product_id, thisProduct)
                  products = products.filter((product) => !product.deleted)
                  if (calltheProductApi) {
                    calltheProductApi()
                  }
                  return products
                })
              )
            funcs.resetForm()
            wipeState()
          }
        }}
      >
        {({
          values = {},
          handleSubmit,
          handleReset,
          handleChange,
          handleBlur,
          touched = {},
          errors = {},
          setFieldValue,
        }) => {
          const {
            age_range = false,
            art_director_notes = '',
            assembly_required = false,
            assorted = false,
            available_instore = false,
            available_online = false,
            brand = '',
            buyer = '',
            category = '',
            coupon_retail = '',
            dpci = '',
            is_existing_image_has_talent = '',
            is_existing_target_asset = '',
            is_top_toy = '',
            is_usage_rights_current = '',
            is_vendor_image_approved = '',
            is_final_image_has_talent = '',
            ivy_offer_id = '',
            features = '',
            fig_model = '',
            final_product_name = '',
            list_reg_retail = false,
            map_floor = '',
            mapped_item = false,
            model_needs = '',
            name = '',
            nullImageError = false,
            page_type = '',
            photo_notes = '',
            product_designer = '',
            promo_retail = '',
            reference_image = curRefImage || '',
            reg_unit_retail = '',
            sample_id = '',
            sample_status = '',
            sample_submitted = false,
            sample_turn_in_date = '',
            set_date = '',
            sizeImageError = false,
            size_range = '',
            status = '',
            target_exclusive = false,
            tcin = '',
            vendor_comments = '',
            validated_dpci = false,
            vendor_funded = false,
            depth = '',
            width = '',
            height = '',
            units = '',
            final_image_origin = '',
            vendor_image_denied_reason = '',
          } = values
          function handleImage(event) {
            let { nullImageError, sizeImageError } = errorCheckImage(event)

            if (nullImageError) {
              imageErrors = ['Required']
            } else if (sizeImageError) {
              imageErrors = ['Image too large, please select another']
            } else {
              imageErrors = []
            }
            if (isEmpty(imageErrors)) {
              convertImagetoBase64(event, (result) => {
                setFieldValue('reference_image', result?.split('base64,')[1])
              })
            }
          }

          const handleDialogClose = () => {
            handleReset()
            wipeState()
          }

          let dpciFormatter = () => {
            setFieldValue('dpci', formatDPCI(dpci))
            if (dpciRegex.test(dpci)) {
              productApi
                .getCoronaInfo(dpci)
                .then((items) => {
                  setFieldValue('validated_dpci', !isEmpty(items))
                  if (!isEmpty(items)) {
                    const {
                      brand = '',
                      size = '',
                      age_range = '',
                      available_instore = '',
                      available_online = '',
                      assembly_required = '',
                      target_exclusive = '',
                      tcin = '',
                      name = '',
                      assorted = '',
                      depth = '',
                      width = '',
                      height = '',
                      units = '',
                    } = items[0]
                    setFieldValue('brand', brand)
                    setFieldValue('size_range', size)
                    setFieldValue('available_instore', available_instore)
                    setFieldValue('available_online', available_online)
                    setFieldValue('assorted', assorted)
                    setFieldValue('assembly_required', assembly_required)
                    setFieldValue('target_exclusive', target_exclusive)
                    setFieldValue('tcin', tcin)
                    setFieldValue('name', name)
                    setFieldValue('age_range', age_range)
                    setFieldValue('depth', depth)
                    setFieldValue('width', width)
                    setFieldValue('height', height)
                    setFieldValue('units', units)
                  }
                })
                .finally(() => {
                  productApi
                    .getPriceInfo(tcin)
                    .catch(() => {
                      setFieldValue('reg_unit_retail', '')
                      setFieldValue('map_floor', '')
                      setFieldValue('mapped_item', false)
                    })
                    .then((result) => {
                      if (result?.data) {
                        const { reg_retail = '', map_floor = '' } =
                          result?.data || {}
                        setFieldValue('reg_unit_retail', reg_retail)
                        setFieldValue('map_floor', map_floor)
                        setFieldValue('mapped_item', true)
                      }
                    })

                  setQueryingCorona(false)
                })
            } else {
              setFieldValue('validated_dpci', false)
            }
          }

          const handleDelete = () => {
            productApi
              .putProduct({
                deleted: true,
                id: product_id,
                last_updated_timestamp,
              })
              .then((thisProduct) =>
                setProducts((products) => {
                  ReplaceFirst(products, product_id, thisProduct)
                  products = products.filter((product) => !product.deleted)
                  return products
                })
              )
            handleDialogClose()
          }

          const handleRegUnitRetail = (evt) => {
            const newVal = evt.target.value
            const num = newVal.includes('$') ? newVal.split('$')[1] : newVal

            if (num.includes('.')) {
              setFieldValue('reg_unit_retail', num)
            } else {
              setFieldValue('reg_unit_retail', `$${num}`)
            }
          }

          return (
            <Modal
              isVisible={editProduct.show}
              headingText={'Edit Product'}
              onRefuse={handleDialogClose}
              className="larger_modal"
            >
              <div className="hc-pa-normal hc-pt-dense">
                <Grid.Container spacing="dense">
                  <Grid.Item xs={12}>
                    <Grid.Container
                      xs={12}
                      justify="space-between"
                      className="copyWriter-Header"
                      style={{ background: '#757575' }}
                    >
                      <Grid.Item>
                        <Heading size="5" style={{ color: '#fff' }}>
                          Product Information
                        </Heading>
                      </Grid.Item>
                      <Grid.Item>
                        <Form.Field
                          id="price_in_copy"
                          type="toggle"
                          value={copyIncludePrice}
                          label="Include Price in Copy"
                          onChange={() =>
                            setCopyIncludePrice(!copyIncludePrice)
                          }
                        />
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                  <div className="hc-pa-normal hc-pt-dense modal_first_container">
                    <GridContainer style={{ flex: 1 }}>
                      <Grid.Item style={{ width: '32%' }}>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <div style={{ flex: 1, marginRight: 5 }}>
                            <Form.Field
                              readOnly={inCreativePageView(view)}
                              id="dpci"
                              label="DPCI"
                              value={dpci}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.dpci && errors.dpci}
                              errorText={errors.dpci}
                              onKeyUp={dpciFormatter}
                            />
                          </div>

                          <div>
                            {queryingCorona ? (
                              <Spinner size="inline" />
                            ) : validated_dpci ? (
                              <Tooltip
                                content="DPCI found in Digital Item"
                                location="top"
                                className="hc-pa-normal hc-pt-dense"
                                style={{
                                  marginTop: '10px',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="lg"
                                  color="green"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                content="DPCI not found in Digital Item"
                                location="top"
                                style={{
                                  marginRight: 20,
                                  marginTop: '20px',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  size="lg"
                                  color="red"
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </Grid.Item>

                      <Grid.Item md={8} xs={8}>
                        <Form.Field
                          readOnly={inCreativePageView(view)}
                          id="name"
                          label="Product Name"
                          value={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.name && errors.name}
                          errorText={errors.name}
                        />
                      </Grid.Item>
                      <Grid.Item style={{ width: '32%' }}>
                        <Form.Field
                          id="tcin"
                          label="TCIN"
                          value={tcin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          readOnly
                        />
                      </Grid.Item>
                      <Grid.Item md={8} xs={8}>
                        <Form.Field
                          id="brand"
                          label="Product Brand"
                          value={brand}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.brand && errors.brand}
                          errorText={errors.brand}
                          readOnly
                        />
                      </Grid.Item>
                      <div style={{ display: 'inline-flex', marginLeft: 10 }}>
                        <Grid.Item style={{ marginTop: 10, width: '39%' }}>
                          <Autocomplete
                            id="category"
                            chipHeight="dense"
                            label="Category"
                            disabled={inCreativePageView(view)}
                            onUpdate={(_, value) =>
                              setFieldValue('category', value)
                            }
                            options={CATEGORY_LIST}
                            error={errors.category}
                            errorText={errors.category}
                            value={category}
                            renderChip={(cat, onRequestDelete) => (
                              <Chip
                                size="dense"
                                style={{
                                  backgroundColor: cat.color,
                                  border: '0px',
                                  color: 'white',
                                }}
                                onRequestDelete={onRequestDelete}
                              >
                                {cat.value}
                              </Chip>
                            )}
                          />
                        </Grid.Item>
                        <div className="modal_checkboxes">
                          <Grid.Item
                            style={{ paddingLeft: 15, paddingRight: '40px' }}
                          >
                            <Form.Field
                              id="assorted"
                              type="checkbox"
                              checked={assorted}
                              label="Assortment"
                              onChange={() =>
                                setFieldValue('assorted', !assorted)
                              }
                            />
                          </Grid.Item>
                          <Grid.Item style={{ paddingRight: '40px' }}>
                            <Form.Field
                              id="target_exclusive"
                              type="checkbox"
                              checked={target_exclusive}
                              label="Sold only at Target"
                              onChange={() =>
                                setFieldValue(
                                  'target_exclusive',
                                  !target_exclusive
                                )
                              }
                            />
                          </Grid.Item>
                          <Grid.Item style={{ paddingRight: '40px' }}>
                            {available_instore}
                            <Form.Field
                              id="available_instore"
                              type="checkbox"
                              checked={available_instore}
                              label="Sold Instore"
                              onChange={() =>
                                setFieldValue(
                                  'available_instore',
                                  !available_instore
                                )
                              }
                            />
                          </Grid.Item>
                          <Grid.Item>
                            <Form.Field
                              id="available_online"
                              type="checkbox"
                              checked={available_online}
                              label="Sold Online"
                              onChange={() =>
                                setFieldValue(
                                  'available_online',
                                  !available_online
                                )
                              }
                            />
                          </Grid.Item>
                        </div>
                      </div>
                    </GridContainer>
                    {inCreativePageView(view) && (
                      <>
                        <div xs={2}>
                          {curRefImage || reference_image ? (
                            <div className="image-title">
                              <p>Reference Image:</p>
                              <div className="reference-image">
                                <img
                                  src={`data:image/png;base64,${
                                    curRefImage || reference_image
                                  }`}
                                  onClick={() =>
                                    !isVendorAccess
                                      ? setAssetHubModalOpen(true)
                                      : setAssetHubModalOpen(false)
                                  }
                                  width="170px"
                                  alt="n/a"
                                />
                                {!isVendorAccess && assetHubModalOpen && (
                                  <CatalogEditProductAssetHubModal
                                    assetHubModalOpen={assetHubModalOpen}
                                    setAssetHubModalOpen={setAssetHubModalOpen}
                                    dpci={dpci}
                                    headingText={
                                      'Catalog AssetHub Image Search'
                                    }
                                  />
                                )}
                                <div style={{ marginBottom: 10, marginTop: 3 }}>
                                  {!isVendorAccess && (
                                    <Button
                                      size="dense"
                                      onClick={() => setAssetHubModalOpen(true)}
                                    >
                                      Click to Search Assethub
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <FontAwesomeIcon
                                icon={faImage}
                                size="lg"
                                color="red"
                              />
                              {' - '}
                              please add image
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {!inCreativePageView(view) && (
                      <>
                        <div style={{ marginTop: 20 }}>
                          <Grid.Item>
                            <Input.Label
                              style={{
                                fontSize: '12px',
                              }}
                            >
                              Change Reference Image
                            </Input.Label>
                            <input
                              id="reference_image"
                              type="file"
                              accept=".jpeg, .jpg"
                              onChange={handleImage}
                              onBlur={handleBlur}
                            />
                            <Input.Label style={{ marginTop: '20px' }}>
                              Please choose a JPEG file under 1MB
                            </Input.Label>
                            {!isEmpty(imageErrors) && (
                              <div style={{ color: '#b85300' }}>
                                <FontAwesomeIcon
                                  color="#b85300"
                                  icon={faExclamationCircle}
                                  style={{ marginRight: '5px' }}
                                />
                                {imageErrors}
                              </div>
                            )}
                          </Grid.Item>
                        </div>
                        <div
                          style={{
                            marginTop: 17,
                          }}
                        >
                          <Grid.Item>
                            <Input.Label style={{ fontSize: '12px' }}>
                              Reference Image
                            </Input.Label>
                            <div className="reference-image">
                              {curRefImage || reference_image ? (
                                <>
                                  <img
                                    src={`data:image/png;base64,${
                                      curRefImage || reference_image
                                    }`}
                                    onClick={() => setAssetHubModalOpen(true)}
                                    width="150px"
                                    alt="n/a"
                                  />
                                  <Button
                                    size="dense"
                                    onClick={() => setAssetHubModalOpen(true)}
                                  >
                                    Click to Search Assethub
                                  </Button>

                                  {assetHubModalOpen && (
                                    <CatalogEditProductAssetHubModal
                                      assetHubModalOpen={assetHubModalOpen}
                                      setAssetHubModalOpen={
                                        setAssetHubModalOpen
                                      }
                                      dpci={dpci}
                                      headingText={
                                        'Catalog AssetHub Image Search'
                                      }
                                    />
                                  )}
                                </>
                              ) : product.reference_image !== '' ? (
                                <Spinner />
                              ) : (
                                <div>
                                  <FontAwesomeIcon
                                    icon={faImage}
                                    size="lg"
                                    color="red"
                                  />
                                  {' - '}
                                  please add image
                                </div>
                              )}
                            </div>
                          </Grid.Item>
                        </div>
                      </>
                    )}
                  </div>

                  <GridContainer className="hc-pa-normal hc-pt-dense modal_second_container">
                    <Grid.Item style={{ width: '24%' }}>
                      <Form.Field
                        id="buyer"
                        label="Buyer Name"
                        value={buyer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.buyer && errors.buyer}
                        errorText={errors.buyer}
                      />
                    </Grid.Item>
                    <Grid.Item style={{ width: '25%' }}>
                      {!inVendorView(view) && (
                        <Form.Field
                          id="page_type"
                          type="select"
                          label="Page Type"
                          value={page_type}
                          onUpdate={setFieldValue}
                          onBlur={handleBlur}
                          options={[
                            { label: 'Full', value: 'full' },
                            { label: 'Half', value: 'half' },
                            { label: 'Item', value: 'item' },
                          ]}
                        />
                      )}
                      {inVendorView(view) && (
                        <Form.Field
                          id="reg_unit_retail"
                          label="Reg Unit Retail"
                          value={reg_unit_retail}
                          onChange={handleRegUnitRetail}
                          onBlur={handleBlur}
                          error={
                            errors.reg_unit_retail && touched.reg_unit_retail
                          }
                          errorText={errors.reg_unit_retail}
                        />
                      )}
                    </Grid.Item>
                    <Grid.Item md={3} xs={6}>
                      <Form.Field
                        id="map_floor"
                        label="Map Floor"
                        value={map_floor}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid.Item>
                    <Grid.Item md={3} xs={6} style={{ marginTop: '20px' }}>
                      <Form.Field
                        id="mapped_item"
                        type="checkbox"
                        style={{ display: 'flex' }}
                        checked={mapped_item}
                        label="Mapped Item"
                        onChange={() =>
                          setFieldValue('mapped_item', !mapped_item)
                        }
                      />
                    </Grid.Item>
                  </GridContainer>
                  <div
                    style={{ width: '25%' }}
                    className="hc-pa-normal hc-pt-dense"
                  >
                    <div className="modal_retail_container ">
                      {!inVendorView(view) && (
                        <Grid.Item xs={6} style={{ paddingRight: '15px' }}>
                          <Form.Field
                            id="reg_unit_retail"
                            label="Reg Unit Retail"
                            value={reg_unit_retail}
                            onChange={handleRegUnitRetail}
                            onBlur={handleBlur}
                            error={
                              errors.reg_unit_retail && touched.reg_unit_retail
                            }
                            errorText={errors.reg_unit_retail}
                            readOnly
                          />
                        </Grid.Item>
                      )}
                      <Grid.Item style={{ marginTop: '20px' }}>
                        {!inVendorView(view) && (
                          <Form.Field
                            id="list_reg_retail"
                            type="checkbox"
                            checked={list_reg_retail}
                            label="List Reg Retail"
                            onChange={() =>
                              setFieldValue('list_reg_retail', !list_reg_retail)
                            }
                          />
                        )}
                      </Grid.Item>
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                      <Grid.Item style={{ marginRight: '12px', width: '45%' }}>
                        {!inVendorView(view) && (
                          <Form.Field
                            id="promo_retail"
                            label="Promo Retail"
                            value={promo_retail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </Grid.Item>
                      <Grid.Item style={{ width: '45%' }}>
                        {!inVendorView(view) && (
                          <Form.Field
                            id="coupon_retail"
                            label="Coupon Retail"
                            value={coupon_retail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </Grid.Item>
                    </div>
                    <div className="modal-text-area">
                      <Grid.Item>
                        <Form.Field
                          id="features"
                          type="textarea"
                          label="Product Features / Info"
                          value={features}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </div>
                  </div>
                  <div className="modal-vendor-container">
                    {inCreativePageView(view) && (
                      <>
                        <div className="modal-depth">
                          <Grid.Item xs={6} style={{ paddingRight: '15px' }}>
                            <Form.Field
                              id="depth"
                              label="Depth"
                              value={depth}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                          <Grid.Item xs={5}>
                            <Form.Field
                              id="width"
                              label="Width"
                              value={width}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                        </div>
                        <div className="modal-height">
                          <Grid.Item style={{ paddingRight: '15px' }} xs={6}>
                            <Form.Field
                              id="height"
                              label="Height"
                              value={height}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                          <Grid.Item xs={5}>
                            <Form.Field
                              id="units"
                              label="Unit Measure"
                              value={units}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                        </div>
                        <div className="modal-text-area">
                          <Grid.Item>
                            <Form.Field
                              id="vendor_comments"
                              type="textarea"
                              label="Vendor Comments"
                              value={vendor_comments}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                        </div>
                      </>
                    )}
                    {inProductListView(view) && (
                      <>
                        <div className="modal-depth">
                          <Grid.Item xs={6} style={{ paddingRight: '15px' }}>
                            <Form.Field
                              id="depth"
                              label="Depth"
                              value={depth}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                          <Grid.Item xs={5}>
                            <Form.Field
                              id="width"
                              label="Width"
                              value={width}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                        </div>
                        <div className="modal-height">
                          <Grid.Item style={{ paddingRight: '15px' }} xs={6}>
                            <Form.Field
                              id="height"
                              label="Height"
                              value={height}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                          <Grid.Item xs={5}>
                            <Form.Field
                              id="units"
                              label="Unit Measure"
                              value={units}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                        </div>
                        <div style={{ marginTop: 7, width: '93%' }}>
                          {inProductListView(view) && (
                            <>
                              <Grid.Item>
                                <Form.Field
                                  id="ivy_offer_id"
                                  label="Ivy Offer ID"
                                  value={ivy_offer_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid.Item>
                              <Grid.Item xs={1}> </Grid.Item>
                            </>
                          )}
                          <Grid.Item style={{ marginTop: 7 }}>
                            {inProductListView(view) && (
                              <DatePicker
                                error={errors.set_date && touched.set_date}
                                errorText="Required"
                                id="set_date"
                                label="Set Date"
                                value={set_date}
                                onUpdate={setFieldValue}
                                location="bottom-left-left"
                              />
                            )}
                          </Grid.Item>
                          <Grid.Item
                            style={{ marginTop: 7, paddingBottom: 10 }}
                          >
                            {inProductListView(view) && isAdminOrMerch && (
                              <Form.Field
                                id="status"
                                type="select"
                                label="Status"
                                value={status}
                                onUpdate={setFieldValue}
                                onBlur={handleBlur}
                                options={[
                                  { label: 'Approved', value: 'accepted' },
                                  { label: 'Pending', value: 'pending' },
                                  { label: 'Rejected', value: 'denied' },
                                ]}
                                defaultValue={status}
                              />
                            )}
                          </Grid.Item>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="modal-final-container"
                    style={{ marginTop: '30px' }}
                  >
                    <Grid.Item className="modal-range-div">
                      <Grid.Item className="modal-range">
                        <Form.Field
                          id="size_range"
                          label="Size Range"
                          value={size_range}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item className="modal-size">
                        <Form.Field
                          id="age_range"
                          label="Age Range"
                          value={age_range}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </Grid.Item>
                    <div style={{ marginTop: '15px' }}>
                      <Grid.Item style={{ marginTop: '15px' }}>
                        <Form.Field
                          id="assembly_required"
                          label="Assembly Required"
                          value={assembly_required}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item style={{ marginTop: 20 }}>
                        {inProductListView(view) && (
                          <>
                            <Autocomplete
                              id="vendor_name"
                              allowCustomInputValue
                              label="Approved Vendors"
                              onUpdate={(_, value) => {
                                setFieldValue('vendor_name', value)
                                setApprovedVendor(value)
                                setApprovedVendorEmails(value?.vendorEmails)
                                if (value?.vendorEmails?.length === 1) {
                                  setSingleVendorEmail(value.vendorEmails[0])
                                  setFieldValue(
                                    'vendor_contact_email',
                                    value.vendorEmails[0]
                                  )
                                } else {
                                  setSingleVendorEmail()
                                }
                              }}
                              options={approvedVendors}
                              value={approvedVendor}
                              error={errors.vendor_name && touched.vendor_name}
                              errorText="Please enter a vendor"
                            />
                            <div className="vendor-email-div">
                              <div
                                xs={5}
                                style={{ marginRight: 20, width: '50%' }}
                              >
                                <Autocomplete
                                  allowCustomInputValue
                                  id="vendor_contact_email"
                                  value={singleVendorEmail}
                                  label="Vendor Email"
                                  options={approvedVendorEmails}
                                  onUpdate={(_, value) => {
                                    setFieldValue('vendor_contact_email', value)
                                    setSingleVendorEmail(value)
                                  }}
                                  error={
                                    errors.vendor_contact_email &&
                                    touched.vendor_contact_email
                                  }
                                  errorText="Please enter a vendor email"
                                />
                              </div>
                              <div style={{ marginTop: 20 }}>
                                <Form.Field
                                  id="vendor_funded"
                                  type="checkbox"
                                  value={vendor_funded}
                                  label="Vendor Funded"
                                  onChange={() =>
                                    setFieldValue(
                                      'vendor_funded',
                                      !vendor_funded
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </Grid.Item>
                      {inCreativePageView(view) && (
                        <>
                          <Grid.Item style={{ marginTop: '15px' }}>
                            <Form.Field
                              id="final_product_name"
                              label="Final Product Name"
                              value={final_product_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={inProductListView(view)}
                            />
                          </Grid.Item>
                        </>
                      )}
                      {inCreativePageView(view) && (
                        <>
                          <Grid.Item style={{ marginTop: '15px' }}>
                            <Form.Field
                              id="final_copy"
                              name="final_copy"
                              label="Final Copy"
                              value={finalCopyString(
                                values,
                                copyIncludePrice,
                                ''
                              )}
                              onBlur={handleBlur}
                              disabled
                            />
                          </Grid.Item>
                        </>
                      )}
                    </div>
                  </div>
                  <Grid.Item xs={1}> </Grid.Item>
                  {inProductListView(view) && (
                    <>
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="ivy_offer_id"
                          label="Ivy Offer ID"
                          value={ivy_offer_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1}> </Grid.Item>
                    </>
                  )}
                  <Grid.Item xs={3}>
                    {inProductListView(view) && (
                      <DatePicker
                        error={errors.set_date && touched.set_date}
                        errorText="Required"
                        id="set_date"
                        label="Set Date"
                        value={set_date}
                        onUpdate={setFieldValue}
                        location="bottom-left-left"
                      />
                    )}
                  </Grid.Item>
                  <Grid.Item xs={1}> </Grid.Item>
                  {inProductListView(view) && <Grid.Item xs={8}> </Grid.Item>}
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item xs={12}> </Grid.Item>
                  {!inCreativePageView(view) && (
                    <>
                      <Grid.Item xs={3}>
                        <Input.Label style={{ fontSize: '12px' }}>
                          Change Reference Image
                        </Input.Label>
                        <input
                          id="reference_image"
                          type="file"
                          accept=".jpeg, .jpg"
                          onChange={handleImage}
                          onBlur={handleBlur}
                        />
                        <Input.Label style={{ marginTop: '20px' }}>
                          Please choose a JPEG file under 1MB
                        </Input.Label>
                        {!isEmpty(imageErrors) && (
                          <div style={{ color: '#b85300' }}>
                            <FontAwesomeIcon
                              color="#b85300"
                              icon={faExclamationCircle}
                              style={{ marginRight: '5px' }}
                            />
                            {imageErrors}
                          </div>
                        )}
                      </Grid.Item>
                      <Grid.Item xs={1}> </Grid.Item>
                      <Grid.Item xs={3}>
                        <Input.Label style={{ fontSize: '12px' }}>
                          Reference Image
                        </Input.Label>
                        {curRefImage || reference_image ? (
                          <>
                            <img
                              src={`data:image/png;base64,${
                                curRefImage || reference_image
                              }`}
                              onClick={() =>
                                !isVendorAccess
                                  ? setAssetHubModalOpen(true)
                                  : setAssetHubModalOpen(false)
                              }
                              width="150px"
                              alt="n/a"
                            />
                            {!isVendorAccess && (
                              <Button
                                size="dense"
                                onClick={() => setAssetHubModalOpen(true)}
                              >
                                Click to Search Assethub
                              </Button>
                            )}

                            {!isVendorAccess && assetHubModalOpen && (
                              <CatalogEditProductAssetHubModal
                                assetHubModalOpen={assetHubModalOpen}
                                setAssetHubModalOpen={setAssetHubModalOpen}
                                dpci={dpci}
                                headingText={'Catalog AssetHub Image Search'}
                              />
                            )}
                          </>
                        ) : product.reference_image !== '' ? (
                          <Spinner />
                        ) : (
                          <div>
                            <FontAwesomeIcon
                              icon={faImage}
                              size="lg"
                              color="red"
                            />
                            {' - '}
                            please add image
                          </div>
                        )}
                      </Grid.Item>
                      <Grid.Item xs={1}> </Grid.Item>
                    </>
                  )}
                  {inProductListView(view) && (
                    <GridContainer justify="flex-end">
                      <Grid.Item xs={1}>
                        <Form.Field
                          id="depth"
                          label="Depth"
                          value={depth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1}>
                        <Form.Field
                          id="width"
                          label="Width"
                          value={width}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1}>
                        <Form.Field
                          id="height"
                          label="Height"
                          value={height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1}>
                        <Form.Field
                          id="units"
                          label="Unit Measure"
                          value={units}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </GridContainer>
                  )}
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item xs={3}>
                    {inProductListView(view) && isAdminOrMerchAccess && (
                      <>
                        <Autocomplete
                          id="vendor_name"
                          allowCustomInputValue
                          label="Approved Vendors"
                          onUpdate={(_, value) => {
                            setFieldValue('vendor_name', value)
                            setApprovedVendor(value)
                            setApprovedVendorEmails(value?.vendorEmails)
                            if (value?.vendorEmails?.length === 1) {
                              setSingleVendorEmail(value.vendorEmails[0])
                              setFieldValue(
                                'vendor_contact_email',
                                value.vendorEmails[0]
                              )
                            } else {
                              setSingleVendorEmail()
                            }
                          }}
                          options={approvedVendors}
                          value={approvedVendor}
                          error={errors.vendor_name && touched.vendor_name}
                          errorText="Please enter a vendor"
                        />

                        <Autocomplete
                          allowCustomInputValue
                          id="vendor_contact_email"
                          value={singleVendorEmail}
                          label="Vendor Email"
                          options={approvedVendorEmails}
                          onUpdate={(_, value) => {
                            setFieldValue('vendor_contact_email', value)
                            setSingleVendorEmail(value)
                          }}
                          error={
                            errors.vendor_contact_email &&
                            touched.vendor_contact_email
                          }
                          errorText="Please enter a vendor email"
                        />

                        <Form.Field
                          id="vendor_funded"
                          type="toggle"
                          value={vendor_funded}
                          label="Vendor Funded"
                          onChange={() =>
                            setFieldValue('vendor_funded', !vendor_funded)
                          }
                        />
                      </>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    {inProductListView(view) && isAdminOrMerchAccess && (
                      <Form.Field
                        id="status"
                        type="select"
                        label="Status"
                        value={status}
                        onUpdate={setFieldValue}
                        onBlur={handleBlur}
                        options={[
                          { label: 'Approved', value: 'accepted' },
                          { label: 'Pending', value: 'pending' },
                          { label: 'Rejected', value: 'denied' },
                        ]}
                        defaultValue={status}
                      />
                    )}
                  </Grid.Item>
                  {!inCreativePageView(view) && (
                    <>
                      {' '}
                      <Grid.Container
                        xs={12}
                        justify="space-between"
                        className="copyWriter-Header"
                        style={{ background: '#757575' }}
                      >
                        <Grid.Item>
                          <Heading size="5" style={{ color: '#fff' }}>
                            Sample Information
                          </Heading>
                        </Grid.Item>
                      </Grid.Container>
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="sample_status"
                          type="select"
                          label="Sample Status"
                          value={sample_status}
                          onUpdate={setFieldValue}
                          onBlur={handleBlur}
                          options={[
                            { label: 'Inventory', value: 'inventory' },
                            { label: 'Turn In', value: 'turn-in' },
                            { label: 'Missing', value: 'missing' },
                          ]}
                        />
                      </Grid.Item>
                      {inProductListView(view) && (
                        <Grid.Item xs={3}>
                          <Form.Field
                            id="sample_id"
                            label="Sample ID"
                            value={sample_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                      )}
                      <Grid.Item md={3} xs={6}>
                        <DatePicker
                          error={
                            errors.sample_turn_in_date &&
                            touched.sample_turn_in_date
                          }
                          errorText="Required"
                          id="sample_turn_in_date"
                          label="Sample Turn In Date"
                          value={sample_turn_in_date}
                          onUpdate={setFieldValue}
                          location="bottom-left-left"
                        />
                      </Grid.Item>
                      {inProductListView(view) && (
                        <Grid.Item xs={3}></Grid.Item>
                      )}
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="sample_submitted"
                          type="toggle"
                          value={sample_submitted}
                          label="Sample Submitted"
                          onChange={() =>
                            setFieldValue('sample_submitted', !sample_submitted)
                          }
                        />
                      </Grid.Item>
                    </>
                  )}
                  {inCreativePageView(view) && (
                    <>
                      {' '}
                      <Grid.Item
                        xs={12}
                        className="hc-ta-left hc-pa-dense"
                        style={{
                          background: '#757575',
                        }}
                      >
                        <Heading size="5" style={{ color: '#fff' }}>
                          Art Direction Information
                        </Heading>
                      </Grid.Item>
                      <div className="art-design" style={{ width: '100%' }}>
                        <Grid.Item style={{ marginRight: 30, width: '22%' }}>
                          <Form.Field
                            id="art_director_notes"
                            label="General Notes"
                            type="textarea"
                            value={art_director_notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                        <Grid.Item style={{ marginRight: 30, width: '23%' }}>
                          <Form.Field
                            id="photo_notes"
                            label="Photography Notes"
                            type="textarea"
                            value={photo_notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                        <Grid.Item style={{ marginRight: 25, width: '21%' }}>
                          <Form.Field
                            id="model_needs"
                            label="Talent Notes"
                            type="textarea"
                            value={model_needs}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                        <Grid.Item style={{ marginTop: 110 }}>
                          <Form.Field
                            id="fig_model"
                            type="checkbox"
                            checked={fig_model}
                            label="Fig/Model"
                            onChange={() =>
                              setFieldValue('fig_model', !fig_model)
                            }
                          />
                        </Grid.Item>
                      </div>
                    </>
                  )}
                  {inCreativePageView(view) && (
                    <>
                      {' '}
                      <Grid.Item
                        xs={12}
                        className="hc-ta-left hc-pa-dense"
                        style={{
                          background: '#757575',
                          marginTop: 10,
                        }}
                      >
                        <Heading size="5" style={{ color: '#fff' }}>
                          Production Design Information
                        </Heading>
                      </Grid.Item>
                      <div className="product_design">
                        <Grid.Item style={{ marginRight: 45, width: '21%' }}>
                          <Autocomplete
                            id={'product designer'}
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={(id, val) => {
                              setFieldValue('product_designer', val)
                            }}
                            value={{
                              id: 0,
                              label:
                                typeof product_designer === 'object' &&
                                product_designer
                                  ? product_designer.value
                                  : product_designer || '',
                              value:
                                typeof product_designer === 'object' &&
                                product_designer
                                  ? product_designer.value
                                  : product_designer || '',
                            }}
                            options={CreativeProd}
                            label={'Production Designer'}
                            noResultsMessage="Use Outlook Name"
                            error={values['product designer']?.length === 0}
                          />
                        </Grid.Item>

                        <div style={{ display: 'block', marginRight: 150 }}>
                          <Grid.Item style={{ marginTop: 10 }}>
                            <Form.Field
                              id="is_top_toy"
                              type="checkbox"
                              checked={is_top_toy}
                              label="Top Toy"
                              onChange={() => {
                                setFieldValue('is_top_toy', !is_top_toy)
                              }}
                            />
                          </Grid.Item>
                          <Grid.Item style={{ marginTop: 10 }}>
                            <Form.Field
                              id="is_existing_target_asset"
                              type="checkbox"
                              checked={is_existing_target_asset}
                              label="Existing Target Asset"
                              onChange={() =>
                                setFieldValue(
                                  'is_existing_target_asset',
                                  !is_existing_target_asset
                                )
                              }
                            />
                          </Grid.Item>
                          <Grid.Item style={{ marginTop: 10 }}>
                            <Form.Field
                              id="is_usage_rights_current"
                              type="checkbox"
                              checked={is_usage_rights_current}
                              label="Are Usage Rights Current"
                              onChange={() =>
                                setFieldValue(
                                  'is_usage_rights_current',
                                  !is_usage_rights_current
                                )
                              }
                            />
                          </Grid.Item>
                          <Grid.Item style={{ marginTop: 10 }}>
                            <Form.Field
                              id="is_existing_image_has_talent"
                              type="checkbox"
                              checked={is_existing_image_has_talent}
                              label="Existing Image Has Talent"
                              onChange={() =>
                                setFieldValue(
                                  'is_existing_image_has_talent',
                                  !is_existing_image_has_talent
                                )
                              }
                            />
                          </Grid.Item>
                        </div>
                        <div style={{ display: 'block', width: '22%' }}>
                          <Grid.Item>
                            <Form.Field
                              id="is_vendor_image_approved"
                              type="checkbox"
                              checked={is_vendor_image_approved}
                              label="Vendor Image Approved"
                              onChange={() =>
                                setFieldValue(
                                  'is_vendor_image_approved',
                                  !is_vendor_image_approved
                                )
                              }
                            />
                          </Grid.Item>
                          <Grid.Item
                            style={{
                              marginRight: 25,
                              marginTop: 20,
                            }}
                          >
                            <Form.Field
                              id="vendor_image_denied_reason"
                              label="Vendor Image Denied Reason"
                              value={vendor_image_denied_reason}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid.Item>
                        </div>
                        <div className="final_image">
                          <Grid.Item>
                            <Form.Field
                              id="is_final_image_has_talent"
                              type="checkbox"
                              checked={is_final_image_has_talent}
                              label="Final Image Has Talent"
                              onChange={() =>
                                setFieldValue(
                                  'is_final_image_has_talent',
                                  !is_final_image_has_talent
                                )
                              }
                            />
                          </Grid.Item>
                          <Grid.Item style={{ marginRight: 25, marginTop: 20 }}>
                            <Form.Field
                              id="final_image_origin"
                              label="Final Image Origin"
                              type="select"
                              value={final_image_origin}
                              onBlur={handleBlur}
                              onUpdate={setFieldValue}
                              options={[
                                { label: '', value: 'blank' },
                                {
                                  label: 'Direct Pickup',
                                  value: 'direct_pickup',
                                },
                                { label: 'Target Image', value: 'target_item' },
                                {
                                  label: 'Target Photoshoot',
                                  value: 'target_photoshoot',
                                },
                                {
                                  label: 'Vendor Image',
                                  value: 'vendor_image',
                                },
                              ]}
                            />
                          </Grid.Item>
                        </div>
                      </div>
                    </>
                  )}
                  {!inVendorView(view) && !inCreativePageView(view) && (
                    <Grid.Item xs={6}>
                      <Form.Field
                        id="final_product_name"
                        label="Final Product Name"
                        value={final_product_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={inProductListView(view)}
                      />
                      <Form.Field
                        id="final_copy"
                        name="final_copy"
                        label="Final Copy"
                        value={finalCopyString(values, copyIncludePrice, '')}
                        onBlur={handleBlur}
                        disabled
                      />
                    </Grid.Item>
                  )}
                </Grid.Container>
                {(nullImageError || sizeImageError) && (
                  <div style={{ color: '#b85300', float: 'right' }}>
                    <FontAwesomeIcon
                      color="#b85300"
                      icon={faExclamationCircle}
                      style={{ marginRight: '5px' }}
                    />
                    Errors somewhere on page. Please double check input.
                  </div>
                )}
                <CancelSubmitButtons
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                  handleDelete={setDeleteDialog}
                  canDelete
                />
              </div>
              <Dialog
                isVisible={isDeleteDialog}
                headingText={'Are you sure you want to delete this Product?'}
                onRefuse={() => setDeleteDialog(false)}
                refuseButtonText="Cancel"
                onApprove={handleDelete}
                approveButtonText={'Delete'}
              />
            </Modal>
          )
        }}
      </Formik>
    )
  )
}
export default EditProduct
