import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import { handleFireflySuccess } from '../../util/FireflyHelper'
import { getActiveTab } from '../util/GroupedOfferUtils'

import { CreateGroupedOffer } from './CreateGroupedOffer'
import { GroupedOfferDetails } from './GroupedOfferDetails'
import { UserGroupedOffers } from './UserGroupedOffers'
import { ViewGroupedOffers } from './ViewGroupedOffers'

export const GroupedOffers = ({ session = {} }) => {
  const navigate = useNavigate()
  const { '*': viewName = 'create' } = useParams()
  const pathString = useLocation()
  const pathArray = pathString.pathname.split('/')
  const groupId = pathArray[3] ? pathArray[3] : null
  const { activeTab = 'create' } = getActiveTab(viewName)
  const [currentActiveTab, setCurrentActiveTab] = useState(activeTab)
  handleFireflySuccess('pageload', 'Grouped Offers', 'success', session)
  return (
    <>
      <div className="hc-pa-none groupdOffersContainer">
        <Tabs
          activeTab={currentActiveTab}
          disableAdaptive={true}
          onTabSelect={(event, tab) => {
            setCurrentActiveTab(tab.name)

            navigate(`/groupedOffers/${tab.name}`)
          }}
          style={{ fontWeight: 700 }}
        >
          <Tabs.Item name="create" style={{ fontWeight: 700 }}>
            Create Grouped Offer
          </Tabs.Item>
          <Tabs.Item name="view" style={{ fontWeight: 700 }}>
            View Grouped Offers
          </Tabs.Item>
          <Tabs.Item name="edit" style={{ fontWeight: 700 }}>
            Manage Your Grouped Offers
          </Tabs.Item>
        </Tabs>

        {currentActiveTab === 'create' && <CreateGroupedOffer />}
        {currentActiveTab === 'view' && <ViewGroupedOffers />}
        {currentActiveTab === 'edit' && <UserGroupedOffers />}
        {currentActiveTab === 'edit' && groupId !== null && (
          <GroupedOfferDetails />
        )}
      </div>
    </>
  )
}
