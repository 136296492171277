import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import itemPatchMapper from '../mappers/itemPatchMapper'
import ReplaceFirst from '../util/ReplaceFirst'

const usePutItemPatch = (setLoading, setWeeklyAd, setToast) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.itemPatch + '/')
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const putItemPatch = async () => {
      mounted && setLoading(true)
      try {
        const id = request.body.id
        let response = await axios.put(url.current + id, request.body)
        let thisItemPatch = itemPatchMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            const item = w.pages
              .find((page) => page.id === request.pageId)
              .stories.find((story) => story.id === request.storyId)
              .cuts.find((cut) => cut.id === request.cutId)
              .items.find((item) => item.id === request.itemId)
            ReplaceFirst(item.itemPatches, id, thisItemPatch)
            /* As the data is not passed from expandable item and we are not closing & opening the modal
               and expecting to see the updated data, the only way it is working is when we are pushing directly with hook */
            if (request.setViewItemPatches) {
              request.setViewItemPatches({
                body: item,
                cutId: request.cutId,
                itemId: request.itemId,
                pageId: request.pageId,
                show: true,
                storyId: request.storyId,
              })
            }
            return w
          })
        if (setToast && mounted) {
          setToast({
            heading: 'Item Added',
            isVisible: true,
            message: 'Good work.',
            type: 'success',
          })
        }
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      putItemPatch()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd, setToast])

  return setRequest
}
export default usePutItemPatch
