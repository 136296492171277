import { Table } from '@enterprise-ui/canvas-ui-react'

import IndicatorChip from './IndicatorChip'
import '../scss/POP-table.scss'

const PropTable = (props) => {
  const propItems = props.data.props
  const { setEditSampleProp } = props

  const columnData = [
    { id: 'pageNumber', label: 'Page No.', size: 1 },
    { id: 'dpci', label: 'DPCI', size: 1 },
    { id: 'description', label: 'Item Name', size: 3 },
    { id: 'sample', label: 'Status', size: 1 },
    { id: 'sampleId', label: 'Sample ID', size: 2 },
    { id: 'samplePulled', label: 'Collected', size: 1 },
    { id: 'notes', label: 'Notes', size: 3 },
  ]

  return propItems ? (
    <div className="hc-pa-normal hc-pt-normal">
      <Table name={props.tableName}>
        <Table.Head>
          <Table.Row>
            {columnData.map((col) => (
              <Table.Header key={col.id} xs={col.size} className="hc-ta-center">
                {col.label}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body className="POP-table">
          {propItems.map((datum) => (
            <Table.Row
              key={datum.id}
              justify="center"
              onClick={() =>
                setEditSampleProp({
                  body: datum,
                  show: true,
                })
              }
            >
              {columnData.map((col) => (
                <Table.Data
                  xs={col.size}
                  key={`${datum.id}-${col.id}`}
                  className="hc-ta-center"
                >
                  <ItemCell datum={datum} col={col} />
                </Table.Data>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  ) : (
    <div />
  )
  function ItemCell(props) {
    const datum = props.datum
    const col = props.col

    switch (col.id) {
      case 'samplePulled':
        if (datum[col.id] === null || datum[col.id] === -1) return null
        return <IndicatorChip value={datum[col.id]} field="pulled" />
      default:
        return datum[col.id]
    }
  }
}
export default PropTable
