import {
  Card,
  Grid,
  Heading,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'

import ContactsApi from '../../api/creative-hub/ContactsApi'
import PlatformApi from '../../api/platform-notifications/EmailVerification'
import {
  emailSchema,
  validateWeeklyAdEmailsContacts,
  addEmail,
} from '../../forms/EmailVerificationHelper'
import {
  camelize,
  createSectionObj,
  mapForSections,
  stringFromEmailArr,
} from '../../util/CategoryUtil'
import { canEditSystemAndProjectContacts } from '../../util/CheckRole'
import {
  handleFireflyInit,
  handleFireflyFailure,
  handleFireflySuccess,
} from '../../util/FireflyHelper'
import { mapPmmContacts } from '../../util/mapPmmHelper'
import '../../scss/Contacts.scss'

const Contacts = (props) => {
  const { role = [], session = {} } = props

  const platformApi = new PlatformApi()
  const contactsApi = new ContactsApi()

  const makeToast = ToastProvider.useToaster()

  const [initialLoading, setInitialLoading] = useState(true)
  const [defaultContacts, setDefaultContacts] = useState({})
  const [contactsApiRes, setContactsApiRes] = useState([])
  const [contactsKeyRef, setContactsKeyRef] = useState(null)
  const canEdit = role?.some((r) => canEditSystemAndProjectContacts(r))
  const viewName = 'Contacts'

  useEffect(() => {
    handleFireflyInit('pageload', viewName, 'success', session)
    contactsApi
      .getDefaultContacts()
      .then((data) => {
        setInitialLoading(false)
        setContactsApiRes(data)
        const { flatContactDetails, keyRef } = createSectionObj(data)
        setContactsKeyRef(keyRef)
        setDefaultContacts(flatContactDetails)

        handleFireflySuccess('pageload', viewName, 'success', session)
      })
      .catch((e) => {
        console.log(e)
        handleFireflyFailure('pageload', viewName, 'error', session)
      })
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])

  if (initialLoading && !contactsApiRes.length && defaultContacts) {
    return (
      <span>
        <Spinner />
      </span>
    )
  }

  const initialValues = Object.keys(defaultContacts)?.reduce(
    (acc, contact) => {
      acc[contact] = mapPmmContacts(defaultContacts[contact])
      return acc
    },
    { invalidEmails: [] }
  )

  return (
    <Card>
      {initialLoading ? (
        <Grid.Container justify="center" className="hc-pa-dense">
          <Spinner />
        </Grid.Container>
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={emailSchema}
        >
          {({ values, errors, handleSubmit, setFieldValue, resetForm }) => {
            const handleEmailFieldUpdate = async (
              id,
              value,
              category,
              role
            ) => {
              try {
                setFieldValue(id, value)
                let valuesCopy = { ...values }
                valuesCopy[id] = value
                const res = await validateWeeklyAdEmailsContacts(
                  valuesCopy,
                  platformApi,
                  Object.keys(defaultContacts)
                )
                setFieldValue('invalidEmails', res)
                const emailsString = stringFromEmailArr(value)
                const reqContainsInvalid = () => {
                  let contains = false
                  res.forEach((email) => {
                    if (emailsString.includes(addEmail(email))) {
                      contains = true
                    }
                  })
                  return contains
                }

                return reqContainsInvalid()
                  ? makeToast({
                      heading: 'Failed to update contacts..',
                      isVisible: true,
                      message: 'Something went wrong, try again...',
                      type: 'error',
                    })
                  : contactsApi.putDefaultContacts(
                      JSON.stringify({
                        category,
                        department: contactsKeyRef[id],
                        email_ids: emailsString,
                        role: role,
                      })
                    )
              } catch (e) {
                console.log('e', e)
              }
            }

            return (
              <div className="hc-pa-normal">
                <Grid.Container spacing="dense">
                  {contactsApiRes.map((categoryData, index) => {
                    const { category, contacts } = categoryData
                    return (
                      <Grid.Item className="hc-ta-center" xs={12} key={index}>
                        <Heading size={4}>{category}</Heading>
                        {mapForSections(contacts).map((sectionsOfFour, idx) => (
                          <div key={idx} className="hc-pa-normal">
                            <Grid.Container spacing="dense">
                              {sectionsOfFour.map((indivCategory, i) => {
                                const { department, role } = indivCategory
                                const keyName = camelize(department) + role
                                return (
                                  <Grid.Item xs={3} key={i}>
                                    <Autocomplete
                                      id={keyName}
                                      multiselect
                                      allowCustomInputValue={true}
                                      chipHeight="dense"
                                      className="hc-mb-dense hc-mt-dense"
                                      disabled={!canEdit}
                                      onUpdate={(id, val) =>
                                        handleEmailFieldUpdate(
                                          id,
                                          val,
                                          category,
                                          role
                                        )
                                      }
                                      value={values[keyName]}
                                      options={[]}
                                      label={department + ' ' + role}
                                      noResultsMessage="Use Outlook Name"
                                    />
                                  </Grid.Item>
                                )
                              })}
                            </Grid.Container>
                          </div>
                        ))}
                      </Grid.Item>
                    )
                  })}
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item>
                    <p className="errorEmailMessage">{errors.invalidEmails}</p>
                    <ul className="errorEmailList">
                      {values?.invalidEmails?.map((email, i) => (
                        <li key={i}>{email}</li>
                      ))}
                    </ul>
                  </Grid.Item>
                </Grid.Container>
              </div>
            )
          }}
        </Formik>
      )}
    </Card>
  )
}
export default Contacts
