import {
  Anchor,
  Card,
  Heading,
  List,
  Grid,
} from '@enterprise-ui/canvas-ui-react'

const Landing = ({ role = '' }) => {
  const CREATIVEHUB_SHAREPOINT_URL = `https://targetonline.sharepoint.com/sites/CreativeHub`
  const PROMO_MARKETING_URL = `https://targetonline.sharepoint.com/sites/WeeklyAdPromotionsMarketing/`

  const SharePointLink = ({ url, name }) => (
    <Anchor target="_blank" href={url}>
      {name}
    </Anchor>
  )

  return (
    <Card>
      <div className="hc-pa-normal">
        <Grid.Container spacing="none" justify="center" align="center">
          {' '}
          <Grid.Item>
            <Heading className="hc-ta-center" size={3}>
              Welcome to CreativeHub
            </Heading>
          </Grid.Item>
        </Grid.Container>
        <br />

        <Heading size={4}>Support</Heading>
        <p className="hc-fs-section  hc-pt-expanded">
          Send CreativeHub system issues to the engineering team by clicking on
          your initials in the upper-right and choosing “Support” option. List
          details such as project week, pyramid, priority, story, umbrella,
          offer number and the issue you’re experiencing to help speed up the
          research process.
        </p>
        <p className="hc-fs-section hc-pt-expanded">
          In Slack, you can post your system issue directly in the{' '}
          <Anchor
            href="https://target.slack.com/archives/CRX69KYV9"
            rel="noreferrer"
            target="_blank"
          >
            #creativehub-user-support
          </Anchor>{' '}
          slack channel.
        </p>

        <Heading className="hc-pt-expanded" size={4}>
          Resources
        </Heading>
        <List>
          <List.Item>
            <SharePointLink
              url={CREATIVEHUB_SHAREPOINT_URL}
              name="CreativeHub Sharepoint"
            />
          </List.Item>
          <List.Item>
            <SharePointLink
              url={PROMO_MARKETING_URL}
              name="Promo Marketing + Weekly Ad Sharepoint"
            />
          </List.Item>
        </List>
      </div>
    </Card>
  )
}
export default Landing
