import { Dialog, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

let addPropItemSchema = yup.object().shape({
  edit_prop_description: yup.string().required('Required'),
  edit_prop_dpci: yup
    .string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, {
      message: 'DPCI in format 000-00-0000',
    })
    .required('DPCI is required'),
  edit_prop_sample: yup
    .string()
    .oneOf(['inventory', 'turnIn', 'missing'])
    .required('Required'),
  edit_prop_sampleId: yup.string().when('edit_prop_sample', {
    is: (sample) => sample === 'inventory',
    otherwise: yup.string(),
    then: yup.string().required('SampleID required when status: inventory'),
  }),
})

const EditPropItem = (props) => {
  const { editPropItem, setEditPropItem, putPropItem, putCut } = props
  const [isDeleteDialog, setDeleteDialog] = useState(false)

  const wipeState = () => {
    setEditPropItem({
      body: {},
      cutId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
    setDeleteDialog(false)
  }

  return editPropItem ? (
    <Formik
      enableReinitialize
      validationSchema={addPropItemSchema}
      initialValues={{
        edit_prop_description: editPropItem.body.description || '',
        edit_prop_dpci: editPropItem.body.dpci || '',
        edit_prop_keyNotes: editPropItem.body.keyNotes || '',
        edit_prop_sample: editPropItem.body.sample || '',
        edit_prop_sampleId: editPropItem.body.sampleId || '',
      }}
      onSubmit={(values, funcs) => {
        putPropItem({
          body: {
            cutId: editPropItem.cutId,
            description: values.edit_prop_description,
            dpci: values.edit_prop_dpci,
            id: editPropItem.body.id,
            keyNotes: values.edit_prop_keyNotes,
            lastUpdatedTimestamp: editPropItem.body.lastUpdatedTimestamp,
            sample: values.edit_prop_sample,
            sampleId: values.edit_prop_sampleId,
          },
          cutId: editPropItem.cutId,
          pageId: editPropItem.pageId,
          storyId: editPropItem.storyId,
        })
        wipeState()
        funcs.resetForm()
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        const onDelete = () => {
          putCut({
            body: {
              id: editPropItem.cutId,
              lastUpdatedTimestamp: editPropItem.cutLastUpdatedTimestamp,
              props: [
                {
                  deleted: true,
                  id: editPropItem.body.id,
                  lastUpdatedTimestamp: editPropItem.body.lastUpdatedTimestamp,
                },
              ],
            },
            pageId: editPropItem.pageId,
            storyId: editPropItem.storyId,
          })
          handleDialogClose()
        }

        return (
          <Modal
            isVisible={editPropItem.show}
            headingText={`Edit Prop Item`}
            onRefuse={wipeState}
          >
            <div className="hc-pa-normal">
              <fieldset disabled={!editPropItem.hasEditPrivileges}>
                <Grid.Container align="center" spacing="dense">
                  <Grid.Item xs={12}>
                    <Form.Field
                      id="edit_prop_description"
                      value={values.edit_prop_description}
                      label="Prop Item Name"
                      onChange={handleChange}
                      error={errors.edit_prop_description}
                      errorText={errors.edit_prop_description}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      id="edit_prop_dpci"
                      value={values.edit_prop_dpci}
                      label="DPCI"
                      onChange={handleChange}
                      error={errors.edit_prop_dpci}
                      errorText={errors.edit_prop_dpci}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      type="select"
                      id="edit_prop_sample"
                      label="Sample Status"
                      value={values.edit_prop_sample}
                      onUpdate={setFieldValue}
                      options={[
                        { label: '', value: '' },
                        { label: 'Inventory', value: 'inventory' },
                        { label: 'Turn-in', value: 'turnIn' },
                        { label: 'Missing', value: 'missing' },
                      ]}
                      error={errors.edit_prop_sample}
                      errorText={errors.edit_prop_sample}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_prop_sampleId"
                      value={values.edit_prop_sampleId}
                      label="Sample ID"
                      onChange={handleChange}
                      error={errors.edit_prop_sampleId}
                      errorText={errors.edit_prop_sampleId}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      type="textarea"
                      grow
                      id="edit_prop_keyNotes"
                      value={values.edit_prop_keyNotes}
                      label="Key Notes"
                      onChange={handleChange}
                    />
                  </Grid.Item>
                </Grid.Container>
              </fieldset>
              <CancelSubmitButtons
                handleDelete={setDeleteDialog}
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!editPropItem.hasEditPrivileges}
                deleteDisabled={!editPropItem.hasEditPrivileges}
              />
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText="Are you sure want to delete this Prop item?"
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText="Delete"
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditPropItem
