import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useEffect, useMemo, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import ProjectApi from '../../api/creative-hub/ProjectApi'
import '../scss/StickySelectAndFilter.scss'
import { BreadcrumbContext } from '../../context/Context'
import {
  handleFireflyInit,
  handleFireflyFailure,
  handleFireflySuccess,
} from '../../util/FireflyHelper'

const StickySelectAndFilter = (props) => {
  const {
    selectedProject,
    setSelectedProject,
    pyramidsApiLoading,
    pastProject,
    setPastProject,
    session = {},
    viewName = '',
  } = props
  const projectApi = useMemo(() => new ProjectApi(), [])
  const [projects, setProjects] = useState([])
  const [projectSelectValues, setProjectSelectValues] = useState([])
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const navigate = useNavigate()

  useEffect(() => {
    handleFireflyInit('pageload', viewName, 'success', session)
    projectApi
      .findProjects({ past_project: pastProject, project_description: '' })
      .then((data) => {
        setProjects(data.projects)
        handleFireflySuccess('pageload', viewName, 'success', session)
      })
      .catch((e) => {
        console.log(e)
        handleFireflyFailure('pageload', viewName, 'error', session)
      })
  }, [projectApi, pastProject, session, viewName])

  useEffect(() => {
    setProjectSelectValues(formatOptions(projects))
  }, [projects])

  const formatOptions = (projects) =>
    projects?.map((project) => ({
      disabled: false,
      label: project.project_description + ' | ' + project.project_name,
      value: project.project_id,
    }))

  const formatOption = (project) => ({
    disabled: false,
    label: project.project_description + ' | ' + project.project_name,
    value: project.project_id,
  })

  const handleProjectChange = (id, value) => {
    if (value != null) {
      const newProject = projects.find(
        (project) => project.project_id === value.value
      )
      setSelectedProject(newProject)
      if (viewName === 'Category Amplification') {
        navigate(`/publishing/${newProject.project_id}`, {
          state: newProject,
        })
      } else if (viewName === 'Priority Offers') {
        navigate(`/priorityoffers/${newProject.project_id}`, {
          state: newProject,
        })
      }
      setBreadcrumbContext({
        row: {
          project_description: newProject.project_description,
          project_id: newProject.project_id,
          project_name: newProject.project_name,
        },
        setBreadcrumbContext,
      })
    }
  }

  const handlePastProject = () => {
    setPastProject(!pastProject)
  }

  return (
    <Grid.Container
      className="stickybar-container-publishing"
      data-testid="stickyBarPublishing"
    >
      <Grid.Item xs={12}>
        <Grid.Container xs={12}>
          <Grid.Item xs={9} data-cy="selectProject">
            <Autocomplete
              id="selectProject"
              label={
                pastProject ? 'Select Past Project' : 'Select Upcoming Project'
              }
              value={selectedProject ? formatOption(selectedProject) : null}
              placeholder="< Find Projects >"
              filter={Autocomplete.filters.contains}
              onUpdate={handleProjectChange}
              options={projectSelectValues}
              disabled={pyramidsApiLoading}
            />
          </Grid.Item>
          <Grid.Item xs={3}>
            <Button className="gray-btn" onClick={handlePastProject}>
              {!pastProject ? (
                <span> View Past Projects </span>
              ) : (
                <span> View Upcoming Projects </span>
              )}
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}
export default StickySelectAndFilter
