import {
  Grid,
  Card,
  Button,
  ButtonGroup,
  Table,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'

import HistoryApi from '../../api/pop-weekly-ad/HistoryApi'
import { MONTH_LIST } from '../../util/MonthLookup'

const YEAR_LIST = [
  { id: 1, label: '2019', value: '2019' },
  { id: 2, label: '2020', value: '2020' },
  {
    id: 3,
    label: '2021',
    value: '2021',
  },
  {
    id: 4,
    label: '2022',
    value: '2022',
  },
]

const columnData = [
  { id: 'startDate', label: 'Start Date', size: 3 },
  { id: 'projectName', label: 'Project Name', size: 5 },
  { id: 'eventWeek', label: 'Event Week', size: 4 },
]

const DATE_FORMAT = 'YYYY-MM-DD'

export default function WeeklyAdView() {
  const navigate = useNavigate()
  const historyApi = new HistoryApi()
  const currentLocation = useLocation()
  const query = new URLSearchParams(currentLocation.search)
  const currentYear = query.get('year')
  const currentMonth = query.get('month')

  const [loading, setLoading] = useState(currentYear && currentYear)
  const [weeklyAds, setWeeklyAds] = useState([])

  useEffect(() => {
    if (currentYear && currentMonth) {
      const date = moment().year(currentYear).month(currentMonth)
      const startDate = date.startOf('month').format(DATE_FORMAT)
      const endDate = date.endOf('month').format(DATE_FORMAT)

      setLoading(true)
      historyApi
        .getWeeklyAds(startDate, endDate)
        .then((weeklayAds) => setWeeklyAds(weeklayAds || []))
        .catch((e) => console.log(e))
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line
  }, [currentYear, currentMonth])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        month: currentMonth
          ? MONTH_LIST.find((month) => month.value === currentMonth)
          : '',
        year: currentYear
          ? YEAR_LIST.find((year) => year.value === currentYear)
          : '',
      }}
      onSubmit={(values) => {
        !isEmpty(values.month)
          ? query.set('month', values.month.value)
          : query.delete('month')
        !isEmpty(values.year)
          ? query.set('year', values.year.value)
          : query.delete('year')
        navigate(`/change-tracking?${query.toString()}`)
      }}
    >
      {({ values, handleSubmit, handleReset, setFieldValue }) => (
        <>
          <Grid.Container
            padding="dense"
            spacing="none"
            justify="center"
            align="center"
            data-testid="historyContainer"
          >
            <Grid.Item xs={2} className="hc-ml-dense hc-mr-dense">
              <Autocomplete
                id="year"
                chipHeight="dense"
                onUpdate={setFieldValue}
                value={values.year}
                options={YEAR_LIST}
                placeholder="Select Year"
              />
            </Grid.Item>
            <Grid.Item xs={2} className="hc-ml-dense hc-mr-dense">
              <Autocomplete
                id="month"
                chipHeight="dense"
                onUpdate={setFieldValue}
                value={values.month}
                options={MONTH_LIST}
                placeholder="Select Month"
              />
            </Grid.Item>
            <Grid.Item xs={2} className="hc-ml-dense hc-mr-dense">
              <ButtonGroup>
                <Button
                  type="primary"
                  disabled={!values.year || !values.month}
                  onClick={handleSubmit}
                >
                  Apply
                </Button>
                <Button
                  type="secondary"
                  onClick={() => {
                    navigate(`/change-tracking`)
                    handleReset()
                  }}
                >
                  Clear
                </Button>
              </ButtonGroup>
            </Grid.Item>
            <Grid.Item xs={12}>
              <div className="hc-pa-dense hc-mt-normal">
                <Card elevation={2} corners="top" className="hc-pa-normal">
                  {loading ? (
                    <ProgressBar indeterminate />
                  ) : (
                    <>
                      {(!currentYear || !currentMonth) &&
                        'Please select year and month'}
                      {currentYear &&
                      currentMonth &&
                      !loading &&
                      !weeklyAds.length
                        ? 'No weeklyads found !!'
                        : null}
                      {weeklyAds.length ? (
                        <div className="hc-pa-normal hc-pt-none">
                          <Table>
                            <Table.Head>
                              <Table.Row>
                                {columnData.map((col) => (
                                  <Table.Header key={col.id} xs={col.size}>
                                    {col.label}
                                  </Table.Header>
                                ))}
                              </Table.Row>
                            </Table.Head>
                            <Table.Body className="POP-table">
                              {weeklyAds?.map((weeklyad) => (
                                <Table.Row
                                  id={weeklyad.id}
                                  key={weeklyad.id}
                                  align="center"
                                  clickable
                                  as={Link}
                                  to={`/change-tracking/${weeklyad.id}/items`}
                                >
                                  <Table.Data xs={3}>
                                    <DateFormatter
                                      date={weeklyad.startDate}
                                      format="ddd MMM DD YYYY"
                                    />
                                  </Table.Data>
                                  <Table.Data xs={5}>
                                    {weeklyad.projectName}
                                  </Table.Data>
                                  <Table.Data xs={4}>
                                    {weeklyad.eventWeek}
                                  </Table.Data>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      ) : null}
                    </>
                  )}
                </Card>
              </div>
            </Grid.Item>
          </Grid.Container>
        </>
      )}
    </Formik>
  )
}
