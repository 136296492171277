import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Grid,
  Heading,
  Tabs,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'

import Alert from '../../components/Alert'
import ItemTable from '../../components/ItemTable'
import LoadingSpinner from '../../components/LoadingSpinner'
import PaginationWidget from '../../components/PaginationWidget'
import appConfig from '../../config/appConfig'
import EditItemSample from '../../forms/EditItemSample'
import useExport from '../../hooks/useGetExport'
import useGetItemList from '../../hooks/useGetItemList'
import useGetWeeklyAd from '../../hooks/useGetWeeklyAd'
import usePutItem from '../../hooks/usePutItem'
import {
  isAdminOrMarketing,
  isAdminOrCreative,
  isAdminOrSamples,
} from '../../util/CheckRole'
import { filterOptions, setFilterFromQuery } from '../../util/filterOptions'
import GetPageTitle from '../../util/GetPageTitle'

const SampleView = (props) => {
  const { role, archive, toSampleUrl } = props
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const wadId = useParams().wadId
  const [loading, setLoading] = useState(false)
  const [wadLoading, setWadLoading] = useState(false)
  const [transport, setTransport] = useState(0)
  const [editItem, setEditItem] = useState({
    body: {},
    show: false,
  })

  const makeToast = ToastProvider.useToaster()
  const [weeklyAd] = useGetWeeklyAd(wadId, setWadLoading, makeToast)
  const [getExport] = useExport(makeToast)
  const [disableExport, setDisableExport] = useState(false)
  const [currentActiveTab, setCurrentActiveTab] = useState('items')

  const [, setCookie, removeCookie] = useCookies(['sample_view_pyramids'])
  const isCreativeUser = isAdminOrCreative(role) && !weeklyAd.creativeLockedOut
  const hasEditPrivileges =
    isAdminOrMarketing(role) || isCreativeUser || isAdminOrSamples(role)

  const [items, setLoadItems] = useGetItemList(
    setLoading,
    query,
    wadId,
    archive
  )
  const putItem = usePutItem(setLoading, setLoadItems)

  const navigate = useNavigate()
  const getPageOptions = weeklyAd?.pages?.map((page) => ({
    id: page.id,
    label: GetPageTitle(page),
    value: page.id,
  }))
  const config = appConfig()

  const sampleExportUrl =
    config.backend.root + config.backend.export + config.backend.samples + wadId

  const setExportUrl = (url) => url + `?${query}`

  let hasRun = query.get('hasRun')

  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage.toString())
      query.set('hasRun', hasRun)
      setTransport(0)
      setLoadItems(true)
      navigate({
        pathname: `/weeklyad/${wadId}/sample/items`,
        search: query.toString(),
      })
    }
  }, [query, navigate, setLoadItems, transport, wadId, archive, hasRun])

  return items ? (
    <Formik
      enableReinitialize
      initialValues={{
        dpci: query.get('dpci') ? query.get('dpci') : '',
        filters: setFilterFromQuery(query),
        page: getPageOptions?.find(
          (page) => String(page.id) === query.get('pageId')
        ),
      }}
      onSubmit={(values) => {
        !isEmpty(values.dpci)
          ? query.set('dpci', values.dpci)
          : query.delete('dpci')

        !isEmpty(values.page)
          ? query.set('pageId', values.page?.id)
          : query.delete('pageId')

        query.delete('pyramids')
        query.delete('samplePulled')
        query.delete('statuses')
        query.delete('adRequest')
        const allFilters = {
          adRequest: [],
          pyramids: [],
          samplePulled: [],
          statuses: [],
        }
        values.filters.forEach((filter) =>
          allFilters[filter.filter].push(filter.value)
        )
        Object.keys(allFilters).forEach((filterType) => {
          if (!isEmpty(allFilters[filterType])) {
            query.set(filterType, allFilters[filterType])
          }
          if (!isEmpty(allFilters['pyramids'])) {
            setCookie('sample_view_pyramids', allFilters['pyramids'], {
              maxAge: 3.154e7,
              path: '/',
            })
          } else {
            removeCookie('sample_view_pyramids', { path: '/' })
          }
        })
        navigate(`/weeklyad/${wadId}/sample/items?${query.toString()}`)
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
        <div className="hc-pa-none">
          <Grid.Container
            spacing="none"
            direction="row-reverse"
            justify="space-between"
          >
            <Grid.Item>
              <PaginationWidget
                corners="top"
                pages={items}
                setTransport={setTransport}
                archive={hasRun}
              />
            </Grid.Item>
            <Grid.Item>
              <Card corners="top" className="hc-bg-grey07">
                <Tabs
                  disableAdaptive={true}
                  activeTab={currentActiveTab}
                  onTabSelect={(event, tab) => {
                    setCurrentActiveTab(tab.name)
                  }}
                >
                  <Tabs.Item
                    active={true}
                    style={{ paddingTop: '5px' }}
                    as={Link}
                    to={toSampleUrl(wadId)}
                    name="items"
                  >
                    Items
                  </Tabs.Item>

                  <Tabs.Item
                    as={Link}
                    to={`/weeklyad/${wadId}/sample/props?hasRun=${hasRun}`}
                    name="props"
                  >
                    Props
                  </Tabs.Item>

                  <Tabs.Item
                    name="late-additions"
                    to={`/weeklyad/${wadId}/sample/late-additions?hasRun=${hasRun}`}
                    style={{ paddingBottom: '10px' }}
                  >
                    Late Additions
                  </Tabs.Item>
                </Tabs>
              </Card>

              {/* <Button name="items">Items</Button>

              <Button
                as={Link}
                to={`/weeklyad/${wadId}/sample/props?hasRun=${hasRun}`}
                name="props"
              >
                Props
              </Button>

              <Button
                as={Link}
                to={`/weeklyad/${wadId}/sample/late-additions?hasRun=${hasRun}`}
                name="late-additions"
              >
                Late Additions
              </Button> */}
            </Grid.Item>

            <Grid.Item xs={12}>
              <Card
                elevation={2}
                corners="bottom"
                className="hc-pa-dense"
                style={{ borderBottomRightRadius: 0 }}
              >
                <Heading className="hc-ta-center" size={2}>
                  {weeklyAd?.eventWeek}
                </Heading>
                {weeklyAd.creativeLockoutDate ? (
                  <Alert
                    toggle={false}
                    color={weeklyAd.creativeLockedOut ? 'danger' : 'warning'}
                  >
                    <p className="hc-fs-subheading ">
                      {weeklyAd.creativeLockedOut
                        ? `This Sample List locked at 11pm `
                        : `Additional Art Director sample requests are due before lockout at 11pm `}
                      <DateFormatter
                        date={weeklyAd.creativeLockoutDate}
                        format="ddd MMM DD YYYY"
                      />
                    </p>
                  </Alert>
                ) : null}
                <Grid.Container align="center" spacing="dense" justify="center">
                  <Grid.Item className="hc-mt-dense">
                    <Button
                      disabled={disableExport}
                      type="secondary"
                      onClick={() =>
                        getExport({
                          setDisable: setDisableExport,
                          url: setExportUrl(sampleExportUrl),
                        })
                      }
                      className="hc-bg-contrast-weak"
                    >
                      Sample Export
                    </Button>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container spacing="dense" justify="center">
                  <Grid.Item xs={4}>
                    <Autocomplete
                      multiselect
                      id="filters"
                      chipHeight="dense"
                      onUpdate={setFieldValue}
                      value={values.filters}
                      options={filterOptions}
                      placeholder="Filters"
                    />
                  </Grid.Item>
                  {weeklyAd.pages && (
                    <Grid.Item xs={2}>
                      <Autocomplete
                        id="page"
                        chipHeight="dense"
                        onUpdate={setFieldValue}
                        filter={(searchText, page) => {
                          const compareString = page.label.split(
                            'Page' || 'Book 2 Page'
                          )[1]
                          searchText = searchText.toString().toLowerCase()
                          return (
                            compareString.toLowerCase().indexOf(searchText) !==
                            -1
                          )
                        }}
                        value={values.page}
                        options={getPageOptions}
                        placeholder="Page search"
                      />
                    </Grid.Item>
                  )}
                  <Grid.Item xs={2} className="hc-mt-dense">
                    <Form.Field
                      id="dpci"
                      placeholder="DPCI search"
                      value={values.dpci}
                      onChange={handleChange}
                    />
                  </Grid.Item>
                  <Grid.Item className="hc-mt-dense">
                    <ButtonGroup className="hc-mb-none hc-mt-none">
                      <Button
                        type="secondary"
                        onClick={() => {
                          removeCookie('sample_view_pyramids', { path: '/' })
                          navigate({ pathname: '', search: '?hasRun=false' })
                          setLoading(true)
                          handleReset()
                        }}
                      >
                        Clear
                      </Button>
                      <Button type="primary" onClick={handleSubmit}>
                        Apply
                      </Button>
                    </ButtonGroup>
                  </Grid.Item>
                </Grid.Container>
                <ItemTable data={items} setEditItem={setEditItem} />
              </Card>
            </Grid.Item>
            <Grid.Item>
              <PaginationWidget
                corners="bottom"
                pages={items}
                setTransport={setTransport}
                archive={hasRun}
              />
            </Grid.Item>
          </Grid.Container>
          <EditItemSample
            editItem={editItem}
            setEditItem={setEditItem}
            putItem={putItem}
            weeklyAd={weeklyAd}
            hasEditPrivileges={hasEditPrivileges}
            isCreativeUser={isCreativeUser}
          />
          <LoadingSpinner show={loading || wadLoading} />
        </div>
      )}
    </Formik>
  ) : null
}
export default SampleView
