import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import weeklyAdMapper from '../mappers/weeklyAdMapper'

const usePostWeeklyAd = (setLoading, setToast, addWeeklyAd) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.weeklyAd)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const postWad = async () => {
      mounted && setLoading(true)
      try {
        let response = await axios.post(url.current, request.body)
        let thisWad = weeklyAdMapper(response.data)
        addWeeklyAd(thisWad)
        mounted &&
          setToast({
            approveButtonText: 'Edit Now',
            heading: 'WeeklyAd Created!',
            isVisible: true,
            link: `/weeklyad/${thisWad.id}`,
            message: `You successfully created a weekly ad for ${thisWad.eventWeek}`,
            refuseButtonText: 'OK',
            type: 'success',
          })
      } catch (e) {
        console.log(e)
        mounted &&
          setToast({
            heading: 'Error Creating WeeklyAd',
            isVisible: true,
            message:
              'There was a problem creating your pagination.  Please try again.',
            type: 'error',
          })
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      postWad()
    }
    return () => {
      mounted = false
    }
  }, [addWeeklyAd, request, setLoading, setToast])

  return setRequest
}
export default usePostWeeklyAd
