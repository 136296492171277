import { Button, Form } from '@enterprise-ui/canvas-ui-react'
import {
  faTimesCircle,
  faCheckCircle,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import { useState, useEffect } from 'react'

import PlatformApi from '../../../api/platform-notifications/EmailVerification'
import { extractEmailAddresses } from '../../../components/util/emailUtil'
import { validateEmails } from '../../../forms/EmailVerificationHelper'
import { formatToDateTime } from '../../../util/MomentUtil'

const platformApi = new PlatformApi()

async function validateComment({ comment }) {
  const errorMessage = {}

  const sendToArray = extractEmailAddresses(comment)

  if (sendToArray.length > 0) {
    const response = await validateEmails(sendToArray, platformApi)
    if (response.unknowns && response.unknowns.length > 0) {
      errorMessage.comment = 'Invalid Email: ' + response.unknowns
    }
  }

  if (/[><]/i.test(comment)) {
    errorMessage.comment = `You can't use special characters`
  } else if (
    /(eval((.*)))|(["'][\\s]*javascript:(.*)["'])|(<script.*?>.*?<script.*?>)|(<script.*?>.*?<\/script.*?>)|(<.*?javascript:.*?>.*?<\/.*?>)|(expression\\((.*?)\\))|(vbscript:)|(onload(.*?)=)/.test(
      comment
    )
  ) {
    errorMessage.comment = `You can't use script in comment`
  }

  return errorMessage
}

export function CommentField({ children, value, handleSetComment, onSubmit }) {
  return (
    <Formik
      initialValues={{
        comment: value,
      }}
      validate={validateComment}
      onSubmit={(values, funcs) => {
        onSubmit()
        funcs.resetForm()
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors }) => {
        const handleCommentChange = (event) => {
          handleChange(event)
          handleSetComment(event)
        }
        return children({
          errors,
          handleBlur,
          handleCommentChange,
          handleSubmit,
          values,
        })
      }}
    </Formik>
  )
}

export default function CommentItem({
  canEdit = false,
  currentUserName,
  editCommentId,
  setEditCommentId,
  updateComment,
  deleteComment,
  comment: {
    id,
    created_by: createdBy,
    comment_text: commentText,
    last_updated_timestamp: lastUpdatedTimestamp,
    updated_date: updatedDate,
  },
  offer,
  umbrella,
  outcomePriorityId,
}) {
  const [comment, setComment] = useState(commentText)
  const isActionable = currentUserName === createdBy

  useEffect(() => {
    if (editCommentId === id) {
      setComment(commentText)
    }
  }, [id, setComment, editCommentId, commentText])

  const onCommentClose = () => {
    setEditCommentId(null)
    setComment(commentText)
  }

  const handleSetComment = (e) => {
    setComment(e.target.value)
  }

  const onSubmit = () => {
    updateComment(id, {
      comment_text: comment,
      id: id,
      last_updated_timestamp: lastUpdatedTimestamp,
      offer: offer,
      offer_comment: true,
      offer_id: offer.id,
      offer_name: offer.name,
      pyramid_id: outcomePriorityId,
      story_id: umbrella.story_id,
      umbrella_name: umbrella.name,
    })
    setEditCommentId(null)
  }

  return (
    <div className="comment-container">
      <div className="comment-content">
        <div className="comment-header">
          <span className="comment-header__creator">{createdBy}</span>
          <span className="comment-header__date">
            {formatToDateTime(updatedDate)}
          </span>
        </div>
        {editCommentId === id ? (
          <div>
            <CommentField
              value={comment}
              setValue={setComment}
              onSubmit={onSubmit}
              handleSetComment={handleSetComment}
            >
              {({
                handleSubmit,
                handleCommentChange,
                handleBlur,
                values,
                errors,
              }) => (
                <>
                  <Form.Field
                    id="comment"
                    type="textarea"
                    handleBlur={handleBlur}
                    onChange={handleCommentChange}
                    value={values.comment}
                    error={errors?.comment}
                    errorText={errors?.comment}
                  />
                  <div>
                    <Button
                      className="C-MenuButton"
                      onClick={onCommentClose}
                      iconOnly
                      aria-label="close comment"
                    >
                      <FontAwesomeIcon color="black" icon={faTimesCircle} />
                    </Button>
                    <Button
                      className="C-MenuButton"
                      onClick={handleSubmit}
                      iconOnly
                      aria-label="update comment"
                    >
                      <FontAwesomeIcon color="black" icon={faCheckCircle} />
                    </Button>
                  </div>
                </>
              )}
            </CommentField>
          </div>
        ) : (
          <p className="comment-text">{commentText}</p>
        )}
      </div>
      {canEdit ? (
        <div className="comment-actions">
          <Button
            className="C-MenuButton"
            onClick={() => setEditCommentId(id)}
            iconOnly
            aria-label="edit comment"
            disabled={!isActionable}
          >
            <FontAwesomeIcon color="black" icon={faPencilAlt} />
          </Button>
          <Button
            className="C-MenuButton"
            onClick={() => deleteComment(id)}
            iconOnly
            aria-label="delete comment"
            disabled={!isActionable}
          >
            <FontAwesomeIcon color="black" icon={faTrashAlt} />
          </Button>
        </div>
      ) : null}
    </div>
  )
}
