import { Grid } from '@enterprise-ui/canvas-ui-react'
import { faBookMedical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useRef, useEffect, useContext } from 'react'

import '../scss/Story.scss'

import StoryApi from '../../api/creative-hub/StoryApi'
import { CanEditContext } from '../../context/Context'
import {
  displayStoriesByChannel,
  sortStoriesByOrder,
} from '../../creativeHubGlobal/util/StoryUtil'
import { handleFireflyClickInteraction } from '../../util/FireflyHelper'

import Story from './Story'

const StoryBucket = (props) => {
  const {
    channelFilters = [],
    projectStartDate,
    projectTitle,
    pyramidDetails,
    pyramid,
    setIsPyramidDraggable,
    setRefreshData,
    session = {},
    setUmbrellaIsDropped,
    setSelectedUmbrellas,
  } = props
  const { canEdit } = useContext(CanEditContext)
  pyramidDetails?.stories?.forEach((element) => {
    element.pyramid_id = pyramid?.pyramid_id
  })

  let displayedStories = []

  if (pyramidDetails?.stories && channelFilters?.length > 0) {
    displayStoriesByChannel(
      pyramidDetails.stories,
      displayedStories,
      channelFilters
    )
  } else {
    displayedStories = sortStoriesByOrder(pyramidDetails.stories)
  }

  const [pyramidStories, setPyramidStories] = useState(displayedStories)

  const dragEnter = (event) => {
    event.dataTransfer.types.includes('drag-story') && event.preventDefault()
  }

  const dragOver = (event) => {
    event.dataTransfer.types.includes('drag-story') && event.preventDefault()
  }

  useEffect(() => {
    setPyramidStories(pyramidStories)
  }, [pyramidStories])

  const draggingItem = useRef()
  const dragOverItem = useRef()
  const storyApi = new StoryApi()

  const handleDragStart = (event, position) => {
    event.stopPropagation()
    setIsPyramidDraggable(false)
    event.dataTransfer.setData('drag-story', true)
    draggingItem.current = position
  }

  const handleDrop = (event, landingPosition) => {
    setIsPyramidDraggable(true)
    if (event.dataTransfer.types.includes('drag-story')) {
      dragOverItem.current = landingPosition
      const storiesCopy = [...pyramidStories]
      const draggingItemContent = storiesCopy[draggingItem.current]
      if (
        storiesCopy[dragOverItem.current] &&
        storiesCopy[draggingItem.current] &&
        dragOverItem.current !== draggingItem.current
      ) {
        storiesCopy.splice(draggingItem.current, 1)
        storiesCopy.splice(dragOverItem.current, 0, draggingItemContent)
        let end, start
        if (draggingItem.current < dragOverItem.current) {
          start = draggingItem.current
          end = dragOverItem.current
        } else {
          start = dragOverItem.current
          end = draggingItem.current
        }
        //only update changed indicies
        let updatedStoriesList = []
        for (let index = start; index <= end; index++) {
          storiesCopy[index].story_order = index + 1
          updatedStoriesList.push(storiesCopy[index])
        }
        storyApi.updateStories(updatedStoriesList)
        setPyramidStories(storiesCopy)
      }
    }
  }

  const createStory = async (pyramidStories) => {
    const newStoryRequest = {
      pyramid_id: pyramid.pyramid_id,
      story_name: '',
      story_order: pyramidStories?.length + 1 || 1,
    }

    //create in API when API is complete
    try {
      const resp = await storyApi.createStory(newStoryRequest)
      setPyramidStories([...pyramidStories, resp])
      handleFireflyClickInteraction(
        'pageload',
        'Marketing & Digital: Add Story',
        'success',
        session,
        {
          message: `pyramidType: ${pyramid.pyramid_type} - pyramidName: ${pyramid.pyramid_name}`,
          start_date: projectStartDate,
        }
      )
      return resp
    } catch (error) {
      console.log(error)
    }
    //update data or handle error
  }
  return (
    pyramidStories && (
      <>
        <Grid.Container
          className="pyramidDetails"
          justify="space-between"
          xs={12}
        >
          <Grid.Item className="storyHeader">
            <h3>Marketing Stories</h3>
          </Grid.Item>
          <Grid.Item>
            {canEdit ? (
              <Grid.Item
                onClick={() => createStory(pyramidStories)}
                data-testid="createStory"
              >
                <FontAwesomeIcon icon={faBookMedical} size="lg" />
              </Grid.Item>
            ) : null}
          </Grid.Item>
        </Grid.Container>
        <Grid.Container
          className="storyContainer"
          xs={12}
          style={{
            backgroundColor: '#f6f7f9',
            border: '1px solid #666666',
            marginBottom: '0px',
            padding: '0px',
          }}
        >
          {pyramidStories?.map((story, index) => (
            <div
              data-testid={'storyBucket-' + index}
              key={story?.story_id}
              onDragStart={
                canEdit ? (event) => handleDragStart(event, index) : null
              }
              onDragEnter={dragEnter}
              onDragOver={dragOver}
              onDrop={(event) => handleDrop(event, index)}
              className="story"
              draggable={canEdit}
            >
              <Story
                key={'story' + index}
                setRefreshData={setRefreshData}
                story={story}
                pyramidType={pyramid.pyramid_type}
                pyramidName={pyramid.pyramid_name}
                channelFilters={channelFilters}
                setUmbrellaIsDropped={setUmbrellaIsDropped}
                setSelectedUmbrellas={setSelectedUmbrellas}
                session={session}
                projectStartDate={projectStartDate}
                projectTitle={projectTitle}
              />
            </div>
          ))}
        </Grid.Container>
      </>
    )
  )
}
export default StoryBucket
