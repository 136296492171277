import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class ContactsApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_defaultContacts_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'content-type': 'application/json',
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getDefaultContacts = async () =>
    await this.init_defaultContacts_api()
      .get(this.config.backend.defaultContactsPage)
      .then((res) => (res.data.length ? res.data : []))
      .catch((e) => {
        console.log(e)
        return []
      })

  putDefaultContacts = async (payload) => {
    try {
      const res = await this.init_defaultContacts_api().put(
        `${this.config.backend.defaultContactsPage}`,
        payload
      )
      return res.data
    } catch (e) {
      console.log('e', e)
      return []
    }
  }
}
