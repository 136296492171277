import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import dummyMapper from '../mappers/dummyMapper'

const usePostDummy = (setLoading, setWeeklyAd) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.dummy)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const postDummy = async () => {
      mounted && setLoading(true)
      try {
        let response = await axios.post(url.current, request.body)
        let thisDummy = dummyMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            w.pages
              .find((page) => page.id === request.pageId)
              .stories.find((story) => story.id === request.storyId)
              .cuts.find((cut) => cut.id === request.cutId)
              .dummies.push(thisDummy)
            return w
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      postDummy()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd])

  return setRequest
}
export default usePostDummy
