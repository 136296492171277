import { Anchor, Card, Heading } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

import { handleFireflySuccess } from '../../util/FireflyHelper'

const Unauthorized = () => {
  const { session } = useAuth()
  handleFireflySuccess('pageload', 'Unauthorized', 'success', session)
  return (
    <Card>
      <div className="hc-pa-normal">
        <Heading size={3}>Access Denied. You are not authorized.</Heading>{' '}
        <br />
        <Heading size={5}>
          If you have a business need to access the CreativeHub tool, please
          submit a request via My Access.
        </Heading>
        <Heading size={5} style={{ paddingTop: '15px' }}>
          For more information, or to report an issue or ask a question, please
          visit the{' '}
          <Anchor
            href="https://target.slack.com/archives/CRX69KYV9"
            target="_blank"
          >
            #creativehub-user-support
          </Anchor>{' '}
          slack channel.
        </Heading>
      </div>
    </Card>
  )
}
export default Unauthorized
