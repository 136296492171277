import { Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import '../WeeklyAdViewPageImages.scss'

export const FullPageImageModal = (props) => {
  const { imageUrl, showFullImage, setShowFullImage } = props

  const handleDialogClose = () => {
    setShowFullImage(false)
  }

  return (
    <span data-testid="fullImageModal">
      <Modal
        isVisible={showFullImage}
        onRefuse={handleDialogClose}
        className="image-modal"
      >
        <div className="hc-pa-normal">
          <Grid.Container justify="center">
            <Grid.Item xs>
              <img
                src={imageUrl + '?wid=1200'}
                alt="page"
                className="full-image"
                id="full-page-image"
              />
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </span>
  )
}
