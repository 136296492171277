import { sortBy } from 'lodash'

import { getPyramidAbbrByGroupName } from '../../../src/planning/util/ProjectDetailsUtil'

export const displayStoriesByChannel = (
  stories,
  displayedStories,
  selectedMarketingChannelFilter
) => {
  stories.forEach((story) => {
    story.umbrellas.forEach((umbrella) => {
      umbrella.channels.forEach((channel) => {
        selectedMarketingChannelFilter.forEach((channelFilter) => {
          if (
            channelFilter.label.includes(channel.channel_name) &&
            !displayedStories.includes(story)
          ) {
            displayedStories.push(story)
          }
        })
      })
    })
  })
}

export const storiesByPyramidFilter = (
  stories,
  selectedMarketingPyramidFilter
) => {
  const filteredStories = []
  const containsPyramid = (umbrella) =>
    getPyramidAbbrByGroupName(umbrella.merchant_category?.group_name) ===
    selectedMarketingPyramidFilter
  const containsBeautyOrEss = (umbrella) =>
    getPyramidAbbrByGroupName(umbrella.merchant_category?.group_name) ===
    'bty/ess'

  for (let i = 0; i < stories?.length; i++) {
    if (
      selectedMarketingPyramidFilter === 'bty' ||
      selectedMarketingPyramidFilter === 'ess'
    ) {
      if (stories[i].umbrellas.some(containsBeautyOrEss)) {
        stories[i].umbrellas = stories[i].umbrellas.filter((umb) =>
          containsBeautyOrEss(umb)
        )
        filteredStories.push(stories[i])
      }
    } else {
      if (stories[i].umbrellas.some(containsPyramid)) {
        stories[i].umbrellas = stories[i].umbrellas.filter((umb) =>
          containsPyramid(umb)
        )
        filteredStories.push(stories[i])
      }
    }
  }

  return filteredStories
}

export const sortStoriesByOrder = (stories = []) =>
  sortBy(stories, (a) => a.story_order) ?? []

export const filterStoriesById = (stories = [], selectedStoryId) => {
  let filteredStory = stories.filter(
    (story) => story.story_id === selectedStoryId
  )?.[0]
  return filteredStory
}
