import axios from 'axios'

import appConfig from '../../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../../creativeHubGlobal/util/AuthUtil'

export default class OfferChannelApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_channel_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getOfferChannels = async (projectId = '', storyId = '', channelId = '') =>
    await this.init_channel_api()
      .get(
        `${this.config.markethub.url}offer/umbrella?projectId=${projectId}&storyId=${storyId}&channelId=${channelId}`
      )
      .then((res) => res.data.sort((a, b) => a.channelOrder - b.channelOrder))
      .catch((e) => {
        console.log(e)
        return []
      })

  updateOfferChannel = async ({
    abEmail = '',
    channelId = '',
    ivyOfferId = '',
    offerId = '',
    selected = true,
    umbrellaName = '',
    selectedStoryId = '',
  }) =>
    await this.init_channel_api()
      .put(`${this.config.markethub.url}offer/channel`, {
        ab_email: abEmail,
        channel_id: channelId,
        ivy_offer_id: ivyOfferId,
        offer_id: offerId,
        selected,
        story_id: selectedStoryId,
        umbrella_name: umbrellaName,
      })
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return null
      })
}
