import { FormField, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

const AddArtPatch = (props) => {
  const { addArtPatch, setAddArtPatch, putStory } = props
  const cuts = addArtPatch?.story?.cuts || []

  const wipeState = () => {
    setAddArtPatch({
      body: {},
      show: false,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        artPatchSelected: [],
        baseCutSelected: null,
      }}
      onSubmit={(values, funcs) => {
        const artPatchCuts = cuts
          .filter((cut) => values.artPatchSelected.includes(cut.id))
          .map((follower) => ({
            artPatchLeaderId: values.baseCutSelected,
            id: follower.id,
            lastUpdatedTimestamp: follower.lastUpdatedTimestamp,
            priority: follower.priority,
          }))

        putStory({
          body: {
            cuts: artPatchCuts,
            id: addArtPatch.story.id,
            lastUpdatedTimestamp: addArtPatch.story.lastUpdatedTimestamp,
          },
          pageId: addArtPatch.pageId,
        })
        funcs.resetForm()
        wipeState()
      }}
    >
      {({ values, handleReset, handleSubmit, setFieldValue }) => {
        const handleDialogClose = () => {
          wipeState()
          handleReset()
        }
        return (
          <Modal
            isVisible={addArtPatch.show}
            onRefuse={wipeState}
            headingText="Link Art Patch"
          >
            <div className="hc-pa-normal">
              <Grid.Container justify="center" direction="row">
                <Grid.Item xs={6}>
                  <FormField
                    required
                    id="baseCutSelected"
                    label="Choose Base Cut First:"
                    type="radio"
                    value={values.baseCutSelected}
                    options={cuts
                      .filter((cut) => !cut.artPatch)
                      .map((cut) => ({
                        label: cut.name,
                        name: cut.name,
                        value: cut.id,
                      }))}
                    onUpdate={(_, baseCutOption) => {
                      setFieldValue('artPatchSelected', [])
                      setFieldValue('baseCutSelected', baseCutOption)
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <FormField
                    id="artPatchSelected"
                    label="Choose Art Patch(es):"
                    type="checkboxes"
                    value={values.artPatchSelected}
                    options={cuts
                      .filter((cut) => !cut.artPatch)
                      .map((cut) => ({
                        disabled: cut.id === values.baseCutSelected,
                        label: cut.name,
                        name: cut.name,
                        value: cut.id,
                      }))}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                submitDisabled={
                  isEmpty(values.artPatchSelected) ||
                  values.baseCutSelected === undefined
                }
                handleSubmit={handleSubmit}
                handleDialogClose={handleDialogClose}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default AddArtPatch
