import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import cutPatchMapper from '../mappers/cutPatchMapper'
import ReplaceFirst from '../util/ReplaceFirst'

const usePutCutPatch = (setLoading, setWeeklyAd) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.cutPatch)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const putCutPatch = async () => {
      mounted && setLoading(true)
      try {
        const id = request.body.id
        let response = await axios.put(url.current + id, request.body)
        let thisCutPatch = cutPatchMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            ReplaceFirst(
              w.pages
                .find((page) => page.id === request.pageId)
                .stories.find((story) => story.id === request.storyId)
                .cuts.find((cut) => cut.id === request.cutId).cutPatches,
              id,
              thisCutPatch
            )
            return w
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      putCutPatch()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd])

  return setRequest
}
export default usePutCutPatch
