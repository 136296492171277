import formatDecimal from '../util/formatDecimal'

import marketMapper from './marketMapper'

const itemPatchMapper = (patch) => ({
  deleted: patch.deleted,
  id: patch.id,
  itemId: patch.itemId,
  keyNotes: patch.keyNotes,
  lastUpdatedTimestamp: patch.lastUpdatedTimestamp,
  marketString: patch.marketString,
  markets: patch.markets
    ? patch.markets.map(marketMapper).sort((a, b) => a.priority - b.priority)
    : [],
  priority: patch.priority,
  regPriceCallout: patch.regPriceCallout,
  regUnitRetail: formatDecimal(patch.regUnitRetail),
})
export default itemPatchMapper
