import axios from 'axios'

import appConfig from '../../config/appConfig'
import pyramidMapper from '../../creativeHubGlobal/mappers/PyramidMapper'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'
import { makeChannelAndDivisionQueryParam } from '../../creativeHubGlobal/util/CreativeHubGlobalUtil'
import { divisionsToDivisionIds } from '../../util/DivisionUtils'
export default class PyramidApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_pyramid_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getPyramids = async (projectId) =>
    await this.init_pyramid_api()
      .get(`${this.config.markethub.url}pyramid?projectId=${projectId}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  findPyramidType = async (projectId, pyramidType) =>
    await this.init_pyramid_api()
      .get(
        `${this.config.markethub.url}pyramid?projectId=${projectId}&pyramidType=${pyramidType}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  getPyramidsByProjectIdAndChannels = async (projectId, channelIds) =>
    await this.init_pyramid_api()
      .get(
        `${this.config.markethub.url}pyramid/channel_pyramids/${projectId}?channelIds=${channelIds}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  createPyramid = async (pyramid) =>
    await this.init_pyramid_api()
      .post(`${this.config.markethub.url}pyramid`, pyramid)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  getPyramidDetails = async (
    pyramidId,
    projectId,
    channels = [],
    divisions = []
  ) =>
    await this.init_pyramid_api()
      .get(
        `${
          this.config.markethub.url
        }pyramid/${pyramidId}?projectId=${projectId}${makeChannelAndDivisionQueryParam(
          channels,
          divisionsToDivisionIds(divisions)
        )}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  updatePyramids = async (pyramidList) =>
    await this.init_pyramid_api()
      .put(this.config.markethub.url + 'pyramid', pyramidList)
      .then((res) => pyramidMapper(res.data))
      .catch((e) => {
        console.error(e)
        return []
      })

  deletePyramid = async (deletePyramidRequest) => {
    await this.init_pyramid_api()
      .delete(
        `${this.config.markethub.url}pyramid/${deletePyramidRequest.pyramid_id}`
      )
      .then((res) => res.data)
      .catch((error) => {
        console.error(error)
        return []
      })
  }

  getPyramidsByFilter = async (
    selectedProjectId,
    priorityFilter,
    pyramidFilter,
    marketingChannelFilter,
    selectedDivisionFilter
  ) => {
    try {
      const projectId = `${this.config.markethub.url}pyramid?projectId=${selectedProjectId}`

      const priorityFilter = !priorityFilter
        ? `&includeEnterprise=${true}`
        : `&includeEnterprise=${false}`
      const encodedPyramidType =
        pyramidFilter !== ''
          ? `&pyramidType=${encodeURIComponent(pyramidFilter)}`
          : ''
      const divisionFilter = selectedDivisionFilter
        ? `&divisionIds=${divisionsToDivisionIds(selectedDivisionFilter)}`
        : ''
      const channelIds =
        marketingChannelFilter.length > 0
          ? `&channelIds=${marketingChannelFilter}`
          : ''

      let url = `${projectId}${priorityFilter}${encodedPyramidType}${divisionFilter}${channelIds}`

      const res = await this.init_pyramid_api().get(url)
      return res.data
    } catch (e) {
      console.log(e)
      return []
    }
  }
}
