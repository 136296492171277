const vendorEmailMapper = (vendorEmail, index) => ({
  id: vendorEmail + index,
  label: vendorEmail,
  value: vendorEmail,
})

const approvedVendorMapper = (approvedVendor) => ({
  id: approvedVendor.vendorId,
  label: approvedVendor.vendorName,
  lastUpdatedTimestamp: approvedVendor.lastUpdatedTimestamp,
  value: approvedVendor.vendorName,
  vendorEmails: approvedVendor.vendorEmails.map((vendorEmail, index) =>
    vendorEmailMapper(vendorEmail, index)
  ),
  vendorId: approvedVendor.vendorId,
  vendorName: approvedVendor.vendorName,
})
export default approvedVendorMapper
