import {
  Grid,
  Button,
  Modal,
  Chip,
  Card,
  GridContainer,
} from '@enterprise-ui/canvas-ui-react'
import { faPencilAlt, faGlasses } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import MarketListTooltip from '../components/MarketListTooltip'
import EditCutPatch from '../forms/EditCutPatch'
import { getPromoUnitRetail } from '../util/GetPromoUnitRetail'
import { formatToDateTime } from '../util/MomentUtil'

const ViewCutPatches = (props) => {
  const { viewCutPatches, setViewCutPatches, putCutPatch } = props

  const { body, offers, cutName, pageId, storyId, cutId, show, lockoutDate } =
    viewCutPatches

  const [editCutPatch, setEditCutPatch] = useState({
    body: [],
    cutId: null,
    lockoutDate: lockoutDate,
    offers: [],
    pageId: null,
    pageName: '',
    show: false,
    storyId: null,
  })

  const handleDialogClose = () => {
    setViewCutPatches({
      body: [],
      cutId: null,
      offers: [],
      pageId: null,
      pageName: '',
      show: false,
      storyId: null,
    })
  }

  return (
    <Modal
      isVisible={show}
      headingText={`${cutName} Cut Patches`}
      onRefuse={handleDialogClose}
    >
      <div style={{ position: 'relative' }} className="hc-pa-normal">
        <Grid.Container align="center" spacing="dense">
          {body
            ? body.map((patch, index) => {
                const isMWMarket = patch.markets
                  .map((mkt) => mkt.shortCode)
                  .includes('MW')
                const promoUnitRetailText = getPromoUnitRetail(
                  patch,
                  isMWMarket
                )

                return (
                  <Grid.Item xs={12} key={patch.id}>
                    <Card
                      className={`hc-pa-dense hc-mt-dense hc-mb-dense ${
                        index === 0 ? 'hc-bg-grey06' : ''
                      }`}
                    >
                      <GridContainer spacing="none">
                        <Grid.Item xs={11}>
                          <Grid.Container spacing="dense">
                            <Grid.Item className="hc-ta-right" xs={2}>
                              <strong>Markets</strong>
                            </Grid.Item>
                            <Grid.Item xs={3}>
                              <MarketListTooltip markets={patch.markets} />
                            </Grid.Item>
                            <Grid.Item xs={7}>
                              <Chip
                                size="dense"
                                style={{
                                  backgroundColor: patch.saleCallout
                                    ? 'red'
                                    : 'grey',
                                  border: '0px',
                                  color: 'white',
                                }}
                              >
                                {patch.saleCallout ? 'SALE' : 'No SALE'}
                              </Chip>
                            </Grid.Item>
                            <Grid.Item className="hc-ta-right" xs={2}>
                              <strong>Promo $</strong>
                            </Grid.Item>
                            <Grid.Item xs={10}>
                              <span
                                style={{
                                  color:
                                    (lockoutDate <
                                      patch.defaultPromoUnitRetailUpdatedTimestamp ||
                                      patch.defaultPromoUnitRetailUpdatedTimestamp ===
                                        null) &&
                                    !isMWMarket
                                      ? 'red'
                                      : '',
                                }}
                              >
                                {patch.promoUnitRetailCallout ? (
                                  ` ${promoUnitRetailText} `
                                ) : (
                                  <s> {promoUnitRetailText}</s>
                                )}
                              </span>
                              {lockoutDate <
                                patch.defaultPromoUnitRetailUpdatedTimestamp &&
                                ` Was: ${patch.previousDefaultPromoUnitRetail}, 
                              revised ${formatToDateTime(
                                patch.promoUnitRetailLastUpdatedTimestamp
                              )}`}
                            </Grid.Item>
                            <Grid.Item className="hc-ta-right" xs={2}>
                              <strong>Copy</strong>
                            </Grid.Item>
                            <Grid.Item className="hc-ws-pre-wrap" xs={10}>
                              {patch.copy}
                            </Grid.Item>
                          </Grid.Container>
                        </Grid.Item>
                        <Grid.Item xs={1}>
                          <Button
                            iconOnly
                            onClick={(event) => {
                              event.stopPropagation()
                              setEditCutPatch({
                                body: patch,
                                cutId: cutId,
                                lockoutDate: lockoutDate,
                                offers: offers,
                                pageId: pageId,
                                show: true,
                                storyId: storyId,
                              })
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                viewCutPatches.hasEditPrivileges
                                  ? faPencilAlt
                                  : faGlasses
                              }
                              color="#000000"
                            />
                          </Button>
                        </Grid.Item>
                      </GridContainer>
                    </Card>
                  </Grid.Item>
                )
              })
            : null}
        </Grid.Container>
      </div>
      <EditCutPatch
        editCutPatch={editCutPatch}
        setEditCutPatch={setEditCutPatch}
        putCutPatch={putCutPatch}
        hasEditPrivileges={viewCutPatches.hasEditPrivileges}
      />
    </Modal>
  )
}
export default ViewCutPatches
