import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class UmbrellaApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_umbrella_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getUmbrella = async (umbrellaId) =>
    await this.init_umbrella_api()
      .get(this.config.markethub.url + `umbrella/` + umbrellaId)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  linkUmbrella = async (umbrella) =>
    await this.init_umbrella_api()
      .post(this.config.markethub.v2Url + 'umbrella', umbrella)
      .then((res) => res.data)
      .catch((e) => {
        console.error(e)
        return []
      })

  unlinkUmbrella = async (umbrellaUUID, storyId) =>
    await this.init_umbrella_api()
      .delete(
        this.config.markethub.url + 'umbrella/' + umbrellaUUID + '/' + storyId
      )
      .then((res) => res.data)
      .catch((e) => {
        console.error(e)
        return []
      })

  getUmbrellas = async (
    projectId,
    startDate = null,
    endDate = null,
    activeTab = 'PYRAMID'
  ) => {
    const query = new URLSearchParams()
    query.set('projectId', projectId)
    if (startDate && endDate !== null) {
      query.set('startDate', startDate)
      query.set('endDate', endDate)
      query.set('tier', activeTab)
      query.set('type', 'UNLINKED')
    }

    return await this.init_umbrella_api()
      .get(this.config.markethub.url + `umbrella?` + query.toString())
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })
  }

  getEnterpriseUmbrellas = async (projectId, startDate, endDate) => {
    const query = new URLSearchParams()
    query.set('projectId', projectId)
    query.set('startDate', startDate)
    query.set('endDate', endDate)
    query.set('tier', 'ENT')
    query.set('type', 'LINKED')

    return await this.init_umbrella_api()
      .get(`${this.config.markethub.url}umbrella?${query.toString()}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })
  }

  updateUmbrellas = async (updateUmbrellasRequest) => {
    await this.init_umbrella_api()
      .put(this.config.markethub.url + 'umbrella', updateUmbrellasRequest)
      .then((res) => res.data)
      .catch((error) => {
        console.error(error)
        return []
      })
  }

  getIvyUmbrellasByTier = async (projectId, startDate, endDate, tier, type) => {
    const query = new URLSearchParams()
    query.set('projectId', projectId)
    query.set('startDate', startDate)
    query.set('endDate', endDate)
    query.set('tier', tier)
    query.set('type', type)

    return await this.init_umbrella_api()
      .get(this.config.markethub.url + `umbrella?` + query.toString())
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })
  }

  refreshUmbrella = async (updateUmbrellasRequest) => {
    await this.init_umbrella_api()
      .post(
        this.config.markethub.url + 'umbrella/refresh',
        updateUmbrellasRequest
      )
      .then((res) => res.data)
      .catch((error) => {
        console.error(error)
        return []
      })
  }
}
