import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'

const usePutItem = (setLoading, setLoadItems) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.item)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const putItem = async () => {
      mounted && setLoading(true)
      try {
        const id = request?.body?.id
        await axios.put(url.current + id, request.body, {
          headers: {
            user: request.user,
          },
        })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoadItems(true) //must be before setRequest call, which causes rerender
      mounted && setRequest(null)
    }

    if (request !== null) {
      putItem()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setLoadItems])

  return setRequest
}
export default usePutItem
