const pyramidMapper = (pyramid) => ({
  captain: pyramid.captain,
  createdBy: pyramid.created_by,
  createdDate: pyramid.created_date,
  dateRange: pyramid.date_range,
  guestOutcomes: pyramid.guest_outcomes,
  id: pyramid.pyramid_id,
  plannerName: pyramid.planner_name,
  plannerNotes: pyramid.planner_notes,
  priorityTier: pyramid.priority_tier,
  projectId: pyramid.project_td,
  pyramidName: pyramid.pyramid_name,
  pyramidOrder: pyramid.pyramid_order,
  stories: pyramid.stories,
  umbrellas: pyramid.umbrellas,
  updatedBy: pyramid.update_by,
  updatedDate: pyramid.updated_date,
})

export default pyramidMapper
