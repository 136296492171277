import moment from 'moment'

const serverFormat = 'YYYY-MM-DD'
const uiFormat = 'MM/DD/YYYY'
const timestampFormat = 'M/D/YYYY, h:mm A'
const timeFormat = 'h:mm A'

export function formatToServer(date) {
  return date ? moment(date, uiFormat).format(serverFormat) : ''
}

export function dateFormatForHistoryViewFilter(date) {
  const formatedDate = date ? moment(date, uiFormat).utc().format() : ''
  return formatedDate
}

export function formatToUi(date) {
  return date ? moment(date, serverFormat).format(uiFormat) : ''
}

export function formatToDateTime(timestamp) {
  return timestamp ? moment(timestamp).format(timestampFormat) : ''
}
export function formatToIvyDateTime(timestamp) {
  return timestamp
    ? moment(timestamp).isSame(moment(), 'day')
      ? 'Today at ' + moment(timestamp).format(timeFormat)
      : moment(timestamp).format(timestampFormat)
    : ''
}

export function formatToJavaScriptDate(date) {
  // Example: Changes "2022-08-25" to "Thu Aug 25 2022 00:00:00 GMT-0500 (Central Daylight Time)"
  return moment(date, 'YYYY-MM-DD').toDate()
}

export function checkOffersValidity(
  weeklyAdStartDate,
  offerStartDateStr,
  offerEndDateStr,
  daysToAdd = 6
) {
  const startDate = moment(weeklyAdStartDate, serverFormat)
  const endDate = moment(weeklyAdStartDate, serverFormat).add(daysToAdd, 'days')
  const offerStartDate = moment(offerStartDateStr, serverFormat)
  const offerEndDate = moment(offerEndDateStr, serverFormat)
  return (
    offerStartDate.isSameOrBefore(endDate) &&
    offerEndDate.isSameOrAfter(startDate)
  )
}

export const isFutureDate = (runDate = new Date()) =>
  moment(new Date()).isBefore(runDate)
