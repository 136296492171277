import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import sortBy from 'lodash.sortby'
import { useState, useEffect, useMemo } from 'react'

import ItemApi from '../../execution/api/projectExecution/ItemApi'
import {
  fetchAllPicturedItems,
  sortItemsAndAddDetails,
} from '../../execution/util/ItemUtil'
import DigitalImagesApi from '../../groupedOffers/api/DigitalImagesApi'

import PublishingItem from './PublishingItem'

import '../../execution/scss/Bucket.scss'

const PublishItemsBucket = (props) => {
  const {
    selectedOffer,
    setIsItemImageModalOpen,
    setItemSelected,
    updatedItemImage,
    boostedTcins,
    setBoostedTcins,
    showPriority,
    canEdit = false,
    setChangeDetected,
  } = props

  const [publishItemElements, setPublishItemElements] = useState([])
  const [isArtPatch, setIsArtPatch] = useState(false)
  const [isMixedDiscount, setIsMixedDiscount] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const itemApi = useMemo(() => new ItemApi(), [])

  const apiImages = useMemo(() => new DigitalImagesApi(), [])

  useEffect(() => {
    if (selectedOffer) {
      setLoading(true)
      let completeItemList = []
      selectedOffer.forEach(async (offer) => {
        setIsArtPatch(offer.ad_patches && offer.ad_patches.length > 1)
        setIsMixedDiscount(offer.mixed_discount)
        const picturedItemsList = fetchAllPicturedItems(offer)

        // only search offers with pictured items
        if (picturedItemsList.length > 0) {
          const response = await itemApi.postItemsByOfferUUID(
            offer.id,
            picturedItemsList
          )
          const results = await sortItemsAndAddDetails(
            response,
            picturedItemsList,
            offer.ad_patches,
            offer.ivy_offer_id,
            '',
            offer.offer_order
          )
          completeItemList = await [...results, ...completeItemList]
          const sortResult = sortBy(completeItemList, [
            'offer_order',
            'item_order',
          ])
          setPublishItemElements(sortResult)
        }
      })
      setLoading(false)
    }
  }, [apiImages, itemApi, selectedOffer, updatedItemImage])

  return isLoading ? (
    <div className="bucketItem">
      <ProgressBar indeterminate />
    </div>
  ) : (
    <>
      <div data-testid="PublishItemsBucket">
        {publishItemElements?.map((picturedItem, index) => (
          <div
            className="bucketItem"
            key={'item-' + index}
            data-testid={'itemCards' + index}
          >
            <PublishingItem
              picturedItem={picturedItem}
              canEdit={canEdit}
              showPriority={showPriority}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setItemSelected={setItemSelected}
              isArtPatch={isArtPatch}
              isMixedDiscount={isMixedDiscount}
              updatedItemImage={updatedItemImage}
              pricePatches={selectedOffer.price_patches}
              boostedTcins={boostedTcins}
              setBoostedTcins={setBoostedTcins}
              setChangeDetected={setChangeDetected}
            ></PublishingItem>
          </div>
        ))}
      </div>
    </>
  )
}

export default PublishItemsBucket
