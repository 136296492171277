import { Form, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EffectiveRoleContext } from '../../context/Context'
import { isInCanEditPlanningViewGroup } from '../../util/CheckRole'

import '../scss/StickyBarPlanning.scss'

const StickyBarPlanning = (props) => {
  const { search, setSearch, pastProject, setPastProject, setPageNum } = props
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const [canEdit, setCanEdit] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setCanEdit(isInCanEditPlanningViewGroup(effectiveRole))
  }, [effectiveRole, setCanEdit])

  const handlePastProject = () => {
    setPastProject(!pastProject)
    setPageNum(0)
  }

  const handleCreateProject = (e) => {
    navigate(`/createEditProject`)
  }

  return (
    <Grid.Container
      className="stickybar-container-planning"
      data-testid="stickyBar"
    >
      {canEdit ? (
        <Grid.Item>
          <Button
            className="gray-btn"
            onClick={(e) => {
              handleCreateProject(e)
            }}
            data-testid="createButton"
            data-cy="create-project"
          >
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            <span>Create Project</span>
          </Button>
        </Grid.Item>
      ) : null}
      <Grid.Item>
        <Form.Field
          id="sticky-search"
          label="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          data-cy="search-project"
        />
      </Grid.Item>
      <Grid.Item>
        <Button
          className="gray-btn"
          onClick={handlePastProject}
          data-testid="pastProjectButton"
          data-cy="view-past-project"
        >
          {!pastProject ? (
            <span> View Past Projects </span>
          ) : (
            <span> View Upcoming Projects </span>
          )}
        </Button>
      </Grid.Item>
    </Grid.Container>
  )
}
export default StickyBarPlanning
