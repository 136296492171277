export const getPyramidColor = (pyramid) => {
  switch (pyramid) {
    case 'a&a':
      return { colorCode: '#313199', colorLabel: 'indigo' }
    case 'bty':
    case 'ess':
    case 'bty/ess':
      return { colorCode: '#6320aa', colorLabel: 'rebeccapurple' }
    case 'f&b':
      return { colorCode: '#1e7854', colorLabel: 'green' }
    case 'hrdl':
      return { colorCode: '#b0270d', colorLabel: 'red' }
    case 'home':
      return { colorCode: '#128491', colorLabel: 'blue' }
    case 'ent':
      return { colorCode: '#757577', colorLabel: 'darkGrey' }
    default:
      return { colorCode: '#6b7aa1', colorLabel: 'darkGrey' }
  }
}

export const getPyramidFullName = (pyramidType) => {
  switch (pyramidType) {
    case 'ent':
      return 'Enterprise'
    case 'a&a':
      return 'Apparel & Accessories'
    case 'bty':
      return 'Beauty'
    case 'ess':
      return 'Essentials'
    case 'f&b':
      return 'Food & Beverage'
    case 'hrdl':
      return 'Hardlines'
    case 'home':
      return 'Home'
    default:
      return pyramidType?.toUpperCase()
  }
}

export const getPyramidAbbrByGroupName = (groupName) => {
  switch (groupName) {
    case 'APPAREL/ACCESS':
      return 'a&a'
    case 'MENS/KIDS':
      return 'a&a'
    case 'BEAUTY/COSMETICS':
      return 'bty'
    case 'ESSENTIALS':
      return 'ess'
    case 'ESSENTIALS &amp; BEAUTY':
    case 'ESSENTIALS & BEAUTY':
      return 'bty/ess'
    case 'FOOD/BEVERAGE':
      return 'f&b'
    case 'HARDLINES':
      return 'hrdl'
    case 'HOME':
      return 'home'
    default:
      return null
  }
}

export const getPyramidColorByGroupName = (pyramid) => {
  switch (pyramid) {
    case 'APPAREL/ACCESS':
      return { colorCode: '#313199', colorLabel: 'indigo' }
    case 'BEAUTY/COSMETICS':
    case 'ESSENTIALS':
    case 'ESSENTIALS &amp; BEAUTY':
    case 'ESSENTIALS & BEAUTY':
      return { colorCode: '#6320aa', colorLabel: 'rebeccapurple' }
    case 'FOOD/BEVERAGE':
      return { colorCode: '#1e7854', colorLabel: 'green' }
    case 'HARDLINES':
      return { colorCode: '#b0270d', colorLabel: 'red' }
    case 'HOME':
      return { colorCode: '#128491', colorLabel: 'blue' }
    case 'ENTERPRISE':
      return { colorCode: '#757577', colorLabel: 'darkGrey' }
    default:
      return { colorCode: '#6b7aa1', colorLabel: 'darkGrey' }
  }
}
export const pyramidAbbreviations = [
  'ent',
  'a&a',
  'bty',
  'ess',
  'f&b',
  'hrdl',
  'home',
]

export const getTierAbbreviation = (ivyTier) => {
  switch (ivyTier) {
    case 'ENTERPRISE':
      return 'EP'
    case 'PYRAMID':
      return 'PP'
    case 'STANDARD':
      return 'SP'
    default:
      return '---'
  }
}

export const getChipValues = (pyramidType) => {
  switch (pyramidType) {
    case 'ent':
      return 'EP'
    case 'a&a':
      return 'A&A'
    case 'bty':
      return 'BTY'
    case 'ess':
      return 'ESS'
    case 'f&b':
      return 'F&B'
    case 'hrdl':
      return 'HRDL'
    case 'home':
      return 'HOME'
    default:
      return pyramidType.toUpperCase()
  }
}

export const pyramid_options = [
  {
    color: '#313199',
    id: 0,
    label: 'Apparel & Accessories',
    value: 'a&a',
  },
  {
    color: '#6320aa',
    id: 1,
    label: 'Beauty',
    value: 'bty',
  },
  {
    color: '#6320aa',
    id: 2,
    label: 'Essentials',
    value: 'ess',
  },
  {
    color: '#1e7854',
    id: 3,
    label: 'Food & Beverage',
    value: 'f&b',
  },
  {
    color: '#b0270d',
    id: 4,
    label: 'Hardlines',
    value: 'hrdl',
  },
  {
    color: '#128491',
    id: 5,
    label: 'Home',
    value: 'home',
  },
  {
    color: '#757577',
    id: 6,
    label: 'Enterprise',
    value: 'ent',
  },
]

export const formatter = new Intl.NumberFormat()
