export function atHomePage(location) {
  return (
    location?.pathname === '/' ||
    location?.pathname.endsWith('catalog') ||
    location?.pathname.endsWith('weeklyad') ||
    location?.pathname.endsWith('pastads') ||
    location?.pathname.endsWith('vendor') ||
    location?.pathname.endsWith('past-catalogs')
  )
}

export function inCatalogs(location) {
  return (
    (location?.pathname.includes('catalog') ||
      location?.pathname.includes('past-catalogs')) &&
    !location?.pathname.endsWith('catalog') &&
    !location?.pathname.endsWith('vendor')
  )
}

export function inWeeklyAd(location) {
  return (
    (location?.pathname.startsWith('/weeklyad') &&
      location?.pathname !== '/change-tracking') ||
    location?.pathname.startsWith('/pastads')
  )
}

export function inDpciSearch(location) {
  return location?.pathname.endsWith('dpci')
}

export function inPastCatalogs(location) {
  return location?.pathname.includes('past-catalogs')
}

export function inVendorView(view) {
  return view === 'vendor'
}

export function inProductListView(view) {
  return view === 'product'
}

export function inCreativePageView(view) {
  return view === 'creative'
}

export function inImageSampleView(view) {
  return view === 'sample'
}
