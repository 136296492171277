import {
  Button,
  Card,
  Dialog,
  Grid,
  List,
  Modal,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

const EditOfferArtPatch = (props) => {
  const {
    editOfferArtPatch,
    setEditOfferArtPatch,
    setAddOfferToArtPatch,
    putOffer,
  } = props
  const handleDialogClose = () => {
    setEditOfferArtPatch({ body: {}, show: false })
  }

  const [splitDialogVisible, setSplitDialogVisible] = useState(false)
  const [editDialogVisible, setEditDialogVisible] = useState({
    offer: {},
    show: false,
  })

  const followers = editOfferArtPatch?.body?.followers
  const leader = editOfferArtPatch?.body?.leader

  const removeFollowerFromArtPatch = () => {
    putOffer([
      {
        id: editDialogVisible.offer.id,
        ivy_offer_id: editDialogVisible.offer.ivy_offer_id,

        ivy_umbrella_id: editDialogVisible.offer.ivy_umbrella_id,
        // Set offerArtPatchLeaderId field to follower's primary key to distinguish unlinking PUT /offer call from PUT
        // call that's invoked when clicking on umbrella.
        offer_art_patch_leader_id: editDialogVisible.offer.id,
        offer_order: editDialogVisible.offer.offer_order,
        project_id: editDialogVisible.offer.project_id,
        umbrella_id: editDialogVisible.offer.umbrella_id,
      },
    ])
    setEditDialogVisible({ offer: {}, show: false })
    setEditOfferArtPatch({ body: {}, show: false })
  }

  const unlinkOfferArtPatches = () => {
    const allOffersInArtPatch = followers.map((follower) => ({
      id: follower.id,
      ivy_offer_id: follower.ivy_offer_id,
      ivy_umbrella_id: follower.ivy_umbrella_id,
      offer_art_patch_leader_id: follower.id,
      offer_order: follower.offer_order,
      project_id: follower.project_id,
      umbrella_id: follower.umbrella_id,
    }))

    putOffer(allOffersInArtPatch)
    setSplitDialogVisible(false)
    setEditOfferArtPatch({ body: {}, show: false })
  }

  return followers ? (
    <Modal
      isVisible={editOfferArtPatch.show}
      onRefuse={handleDialogClose}
      headingText={'Edit Art Patch: ' + leader?.name}
    >
      <div className="hc-pa-normal hc-pt-dense">
        <List>
          {followers.map((follower) => (
            <Card
              className="hc-mt-dense hc-mb-dense hc-bg-grey06"
              key={follower.id}
            >
              <List.Item>
                {follower.name}
                <Button
                  onClick={() =>
                    setEditDialogVisible({ offer: follower, show: true })
                  }
                  className="C-ListItem__item__button"
                  iconOnly
                >
                  <Tooltip content="Unlink Art Patch" location="top">
                    <FontAwesomeIcon
                      icon={faLink}
                      style={{ display: 'block' }}
                    />
                  </Tooltip>
                </Button>
              </List.Item>
            </Card>
          ))}
        </List>

        <Grid.Container
          className="hc-pt-expanded"
          spacing="dense"
          justify="space-between"
        >
          {Boolean(
            editOfferArtPatch.body?.offers?.filter((offer) => !offer.artPatch)
              .length
          ) && (
            <Grid.Item xs={12}>
              <Button
                type="primary"
                disabled={false}
                onClick={() =>
                  setAddOfferToArtPatch({
                    body: editOfferArtPatch.body,
                    show: true,
                  })
                }
              >
                Add Offer to Art Patch
              </Button>
            </Grid.Item>
          )}
          <Grid.Item>
            <Button raise onClick={() => setSplitDialogVisible(true)}>
              Unlink All Offers
            </Button>
          </Grid.Item>

          <Grid.Item>
            <Button type="secondary" onClick={handleDialogClose}>
              Close
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
      <Dialog
        headingText="Are you sure you want to unlink all offers from the art patch?"
        approveButtonText="Yes"
        isVisible={splitDialogVisible}
        onApprove={unlinkOfferArtPatches}
        refuseButtonText="No"
        onRefuse={() => setSplitDialogVisible(false)}
      />
      <Dialog
        headingText="Are you sure you want to remove this offer from the art patch?"
        isVisible={editDialogVisible.show}
        approveButtonText="Remove"
        onApprove={removeFollowerFromArtPatch}
        onRefuse={() => setEditDialogVisible({ offer: {}, show: false })}
        refuseButtonText="Cancel"
      />
    </Modal>
  ) : null
}
export default EditOfferArtPatch
