import { Button, Grid, Image, Heading } from '@enterprise-ui/canvas-ui-react'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../scss/PageView.scss'

const PageView = (props) => {
  const { onClose, isVisible, page } = props
  const hasItems = (cuts) => {
    let itemCount = 0

    cuts.forEach((cut) => {
      itemCount = itemCount + cut.items.length
    })

    return itemCount
  }

  const downloadPageImages = (page) => {
    let assethubLinks = []
    if (page.stories.length > 0) {
      page.stories.forEach((story) =>
        story.cuts.forEach((cut) =>
          cut.items.forEach((item) => {
            if (
              item.image.match('(/assethub_assets/[^ "]+$)+') ||
              item.image.match('(/vault_digital_assets/[^ "]+$)+')
            ) {
              assethubLinks.push(`${item.image}&is_download=true`)
            }
          })
        )
      )
    }
    if (assethubLinks.length > 0) {
      assethubLinks.forEach((link) => window.open(link, '_blank'))
    }
  }

  const onDialogClose = (event) => {
    if (event.target.id === 'filter') {
      onClose(false)
    }
  }

  return (
    isVisible && (
      <div id="filter" className="page-view-filter" onClick={onDialogClose}>
        <div className="page-view-container">
          <Button
            iconOnly
            onClick={() => onClose(false)}
            className="page-view-close"
          >
            <FontAwesomeIcon size="lg" icon={faTimesCircle} color="#000000" />
          </Button>
          <Button
            className="page-view-download"
            onClick={() => downloadPageImages(page)}
          >
            Download Selected AssetHub Images
          </Button>
          <Grid.Container
            spacing="none"
            align="center"
            className="hc-ta-center"
          >
            <Heading className="hc-ta-center" size={4}>
              {`Item View - Page ${page.bookOnePageNumber}: ${page.businessObjectives}`}
            </Heading>
            {page.stories.map((story, storyCount) =>
              story.cuts.length && hasItems(story.cuts) ? (
                <Grid.Item key={story.id} xs={12}>
                  <div>
                    <Heading size={5}>
                      {`Story ${storyCount + 1}: ${story.name}`}
                    </Heading>
                    <Heading size={6}>
                      {`Promo message: ${story.promoMessaging}`}
                    </Heading>
                    <div className="page-view-story">
                      {story.cuts.map((cut) =>
                        cut.items.length ? (
                          <div key={cut.id} className="page-view-cut">
                            <div className="page-view-cut--count">
                              {cut.priority + 1}
                            </div>
                            {cut.items.map((item) => (
                              <div key={item.id} className="page-view-item">
                                {item?.image?.match(
                                  '(/assethub_assets/[^ "]+$)+'
                                ) ? (
                                  <p className="page-view-assethub">
                                    AssetHub Image
                                  </p>
                                ) : (
                                  ''
                                )}
                                <Image
                                  width="140px"
                                  type="contain"
                                  position="center-center"
                                  src={item.image}
                                />
                                <Heading size={8}>{item.dpci}</Heading>
                                <Heading size={12}>{item.description}</Heading>
                              </div>
                            ))}
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                </Grid.Item>
              ) : null
            )}
          </Grid.Container>
        </div>
      </div>
    )
  )
}
export default PageView
