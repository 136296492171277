import { getEnvConfig } from '@praxis/component-runtime-env'
import { merge } from 'lodash'

import apiConfig from './apiConfig'
import commonConfig from './commonConfig'

const appConfig = () => {
  const envConfig = getEnvConfig(
    process.env.NODE_ENV === 'development' ? '/config.json' : '/app-environment'
  )
  return merge(commonConfig, envConfig, apiConfig)
}
export default appConfig
