import { Form, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import getPageTitle from '../util/GetPageTitle'

const AddStory = (props) => {
  const { show, setShow, page, postStory, closeModal } = props

  return page ? (
    <Formik
      enableReinitialize
      initialValues={{
        add_story_name: '',
        add_story_promoMessaging: '',
      }}
      onSubmit={(values, funcs) => {
        postStory({
          body: {
            name: values.add_story_name,
            pageId: page.id,
            promoMessaging: values.add_story_promoMessaging,
          },
          pageId: page.id,
        })
        setShow(false)
        funcs.resetForm()
        closeModal()
      }}
    >
      {({ values, handleBlur, handleChange, handleSubmit, handleReset }) => {
        const handleDialogClose = () => {
          handleReset()
          setShow(false)
        }
        return (
          <Modal
            isVisible={show}
            headingText={`Add story to ${getPageTitle(page)}`}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <Form.Field
                id="add_story_name"
                label="Story Name"
                value={values.add_story_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Field
                id="add_story_promoMessaging"
                label="Promo Message"
                value={values.add_story_promoMessaging}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default AddStory
