import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import OfferMapper from '../mappers/offerMapper'

const useGetOffer = (setLoading) => {
  const config = appConfig()
  const url = useRef(
    config.backend.root +
      config.backend.offer +
      '?statuses=live,ready,messaging,draft_coe,draft_merchant,review_merchant,review_coe'
  )
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const getOffers = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(
          url.current +
            `&startDate=${request.startDate}` +
            `&divisionIds=${request.divisionId}` +
            (request.cutId ? `&cutId=${request.cutId}` : '')
        )
        mounted &&
          request.setOffers(response.data.map((offer) => OfferMapper(offer)))
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
    }
    if (request !== null) {
      let thenFunc =
        typeof request.then === 'function' ? request.then : () => {}
      getOffers().then(() => thenFunc())
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading])

  return setRequest
}
export default useGetOffer
