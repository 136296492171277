const lateAdditionMapper = (lateItem) => ({
  deleted: lateItem.deleted,
  description: lateItem.description,
  dpci: lateItem.dpci,
  id: lateItem.id,
  jiraFormNumber: lateItem.jiraFormNumber,
  lastUpdatedTimestamp: lateItem.lastUpdatedTimestamp,
  notes: lateItem.notes,
  pageNumber: lateItem.pageNumber,
  sample: lateItem.sample,
  sampleId: lateItem.sampleId,
  samplePulled: lateItem.samplePulled,
  weeklyAdId: lateItem.weeklyAdId,
})
export default lateAdditionMapper
