const StoryList = ({ selectedStoryId, stories = [], handleStorySelection }) =>
  stories.map((story, ind) => (
    <StoryItem
      key={story.story_id}
      isSelected={selectedStoryId === story.story_id}
      ind={ind}
      story={story}
      handleStorySelection={handleStorySelection}
    />
  ))

const StoryItem = ({ ind, story, isSelected, handleStorySelection }) => (
  <div
    className={`story-item-container${
      isSelected ? ' selected-story-item-container' : ''
    }`}
    onClick={() => handleStorySelection(story.story_id, ind + 1)}
  >
    <h4 data-cy={`storyItem ${ind}`}>Story {story.story_order}</h4>
    <div>{story?.story_name}</div>
  </div>
)

export default StoryList
