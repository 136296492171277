import formatDecimal from '../util/formatDecimal'

import itemPatchMapper from './itemPatchMapper'

const itemMapper = (item) => ({
  adIndicator: item.adIndicator,
  alternateImages: item.alternateImages,
  complete: item.complete,
  createdTimestamp: item.createdTimestamp,
  cutId: item.cutId,
  deleted: item.deleted,
  description: item.description,
  digitalItemPrimaryImage: item.digitalItemPrimaryImage,
  dpci: item.dpci,
  figModel: item.figModel,
  id: item.id,
  image: item.image,
  imageFileName: item.imageFileName,
  imagePulled: item.imagePulled,
  imageType: item.imageType,
  itemPatches: item.itemPatches
    ? item.itemPatches
        .map(itemPatchMapper)
        .filter((itemPatch) => itemPatch.deleted === false)
        .sort((a, b) => a.id - b.id)
    : [],
  ivyPriority: item.ivyPriority,
  lastUpdatedTimestamp: item.lastUpdatedTimestamp,
  marketString: item.marketString,
  marketingNotes: item.marketingNotes,
  markets: item.markets,
  merchLastUpdatedField: item.merchLastUpdatedField,
  merchLastUpdatedTimestamp: item.merchLastUpdatedTimestamp,
  merchNotes: item.merchNotes,
  offerId: item.offerId,
  pageNumber: item.pageNumber,
  priority: item.priority,
  pyramid: item.pyramid,
  regPriceCallout: item.regPriceCallout,
  regUnitRetail: formatDecimal(item.regUnitRetail),
  sample: item.sample,
  sampleId: item.sampleId,
  sampleImageComments: item.sampleImageComments,
  sampleLastUpdatedField: item.sampleLastUpdatedField,
  sampleLastUpdatedTimestamp: item.sampleLastUpdatedTimestamp,
  sampleNeeded: item.sampleNeeded,
  samplePulled: item.samplePulled,
  sampleRequestReason: item.sampleRequestReason,
  tcin: item.tcin,
  valid: item.valid,
})
export default itemMapper
