import {
  Document,
  Font,
  Link,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from '@react-pdf/renderer'
import moment from 'moment'
import { Fragment } from 'react'

import HelveticaForTarget from '../../../fonts/HelveticaForTarget/HelveticaforTarget.otf'
import noImagePlaceholder from '../../../media/NoImagePlaceholder.png'
import '../../scss/Category.scss'

import Details from './Details'
import MediaUnitTitle from './Title'

Font.register({
  family: 'HelveticaForTarget',
  src: HelveticaForTarget,
})

Font.registerHyphenationCallback((word) => [word])
const styles = StyleSheet.create({
  container: {
    borderBottom: '2px solid #ededed',
    borderLeft: '2px solid #ededed',
    display: 'flex',
    flexDirection: 'row',
    height: '250px',
    width: '100%',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
  },
  footer: {
    borderColor: 'gray',
    borderStyle: 'dashed',
    borderWidth: 3,
    fontSize: 12,
    marginTop: 15,
    paddingTop: 5,
    textAlign: 'center',
  },
  hContainer: {
    alignItems: 'stretch',
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    flexDirection: 'row',
  },
  image: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 0,
    marginTop: 10,
  },
  leftColumn: {
    backgroundColor: 'lightgray',
    flexDirection: 'column',
    paddingRight: 15,
    paddingTop: 30,
    width: 200,
  },
  link: {
    alignSelf: 'flex-end',
    color: 'blue',
    fontSize: 16,
    justifySelf: 'flex-end',
    textDecoration: 'none',
  },
  linkColumn: {
    alignSelf: 'flex-end',
    flexDirection: 'column',
    flexGrow: 2,
    justifySelf: 'flex-end',
  },
  name: {
    alignItems: 'center',
    fontSize: 28,
    justifyContent: 'center',
  },
  page: {
    fontFamily: 'HelveticaForTarget',
    padding: 30,
  },
  subtitle: {
    fontSize: 18,
    justifySelf: 'flex-end',
    paddingTop: 10,
  },
  versionContainer: {
    borderLeft: '2px solid lightgray',
    flex: 1,
    flexDirection: 'row',
    marginBottom: 15,
    marginLeft: 50,
  },
})

const ExportPDFMediaUnit = ({
  mediaUnitData,
  selectedCategory,
  selectedProject,
}) => {
  const MediaUnitPreview = (props) => {
    const { mediaUnit, versionNumber } = props

    return (
      <View wrap={false} id={'media-unit-key-' + mediaUnit.id}>
        {versionNumber === '1' && (
          <MediaUnitTitle name={mediaUnit.name} versions={mediaUnit.versions} />
        )}
        <View
          wrap={false}
          id={'pdf-content-' + mediaUnit.id}
          style={styles.container}
        >
          <View id={'pdf-image-' + mediaUnit.id} style={styles.leftColumn}>
            <Image
              src={
                mediaUnit.images?.[0]?.external_url?.length
                  ? mediaUnit.images?.[0]?.external_url
                  : noImagePlaceholder
              }
              style={styles.image}
              cache={true}
            />
          </View>
          <Details mediaUnit={mediaUnit} versionNumber={versionNumber} />
        </View>
      </View>
    )
  }

  const Header = () => (
    <View fixed key="pdf-header + {mediaUnitData.id}" style={styles.hContainer}>
      <View style={styles.detailColumn}>
        <Text style={styles.name}>
          {selectedCategory} - {mediaUnitData?.length} Media Units
        </Text>
        <Text style={styles.subtitle}>
          {moment(selectedProject?.start_date).format('MM/DD/YYYY')} -
          {moment(selectedProject?.end_date).format('MM/DD/YYYY')}
        </Text>
      </View>
      <View style={styles.linkColumn}>
        <Link target="_blank" href={window.location} style={styles.link}>
          {selectedProject?.project_name}
        </Link>
      </View>
    </View>
  )

  return (
    <Fragment key={`category-pdf-${selectedCategory}`}>
      <Document>
        <Page size="A2" key={'page'} style={styles.page}>
          <Header />
          {mediaUnitData.map((mediaUnit) => (
            <Fragment key={'media-unit-key-frag-' + mediaUnit.id}>
              <MediaUnitPreview
                key={'mu-details-1' + mediaUnit.id}
                mediaUnit={mediaUnit}
                versionNumber={'1'}
              />
              {mediaUnit.versions &&
              mediaUnit.versions !== undefined &&
              mediaUnit.versions.length > 0
                ? mediaUnit.versions.map((version, i) => (
                    <MediaUnitPreview
                      key={'mu-version-' + version.id}
                      mediaUnit={version}
                      versionNumber={i + 2}
                    />
                  ))
                : null}
            </Fragment>
          ))}
        </Page>
      </Document>
    </Fragment>
  )
}

export default ExportPDFMediaUnit
