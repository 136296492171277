import axios from 'axios'
import { useEffect, useState } from 'react'

import appConfig from '../config/appConfig'
import paginationMapper from '../mappers/paginationMapper'
import propItemMapper from '../mappers/propItemMapper'

const useGetPropList = (setLoading, query, wadId) => {
  const config = appConfig()
  const url =
    config.backend.root +
    config.backend.props +
    `?${query.toString()}&wadId=${wadId}`
  const [propItems, setPropItems] = useState({})
  const [loadPropItems, setLoadPropItems] = useState(true)

  useEffect(() => {
    let mounted = true
    const getPropItems = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(url)
        mounted &&
          setPropItems(paginationMapper(response.data, propItemMapper, 'props'))
      } catch (e) {
        console.log(e)
      }
      mounted && setLoadPropItems(false)
      mounted && setLoading(false)
    }

    if (loadPropItems) {
      getPropItems()
    }
    return () => {
      mounted = false
    }
  }, [url, setLoading, loadPropItems])

  return [propItems, setLoadPropItems, setPropItems]
}
export default useGetPropList
