import { TARGET_CIRCLE_DOMAIN, TARGET_WEB_DOMAIN } from '../../util/Constants'
const replaceAt = (index, replacement, word) =>
  word.substr(0, index) + replacement + word.substr(index + replacement.length)

export const capFirstLetter = (sentence) => {
  if (!sentence) {
    return ''
  }

  const newWord = []
  sentence.split(' ').map((word) => {
    let theWord = word.toLowerCase()
    const cap = (theWord[0] || '').toUpperCase()
    theWord = replaceAt(0, cap, theWord)
    return newWord.push(theWord)
  })
  return newWord.join(' ')
}

export const changeDateFormat = (date) =>
  date.getUTCMonth() + 1 + '/' + date.getUTCDate() + '/' + date.getFullYear()

export const convertCategoryDate = (instant, projectDate) => {
  if (instant && instant !== 'null') {
    let dateFromCategory = new Date(instant.split('T')[0])
    return changeDateFormat(dateFromCategory)
  } else if (projectDate && projectDate !== 'null') {
    let dateFromProject = new Date(projectDate)
    return changeDateFormat(dateFromProject)
  } else {
    return
  }
}

export const prepCategoryDateForUpdate = (catDate) => {
  if (catDate && catDate !== 'null') {
    let date = new Date(catDate)

    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2)
    )
  } else {
    return
  }
}

export const missingContacts = (values) => Object.keys(values)?.length < 7

const roleKey = (key) => {
  switch (key) {
    case 'bcm':
      return 1
    case 'ea manager':
      return 2
    case 'target creative':
      return 3
    case 'art director':
      return 4
    case 'assistant creative director':
      return 5
    case 'promo production design':
      return 6
    case 'copy writer':
      return 7
    default:
      return key
  }
}

export const unGroup = (groupedArray) => {
  const newArray = []
  for (const [key, value] of Object.entries(groupedArray)) {
    value.forEach((x) => {
      newArray.push({
        contact_name: x.label,
        id: x.project_category_unique_id ? x.project_category_unique_id : null,
        role_id: roleKey(key),
        role_name: key,
      })
    })
  }
  return newArray
}

export const groupBy = (objectArray, property) =>
  objectArray.reduce((acc, obj) => {
    const key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    // Add object to list for given key's value
    acc[key].push({
      id: obj.id,
      label: obj.contact_name,
      project_category_unique_id: obj.id,
      role_id: obj.role_id,
      value: obj.contact_name,
    })
    return acc
  }, {})

export const prepStoredContacts = (dbContacts) => {
  const groupedContacts = groupBy(dbContacts, 'role_id')

  return groupedContacts
}

export const convertCategoryOptions = (optionsArr, view) => {
  if (!optionsArr?.length) {
    return
  }
  return (
    optionsArr
      //currently we are storing the grouped offers in category 6100001 we don't want to show that for category ammplification
      .filter((option) => option.id !== 6100001 && option[view] === true)
      .map((option) => ({
        ...option,
        label: capFirstLetter(option.name),
        name: option.name,
        value: option.name.toLowerCase(),
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  )
}

export const convertMarketOptions = (marketsArr) => {
  if (!marketsArr?.length) {
    return
  }

  return marketsArr
    ?.reduce((acc, market, i) => {
      const { name, id } = market
      if ((name, id)) {
        acc.push({
          ...market,
          label: name,
          value: id,
        })
      }
      return acc
    }, [])
    .sort((a, b) => a.name?.localeCompare(b.name))
}

const sortingArr = [
  'mw',
  'mw1',
  'mw2',
  'mw3',
  'mw4',
  'ce',
  'ne',
  'ne1',
  'ov',
  'ov1',
  'sc',
  'sc1',
  'sc2',
  'sc3',
  'sc4',
  'sc5',
  'gc',
  'ma',
  'ma1',
  'ma2',
  'ma3',
  'ma4',
  'sfl',
  'mtn',
  'mtn1',
  'pnw',
  'nca',
  'sca',
  'az',
  'hi',
  'ak',
]

// markets within a version should be sorted as defined by the above array
export const sortMarkets = (array) => {
  if (array && array.length > 0) {
    return array.sort(
      (a, b) =>
        sortingArr.indexOf(a.name?.toLowerCase()) -
        sortingArr.indexOf(b.name?.toLowerCase())
    )
  }
}

// versions of a media unit should be sorted as defined by the above array
export const sortVersions = (array) =>
  array.sort(
    (a, b) =>
      sortingArr.indexOf(a.markets[0]?.name?.toLowerCase()) -
      sortingArr.indexOf(b.markets[0]?.name?.toLowerCase())
  )

export const getCategoryLabels = (categoriesSelected, categoryOptions) => {
  const categoryLabels = categoriesSelected?.map((category) => {
    const id = category.id
    return categoryOptions.find((category) => category.id === id)
  })

  return categoryLabels
}

// this function finds the ad patch with the midwest version in ad patched offers.  Or returns the standard markets for non ad patched offers
export const setOfferMarkets = (offerData) => {
  let offerMarkets = offerData[0].markets

  if (offerData[0]?.ad_patches.length > 1) {
    const mwVersion = offerData[0]?.ad_patches.filter((adPatch) =>
      adPatch.markets.find((adMarket) => adMarket === 'MW')
    )
    offerMarkets = mwVersion[0].markets
  }
  return offerMarkets
}

export const offerToMediaUnitXfer = (offerData) => {
  var returnArray = []

  if (offerData[0].markets) {
    returnArray.push('markets')
  }
  if (offerData[0].saleHandle === 'Yes') {
    returnArray.push('sale_handle')
  }
  return returnArray
}

export const marketsToString = (marketsArr) => {
  if (marketsArr && marketsArr.length > 0) {
    const sortedMarkets = sortMarkets(marketsArr)

    return sortedMarkets.reduce((acc, market, index) => {
      const { name } = market
      if (name) {
        acc += sortedMarkets[index + 1] ? `${name}, ` : name
      }
      return acc
    }, ``)
  } else {
    return ''
  }
}

export const reducePayload = (payload) =>
  Object.keys(payload).reduce((acc, key) => {
    if (payload[key] && payload[key].length) {
      acc[key] = payload[key]
    }
    return acc
  }, {})

export const isChannelCheck = (channels, channelId) => {
  if (channels === undefined) return false
  const found = channels?.find((channel) => channel.channel_id === channelId)
  return found
}

export const getMarketsNickName = (markets, marketOptions) => {
  if (markets.length === marketOptions.length) return 'All Markets'
  else if (
    markets.length === 29 &&
    markets.map((market) => !['AK', 'HI'].includes(market.name))
  ) {
    return 'Mainland'
  } else if (
    markets.length === 2 &&
    markets.some((e) => e.name === 'AK') &&
    markets.some((e) => e.name === 'HI')
  ) {
    return 'Off Shore'
  } else {
    return ''
  }
}

const patchDetails = (finalMarkets, price_patch) => {
  var patchDetails = {
    markets: finalMarkets,
    price: price_patch,
  }

  return patchDetails
}

export const buildAutoVersionBody = (
  offerData,
  marketOptions,
  mediaUnitData
) => {
  var autoVersionBody = []
  if (offerData?.price_patches?.length) {
    var finalMarkets = expandMarkets(
      offerData.markets,
      offerData.location_group_id,
      marketOptions
    )
    // for price patch,  use the existing media unit as one of the versions
    autoVersionBody.push(patchDetails(finalMarkets, offerData.price))

    offerData.price_patches.forEach((pricePatch) => {
      var finalMarkets = expandMarkets(
        pricePatch.price_markets,
        offerData.location_group_id,
        marketOptions
      )

      autoVersionBody.push(patchDetails(finalMarkets, pricePatch.price_patch))
    })
    return autoVersionBody
  } else if (offerData?.ad_patches.length > 1) {
    offerData.ad_patches.forEach((adPatch) => {
      var finalMarkets = expandMarkets(
        adPatch.markets,
        offerData.location_group_id,
        marketOptions
      )
      var patchDetails = {
        markets: finalMarkets,
        price: adPatch.price,
      }
      autoVersionBody.push(patchDetails)
    })
    return autoVersionBody
  } else {
    var singleNewVersion = {
      markets: mediaUnitData.markets,
      price: mediaUnitData.price,
    }
    autoVersionBody.push(singleNewVersion)

    return autoVersionBody
  }
}

export const buildUrl = (offerData, mu) => {
  if (offerData.length > 1) {
    return `${TARGET_WEB_DOMAIN}pl/9876-${mu.go_id}?marketing_id=9876-${mu.go_id}`
  } else if (offerData[0].circle_offer) {
    return `${TARGET_CIRCLE_DOMAIN}${offerData[0].ivy_offer_id}`
  } else {
    return `${TARGET_WEB_DOMAIN}pl/${offerData[0].ivy_offer_id}`
  }
}

export const expandMarkets = (markets, locationGroupId, marketOptions) => {
  var expandedMarketNames = []
  if (locationGroupId === 'CC') {
    expandedMarketNames = markets.flatMap((o) => {
      switch (o) {
        case 'MW':
          return [o, 'MW1', 'MW2', 'MW3', 'MW4']
        case 'SC':
          return [o, 'SC1', 'SC2', 'SC3', 'SC4', 'SC5']
        case 'MA':
          return [o, 'MA1', 'MA2', 'MA3', 'MA4']
        case 'OV':
          return [o, 'OV1']
        case 'MTN':
          return [o, 'MTN1']
        case 'NCA':
          return [o]
        case 'SCA':
          return [o]
        case 'AZ':
          return [o]
        case 'NE':
          return [o, 'NE1']
        case 'CE':
          return [o]
        case 'GC':
          return [o]
        case 'SFL':
          return [o]
        case 'AK':
          return [o]
        case 'HI':
          return [o]
        case 'PNW':
          return [o]
        default:
          return null
      }
    })
  } else {
    expandedMarketNames = markets
  }

  if (marketOptions !== undefined) {
    var newArray = expandedMarketNames.map((o) =>
      marketOptions.find((m) => m.name === o)
    )
  }

  return newArray.filter((element) => element !== undefined)
}

export const bcmCategoryOptions = [
  {
    label: 'Select a Category',
    value: 0,
  },
  {
    label: 'AA',
    value: '1',
  },
  {
    label: 'Baby',
    value: '2',
  },
  {
    label: 'Beauty',
    value: '3',
  },
  {
    label: 'CrossCatFrequency',
    value: '4',
  },
  {
    label: 'CrossCatDiscretionary',
    value: '5',
  },
  {
    label: 'CrossCatTotal',
    value: '6',
  },
  {
    label: 'Hardlines All Other',
    value: '7',
  },
  {
    label: 'Essentials',
    value: '8',
  },
  {
    label: 'FB',
    value: '9',
  },
  {
    label: 'Hardlines',
    value: '10',
  },
  {
    label: 'Home',
    value: 11,
  },
  {
    label: 'NA',
    value: 12,
  },
]

export const consolidateMediaUnitOffers = (
  offerDetails,
  offersObj,
  mediaUnitOffers
) => {
  let newOffers = []
  for (const offer of offersObj) {
    let index = offerDetails.findIndex((x) => x.id === offer.id)
    offerDetails[index].external_promotion_id = offer?.external_promotion_id
    if (
      mediaUnitOffers.filter((offer) => offer.id === offerDetails[index].id)
        .length === 0
    ) {
      newOffers.push(offerDetails[index])
    }
  }
  return newOffers
}

export const responseErrorCheck = (responseObject) => {
  if (responseObject && !responseObject.error && responseObject.id) {
    return false
  } else {
    return true
  }
}

export const isBoosted = (boostedTcins, tcinValue) => {
  if (boostedTcins && boostedTcins !== '') {
    return boostedTcins.includes(tcinValue)
  } else return false
}

export const updateBoostedTcins = (boostedTcins, tcin, priority) => {
  var boostedTcinArray = !boostedTcins ? [] : boostedTcins.split(',')

  if (priority === 0) {
    const index = boostedTcinArray.indexOf(tcin)
    boostedTcinArray.splice(index, 1)
  } else if (priority === 1) {
    boostedTcinArray.push(tcin)
  }

  return boostedTcinArray.join()
}

// some older offers return dateTime this ensure that we only set the media unit to yyyy-mm-dd regardless of the offer date format
export const returnDateNoTime = (date) => {
  let newDate = new Date(date)
  return newDate.toISOString().split('T')[0]
}

export const adPatchCheck = (offers) => {
  let hasPatches = false
  offers.forEach((offer) => {
    if (offer.ad_patches?.length > 0) {
      hasPatches = true
    }
  })
  return hasPatches
}

export const validateDestinationUrl = (destinationUrl, circleOffer = false) => {
  if (
    !destinationUrl ||
    destinationUrl
      .toString()
      .startsWith(circleOffer ? TARGET_CIRCLE_DOMAIN : TARGET_WEB_DOMAIN)
  ) {
    return true
  } else {
    return false
  }
}
