// For each story, we will now have cuts organized as an array of arrays.
// each array will have the leader as index 0, and each follower will be appended onto the array.

export const MapFollowersToLeaders = (leaders, followers) =>
  leaders.map((leader) => [
    leader,
    ...followers.filter((follower) => follower.artPatchLeaderId === leader.id),
  ])

export function FlatmapArtPatches(leaders, followers) {
  const leadersAndFollowers = []

  leaders.forEach((leader) => {
    leadersAndFollowers.push(leader)

    followers
      .filter((follower) => follower.artPatchLeaderId === leader.id)
      .forEach((follower) =>
        leadersAndFollowers.push({
          artPatchLeaderId: leader.id,
          id: follower.id,
          lastUpdatedTimestamp: follower.lastUpdatedTimestamp,
          priority: leader.priority,
        })
      )
  })
  return leadersAndFollowers
}
