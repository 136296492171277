import {
  Button,
  Form,
  Grid,
  GridItem,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

const AddRemovePages = (props) => {
  const { show, setShowModal, weeklyAd, putWad } = props
  const wipeState = () => {
    setShowModal(false)
  }

  return weeklyAd ? (
    <Formik
      enableReinitialize
      initialValues={{
        add_digitalPageCount: weeklyAd.digitalPageCount,
        add_insertPageCount: weeklyAd.insertPageCount,
        add_printedPageCount: weeklyAd.printedPageCount,
        add_wrapPageCount: weeklyAd.wrapPageCount,
      }}
      onSubmit={(values, funcs) => {
        putWad({
          contactId: weeklyAd.contact.id,
          digitalPageCount: values.add_digitalPageCount,
          id: weeklyAd.id,
          insertPageCount: values.add_insertPageCount,
          lastUpdatedTimestamp: weeklyAd.lastUpdatedTimestamp,
          printedPageCount: values.add_printedPageCount,
          wrapPageCount: values.add_wrapPageCount,
        })
        wipeState()
        funcs.resetForm()
      }}
    >
      {({ values, handleSubmit, handleReset, setFieldValue }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={show}
            headingText="Add/Remove Pages for Weekly Ad"
            onRefuse={() => {
              handleReset()
              setShowModal(false)
            }}
            onApprovalModal={() => setShowModal(false)}
          >
            <div className="hc-pa-normal">
              <Grid.Container spacing="dense">
                <GridItem xs={6}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setFieldValue(
                        'add_printedPageCount',
                        values.add_printedPageCount + 1
                      )
                      handleSubmit()
                    }}
                  >
                    add printed page
                  </Button>
                </GridItem>
                <GridItem xs={6}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setFieldValue(
                        'add_digitalPageCount',
                        values.add_digitalPageCount + 1
                      )
                      handleSubmit()
                    }}
                  >
                    add digital page
                  </Button>
                </GridItem>
                <Grid.Item md={6} xs={12}>
                  <Form.Field
                    type="select"
                    id="add_wrapPageCount"
                    label="Wrap"
                    errorText="Please indicate whether this has a wrap."
                    value={values.add_wrapPageCount}
                    options={[
                      { label: 'Yes (4 pg)', value: 4 },
                      { label: 'Yes (8 pg)', value: 8 },
                      { label: 'No', value: 0 },
                    ]}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
                <Grid.Item md={6} xs={12}>
                  <Form.Field
                    type="select"
                    id="add_insertPageCount"
                    label="Insert (+4)"
                    errorText="Please state whether this has an insert."
                    value={values.add_insertPageCount}
                    options={[
                      { label: 'Yes', value: 4 },
                      { label: 'No', value: 0 },
                    ]}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                canDelete={false}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default AddRemovePages
