import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import storyMapper from '../mappers/storyMapper'
import ReplaceFirst from '../util/ReplaceFirst'

const usePutMoveCut = (setLoading, setWeeklyAd) => {
  const config = appConfig()
  const url = useRef(
    config.backend.root + config.backend.story + config.backend.moveCut
  )
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const moveCut = async () => {
      mounted && setLoading(true)
      try {
        const id = request.id
        const response = await axios.put(url.current + id, request.body)
        const storiesChanged = response.data.map(storyMapper)
        mounted &&
          setWeeklyAd((w) => {
            storiesChanged.forEach((story) => {
              ReplaceFirst(
                w.pages.find((page) => page.id === story.pageId).stories,
                story.id,
                story
              )
            })
            return w
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      moveCut()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd])

  return setRequest
}
export default usePutMoveCut
