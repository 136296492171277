import {
  Grid,
  TimelineItem,
  Table,
  Input,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import {
  faCircleCheck,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState, useEffect, useMemo } from 'react'

import InlineEditInput from '../../src/planning/components/InlineEditInput'
import ChangeMgmtApi from '../api/creative-hub/ChangeMgmtApi'
import { CanEditContext } from '../context/Context'
import {
  getSelectedChannelName,
  formatDcpi,
  formatMarkets,
} from '../creativeHubGlobal/util/ChangeSummaryUtil'
import {
  isChangeSummaryFirstPage,
  isChangeSummaryLastPage,
  sortNotesByCreatedTime,
} from '../creativeHubGlobal/util/ChangeSummaryUtil'
import { getDivisionName } from '../creativeHubGlobal/util/MerchandiseUtil'
import { getPyramidFullName } from '../planning/util/ProjectDetailsUtil'
import { Email } from '../util/AuthUtils'
import { formatToDateTime } from '../util/MomentUtil'

const CustomTimelineChangeHistory = (props) => {
  const changeMgmtApi = useMemo(() => new ChangeMgmtApi(), [])

  const [setOfferAuditData] = useState()
  const [setOnFirstPage] = useState(true)
  const [setOnLastPage] = useState(false)
  const [setPageNumber] = useState(1)
  const [refreshData, setRefreshData] = useState(true)
  const { canEdit } = useContext(CanEditContext)

  const {
    channels = [],
    divisions = {},
    history: hist,
    uniqueKey,
    makeChangeSummaryPayload,
    changePage,
    curProjectIds = {},
    query,
    pageNumber,
  } = props
  const [history, setHistory] = useState(hist)
  const [notes, setNotes] = useState(
    sortNotesByCreatedTime(history?.fields_changed[0]?.notes)
  )

  const currentUserEmail = Email()
  const [editNotes, setEditNotes] = useState('')
  const [editIndex, setEditIndex] = useState(null)
  const [editRowIndex, setEditRowIndex] = useState(null)

  const buildPayload = (fieldName, updatedNotes) => ({
    change_time: history?.date_changed,
    field_notes: {
      [fieldName]: updatedNotes,
    },
    offer_id: history?.offer_id,
    priority: history?.priority,
    project_id: history?.project_id,
    pyramid_id: history?.pyramid_id,
    review: {
      status: history?.review_status?.status,
    },
    story_id: history?.story_id,
    umbrella_id: history?.umbrella_id,
  })

  const handleDelete = async (fieldName, index) => {
    const updatedNotes = [...notes].filter((_, i) => i !== index)
    const payload = buildPayload(fieldName, updatedNotes)

    const response = await changeMgmtApi.updateNotes(payload)
    setHistory(response.data)
    setNotes(sortNotesByCreatedTime(updatedNotes))
  }

  const handleSaveEdit = async (fieldName) => {
    if (editIndex !== null) {
      const updatedNotes = [...notes]
      updatedNotes[editIndex].value = editNotes
      const payload = buildPayload(fieldName, updatedNotes)

      const response = await changeMgmtApi.updateNotes(payload)
      setHistory(response.data)
      setNotes(sortNotesByCreatedTime(updatedNotes))
      setEditIndex(null)
      setEditNotes('')
    }
  }

  const handleEdit = (index, rowIndex) => {
    setEditIndex(index)
    setEditRowIndex(rowIndex)
  }

  const handleReviewStatus = async (history) => {
    const payload = {
      change_time: history?.date_changed,
      offer_id: history?.offer_id,
      priority: history?.priority,
      project_id: history?.project_id,
      pyramid_id: history?.pyramid_id,
      review: {
        status: !history?.review_status.status,
      },
      story_id: history?.story_id,
      umbrella_id: history?.umbrella_id,
    }
    await changeMgmtApi.updateNotes(payload).then((response) => {
      setHistory(response.data)
    })
    setRefreshData(true)
  }
  useEffect(() => {
    if (!refreshData) {
      const payload = makeChangeSummaryPayload({
        changePage,
        channels,
        curProjectIds,
        pageNumber,
        query,
      })
      changeMgmtApi.getChangeSummary(payload).then((res) => {
        setOfferAuditData(res.data)
        setOnFirstPage(isChangeSummaryFirstPage(res.page_num))
        setPageNumber(res.page_num)
        setOnLastPage(
          isChangeSummaryLastPage({
            pageNum: res.page_num,
            totalRecords: res.total,
          })
        )
        setHistory(res)
      })
      setRefreshData(true)
    }
  }, [
    refreshData,
    changeMgmtApi,
    setOfferAuditData,
    setOnFirstPage,
    setOnLastPage,
    setPageNumber,
    changePage,
    channels,
    curProjectIds,
    makeChangeSummaryPayload,
    pageNumber,
    query,
    history,
  ])

  return (
    <TimelineItem>
      <div
        className="timeline-item"
        key={`${history?.date_changed}-${history?.offer_id}-${history?.fields_changed[0]?.field}`}
      >
        <h3
          className="timeline-item--date"
          style={{
            backgroundColor: history?.offer_id === -1 ? '#ffeef9' : null,
          }}
        >
          {history?.offer_id === -1 ? (
            <span> PLACEHOLDER OFFER CHANGE:</span>
          ) : (
            <>
              {history?.origin === 'Ivy' ? (
                <span>OFFER REVISED IN IVY:</span>
              ) : (
                <span>CREATIVEHUB REVISED:</span>
              )}
            </>
          )}
          {' ' + formatToDateTime(hist.date_changed) + ' '}
          <span>CHANGED BY: {' ' + hist.changed_by}</span>
        </h3>
        <Grid.Container xs={12}>
          <Grid.Item xs={5} className="timeline-item--content">
            <h5 className="timeline-item--title">
              PROJECT:{' '}
              <span className="timeline-item--value">
                {history?.project_desc}
              </span>{' '}
              <br />
              PYRAMID:{' '}
              <span className="timeline-item--value">
                {getPyramidFullName(history?.pyramid_name)}
              </span>
              <br />
              PRIORITY:{' '}
              <span className="timeline-item--value">
                {history?.priority}
              </span>{' '}
              <br />
              DIVISION:{' '}
              <span className="timeline-item--value">
                {getDivisionName(divisions, history?.division)}
              </span>{' '}
              <br />
              OFFER:{' '}
              <span
                className="timeline-item--value"
                dangerouslySetInnerHTML={{
                  __html: history.offer_name,
                }}
              />
              <br />
            </h5>
          </Grid.Item>
          <Grid.Item xs={5} className="timeline-item--content">
            <h5 className="timeline-item--title">
              IVY OFFER ID:{' '}
              <span className="timeline-item--value">{history?.offer_id}</span>{' '}
              <br />
              ASSOC. BUYER:{' '}
              <span className="timeline-item--value">
                {history.associated_buyer}
              </span>{' '}
              <br />
              MKTG STORY:{' '}
              <span className="timeline-item--value">
                {history.story_name}
              </span>{' '}
              <br />
              UMBRELLA:{' '}
              <span className="timeline-item--value">
                {history.umbrella_name}
              </span>{' '}
              <br />
              MKTG CHANNELS:
              {history?.marketing_channels?.map((channelId, i) => (
                <span
                  className="timeline-item--value"
                  key={`market-channel-${i}`}
                >
                  {getSelectedChannelName(channels, channelId) + '; '}
                </span>
              ))}{' '}
              <br />
            </h5>
          </Grid.Item>
          <Grid.Item xs={2}>
            <h5 className="timeline-item--title">Reviewed: </h5>
            <span className="reviewed-checkbox">
              <Input.Checkbox
                checked={history.review_status?.status}
                disabled={!canEdit}
                onClick={() => {
                  handleReviewStatus(history)
                }}
                id={`review-checkbox-${history?.offer_id}-${uniqueKey}`}
                key={`review-checkbox-${history?.offer_id}-${uniqueKey}`}
              />
            </span>{' '}
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Item>
            <h5 className="timeline-item--title">
              {history?.media_units?.length < 1 ? (
                <>
                  MEDIA UNIT NAME:{' '}
                  <span className="timeline-item--value">N/A</span>
                </>
              ) : (
                history?.media_units?.map((item) => (
                  <>
                    MEDIA UNIT NAME:{' '}
                    <span className="timeline-item--value">{item?.name}</span>
                    <br />
                  </>
                ))
              )}{' '}
            </h5>
          </Grid.Item>
          <Grid.Item>
            <h5 className="timeline-item--title">
              {history?.media_units?.length < 1 ? (
                <>
                  CATEGORIES: <span className="timeline-item--value">N/A</span>
                </>
              ) : (
                history?.media_units?.map((item) => (
                  <>
                    CATEGORIES:{' '}
                    {item?.categories?.map((category, i) => (
                      <span
                        className="timeline-item--value"
                        key={`category-${i}`}
                      >
                        {category}
                      </span>
                    ))}
                    <br />
                  </>
                ))
              )}{' '}
              <br />
            </h5>
          </Grid.Item>
        </Grid.Container>
        <Table alternateRowColor={false}>
          <Table.Head>
            {hist?.fields_changed?.length > 0 ? (
              <Table.Row
                key={`${history?.date_changed}-${history?.offer_id}-${uniqueKey}`}
              >
                <Table.Header key={`field-header-offer-${uniqueKey}`} xs={2}>
                  <strong>Field</strong>
                </Table.Header>
                <Table.Header
                  key={`old-value-header-offer-${uniqueKey}`}
                  xs={3}
                >
                  <strong>Old Value</strong>
                </Table.Header>
                <Table.Header
                  key={`new-value-header-offer-${uniqueKey}`}
                  xs={3}
                >
                  <strong>New Value</strong>
                </Table.Header>
                <Table.Header key={`notes-header-offer-${uniqueKey}`} xs={3}>
                  <strong>Creative Notes</strong>
                </Table.Header>
              </Table.Row>
            ) : null}
          </Table.Head>
          <Table.Body className="POP-table">
            {history?.fields_changed
              ?.sort((a, b) => a?.field.localeCompare(b?.field))
              .map((row, rowIndex) => (
                <Table.Row
                  key={`${rowIndex}-${history?.date_changed}-${uniqueKey}`}
                  align="center"
                  clickable={false}
                >
                  <Table.Data xs={2} key={rowIndex + 'offer-field'}>
                    {row?.field}
                  </Table.Data>
                  <Table.Data xs={3} key={rowIndex + 'ofv'}>
                    {row?.field === 'Division' &&
                      getDivisionName(divisions, row?.old_value)}
                    {row?.field === 'Offer Promo $ Market Patches' && (
                      <ul>
                        {formatMarkets(row?.old_value).map((markets, index) => (
                          <li
                            dangerouslySetInnerHTML={{
                              __html: `${markets.replace(/:|,/g, ' ')}`,
                            }}
                            key={`market-${index}`}
                          ></li>
                        ))}
                      </ul>
                    )}
                    {row?.field === 'Pictured Item' && !row?.old_value
                      ? formatDcpi(row?.old_value)
                      : ''}
                    {row?.field !== 'Offer Promo $ Market Patches' &&
                    row?.field !== 'Division' &&
                    row?.field !== 'Pictured Item'
                      ? row?.old_value
                      : ''}
                  </Table.Data>
                  <Table.Data xs={3} key={rowIndex + 'nfv'}>
                    {row?.field === 'Division' &&
                      getDivisionName(divisions, row?.new_value)}
                    {row?.field === 'Offer Promo $ Market Patches' && (
                      <ul>
                        {formatMarkets(row?.new_value, row?.old_value).map(
                          (markets, index) => (
                            <li
                              dangerouslySetInnerHTML={{
                                __html: `${markets.replace(/:|,/g, ' ')}`,
                              }}
                              key={`offer-market-${index}`}
                            ></li>
                          )
                        )}
                      </ul>
                    )}
                    {row?.field === 'Pictured Item' && !row?.new_value
                      ? formatDcpi(row?.new_value)
                      : ''}

                    {row?.field !== 'Offer Promo $ Market Patches' &&
                    row?.field !== 'Division' &&
                    row?.field !== 'Pictured Item'
                      ? row?.new_value
                      : ''}
                  </Table.Data>
                  <Table.Data xs={3} key={rowIndex + 'notes'}>
                    <div className="main-container">
                      <ul style={{ padding: '0' }}>
                        {sortNotesByCreatedTime(row?.notes).map(
                          (note, index) => (
                            <li
                              key={`offer-notes-${history?.offer_id}-${index}`}
                            >
                              {index === editIndex &&
                              rowIndex === editRowIndex ? (
                                <div>
                                  <input
                                    className="edit-textbox"
                                    type="text"
                                    value={editNotes}
                                    onChange={(e) =>
                                      setEditNotes(e.target.value)
                                    }
                                  />
                                  <Button
                                    className="C-EditButton"
                                    onClick={() =>
                                      handleSaveEdit(row?.field, index)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <div className="notes_info">
                                    <div>
                                      <span
                                        style={{
                                          color: 'black',
                                          fontSize: '14px',
                                        }}
                                      >
                                        {note.value}
                                      </span>{' '}
                                      <br />
                                      {`${
                                        note.written_by.split('@', 1)[0]
                                      } ${formatToDateTime(note.written_at)} `}
                                    </div>
                                    {note.written_by === currentUserEmail && (
                                      <div className="icons">
                                        <Button
                                          className="C-MenuButton"
                                          style={{ marginRight: '4px' }}
                                          onClick={() => {
                                            setEditNotes(note.value)
                                            handleEdit(index, rowIndex)
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            color="black"
                                            icon={faPencilAlt}
                                          />
                                        </Button>
                                        <Button
                                          className="C-MenuButton"
                                          onClick={() =>
                                            handleDelete(row?.field, index)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            color="black"
                                            icon={faTrashAlt}
                                          />
                                        </Button>
                                      </div>
                                    )}
                                    <br />
                                  </div>
                                </div>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <InlineEditInput
                      fieldName={row?.field}
                      notes={sortNotesByCreatedTime(row?.notes)}
                      setNotes={setNotes}
                      setHistory={setHistory}
                      data={history}
                      id="text-box"
                      field="creative_notes"
                      placeholder="Add a note"
                      type={undefined}
                      isDeactivated={!canEdit}
                    />
                  </Table.Data>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <Table alternateRowColor={false}>
          {history?.items_changed?.map((row, index) => (
            <Table.Head key={`table-head-${index}-${row.dpci}-${uniqueKey}`}>
              <Table.Row
                key={`item-${index}-${row.dpci}-${uniqueKey}`}
                align="center"
                clickable={false}
              >
                <h5 className="timeline-item--title">
                  DPCI:{' '}
                  <span className="timeline-item--value">{row.dpci};</span>
                  TCIN:{' '}
                  <span className="timeline-item--value">{row.tcin};</span>
                  DESCRIPTION:{' '}
                  <span
                    className="timeline-item--value"
                    dangerouslySetInnerHTML={{
                      __html: row.description,
                    }}
                  />
                </h5>
              </Table.Row>
              <Table.Row key={`Field-${index}-${row.dpci}-${uniqueKey}`}>
                <Table.Header key={`field-header-item-${uniqueKey}`} xs={2}>
                  <strong>Field</strong>
                </Table.Header>
                <Table.Header key={`old-value-header-item-${uniqueKey}`} xs={3}>
                  <strong>Old Value</strong>
                </Table.Header>
                <Table.Header key={`new-value-header-item-${uniqueKey}`} xs={3}>
                  <strong>New Value</strong>
                </Table.Header>
                <Table.Header key={`notes-header-item-${uniqueKey}`} xs={3}>
                  <strong>Creative Notes</strong>
                </Table.Header>
              </Table.Row>
              {row.fields_changed.map((item, index) => (
                <Table.Row
                  className="timeline-item--value"
                  key={`${index}-${row.dpci}-${item?.field}-${uniqueKey}`}
                >
                  <Table.Data xs={2} key={index + 'item-field'}>
                    {item?.field}
                  </Table.Data>
                  <Table.Data xs={3} key={index + 'oiv'}>
                    {item?.old_value}
                  </Table.Data>
                  <Table.Data xs={3} key={index + 'niv'}>
                    {item?.new_value}
                  </Table.Data>
                  <Table.Data xs={3} key={index + 'notes'}>
                    <ul>
                      {sortNotesByCreatedTime(item?.notes).map(
                        (note, index) => (
                          <li
                            key={`item-notes-${item.id}-${index}-${uniqueKey}`}
                          >
                            {note.value}
                            <div className="notes_info">
                              {`${
                                note.written_by.split('@', 1)[0]
                              } ${formatToDateTime(note.written_at)} `}
                            </div>
                            <br />
                          </li>
                        )
                      )}
                    </ul>
                    <InlineEditInput
                      fieldName={item?.field}
                      notes={sortNotesByCreatedTime(item?.notes)}
                      setHistory={setHistory}
                      setNotes={setNotes}
                      data={history}
                      id="text-box"
                      field="creative_notes"
                      placeholder="Add a note"
                      type={undefined}
                      isDeactivated={!canEdit}
                    />
                  </Table.Data>
                </Table.Row>
              ))}
            </Table.Head>
          ))}
        </Table>
      </div>
    </TimelineItem>
  )
}

export default CustomTimelineChangeHistory
