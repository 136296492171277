import formatDecimal from '../util/formatDecimal'

const dummyMapper = (dummy) => ({
  cutId: dummy.cutId,
  deleted: dummy.deleted,
  description: dummy.description,
  id: dummy.id,
  keyNotes: dummy.keyNotes,
  lastUpdatedTimestamp: dummy.lastUpdatedTimestamp,
  regPriceCallout: dummy.regPriceCallout,
  regUnitRetail: formatDecimal(dummy.regUnitRetail),
})
export default dummyMapper
