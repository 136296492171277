/* eslint sort-keys: 0 */

import { Chip, Table, Spinner } from '@enterprise-ui/canvas-ui-react'

import {
  filterDisplayPriorities,
  sortPyramids,
} from '../util/CreativeHubGlobalUtil'

import '../scss/ProjectListTable.scss'

const OutcomePriorityTable = ({
  pyramids,
  selectedMarketingPyramidFilter,
  selectedMarketingPriorityFilter,
  handleOutcomePrioritySelection,
  selectedOutcomePriorityId,
  pyramidsApiLoading,
}) => {
  const sortedPyramids = sortPyramids(pyramids)
  const displayPriorities = filterDisplayPriorities(
    sortedPyramids,
    selectedMarketingPriorityFilter,
    selectedMarketingPyramidFilter
  )

  return (
    <Table className="mktgTableExecution" data-testid="mktgTable">
      {pyramidsApiLoading && selectedMarketingPyramidFilter !== '' ? (
        <div className="pyramidSpinner">
          <Spinner size="expanded" />
        </div>
      ) : (
        <>
          <Table.Head>
            <Table.Row key="mktgHeader">
              <Table.Header xs={4} key="mktgPriority">
                Mktg Priority
              </Table.Header>
              <Table.Header xs={5} key="MktgDescription">
                Mktg Description
              </Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {displayPriorities?.map((row, index) => (
              <Table.Row
                align="center"
                className={
                  selectedOutcomePriorityId === row.pyramid_id
                    ? 'selectedRow'
                    : 'tableRow'
                }
                key={`${row.pyramid_id}${row.priority_order}${index}`}
                onClick={() => {
                  handleOutcomePrioritySelection(row.pyramid_id, true)
                }}
                clickable
                data-testid={`selectRow${row.pyramid_id}`}
              >
                <Table.Data
                  className="projectTD"
                  data-cy={`projectTD ${index}`}
                  xs={4}
                  key={row.pyramid_id + '-pyramidId'}
                >
                  {row?.pyramid_type === 'ent'
                    ? `Enterprise Guest Outcome ${row.priority_order}: `
                    : `Mktg Priority ${
                        row.priority_order ? row.priority_order : index
                      }: `}
                </Table.Data>
                {row?.color && selectedMarketingPriorityFilter !== 'ent' ? (
                  <Table.Data className="chipContainer">
                    <Chip
                      size="dense"
                      style={{
                        backgroundColor: row?.color,
                        color: '#fff',
                      }}
                    >
                      {row.pyramid_type === 'ent'
                        ? 'EP'
                        : row.pyramid_type?.toUpperCase()}
                    </Chip>
                  </Table.Data>
                ) : (
                  <Table.Data className="chipContainer"></Table.Data>
                )}
                <Table.Data
                  className="projectTD"
                  xs={5}
                  key={row.pyramid_id + '-pyramidDescription'}
                >
                  {row?.pyramid_type === 'ent'
                    ? row.pyramid_name + ': ' + row.guest_outcomes
                    : row.pyramid_name}
                </Table.Data>
              </Table.Row>
            ))}
          </Table.Body>
        </>
      )}
    </Table>
  )
}
export default OutcomePriorityTable
