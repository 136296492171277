import { Button, Chip, Grid, Tabs, Input } from '@enterprise-ui/canvas-ui-react'
import {
  faUmbrella,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import ChannelApi from '../../api/creative-hub/ChannelApi'
import ProjectApi from '../../api/creative-hub/ProjectApi'
import PyramidApi from '../../api/creative-hub/PyramidApi'
import UmbrellaApi from '../../api/creative-hub/UmbrellaApi'
import CustomAutoComplete from '../../components/CustomAutocomplete'
import {
  BreadcrumbContext,
  UmbrellaContext,
  EffectiveRoleContext,
  CanEditContext,
  EnterpriseLinkedUmbrellasContext,
} from '../../context/Context'
import { isInCanEditPlanningViewGroup } from '../../util/CheckRole'
import { formatDateForApi } from '../util/DateUtil'
import { getChipValues, pyramid_options } from '../util/ProjectDetailsUtil'
import { pyramidAbbreviations } from '../util/ProjectDetailsUtil'
import { umbrellaSearch, standardUmbrellas } from '../util/UmbrellaUtil'

import PlanningViewMessage from './PlanningViewMessage'
import PyramidBucket from './PyramidBucket'
import StickyBarProject from './StickyBarProject'
import Umbrella from './Umbrella'

import '../scss/ProjectDetails.scss'

const ProjectDetails = ({ session = {} }) => {
  const channelApi = new ChannelApi()
  const umbrellaApi = new UmbrellaApi()
  const [selectedProject, setSelectedProject] = useState(useLocation().state)
  const [selectedProjectId] = useState(useParams().projectId)
  const [pyramidFilter, setPyramidFilter] = useState([])
  const [pyramidLists, setPyramidLists] = useState([])
  const [unlinkedUmbrellas, setUnlinkedUmbrellas] = useState([])
  const [linkedUmbrellas, setLinkedUmbrellas] = useState([])
  const [channels, setChannels] = useState([])
  const [selectedMarketingChannelFilter, setMarketingChannelFilter] = useState(
    []
  )
  const [isUmbrellaExpanded, setIsUmbrellaExpanded] = useState(false)
  const [search, setSearch] = useState('')
  const [umbrellaPyramidSearchOptions, setUmbrellaPyramidSearchOptions] =
    useState([])
  const [refreshData, setRefreshData] = useState(true)
  const [refreshPriorityData, setRefreshPyramidData] = useState(true)
  const { breadcrumbContext, setBreadcrumbContext } =
    useContext(BreadcrumbContext)
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const [canEdit, setCanEdit] = useState(false)
  const [channelsDialogIsVisible, setChannelsDialogIsVisible] = useState(false)
  const [umbrellaIsDropped, setUmbrellaIsDropped] = useState(false)
  const [channelsPDFPreviewIsVisible, setChannelsPDFPreviewIsVisible] =
    useState(false)
  const [selectedChannelBucket, setSelectedChannelBucket] = useState(null)
  const [currentActiveTab, setCurrentActiveTab] = useState('PYRAMID')
  const [ascendingUmbrella, setAscendingUmbrella] = useState(false)
  const [sortHasBeenClicked, setSortHasBeenClicked] = useState(false)
  const [ascendingDateClicked, setAscendingDateClicked] = useState(false)
  const [sortDateClicked, setSortDateClicked] = useState(false)
  const [standardUmbrellasHidden, setStandardUmbrellasHidden] = useState(true)
  const [selectedUmbrellas, setSelectedUmbrellas] = useState([])
  const [umbrellaShowOptions, setUmbrellaShowOptions] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [showUmbrellas, setShowUmbrellas] = useState(true)
  const handleToggleChange = () => {
    setShowUmbrellas((current) => !current)
  }
  const filteredUmbrellas = umbrellaSearch(
    unlinkedUmbrellas,
    search,
    umbrellaPyramidSearchOptions,
    sortHasBeenClicked,
    ascendingUmbrella,
    sortDateClicked,
    ascendingDateClicked,
    umbrellaIsDropped,
    standardUmbrellasHidden
  )
  const handleUpdateUmbrellaSearchOptions = (_, values) => {
    setUmbrellaPyramidSearchOptions(values.map((el) => el.value))
    setUmbrellaShowOptions([...values])
  }
  const standardUmbrellaCount = standardUmbrellas(unlinkedUmbrellas)
  const {
    end_date: endDate = '',
    project_description: projectDescription = '',
    project_id: projectId = '',
    project_name: projectName = '',
    start_date: startDate = '',
    kickoff_date: kickoffDate = '',
    deadline_date: deadlineDate = '',
  } = selectedProject || {}
  useEffect(() => {
    setCanEdit(
      effectiveRole?.some((role) => isInCanEditPlanningViewGroup(role))
    )
  }, [effectiveRole, setCanEdit])
  const dragOver = (event) => {
    event.dataTransfer.types.includes('allow_drop_in_unlinked') &&
      event.preventDefault()
  }
  const dragEnter = (event) => {
    event.dataTransfer.types.includes('allow_drop_in_unlinked') &&
      event.preventDefault()
  }
  const drop = (event) => {
    const umbrella = JSON.parse(event.dataTransfer.getData('drag-umbrella'))
    const storyId = event.dataTransfer.getData('story-id')
    setUnlinkedUmbrellas((unlinkedUmbrellas) => [
      ...unlinkedUmbrellas,
      umbrella,
    ])
    if (umbrella) {
      umbrellaApi
        .unlinkUmbrella(umbrella.planning_umbrella_id, storyId)
        .then((response) => {
          response && setRefreshData(true)
        })
    }
  }
  const sortUmbrellas = () => {
    setSortDateClicked(false)
    setAscendingUmbrella(!ascendingUmbrella)
    if (sortHasBeenClicked) {
      return
    } else {
      setSortHasBeenClicked(true)
    }
  }
  const sortDateUmbrellas = () => {
    setSortHasBeenClicked(false)
    setAscendingDateClicked((val) => !val)
    return sortDateClicked ? null : setSortDateClicked(true)
  }
  const handleAddChannelToUmbrella = ({
    channel_id,
    project_id,
    umbrella_id,
  }) => {
    channelApi.addChannelToUmbrella({
      channel_id,
      project_id,
      umbrella_id,
    })
  }
  const handleRemoveChannelFromUmbrella = ({
    channel_id,
    project_id,
    umbrella_id,
  }) => {
    channelApi.removeChannelFromUmbrella({
      channel_id,
      project_id,
      umbrella_id,
    })
  }
  const handleAddAllChannelsToUmbrella = ({
    channel_id,
    project_id,
    pyramid_type,
    select_all,
  }) => {
    channelApi.addAllChannelsToUmbrella({
      channel_id,
      project_id,
      pyramid_type,
      select_all,
    })
  }
  const handleRemoveAllChannelsFromUmbrella = ({
    channel_id,
    project_id,
    pyramid_type,
    select_all,
  }) => {
    channelApi.removeAllChannelsFromUmbrella({
      channel_id,
      project_id,
      pyramid_type,
      select_all,
    })
  }
  const handleToggleChannelDialog = async () =>
    setChannelsDialogIsVisible((prev) => !prev)
  const handleTogglePDFPreview = async () =>
    setChannelsPDFPreviewIsVisible((prev) => !prev)
  const setActiveUmbrellaTab = (event, { name }) => {
    setCurrentActiveTab(name)
    umbrellaApi
      .getIvyUmbrellasByTier(
        projectId,
        formatDateForApi(startDate),
        formatDateForApi(endDate),
        name,
        'UNLINKED'
      )
      .then((response) => setUnlinkedUmbrellas(response))
  }
  useEffect(() => {
    if (breadcrumbContext?.row?.project_id !== selectedProject?.project_id) {
      setBreadcrumbContext({ row: selectedProject, setBreadcrumbContext })
    } else if (selectedProjectId !== selectedProject?.project_id) {
      const projectApi = new ProjectApi()
      projectApi.fetchProjectDetails(selectedProjectId).then((data) => {
        setSelectedProject(data)
        setBreadcrumbContext({ row: data, setBreadcrumbContext })
        setRefreshPyramidData(true)
        setRefreshData(true)
      })
    }
  }, [
    breadcrumbContext,
    setBreadcrumbContext,
    selectedProject,
    selectedProjectId,
  ])
  useEffect(() => {
    if (projectId && (refreshData || refreshPriorityData)) {
      setRefreshPyramidData(false)
      const pyramidsApi = new PyramidApi()
      const umbrellaApi = new UmbrellaApi()
      const channelApi = new ChannelApi()
      const channelIds = []
      if (selectedMarketingChannelFilter.length) {
        selectedMarketingChannelFilter.forEach((channelFilter) => {
          channelIds.push(channelFilter?.id)
        })
      }
      if (channelIds.length) {
        pyramidsApi
          .getPyramidsByFilter(projectId, '', '', channelIds)
          .then((response) => {
            setPyramidLists(response)
          })
      } else {
        pyramidsApi.getPyramids(projectId).then((response) => {
          setPyramidLists(response)
        })
      }
      if (refreshData) {
        setRefreshData(false)
        umbrellaApi
          .getIvyUmbrellasByTier(
            projectId,
            formatDateForApi(startDate),
            formatDateForApi(endDate),
            currentActiveTab,
            'UNLINKED'
          )
          .then((response) => {
            setUnlinkedUmbrellas(response)
          })
        umbrellaApi
          .getEnterpriseUmbrellas(projectId, startDate, endDate)
          .then((response) => {
            setLinkedUmbrellas(response)
          })
        channelApi.getChannels().then((response) => setChannels(response))
      }
    }
  }, [
    projectId,
    selectedProject,
    refreshData,
    refreshPriorityData,
    currentActiveTab,
    setLinkedUmbrellas,
    linkedUmbrellas,
    startDate,
    endDate,
    selectedMarketingChannelFilter,
    kickoffDate,
    deadlineDate,
  ])
  useEffect(() => {
    setRefreshPyramidData(true)
    setRefreshData(true)
  }, [selectedMarketingChannelFilter])
  return (
    <div data-testid="projectDetails">
      <EnterpriseLinkedUmbrellasContext.Provider value={{ linkedUmbrellas }}>
        <PlanningViewMessage />
        <CanEditContext.Provider value={{ canEdit }}>
          <UmbrellaContext.Provider
            value={{
              isUmbrellaExpanded,
              setIsUmbrellaExpanded,
            }}
          >
            <div className="projectDetailsHeaderContainer">
              <div className="projectDetailsHeader">
                <h2>
                  {projectDescription}
                  {'  '}
                  {projectName}
                </h2>
              </div>
              <div className="projectDetailsHeader">
                <Input.Toggle
                  id="view-ivy-umbrellas-toggle"
                  label="View IVY Planning Umbrellas"
                  checked={showUmbrellas}
                  onChange={handleToggleChange}
                />
                <Button
                  className="dkgrey-btn expand-button"
                  onClick={() => setIsUmbrellaExpanded(!isUmbrellaExpanded)}
                  data-testid="expandUmbrellaButton"
                  style={{ marginLeft: '10px' }}
                >
                  <span style={{ fontWeight: '500' }}>
                    <FontAwesomeIcon icon={faUmbrella} size="lg" />{' '}
                    {isUmbrellaExpanded
                      ? 'Collapse Umbrellas'
                      : 'Expand Umbrellas'}
                  </span>
                </Button>
              </div>
              <StickyBarProject
                setPyramidFilter={setPyramidFilter}
                pyramidFilter={pyramidFilter}
                search={search}
                setSearch={setSearch}
                setUmbrellaPyramidSearchOptions={
                  setUmbrellaPyramidSearchOptions
                }
                selectedMarketingChannelFilter={selectedMarketingChannelFilter}
                setMarketingChannelFilter={setMarketingChannelFilter}
                channels={channels}
                isUmbrellaExpanded={isUmbrellaExpanded}
                setIsUmbrellaExpanded={setIsUmbrellaExpanded}
              />
            </div>
            <div
              className={`projectDetails ${
                showUmbrellas ? 'umbrellas-visible' : ''
              }`}
            >
              <div
                xs={5}
                className="umbrellas"
                style={{ display: showUmbrellas ? 'block' : 'none' }}
              >
                <Grid.Item>
                  <h2>
                    <span>
                      <FontAwesomeIcon color="#000" icon={faUmbrella} />
                      {`  IVY Planning Umbrellas (${filteredUmbrellas.length}) `}
                    </span>
                    <Button
                      xs={2}
                      style={{ marginTop: '7px' }}
                      className="dkgrey-btn"
                      disabled={standardUmbrellaCount.length === 0}
                      onClick={() => {
                        setStandardUmbrellasHidden(!standardUmbrellasHidden)
                      }}
                    >
                      {`${
                        standardUmbrellasHidden ? 'Unhide' : 'Hide'
                      } Standard Umbrellas (${standardUmbrellaCount.length})`}
                    </Button>
                  </h2>
                </Grid.Item>
                <Grid.Item xs={12} id="pyramidFilter" className="searchCon">
                  <CustomAutoComplete
                    placeholder="Search Umbrellas"
                    onUpdate={handleUpdateUmbrellaSearchOptions}
                    options={pyramid_options.filter(
                      (option) => option.value !== 'ent'
                    )}
                    multiselect
                    value={umbrellaShowOptions}
                    onChange={(e) => setSearch(e)}
                    renderChip={(cat, onRequestDelete) => (
                      <Chip
                        size="dense"
                        style={{
                          backgroundColor: cat?.color,
                          border: '0px',
                          color: 'white',
                        }}
                        onRequestDelete={onRequestDelete}
                      >
                        {getChipValues(cat?.value)}
                      </Chip>
                    )}
                    data-testid="umbrellaSearch"
                  />
                </Grid.Item>
                <Grid.Item xs={24} id="pyramidFilter" className="searchCon">
                  <Tabs
                    activeTab={currentActiveTab}
                    onTabSelect={(event, tab) => {
                      event.preventDefault()
                      setActiveUmbrellaTab(event, tab)
                      setSortHasBeenClicked(false)
                      setSortDateClicked(false)
                      setSelectedUmbrellas([])
                    }}
                    className="umbrellaTabs"
                    disableAdaptive={true}
                  >
                    <Tabs.Item
                      name="PYRAMID"
                      className={`border-left ${
                        currentActiveTab === 'PYRAMID'
                          ? 'activeTab'
                          : 'unlinkUmbrellaTab'
                      }`}
                    >
                      For Pyramid Mktg Stories
                    </Tabs.Item>
                    <Tabs.Item
                      name="ENT"
                      className={`border-right ${
                        currentActiveTab === 'ENT'
                          ? 'activeTab'
                          : 'unlinkUmbrellaTab'
                      }`}
                    >
                      For Enterprise Mktg Stories
                    </Tabs.Item>
                  </Tabs>
                </Grid.Item>
                <Grid.Container className="sortbtns">
                  <Button xs={2} className="dkgrey-btn" onClick={sortUmbrellas}>
                    {sortHasBeenClicked ? (
                      <FontAwesomeIcon
                        icon={ascendingUmbrella ? faArrowUp : faArrowDown}
                        size="lg"
                      />
                    ) : null}
                    <span style={{ fontWeight: '500' }}>Sort by Promo $</span>
                  </Button>
                  <Button
                    xs={2}
                    className="dkgrey-btn"
                    onClick={sortDateUmbrellas}
                  >
                    {sortDateClicked ? (
                      <FontAwesomeIcon
                        icon={ascendingDateClicked ? faArrowUp : faArrowDown}
                        size="lg"
                      />
                    ) : null}
                    <span
                      style={{
                        fontWeight: '500',
                        marginLeft: sortDateClicked ? '10px' : '',
                      }}
                    >
                      Sort by Run Date
                    </span>
                  </Button>
                </Grid.Container>
                <div
                  xs={5}
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDrop={drop}
                  className="umbrellaContainer"
                  data-testid="umbrellaContainer"
                >
                  {filteredUmbrellas?.map((umbrella, ind) => (
                    <Umbrella
                      key={`${umbrella.ivy_umbrella_id}-${ind}`}
                      data={umbrella}
                      activeTab={currentActiveTab}
                      index={ind}
                      selectedUmbrellas={selectedUmbrellas}
                      setSelectedUmbrellas={setSelectedUmbrellas}
                      setIsDragging={setIsDragging}
                      isDragging={isDragging}
                      currentlySelected={selectedUmbrellas.some(
                        (u) => u.ivy_umbrella_id === umbrella.ivy_umbrella_id
                      )}
                    />
                  ))}
                </div>
              </div>
              <div className="pyramids">
                <div className="pyramidsContainer">
                  <Grid.Item xs={24}>
                    {pyramidAbbreviations.map((key, index) => {
                      if (selectedMarketingChannelFilter.length) {
                        const containsPyramidName = pyramidLists.find(
                          (pyramidItem) => pyramidItem.pyramid_type === key
                        )
                        if (!containsPyramidName) {
                          return null
                        }
                      }
                      return (
                        <PyramidBucket
                          uniqueId={index}
                          key={index}
                          pyramidList={
                            pyramidLists?.filter(
                              (x) => x.pyramid_type === key
                            ) || []
                          }
                          setUmbrellaIsDropped={setUmbrellaIsDropped}
                          pyramidType={key}
                          projectId={projectId}
                          projectEndDate={endDate}
                          projectStartDate={startDate}
                          setRefreshData={setRefreshData}
                          setRefreshPyramidData={setRefreshPyramidData}
                          isVisible={
                            pyramidFilter.length === 0 ||
                            pyramidFilter.find(
                              (filter) => filter?.value === key
                            )
                          }
                          addChannelToUmbrella={handleAddChannelToUmbrella}
                          removeChannelFromUmbrella={
                            handleRemoveChannelFromUmbrella
                          }
                          addAllChannelsToUmbrella={
                            handleAddAllChannelsToUmbrella
                          }
                          removeAllChannelsFromUmbrella={
                            handleRemoveAllChannelsFromUmbrella
                          }
                          handleToggleChannelDialog={handleToggleChannelDialog}
                          handleTogglePDFPreview={handleTogglePDFPreview}
                          projectTitle={`${projectDescription} ${projectName}`}
                          selectedChannelBucket={selectedChannelBucket}
                          setSelectedChannelBucket={setSelectedChannelBucket}
                          channels={channels}
                          channelsDialogIsVisible={channelsDialogIsVisible}
                          channelsPDFPreviewIsVisible={
                            channelsPDFPreviewIsVisible
                          }
                          channelFilters={selectedMarketingChannelFilter}
                          setSelectedUmbrellas={setSelectedUmbrellas}
                          session={session}
                        />
                      )
                    })}
                  </Grid.Item>
                </div>
              </div>
            </div>
          </UmbrellaContext.Provider>
        </CanEditContext.Provider>
      </EnterpriseLinkedUmbrellasContext.Provider>
    </div>
  )
}
export default ProjectDetails
