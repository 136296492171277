const productMapper = (product) => ({
  additional_product_info: product.additional_product_info,
  age_range: product.age_range,
  art_director_notes: product.art_director_notes,
  art_director_request: product.art_director_request,
  assembly_required: product.assembly_required,
  assorted: product.assorted,
  available_instore: product.available_instore,
  available_online: product.available_online,
  brand: product.brand,
  buyer: product.buyer,
  category: product.category,
  coupon_retail: product.coupon_retail,
  deleted: product.deleted,
  depth: product.depth,
  dpci: product.dpci,
  features: product.features,
  fig_model: product.fig_model,
  final_copy: product.final_copy,
  final_product_name: product.final_product_name,
  gender_age_preference: product.gender_age_preference,
  height: product.height,
  id: product.id,
  image_approved: product.image_approved,
  image_sample_collected: product.image_sample_collected,
  ivy_offer_id: product.ivy_offer_id,
  last_updated_timestamp: product.last_updated_timestamp,
  list_reg_retail: product.list_reg_retail,
  map_floor: product.map_floor,
  mapped_item: product.mapped_item,
  model_needs: product.model_needs,
  name: product.name,
  name_guest_facing: product.name_guest_facing,
  page_id: product.page_id,
  page_ordering: product.page_ordering,
  page_type: product.page_type,
  photo_notes: product.photo_notes,
  product_idx: product.product_idx,
  product_ordering: product.product_ordering || 0,
  promo_retail: product.promo_retail,
  prop_needs: product.prop_needs,
  reason_for_request: product.reason_for_request,
  reference_image: product.reference_image,
  reg_unit_retail: product.reg_unit_retail,
  sample_id: product.sample_id,
  sample_status: product.sample_status,
  sample_submitted: product.sample_submitted,
  sample_turn_in_date: product.sample_turn_in_date,
  section: product.section,
  set_date: product.set_date,
  size_range: product.size_range,
  status: product.status,
  target_exclusive: product.target_exclusive,
  tcin: product.tcin,
  tcin_type: product.tcin_type,
  units: product.units,
  validated_dpci: product.validated_dpci || false,
  vendor_comments: product.vendor_comments,
  vendor_contact_email: product.vendor_contact_email,
  vendor_contact_name: product.vendor_contact_name,
  vendor_funded: product.vendor_funded,
  vendor_id: product.vendor_id,
  vendor_name: product.vendor_name,
  width: product.width,
})
export default productMapper
