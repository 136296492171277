import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { bcmCategoryOptions } from '../util/helpers'

import ContentDetailsHalfRow from './ContentDetailsHalfRow'
import ContentDetailsRow from './ContentDetailsRow'

const MessagingDetails = ({
  setLandingUrl,
  landingUrl,
  categoryInfo,
  setUpdateCategory,
  startDate,
  endDate,
  setStoryId,
  storyId,
  setBcmCategory,
  bcmCategory,
  isBcmDrawerOpen,
  setIsBcmDrawerOpen,
}) => (
  <div
    className="messaging-details-container"
    data-testid="messaging-details-containers"
  >
    <Grid.Container>
      <Grid.Item xs={12}>
        <Grid.Container className="category-container-row">
          <ContentDetailsHalfRow
            value={storyId}
            updateValue={setStoryId}
            onChange={setStoryId}
            title="Story ID"
            disabled={false}
            id={categoryInfo.id + 'story-id'}
            setChangeDetected={setUpdateCategory}
          />
          <Grid.Item className="category-label" xs={2}>
            BCM Category
            <FontAwesomeIcon
              data-testid="bcm-info-btn"
              className="labelledIcon fa-light"
              fontSize={14}
              icon={faCircleInfo}
              onClick={(e) => {
                setIsBcmDrawerOpen(!isBcmDrawerOpen)
              }}
            />
          </Grid.Item>
          <Grid.Item className="category-input" xs={4}>
            <Form.Field
              data-testid="bcm-category-selectbox"
              type="select"
              options={bcmCategoryOptions}
              onUpdate={(id, value) => {
                setBcmCategory(value)
                setUpdateCategory(true)
              }}
              value={bcmCategory}
              id={`bcm-category-selectbox-${categoryInfo?.id}`}
            />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container className="category-container-row">
          <Grid.Item className="category-label" xs={2}>
            Channels
          </Grid.Item>
          <Grid.Item className="checkbox-input" xs={10}>
            <Form.Field
              className="checkbox-media-channels"
              label="Priority Offers"
              type="checkbox"
              checked={true}
              disabled={true}
              id={`category-priority-checkbox-${categoryInfo.id}`}
            />
          </Grid.Item>
        </Grid.Container>
        <ContentDetailsRow
          destinationUrlRow={setLandingUrl}
          value={landingUrl}
          updateValue={setLandingUrl}
          onChange={setLandingUrl}
          title="Intro Dest. URL"
          disabled={false}
          id={categoryInfo.id + 'category-intro-url'}
          setChangeDetected={setUpdateCategory}
        />
        <Grid.Container className="category-container-row">
          <ContentDetailsHalfRow
            value={startDate}
            title="Start Date"
            disabled={true}
            id={categoryInfo.id + 'start-date'}
          />
          <ContentDetailsHalfRow
            value={endDate}
            title="End Date"
            disabled={true}
            id={categoryInfo.id + 'end-date'}
          />
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  </div>
)

export default MessagingDetails
