import {
  Button,
  Card,
  Grid,
  Modal,
  Table,
  Pagination,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faEdit, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ProjectApi from '../../api/creative-hub/ProjectApi'
import {
  BreadcrumbContext,
  CanEditContext,
  EffectiveRoleContext,
} from '../../context/Context'
import {
  isInCanEditPlanningViewGroup,
  isAdmin,
  isAdminOrCreative,
  isMarketing,
} from '../../util/CheckRole'

import ProjectContacts from './ProjectContacts'

import '../scss/ProjectListTable.scss'

const ProjectListTable = (props) => {
  const { projects, setProject, setPageNum, pageNum, projectsData, loading } =
    props
  const location = useLocation()
  const navigate = useNavigate()
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const projectApi = new ProjectApi()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedProject, setSelectedProject] = useState({})
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const [canEdit, setCanEdit] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const [canViewContacts, setCanViewContacts] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    setCanEdit(
      effectiveRole?.some((role) => isInCanEditPlanningViewGroup(role))
    )
    setCanDelete(effectiveRole?.some((role) => isAdmin(role)))
    setCanViewContacts(
      effectiveRole?.some(
        (role) => isAdminOrCreative(role) || isMarketing(role)
      )
    )
  }, [effectiveRole, setCanEdit])

  const openEdit = (project) => {
    navigate(`/createEditProject/${project.project_id}`)
  }

  const openContactsModal = (project) => {
    setIsDrawerOpen(true)
    setSelectedProject(project)
  }

  const deleteProject = async (project) => {
    projectApi.deleteProject(project).finally(() => setProject({}))
  }

  const paginationPageNum = pageNum + 1
  const paginationNext =
    paginationPageNum >= projectsData?.total_pages
      ? null
      : () => setPageNum(paginationPageNum)
  const paginationPrev =
    paginationPageNum === 1 ? null : () => setPageNum(pageNum - 1)
  return (
    <CanEditContext.Provider value={{ canEdit }}>
      {loading ? (
        <ProgressBar indeterminate />
      ) : (
        <Table className="projectTable" data-testid="table">
          <Table.Head className="projectTableHeader">
            <Table.Row className="tableHeaderRow" key="projectHeader">
              <Table.Header className="projectTH" xs={3} key="projectDesc">
                Project Description
              </Table.Header>
              <Table.Header className="projectTH" xs={3} key="projectName">
                Project Name
              </Table.Header>
              <Table.Header className="projectTH" xs={3} key="promoDates">
                Start Date to End Date
              </Table.Header>
              <Table.Header className="projectTH" xs={3} key="actions">
                {projectsData && projectsData?.total_pages > 1 && (
                  <Card
                    elevation={2}
                    corners="top"
                    style={{ backgroundColor: '#f0f0f0' }}
                  >
                    <Pagination
                      currentPage={paginationPageNum}
                      onRequestNext={paginationNext}
                      onRequestPrev={paginationPrev}
                      totalPages={projectsData?.total_pages}
                    />
                  </Card>
                )}
              </Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {projects?.map((row, index) => (
              <Table.Row
                className="tableBodyRow"
                key={row.project_id + `${index}`}
                onClick={() => {
                  setBreadcrumbContext({ row, setBreadcrumbContext })
                  navigate(`${location.pathname}/${row.project_id}`, {
                    state: row,
                  })
                }}
                data-testid={'detailsButton' + row.project_id}
                data-cy={row.project_name}
              >
                <Table.Data
                  className="projectTD"
                  xs={3}
                  key={row.project_id + '-projectDesc'}
                >
                  {row.project_description}
                </Table.Data>
                <Table.Data
                  className="projectTD"
                  xs={3}
                  key={row.project_id + '-projectName'}
                >
                  {row.project_name}
                </Table.Data>
                <Table.Data
                  className="projectTD"
                  xs={3}
                  key={row.project_id + '-promoDates'}
                >
                  <DateFormatter date={row.start_date} format="MM/DD/YYYY" /> to{' '}
                  <DateFormatter date={row.end_date} format="MM/DD/YYYY" />
                </Table.Data>
                <Table.Data xs={2} className="tableIcon">
                  {canEdit && (
                    <div
                      key={row.project_id + '-edit-button'}
                      onClick={(event) => {
                        event.stopPropagation()
                        openEdit(row)
                      }}
                      data-testid={'editButton' + row.project_id}
                      data-cy={'edit-' + row.project_name}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                  )}
                  {canViewContacts && (
                    <div
                      key={row.project_id + '-view-contacts-button'}
                      onClick={(event) => {
                        event.stopPropagation()
                        openContactsModal(row)
                      }}
                      data-testid={'view-contacts-button' + row.project_id}
                      data-cy={'view-contacts-' + row.project_name}
                    >
                      <FontAwesomeIcon icon={faUserGroup} />
                    </div>
                  )}
                  {canDelete ? (
                    <div
                      key={row.project_id + '-delete-button'}
                      onClick={(event) => {
                        event.stopPropagation()
                        setSelectedProject(row)
                        setProject(row)
                        setIsModalVisible(true)
                      }}
                      data-testid={'delete-button-' + index}
                      data-cy={'delete-' + row.project_name}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  ) : null}
                </Table.Data>
              </Table.Row>
            ))}
            {isModalVisible && (
              <div data-testid="pyramidConfirmationModal">
                <Modal
                  headingText="Are you sure you want to delete this project?"
                  onRefuse={() => setIsModalVisible(false)}
                  isVisible={isModalVisible}
                >
                  <div className="hc-pa-normal">
                    <Grid.Container>
                      <Grid.Item xs>
                        <p className="modalProjectMessage">
                          Deleting{' '}
                          <strong>
                            {selectedProject?.project_description ||
                              'this project'}{' '}
                          </strong>{' '}
                          will delete all associated data. This includes all
                          outcomes, stories and linked umbrellas assigned to
                          those stories.
                        </p>
                      </Grid.Item>
                    </Grid.Container>
                    <Grid.Container direction="row-reverse" spacing="dense">
                      <Grid.Item>
                        <Button
                          data-testid={'delete-project'}
                          type="primary"
                          onClick={() => {
                            setIsModalVisible(false)
                            deleteProject(selectedProject)
                          }}
                        >
                          Yes
                        </Button>
                      </Grid.Item>
                      <Grid.Item>
                        <Button
                          type="secondary"
                          onClick={() => {
                            setIsModalVisible(false)
                            setSelectedProject({})
                          }}
                        >
                          No
                        </Button>
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                </Modal>
              </div>
            )}
          </Table.Body>
          <>
            {isDrawerOpen ? (
              <ProjectContacts
                key={'contacts_' + selectedProject.project_id}
                setIsDrawerOpen={setIsDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                selectedProject={selectedProject}
              />
            ) : null}
          </>
        </Table>
      )}
    </CanEditContext.Provider>
  )
}
export default ProjectListTable
