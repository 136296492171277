export const getUserName = (userInfo) => `${userInfo.fullName}`

export const getLanId = (userInfo) => `${userInfo.lanId}`

export const SLINGSHOT_MERCH_TYPE = 'SLINGSHOT'
export const GROUPED_OFFER_CATEGORY = 6100001
export const MEDIA_UNIT_TYPE = 'GO'
export const CREATIVEHUB_GO_PREFIX = '8675'

export const buildGroupedOfferRequest = (values) => {
  const payload = {
    body_copy: values.bodyCopy,
    boosted_tcins: values.selectedBoostedIds.map((item) => item.id),
    categories: [{ id: GROUPED_OFFER_CATEGORY }],
    end_date: values.endDate,
    feature_offer: values.featureOffer,
    images: [
      {
        external_url: values.groupImageURL
          ? values.groupImageURL
          : 'https://target.scene7.com/is/image/Target/CH_icon_RGB_red',
        id: null,
        internal_url: '',
      },
    ],
    offers: values.verifiedOffers.map((offer) => offer.id.toString()),
    scroller_type: MEDIA_UNIT_TYPE,
    start_date: values.startDate,
  }
  return payload
}

export const createOfferPayload = (dpci, boosted_tcins, offerIds) => {
  let offerPayload = []
  let parent_offer = true

  offerIds.forEach((offerId) => {
    if (parent_offer) {
      offerPayload.push({
        BOOSTED_TCINS: boosted_tcins,
        DPCI: dpci,
        LINERED_PARENT: '',
        LISTING_TYPE: 'ProductDetails_L2',
        OFFER_ID: offerId,
        PICTURED: true,
        STATUS: 'ACTIVE',
      })
      parent_offer = false
    } else {
      offerPayload.push({
        BOOSTED_TCINS: '',
        DPCI: '111-11-1111',
        LINERED_PARENT: dpci,
        LISTING_TYPE: 'ProductDetails',
        OFFER_ID: offerId,
        PICTURED: false,
        STATUS: 'ACTIVE',
      })
    }
  })
  return offerPayload
}

export const getInitialValues = (date = new Date(), sourcePromoUUID) => {
  const initialValues = {
    adName: '',
    bodyCopy: '',
    boostedIds: '',
    displayOrder: 1,
    dwaSearch: false,
    endDate: '',
    featureOffer: '',
    groupImageURL: '',
    invalidOfferIds: [],
    offerIds: '',
    pageNumbers: '',
    promoId: '',
    sneakPeek: false,
    sourcePromoUUID: sourcePromoUUID,
    startDate: '',
    umbrellaId: '',
    verifiedOffers: [],
  }
  return initialValues
}

export const getActiveTab = (tab = '') => {
  const validTabNames = ['', 'create', 'view', 'edit']
  if (!validTabNames.includes(tab)) {
    return { activeTab: 'view', details: true }
  }
  return { activeTab: tab || 'create' }
}
