import { Chip, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import '../scss/StickyBarProject.scss'
import MarketingChannelSelect from '../../creativeHubGlobal/components/MarketingChannelSelect'
import { getChipValues, pyramid_options } from '../util/ProjectDetailsUtil'

const StickyBarProject = ({
  pyramidFilter,
  setPyramidFilter,
  selectedMarketingChannelFilter,
  setMarketingChannelFilter,
  channels,
}) => (
  <>
    <Grid.Container
      className="stickybar-container-project"
      data-testid="stickyBar"
    >
      <Grid.Item xs={6} id="pyramidFilter">
        <Autocomplete
          chipHeight="dense"
          label="Pyramids"
          placeholder="Filter Pyramids"
          onUpdate={(_, value) => setPyramidFilter(value)}
          options={pyramid_options}
          multiselect
          value={pyramidFilter}
          renderChip={(cat, onRequestDelete) => (
            <Chip
              size="dense"
              style={{
                backgroundColor: cat.color,
                border: '0px',
                color: 'white',
              }}
              onRequestDelete={onRequestDelete}
            >
              {getChipValues(cat.value)}
            </Chip>
          )}
          data-testid="pyramidFilter"
        />
      </Grid.Item>
      <Grid.Item xs={6} id="pyramidFilter">
        <MarketingChannelSelect
          selectedMarketingChannelFilter={selectedMarketingChannelFilter}
          setMarketingChannelFilter={setMarketingChannelFilter}
          channels={channels}
        />
      </Grid.Item>
    </Grid.Container>
  </>
)

export default StickyBarProject
