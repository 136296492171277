import { Dialog, Form, Grid, Modal, Chip } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'

import PlatformApi from '../api/platform-notifications/EmailVerification'
import CatalogApi from '../api/pop-catalog/CatalogApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { CategoryColorMap, CATEGORY_LIST } from '../util/CategoryColorMap'
import { canEditCatalogPage } from '../util/CheckRole'

import { formatContacts } from './util/EditCatalogPageUtil'

let pageSchema = yup.object().shape({
  artDirector: yup.array(),
  category: yup.array().min(1, 'Must have at least 1 category'),
  copyWriter: yup.array(),
  merchant: yup.array(),
  notes: yup.string(),
  section: yup.array(),
})

const EditCatalogPage = (props) => {
  const { page, show, setShow, catalog, setCatalog, role } = props

  const [isDeleteDialog, setDeleteDialog] = useState(false)
  const [groupMembers, setGroupMembers] = useState()
  const platformApi = new PlatformApi()
  const catalogApi = new CatalogApi()
  const wipeState = () => {
    setDeleteDialog(false)
    setShow(false)
  }
  const hasEditPrivileges = role.some((r) => canEditCatalogPage(r))

  const contactsFromEntitlementGroups = [
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CrtvHub-CopyWriter',
  ]
  const entitlements = []

  useEffect(() => {
    async function fetchData() {
      for (let i = 0; i < contactsFromEntitlementGroups.length; i++) {
        await platformApi
          .getContactsByEntitlement(contactsFromEntitlementGroups[i])
          .then((data) => {
            entitlements.push(data)
          })
          .catch((e) => console.log('error', e))
      }
      setGroupMembers(entitlements)
    }
    fetchData()
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])

  const defaultMembers = formatContacts(groupMembers)
  const { CreativeArt = [], Copywriter = [] } = defaultMembers

  return (
    show && (
      <Formik
        initialValues={{
          artDirector: formatToForm(page?.artDirector) || [],
          category: formCategories(page?.category) || [],
          copyWriter: formatToForm(page?.copyWriter) || [],
          merchant: formatToForm(page?.merchant) || [],
          notes: page.notes || '',
          section: formatToForm(page?.section) || [],
        }}
        validationSchema={pageSchema}
        onSubmit={(values, funcs) => {
          const sectionValue = values?.section?.map((section) => section.value)
          const categoryValue = values?.category.map(
            (category) => category.value
          )
          const artDirectorValue = values?.artDirector?.map(
            (artDirector) => artDirector.value
          )
          const copyWriterValue = values?.copyWriter?.map(
            (copyWriter) => copyWriter.value
          )
          const merchantValue = values?.merchant?.map(
            (merchant) => merchant.value
          )
          catalogApi
            .putCatalog(catalog.id, {
              id: catalog.id,
              last_updated_timestamp: catalog.lastUpdatedTimestamp,
              pages: [
                {
                  art_director: artDirectorValue,
                  category: categoryValue,
                  copy_writer: copyWriterValue,
                  id: page.id,
                  last_updated_timestamp: page.lastUpdatedTimestamp,
                  merchant: merchantValue,
                  notes: values.notes,
                  section: sectionValue,
                },
              ],
            })
            .then((response) => {
              funcs.resetForm()
              wipeState()
              setCatalog(response)
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          submitCount,
        }) => {
          const handleDialogClose = () => {
            handleReset()
            wipeState()
          }

          const handleDelete = () => {
            // We have to delete a single page in the catalog this way
            catalogApi
              .putCatalog(catalog.id, {
                deleted: false,
                id: catalog.id,
                last_updated_timestamp: catalog.lastUpdatedTimestamp,
                pages: [
                  {
                    deleted: true,
                    id: page.id,
                    last_updated_timestamp: page.lastUpdatedTimestamp,
                  },
                ],
              })
              .then((response) => setCatalog(response))
            handleDialogClose()
          }

          const pageNumber = page.pageOrdering + 1

          return (
            <Modal
              isVisible={show}
              headingText={'Edit Page ' + pageNumber}
              onRefuse={handleDialogClose}
            >
              <div className="hc-pa-normal">
                <Grid.Container spacing="dense">
                  <Grid.Container spacing="dense">
                    <Grid.Item md={6} xs={12}>
                      <Autocomplete
                        id="category"
                        chipHeight="dense"
                        label="Categories"
                        onUpdate={(_, value) =>
                          setFieldValue('category', value)
                        }
                        options={CATEGORY_LIST}
                        error={errors.category}
                        errorText={errors.category}
                        multiselect
                        value={values.category}
                        renderChip={(cat, onRequestDelete) => (
                          <Chip
                            size="dense"
                            style={{
                              backgroundColor: cat.color,
                              border: '0px',
                              color: 'white',
                            }}
                            onRequestDelete={onRequestDelete}
                          >
                            {cat.value}
                          </Chip>
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Autocomplete
                        id="section"
                        chipHeight="dense"
                        label="Section"
                        className="hc-mb-dense hc-mt-dense"
                        onUpdate={(_, value) => setFieldValue('section', value)}
                        options={values.section}
                        value={values.section}
                        multiselect
                        allowCustomInputValue
                        placeholder="Insert 1-2 Sections"
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Autocomplete
                        id="artDirector"
                        allowCustomInputValue={true}
                        className="hc-mb-dense hc-mt-dense"
                        disabled={!hasEditPrivileges}
                        multiselect
                        onUpdate={(_, value) =>
                          setFieldValue('artDirector', value)
                        }
                        options={CreativeArt}
                        renderChip={(option, onRequestDelete) => (
                          <Chip
                            size="dense"
                            onRequestDelete={
                              hasEditPrivileges && onRequestDelete
                            }
                          >
                            {option.value}
                          </Chip>
                        )}
                        value={values.artDirector}
                        label="Art Director"
                        noResultsMessage="Use Outlook Name"
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Autocomplete
                        id="copyWriter"
                        multiselect
                        allowCustomInputValue={true}
                        className="hc-mb-dense hc-mt-dense"
                        disabled={!hasEditPrivileges}
                        onUpdate={(_, value) =>
                          setFieldValue('copyWriter', value)
                        }
                        options={Copywriter}
                        renderChip={(option, onRequestDelete) => (
                          <Chip
                            size="dense"
                            onRequestDelete={
                              hasEditPrivileges && onRequestDelete
                            }
                          >
                            {option.value}
                          </Chip>
                        )}
                        value={values.copyWriter}
                        label="Copy Writer"
                        noResultsMessage="Use Outlook Name"
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Autocomplete
                        id="merchant"
                        multiselect
                        allowCustomInputValue={true}
                        chipHeight="dense"
                        className="hc-mb-dense hc-mt-dense"
                        onUpdate={(_, value) =>
                          setFieldValue('merchant', value)
                        }
                        value={values.merchant}
                        options={values.merchant}
                        label="Merchant"
                        noResultsMessage="Use Outlook Name"
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Form.Field
                        id="notes"
                        label="Notes"
                        onChange={handleChange}
                        value={values.notes}
                        error={
                          errors.notes && (submitCount > 0 || touched.notes)
                        }
                        onBlur={handleBlur}
                        errorText={errors.notes}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Container>
                <CancelSubmitButtons
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                  handleDelete={setDeleteDialog}
                />
              </div>
              <Dialog
                isVisible={isDeleteDialog}
                headingText={'Are you sure want to delete this page?'}
                onRefuse={() => setDeleteDialog(false)}
                refuseButtonText="Cancel"
                onApprove={handleDelete}
                approveButtonText={'Delete'}
              />
            </Modal>
          )
        }}
      </Formik>
    )
  )
}

function formatToForm(data) {
  return data?.map((d, index) => ({
    id: index,
    label: d,
    value: d,
  }))
}

function formCategories(categories) {
  return categories?.map((category, idx) => ({
    color: CategoryColorMap[category],
    id: idx,
    label: (() => {
      switch (category) {
        case 'Toys':
          return 'Toys'
        case 'Ent':
          return 'Entertainment'
        case 'A&A':
          return 'A&A'
        case 'ESS/BTY':
          return 'ESS/BTY'
        case 'Elec':
          return 'Electronics'
        case 'F&B':
          return 'F&B'
        case 'SPRT':
          return 'Sporting Goods'
        case 'Home':
          return 'Home'
        default:
          return 'Toys'
      }
    })(),
    value: category,
  }))
}
export default EditCatalogPage
