const projectMapper = (project) => ({
  copyDueDate: project.copyDueDate,
  createdBy: project.createdBy,
  createdDate: project.createdDate,
  deleted: project.deleted,
  endDate: project.endDate,
  imagesDueDate: project.imagesDueDate,
  kickoffDate: project.kickoffDate,
  offersDueDate: project.offersDueDate,
  projectDescription: project.projectDescription,
  projectId: project.projectId,
  projectMonth: project.projectMonth,
  projectName: project.projectName,
  projectQuarter: project.projectQuarter,
  projectWeek: project.projectWeek,
  projectYear: project.projectYear,
  startDate: project.startDate,
  strategicIntent: project.strategicIntent,
  updatedBy: project.updatedBy,
  updatedDate: project.updatedDate,
})
export default projectMapper
