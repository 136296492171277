/**
 * This filter looks for a block match in possible values.
 *
 * Example: "t & j" matches "cat & jack", but not "tom & jerry"
 */
export default function containsFilter(searchText, { label }) {
  const compareString = label.toString().toLowerCase()
  searchText = searchText.toString().toLowerCase()

  return compareString.indexOf(searchText) !== -1
}
