import { Heading, Card, Grid } from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ExpandableCategory from '../../components/ExpandableCategory'
import LoadingSpinner from '../../components/LoadingSpinner'
import useGetAllCategories from '../../hooks/useGetAllCategories'
import useGetDWAOffers from '../../hooks/useGetDWAOffers'
import useGetWeeklyAd from '../../hooks/useGetWeeklyAd'
import usePutCategory from '../../hooks/usePutCategory'
import usePutDWAOffer from '../../hooks/usePutDWAOffer'
import { DWA_CATEGORY_LIST } from '../../util/DWACategories'

const DigitalWeeklyAdView = () => {
  const [loading, setLoading] = useState(false)
  const [dwa, setDWA] = useState(DWA_CATEGORY_LIST)
  const wadId = useParams().wadId
  const [getCategory] = useGetDWAOffers(setLoading, setDWA)
  const [getAllCategories] = useGetAllCategories(setLoading, setDWA)
  const [weeklyAd] = useGetWeeklyAd(wadId, setLoading)
  const [putDWAOffer] = usePutDWAOffer(setLoading, setDWA)
  const [putCategory] = usePutCategory(setLoading, setDWA)

  const lockedOut = weeklyAd?.lockedOut
  useEffect(() => {
    getAllCategories(true)
  }, [dwa, getAllCategories])

  function validCategory(id) {
    return id !== 0 && id !== 2 && id !== 3 && id !== 4
  }

  return weeklyAd && weeklyAd?.midweek ? (
    <Heading size={2} className="hc-ta-center hc-pb-expanded">
      Midweek ads do not have a DWA page
    </Heading>
  ) : weeklyAd.lockedOut ? (
    <div>
      <Card elevation={2} className="hc-pa-dense">
        <Heading size={2} className="hc-ta-center hc-pb-expanded">
          DWA: {weeklyAd?.eventWeek}
        </Heading>
        <Grid.Container spacing="dense">
          {dwa?.map(
            (category) =>
              validCategory(category.categoryId) && (
                <Grid.Item xs={12} className="hc-pt-dense hc-pb-none">
                  <ExpandableCategory
                    lockedOut={lockedOut}
                    category={category}
                    getCategory={getCategory}
                    putCategory={putCategory}
                    setDWA={setDWA}
                    putDWAOffer={putDWAOffer}
                  />
                </Grid.Item>
              )
          )}
        </Grid.Container>
      </Card>
      <LoadingSpinner show={loading} />
    </div>
  ) : (
    <Heading size={2} className="hc-ta-center hc-pb-expanded">
      Unavailable until post-lockout
    </Heading>
  )
}
export default DigitalWeeklyAdView
