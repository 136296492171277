import {
  ASSETHUB_PROJECT_EXIST_ERROR,
  ASSETHUB_PROJECT_GENERIC_ERROR,
} from './Constants'

export const generateCreateEditProjectError = (response) => {
  const error_message = response?.data?.error?.includes(
    'Project already exists'
  )
    ? ASSETHUB_PROJECT_EXIST_ERROR
    : ASSETHUB_PROJECT_GENERIC_ERROR
  return { error_message }
}
