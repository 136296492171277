import axios from 'axios'

import appConfig from '../../config/appConfig'
import commentMapper from '../../mappers/commentMapper'

export default class CommentApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_comment_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.root,
      timeout: 31000,
    })
    return this.client
  }

  getComments = async (cutId) =>
    await this.init_comment_api()
      .get(this.config.backend.comment + `?cutId=${cutId}`)
      .then((res) => res.data.content.map((comment) => commentMapper(comment)))
      .catch((e) => console.log(e))

  postComment = async (commentData) =>
    await this.init_comment_api()
      .post(this.config.backend.comment, commentData)
      .then((res) => commentMapper(res.data))
      .catch((e) => console.log(e))

  putComment = async (commentId, commentData) =>
    await this.init_comment_api()
      .put(this.config.backend.comment + commentId, commentData)
      .then((res) => commentMapper(res.data))
      .catch((e) => console.log(e))

  deleteComment = async (commentId) =>
    await this.init_comment_api()
      .delete(this.config.backend.comment + commentId)
      .catch((e) => console.log(e))
}
