import {
  Button,
  Card,
  Chip,
  ExpandableSection,
  Grid,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import {
  faExternalLinkAlt,
  faImages,
  faPencilAlt,
  faGlasses,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import appConfig from '../config/appConfig'
import PageView from '../containers/PageView'
import {
  isAdminOrMarketing,
  isMarketing,
  isAdminOrMerch,
} from '../util/CheckRole'
import GetPageTitle from '../util/GetPageTitle'

import SortableStoryContainer from './SortableStoryContainer'
import '../scss/POP-table.scss'

const ExpandablePage = (props) => {
  const {
    role,
    isMidweek,
    page,
    getPage,
    putPage,
    putStory,
    putCut,
    setEditPage,
    setEditStory,
    setAddArtPatch,
    setEditCut,
    setViewCutPatches,
    setViewItemPatches,
    setEditArtPatch,
    setEditItem,
    setEditDummy,
    setEditPropItem,
    divisionFilters,
    expands,
    setExpands,
    getExport,
    lockoutDate,
    eventWeek,
  } = props
  const hasEditPrivileges =
    isAdminOrMarketing(role) ||
    ((isAdminOrMerch(role) || isMarketing(role)) && !page.lockedOut)
  const [expanded, setExpanded] = useState(false)
  const config = appConfig()
  const exportUrl = config.backend.root + config.backend.exportPage + page.id
  const [disableExport, setDisableExport] = useState(false)
  const [isPageView, setPageView] = useState(false)
  const [isPageDataAvailable, setPageDataStatus] = useState(false)

  const onPageView = (event) => {
    event.stopPropagation()
    setPageView(true)
    if (!isPageDataAvailable) {
      getPage(page.id)
      setPageDataStatus(true)
    }
  }

  if (divisionMatch(divisionFilters, page.divisions)) {
    return (
      <Card className="weekly-ad-expandable-page">
        <ExpandableSection
          padding="none"
          expanded={expanded}
          onExpand={() => {
            if (!isPageDataAvailable) {
              getPage(page.id)
              setPageDataStatus(true)
            }
            setExpanded(!expanded)
            let exp = Object.assign({}, expands)

            exp.page[page.id] = !expanded
            setExpands(exp)
          }}
        >
          <Grid.Container align="center" spacing="none">
            <Grid.Item xs={0.5} align="center" style={{ paddingLeft: '10px' }}>
              {' '}
              <FontAwesomeIcon
                className={`weekly-ad-expand${
                  expanded
                    ? 'rotate-clockwise-45'
                    : 'rotate-counter-clockwise-45'
                }`}
                icon={expanded ? faChevronDown : faChevronRight}
              />
            </Grid.Item>
            <Grid.Item xs={2} style={{ paddingLeft: '10px' }}>
              {GetPageTitle(page)}
              {!isMidweek && (
                <Tooltip
                  location={'right'}
                  className="hc-ml-min hc-mr-min"
                  content={`Page Lockout Date: ${page.lockoutDate}`}
                >
                  <FontAwesomeIcon
                    icon={faClock}
                    size="xs"
                    className={
                      page.lockedOut ? 'hc-clr-error' : 'hc-clr-success'
                    }
                  />
                </Tooltip>
              )}
            </Grid.Item>
            <Grid.Item xs={6}>
              {page.divisions
                ? page.divisions.map((division) => (
                    <Chip
                      size="dense"
                      style={{
                        backgroundColor: division.color,
                        border: '0px',
                        color: 'white',
                      }}
                      key={division.id}
                    >
                      {division.shortCode.toUpperCase()}
                    </Chip>
                  ))
                : null}
            </Grid.Item>
            <Grid.Item xs={1}>
              <Button iconOnly onClick={onPageView}>
                <FontAwesomeIcon icon={faImages} color="#000000" />
              </Button>
            </Grid.Item>
            <Grid.Item xs={1}>
              <Button
                disabled={disableExport}
                iconOnly
                onClick={(event) => {
                  event.stopPropagation()
                  getExport({ setDisable: setDisableExport, url: exportUrl })
                }}
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} color="#000000" />
              </Button>
            </Grid.Item>
            <Grid.Item xs={1}>
              <Button
                iconOnly
                onClick={(event) => {
                  event.stopPropagation()
                  setEditPage({
                    body: page,
                    hasEditPrivileges,
                    show: true,
                  })
                }}
              >
                <FontAwesomeIcon
                  icon={hasEditPrivileges ? faPencilAlt : faGlasses}
                  color="#000000"
                />
              </Button>
            </Grid.Item>
          </Grid.Container>
          <ExpandableSection.Content className="hc-pa-normal">
            Objective: {page.businessObjectives}
            <SortableStoryContainer
              role={role}
              page={page}
              putPage={putPage}
              putStory={putStory}
              putCut={putCut}
              setEditStory={setEditStory}
              setEditCut={setEditCut}
              setAddArtPatch={setAddArtPatch}
              setEditArtPatch={setEditArtPatch}
              setViewCutPatches={setViewCutPatches}
              setViewItemPatches={setViewItemPatches}
              setEditItem={setEditItem}
              setEditDummy={setEditDummy}
              setEditPropItem={setEditPropItem}
              expands={expands}
              setExpands={setExpands}
              hasEditPrivileges={hasEditPrivileges}
              lockoutDate={lockoutDate}
              eventWeek={eventWeek}
              expanded={expanded}
            />
          </ExpandableSection.Content>
        </ExpandableSection>
        <PageView isVisible={isPageView} page={page} onClose={setPageView} />
      </Card>
    )
  } else return null
}

function divisionMatch(filter, pageDivs) {
  if (!filter || !pageDivs) return false
  if (filter.length === 0) return true

  let filterIds = filter.map((it) => it.id)
  let pageDivIds = pageDivs.map((it) => it.id)

  return filterIds.some((it) => pageDivIds.includes(it))
}
export default ExpandablePage
