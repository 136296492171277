import { DIVISION_LIST } from './DivisionUtils'

export const DWA_CATEGORY_LIST = [
  {
    categoryId: 0,
    divisions: [],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Invalid',
    offers: [],
  },
  {
    categoryId: 1,
    divisions: [],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Front Page Deals',
    offers: [],
  },
  {
    categoryId: 2,
    divisions: [],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Seasonal Holiday',
    offers: [],
  },
  {
    categoryId: 3,
    divisions: [],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Flex Category I',
    offers: [],
  },
  {
    categoryId: 4,
    divisions: [],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Flex Category II',
    offers: [],
  },
  {
    categoryId: 5,
    divisions: [DIVISION_LIST[0]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Baby',
    offers: [],
  },
  {
    categoryId: 6,
    divisions: [DIVISION_LIST[1], DIVISION_LIST[4]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Beauty',
    offers: [],
  },
  {
    categoryId: 7,
    divisions: [
      DIVISION_LIST[11],
      DIVISION_LIST[12],
      DIVISION_LIST[13],
      DIVISION_LIST[15],
      DIVISION_LIST[17],
      DIVISION_LIST[20],
      DIVISION_LIST[23],
      DIVISION_LIST[25],
      DIVISION_LIST[30],
      DIVISION_LIST[33],
    ],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Clothing & Shoes',
    offers: [],
  },
  {
    categoryId: 8,
    divisions: [DIVISION_LIST[8]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Entertainment',
    offers: [],
  },
  {
    categoryId: 9,
    divisions: [
      DIVISION_LIST[2],
      DIVISION_LIST[7],
      DIVISION_LIST[22],
      DIVISION_LIST[26],
    ],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Grocery',
    offers: [],
  },
  {
    categoryId: 10,
    divisions: [DIVISION_LIST[18]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Health',
    offers: [],
  },
  {
    categoryId: 11,
    divisions: [
      DIVISION_LIST[5],
      DIVISION_LIST[6],
      DIVISION_LIST[14],
      DIVISION_LIST[24],
      DIVISION_LIST[28],
      DIVISION_LIST[29],
    ],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Home',
    offers: [],
  },
  {
    categoryId: 12,
    divisions: [DIVISION_LIST[10]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Household Essentials',
    offers: [],
  },
  {
    categoryId: 13,
    divisions: [DIVISION_LIST[19]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Outdoor Living',
    offers: [],
  },
  {
    categoryId: 14,
    divisions: [DIVISION_LIST[21]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Pets',
    offers: [],
  },
  {
    categoryId: 15,
    divisions: [DIVISION_LIST[27]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Sporting Goods',
    offers: [],
  },
  {
    categoryId: 16,
    divisions: [DIVISION_LIST[16], DIVISION_LIST[9]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Tech',
    offers: [],
  },
  {
    categoryId: 17,
    divisions: [DIVISION_LIST[31]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Toys',
    offers: [],
  },
  {
    categoryId: 18,
    divisions: [DIVISION_LIST[32]],
    eaNotes: '',
    expanded: false,
    id: -1,
    name: 'Video Games',
    offers: [],
  },
]
