import isEmpty from 'lodash.isempty'

export function setFilterFromQuery(query) {
  const filterQueryOptions = []
  const filters = ['pyramids', 'statuses', 'adRequest', 'samplePulled']
  filters.forEach((filterName) => {
    if (!isEmpty(query.getAll(filterName))) {
      const elements = query.getAll(filterName)[0].split(',')
      elements.forEach((elem) => {
        const option = filterOptions.find(
          ({ filter, value }) => filter === filterName && value === elem
        )
        option && filterQueryOptions.push(option)
      })
    }
  })
  return filterQueryOptions
}

export function setPageFilterFromQuery(query = '', pageList = []) {
  var pageIds = []
  if (query !== '' && pageList.length > 0) {
    pageIds = query.get('pageId').split(',')
  }
  const pageQueryOptions = []
  if (pageList.length > 0) {
    pageIds.forEach((pageId) => {
      let x = pageList?.find((page) => page?.id === parseInt(pageId))
      pageQueryOptions.push(x)
    })
    return pageQueryOptions
  } else return []
}

export function setImageSampleFilterFromQuery(query) {
  const filterQueryOptions = []
  const filters = [
    'category',
    'fig_model',
    'sample_submitted',
    'sample_status',
    'art_director_request',
    'image_sample_collected',
    'image_approved',
  ]
  filters.forEach((filterName) => {
    if (!isEmpty(query.getAll(filterName))) {
      const elements = query.getAll(filterName)[0].split(',')
      elements.forEach((elem) => {
        const option = imageSampleFilters.find(
          ({ filter, value }) => filter === filterName && value === elem
        )
        option && filterQueryOptions.push(option)
      })
    }
  })
  return filterQueryOptions
}

export const imageSampleFilters = [
  {
    filter: 'category',
    id: 'categoryToys',
    label: 'Category: Toys',
    value: 'Toys',
  },
  {
    filter: 'category',
    id: 'categoryA&A',
    label: 'Category: A&A',
    value: 'A&A',
  },
  {
    filter: 'category',
    id: 'categoryESS/BTY',
    label: 'Category: ESS/BTY',
    value: 'ESS/BTY',
  },
  {
    filter: 'category',
    id: 'categoryElec',
    label: 'Category: Elec',
    value: 'Elec',
  },
  {
    filter: 'category',
    id: 'categoryEnt',
    label: 'Category: Ent',
    value: 'Ent',
  },
  {
    filter: 'category',
    id: 'categoryFB',
    label: 'Category: F&B',
    value: 'F&B',
  },
  {
    filter: 'category',
    id: 'categoryHome',
    label: 'Category: Home',
    value: 'Home',
  },
  {
    filter: 'category',
    id: 'categorySPRT',
    label: 'Category: SPRT',
    value: 'SPRT',
  },
  {
    filter: 'fig_model',
    id: 'figModelYes',
    label: 'Fig Model: Yes',
    value: 'true',
  },
  {
    filter: 'fig_model',
    id: 'figModelNo',
    label: 'Fig Model: No',
    value: 'false',
  },
  {
    filter: 'sample_submitted',
    id: 'sampleSubmittedYes',
    label: 'Sample submitted: Yes',
    value: 'true',
  },
  {
    filter: 'sample_submitted',
    id: 'sampleSubmittedNo',
    label: 'Sample submitted: No',
    value: 'false',
  },
  {
    filter: 'sample_status',
    id: 'statusTurnIn',
    label: 'Status: Turn-in',
    value: 'turn-in',
  },
  {
    filter: 'sample_status',
    id: 'statusInventory',
    label: 'Status: Inventory',
    value: 'inventory',
  },
  {
    filter: 'sample_status',
    id: 'statusMissing',
    label: 'Status: Missing',
    value: 'missing',
  },
  {
    filter: 'sample_status',
    id: 'statusBlank',
    label: 'Status: Blank',
    value: 'blank',
  },
  {
    filter: 'art_director_request',
    id: 'adRequestTrue',
    label: 'Shooting',
    value: 'true',
  },
  {
    filter: 'image_sample_collected',
    id: 'imageSampleCollectedTrue',
    label: 'Image sample collected',
    value: 'true',
  },
  {
    filter: 'image_sample_collected',
    id: 'imageSampleCollectedFalse',
    label: 'Image sample not collected',
    value: 'false',
  },
  {
    filter: 'image_approved',
    id: 'imageApprovedTrue',
    label: 'Image approved',
    value: 'true',
  },
  {
    filter: 'image_approved',
    id: 'imageApprovedFalse',
    label: 'Image not approved',
    value: 'false',
  },
]

export const USAGE_RIGHTS_STATEMENT_OPTIONS = [
  {
    label: 'Accept',
    value: 'accept',
  },
  {
    label: 'Decline',
    value: 'decline',
  },
]

export const filterOptions = [
  {
    filter: 'statuses',
    id: 'statusTurnIn',
    label: 'Status: Turn-in',
    value: 'turnIn',
  },
  {
    filter: 'statuses',
    id: 'statusInventory',
    label: 'Status: Inventory',
    value: 'inventory',
  },
  {
    filter: 'statuses',
    id: 'statusMissing',
    label: 'Status: Missing',
    value: 'missing',
  },
  {
    filter: 'statuses',
    id: 'statusBlank',
    label: 'Status: Blank',
    value: 'blank',
  },
  {
    filter: 'adRequest',
    id: 'adRequest',
    label: 'Shooting',
    value: 'true',
  },
  {
    filter: 'samplePulled',
    id: 'samplePulled0',
    label: 'Pulled: No',
    value: '0',
  },
  {
    filter: 'samplePulled',
    id: 'samplePulled1',
    label: 'Pulled: Yes',
    value: '1',
  },
  {
    filter: 'samplePulled',
    id: 'samplePulled2',
    label: 'Pulled: Cancelled',
    value: '2',
  },
  {
    filter: 'samplePulled',
    id: 'samplePulled-1',
    label: 'Pulled: Blank',
    value: '-1',
  },
  {
    filter: 'pyramids',
    id: 'pyramid1',
    label: 'Pyramid: Apparel/Access',
    value: '1',
  },
  {
    filter: 'pyramids',
    id: 'pyramid2',
    label: 'Pyramid: Hardlines',
    value: '2',
  },
  {
    filter: 'pyramids',
    id: 'pyramid3',
    label: 'Pyramid: Essentials/Beauty',
    value: '3',
  },
  {
    filter: 'pyramids',
    id: 'pyramid4',
    label: 'Pyramid: Food/Bev',
    value: '4',
  },
  {
    filter: 'pyramids',
    id: 'pyramid5',
    label: 'Pyramid: Home',
    value: '5',
  },
  {
    filter: 'pyramids',
    id: 'pyramid6',
    label: 'Pyramid: Special Projects',
    value: '6',
  },
  {
    filter: 'pyramids',
    id: 'pyramid7',
    label: 'Pyramid: Baby',
    value: '7',
  },
]
