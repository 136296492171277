import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import appConfig from '../config/appConfig'

const usePutCategory = (setLoading, setDWA) => {
  const config = appConfig()
  const wadId = useParams().wadId
  const [request, setRequest] = useState(null)
  const url = config.backend.root + config.backend.dwa + wadId

  useEffect(() => {
    let mounted = true
    const putCategory = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.put(url, request.body)
        const updatedCategory = response.data
        mounted &&
          setDWA((oldDWA) => [
            ...oldDWA.slice(0, updatedCategory.categoryId),
            {
              ...oldDWA[updatedCategory.categoryId],
              eaNotes: updatedCategory.eaNotes,
            },
            ...oldDWA.slice(updatedCategory.categoryId + 1, 19),
          ])
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
    }
    if (request) {
      putCategory()
    }

    return () => {}
  }, [setLoading, url, request, setDWA])

  return [setRequest]
}
export default usePutCategory
