import { Button, Spinner } from '@enterprise-ui/canvas-ui-react'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconArea = ({
  showSearchIcon,
  isLoading,
  searchText,
  onClear,
  disabled,
}) => {
  let iconContent
  if (isLoading) {
    iconContent = <Spinner size="dense" />
  } else if (searchText.length === 0 && showSearchIcon) {
    iconContent = (
      <FontAwesomeIcon
        icon={faSearch}
        className="hc-mr-dense C-Autocomplete__IconArea__Icon"
      />
    )
  } else if (!disabled && searchText.length !== 0) {
    iconContent = (
      <Button
        className="C-Autocomplete__IconArea__Button --embeddedInComponent"
        size="dense"
        iconOnly
        aria-label="clear"
        onClick={onClear}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    )
  } else {
    return null
  }

  return <span className="C-Autocomplete__IconArea">{iconContent}</span>
}

IconArea.displayName = 'IconArea'
export default IconArea
