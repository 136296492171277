import {
  Button,
  Card,
  Grid,
  Heading,
  Tabs,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'

import LateAdditionsTable from '../../components/LateAdditionsTable'
import LoadingSpinner from '../../components/LoadingSpinner'
import PaginationWidget from '../../components/PaginationWidget'
import appConfig from '../../config/appConfig'
import AddLateAddition from '../../forms/AddLateAddition'
import EditLateAddition from '../../forms/EditLateAddition'
import useDeleteLateAddition from '../../hooks/useDeleteLateAddition'
import useExport from '../../hooks/useGetExport'
import useGetLateAddition from '../../hooks/useGetLateAddition'
import useGetWeeklyAd from '../../hooks/useGetWeeklyAd'
import usePostLateAddition from '../../hooks/usePostLateAddition'
import usePutLateAddition from '../../hooks/usePutLateAddition'
import {
  isAdminOrMarketing,
  isAdminOrCreative,
  isAdminOrSamples,
} from '../../util/CheckRole'
import '../../scss/POP-table.scss'

const LateAdditionsView = (props) => {
  let { role, toSampleUrl } = props
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const wadId = useParams().wadId
  const [loading, setLoading] = useState(false)
  const [wadLoading, setWadLoading] = useState(false)

  const makeToast = ToastProvider.useToaster()

  const [weeklyAd] = useGetWeeklyAd(wadId, setWadLoading, makeToast)
  const [getExport] = useExport(makeToast)
  const [disableExport, setDisableExport] = useState(false)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [lateAdditions, setLoadLateAdditions] = useGetLateAddition(
    setLoading,
    query,
    wadId
  )
  const deleteLateAddition = useDeleteLateAddition(
    setLoading,
    setLoadLateAdditions
  )
  const putLateAddition = usePutLateAddition(setLoading, setLoadLateAdditions)
  const postLateAddition = usePostLateAddition(setLoading, setLoadLateAdditions)
  const [transport, setTransport] = useState(0)
  const [currentActiveTab, setCurrentActiveTab] = useState('late-additions')

  const [editLateAddition, setEditLateAddition] = useState({
    body: {},
    show: false,
  })
  const hasEditPrivileges =
    isAdminOrMarketing(role) ||
    ((isAdminOrCreative(role) || isAdminOrSamples(role)) &&
      weeklyAd.creativeLockedOut)

  const navigate = useNavigate()
  const config = appConfig()

  const sampleExportUrl =
    config.backend.root + config.backend.export + config.backend.samples + wadId
  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage.toString())
      setTransport(0)
      setLoadLateAdditions(true)
      navigate(`/weeklyad/${wadId}/sample/late-additions?${query.toString()}`)
    }
  }, [navigate, query, setLoadLateAdditions, transport, wadId])

  return (
    <div className="hc-pa-none">
      <Grid.Container
        spacing="none"
        direction="row-reverse"
        align="flex-end"
        justify="space-between"
      >
        <Grid.Item>
          <PaginationWidget
            pages={lateAdditions}
            corners="top"
            setTransport={setTransport}
          />
        </Grid.Item>
        <Grid.Item>
          <Card corners="top" className="hc-bg-grey07">
            <Tabs
              activeTab={currentActiveTab}
              disableAdaptive={true}
              onTabSelect={(event, tab) => {
                setCurrentActiveTab(tab.name)

                navigate(`/groupedOffers/${tab.name}`)
              }}
            >
              <Tabs.Item as={Link} to={toSampleUrl(wadId)} name="items">
                Items
              </Tabs.Item>
              <Tabs.Item
                as={Link}
                to={`/weeklyad/${wadId}/sample/props`}
                name="props"
              >
                Props
              </Tabs.Item>

              <Tabs.Item active={true} name="late-additions">
                Late Additions
              </Tabs.Item>
            </Tabs>
          </Card>
        </Grid.Item>
        <Grid.Item xs={12}>
          <Card
            elevation={2}
            corners="bottom"
            className="hc-pa-dense"
            style={{ borderBottomRightRadius: 0 }}
          >
            <Grid.Container spacing="dense" justify="center">
              <Grid.Item xs={12}>
                <Heading className="hc-ta-center" size={2}>
                  {weeklyAd?.eventWeek}
                </Heading>
              </Grid.Item>
              {hasEditPrivileges && (
                <Grid.Item>
                  <Button type="primary" onClick={() => setShowAddDialog(true)}>
                    Add Late Addition
                  </Button>
                </Grid.Item>
              )}
              <Grid.Item className="hc-ta-center hc-pb-dense">
                <Button
                  disabled={disableExport}
                  type="secondary"
                  onClick={() =>
                    getExport({
                      setDisable: setDisableExport,
                      url: sampleExportUrl,
                    })
                  }
                  className="hc-bg-contrast-weak"
                >
                  Sample Export
                </Button>
              </Grid.Item>
            </Grid.Container>
            <LateAdditionsTable
              data={lateAdditions}
              setEditLateAddition={setEditLateAddition}
              hasEditPrivileges={hasEditPrivileges}
            />
          </Card>
        </Grid.Item>
        <Grid.Item>
          <PaginationWidget
            corners="bottom"
            pages={lateAdditions}
            setTransport={setTransport}
          />
        </Grid.Item>
      </Grid.Container>
      <EditLateAddition
        editLateAddition={editLateAddition}
        setEditLateAddition={setEditLateAddition}
        deleteLateAddition={deleteLateAddition}
        putLateAddition={putLateAddition}
        hasEditPrivileges={hasEditPrivileges}
      />
      <AddLateAddition
        showAddDialog={showAddDialog}
        setShowAddDialog={setShowAddDialog}
        postLateAddition={postLateAddition}
        weeklyAdId={weeklyAd.id}
      />
      <LoadingSpinner show={loading || wadLoading} />
    </div>
  )
}
export default LateAdditionsView
