import { mapPmm } from '../../util/mapPmmHelper'

export const groups = {
  'APP-OAUTH2-CrtvHub-CopyWriter': 'Copywriter',
  'APP-OAUTH2-CrtvHub-CreativeArt': 'CreativeArt',
}

export const formatContacts = (contactsByEntitlement) => {
  const flatContactDetails = {}
  contactsByEntitlement?.forEach((entitlement = {}) => {
    const { group = '' } = entitlement
    const listofMemberNames = []
    entitlement?.members?.forEach((contact) => {
      const { display_name } = contact
      if (display_name === 'cochubcrtuser') {
        return
      }
      listofMemberNames.push(display_name)
    })
    if (group) {
      flatContactDetails[groups[group]] = [...mapPmm(listofMemberNames)]
    }
  })
  return flatContactDetails
}
