import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect, useRef, useMemo, useContext } from 'react'

import OfferApi from '../../api/creative-hub/OfferApi'
import { CanEditContext } from '../../context/Context'
import DigitalImagesApi from '../../groupedOffers/api/DigitalImagesApi'
import ItemApi from '../api/projectExecution/ItemApi'
import { fetchAllPicturedItems, sortItemsAndAddDetails } from '../util/ItemUtil'

import Item from './Item'

import '../scss/Bucket.scss'

const ItemBucket = (props) => {
  const {
    isDeactivated,
    selectedOffer,
    setIsItemImageModalOpen,
    setItemSelected,
    updatedItemImage,
    selectedStoryId,
    setUpdatedOffers,
    setChangesMade,
  } = props
  const { canEdit } = useContext(CanEditContext)
  const [itemElements, setItemElements] = useState([])
  const [isArtPatch, setIsArtPatch] = useState(false)
  const [isMixedDiscount, setIsMixedDiscount] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const canBeEdited = canEdit && !isDeactivated
  const itemApi = useMemo(() => new ItemApi(), [])

  const apiImages = useMemo(() => new DigitalImagesApi(), [])

  useEffect(() => {
    if (
      selectedOffer &&
      (selectedOffer?.ad_patches?.length > 0) & !selectedOffer.deleted
    ) {
      const { ad_patches = [], id = '' } = selectedOffer
      const picturedItemsList = fetchAllPicturedItems(selectedOffer)
      setIsArtPatch(ad_patches.length > 1)
      setIsMixedDiscount(selectedOffer.mixed_discount)
      setLoading(true)
      itemApi
        .postItemsByOfferUUID(id, picturedItemsList, selectedStoryId)
        .then((response) =>
          setItemElements(
            sortItemsAndAddDetails(
              response,
              picturedItemsList,
              selectedOffer.ad_patches,
              selectedOffer.ivy_offer_id,
              selectedOffer.pyramid_name
            )
          )
        )
        .finally(setLoading(false))
    } else {
      setLoading(false)
    }
  }, [apiImages, itemApi, selectedOffer, updatedItemImage, selectedStoryId])

  const draggingItem = useRef()
  const dragOverItem = useRef()
  const offerApi = new OfferApi()

  const handleDragStart = (event, position) => {
    event.stopPropagation()
    event.dataTransfer.setData('drag-item', true)
    draggingItem.current = position
  }

  const dragEnter = (event) => {
    event.dataTransfer.types.includes('drag-item') && event.preventDefault()
  }

  const dragOver = (event) => {
    event.dataTransfer.types.includes('drag-item') && event.preventDefault()
  }

  const handleDrop = (event, dropPosition) => {
    if (event.dataTransfer.types.includes('drag-item')) {
      dragOverItem.current = dropPosition
      const itemsCopy = [...itemElements]
      const draggingItemContent = itemsCopy[draggingItem.current]
      if (
        itemsCopy[dragOverItem.current] &&
        itemsCopy[draggingItem.current] &&
        dragOverItem.current !== draggingItem.current
      ) {
        itemsCopy.splice(draggingItem.current, 1)
        itemsCopy.splice(dragOverItem.current, 0, draggingItemContent)
        let end, start
        if (draggingItem.current < dragOverItem.current) {
          start = draggingItem.current
          end = dragOverItem.current
        } else {
          start = dragOverItem.current
          end = draggingItem.current
        }
        //only update changed indicies
        let updatedItemsList = []
        for (let index = start; index <= end; index++) {
          itemsCopy[index].item_order = index + 1
          itemsCopy[index].id = itemsCopy[index].item_id
          updatedItemsList.push(itemsCopy[index])
        }
        offerApi.updateOfferItems(updatedItemsList)
        setItemElements(itemsCopy)
      }
    }
  }
  return isLoading ? (
    <ProgressBar indeterminate />
  ) : (
    <>
      <div data-testid="ItemsBucket">
        {itemElements?.map((picturedItem, index) => (
          <div
            className="bucketOfferItem"
            key={'item-' + index}
            data-testid={'itemCards'}
            draggable={!isExpanded}
            onDragStart={
              canBeEdited ? (event) => handleDragStart(event, index) : null
            }
            onDragEnter={dragEnter}
            onDragOver={dragOver}
            onDrop={(event) => handleDrop(event, index)}
          >
            <Item
              picturedItem={picturedItem}
              canEdit={canBeEdited}
              setIsExpanded={setIsExpanded}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setItemSelected={setItemSelected}
              isArtPatch={isArtPatch}
              isMixedDiscount={isMixedDiscount}
              updatedItemImage={updatedItemImage}
              pricePatches={selectedOffer.price_patches}
              itemElements={itemElements}
              selectedStoryId={selectedStoryId}
              setUpdatedOffers={setUpdatedOffers}
              setChangesMade={setChangesMade}
            ></Item>
          </div>
        ))}
      </div>
    </>
  )
}

export default ItemBucket
