import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SortableHandle } from 'react-sortable-hoc'

const DragHandle = SortableHandle(() => (
  <span className="hc-pa-dense">
    <FontAwesomeIcon icon={faBars} />
  </span>
))
export default DragHandle
