import * as yup from 'yup'

import PublishingApi from '../../api/creative-hub/PublishingApi'
import UmbrellaApi from '../../api/creative-hub/UmbrellaApi'
import DigitalImagesApi from '../api/DigitalImagesApi'

export const findNumbers = (inputString) => {
  let extractArray = []
  let pattern = new RegExp('^\\d+$')
  let textArray = inputString.trim().match(/\b(\w+)\b/g)
  textArray?.map((word) => {
    if (pattern.test(word)) {
      extractArray.push(word)
    }
    return null
  })
  return extractArray
}

export const validateOffers = async (
  offerIdString,
  values,
  setFieldValue,
  setIsLoading
) => {
  const publishingApi = new PublishingApi()

  //parse the user input data
  const offerIdArray = [...new Set(findNumbers(offerIdString))]

  //strings !== 9 digits are not valid offer ids
  let newInvalidOfferIdArray = []
  offerIdArray?.filter((word) =>
    word.length !== 9 ? newInvalidOfferIdArray.push(word) : null
  )

  //offer ids verified go in array, invalid ids get added to invalid array
  const idsForApi = offerIdArray.filter((word) => word.length === 9)
  const response = await publishingApi.getOffers(idsForApi.join(','))

  let newVerifiedOfferIdArray = []
  let newBoostedIds = []
  let startDates = []
  let endDates = []
  for (const offerId of idsForApi) {
    //if found in response, its valid. Add to validated array
    //if not found in response, its invalid, add to the invalid array
    const found = response.find(
      (validOffer) => validOffer.id === parseInt(offerId)
    )
    found
      ? newVerifiedOfferIdArray.push(found)
      : newInvalidOfferIdArray.push(offerId)

    if (found?.start_date) {
      startDates.push(new Date(found.start_date).toISOString().split('T')[0])
    }

    if (found?.end_date) {
      endDates.push(new Date(found.end_date).toISOString().split('T')[0])
    }

    //get boosted tcins
    if (found?.ad_patches && found?.ad_patches?.length > 0) {
      const ad_patches = found?.ad_patches
      for (const patch of ad_patches) {
        if (patch.pictured_items) {
          for (const item of patch.pictured_items) {
            if (newBoostedIds.length < 40) {
              newBoostedIds.push(item.tcin)
            }
          }
        }
      }
    }
  }

  endDates.sort((a, b) => {
    const aa = a.split('-').reverse().join()
    const bb = b.split('-').reverse().join()
    return aa < bb ? -1 : aa > bb ? 1 : 0
  })

  startDates.sort((a, b) => {
    const aa = a.split('-').join()
    const bb = b.split('-').join()
    return aa < bb ? -1 : aa > bb ? 1 : 0
  })

  setFieldValue('startDate', startDates[0])
  setFieldValue('endDate', endDates[0])
  setFieldValue('verifiedOffers', newVerifiedOfferIdArray)
  setFieldValue('invalidOfferIds', newInvalidOfferIdArray)
  setFieldValue('boostedIds', newBoostedIds.join(', '))
  handleChangeBoostedIds(
    newBoostedIds,
    values.selectedBoostedIds,
    setFieldValue
  )
  setIsLoading(false)
}

export const validateUmbrella = async (
  umbrellaIdString,
  values,
  setFieldValue,
  setIsLoading
) => {
  const umbrellaApi = new UmbrellaApi()

  const umbrellaIdArray = findNumbers(umbrellaIdString)
  if (umbrellaIdArray.length > 0) {
    let offers = ''
    for (const id of umbrellaIdArray) {
      const response = await umbrellaApi.getUmbrella(id)
      if (umbrellaIdArray.length === 1) {
        setFieldValue('groupTitle', response.name)
      }
      if (response.offers) {
        offers += response.offers.join(', ') + ', '
      }
    }
    setFieldValue('offerIds', offers)
    validateOffers(offers, values, setFieldValue, setIsLoading)
  }
}

export const handleChangeBoostedIds = async (
  boostedIds,
  selectedBoostedIds,
  setFieldValue,
  setBoostedIds
) => {
  const apiImages = new DigitalImagesApi()

  let response = await apiImages.getDigitalImages(boostedIds).then((res) => res)

  const newBoostedIds = boostedIds.map((tcin) => {
    let foundInSelected = selectedBoostedIds?.find(
      (boostedId) => boostedId.id === tcin
    )
    const foundInResponse = response?.find(
      (boostedId) => boostedId?.tcin === tcin
    )
    const responseUrl = foundInResponse
      ? `https:` +
        foundInResponse.digitalAssets?.images?.baseUrl +
        foundInResponse.digitalAssets?.images?.primaryImage
      : null
    // if not found in selectedBoostedIds, set the defaults
    if (!foundInSelected) {
      return {
        checked: responseUrl ? true : false,
        id: tcin,
        url:
          responseUrl ||
          `https://target.scene7.com/is/image/Target/dwa_FC_Placeholder`,
      }
    } else {
      // if found in selectedBoostedIds, track the checked status
      return {
        checked: foundInSelected.checked || false,
        id: foundInSelected.id,
        url:
          responseUrl ||
          `https://target.scene7.com/is/image/Target/dwa_FC_Placeholder`,
      }
    }
  })
  if (typeof setBoostedIds === 'function') {
    setBoostedIds(newBoostedIds)
  } else {
    setFieldValue('selectedBoostedIds', newBoostedIds)
  }
}

export const editGroupedOfferFormSchema = yup.object().shape({
  boosted_tcins: yup
    .string()
    .nullable()
    .trim()
    .matches(
      /^[0-9]+(\s*,\s*[0-9]+)*$/,
      'Boosted Tcins must be 8 digits and seperated by a comma'
    ),
  offer_ids: yup
    .string()
    .nullable()
    .trim()
    .matches(
      /^[0-9]+(\s*,\s*[0-9]+)*$/,
      'Offers must be 9 digits and seperated by a comma'
    )
    .required('Grouped Offers must have at least one offer'),
})

export const createGroupOfferSchema = yup.object().shape({
  boostedIds: yup.string().nullable(),
  //.max(399, 'Limit of 399 characters,  please remove a tcin'),
  groupImageURL: yup.string().nullable().url('Must be a valid URL'),
  offerIds: yup
    .string()
    .nullable()
    .test(
      'valid offer ids',
      `All offer ids must be valid.  Please update the following invalid offer ids: `,
      (value, testContext) =>
        testContext.parent.invalidOfferIds?.length > 0 ? false : true
    )
    .required('Required'),
})
