import { Modal, Card, Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'

import ProductApi from '../api/pop-catalog/ProductApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { STATUS_LIST } from '../util/FormConstants'
import ReplaceFirst from '../util/ReplaceFirst'
import '../scss/modal_size.scss'

const AssignProductToPage = (props) => {
  const {
    assignProduct = {},
    setAssignProduct,
    pages = [],
    setProducts,
  } = props

  const [availablePages, setAvailablePages] = useState([])
  const productApi = new ProductApi()

  useEffect(() => {
    const pageFilter = pages.filter((page) =>
      page.category?.includes(assignProduct?.product?.category)
    )
    setAvailablePages(pageFilter)
    // eslint-disable-next-line
  }, [pages, assignProduct])

  const wipeState = () => {
    setAssignProduct({ body: {}, show: false })
    setAvailablePages([])
  }
  const { product = {}, show = false } = assignProduct
  const { page_id, section, status } = product
  return !isEmpty(availablePages) ? (
    <Formik
      enableReinitialize
      initialValues={{
        checked: page_id,
        section: section,
        status: status
          ? STATUS_LIST.find((stat) => stat.value === status).value
          : 'pending',
      }}
      onSubmit={(values, funcs) => {
        const { checked, section, status } = values
        productApi
          .putProduct({
            id: product.id,
            last_updated_timestamp: product.last_updated_timestamp,
            page_id: checked ? checked : null,
            section: section,
            status: status,
          })
          .then((product) =>
            setProducts((oldProducts) => {
              const newProds = [...oldProducts]

              ReplaceFirst(newProds, product.id, product)
              return newProds
            })
          )
          .catch((err) => console.log(err))
        funcs.resetForm()
        wipeState()
      }}
    >
      {({ values, setFieldValue, handleSubmit, handleReset }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            className="larger_modal"
            isVisible={show}
            onRefuse={handleDialogClose}
            headingText={
              values?.status === 'accepted'
                ? `Pages Available to Assign Product  ${
                    product?.dpci + ': ' + product?.name
                  }`
                : `Product Submission Status ${
                    product?.dpci + ': ' + product?.name
                  }`
            }
          >
            <div className="hc-pa-dense new-modal">
              <Grid.Item className="hc-ta-center" xs={12}>
                {' '}
                <Form.Field
                  id="status"
                  type="select"
                  placeholder="Status"
                  onUpdate={(id, val) => {
                    if (val !== 'accepted') {
                      setFieldValue('checked', '')
                    }
                    setFieldValue(id, val)
                  }}
                  value={values.status}
                  options={STATUS_LIST}
                />
              </Grid.Item>
              <Grid.Item xs={12}>
                <Card
                  className="hc-bg-grey07 hc-pa-dense hc-ma-dense"
                  key={'asfaseas'}
                >
                  <Grid.Container
                    spacing="dense"
                    direction="row"
                    justify="flex-start"
                  >
                    {values?.status === 'accepted' &&
                      availablePages.map((page = {}, index) => (
                        <Grid.Item xs={2} xm={1} lg={1}>
                          <Form.Field
                            id={`${page.id}-${index}`}
                            type="checkbox"
                            checked={values.checked.includes(page.id)}
                            label={`${page.pageOrdering + 1}`}
                            value={page.id}
                            onChange={(event) => {
                              event.target.checked
                                ? values.checked.push(
                                    parseInt(event.target.value)
                                  )
                                : values.checked.splice(
                                    values.checked.indexOf(
                                      parseInt(event.target.value)
                                    ),
                                    1
                                  )
                              setFieldValue('checked', values.checked)
                              if (page.section.length > 1) {
                                setFieldValue('section', page.section[0])
                              }
                            }}
                          />
                        </Grid.Item>
                      ))}
                  </Grid.Container>
                </Card>
              </Grid.Item>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                canDelete={false}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : (
    <Modal
      isVisible={show}
      onRefuse={wipeState}
      headingText={`No pages available to assign ${
        product?.dpci + ': ' + product?.name
      }`}
    />
  )
}
export default AssignProductToPage
