import { Grid, Tooltip, Button } from '@enterprise-ui/canvas-ui-react'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import appConfig from '../config/appConfig'

import DragHandle from './DragHandle'

const DWAOffer = (props) => {
  const { offer, lockedOut } = props
  const config = appConfig()
  const linkProps = {
    href: `${config.ivy.uiUrl}promotion/${offer.offerId}/edit/general`,
    target: '_blank',
  }
  const {
    priority,
    offerId,
    pageName,
    storyName,
    storyPriority,
    cutName,
    cutPriority,
  } = offer

  return (
    <Grid.Container
      className="hc-ta-center"
      spacing="none"
      justify="center"
      align="center"
    >
      {lockedOut && <Grid.Item xs={1}>{<DragHandle />}</Grid.Item>}
      <Grid.Item xs={2}>{`Priority ${priority + 1}`}</Grid.Item>
      <Grid.Item xs={2}>
        <strong>{`${pageName}`}</strong>
      </Grid.Item>

      <Grid.Item xs={2}>
        <strong>{`Story ${storyPriority + 1}: `}</strong>
        {`${storyName}`}
      </Grid.Item>
      <Grid.Item xs={2}>
        {' '}
        <strong>{`Cut ${cutPriority + 1}: `}</strong>
        {`${cutName}`}
      </Grid.Item>

      <Grid.Item xs={2}>Offer: {offerId}</Grid.Item>
      <Grid.Item xs={1}>
        <Tooltip content={`${config.ivy.uiUrl}`} location="top">
          <Button className="hc-bg-success" raised icon as="a" {...linkProps}>
            <FontAwesomeIcon icon={faLeaf} />
          </Button>
        </Tooltip>
      </Grid.Item>
    </Grid.Container>
  )
}
export default DWAOffer
