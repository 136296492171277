export const formatDateForDb = (dateString) => {
  const dateStringArray = dateString?.split('/')
  return `${dateStringArray[2]}-${dateStringArray[0]}-${dateStringArray[1]}`
}

export const formatDateForApi = (dateString) => {
  const dateStringNoTimestamp = dateString?.split('T')
  const dateStringArray = dateStringNoTimestamp[0]?.split('-')
  return `${dateStringArray[1]}-${dateStringArray[2]}-${dateStringArray[0]}`
}

export const formatDateForCanvas = (dateString) => {
  if (dateString) {
    const dateStringNoTimestamp = dateString?.split('T')
    const dateStringArray = dateStringNoTimestamp[0]?.split('-')
    return `${dateStringArray[1]}/${dateStringArray[2]}/${dateStringArray[0]}`
  } else return null
}

export const deriveNoOfDaysFromCurrentDate = (dateString) => {
  let currentDate = new Date()
  let inputDate = new Date(dateString)

  // Convert dates to UTC timestamps
  let currentDateUTC = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  )
  let inputDateUTC = Date.UTC(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate()
  )

  // Calculate the time difference in milliseconds
  let timeDiff = Math.abs(inputDateUTC - currentDateUTC)

  // Convert milliseconds to days
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
}
