import { Dialog, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

const EditLateAddition = (props) => {
  const {
    setEditLateAddition,
    putLateAddition,
    deleteLateAddition,
    editLateAddition: { show, body: editLateAddition },
  } = props

  const [isDeleteDialog, setDeleteDialog] = useState(false)

  let editLateAdditionSchema = yup.object().shape({
    edit_lateItem_dpci: yup.string().matches(/^(?:\d{3}-\d{2}-\d{4})?$/, {
      message: 'DPCI in format 000-00-0000',
    }),
    edit_lateItem_sampleId: yup.string().when('edit_lateItem_sample', {
      is: (sample) => sample === 'inventory',
      otherwise: yup.string(),
      then: yup.string().required('SampleID required when status: inventory'),
    }),
  })

  const wipeState = () => {
    setEditLateAddition({
      body: {},
      show: false,
    })
  }

  const onDelete = () => {
    deleteLateAddition({ id: editLateAddition.id })
    wipeState()
    setDeleteDialog(false)
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={editLateAdditionSchema}
      initialValues={{
        edit_lateItem_description: editLateAddition.description || '',
        edit_lateItem_dpci: editLateAddition.dpci || '',
        edit_lateItem_jiraFormNumber: editLateAddition.jiraFormNumber || '',
        edit_lateItem_notes: editLateAddition.notes || '',
        edit_lateItem_pageNumber: editLateAddition.pageNumber || '',
        edit_lateItem_sample: editLateAddition.sample || '',
        edit_lateItem_sampleId: editLateAddition.sampleId || '',
        edit_lateItem_samplePulled:
          editLateAddition.samplePulled || editLateAddition.samplePulled === 0
            ? editLateAddition.samplePulled
            : -1,
      }}
      onSubmit={(values, funcs) => {
        putLateAddition({
          description: values.edit_lateItem_description,
          dpci: values.edit_lateItem_dpci,
          id: editLateAddition.id,
          jiraFormNumber: values.edit_lateItem_jiraFormNumber,
          lastUpdatedTimestamp: editLateAddition.lastUpdatedTimestamp,
          notes: values.edit_lateItem_notes,
          pageNumber: values.edit_lateItem_pageNumber,
          sample: values.edit_lateItem_sample,
          sampleId: values.edit_lateItem_sampleId,
          samplePulled: values.edit_lateItem_samplePulled,
        })
        wipeState()
        funcs.resetForm()
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }

        return (
          <Modal
            isVisible={show}
            headingText={`Edit ${editLateAddition.description}`}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <Grid.Container spacing="dense" align="center">
                <Grid.Item xs={6}>
                  <Form.Field
                    id="edit_lateItem_pageNumber"
                    value={values.edit_lateItem_pageNumber}
                    label="Page no"
                    onChange={handleChange}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="edit_lateItem_dpci"
                    value={values.edit_lateItem_dpci}
                    label="DPCI"
                    onChange={handleChange}
                    error={errors.edit_lateItem_dpci}
                    errorText={errors.edit_lateItem_dpci}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    id="edit_lateItem_description"
                    value={values.edit_lateItem_description}
                    label="Item Name"
                    onChange={handleChange}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="edit_lateItem_sample"
                    label="Sample Status"
                    value={values.edit_lateItem_sample}
                    onUpdate={setFieldValue}
                    options={[
                      { label: '', value: '' },
                      { label: 'Inventory', value: 'inventory' },
                      { label: 'Turn-in', value: 'turnIn' },
                      { label: 'Missing', value: 'missing' },
                    ]}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="edit_lateItem_sampleId"
                    value={values.edit_lateItem_sampleId}
                    label="Sample ID"
                    onChange={handleChange}
                    error={errors.edit_lateItem_sampleId}
                    errorText={errors.edit_lateItem_sampleId}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="edit_lateItem_samplePulled"
                    label="Sample/Image Collected"
                    value={values.edit_lateItem_samplePulled}
                    options={[
                      { label: '', value: -1 },
                      { label: 'No', value: 0 },
                      { label: 'Yes', value: 1 },
                      { label: 'Canceled', value: 2 },
                    ]}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="edit_lateItem_jiraFormNumber"
                    value={values.edit_lateItem_jiraFormNumber}
                    label="Jira Form Number"
                    onChange={handleChange}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    id="edit_lateItem_notes"
                    value={values.edit_lateItem_notes}
                    label="Notes"
                    onChange={handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                handleDelete={setDeleteDialog}
              />
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText="Are you sure want to delete this late item?"
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText="Delete"
            />
          </Modal>
        )
      }}
    </Formik>
  )
}
export default EditLateAddition
