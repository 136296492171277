import {
  Card,
  Grid,
  Heading,
  Button,
  Form,
  Divider,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import isEmpty from 'lodash.isempty'
import { useState, useEffect } from 'react'

import CatalogApi from '../../api/pop-catalog/CatalogApi'
import ExportApi from '../../api/pop-catalog/ExportApi'
import ProductApi from '../../api/pop-catalog/ProductApi'
import authUtil from '../../components/Auth/authUtil'
import appConfig from '../../config/appConfig'
import AddProduct from '../../forms/AddProduct'
import ProductList from '../../tables/ProductList'
import { VENDOR_EXPORT_URL } from '../../util/urlUtil'
// Vendor Screen
export const VendorView = (props) => {
  const { role } = props
  const [loading, setLoading] = useState(false)

  const query = new URLSearchParams()
  const auth = useAuth()
  const { session } = auth
  const isSessionValid = authUtil(session)

  const config = appConfig()

  const [productData, setProductData] = useState({})
  const [addProduct, setAddProduct] = useState({})
  const [catalogs, setCatalogs] = useState([{ id: -1, label: '', value: -1 }])

  const productApi = new ProductApi()
  const catalogApi = new CatalogApi()
  const exportApi = new ExportApi()

  useEffect(() => {
    setLoading(true)
    catalogApi
      .getCatalogBatch(new URLSearchParams())
      .then((res) => {
        const catalogData = [{ id: -1, label: '', value: -1 }].concat(
          res?.catalogs?.map((catalog) => ({
            id: catalog?.id,
            label: catalog?.name,
            value: catalog?.id,
          }))
        )
        setCatalogs(catalogData)
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [setCatalogs, setLoading])

  const vendorName = session.identity.company_name
  const vendorContactName =
    session.identity.firstname + ' ' + session.identity.lastname
  const vendorContactEmail = session.identity.mail

  const [currentCatalogId, setCurrentCatalogId] = useState(-1)

  const [products, setProducts] = useState([])

  return productData && isSessionValid ? (
    <>
      <div className="hc-pa-normal">
        <Card>
          <Grid.Container justify="flex-end">
            <Grid.Item className="hc-ta-center" xs={12}>
              <Heading size={3}>Catalog Product Submission</Heading>
              <Divider width={2} />
              <div className="hc-pa-normal">
                <Heading size={4}>{vendorName}</Heading>
              </div>
            </Grid.Item>

            <Grid.Container justify="center">
              <Grid.Item xs={3}>
                <Form.Field
                  type="select"
                  id="currentCatalog"
                  hintText="Choose Catalog"
                  value={currentCatalogId}
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                  onUpdate={(_, value) => {
                    setCurrentCatalogId(value)
                    setLoading(true)
                    setProducts({})
                    productApi
                      .getProductBatch(query, value)
                      .then((res) => {
                        setProductData(res)
                        setProducts(res.products)
                      })
                      .catch((e) => console.log(e))
                      .finally(() => setLoading(false))
                  }}
                  options={catalogs}
                />
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="primary"
                  disabled={currentCatalogId < 0}
                  onClick={() =>
                    setAddProduct({
                      catalogId: currentCatalogId,
                      show: true,
                      vendorContactEmail: vendorContactEmail,
                      vendorContactName: vendorContactName,
                      vendorName: vendorName,
                    })
                  }
                >
                  Add Product
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="secondary"
                  className="hc-ml-expanded"
                  onClick={() => {
                    const newQ = new URLSearchParams([
                      ['vendorName', vendorName],
                    ])
                    newQ.set('key', config.catapi.apikey)
                    exportApi.getExport(
                      VENDOR_EXPORT_URL(currentCatalogId) + newQ.toString()
                    )
                  }}
                  disabled={currentCatalogId === -1}
                >
                  Export
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Container>
          <Grid.Container>
            {' '}
            <Grid.Item xs={12}>
              {currentCatalogId === -1 ? (
                <Heading className="hc-ta-center hc-pt-expanded">
                  Please select a catalog
                </Heading>
              ) : loading ? (
                <Grid.Container xs={12} justify="space-around">
                  <Grid.Item>
                    <Spinner />
                  </Grid.Item>
                </Grid.Container>
              ) : isEmpty(products) ? (
                <Heading className="hc-ta-center hc-pt-expanded">
                  No products have been submitted for this catalog.
                </Heading>
              ) : (
                <div className="hc-pa-normal">
                  <ProductList
                    products={products}
                    setProducts={setProducts}
                    role={role}
                    view="vendor"
                  />
                </div>
              )}
            </Grid.Item>
            <AddProduct
              addProduct={addProduct}
              setAddProduct={setAddProduct}
              setProducts={setProducts}
              isVendor={true}
              view="vendor"
            />
          </Grid.Container>
        </Card>
      </div>
    </>
  ) : null
}
