import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class PublishingApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_publishing_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getMarketOptions = async () => {
    try {
      const marketOptions = await this.init_publishing_api().get(
        `${this.config.markethub.url}market`
      )
      return marketOptions.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }
  getCategoryFilters = async () => {
    try {
      const channelOptions = await this.init_publishing_api().get(
        `${this.config.markethub.url}category`
      )
      return channelOptions.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  getCategoriesByProjectId = async (projectId) => {
    try {
      const channelOptions = await this.init_publishing_api().get(
        `${this.config.markethub.url}project_categories/${projectId}`
      )
      return channelOptions.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  getMediaUnitCountsByCategory = async (projectUuid) => {
    try {
      const mediaUnit = await this.init_publishing_api().get(
        `${this.config.markethub.url}media_unit/count/${projectUuid}`
      )
      return mediaUnit.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  getMediaUnitDetails = async (mediaUnitUuid, categoryId) => {
    try {
      const mediaUnit = await this.init_publishing_api().get(
        `${this.config.markethub.url}media_unit/details/${mediaUnitUuid}/${categoryId}`
      )
      return mediaUnit.data
    } catch (e) {
      let errorObj = {
        error: true,
        guest_facing:
          'There was a issue retrieving the data.   Please try again or contact your support team!',
        status: e.response && e.response.status ? e.response.status : e,
      }
      console.log(errorObj)
      return errorObj
    }
  }

  getMediaUnitsLiteUser = async (projectUuid, categoryId) => {
    try {
      const mediaUnit = await this.init_publishing_api().get(
        `${this.config.markethub.url}media_unit/lite/user/${projectUuid}/${categoryId}`
      )
      return mediaUnit.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  getMediaUnitsLite = async (projectUuid, categoryId) => {
    try {
      const mediaUnit = await this.init_publishing_api().get(
        `${this.config.markethub.url}media_unit/lite/${projectUuid}/${categoryId}`
      )
      return mediaUnit.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  createGroupedOffer = async (data) => {
    try {
      const newMediaUnit = await this.init_publishing_api().post(
        `${this.config.markethub.url}media_unit`,
        data
      )
      return newMediaUnit.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  getOffers = async (ivyOfferIds) => {
    try {
      const ivyOffer = await this.init_publishing_api().get(
        `${this.config.markethub.url}offer?offerIds=${ivyOfferIds.toString()}`
      )
      return ivyOffer.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  createMediaUnit = async (
    order,
    projectId,
    categories,
    channels,
    startDate,
    endDate
  ) => {
    const data = {
      categories: categories,
      channels: channels,
      end_date: endDate,
      order,
      project_id: projectId,
      start_date: startDate,
    }
    try {
      const newMediaUnit = await this.init_publishing_api().post(
        `${this.config.markethub.url}media_unit`,
        data
      )
      return newMediaUnit.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  updateGroupedOffer = async (updatedGroupedOffer, groupedOfferId) => {
    try {
      const updatedGroupedOfferData = await this.init_publishing_api().put(
        `${this.config.markethub.url}media_unit/grouped_offer/${groupedOfferId}`,
        updatedGroupedOffer
      )
      return updatedGroupedOfferData.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  updateProjectCategory = async (
    updatedProjectCategory,
    categoryId,
    projectId
  ) => {
    try {
      const updatedCategoryData = await this.init_publishing_api().put(
        `${this.config.markethub.url}project_categories/${categoryId}/${projectId}`,
        updatedProjectCategory
      )
      return updatedCategoryData.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  updateMediaUnits = async (updatedMediaUnits, categoryTypeId) => {
    try {
      const updatedMediaUnitData = await this.init_publishing_api().put(
        `${this.config.markethub.url}media_unit/${categoryTypeId}`,
        updatedMediaUnits
      )
      return updatedMediaUnitData.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  reorderMediaUnits = async (reorderMediaUnits, currentCategoryId) => {
    try {
      const data = reorderMediaUnits
      const updatedMediaUnitData = await this.init_publishing_api().put(
        `${this.config.markethub.url}media_unit/${currentCategoryId}`,
        data
      )

      return updatedMediaUnitData.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  createNewVersion = async (parentUUid, categoryId, data) => {
    try {
      await this.init_publishing_api().post(
        `${this.config.markethub.url}media_unit/${parentUUid}/${categoryId}`,
        data
      )
      return true
    } catch (e) {
      console.log('Error: ', e)
      return false
    }
  }

  deleteGroupedOffer = async (groupedOfferId) => {
    try {
      const deletedMediaUnit = await this.init_publishing_api().delete(
        `${this.config.markethub.url}media_unit/grouped_offer/${groupedOfferId}`
      )
      return deletedMediaUnit.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  deleteMediaUnit = async (mediaUnitUUid, categoryId, projectId) => {
    try {
      const deletedMediaUnit = await this.init_publishing_api().delete(
        `${this.config.markethub.url}media_unit/${mediaUnitUUid}/${categoryId}`,
        {
          data: {
            id: mediaUnitUUid,
            project_id: projectId,
          },
        }
      )
      return deletedMediaUnit.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }
}
