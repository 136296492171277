import cutMapper from './cutMapper'

const storyMapper = (story) => ({
  cuts: story.cuts
    ? story.cuts
        .map(cutMapper)
        .filter((cut) => cut.deleted === false)
        .sort((a, b) => a.priority - b.priority)
    : [],
  deleted: story.deleted,
  id: story.id,
  lastUpdatedTimestamp: story.lastUpdatedTimestamp,
  name: story.name,
  pageId: story.pageId,
  priority: story.priority,
  promoMessaging: story.promoMessaging,
})
export default storyMapper
