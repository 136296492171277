import { Grid, Modal, Chip } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Formik } from 'formik'
import * as yup from 'yup'
import '../scss/modal_size.scss'

import CatalogApi from '../api/pop-catalog/CatalogApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { CATEGORY_LIST } from '../util/CategoryColorMap'

let pageSchema = yup.object().shape({
  category: yup.array(),
  section: yup.array(),
})

const AddCatalogPage = (props) => {
  const { catalogId, show, setShow, setCatalog } = props
  const catalogApi = new CatalogApi()
  const wipeState = () => {
    setShow(false)
  }

  return (
    show && (
      <Formik
        initialValues={{
          category: [
            {
              color: 'green',
              id: 0,
              label: 'Toys',
              value: 'Toys',
            },
          ],
          section: [],
        }}
        validationSchema={pageSchema}
        onSubmit={(values, funcs) => {
          const sectionValue = values?.section?.map((section) => section.value)
          const categoryValue = values?.category.map(
            (category) => category.value
          )
          catalogApi
            .postCatalogPage(catalogId, {
              category: categoryValue,
              section: sectionValue,
            })
            .then((response) => setCatalog(response))
          funcs.resetForm()
          wipeState()
        }}
      >
        {({ values, errors, handleSubmit, handleReset, setFieldValue }) => {
          const handleDialogClose = () => {
            handleReset()
            wipeState()
          }

          return (
            <Modal
              isVisible={show}
              headingText="Add Page"
              onRefuse={handleDialogClose}
              className="show_overflow"
            >
              <div className="hc-pa-normal">
                <Grid.Container spacing="dense">
                  <Grid.Container spacing="dense">
                    <Grid.Item md={6} xs={12}>
                      <Autocomplete
                        id="category"
                        chipHeight="dense"
                        label="Categories"
                        onUpdate={(_, value) =>
                          setFieldValue('category', value)
                        }
                        options={CATEGORY_LIST}
                        error={errors.category}
                        errorText={errors.category}
                        multiselect
                        value={values.category}
                        renderChip={(cat, onRequestDelete) => (
                          <Chip
                            size="dense"
                            style={{
                              backgroundColor: cat.color,
                              border: '0px',
                              color: 'white',
                            }}
                            onRequestDelete={onRequestDelete}
                          >
                            {cat.value}
                          </Chip>
                        )}
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Autocomplete
                        id="section"
                        chipHeight="dense"
                        label="Section"
                        className="hc-mb-dense hc-mt-dense"
                        onUpdate={(_, value) => setFieldValue('section', value)}
                        options={values.section}
                        value={values.section}
                        multiselect
                        noResultsMessage="Type in the page section here"
                        allowCustomInputValue
                        placeholder="Insert 1-2 Sections"
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Container>
                <CancelSubmitButtons
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                  canDelete={false}
                />
              </div>
            </Modal>
          )
        }}
      </Formik>
    )
  )
}
export default AddCatalogPage
