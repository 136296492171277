import { sortBy } from 'lodash'
import Moment from 'moment'

import appConfig from '../../config/appConfig'
import { formatToJavaScriptDate } from '../../util/MomentUtil'

const config = appConfig()

export const offerSort = (offers = []) =>
  sortBy(offers, (a) => a.offer_order) ?? []

export const getOnlineOnlyOffers = (variableProfitability = {}) => {
  const { associated_offer_ids = [], strategy = '' } = variableProfitability
  if (strategy === 'NO_SHIPPING') {
    return associated_offer_ids.join(', ')
  }

  return ''
}

export const copyOfferLink = ({ ivyOfferId, name }) =>
  window.navigator.clipboard.write([
    new window.ClipboardItem({
      'text/html': new Blob(
        [
          `<a href="${config.ivy.uiUrl}promotion/${ivyOfferId}/edit/general">${name}</a>`,
        ],
        { type: 'text/html' }
      ),
    }),
  ])

export const checkOfferDatesAgainstProjectDates = (
  offerStart,
  offerEnd,
  projectStart,
  projectEnd
) => {
  if (offerStart && offerEnd && projectStart && projectEnd !== undefined) {
    const offStart = new Date(offerStart)
    const offEnd = new Date(offerEnd)
    const projStart = new Date(projectStart)
    const projEnd = new Date(projectEnd)

    if (offStart.toLocaleDateString() !== projStart.toLocaleDateString()) {
      return true
    } else if (offEnd.toLocaleDateString() !== projEnd.toLocaleDateString()) {
      return true
    } else {
      return false
    }
  }
  return
}

export const generateOfferCommentEmaillink = (
  projectId = '',
  pyramidId = '',
  storyId = '',
  umbrellaId = '',
  offerId = ''
) => {
  if (!!projectId && !!pyramidId && !!storyId && !!umbrellaId && !!offerId) {
    return `${config.ui.url}/execution/${projectId}/${pyramidId}/${storyId}/${umbrellaId}/${offerId}?canExpandOffer=true`
  }
  return ''
}

export const displayOffersByChannel = (offers = [], appliedChannels = []) => {
  let filteredOffers = []

  if (appliedChannels.length === 0) {
    filteredOffers = offers.map((offer = {}) => {
      offer.eligible_for_change_mgmt = isOfferEligibleForChangeMgmt(offer)
      return offer
    })
    return filteredOffers
  }

  const appliedChannelIds = appliedChannels.map((channel) => channel.id)
  offers?.forEach((offer = {}) => {
    offer.eligible_for_change_mgmt = isOfferEligibleForChangeMgmt(offer)
    if (offer.ivy_offer_id === -1 || offer.status === 'deactivated') {
      filteredOffers.push(offer)
    } else {
      offer.channels.forEach((channel = {}) => {
        if (
          appliedChannelIds.includes(channel.channel_id) &&
          channel.selected === true &&
          !filteredOffers.includes(offer)
        ) {
          filteredOffers.push(offer)
        }
      })
    }
  })
  return filteredOffers
}

export const canEnableSendEmailButton = (
  projectOffersDueDate,
  offerEndDate,
  isPlaceholderOffer = false
) => {
  if (isPlaceholderOffer) {
    return false
  }

  const today = new Date()
  const todayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  )
  return (
    todayDate > Moment(projectOffersDueDate).toDate() &&
    todayDate <= Moment(offerEndDate).toDate()
  )
}

export const sortedModifiedOffers = (
  updatedOfferList = [],
  currentOfferMap = {}
) => {
  let activeOfferList = []
  let inActiveOfferList = []
  updatedOfferList.forEach((offer, i) => {
    let offerData = currentOfferMap.get(offer.id)
    if (offerData) {
      if (offer.offer_art_patch_leader_id) {
        offerData.offer_art_patch_leader_id = offer.offer_art_patch_leader_id
      } else if (offerData.offer_art_patch_leader_id) {
        delete offerData.offer_art_patch_leader_id
      }

      if (offer.offer_order > -1) {
        offerData.offer_order = offer.offer_order
        activeOfferList.push(offerData)
      } else {
        inActiveOfferList.push(offerData)
      }
    }
  })
  return offerSort(activeOfferList).concat(inActiveOfferList)
}

export const isOfferEligibleForChangeMgmt = (offer = {}) => {
  const { ad_patches = [], priority_tier = '' } = offer
  return (
    priority_tier !== 'STANDARD' ||
    (ad_patches?.length > 0 && ad_patches[0]?.pictured_items?.length > 0)
  )
}

export const checkForAllMarkets = (
  markets = [],
  location_group_id,
  price_patch = [],
  mixed_discount
) => {
  if (location_group_id == 'RW' || location_group_id == 'WR') {
    // only for Dept MSC
    if (!mixed_discount && price_patch?.length > 0) {
      return markets.join(', ')
    } else {
      return 'All'
    }
  } else {
    // default is show exact markets.
    // for CC market, if 15, then show All.
    if (markets?.length === 15) {
      return 'All'
    } else {
      return markets.join(', ')
    }
  }
}

export const isOfferAddedAfterProjectKODate = (
  projectDeadlineDate = new Date(),
  offerAddedDate = new Date()
) =>
  formatToJavaScriptDate(projectDeadlineDate).getTime() + 72000000 <
  new Date(offerAddedDate).getTime()

export const isDisableEmailNotifyButton = (
  sale_handle = false,
  changesMade = false
) => !sale_handle || !changesMade

export const makeOfferDetails = (circle_offer = false, mass_offer = false) => {
  if (circle_offer && mass_offer) return 'Circle-Mass Offer'
  else if (circle_offer) return 'Circle-Segmented Offer'
  else if (mass_offer) return 'Mass Offer'
  else return ''
}
