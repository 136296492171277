import {
  SideNav,
  Card,
  Form,
  Heading,
  Grid,
  Divider,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { SlackIcon } from '@enterprise-ui/icons'
import { useContext, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { EffectiveRoleContext } from '../context/Context'
import creatievHubLogo from '../media/CreativeHubLogo.png'
import {
  atHomePage,
  inCatalogs,
  inDpciSearch,
  inPastCatalogs,
  inWeeklyAd,
} from '../util/CheckLocation'
import {
  isVendor,
  canAccessPlanning,
  canAccessExecution,
  canAccessWeeklyAd,
  canAccessCatalog,
  canAccessVendor,
  canAccessDWAChangeHistory,
  canAccessGroupedOffer,
  canAccessAdminCenter,
  canAccessChangeSummary,
  isAdmin,
  canAccessCatAmp,
} from '../util/CheckRole'
import '../scss/PopNavigation.scss'

const PopNavigation = (props) => {
  const { showSidebar, wadId, toSampleUrl, targetRole, closeSideNav } = props
  const { effectiveRole, setEffectiveRole } = useContext(EffectiveRoleContext)
  // eslint-disable-next-line
  const [targetRoles, setTargetRoles] = useState(targetRole)

  function getLabel(role) {
    switch (role) {
      case 'APP-OAUTH2-CRTVHUB-ADMIN':
        return 'Admin'
      case 'APP-OAUTH2-CRTVHUB-BCM':
        return 'BCM'
      case 'APP-OAUTH2-CRTVHUB-COPYWRITER':
        return 'Creative CopyWriter'
      case 'APP-OAUTH2-CRTVHUB-CREATIVEART':
        return 'Creative Art'
      case 'APP-OAUTH2-CRTVHUB-CREATIVEPROD':
        return 'Creative Production'
      case 'APP-OAUTH2-CRTVHUB-PROJMGR':
        return 'Project Manager'
      case 'APP-OAUTH2-CRTVHUB-MARKETING':
        return 'Marketing'
      case 'APP-OAUTH2-CRTVHUB-MERCH':
        return 'Merch All'
      case 'APP-OAUTH2-CRTVHUB-READONLY':
        return 'Read-only All'
      case 'APP-POL-USERS':
        return 'Vendor'
      case 'APP-CTLG':
        return 'Vendor'
      case 'APP-OAUTH2-CRTVHUB-GROUPOFFERS':
        return 'GroupedOffer User'
      default:
        return ''
    }
  }

  let location = useLocation()

  const navStyle = {
    backgroundColor: 'rgba(0,0,0,0.3)',
    height: '100%',
    left: '0',
    position: 'fixed',
    top: '0',

    zIndex: '100',
  }

  const backdropStyle = {
    backgroundColor: 'rgba(0,0,0,0.3)',
    height: '100%',
    left: '0',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '100',
  }

  return (
    <div
      style={showSidebar ? backdropStyle : {}}
      onClick={() => {
        closeSideNav()
      }}
    >
      <SideNav
        data-testid="sideNav"
        isVisible={showSidebar}
        onRequestClose={closeSideNav}
        suppressAutoOpen={!showSidebar}
        style={showSidebar ? navStyle : {}}
      >
        {effectiveRole.some((role) => isVendor(role)) ? (
          <SideNav.Header data-testid="sideNavLogoHeader" exact>
            <Grid.Container
              spacing="none"
              justify="center"
              align="center"
              className="logo"
            >
              <Grid.Item>
                <img src={creatievHubLogo} alt="CreativeHub Logo"></img>
              </Grid.Item>
            </Grid.Container>
          </SideNav.Header>
        ) : (
          <span onClick={(e) => e.stopPropagation()}>
            <SideNav.Header
              data-testid="sideNavLogoHeader"
              as={NavLink}
              to={'/'}
            >
              <Grid.Container
                spacing="none"
                justify="center"
                align="center"
                className="logo"
              >
                <Grid.Item>
                  <img src={creatievHubLogo} alt="CreativeHub Logo"></img>
                </Grid.Item>
              </Grid.Container>
            </SideNav.Header>
            {effectiveRole.some(
              (role) =>
                canAccessPlanning(role) || canAccessExecution(effectiveRole)
            ) ? (
              <h3 className="navHeader">PLAN</h3>
            ) : null}
            {effectiveRole.some((role) => canAccessPlanning(role)) ? (
              <SideNav.NavigationItem
                as={NavLink}
                to={'/planning'}
                className={
                  location.pathname === '/planning' ? 'isSelected' : ''
                }
                data-testid="side-nav-planning"
                data-cy="side-nav-planning"
                onClick={() => {
                  closeSideNav()
                }}
              >
                <Heading size={6}>{'MARKETING & DIGITAL'}</Heading>
              </SideNav.NavigationItem>
            ) : null}
            {effectiveRole.some((role) => canAccessExecution(role)) ? (
              <SideNav.NavigationItem
                as={NavLink}
                to={'/execution'}
                className={
                  location.pathname === '/execution' ? 'isSelected' : ''
                }
                data-cy="side-nav-execution"
                onClick={() => {
                  closeSideNav()
                }}
              >
                <Heading size={6}>{'MERCHANTS'}</Heading>
              </SideNav.NavigationItem>
            ) : null}
            {effectiveRole.some(
              (role) =>
                canAccessWeeklyAd(role) ||
                canAccessCatalog(role) ||
                canAccessGroupedOffer(role) ||
                canAccessDWAChangeHistory(role)
            ) ? (
              <h3 className="navHeader">CREATE</h3>
            ) : null}
            {effectiveRole.some((role) => canAccessCatAmp(role)) ? (
              <span onClick={(e) => e.stopPropagation()}>
                <SideNav.NavigationItem
                  as={NavLink}
                  to="/publishing"
                  className={
                    location.pathname === '/publishing' ? 'isSelected' : ''
                  }
                  onClick={() => {
                    closeSideNav()
                  }}
                >
                  <Heading size={6}>CATEGORY AMPLIFICATION</Heading>
                </SideNav.NavigationItem>
              </span>
            ) : null}
            {/* {effectiveRole.some((role) => canAccessCatAmp(role)) ? (
              <span onClick={(e) => e.stopPropagation()}>
                <SideNav.NavigationItem
                  as={NavLink}
                  to="/priorityoffers"
                  className={
                    location.pathname === '/priorityoffers' ? 'isSelected' : ''
                  }
                >
                  <Heading size={6}>PRIORITY OFFERS</Heading>
                </SideNav.NavigationItem>
              </span>
            ) : null} */}
            {effectiveRole.some((role) => canAccessGroupedOffer(role)) ? (
              <span onClick={(e) => e.stopPropagation()}>
                <SideNav.NavigationItem
                  as={NavLink}
                  to="/groupedOffers"
                  className={
                    location.pathname === '/groupedOffers' ? 'isSelected' : ''
                  }
                  onClick={() => {
                    closeSideNav()
                  }}
                >
                  <Heading size={6}>GROUPED OFFER (GO)</Heading>
                </SideNav.NavigationItem>
              </span>
            ) : null}

            {effectiveRole.some((role) => canAccessCatalog(role)) ? (
              <SideNav.NavigationGroup>
                <SideNav.NavigationItem
                  className={
                    location?.pathname.startsWith('/catalog') ||
                    location?.pathname.startsWith('/past-catalogs') ||
                    location?.pathname.startsWith('/vendor')
                      ? 'catalogLink'
                      : ''
                  }
                >
                  <Heading size={6}>{'CATALOGS'}</Heading>
                </SideNav.NavigationItem>
                <SideNav.NavigationGroupContent>
                  <SideNav.NavigationItem
                    as={NavLink}
                    className={
                      location.pathname === `/catalog` && wadId === '-1'
                        ? 'catalogSubLink isSelected'
                        : location.pathname.startsWith(`/past-catalogs`) ||
                          location.pathname.startsWith('/catalog') ||
                          location?.pathname.startsWith('/vendor')
                        ? 'catalogSubLink'
                        : 'sublink'
                    }
                    to={'/catalog'}
                    onClick={() => {
                      closeSideNav()
                    }}
                  >
                    {'Upcoming Catalogs'}
                  </SideNav.NavigationItem>
                  <SideNav.NavigationItem
                    as={NavLink}
                    className={
                      location.pathname === `/past-catalogs`
                        ? 'catalogSubLink isSelected'
                        : location.pathname.startsWith(`/past-catalogs`) ||
                          location.pathname.startsWith('/catalog') ||
                          location.pathname.startsWith('/vendor')
                        ? 'catalogSubLink'
                        : 'sublink'
                    }
                    to={'/past-catalogs'}
                    onClick={() => {
                      closeSideNav()
                    }}
                  >
                    {'Past Catalogs'}
                  </SideNav.NavigationItem>

                  {effectiveRole.some((role) => canAccessVendor(role)) ? (
                    <span onClick={(e) => e.stopPropagation()}>
                      <SideNav.NavigationItem
                        as={NavLink}
                        to="/vendor"
                        className={
                          location.pathname === `/vendor`
                            ? 'catalogSubLink isSelected'
                            : location.pathname.startsWith(`/past-catalogs`) ||
                              location.pathname.startsWith('/catalog')
                            ? 'catalogSubLink'
                            : 'sublink'
                        }
                        onClick={() => {
                          closeSideNav()
                        }}
                      >
                        {'Catalog Vendors'}
                      </SideNav.NavigationItem>
                    </span>
                  ) : null}

                  {effectiveRole.some((role) => canAccessCatalog(role)) &&
                  inCatalogs(location) &&
                  !inPastCatalogs(location) ? (
                    <>
                      <SideNav.NavigationItem
                        as={NavLink}
                        className={
                          location.pathname === `/catalog/${wadId}`
                            ? 'catalogSubLink isSelected'
                            : 'catalogSubLink'
                        }
                        to={`/catalog/${wadId}`}
                        onClick={() => {
                          closeSideNav()
                        }}
                      >
                        Pagination
                      </SideNav.NavigationItem>
                      <SideNav.NavigationItem
                        as={NavLink}
                        className={
                          location.pathname === `/catalog/${wadId}/product-list`
                            ? 'catalogSubLink isSelected'
                            : 'catalogSubLink'
                        }
                        to={`/catalog/${wadId}/product-list`}
                        onClick={() => {
                          closeSideNav()
                        }}
                      >
                        Main Product List
                      </SideNav.NavigationItem>
                      <SideNav.NavigationItem
                        as={NavLink}
                        className={
                          location.pathname === `/catalog/${wadId}/creative`
                            ? 'catalogSubLink isSelected'
                            : 'catalogSubLink'
                        }
                        to={`/catalog/${wadId}/creative`}
                        onClick={() => {
                          closeSideNav()
                        }}
                      >
                        Catalog Creative
                      </SideNav.NavigationItem>
                      <SideNav.NavigationItem
                        as={NavLink}
                        className={
                          location.pathname === `/catalog/${wadId}/sample`
                            ? 'catalogSubLink isSelected'
                            : 'catalogSubLink'
                        }
                        to={`/catalog/${wadId}/sample`}
                        onClick={() => {
                          closeSideNav()
                        }}
                      >
                        Samples and Images
                      </SideNav.NavigationItem>
                      <SideNav.NavigationItem
                        as={NavLink}
                        className={
                          location.pathname === `/catalog/${wadId}/history`
                            ? 'catalogSubLink isSelected'
                            : 'catalogSubLink'
                        }
                        to={`/catalog/${wadId}/history`}
                        onClick={() => {
                          closeSideNav()
                        }}
                      >
                        Product History
                      </SideNav.NavigationItem>
                    </>
                  ) : null}
                </SideNav.NavigationGroupContent>
              </SideNav.NavigationGroup>
            ) : null}
          </span>
        )}
        {effectiveRole.some((role) => canAccessWeeklyAd(role)) ? (
          <span onClick={(e) => e.stopPropagation()}>
            <SideNav.NavigationGroup startExpanded={false}>
              <SideNav.NavigationItem
                className={
                  location?.pathname.startsWith('/weeklyad') ||
                  location?.pathname.startsWith('/change-tracking') ||
                  location?.pathname.startsWith('/pastads') ||
                  location?.pathname.startsWith('/admin')
                    ? 'weeklyAdLink'
                    : ''
                }
              >
                <Heading size={6}>{'WEEKLY ADS'}</Heading>
              </SideNav.NavigationItem>
              <SideNav.NavigationGroupContent>
                <SideNav.NavigationItem
                  as={NavLink}
                  className={
                    location.pathname === `/weeklyad` && wadId === '-1'
                      ? 'weeklyAdSubLink isSelected'
                      : location.pathname.startsWith(`/pastads`) ||
                        location.pathname.startsWith(`/admin`) ||
                        location.pathname.startsWith(`/change-tracking`)
                      ? 'weeklyAdSubLink'
                      : 'sublink'
                  }
                  to={'/weeklyad?hasRun=false'}
                  onClick={() => {
                    closeSideNav()
                  }}
                >
                  {'Upcoming Ads'}
                </SideNav.NavigationItem>
                <SideNav.NavigationItem
                  as={NavLink}
                  className={
                    location.pathname === `/pastads`
                      ? 'weeklyAdSubLink isSelected'
                      : location.pathname.startsWith(`/weeklyad`) ||
                        location.pathname.startsWith(`/pastads`) ||
                        location.pathname.startsWith(`/admin`) ||
                        location.pathname.startsWith(`/change-tracking`)
                      ? 'weeklyAdSubLink'
                      : 'sublink'
                  }
                  to={'/pastads?hasRun=true'}
                  archive="true"
                  onClick={() => {
                    closeSideNav()
                  }}
                >
                  {'Past Ads'}
                </SideNav.NavigationItem>
                {effectiveRole.some((role) => canAccessWeeklyAd(role)) &&
                !atHomePage(location) &&
                inWeeklyAd(location) &&
                !inDpciSearch(location) ? (
                  <div>
                    <Divider width={2} />
                    <SideNav.NavigationItem
                      as={NavLink}
                      className={
                        location.pathname === `/weeklyad/${wadId}`
                          ? 'weeklyAdSubLink isSelected'
                          : 'weeklyAdSubLink'
                      }
                      to={`/weeklyad/${wadId}`}
                      archive={location?.search.includes('hasRun=true')}
                      onClick={() => {
                        closeSideNav()
                      }}
                    >
                      Pagination
                    </SideNav.NavigationItem>
                    <SideNav.NavigationItem
                      as={NavLink}
                      className={
                        location.pathname === `/weeklyad/${wadId}/merchant`
                          ? 'weeklyAdSubLink isSelected'
                          : 'weeklyAdSubLink'
                      }
                      to={`/weeklyad/${wadId}/merchant`}
                      onClick={() => {
                        closeSideNav()
                      }}
                    >
                      Merchant
                    </SideNav.NavigationItem>
                    <SideNav.NavigationItem
                      as={NavLink}
                      className={
                        location.pathname.includes('sample') &&
                        !location.pathname.includes('props') &&
                        !location.pathname.includes('late-additions')
                          ? 'weeklyAdSubLink isSelected'
                          : 'weeklyAdSubLink'
                      }
                      to={toSampleUrl(
                        wadId,
                        location?.search.includes('hasRun=true')
                      )}
                      archive={location?.search.includes('hasRun=true')}
                      onClick={() => {
                        closeSideNav()
                      }}
                    >
                      Samples
                    </SideNav.NavigationItem>
                    <SideNav.NavigationItem
                      as={NavLink}
                      className={
                        location.pathname.includes('sample') &&
                        location.pathname.includes('props')
                          ? 'weeklyAdSubLink isSelected'
                          : 'weeklyAdSubLink'
                      }
                      to={`/weeklyad/${wadId}/sample/props`}
                      onClick={() => {
                        closeSideNav()
                      }}
                    >
                      Samples - Props
                    </SideNav.NavigationItem>
                    <SideNav.NavigationItem
                      as={NavLink}
                      className={
                        location.pathname ===
                        `/weeklyad/${wadId}/sample/late-additions`
                          ? 'weeklyAdSubLink isSelected'
                          : 'weeklyAdSubLink'
                      }
                      to={`/weeklyad/${wadId}/sample/late-additions`}
                      onClick={() => {
                        closeSideNav()
                      }}
                    >
                      Samples - Late Additions
                    </SideNav.NavigationItem>
                    <Divider />
                  </div>
                ) : null}
                {effectiveRole.some((role) => canAccessAdminCenter(role)) ? (
                  <span onClick={(e) => e.stopPropagation()}>
                    <SideNav.NavigationItem
                      as={NavLink}
                      to="/admin"
                      className={
                        location.pathname === `/admin`
                          ? 'weeklyAdSubLink isSelected'
                          : location.pathname.startsWith(`/weeklyad`) ||
                            location.pathname.startsWith(`/pastads`) ||
                            location.pathname.startsWith(`/change-tracking`)
                          ? 'weeklyAdSubLink'
                          : 'sublink'
                      }
                      onClick={() => {
                        closeSideNav()
                      }}
                    >
                      {'WA Admin Center'}
                    </SideNav.NavigationItem>
                  </span>
                ) : null}
                {effectiveRole.some((role) =>
                  canAccessDWAChangeHistory(role)
                ) ? (
                  <SideNav.NavigationItem
                    onClick={(e) => {
                      e.stopPropagation()
                      closeSideNav()
                    }}
                    as={NavLink}
                    to="/change-tracking"
                    className={
                      location.pathname === `/change-tracking`
                        ? 'weeklyAdSubLink isSelected'
                        : location.pathname.startsWith(`/weeklyad`) ||
                          location.pathname.startsWith(`/pastads`) ||
                          location.pathname.startsWith(`/admin`)
                        ? 'weeklyAdSubLink'
                        : 'sublink'
                    }
                  >
                    {'WA Change Tracking'}
                  </SideNav.NavigationItem>
                ) : null}
              </SideNav.NavigationGroupContent>
            </SideNav.NavigationGroup>
          </span>
        ) : null}
        {effectiveRole.some((role) => canAccessChangeSummary(role)) ? (
          <span onClick={(e) => e.stopPropagation()}>
            <h3 className="navHeader">REPORTING</h3>
            <SideNav.NavigationItem
              as={NavLink}
              to="/changeSummary"
              className={
                location.pathname === '/changeSummary' ? 'isSelected' : ''
              }
              onClick={() => {
                closeSideNav()
              }}
            >
              <Heading size={6}>CHANGE SUMMARY</Heading>
            </SideNav.NavigationItem>
          </span>
        ) : null}
        {effectiveRole.some((role) => canAccessAdminCenter(role)) ? (
          <span onClick={(e) => e.stopPropagation()}>
            <h3 className="navHeader">SETTINGS</h3>
            <SideNav.NavigationItem
              as={NavLink}
              to="/contacts"
              className={location.pathname === '/contacts' ? 'isSelected' : ''}
              onClick={() => {
                closeSideNav()
              }}
            >
              <Heading size={6}>CONTACTS</Heading>
            </SideNav.NavigationItem>
          </span>
        ) : null}
        {effectiveRole.some((role) => isAdmin(role)) ? (
          <Card>
            <div className="hc-pa-normal" />
            <div className="hc-pa-normal">
              <Form.Field
                type="select"
                id="effectiveRole"
                label="Change Role"
                className="hc-pa-none"
                value={
                  effectiveRole[0]?.includes('CRTV')
                    ? effectiveRole[0]
                    : effectiveRole[1]
                }
                onClick={(event) => {
                  event.stopPropagation()
                }}
                onUpdate={(_, value) => {
                  setEffectiveRole([value])
                }}
                options={targetRoles?.map((role) => ({
                  key: role,
                  label: getLabel(role),
                  value: role,
                }))}
              />
            </div>
          </Card>
        ) : null}
        <div className="navbar-footer">
          <a
            href="https://target.slack.com/archives/CRX69KYV9"
            target="_blank"
            rel="noreferrer"
          >
            <Button className="C-SlackIcon" iconOnly aria-label="close comment">
              <EnterpriseIcon icon={SlackIcon} />
            </Button>
          </a>
          <div className="logo">Version {`${process.env.APP_VERSION}`}</div>
        </div>
      </SideNav>
    </div>
  )
}
export default PopNavigation
