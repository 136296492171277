import {
  Button,
  Card,
  Dialog,
  Grid,
  List,
  Modal,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

const EditArtPatch = (props) => {
  const { editArtPatch, setEditArtPatch, setAddCutToArtPatch, putStory } = props
  const handleDialogClose = () => {
    setEditArtPatch({ body: {}, show: false })
  }
  const [splitDialogVisible, setSplitDialogVisible] = useState(false)
  const [editDialogVisible, setEditDialogVisible] = useState({
    cut: {},
    show: false,
  })

  const followers = editArtPatch?.body?.followers
  const leader = editArtPatch?.body?.leader

  const removeFollowerFromPatch = () => {
    putStory({
      body: {
        cuts: [
          {
            id: editDialogVisible.cut.id,
            lastUpdatedTimestamp: editDialogVisible.cut.lastUpdatedTimestamp,
          },
        ],
        id: editArtPatch.body.story.id,
        lastUpdatedTimestamp: editArtPatch.body.story.lastUpdatedTimestamp,
      },
      pageId: editArtPatch.body.story.pageId,
    })
    setEditDialogVisible({ cut: {}, show: false })
    setEditArtPatch({ body: {}, show: false })
  }

  const unlinkCuts = () => {
    const allCutsInPatch = followers.map((follower) => ({
      id: follower.id,
      lastUpdatedTimestamp: follower.lastUpdatedTimestamp,
    }))

    //push leader
    allCutsInPatch.push({
      id: leader.id,
      lastUpdatedTimestamp: leader.lastUpdatedTimestamp,
    })
    putStory({
      body: {
        cuts: allCutsInPatch,
        id: editArtPatch.body.story.id,
        lastUpdatedTimestamp: editArtPatch.body.story.lastUpdatedTimestamp,
      },
      pageId: editArtPatch.body.story.pageId,
    })
    setSplitDialogVisible(false)
    setEditArtPatch({ body: {}, show: false })
  }

  return followers ? (
    <Modal
      isVisible={editArtPatch.show}
      onRefuse={handleDialogClose}
      headingText={'Edit Art Patch: ' + leader?.name}
    >
      <div className="hc-pa-normal hc-pt-dense">
        <List>
          {followers.map((follower) => (
            <Card
              className="hc-mt-dense hc-mb-dense hc-bg-grey06"
              key={follower.id}
            >
              <List.Item>
                {follower.name}
                <Button
                  onClick={() =>
                    setEditDialogVisible({ cut: follower, show: true })
                  }
                  className="C-ListItem__item__button"
                  iconOnly
                >
                  <Tooltip content="Unlink Cut" location="top">
                    <FontAwesomeIcon
                      icon={faLink}
                      style={{ display: 'block' }}
                    />
                  </Tooltip>
                </Button>
              </List.Item>
            </Card>
          ))}
        </List>

        <Grid.Container
          className="hc-pt-expanded"
          spacing="dense"
          justify="space-between"
        >
          {Boolean(
            editArtPatch.body?.story?.cuts?.filter((cut) => !cut.artPatch)
              .length
          ) && (
            <Grid.Item xs={12}>
              <Button
                type="primary"
                disabled={false}
                onClick={() =>
                  setAddCutToArtPatch({
                    body: editArtPatch.body,
                    show: true,
                  })
                }
              >
                Add Cut to Art Patch
              </Button>
            </Grid.Item>
          )}
          <Grid.Item>
            <Button type="primary" onClick={() => setSplitDialogVisible(true)}>
              Unlink All Cuts
            </Button>
          </Grid.Item>

          <Grid.Item>
            <Button type="secondary" onClick={handleDialogClose}>
              Close
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
      <Dialog
        headingText="Are you sure you want to unlink all cuts?"
        approveButtonText="Yes"
        isVisible={splitDialogVisible}
        onApprove={unlinkCuts}
        refuseButtonText="No"
        onRefuse={() => setSplitDialogVisible(false)}
      />
      <Dialog
        headingText="Are you sure you want to remove this cut from the art patch"
        isVisible={editDialogVisible.show}
        approveButtonText="remove"
        onApprove={removeFollowerFromPatch}
        onRefuse={() => setEditDialogVisible({ cut: {}, show: false })}
        refuseButtonText="Cancel"
      />
    </Modal>
  ) : null
}
export default EditArtPatch
