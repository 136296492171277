import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class OfferApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_offer_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getAllOfferDetailsByUUID = async (offerUUIDs) =>
    await this.init_offer_api()
      .post(`${this.config.markethub.url}offer/get-ivy-details`, offerUUIDs)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  getOffer = async (id) =>
    await this.init_offer_api()
      .get(`${this.config.markethub.url}offer/${id}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  postOffers = async (offerRequest) =>
    await this.init_offer_api()
      .post(`${this.config.markethub.url}offer/umbrella`, offerRequest)
      .then((res) => res.data)
      .catch((error) => {
        const errorStatus = error?.status
        let message = 'There is an issue displaying offers'
        if (errorStatus === 400 || errorStatus === 401 || errorStatus === 500) {
          message = "Offers associated aren't displaying as expected"
        }
        return { error: message }
      })

  updateOffers = async (offers) => {
    try {
      const offersWithoutDates = offers.map(
        ({ start_date, end_date, ...rest }) => rest
      )
      return await this.init_offer_api().put(
        `${this.config.markethub.url}offer`,
        offersWithoutDates
      )
    } catch (error) {
      console.log(error)
      return []
    }
  }

  createOffer = async (offer) => {
    await this.init_offer_api().post(`${this.config.markethub.url}offer`, offer)
  }

  deleteOffer = async (id) => {
    await this.init_offer_api().delete(
      `${this.config.markethub.url}offer/${id}`
    )
  }

  updatePromoMessage = async (offer) => {
    await this.init_offer_api()
      .put(`${this.config.markethub.url}offer/promoMessage`, offer)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })
  }

  getOfferPromoMessage = async (umbrellaUuid, projectUuid) =>
    await this.init_offer_api()
      .get(
        `${this.config.markethub.url}offer/promoMessage?umbrellaId=${umbrellaUuid}&projectId=${projectUuid}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  getOffersByProjectAndChannel = async (projectId, channelId) =>
    await this.init_offer_api()
      .get(
        `${this.config.markethub.url}offer/project/${projectId}/${channelId}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  updateOfferItems = async (items) => {
    try {
      const res = await this.init_offer_api().put(
        `${this.config.markethub.url}offer/items`,
        items
      )
      return res.data
    } catch (e) {
      console.log(e)
      return []
    }
  }

  sendOfferEmail = async (payload) =>
    await this.init_offer_api()
      .post(`${this.config.markethub.url}offer/notify`, payload)
      .then((res) => res.data)
      .catch((error) => {
        console.log(error)
      })

  verifyEnableOfferNotification = async (projectId, ivyOfferId, offerId) =>
    await this.init_offer_api()
      .get(
        `${this.config.markethub.url}offer/notify/${projectId}/${ivyOfferId}/${offerId}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  getOffers = async (ivyUmbrellaId, storyId, projectId) =>
    await this.init_offer_api()
      .get(
        `${this.config.markethub.url}offer/umbrella?ivyUmbrellaId=${ivyUmbrellaId}&storyId=${storyId}&projectId=${projectId}`
      )
      .then((res) => res.data)
      .catch((error) => {
        const errorStatus = error?.status
        let message = 'There is an issue displaying offers'
        if (errorStatus === 400 || errorStatus === 401 || errorStatus === 500) {
          message = "Offers associated aren't displaying as expected"
        }
        return { error: message }
      })
}
