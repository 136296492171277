import {
  Button,
  ButtonGroup,
  Dialog,
  Form,
  Grid,
  Heading,
  Modal,
  Tooltip,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import React, { useEffect, useRef, useState } from 'react'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import OfferCard from '../components/OfferCard'
import { formatToDateTime } from '../util/MomentUtil'

import AddItem from './AddItem'
import AddOffer from './AddOffer'
import AddPropItem from './AddPropItem'

const EditCut = (props) => {
  const {
    editCut,
    setEditCut,
    putCut,
    putStory,
    getItems,
    getOffers,
    weeklyAd,
    postDummy,
    postPropItem,
    setMoveCut,
    spawnArtPatch,
  } = props

  const divisions = editCut.divisions
  const divisionId = editCut ? editCut.body.divisionId : -1

  const promoUnitRegUpdatedPostLockout =
    editCut?.lockoutDate < editCut?.body?.promoUnitRetailLastUpdatedTimestamp
  const [isDeleteDialog, setDeleteDialog] = useState(false)
  const [isSaveCutAlert, setSaveCutAlert] = useState(false)

  const cutTimestampRef = useRef(editCut?.body?.lastUpdatedTimestamp)
  const selectedStoryToMoveRef = useRef({})

  const [addOffers, setAddOffers] = useState({})
  const [addItems, setAddItems] = useState({})
  const [addPropItem, setAddPropItem] = useState({})
  const [isCutMoveAlert, setCutMoveAlert] = useState(false)

  //needed to pass new timestamp to addItem modal after initialization (after onSaveAlert())
  useEffect(() => {
    cutTimestampRef.current = editCut?.body?.lastUpdatedTimestamp
  }, [editCut])
  useEffect(() => {})

  const wipeState = () => {
    setEditCut({
      body: {},
      pageId: null,
      show: false,
      storyId: null,
    })
    setDeleteDialog(false)
    setSaveCutAlert(false)
    setAddItems({})
    setAddOffers({})
    setAddPropItem({})
    cutTimestampRef.current = null
    selectedStoryToMoveRef.current = {}
  }

  const addItem = () => {
    setAddItems({
      cutId: editCut.body.id,
      cutTimestampRef: cutTimestampRef,
      divisionId: divisionId,
      divisions: divisions,
      offers: editCut.body.offers,
      pageId: editCut.page.id,
      setEditCut: setEditCut,
      storyId: editCut.storyId,
      storyLastUpdatedTimestamp: editCut.storyLastUpdatedTimestamp,
    })
  }

  const onDelete = () => {
    putStory({
      body: {
        cuts: [
          {
            deleted: true,
            id: editCut.body.id,
            lastUpdatedTimestamp: editCut.body.lastUpdatedTimestamp,
          },
        ],
        id: editCut.storyId,
        lastUpdatedTimestamp: editCut.storyLastUpdatedTimestamp,
      },
      pageId: editCut.page.id,
    })
  }

  const onCutUpdate = (show, values, after) => {
    if (editCut.body.primaryPatchIndex) {
      putCut({
        body: {
          copy: values.edit_cut_copy,
          divisionId: divisionId,
          id: editCut.body.id,
          lastUpdatedTimestamp: editCut.body.lastUpdatedTimestamp,
          name: values.edit_cut_name,
          promoUnitRetailCallout: values.edit_cut_promoUnitRetailCallout,
          saleCallout: values.edit_cut_saleCallout,
        },
        divisions: divisions,
        followers: editCut.followers,
        hasEditPrivileges: editCut.hasEditPrivileges,
        page: editCut.page,
        setEditCut: setEditCut,
        show,
        storyId: editCut.storyId,
        storyLastUpdatedTimestamp: editCut.storyLastUpdatedTimestamp,
        then: after,
        weeklyAdStartDate: editCut.weeklyAdStartDate,
      })
    } else {
      putCut({
        body: {
          divisionId: divisionId,
          id: editCut.body.id,
          lastUpdatedTimestamp: editCut.body.lastUpdatedTimestamp,
          name: values.edit_cut_name,
        },
        divisions: divisions,
        followers: editCut.followers,
        hasEditPrivileges: editCut.hasEditPrivileges,
        page: editCut.page,
        setEditCut: setEditCut,
        show,
        storyId: editCut.storyId,
        storyLastUpdatedTimestamp: editCut.storyLastUpdatedTimestamp,
        then: after,
        weeklyAdStartDate: editCut.weeklyAdStartDate,
      })
    }
  }

  const initialValues = {
    edit_cut_copy: editCut.body.copy || '',
    edit_cut_name: editCut.body.name || '',
    edit_cut_promoUnitRetailCallout: Boolean(
      editCut.body.promoUnitRetailCallout
    ),
    edit_cut_saleCallout: Boolean(editCut.body.saleCallout),
  }

  return editCut.body ? (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        onCutUpdate(false, values)
        wipeState()
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        handleReset,
        setValues,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }

        const onAddItemClick = () => {
          if (isEqual(values, initialValues)) {
            addItem()
          } else {
            setSaveCutAlert(true)
          }
        }

        const onSaveAlert = () => {
          onCutUpdate(true, values, addItem)
          setSaveCutAlert(false)
        }

        const handleDelete = () => {
          onDelete()
          handleDialogClose()
        }

        const handleSaveAlertClose = () => {
          setSaveCutAlert(false)
          addItem()
          setValues(initialValues)
        }

        const onCutMoveClick = () => {
          if (isEqual(values, initialValues)) {
            moveCut()
          } else {
            setCutMoveAlert(true)
          }
        }

        const moveCut = () => {
          setMoveCut({
            cut: editCut,
            cutTimestampRef: cutTimestampRef,
            show: true,
          })
        }

        const onMoveCutSave = () => {
          onCutUpdate(true, values, moveCut)
          setCutMoveAlert(false)
        }

        const onMoveCutRevert = () => {
          setCutMoveAlert(false)
          setValues(initialValues)
          moveCut()
        }
        const canSpawnArtPatches =
          editCut?.body?.offers &&
          editCut?.body?.offers?.length <= 1 &&
          editCut?.body?.offers[0]?.offerArtPatches?.length > 1 &&
          isEmpty(editCut?.followers) &&
          !editCut.hasItems
        return (
          <Modal isVisible={editCut.show} onRefuse={handleDialogClose}>
            <Heading
              size={4}
              className="hc-pt-expanded hc-pl-expanded hc-pr-expanded"
            >
              {editCut.hasEditPrivileges
                ? `Edit Cut ${editCut.body.priority + 1} `
                : 'Cut details '}
              {!editCut.cutValid && (
                <span
                  style={{
                    color: 'red',
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                  }}
                >
                  Deactivated Offer
                </span>
              )}
              <Tooltip
                location={'left'}
                style={{ float: 'right' }}
                content={`Cut Revised: ${
                  editCut.body.coreDataLastUpdatedField
                } at ${formatToDateTime(
                  editCut.body.coreDataLastUpdatedTimestamp
                )}`}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  size="xs"
                  className="hc-clr-grey03"
                />
              </Tooltip>
            </Heading>
            <div className="hc-pa-normal" style={{ position: 'relative' }}>
              <fieldset disabled={!editCut.hasEditPrivileges}>
                {divisions && divisionId ? (
                  <Input.Chips
                    id="edit_cut_divisionId"
                    disabled
                    singleSelect
                    options={divisions.map((division) => ({
                      label: division.shortCode,
                      value: division.id,
                    }))}
                    value={editCut.body.divisionId}
                  />
                ) : null}
                <Button
                  onClick={() =>
                    setAddOffers({
                      cleanup: (pictured, liner) => {
                        putCut({
                          body: {
                            divisionId: divisionId,
                            id: editCut.body.id,
                            lastUpdatedTimestamp:
                              editCut.body.lastUpdatedTimestamp,
                            linerOffers: liner,
                            offers: pictured,
                          },
                          divisions: divisions,
                          followers: editCut.followers,
                          hasEditPrivileges: editCut.hasEditPrivileges,
                          page: editCut.page,
                          setEditCut: setEditCut,
                          show: true,
                          storyId: editCut.storyId,
                          storyLastUpdatedTimestamp:
                            editCut.storyLastUpdatedTimestamp,
                          weeklyAdStartDate: editCut.weeklyAdStartDate,
                        })
                      },
                      cutId: editCut.body.id,
                      divisionId: divisionId,
                      linerOfferIds:
                        editCut.body.linerOffers?.map((off) => off.id) || [],
                      offerIds: editCut.body.offers?.map((off) => off.id) || [],
                      show: false, //show set to true after useGetOffers completes
                      startDate: weeklyAd.startDate,
                    })
                  }
                  type="primary"
                  className="hc-mt-normal hc-mb-normal"
                  disabled={!editCut.hasEditPrivileges}
                >
                  Link Offers
                </Button>
                <Button
                  className="hc-ml-dense"
                  type="primary"
                  onClick={onCutMoveClick}
                  disabled={
                    (editCut.isArtPatch && isEmpty(editCut.followers)) ||
                    !editCut.hasEditPrivileges
                  }
                >
                  Move Cut
                </Button>
                <Form.Field
                  id="edit_cut_name"
                  label="Cut Name"
                  value={values.edit_cut_name}
                  onChange={handleChange}
                />
                {editCut.body.primaryPatchIndex ? (
                  <div className="hc-pa-normal" spacing="none">
                    <Grid.Item xs={12}>
                      <Input.Label disabled>
                        Promo Unit Retail
                        <Tooltip
                          style={{ float: 'right' }}
                          location={'left'}
                          content={`Promo Unit Retail Revised: ${formatToDateTime(
                            editCut.body.promoUnitRetailLastUpdatedTimestamp
                          )}`}
                        >
                          <FontAwesomeIcon
                            icon={faClock}
                            size="sm"
                            className="hc-clr-grey03"
                          />
                        </Tooltip>
                      </Input.Label>
                      <Form.Field
                        disabled
                        style={{
                          color: promoUnitRegUpdatedPostLockout ? 'red' : '',
                        }}
                        id="edit_cut_promoUnitRetail"
                        value={editCut.body.promoUnitRetail}
                        hintText="Promo Unit Retail edits must be made in IVY Promo"
                      />
                    </Grid.Item>
                    <Grid.Item xs={6}>
                      <Form.Field
                        type="toggle"
                        id="edit_cut_saleCallout"
                        label="Sale Callout"
                        value={values.edit_cut_saleCallout}
                        onChange={() =>
                          setFieldValue(
                            'edit_cut_saleCallout',
                            !values.edit_cut_saleCallout
                          )
                        }
                      />
                    </Grid.Item>
                    <Grid.Item xs={6}>
                      <Form.Field
                        type="toggle"
                        id="edit_cut_promoUnitRetailCallout"
                        label="List Promo Unit Retail?"
                        value={values.edit_cut_promoUnitRetailCallout}
                        onChange={() =>
                          setFieldValue(
                            'edit_cut_promoUnitRetailCallout',
                            !values.edit_cut_promoUnitRetailCallout
                          )
                        }
                      />
                    </Grid.Item>
                    <Grid.Item xs={12}>
                      <Form.Field
                        type="textarea"
                        grow
                        id="edit_cut_copy"
                        value={values.edit_cut_copy}
                        label="Copy"
                        onChange={handleChange}
                      />
                    </Grid.Item>
                  </div>
                ) : null}
                {!isEmpty(editCut?.body?.offers) && (
                  <React.Fragment>
                    <Input.Label>
                      Offer Info:
                      <Tooltip
                        style={{ float: 'right' }}
                        location={'left'}
                        content={`Offer Linked: ${formatToDateTime(
                          editCut.body.offerLastLinkedTimestamp
                        )}`}
                      >
                        <FontAwesomeIcon
                          icon={faClock}
                          size="sm"
                          className="hc-clr-grey03"
                        />
                      </Tooltip>
                    </Input.Label>
                    {editCut.body.offers.map((offer) => (
                      <OfferCard
                        index={editCut.body.primaryPatchIndex}
                        key={offer.id}
                        offer={offer}
                        lockoutDate={editCut?.lockoutDate}
                        isMultiple={Boolean(editCut.body.offers.length > 1)}
                        weeklyAdStartDate={editCut.weeklyAdStartDate}
                      />
                    ))}
                    {!isEmpty(editCut?.body?.linerOffers) && (
                      <React.Fragment>
                        <Input.Label>Liner Offer Info:</Input.Label>
                        {editCut.body.linerOffers.map((liner) => (
                          <OfferCard
                            key={liner.id}
                            liner
                            lockoutDate={editCut?.lockoutDate}
                            offer={liner}
                            weeklyAdStartDate={editCut.weeklyAdStartDate}
                          />
                        ))}
                      </React.Fragment>
                    )}
                    {
                      //TODO fix spacing and eliminate empty container
                    }
                    <div className="hc-pa-none" padding="dense" />
                  </React.Fragment>
                )}
                <ButtonGroup>
                  <Button
                    type="primary"
                    disabled={
                      (!editCut.body.primaryPatchIndex &&
                        editCut.body.primaryPatchIndex !== '') ||
                      !editCut.hasEditPrivileges
                    }
                    onClick={onAddItemClick}
                  >
                    Add Item
                  </Button>
                  <Button
                    type="primary"
                    disabled={!editCut.hasItems || !editCut.hasEditPrivileges}
                    onClick={() => {
                      setAddPropItem({
                        cutId: editCut.body.id,
                        pageId: editCut.page.id,
                        show: true,
                        storyId: editCut.storyId,
                      })
                    }}
                  >
                    Add Prop Item
                  </Button>
                </ButtonGroup>
                <CancelSubmitButtons
                  handleDelete={setDeleteDialog}
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                  submitDisabled={!editCut.hasEditPrivileges}
                  deleteDisabled={!editCut.hasEditPrivileges}
                />
              </fieldset>
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText={'Are you sure want to delete this cut?'}
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={handleDelete}
              approveButtonText={'Delete'}
            />
            <AddOffer
              getOffers={getOffers}
              addOffers={addOffers}
              setAddOffers={setAddOffers}
            />
            <Dialog
              isVisible={isSaveCutAlert}
              headingText="Do you want to save the changes to the current Cut before proceeding?"
              onRefuse={handleSaveAlertClose}
              refuseButtonText="Revert Changes"
              onApprove={onSaveAlert}
              approveButtonText="Update"
            />
            <Dialog
              isVisible={isCutMoveAlert}
              headingText="Do you want to save the changes to the current Cut before moving the Cut?"
              onRefuse={onMoveCutRevert}
              refuseButtonText="Move Without Changes"
              onApprove={onMoveCutSave}
              approveButtonText="Update And Move"
            />
            <AddItem
              closeEditCutModal={wipeState}
              getItems={getItems}
              putCut={putCut}
              addItems={addItems}
              setAddItems={setAddItems}
              postDummy={postDummy}
              canSpawnArtPatches={canSpawnArtPatches}
              spawnArtPatch={spawnArtPatch}
              editCut={editCut}
              cutTimestampRef={cutTimestampRef}
            />
            <AddPropItem
              addPropItem={addPropItem}
              setAddPropItem={setAddPropItem}
              postPropItem={postPropItem}
              closeEditCutModal={wipeState}
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditCut
