export function finalCopyString(values, includePrice, add) {
  let copyString =
    values[`${add}target_exclusive`] === true ? 'Only at Target.' : ''

  includePrice &&
    (copyString +=
      values.promo_retail !== null && values.promo_retail !== ''
        ? ' ' + values.promo_retail + ' Sale'
        : '')

  includePrice &&
    values.coupon_retail !== null &&
    values.coupon_retail !== '' &&
    (copyString += ' ' + values.coupon_retail)

  includePrice &&
    (values.coupon_retail === null || values.coupon_retail === '') &&
    (values.promo_retail === null || values.promo_retail === '') &&
    (copyString += ' ' + values.reg_unit_retail)

  values.final_product_name && (copyString += ' ' + values.final_product_name)

  includePrice &&
    (values.coupon_retail !== null || values.coupon_retail !== '') &&
    values.promo_retail !== null &&
    values.promo_retail !== '' &&
    (copyString += ' Reg. ' + values.reg_unit_retail)

  values.age_range && (copyString += ' ' + values.age_range)

  values.dpci && (copyString += ' ' + values.dpci)

  values[`${add}assorted`] && (copyString += ' Assortment varies by store.')

  values[`${add}available_online`] &&
    !values[`${add}available_instore`] &&
    (copyString += ' Available online only.')

  values[`${add}available_instore`] &&
    !values[`${add}available_online`] &&
    (copyString += ' Available in stores only.')

  values.additional_product_info &&
    (copyString += ' ' + values.additional_product_info)

  return copyString.replace(/\s+/g, ' ').trim()
}
