import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from '@react-pdf/renderer'

import noImagePlaceholder from '../../media/NoImagePlaceholder.png'

const cellWidth = 80
const labelContentWidth = 250

const ItemsPDF = ({ items = [], projectTitle = '' }) => {
  const columnData = [
    { id: 'image_url', label: 'Thumbnail', size: 2 },
    { id: 'tcin', label: 'TCIN', size: 1 },
    { id: 'dpci', label: 'DPCI', size: 1 },
    { id: 'item_name', label: 'Item Description', size: 1 },
    { id: 'file_image_name', label: 'Image File Name', size: 1 },
    { id: 'image_type', label: 'Image Type', size: 1 },
    { id: 'image_collected', label: 'Image Collected', size: 1 },
  ]

  // Create styles
  const styles = StyleSheet.create({
    chip: {
      alignItems: 'center',
      borderRadius: 15,
      color: 'white',
      fontweight: '500',
      justifyContent: 'center',
      marginRight: 4,
      minWidth: 55,
      padding: '3px 7px',
    },
    hyperLink: {
      maxWidth: 110,
      overflow: 'hidden',
    },
    labelContent: {
      alignItems: 'center',
      flexDirection: 'row',
      fontSize: 15,
      justifyContent: 'flex-start',
      maxHeight: 100,
      minHeight: 50,
      padding: 9,
      width: 220,
    },
    labelHeader: {
      alignItems: 'center',
      flexDirection: 'row',
      fontSize: 14,
      fontWeight: '1500',
      justifyContent: 'flex-start',
      minHeight: 50,
      padding: 9,
      width: 220,
    },
    mainContainer: {
      alignItems: 'flex-start',
      backgroundColor: 'white',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 10,
    },
    page: {
      alignItems: 'center',
      backgroundColor: '#E4E4E4',
      padding: 10,
    },

    section: {
      alignItems: 'center',
      flexDirection: 'row',
      flexGrow: 1,
      marginBottom: 10,
      paddingLeft: 20,
    },
    sectionTop: {
      alignItems: 'center',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'space-between',
      margin: 20,
      padding: 20,
      textAlign: 'center',
      width: labelContentWidth + cellWidth * 7,
    },
    title: {
      justifyContent: 'center',
    },
  })
  return (
    <Document useOC>
      <Page size="A2" orientation="landscape" style={styles.page}>
        <View style={styles.mainContainer}>
          <View style={styles.sectionTop}>
            <Text style={{ fontSize: 10 }}>
              {`Created At: ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}
            </Text>
            <Text style={{ fontSize: 20 }}>{projectTitle}</Text>
          </View>

          <View key="item_table" style={styles.section}>
            {columnData.map((column = {}) => (
              <View style={styles.labelHeader}>
                <Text key={column.id} style={styles.cell}>
                  {column.label}
                </Text>
              </View>
            ))}
          </View>
          <>
            {items.map((item, index) => (
              <View key={index} style={styles.section}>
                <View style={styles.labelContent}>
                  {item.image_url === null && item.image_url === '' ? (
                    ''
                  ) : (
                    <Image src={item.image_url || noImagePlaceholder} />
                  )}
                </View>
                <View style={styles.labelContent}>
                  <Text>{item.tcin}</Text>
                </View>
                <View style={styles.labelContent}>
                  <Text>{item.dpci}</Text>
                </View>
                <View style={styles.labelContent}>
                  <Text> {item.item_name}</Text>
                </View>
                <View style={styles.labelContent}>
                  {item.file_image_name?.includes('https') ? (
                    <Link
                      style={styles.hyperLink}
                      href={item.file_image_name}
                      rel="noreferrer"
                    >
                      'Assethub Link'
                    </Link>
                  ) : (
                    <Text style={styles.hyperLink}>
                      {' '}
                      {item.file_image_name}
                    </Text>
                  )}
                </View>
                <View style={styles.labelContent}>
                  <Text>{item.image_type}</Text>
                </View>
                {item.image_collected ? (
                  <View
                    style={[
                      styles.chip,
                      {
                        backgroundColor:
                          item.image_collected === 'Yes'
                            ? '#3838a3'
                            : '#e2ba0c',
                      },
                    ]}
                  >
                    <Text>{item.image_collected}</Text>
                  </View>
                ) : (
                  <View style={styles.labelContent}>
                    <Text>{item.image_collected}</Text>
                  </View>
                )}
              </View>
            ))}
          </>
        </View>
      </Page>
    </Document>
  )
}

export default ItemsPDF
