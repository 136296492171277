import {
  Button,
  Card,
  ExpandableSection,
  Grid,
  Heading,
  Chip,
  Form,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash.isempty'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import '../scss/ExpandableCategory.scss'
import SortableOfferContainer from './SortableOfferContainer'

const ExpandableCategory = (props) => {
  const { category, getCategory, putCategory, putDWAOffer, lockedOut } = props
  const [expanded, setExpanded] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [eaNotes, setEaNotes] = useState()
  const wadId = useParams().wadId

  const handleUpdateEANotes = () => {
    putCategory({
      body: {
        categoryId: category.categoryId,
        eaNotes: eaNotes,
        id: category.id,
        weeklyAdId: wadId,
      },
    })
  }
  return (
    <Card className="hc-bg-grey07">
      <ExpandableSection
        padding="dense"
        expanded={expanded}
        onExpand={() => {
          if (!expanded) {
            getCategory({ categoryId: category.categoryId, id: category.id })
          }
          setExpanded(!expanded)
        }}
      >
        <Grid.Container spacing="none" align="center" justify="space-between">
          <Grid.Item xs={2}>
            <Heading size={5}>{category.name}</Heading>
          </Grid.Item>
          <Grid.Item xs={7}>
            {!isEmpty(category?.divisions)
              ? category?.divisions?.map(
                  (division) =>
                    division && (
                      <Chip
                        size="dense"
                        key={division.id}
                        style={{
                          backgroundColor: division.color,
                          border: '0px',
                          color: 'white',
                        }}
                      >
                        {division.shortCode?.toUpperCase()}
                      </Chip>
                    )
                )
              : null}
          </Grid.Item>
          {/* <Grid.Item xs={1}>
            <Button
              icon
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} color="#000000" />
            </Button>
          </Grid.Item> */}
        </Grid.Container>
        <ExpandableSection.Content>
          <Grid.Container
            className="hc-pt-none"
            justify="center"
            align="center"
          >
            <Grid.Item xs={12}>
              <Grid.Container align="center">
                <Grid.Item
                  xs={4}
                  className="hc-ta-right hc-pr-dense hc-mr-none"
                >
                  <strong>EA Notes:</strong>
                </Grid.Item>
                {editMode ? (
                  <Grid.Item
                    xs={4}
                    className="hc-pl-none hc-ml-dense hc-pr-none"
                  >
                    <Form.Field
                      value={eaNotes}
                      onClick={(event) => event.stopPropagation()}
                      onChange={(event) => setEaNotes(event.target.value)}
                    />
                  </Grid.Item>
                ) : (
                  category.eaNotes
                )}
                <Grid.Item>
                  <div>
                    <Tooltip
                      location="top-right"
                      content={editMode ? 'Update EA Notes' : 'Edit EA Notes'}
                    >
                      <Button
                        iconOnly
                        onClick={(event) => {
                          event.stopPropagation()
                          if (editMode) {
                            handleUpdateEANotes()
                          } else {
                            setEaNotes(category.eaNotes)
                          }
                          setEditMode(!editMode)
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: 'grey' }}
                          icon={faPencilAlt}
                          color="#000000"
                        />
                      </Button>
                    </Tooltip>
                  </div>{' '}
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <SortableOfferContainer
            lockedOut={lockedOut}
            category={category}
            putDWAOffer={putDWAOffer}
          />
        </ExpandableSection.Content>
      </ExpandableSection>
    </Card>
  )
}
export default ExpandableCategory
