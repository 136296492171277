import {
  Card,
  Grid,
  Heading,
  TargetLogo,
  Button,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@enterprise-ui/canvas-ui-css'
import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'

import '../../scss/Modal.scss'
import '../../scss/modal_size.scss'

export const ProductInfoSheetView = (props) => {
  const { productInfoSheet, setProductInfoSheet, product, page, catalog } =
    props

  const latestTime = {
    curTime: new Date().toLocaleString(),
  }

  const wipeState = () => {
    setProductInfoSheet({ body: {}, show: false })
  }

  const handleDialogClose = () => {
    wipeState()
  }

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div className="hc-pa-normal hc-pt-none">
          <Card>
            <div data-testid="productInfoSheet" className="hc-pa-normal">
              <Grid.Container spacing="normal" justify="space-between">
                <Grid.Item xs={7} className="hc-ta-left hc-pa-dense">
                  <div style={{ float: 'left', marginRight: '10px' }}>
                    <TargetLogo size="expanded" />
                  </div>
                  <Heading size="4">
                    Target Catalog Product Information Sheet
                  </Heading>
                </Grid.Item>
                {/* only show this on PDF when downloaded */}
                <Grid.Item xs={5} className="hc-ta-right hc-pa-dense">
                  <Heading size="4">{latestTime.curTime}</Heading>
                </Grid.Item>
                <Grid.Item
                  xs={12}
                  className="hc-ta-left hc-pa-dense"
                  style={{ background: '#757575' }}
                >
                  <Heading size="5" style={{ color: '#fff' }}>
                    Catalog Information
                  </Heading>
                </Grid.Item>
                <Grid.Item xs={6} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Catalog Name:
                    </div>
                    {catalog.name}
                  </p>
                </Grid.Item>
                <Grid.Item xs={3} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Run Date:
                    </div>
                    {catalog.runDate}
                  </p>
                </Grid.Item>
                <Grid.Item xs={3} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      End Date:
                    </div>
                    {catalog.endDate}
                  </p>
                </Grid.Item>
                <Grid.Item
                  xs={12}
                  className="hc-ta-left hc-pa-dense"
                  style={{ background: '#757575' }}
                >
                  <Heading size={5} style={{ color: '#fff' }}>
                    Contact Information
                  </Heading>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Buyer Name:
                    </div>
                    {product.buyer}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Vendor Company:
                    </div>
                    {product.vendor_name}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Vendor ID:
                    </div>
                    {product.vendor_id}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Vendor Contact Name:
                    </div>
                    {product.vendor_contact_name}
                  </p>
                </Grid.Item>
                <Grid.Item xs={3} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Vendor Funded:
                    </div>
                    {(() => {
                      switch (product.vendor_funded) {
                        case true:
                          return 'Yes'
                        case false:
                          return 'No'
                        default:
                          return 'No'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={5} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Vendor Contact Email:
                    </div>
                    {product.vendor_contact_email}
                  </p>
                </Grid.Item>
                <Grid.Item
                  xs={12}
                  className="hc-ta-left hc-pa-dense"
                  style={{ background: '#757575' }}
                >
                  <Heading size="5" style={{ color: '#fff' }}>
                    Product Information
                  </Heading>
                </Grid.Item>
                <Grid.Item xs={8} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Product Name:
                    </div>
                    {product.name}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      DPCI:
                    </div>
                    {product.dpci}
                  </p>
                </Grid.Item>
                <Grid.Item xs={8} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Product Brand:
                    </div>
                    {product.brand}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      TCIN:
                    </div>
                    {product.tcin}
                  </p>
                </Grid.Item>
                <Grid.Item xs={12} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Category:
                    </div>
                    {(() => {
                      switch (product.category) {
                        case 'Toys':
                          return 'Toys'
                        case 'Ent':
                          return 'Entertainment'
                        case 'Elec':
                          return 'Electronics'
                        case 'SPRT':
                          return 'Sporting Goods'
                        case 'Home':
                          return 'Home'
                        case 'F&B':
                          return 'F&B'
                        case 'A&A':
                          return 'A&A'
                        case 'ESS/BTY':
                          return 'ESS/BTY'
                        default:
                          return 'No'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Page Number:
                    </div>
                    {page.pageOrdering + 1}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Page Type:
                    </div>
                    {(() => {
                      switch (product.page_type) {
                        case 'full':
                          return 'Full'
                        case 'half':
                          return 'Half'
                        case 'item':
                          return 'Item'
                        default:
                          return 'Full'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Page Section:
                    </div>
                    {page.section.toString()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      List Regular Retail:
                    </div>
                    {(() => {
                      switch (product.list_reg_retail) {
                        case true:
                          return 'Yes'
                        case false:
                          return 'No'
                        default:
                          return 'Yes'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Set Date:
                    </div>
                    {product.set_date}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Regular Price:
                    </div>
                    ${product.reg_unit_retail}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Sale Price:
                    </div>
                    ${product.promo_retail}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Coupon Price:
                    </div>
                    ${product.coupon_retail}
                  </p>
                </Grid.Item>
                <Grid.Item xs={12} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Vendor Comments:
                    </div>
                    {product.vendor_comments}
                  </p>
                </Grid.Item>
                <Grid.Item xs={2} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Reference Image:
                    </div>
                    {product?.reference_image ? (
                      <img
                        src={`data:image/png;base64,${product?.reference_image}`}
                        width="150px"
                        alt="n/a"
                      />
                    ) : (
                      <div>
                        <FontAwesomeIcon icon={faImage} size="lg" color="red" />
                        {' - '}
                        please add image
                      </div>
                    )}
                  </p>
                </Grid.Item>
                <Grid.Item xs={5} className="hc-ta-left hc-pa-dense">
                  {' '}
                </Grid.Item>
                <Grid.Item xs={5} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div style={{ fontWeight: 'bold' }}>Product Features:</div>
                    {product.features}
                  </p>
                </Grid.Item>
                <Grid.Item xs={7} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Assorted Product:
                    </div>
                    {(() => {
                      switch (product.assorted) {
                        case true:
                          return 'Yes'
                        case false:
                          return 'No'
                        default:
                          return 'No'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={5} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div style={{ fontWeight: 'bold' }}>
                      Product Dimensions:
                    </div>
                    Depth: {product.depth} Width: {product.width} Height:{' '}
                    {product.height} {product.units}
                  </p>
                </Grid.Item>
                <Grid.Item xs={7} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Assembly Required:
                    </div>
                    {product.assembly_required}
                  </p>
                </Grid.Item>
                <Grid.Item xs={5} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Age Range:
                    </div>
                    {product.age_range}
                  </p>
                </Grid.Item>
                <Grid.Item xs={7} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Target Exclusive:
                    </div>
                    {(() => {
                      switch (product.targe_exclusive) {
                        case true:
                          return 'Yes'
                        case false:
                          return 'No'
                        default:
                          return 'No'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={5} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Size Range:
                    </div>
                    {product.size_range}
                  </p>
                </Grid.Item>
                <Grid.Item xs={7} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Sold in Stores:
                    </div>
                    {(() => {
                      switch (product.available_instore) {
                        case true:
                          return 'Yes'
                        case false:
                          return 'No'
                        default:
                          return 'No'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={5} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Sold Online:
                    </div>
                    {(() => {
                      switch (product.available_online) {
                        case true:
                          return 'Yes'
                        case false:
                          return 'No'
                        default:
                          return 'No'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item
                  xs={12}
                  className="hc-ta-left hc-pa-dense"
                  style={{ background: '#757575' }}
                >
                  <Heading size="5" style={{ color: '#fff' }}>
                    Art Direction Information
                  </Heading>
                </Grid.Item>
                <Grid.Item xs={12} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Art Director Notes:
                    </div>
                    {product.art_director_notes}
                  </p>
                </Grid.Item>
                <Grid.Item xs={8} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      How should this product be photographed?
                    </div>
                    {product.photo_notes}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Model Gender/Age:
                    </div>
                    {product.gender_age_preference}
                  </p>
                </Grid.Item>
                <Grid.Item xs={8} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Model Needs:
                    </div>
                    {product.model_needs}
                  </p>
                </Grid.Item>
                <Grid.Item xs={4} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Fig/Model:
                    </div>
                    {(() => {
                      switch (product.fig_model) {
                        case true:
                          return 'Yes'
                        case false:
                          return 'No'
                        default:
                          return 'No'
                      }
                    })()}
                  </p>
                </Grid.Item>
                <Grid.Item xs={12} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Does this product need to be shot with additional features
                      or props that are sold separately?
                    </div>
                    {product.prop_needs}
                  </p>
                </Grid.Item>
                <Grid.Item
                  xs={12}
                  className="hc-ta-left hc-pa-dense"
                  style={{ background: '#757575' }}
                >
                  <Heading size="5" style={{ color: '#fff' }}>
                    Copywriter Information
                  </Heading>
                </Grid.Item>
                <Grid.Item xs={12} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Additional Product Info:
                    </div>
                    {product.additional_product_info}
                  </p>
                </Grid.Item>
                <Grid.Item xs={12} className="hc-ta-left hc-pa-dense">
                  <p>
                    <div
                      style={{
                        float: 'left',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Product Copy:
                    </div>
                    {product.final_copy}
                  </p>
                </Grid.Item>
              </Grid.Container>
            </div>
          </Card>
        </div>
      )
    }
  }
  const componentRef = useRef()

  return (
    productInfoSheet.show && (
      <Modal
        isVisible={productInfoSheet.show}
        onRefuse={handleDialogClose}
        className="larger_modal"
      >
        <ReactToPrint
          trigger={() => (
            <Grid.Container spacing="normal">
              <Grid.Item xs={12} className="hc-ta-left hc-pa-expanded">
                <Button type="secondary">Print PDF</Button>
              </Grid.Item>
            </Grid.Container>
          )}
          content={() => componentRef.current}
        />
        <ComponentToPrint ref={componentRef} />
      </Modal>
    )
  )
}
