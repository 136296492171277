import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import weeklyAdMapper from '../mappers/weeklyAdMapper'

const usePutWeeklyAd = (wadId, setLoading, setWad, setToast) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.weeklyAd + wadId)
  const [body, setBody] = useState(null)

  useEffect(() => {
    let mounted = true
    const putWad = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.put(url.current, body)
        setWad(weeklyAdMapper(response.data))
      } catch (e) {
        setToast({
          color: 'error',
          headingText: e.response.data.error || 'Error',
          isVisible: true,
          message: e.response.data.message || 'Something went wrong.',
        })
      }
      mounted && setLoading(false)
      mounted && setBody(null)
    }

    if (body !== null) {
      putWad()
    }
    return () => {
      mounted = false
    }
  }, [body, setLoading, setWad, setToast])

  return setBody
}
export default usePutWeeklyAd
