import {
  Anchor,
  Button,
  Card,
  Grid,
  Heading,
  List,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faLeaf, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash.isempty'

import appConfig from '../config/appConfig'
import { formatToDateTime } from '../util/MomentUtil'
import { checkOffersValidity } from '../util/MomentUtil'

import MarketListTooltip from './MarketListTooltip'

const OfferCard = (props) => {
  const { offer, index, liner, lockoutDate, isMultiple, weeklyAdStartDate } =
    props
  const isOfferValid = checkOffersValidity(
    weeklyAdStartDate,
    offer.startDate,
    offer.endDate
  )
  const config = appConfig()
  const linkProps = {
    href: `${config.ivy.uiUrl}promotion/${offer.id}/edit/general`,
    target: '_blank',
  }

  let details = liner
    ? offer?.details
    : offer?.offerDetails?.filter((detail) => detail.index === index)
  //if no matching details found, show all details,
  //if offer.offerDetails is null/undefined set details to empty array

  if (isEmpty(details)) details = offer?.offerDetails || []
  let markets = details.flatMap((detail) => detail.markets)

  const rewardTimestamp = (details) => details[0].rewardValueUpdatedTimestamp
  const previousRewardValue = (details) => details[0].previousRewardValue
  const toOfferList = (details) => {
    if (details.length < 2) {
      return details[0].promoUnitRetail
    }
    const promos = details.map((detail) => detail.promoUnitRetail)
    return (
      <List>
        {promos.map((promo, idx) => (
          <List.Item
            key={promo}
            divider={idx !== promos.length - 1}
            className="hc-pl-none"
            style={{ borderLeft: 0 }}
          >
            {promo}
          </List.Item>
        ))}
      </List>
    )
  }
  return (
    <Card
      className={'hc-pa-dense hc-mt-dense hc-mb-dense hc-bg-grey06'}
      key={offer.id}
    >
      <Grid.Container justify="space-between">
        <Grid.Item>
          <Heading size={5} className="hc-pb-normal">
            {offer.name}
          </Heading>
        </Grid.Item>
        {!isOfferValid && (
          <Grid.Item style={{ color: !isOfferValid ? 'darkred' : '' }}>
            Invalid Offer
          </Grid.Item>
        )}
        <Grid.Item>
          <Tooltip content={`${config.ivy.uiUrl}`} location="left">
            <Button className="hc-bg-success" raised icon as="a" {...linkProps}>
              <FontAwesomeIcon icon={faLeaf} />
            </Button>
          </Tooltip>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container spacing="dense" align="center">
        {isMultiple && (
          <>
            <Grid.Item
              xs={2}
              className="hc-ta-right"
              style={{ color: !isOfferValid ? 'darkred' : '' }}
            >
              <strong>{`Start Date `}</strong>
            </Grid.Item>
            <Grid.Item xs={4} style={{ color: !isOfferValid ? 'darkred' : '' }}>
              {offer.startDate}
            </Grid.Item>
            <Grid.Item
              xs={2}
              style={{ color: !isOfferValid ? 'darkred' : '' }}
              className="hc-ta-right"
            >
              <strong>{`End Date `}</strong>
            </Grid.Item>
            <Grid.Item xs={4} style={{ color: !isOfferValid ? 'darkred' : '' }}>
              {offer.endDate}
            </Grid.Item>
          </>
        )}
        <Grid.Item xs={2} className="hc-ta-right">
          <strong>{`Id `}</strong>
        </Grid.Item>
        <Grid.Item xs={4}>
          <Anchor {...linkProps}>{offer.id}</Anchor>
        </Grid.Item>
        <Grid.Item xs={2} className="hc-ta-right">
          <strong>{`Markets `}</strong>
        </Grid.Item>
        <Grid.Item xs={4}>
          <MarketListTooltip markets={markets} size={3} />
        </Grid.Item>
        <Grid.Item xs={2} className="hc-ta-right">
          <strong>{`Status `}</strong>
        </Grid.Item>
        <Grid.Item
          xs={4}
          style={{
            color: `${offer.status === 'deactivated' ? 'red' : 'black'}`,
          }}
        >
          {offer.status}
        </Grid.Item>

        <Grid.Item xs={2} className="hc-ta-right">
          <strong>{`Type `}</strong>
        </Grid.Item>
        <Grid.Item xs={4}>{offer.type}</Grid.Item>
        <Grid.Item xs={2} className="hc-ta-right">
          <strong>{`Promo $ `}</strong>
        </Grid.Item>
        <Grid.Item
          xs={4}
          style={{
            color: `${
              lockoutDate < rewardTimestamp(details) &&
              previousRewardValue(details)
                ? 'red'
                : 'black'
            }`,
          }}
        >
          {toOfferList(details)}
          {previousRewardValue(details) && (
            <Tooltip
              location={'left'}
              style={{ float: 'right' }}
              content={`Offer Revised: ${previousRewardValue(
                details
              )} ${formatToDateTime(rewardTimestamp(details))}`}
            >
              <FontAwesomeIcon
                icon={faClock}
                size="xs"
                className="hc-clr-grey03"
              />
            </Tooltip>
          )}
        </Grid.Item>
        <Grid.Item xs={2} className="hc-ta-right">
          <strong>{`AB Email `}</strong>
        </Grid.Item>
        <Grid.Item xs={4}>{offer.abEmail}</Grid.Item>
      </Grid.Container>
    </Card>
  )
}
export default OfferCard
