import {
  ExpandableSection,
  Grid,
  Heading,
  Tooltip,
  Button,
  Chip,
  Spinner,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import {
  faPencilAlt,
  faClock,
  faCheckCircle,
  faTimesCircle,
  faPaperPlane,
  faFileAlt,
  faImage,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import ProductApi from '../api/pop-catalog/ProductApi'
import DragHandle from '../components/DragHandle'
import EditProduct from '../forms/EditProduct'
import { CategoryColorMap } from '../util/CategoryColorMap'
import {
  inCreativePageView,
  inVendorView,
  inProductListView,
} from '../util/CheckLocation'
import { canEditUpcomingCatalogs, isVendor } from '../util/CheckRole'
import { ProductInfoSheetView } from '../views/catalog/ProductInfoSheetView'

const ExpandableProducts = (props) => {
  const {
    product,
    setAssignProduct,
    isAssignPage,
    page,
    role,
    catalog,
    view,
    setProducts,
    isStatusUpdate,
    setSelectedProducts,
    isProductSelected,
    isBulkProductModal = false,
    approvedVendors,
    calltheProductApi,
  } = props

  const productApi = new ProductApi()
  const [expanded, setExpanded] = useState(false)
  const [expandedImage, setExpandedImage] = useState({})

  const [productInfoSheet, setProductInfoSheet] = useState({
    body: {},
    show: false,
  })

  const [editProduct, setEditProduct] = useState({ body: {}, show: false })

  const isAdminOrCreative = inCreativePageView(view)
  const isProductList = inProductListView(view)
  const isVendorView = inVendorView(view)
  const isCreativeDeniedProduct =
    isAdminOrCreative && product.status === 'denied'

  // Move this out
  const editPerms =
    role.some((effectiveRole) => canEditUpcomingCatalogs(effectiveRole)) ||
    (isVendorView && isVendor(role))

  const query = new URLSearchParams()
  const getImageOnExpand = () => {
    !product.reference_image &&
      productApi
        .getProductImage(query, product.id)
        .then((res) => {
          product.reference_image = res.data.reference_image
          setExpandedImage(res.data.reference_image)
        })
        .catch((e) => console.log(e))
  }

  const onProductSelect = () => {
    setSelectedProducts((prods) => {
      if (isProductSelected) {
        delete prods[product.id]
        return { ...prods }
      }
      return {
        ...prods,
        [product.id]: {
          id: product.id,
          last_updated_timestamp: product.last_updated_timestamp,
        },
      }
    })
  }

  return (
    <>
      <ExpandableSection
        className={`hc-ma-dense ${
          isCreativeDeniedProduct ? 'hc-bg-grey03' : ''
        }`}
        padding="none"
        expanded={expanded}
        onExpand={() => {
          setExpanded(!expanded)
          getImageOnExpand()
        }}
      >
        <Grid.Container align="center" spacing="none">
          {inCreativePageView(view) && <DragHandle />}
          <Grid.Item xs={3}>
            {inCreativePageView(view) ? `${product.productOrdering + 1}: ` : ''}
            <strong>{product.name}</strong>
          </Grid.Item>

          <Grid.Item
            xs={inCreativePageView(view) ? 1 : 2}
            className={!product.validated_dpci && 'hc-clr-error'}
          >
            <Tooltip content="Valid DPCI" location="top">
              {product.dpci}
            </Tooltip>
          </Grid.Item>

          <Grid.Item xs={1}>
            <Chip
              className="hc-ml-normal"
              size="dense"
              style={{
                backgroundColor: CategoryColorMap[product.category],
                border: '0px',
                color: 'white',
              }}
            >
              {product.category}
            </Chip>
          </Grid.Item>
          {isBulkProductModal && (
            <>
              <Grid.Item xs={2}>
                <strong>{product.buyer}</strong>
              </Grid.Item>
              <Grid.Item xs={2}>
                <strong>{product.reg_unit_retail}</strong>
              </Grid.Item>
              <Grid.Item>
                {expandedImage ? (
                  <img
                    src={`data:image/png;base64,${expandedImage}`}
                    width="50px"
                    alt="n/a"
                  />
                ) : (
                  <Spinner />
                )}
              </Grid.Item>
            </>
          )}
          {!isBulkProductModal && (
            <>
              {isVendorView ? (
                <>
                  {' '}
                  <Grid.Item xs={5}></Grid.Item>
                </>
              ) : (
                <>
                  <Grid.Item xs={1}>
                    <strong>Page:</strong> {PageNumbers(product)}{' '}
                    {page?.section?.length > 1 &&
                      ` - Section: ${product.section}`}
                  </Grid.Item>
                  {inCreativePageView(view) ? (
                    <Grid.Item xs={3}>
                      <strong>FPN:</strong>
                      {product.final_product_name}
                    </Grid.Item>
                  ) : (
                    <Grid.Item xs={3}>{product?.vendor_name}</Grid.Item>
                  )}
                </>
              )}

              <Grid.Item className="hc-ml-dense hc-mr-dense">
                <ProductStatus status={product.status} />
              </Grid.Item>

              {editPerms && (
                <Grid.Item className="hc-ml-dense hc-mr-dense hc-ta-right">
                  <Tooltip
                    location="top-left"
                    content="Edit Product Information"
                  >
                    <Button
                      aria-label={`edit product ${product.name}`}
                      iconOnly
                      onClick={(event) => {
                        event.stopPropagation()
                        setEditProduct({
                          body: product,
                          show: true,
                        })
                      }}
                      disabled={isCreativeDeniedProduct}
                    >
                      <FontAwesomeIcon color="black" icon={faPencilAlt} />
                    </Button>
                  </Tooltip>
                </Grid.Item>
              )}
              {!isVendorView && isAssignPage && editPerms && (
                <Grid.Item className="hc-ml-dense hc-mr-dense">
                  <Tooltip
                    location="top-left"
                    content={
                      product?.status === 'accepted'
                        ? 'Assign Page'
                        : 'Accept Product'
                    }
                  >
                    <Button
                      iconOnly
                      aria-label={`assign product ${product.name}`}
                      onClick={(event) => {
                        event.stopPropagation()
                        setAssignProduct({ product: product, show: true })
                      }}
                    >
                      <FontAwesomeIcon color="black" icon={faPaperPlane} />
                    </Button>
                  </Tooltip>
                </Grid.Item>
              )}
              {isStatusUpdate && product.status === 'pending' && (
                <Grid.Item className="hc-ml-dense hc-mr-dense">
                  <Input.Checkbox
                    className="hc-clr-grey04"
                    type="checkbox"
                    checked={isProductSelected}
                    onClick={onProductSelect}
                  />
                </Grid.Item>
              )}
              {isAdminOrCreative && (
                <Grid.Item className="hc-ml-dense hc-mr-dense">
                  <Tooltip
                    location="top-left"
                    content="View Product Information Sheet"
                  >
                    <Button
                      iconOnly
                      aria-label={`set info product ${product.name}`}
                      onClick={(event) => {
                        event.stopPropagation()
                        setProductInfoSheet({ body: product, show: true })
                      }}
                    >
                      <FontAwesomeIcon color="black" icon={faFileAlt} />
                    </Button>
                  </Tooltip>
                </Grid.Item>
              )}
            </>
          )}
        </Grid.Container>
        <ExpandableSection.Content>
          <div className="hc-pa-normal hc-pa-dense hc-mt-normal">
            <Grid.Container className="hc-bg-contrast-weak hc-mb-none">
              {!isAdminOrCreative && (
                <>
                  <Grid.Item xs={6}>
                    <strong> Buyer Name: </strong> {product.buyer}
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <strong> Additional Product Info: </strong>
                    {product.additional_product_info}
                  </Grid.Item>
                </>
              )}
              <Grid.Item
                xs={12}
                className="hc-ta-left hc-pa-dense"
                style={{ background: '#757575' }}
              >
                <Heading size="8" style={{ color: '#fff' }}>
                  Product Information
                </Heading>
              </Grid.Item>
              <Grid.Item xs={6}>
                {isAdminOrCreative ? (
                  <>
                    <Grid.Item>
                      <strong> Final Copy: </strong>
                      {product.final_copy}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Final Product Name: </strong>
                      {product.final_product_name}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Additional Product Info: </strong>
                      {product.additional_product_info}
                    </Grid.Item>
                  </>
                ) : (
                  <></>
                )}
                <Grid.Item>
                  <strong> Assorted: </strong> {String(product.assorted)}
                </Grid.Item>
                <Grid.Item>
                  <strong> Assembly Required: </strong>{' '}
                  {product.assembly_required}
                </Grid.Item>
                <Grid.Item>
                  <strong> Target Exclusive: </strong>{' '}
                  {String(product.target_exclusive)}
                </Grid.Item>
                <Grid.Item>
                  <strong> Reg Retail: </strong>
                  {product.reg_unit_retail}
                </Grid.Item>
                {isProductList && (
                  <>
                    <Grid.Item>
                      <strong> IVY Offer ID: </strong> {product.ivy_offer_id}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Vendor Funded: </strong> {product.vendor_funded}
                    </Grid.Item>
                  </>
                )}
                {isAdminOrCreative ? (
                  <>
                    <Grid.Item>
                      {' '}
                      <strong> Vendor Name: </strong>
                      {product.vendor_name}
                    </Grid.Item>

                    <Grid.Item>
                      <strong> Page Type: </strong>
                      {product.page_type}
                    </Grid.Item>
                  </>
                ) : (
                  <>
                    <Grid.Item>
                      <strong> Vendor Comments: </strong>{' '}
                      {product.vendor_comments}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Brand: </strong> {product.brand}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Features: </strong> {product.features}
                    </Grid.Item>
                  </>
                )}
                {isProductList && (
                  <>
                    <Grid.Item>
                      <strong> Set Date: </strong> {product.set_date}
                    </Grid.Item>
                  </>
                )}
                <Grid.Item>
                  <strong> Reference Image: </strong>
                </Grid.Item>
                <Grid.Item>
                  {product.reference_image ? (
                    <img
                      src={`data:image/png;base64,${product.reference_image}`}
                      width="150px"
                      alt="n/a"
                    />
                  ) : product.reference_image !== '' ? (
                    <Spinner />
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faImage} size="lg" color="red" />
                      {' - '}
                      please add image
                    </div>
                  )}
                </Grid.Item>
              </Grid.Item>
              <Grid.Item xs={6}>
                <Grid.Item>
                  <strong> Age Range: </strong> {product.age_range}
                </Grid.Item>
                <Grid.Item>
                  <strong> Size Range: </strong> {product.size_range}
                </Grid.Item>
                {isAdminOrCreative && (
                  <>
                    <Grid.Item>
                      <strong> Depth: </strong> {product.depth}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Width: </strong> {product.width}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Height: </strong> {product.height}
                    </Grid.Item>
                    <Grid.Item>
                      <strong>Unit Measure: </strong> {product.units}
                    </Grid.Item>
                  </>
                )}
                <Grid.Item>
                  <strong> Available Online: </strong>{' '}
                  {String(product.available_online)}
                </Grid.Item>
                <Grid.Item>
                  <strong> Available in Store: </strong>{' '}
                  {String(product.available_instore)}
                </Grid.Item>
                {(isAdminOrCreative || isProductList) && (
                  <>
                    <Grid.Item>
                      <strong> Promo Retail: </strong>
                      {product.promo_retail}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Coupon Retail: </strong>
                      {product.coupon_retail}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> List Reg Retail: </strong>
                      {product.list_reg_retail}
                    </Grid.Item>
                  </>
                )}
                {!isAdminOrCreative && (
                  <>
                    <Grid.Item>
                      <strong> TCIN: </strong> {product.tcin}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Mapped Item: </strong>{' '}
                      {product.mapped_item ? String(product.mapped_item) : ''}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Map Floor: </strong> {product.map_floor}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Depth: </strong> {product.depth}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Width: </strong> {product.width}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Height: </strong> {product.height}
                    </Grid.Item>
                    <Grid.Item>
                      <strong>Unit Measure: </strong> {product.units}
                    </Grid.Item>
                  </>
                )}
              </Grid.Item>
              {isProductList && (
                <>
                  <Grid.Item
                    xs={12}
                    className="hc-ta-left hc-pa-dense"
                    style={{ background: '#757575' }}
                  >
                    <Heading size="8" style={{ color: '#fff' }}>
                      Art Direction Information
                    </Heading>
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Grid.Item>
                      <strong> Photo Notes: </strong> {product.photo_notes}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Model Needs: </strong> {product.model_needs}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Prop Needs: </strong> {product.prop_needs}
                    </Grid.Item>
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Grid.Item>
                      <strong> Gender/Age Preference: </strong>{' '}
                      {product.gender_age_preference}
                    </Grid.Item>
                    <Grid.Item>
                      <strong> Fig/Model: </strong> {String(product.fig_model)}
                    </Grid.Item>
                  </Grid.Item>
                </>
              )}
              {(isProductList || isVendorView) && (
                <>
                  <Grid.Item
                    xs={12}
                    className="hc-ta-left hc-pa-dense"
                    style={{ background: '#757575' }}
                  >
                    <Heading size="8" style={{ color: '#fff' }}>
                      Sample Information
                    </Heading>
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <strong> Sample Status: </strong> {product.sample_status}
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <strong> Sample Turn-In Date: </strong>{' '}
                    {product.sample_turn_in_date}
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <strong> Sample Submitted: </strong>{' '}
                    {String(product.sample_submitted)}
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <strong> Sample ID: </strong> {String(product.sample_id)}
                  </Grid.Item>
                </>
              )}
            </Grid.Container>
          </div>
        </ExpandableSection.Content>
      </ExpandableSection>
      <ProductInfoSheetView
        productInfoSheet={productInfoSheet}
        setProductInfoSheet={setProductInfoSheet}
        product={product}
        page={page}
        catalog={catalog}
      />
      <EditProduct
        approvedVendors={approvedVendors}
        editProduct={editProduct}
        setEditProduct={setEditProduct}
        view={view}
        setProducts={setProducts}
        role={role}
        calltheProductApi={calltheProductApi}
      />
    </>
  )
}

const ProductStatus = ({ status }) => {
  switch (status) {
    case 'accepted':
      return (
        <Tooltip location="top-left" content="Status: Accepted">
          <FontAwesomeIcon color="#006200" icon={faCheckCircle} />
        </Tooltip>
      )
    case 'denied':
      return (
        <Tooltip location="top-left" content="Status: Denied">
          <FontAwesomeIcon color="#E80018" icon={faTimesCircle} />
        </Tooltip>
      )
    case 'pending':
      return (
        <Tooltip location="top-left" content="Status: Pending">
          <FontAwesomeIcon color="#000000" icon={faClock} />
        </Tooltip>
      )
    default:
      return null
  }
}
export default ExpandableProducts

const PageNumbers = (product) =>
  product?.status === 'denied'
    ? 'N/A'
    : product?.page_ordering.length > 0
    ? product?.page_ordering
        .map((element) => element + 1)
        .sort()
        .join(' & ')
    : 'TBD'
