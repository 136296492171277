import Firefly from 'firefly-sdk-ts'

import apiConfig from '../config/apiConfig'

import { isValidCreativeHubADGroup } from './CheckRole'

const eventManager = Firefly.registerEventManagerWithConfig(apiConfig.firefly)

export const FIREFLY_INIT_EVENT = 'Init'
export const FIREFLY_SUCCESS_EVENT = 'Success'
export const FIREFLY_FAILURE_EVENT = 'Failure'
export const FIREFLY_NAVIGATION_EVENT = 'Navigation'
export const FIREFLY_CLICK_EVENT = 'Click'

export const handleFireflyEvent = (
  event_type,
  task_name,
  task_status,
  error_message,
  error_status_code,
  session = {},
  customMetricData = {}
) => {
  const {
    user_id = '',
    user_email = '',
    user_ad_group = '',
  } = fetchUserInfo(session)

  const date = new Date()
  const display_month =
    date.getUTCMonth() + 1 >= 10
      ? date.getUTCMonth() + 1
      : `0${date.getUTCMonth() + 1}`
  const display_day_of_month =
    date.getUTCDate() >= 10 ? date.getUTCDate() : `0${date.getUTCDate()}`
  const display_year = date.getUTCFullYear()
  const display_hour =
    date.getUTCHours() >= 10 ? date.getUTCHours() : `0${date.getUTCHours() + 1}`
  const display_minute =
    date.getUTCMinutes() >= 10
      ? date.getUTCMinutes()
      : `0${date.getUTCMinutes()}`
  const display_second =
    date.getUTCSeconds() >= 10
      ? date.getUTCSeconds()
      : `0${date.getUTCSeconds()}`

  const display_date = `${display_month}-${display_day_of_month}-${display_year}`
  const display_date_time = `${display_date} at ${display_hour}:${display_minute}:${display_second} UTC`

  const event_name = task_name.split('_').join(' ') + ' ' + task_status

  Firefly.setGlobalDataLayer(
    {
      application: {
        name: 'CreativeHub',
      },
    },
    apiConfig.firefly.persistMethod
  )

  switch (task_status) {
    case FIREFLY_NAVIGATION_EVENT:
      eventManager.addEvent({
        customMetrics: {
          metric1: display_date,
          metric2: display_date_time,
        },
        error: {
          id: error_status_code.toString(),
          message: error_message,
        },
        event: {
          name: event_name,
          type: event_type,
        },
        user: {
          customDimension1: user_email,
          customDimension2: user_ad_group,
          id: user_id,
        },
      })
      break
    case FIREFLY_INIT_EVENT:
      eventManager.addTaskInitEvent({
        customMetrics: {
          metric1: display_date,
          metric2: display_date_time,
        },
        error: {
          id: error_status_code.toString(),
          message: error_message,
        },
        event: {
          name: event_name,
          type: event_type,
        },
        task: { name: task_name },
        user: {
          customDimension1: user_email,
          customDimension2: user_ad_group,
          id: user_id,
        },
      })
      break
    case FIREFLY_SUCCESS_EVENT:
      eventManager.addTaskSuccessEvent({
        customMetrics: {
          metric1: display_date,
          metric2: display_date_time,
        },
        error: {
          id: error_status_code.toString(),
          message: error_message,
        },
        event: {
          name: event_name,
          type: event_type,
        },
        task: { name: task_name },
        user: {
          customDimension1: user_email,
          customDimension2: user_ad_group,
          id: user_id,
        },
      })
      break
    case FIREFLY_FAILURE_EVENT:
      eventManager.addTaskFailEvent({
        customMetrics: {
          metric1: display_date,
          metric2: display_date_time,
        },
        error: {
          id: error_status_code ? error_status_code.toString() : '404',
          message: error_message ? error_message : 'Not Found',
        },
        event: {
          name: event_name,
          type: event_type,
        },
        task: { name: task_name },
        user: {
          customDimension1: user_email,
          customDimension2: user_ad_group,
          id: user_id,
        },
      })
      break
    case FIREFLY_CLICK_EVENT:
      eventManager.addTaskSuccessEvent({
        customMetrics: {
          metric1: display_date,
          metric2: display_date_time,
          metric3: customMetricData.start_date,
        },
        error: {
          id: error_status_code.toString(),
          message: error_message,
        },
        event: {
          name: event_name,
          type: event_type,
        },
        guest: {
          eventType: 'click',
        },
        task: { name: task_name },
        user: {
          customDimension1: user_email,
          customDimension2: user_ad_group,
          customDimension3: customMetricData.message,
          id: user_id,
        },
      })
      break
    default:
  }
}

export const handleFireflyInit = (
  event_type,
  task_name,
  error_info,
  session
) => {
  handleFireflyEvent(
    event_type,
    task_name,
    FIREFLY_INIT_EVENT,
    error_info,
    200,
    session
  )
}

export const handleFireflyFailure = (
  event_type,
  task_name,
  error_info,
  session
) => {
  handleFireflyEvent(
    event_type,
    task_name,
    FIREFLY_FAILURE_EVENT,
    error_info?.data.error === undefined
      ? error_info?.data.Message
      : error_info?.data.error,
    error_info.status,
    session
  )
}

export const handleFireflySuccess = (
  event_type,
  task_name,
  error_info,
  session
) => {
  handleFireflyEvent(
    event_type,
    task_name,
    FIREFLY_SUCCESS_EVENT,
    error_info,
    200,
    session
  )
}

export const handleFireflyPageLoad = (
  event_type,
  task_name,
  error_info,
  session
) => {
  handleFireflyEvent(
    event_type,
    task_name,
    FIREFLY_NAVIGATION_EVENT,
    error_info,
    200,
    session
  )
}

export const handleFireflyClickInteraction = (
  event_type,
  task_name,
  error_info,
  session,
  customMetricData
) => {
  handleFireflyEvent(
    event_type,
    task_name,
    FIREFLY_CLICK_EVENT,
    error_info,
    200,
    session,
    customMetricData
  )
}

export const fetchUserInfo = (session = {}) => {
  const { identity = {} } = session
  const {
    display_name: user_id = '',
    mail: user_email = '',
    memberof: userGroups = [],
  } = identity

  let user_ad_group = ''
  userGroups.forEach((userGroup = {}) => {
    if (!user_ad_group) {
      const userAdGroup = userGroup.substring(3, userGroup.indexOf(',')) || ''
      if (isValidCreativeHubADGroup(userAdGroup?.toUpperCase())) {
        user_ad_group = userAdGroup
      }
    }
  })

  return { user_ad_group, user_email, user_id }
}
