const assetHubSearchList = (assetHubSearchList) => ({
  asset_id: assetHubSearchList.meta_data?.asset_id,
  available_channels: imageUsageInfo(assetHubSearchList.meta_data)[0]
    ?.available_channels,
  expiration_date: imageUsageInfo(assetHubSearchList.meta_data)[0]
    ?.expiration_date,
  file_name: assetHubSearchList.meta_data?.metadata_info?.file_name,
  import_date: assetHubSearchList.meta_data?.import_date,
  storage_url: assetHubSearchList.meta_data?.storage_url,
})

export const imageUsageInfo = (metaData = {}) => metaData.usage_info || []

export const assetHubSearchMapper = (searchResult) => ({
  search: searchResult.search
    ? searchResult.search.map((resultList) => assetHubSearchList(resultList))
    : [],
  totalResults: searchResult.total_results,
})
