const paginationMapper = (response, mapper, fieldName) => ({
  [fieldName]: response.content
    ? mapper
      ? response.content.map(mapper)
      : response.content
    : [],
  isFirstPage: response.first,
  isLastPage: response.last,
  isPageEmpty: response.empty,
  numberOfElementsThisPage: response.numberOfElements,
  pageNumber: response.number,
  pageSize: response.size,
  totalElements: response.totalElements,
  totalPages: response.totalPages,
})
export default paginationMapper
