import ivyDivisionMapper from './ivyDivisionMapper'
import offerArtPatchMapper from './offerArtPatchMapper'
import offerDetailMapper from './offerDetailMapper'

const offerMapper = (offer) => ({
  abEmail: offer.abEmail,
  channel: offer.channel,
  discountVaries: offer.discountVaries,
  endDate: offer.endDate,
  id: offer.id,
  ivyDivisions: offer?.ivyDivisions
    ? offer.ivyDivisions
        .filter((x) => !!x)
        .map(ivyDivisionMapper)
        .sort((a, b) => a?.id - b?.id)
    : [],
  lastModifiedTimestamp: offer.lastModifiedTimestamp,
  linkedToCut: offer.linkedToCut,
  name: offer.name,
  offerArtPatches: offer.offerArtPatches
    ? offer.offerArtPatches
        .map(offerArtPatchMapper)
        .sort((a, b) => a.priority - b.priority)
        .filter((patch) => patch.deleted === false)
    : [],
  offerDetails: offer.offerDetails
    ? offer.offerDetails
        .map(offerDetailMapper)
        .sort((a, b) => a.priority - b.priority)
        .filter((detail) => detail.deleted === false)
    : [],
  pictured: offer.pictured,
  startDate: offer.startDate,
  status: offer.status,
  type: offer.type,
  vehicle: offer.vehicle,
  weeklyAd: offer.weeklyAd,
})
export default offerMapper
