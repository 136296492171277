import axios from 'axios'
import { useEffect, useState } from 'react'

import appConfig from '../config/appConfig'
import itemMapper from '../mappers/itemMapper'
import paginationMapper from '../mappers/paginationMapper'

const useGetItemList = (setLoading, query, wadId, archive) => {
  const config = appConfig()
  const url =
    config.backend.root +
    config.backend.item +
    `?${query.toString()}&wadId=${wadId}&hasRun=${archive}`
  const [items, setItems] = useState({})
  const [loadItems, setLoadItems] = useState(true)

  useEffect(() => {
    let mounted = true
    const getItems = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(url)
        mounted &&
          setItems(paginationMapper(response.data, itemMapper, 'items'))
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false) //must be before setLoadItems call, which causes rerender
      mounted && setLoadItems(false)
    }

    if (loadItems) {
      getItems()
    }
    return () => {
      mounted = false
    }
  }, [url, setLoading, loadItems])

  return [items, setLoadItems]
}
export default useGetItemList
