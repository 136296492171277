const offerCommentMapper = (comment) => ({
  comment_text: comment.comment_text,
  created_by: comment.created_by,
  created_date: comment.created_date,
  deleted: comment.deleted,
  id: comment.id,
  offer_id: comment.offer_id,
  umbrella: comment.umbrella,
  updated_date: comment.updated_date,
})
export default offerCommentMapper
