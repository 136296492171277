import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import appConfig from '../config/appConfig'

const useGetDWAOffers = (setLoading, setDWA) => {
  const config = appConfig()
  const [request, setRequest] = useState(null)
  const wadId = useParams().wadId
  const url = config.backend.root + config.backend.dwa + wadId

  useEffect(() => {
    let mounted = true
    const getCategory = async () => {
      mounted && setLoading(true)

      try {
        const response = await axios.get(
          url + `/category/${request.id}/categoryId/${request.categoryId}`
        )
        setDWA((oldDWA) => [
          ...oldDWA.slice(0, request.categoryId),
          {
            ...oldDWA[request?.categoryId],
            offers: response.data,
          },
          ...oldDWA.slice(request.categoryId + 1, 19),
        ])
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
    }
    if (request !== null) {
      getCategory()
    }

    return () => {}
  }, [setLoading, url, request, setRequest, setDWA])

  return [setRequest]
}
export default useGetDWAOffers
