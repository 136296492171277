export const createPyramidRequest = (pyramidType, pyramidOrder, projectId) => {
  const pyramid = {
    captain: '',
    guest_outcomes: '',
    priority_order: pyramidOrder,
    project_id: projectId,
    pyramid_name: '',
    pyramid_type: pyramidType,
  }

  return pyramid
}
