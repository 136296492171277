import {
  Button,
  ExpandableSection,
  Grid,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import {
  faPalette,
  faPencilAlt,
  faPlus,
  faGlasses,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SortableCutContainer from './SortableCutContainer'

const ExpandableStory = (props) => {
  const {
    story,
    putStory,
    putCut,
    page,
    divisions,
    setEditStory,
    setEditCut,
    setViewCutPatches,
    setViewItemPatches,
    setEditArtPatch,
    setEditItem,
    setEditDummy,
    setEditPropItem,
    expands,
    setExpands,
    setAddArtPatch,
    hasEditPrivileges,
    lockoutDate,
    eventWeek,
  } = props
  const expanded = expands.story[story.id]

  return (
    <ExpandableSection
      padding="none"
      expanded={expanded}
      onExpand={() => {
        let exp = Object.assign({}, expands)
        exp.story[story.id] = !expanded
        setExpands(exp)
      }}
    >
      <Grid.Container align="center" spacing="dense">
        <Grid.Item xs={0.5} align="center" style={{ paddingLeft: '10px' }}>
          {' '}
          <FontAwesomeIcon
            className={`weekly-ad-expand${
              expanded ? 'rotate-clockwise-45' : 'rotate-counter-clockwise-45'
            }`}
            icon={expanded ? faChevronDown : faChevronRight}
          />
        </Grid.Item>
        <Grid.Item xs={9}>
          {`Story ${story.priority + 1}: ${story.name}`}
        </Grid.Item>
        <Grid.Item xs={1}>
          {hasEditPrivileges && (
            <Tooltip location="top" content="Link Art Patch">
              <Button
                iconOnly
                onClick={(event) => {
                  event.stopPropagation()
                  setAddArtPatch({
                    pageId: page.id,
                    show: true,
                    story: story,
                  })
                }}
                style={{
                  verticalAlign: 'top',
                }}
              >
                <FontAwesomeIcon
                  className="fa-stack-1x hc-clr-contrast"
                  style={{ marginRight: 'auto' }}
                  icon={faPalette}
                />
                <FontAwesomeIcon
                  className="fa-stack-1x hc-clr-success"
                  style={{ left: 15, marginRight: 'auto', top: 15 }}
                  size="xs"
                  icon={faPlus}
                />
              </Button>
            </Tooltip>
          )}
        </Grid.Item>
        <Grid.Item xs={1}>
          <Button
            iconOnly
            onClick={(event) => {
              event.stopPropagation()
              setEditStory({
                body: story,
                divisions: divisions,
                hasEditPrivileges,
                page: page,
                show: true,
              })
            }}
          >
            <FontAwesomeIcon
              icon={hasEditPrivileges ? faPencilAlt : faGlasses}
              color="#000000"
            />
          </Button>
        </Grid.Item>
      </Grid.Container>
      <ExpandableSection.Content className="hc-mb-dense hc-pb-dense hc-ml-dense hc-pl-dense hc-mr-dense hc-pr-dense">
        Promo Message: {story.promoMessaging}
        <SortableCutContainer
          page={page}
          story={story}
          putStory={putStory}
          putCut={putCut}
          divisions={divisions}
          setEditCut={setEditCut}
          setViewCutPatches={setViewCutPatches}
          setViewItemPatches={setViewItemPatches}
          setEditItem={setEditItem}
          setEditDummy={setEditDummy}
          setEditPropItem={setEditPropItem}
          setAddArtPatch={setAddArtPatch}
          setEditArtPatch={setEditArtPatch}
          expands={expands}
          setExpands={setExpands}
          hasEditPrivileges={hasEditPrivileges}
          lockoutDate={lockoutDate}
          eventWeek={eventWeek}
        />
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}
export default ExpandableStory
