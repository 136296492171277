const PageBackgroundColor = (page) => {
  if (page.bookOnePageNumber === '-') {
    // Insert
    return 'hc-bg-grey06'
  } else if (
    page.bookOnePageNumber > 60 ||
    page.bookOnePageNumber.match(/[A-z]/i)
  ) {
    return 'hc-bg-grey07'
  } else {
    return 'hc-bg-white'
  }
}
export default PageBackgroundColor
