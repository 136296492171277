import { StyleSheet, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  title: {
    backgroundColor: '#ededed',
    fontSize: 24,
    fontWeight: 800,
    marginTop: 15,
    paddingLeft: 10,
    textTransform: 'uppercase',
  },
})

const MediaUnitTitle = ({ name, versions }) => {
  let versionText = ''
  if (versions !== undefined && versions.length > 0) {
    versionText = ` -   ${versions.length + 1} Versions`
  }
  return (
    <Text style={styles.title}>
      {name} {versionText}
    </Text>
  )
}
export default MediaUnitTitle
