import {
  Grid,
  Card,
  Timeline,
  Button,
  ButtonGroup,
  ProgressBar,
  Form,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState, Fragment, useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import HistoryApi from '../../api/pop-weekly-ad/HistoryApi'
import CustomTimelineItem from '../../components/CustomTimelineItem'
import DivisionFilter from '../../components/DivisionFilter'
import PaginationWidget from '../../components/PaginationWidget'
import useGetWeeklyAd from '../../hooks/useGetWeeklyAd'
import { DIVISION_LIST } from '../../util/DivisionUtils'
import { setPageFilterFromQuery } from '../../util/filterOptions'
import GetPageTitle from '../../util/GetPageTitle'
import { formatToServer, formatToUi } from '../../util/MomentUtil'
import '../../scss/ProductHistory.scss'

const approvedOptions = [
  { id: 1, label: 'Reviewed', value: 'true' },
  { id: 2, label: 'Not Reviewed', value: 'false' },
]

const getDivisionsFromIds = (list) =>
  DIVISION_LIST.filter((div) => list.includes(`${div.id}`))

const WeeklyAdHistory = (props) => {
  const search = useLocation()?.search
  const query = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()
  const wadId = useParams()?.wadId

  const [isWeeklyAdLoading, setWeeklyAdLoading] = useState(false)
  const [weeklyAd] = useGetWeeklyAd(wadId, setWeeklyAdLoading)
  const [itemHistory, setItemHistory] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [transport, setTransport] = useState(0)

  const itemHistoryApi = new HistoryApi()

  // Loading product data
  useEffect(() => {
    query.set('wadId', wadId)
    itemHistoryApi
      .getItemHistory(wadId, query)
      .then((res) => setItemHistory(res))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [wadId, setLoading])

  // Utility changing pages
  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage.toString())
      setTransport(0)
      navigate(`/change-tracking/${wadId}/items?${query.toString()}`)
    }
  }, [wadId, query, navigate, transport])

  const approvedIntialVal = approvedOptions.find(
    (app) => app.value === query.get('approved')
  )
  return (
    <Fragment>
      {isLoading & isWeeklyAdLoading ? (
        <div className="hc-pa-normal">
          <ProgressBar indeterminate />
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            approved: approvedIntialVal ? approvedIntialVal : '',
            creationDate: query.get('creationDate')
              ? formatToUi(query.get('creationDate'))
              : '',
            divisionIds: query.get('divisionIds')
              ? getDivisionsFromIds(query.get('divisionIds')?.split(',') || [])
              : [],
            dpci: query.get('dpci') ? query.get('dpci') : '',
            fieldName: query.get('fieldName') ? query.get('fieldName') : '',
            itemDescription: query.get('itemDescription')
              ? query.get('itemDescription')
              : '',
            page: query.get('pageId')
              ? setPageFilterFromQuery(query, pageList(weeklyAd))
              : '',
            query: query.toString(),
            updatedBy: query.get('updatedBy') ? query.get('updatedBy') : '',
          }}
          onSubmit={(values) => {
            query.set('pageNo', 0)
            !isEmpty(values.dpci)
              ? query.set('dpci', values.dpci)
              : query.delete('dpci')

            !isEmpty(values.updatedBy)
              ? query.set('updatedBy', values.updatedBy)
              : query.delete('updatedBy')

            !isEmpty(values.page)
              ? query.set(
                  'pageId',
                  values.page.map((page) => page.id).join(',')
                )
              : query.delete('pageId')

            !isEmpty(values.itemDescription)
              ? query.set('itemDescription', values.itemDescription)
              : query.delete('itemDescription')

            !isEmpty(values.fieldName)
              ? query.set('fieldName', values.fieldName)
              : query.delete('fieldName')

            !isEmpty(values.creationDate)
              ? query.set('creationDate', formatToServer(values?.creationDate))
              : query.delete('creationDate')

            !isEmpty(values.approved)
              ? query.set('approved', values.approved.value)
              : query.delete('approved')

            !isEmpty(values.divisionIds)
              ? query.set(
                  'divisionIds',
                  values.divisionIds.map((div) => div.id).join(',')
                )
              : query.delete('divisionIds')

            navigate(`/change-tracking/${wadId}/items?${query.toString()}`)
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
            errors,
            touched,
          }) => {
            const onDivisionChange = (divs) => {
              setFieldValue('divisionIds', divs)
            }

            return (
              <>
                <div className="hc-pa-normal hc-pa-none">
                  <Grid.Container
                    padding="dense"
                    spacing="none"
                    justify="flex-end"
                    align="flex-end"
                  >
                    <Grid.Item>
                      <PaginationWidget
                        corners="top"
                        pages={itemHistory}
                        setTransport={setTransport}
                      />
                    </Grid.Item>
                    <Grid.Item xs={12}>
                      <Card>
                        <div className="hc-pa-normal">
                          <Grid.Container
                            padding="dense"
                            spacing="none"
                            justify="flex-end"
                            align="flex-end"
                          >
                            <Grid.Item xs={12} className="hc-ta-center">
                              <Heading size={2}>{weeklyAd?.eventWeek}</Heading>
                              <Heading size={4}>
                                Project Name: {weeklyAd?.projectName}
                              </Heading>
                              <Heading size={4}>
                                Start Date:{' '}
                                <DateFormatter
                                  date={weeklyAd.startDate}
                                  format="ddd MMM DD YYYY"
                                />
                              </Heading>
                            </Grid.Item>
                            <Grid.Item xs={12}>
                              <div className="hc-pa-normal">
                                <Grid.Container
                                  align="center"
                                  justify="center"
                                  padding="dense"
                                >
                                  <Grid.Item xs={3}>
                                    <DatePicker
                                      error={
                                        errors.creationDate &&
                                        touched.creationDate
                                      }
                                      id="creationDate"
                                      label=""
                                      aria-label="select creationDate"
                                      placeholder="Date"
                                      className="datepicker-icon"
                                      value={values.creationDate}
                                      onUpdate={setFieldValue}
                                      location="bottom-left-left"
                                    />
                                    <Form.Field
                                      id="itemDescription"
                                      placeholder="Product Name"
                                      value={values.itemDescription}
                                      onChange={handleChange}
                                    />
                                  </Grid.Item>
                                  <Grid.Item xs={2}>
                                    <Form.Field
                                      id="dpci"
                                      placeholder="DPCI"
                                      value={values.dpci}
                                      onChange={handleChange}
                                    />
                                    <Form.Field
                                      id="updatedBy"
                                      placeholder="Updated by"
                                      value={values.updatedBy}
                                      onChange={handleChange}
                                    />
                                  </Grid.Item>
                                  <Grid.Item
                                    xs={2}
                                    className="autocomplete-alignment"
                                  >
                                    <Autocomplete
                                      id="page"
                                      multiselect
                                      chipHeight="dense"
                                      onUpdate={setFieldValue}
                                      value={values.page}
                                      options={pageList(weeklyAd)}
                                      placeholder="Page"
                                    />
                                    <Form.Field
                                      id="fieldName"
                                      placeholder="Field Name"
                                      value={values.fieldName}
                                      onChange={handleChange}
                                      className="plv-input-alignment"
                                    />
                                  </Grid.Item>
                                  <Grid.Item xs={5}>
                                    <DivisionFilter
                                      divisions={values.divisionIds}
                                      setDivisions={onDivisionChange}
                                    />
                                  </Grid.Item>
                                  <Grid.Item xs={2}>
                                    <ButtonGroup>
                                      <Button
                                        type="primary"
                                        onClick={handleSubmit}
                                      >
                                        Apply
                                      </Button>
                                      <Button
                                        type="secondary"
                                        onClick={() => {
                                          navigate(
                                            `/change-tracking/${wadId}/items`
                                          )
                                          handleReset()
                                        }}
                                      >
                                        Clear
                                      </Button>
                                    </ButtonGroup>
                                  </Grid.Item>
                                </Grid.Container>
                              </div>
                            </Grid.Item>
                          </Grid.Container>
                        </div>
                      </Card>
                    </Grid.Item>
                    <Grid.Item xs={12}>
                      <div className="hc-pa-none">
                        <Card
                          elevation={2}
                          corners="top"
                          className="hc-pa-dense"
                        >
                          <Timeline expanded>
                            {itemHistory?.history?.map((hty) => (
                              <CustomTimelineItem
                                history={hty}
                                isAllowed={false}
                                type="item"
                              />
                            ))}
                          </Timeline>
                        </Card>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <PaginationWidget
                        corners="bottom"
                        pages={itemHistory}
                        setTransport={setTransport}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </div>
              </>
            )
          }}
        </Formik>
      )}
    </Fragment>
  )
}

function pageList(weeklyAd) {
  return !isEmpty(weeklyAd)
    ? weeklyAd?.pages
        ?.map((page) => ({
          id: page.id,
          label: GetPageTitle(page),
          value: page.id,
        }))
        .concat([{ id: -1, label: 'TBD', value: -1 }])
    : []
}
export default WeeklyAdHistory
