import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class StoryApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_story_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  createStory = async (story) =>
    await this.init_story_api()
      .post(`${this.config.markethub.url}story`, story)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return null
      })

  updateStory = async (updateRequest) => {
    await this.init_story_api()
      .put(
        `${this.config.markethub.url}story/${updateRequest.story_id}`,
        updateRequest
      )
      .then((res) => res.data)
      .catch((error) => {
        console.error(error)
        return []
      })
  }

  updateStories = async (updateStoriesRequest) => {
    await this.init_story_api()
      .put(`${this.config.markethub.url}story`, updateStoriesRequest)
      .then((res) => res.data)
      .catch((error) => {
        console.error(error)
        return []
      })
  }

  deleteStory = async (deleteStoryRequest) => {
    await this.init_story_api()
      .delete(
        `${this.config.markethub.url}story/${deleteStoryRequest.story_id}`
      )
      .then((res) => res)
      .catch((error) => {
        console.error(error)
        return []
      })
  }
}
