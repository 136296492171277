import { Pagination, Grid, Card } from '@enterprise-ui/canvas-ui-react'

const PaginationWidget = (props) => {
  const { pages, corners, setTransport, archive } = props

  return (
    <Grid.Item>
      <Card elevation={2} corners={corners}>
        {pages && (
          <Pagination
            currentPage={pages.pageNumber + 1}
            totalPages={pages.totalPages}
            onRequestNext={pages.isLastPage ? null : () => setTransport(1)}
            onRequestPrev={pages.isFirstPage ? null : () => setTransport(-1)}
            archive={archive}
          />
        )}
      </Card>
    </Grid.Item>
  )
}
export default PaginationWidget
