import { Chip } from '@enterprise-ui/canvas-ui-react'

function defaultChipRenderer(value, onRequestDelete, disabled, chipHeight) {
  return (
    <Chip
      dense={chipHeight === 'dense'}
      expanded={chipHeight === 'expanded'}
      disabled={disabled}
      onRequestDelete={onRequestDelete}
    >
      {value.label}
    </Chip>
  )
}

export default defaultChipRenderer
