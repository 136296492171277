import { Button, Form, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import AddStory from '../forms/AddStory'
import getPageTitle from '../util/GetPageTitle'

let pageMerchSchema = yup.object().shape({
  edit_page_businessObjectives: yup.string(),
})

const EditPageMerch = (props) => {
  const { editPage, setEditPage, putPage, postStory } = props

  const [showAddStory, setShowAddStory] = useState(false)

  const wipeState = () => {
    setEditPage({ body: {}, show: false })
    setShowAddStory(false)
  }

  return editPage ? (
    <Formik
      enableReinitialize
      validationSchema={pageMerchSchema}
      initialValues={{
        edit_page_businessObjectives: editPage.body.businessObjectives || '',
      }}
      onSubmit={(values, funcs) => {
        putPage({
          businessObjectives: values.edit_page_businessObjectives,
          id: editPage.body.id,
          lastUpdatedTimestamp: editPage.body.lastUpdatedTimestamp,
        })
        wipeState()
        funcs.resetForm()
      }}
    >
      {({ values, handleBlur, handleChange, handleSubmit, handleReset }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={editPage.show}
            headingText={`Edit ${getPageTitle(editPage.body)}`}
            onRefuse={handleDialogClose}
          >
            <fieldset disabled={!editPage.hasEditPrivileges}>
              <div className="hc-pa-normal">
                <Form.Field
                  id="edit_page_businessObjectives"
                  label="Objectives"
                  value={values.edit_page_businessObjectives}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Button
                  type="primary"
                  disabled={!editPage.hasEditPrivileges}
                  onClick={() => setShowAddStory(true)}
                >
                  Add Story
                </Button>
                <CancelSubmitButtons
                  canDelete={false}
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                  submitDisabled={!editPage.hasEditPrivileges}
                />
              </div>
            </fieldset>
            <AddStory
              show={showAddStory}
              setShow={setShowAddStory}
              page={editPage.body}
              postStory={postStory}
              closeModal={handleDialogClose}
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditPageMerch
