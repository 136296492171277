import axios from 'axios'
import { useEffect, useState, useCallback } from 'react'

import appConfig from '../config/appConfig'
import paginationMapper from '../mappers/paginationMapper'
import weeklyAdMapper from '../mappers/weeklyAdMapper'

const useGetWeeklyAdList = (setLoading, archive, location) => {
  const config = appConfig()
  const query = new URLSearchParams(location.search)
  const filter = query.get('eventWeek') || null
  const queryString = archive
    ? `?hasRun=true&${query.toString()}`
    : `?hasRun=false&${query.toString()}`
  const url = config.backend.root + 'wads' + queryString
  const [paginations, setPaginations] = useState({})
  const addWeeklyAd = useCallback(
    () => (newWeeklyAd) => {
      setPaginations((pns) => ({ ...pns, wads: [...pns.wads, newWeeklyAd] }))
    },
    []
  )

  useEffect(() => {
    let mounted = true
    const getPaginations = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(url)
        mounted &&
          setPaginations(
            paginationMapper(response.data, weeklyAdMapper, 'wads')
          )
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
    }

    getPaginations()
    return () => {
      mounted = false
    }
  }, [url, setLoading, filter])

  return [paginations, addWeeklyAd]
}
export default useGetWeeklyAdList
