import { Chip } from '@enterprise-ui/canvas-ui-react'
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  getChipValues,
  getPyramidAbbrByGroupName,
  getPyramidColor,
} from '../util/ProjectDetailsUtil'

import '../scss/Umbrella.scss'

const PyramidChip = ({ groupName = '', isKey }) => {
  const pyramidName = getPyramidAbbrByGroupName(groupName) || ''
  const pyramidColor = getPyramidColor(pyramidName).colorCode
  const pyramidDisplayName = getChipValues(pyramidName)
  return pyramidDisplayName ? (
    <span className="umrella-name-chip" data-testid="pyramid-chip">
      <Chip
        style={{ backgroundColor: pyramidColor, border: '0px', color: 'white' }}
        size="dense"
      >
        {pyramidDisplayName}
      </Chip>
      {isKey ? (
        <FontAwesomeIcon icon={faKey} className="fa-duotone key" size="lg" />
      ) : null}
    </span>
  ) : null
}
export default PyramidChip
