import ProductApi from '../../api/pop-catalog/ProductApi'
import { CategoryColorMap } from '../../util/CategoryColorMap'

const formCategory = (category = '') =>
  category
    ? {
        color: CategoryColorMap[category],
        id: category,
        label: category,
        value: category,
      }
    : null

const formatDPCI = (dpci = '') =>
  dpci.replace(/(\d{3})-?(\d{2})-?(\d{4})/, '$1-$2-$3')

const productApi = new ProductApi()
const parseSetplaceholdDpci = (catalogId, setPlaceholdDpci) => {
  productApi
    .getLastPlaceholdDpci(catalogId)
    .then((res) => {
      if (res !== '') {
        var endDpci =
          '000-00-' +
          String((parseInt(res.split('-')[2]) + 1) % 9999).padStart(4, '0')
        setPlaceholdDpci(endDpci)
      } else {
        setPlaceholdDpci()
      }
    })
    .catch((e) => console.log(e))
}

const duplicateDpciResponse =
  'This DPCI has already been submitted and cannot be re-submitted.  Please submit a different DPCI'

const handlePostProductError = (e, setDialogContent, setDialogVisible) => {
  if (e.response.status === 409) {
    setDialogContent(duplicateDpciResponse)
  } else {
    setDialogContent('Error Submitting')
  }
  setDialogVisible(true)
}
export {
  formCategory,
  formatDPCI,
  parseSetplaceholdDpci,
  duplicateDpciResponse,
  handlePostProductError,
}
