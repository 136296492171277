import merge from 'lodash/merge'
let appEnv
const hostname = window && window.location && window.location.hostname

const commonConfig = {
  auth: {
    loginRedirect: `${window.location.origin}`,
    responseType: 'token id_token',
  },
}

const envConfigs = {
  ephemeral: {
    assethub: {
      key: '2934bb32935bafe1f7215c504fb799dab329ae35',
      uiUrl: 'https://assethub.dev.target.com',
      url: 'https://stage-api.target.com/marketing_assets/v1/asset_search',
    },
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      clientId: 'pop_npe_im',
      logoutUrl: `https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=${window.location.origin}/`,
    },
    backend: {
      catalog: 'https://stage-api.target.com/promo_catalogs/v1/',
      root: 'https://pop-backend.dev.target.com/',
    },
    catapi: {
      apikey: '2139871233b4f6cfdff29031f890df8ee72c32bd',
    },
    firefly: {
      apiKey: '2934bb32935bafe1f7215c504fb799dab329ae35',
      eventManagerKey: 'internalapp',
      retries: 1,
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      key: '360729a3d898ec130e09f7caadd8c165',
      url: 'https://api-internal.target.com/items/v4/graphql',
    },
    ivy: {
      uiUrl: 'https://ivy-test.dev.target.com/',
    },
    markethub: {
      url: 'https://creativehubapi.dev.target.com/v1/',
      v2Url: 'https://creativehubapi.dev.target.com/v2/',
    },
    platform: {
      key: 'f1d1d52e0da1690ef1f4e7aeb2deda35fbddfb57',
      ldap: 'https://api-internal.target.com/ldap_directory_items/v2/group_members',
      url: 'https://api-internal.target.com/platform_notifications/v1/ldap_members',
    },
    price: {
      key: '?key=e164a01f130044821597116a52242e7459ee9043',
      url: 'https://price.target.com/location_prices/v2/tcin/',
    },
    ui: {
      url: 'https://shortstacksproxy.prod.target.com',
    },
  },
  local: {
    assethub: {
      key: '2934bb32935bafe1f7215c504fb799dab329ae35',
      uiUrl: 'https://assethub.dev.target.com',
      url: 'https://stage-api.target.com/marketing_assets/v1/asset_search',
    },
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      clientId: 'pop_npe_im',
      logoutUrl: `https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=${window.location.origin}/`,
    },
    backend: {
      catalog: 'https://stage-api.target.com/promo_catalogs/v1/',
      root: 'https://pop-backend.dev.target.com/',
    },
    catapi: {
      apikey: '2139871233b4f6cfdff29031f890df8ee72c32bd',
    },
    firefly: {
      apiKey: '2934bb32935bafe1f7215c504fb799dab329ae35',
      eventManagerKey: 'internalapp',
      retries: 1,
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      key: '360729a3d898ec130e09f7caadd8c165',
      url: 'https://api-internal.target.com/items/v4/graphql',
    },
    ivy: {
      uiUrl: 'https://ivy-test.dev.target.com/',
    },
    markethub: {
      url: 'https://creativehubapi.dev.target.com/v1/',
      v2Url: 'https://creativehubapi.dev.target.com/v2/',
    },
    platform: {
      key: 'f1d1d52e0da1690ef1f4e7aeb2deda35fbddfb57',
      ldap: 'https://api-internal.target.com/ldap_directory_items/v2/group_members',
      url: 'https://api-internal.target.com/platform_notifications/v1/ldap_members',
    },
    price: {
      key: '?key=e164a01f130044821597116a52242e7459ee9043',
      url: 'https://price.target.com/location_prices/v2/tcin/',
    },
    ui: {
      url: 'http://localhost:3000',
    },
  },
  partnersonline_prod: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/we2w/1',
      clientId: 'pop_prod_im',
      logoutUrl: `https://oauth.iam.partnersonline.com/login/responses/logoff.html?target=${window.location.origin}/`,
    },
    backend: {
      catalog: 'https://api.target.com/promo_catalogs/v1/',
    },
    catapi: {
      apikey: '398286abeb37ab5542e93048228bebca941f2106',
    },
    firefly: {
      apiKey: '4f8009196ee07d03d14dc77fa081703458541f4e',
      eventManagerKey: 'internalapp',
      retries: 1,
      url: 'https://api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      key: 'f1d1d52e0da1690ef1f4e7aeb2deda35fbddfb57',
      url: 'https://api.target.com/items/v4/graphql',
    },
    platform: {
      key: 'f1d1d52e0da1690ef1f4e7aeb2deda35fbddfb57',
      ldap: 'https://api-internal.target.com/ldap_directory_items/v2/group_members',
      url: 'https://api-internal.target.com/platform_notifications/v1/ldap_members',
    },
    price: {
      key: '?key=e164a01f130044821597116a52242e7459ee9043',
      url: 'https://price.target.com/location_prices/v2/tcin/',
    },
    ui: {
      url: 'https://pop.partnersonline.com',
    },
  },
  partnersonline_stg: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/we2w/1',
      clientId: 'pop_npe_im',
      logoutUrl: `https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html?target=${window.location.origin}/`,
    },
    backend: {
      catalog: 'https://stage-api.target.com/promo_catalogs/v1/',
    },
    catapi: {
      apikey: '2139871233b4f6cfdff29031f890df8ee72c32bd',
    },
    firefly: {
      apiKey: '2934bb32935bafe1f7215c504fb799dab329ae35',
      eventManagerKey: 'internalapp',
      retries: 1,
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      key: '360729a3d898ec130e09f7caadd8c165',
      url: 'https://stage-api.target.com/items/v4/graphql',
    },
    markethub: {
      url: 'https://creativehubapi.dev.target.com/v1/',
      v2Url: 'https://creativehubapi.dev.target.com/v2/',
    },
    platform: {
      key: 'f1d1d52e0da1690ef1f4e7aeb2deda35fbddfb57',
      ldap: 'https://api-internal.target.com/ldap_directory_items/v2/group_members',
      url: 'https://api-internal.target.com/platform_notifications/v1',
    },
    price: {
      key: '?key=e164a01f130044821597116a52242e7459ee9043',
      url: 'https://price.target.com/location_prices/v2/tcin/',
    },
    ui: {
      url: 'https://pop.perf.partnersonline.com',
    },
  },
  prod: {
    assethub: {
      key: '4f8009196ee07d03d14dc77fa081703458541f4e',
      uiUrl: 'https://assethub.target.com',
      url: 'https://api.target.com/marketing_assets/v1/asset_search',
    },
    auth: {
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      clientId: 'pop_prod_im',
      logoutUrl: `https://logonservices.iam.target.com/login/responses/logoff.html?target=${window.location.origin}/`,
    },
    backend: {
      catalog: 'https://api.target.com/promo_catalogs/v1/',
      root: 'https://pop-backend.prod.target.com/',
    },
    catapi: {
      apikey: '398286abeb37ab5542e93048228bebca941f2106',
    },
    firefly: {
      apiKey: '4f8009196ee07d03d14dc77fa081703458541f4e',
      eventManagerKey: 'internalapp',
      retries: 1,
      url: 'https://api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      key: 'f1d1d52e0da1690ef1f4e7aeb2deda35fbddfb57',
      url: 'https://api-internal.target.com/items/v4/graphql',
    },
    ivy: {
      uiUrl: 'https://ivy.prod.target.com/',
    },
    markethub: {
      url: 'https://creativehubapi.prod.target.com/v1/',
      v2Url: 'https://creativehubapi.prod.target.com/v2/',
    },
    platform: {
      key: 'f1d1d52e0da1690ef1f4e7aeb2deda35fbddfb57',
      ldap: 'https://api-internal.target.com/ldap_directory_items/v2/group_members',
      url: 'https://api-internal.target.com/platform_notifications/v1/ldap_members',
    },
    price: {
      key: '?key=e164a01f130044821597116a52242e7459ee9043',
      url: 'https://price.target.com/location_prices/v2/tcin/',
    },
    ui: {
      url: 'https://creativehub.prod.target.com',
    },
  },
  stg: {
    assethub: {
      key: '2934bb32935bafe1f7215c504fb799dab329ae35',
      uiUrl: 'https://assethub.dev.target.com',
      url: 'https://stage-api.target.com/marketing_assets/v1/asset_search',
    },
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
      clientId: 'pop_npe_im',
      logoutUrl: `https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=${window.location.origin}/`,
    },
    backend: {
      catalog: 'https://stgapi-internal.target.com/promo_catalogs/v1/',
      root: 'https://pop-backend.dev.target.com/',
    },
    catapi: {
      apikey: '2139871233b4f6cfdff29031f890df8ee72c32bd',
    },
    firefly: {
      apiKey: '2934bb32935bafe1f7215c504fb799dab329ae35',
      eventManagerKey: 'internalapp',
      retries: 1,
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      key: '360729a3d898ec130e09f7caadd8c165',
      url: 'https://api-internal.target.com/items/v4/graphql',
    },
    ivy: {
      uiUrl: 'https://ivy-test.dev.target.com/',
    },
    markethub: {
      url: 'https://creativehubapi.dev.target.com/v1/',
      v2Url: 'https://creativehubapi.dev.target.com/v2/',
    },
    platform: {
      key: 'f1d1d52e0da1690ef1f4e7aeb2deda35fbddfb57',
      ldap: 'https://api-internal.target.com/ldap_directory_items/v2/group_members',
      url: 'https://api-internal.target.com/platform_notifications/v1/ldap_members',
    },
    price: {
      key: '?key=e164a01f130044821597116a52242e7459ee9043',
      url: 'https://price.target.com/location_prices/v2/tcin/',
    },
    ui: {
      url: 'https://creativehub.dev.target.com',
    },
  },
}

if (hostname === 'creativehub.prod.target.com') {
  appEnv = 'prod'
} else if (hostname === 'creativehub.partnersonline.com') {
  appEnv = 'partnersonline_prod'
} else if (hostname === 'creativehub.perf.partnersonline.com') {
  appEnv = 'partnersonline_stg'
} else if (hostname === 'shortstacksproxy.prod.target.com') {
  appEnv = 'ephemeral'
} else if (hostname === 'localhost' || hostname === 'localhost.target.com') {
  appEnv = 'local'
} else {
  appEnv = 'stg'
}

const config = envConfigs[appEnv]
const apiConfig = merge(commonConfig, config)

export default apiConfig
