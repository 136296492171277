import { Dialog, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import { useState } from 'react'

import PlatformApi from '../api/platform-notifications/EmailVerification'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import DivisionFilter from '../components/DivisionFilter'
import { divisionsToDivisionIds } from '../util/DivisionUtils'
import getPageTitle from '../util/GetPageTitle'
import { formatToServer, formatToUi } from '../util/MomentUtil'

import '../scss/EditWeeklyAdDetails.scss'
import {
  editWeeklyAdPageSchema,
  validateWeeklyAdPageEmails,
} from './EmailVerificationHelper'

const EditPageDetails = (props) => {
  const { weeklyAd, page, setPage, putPage } = props
  const api = new PlatformApi()
  const [isDeleteDialog, setDeleteDialog] = useState(false)

  const wipeState = () => {
    setDeleteDialog(false)
    setPage({ body: {}, show: false })
  }

  const mapAutocomplete = (options = []) =>
    options.map((option, index) => ({
      id: index,
      label: option,
      value: option,
    }))

  const mapAutocompleteStr = (options = []) =>
    options
      .filter((option) => {
        if (option === '') {
          return false
        }
        return true
      })
      .map((ele, indx) => ({
        id: indx,
        label: ele,
        value: ele,
      }))
  return page ? (
    <Formik
      enableReinitialize
      initialValues={{
        artDirector: mapAutocompleteStr(
          page.body?.artDirector?.split(/[ ,/]+/)
        ),
        bookOneOldPageNumber: page.body?.bookOneOldPageNumber || '',
        bookTwoOldPageNumber: page.body?.bookTwoOldPageNumber || '',
        copyWriter: mapAutocompleteStr(page.body?.copyWriter?.split(/[ ,/]+/)),
        divisions: page.body?.divisions || [],
        invalidEmails: [],
        lockoutDate: formatToUi(page.body?.lockoutDate) || '',
        messaging: page.body?.messaging || '',
        pageFlip: false,
        pageType: page.body?.bookOnePageType === 'DIGITAL' || false,
        postMprCopyWriter: mapAutocomplete(page.body?.postMprCopyWriter),
      }}
      validationSchema={editWeeklyAdPageSchema}
      onSubmit={(values = {}, funcs) => {
        const {
          artDirector,
          bookOneOldPageNumber,
          bookTwoOldPageNumber,
          copyWriter,
          divisions,
          messaging,
          pageFlip,
          postMprCopyWriter,
          lockoutDate,
        } = values
        putPage({
          artDirector: artDirector.map((opt) => opt.value).toString(),
          bookOneOldPageNumber: bookOneOldPageNumber,
          bookTwoOldPageNumber: bookTwoOldPageNumber,
          copyWriter: copyWriter.map((opt) => opt.value).toString(),
          divisionIds: divisionsToDivisionIds(divisions),
          id: page.body.id,
          lastUpdatedTimestamp: page.body.lastUpdatedTimestamp,
          lockoutDate: formatToServer(lockoutDate),
          messaging: messaging,
          pageFlip: pageFlip,
          postMprCopyWriter: postMprCopyWriter.map((opt) => opt.value),
        })
        wipeState()
        funcs.resetForm()
      }}
    >
      {({
        errors,
        values = {},
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        const onDelete = () => {
          props.putWad({
            contactId: weeklyAd.contact.id,
            id: weeklyAd.id,
            lastUpdatedTimestamp: weeklyAd.lastUpdatedTimestamp,
            pages: [
              {
                deleted: true,
                id: page.body.id,
                lastUpdatedTimestamp: page.body.lastUpdatedTimestamp,
                lockoutDate: page.body.lockoutDate,
              },
            ],
          })
          handleDialogClose()
        }

        const {
          artDirector,
          bookOneOldPageNumber,
          bookTwoOldPageNumber,
          copyWriter,
          postMprCopyWriter,
          divisions,
          messaging,
          lockoutDate,
        } = values

        const verifyEmails = async function (values, api) {
          const response = await validateWeeklyAdPageEmails(values, api)
          setFieldValue('invalidEmails', response)
        }

        const handleEmailFieldUpdate = (id, value) => {
          setFieldValue(id, value)
          let valuesCopy = { ...values }
          valuesCopy[id] = value
          verifyEmails(valuesCopy, api)
        }

        const headingTextValue = 'Edit ' + getPageTitle(page.body) + ' Details'
        return (
          <Modal
            isVisible={page.show}
            headingText={headingTextValue}
            onRefuse={handleDialogClose}
            className="datepicker_modal"
          >
            <div className="hc-pa-normal">
              <Grid.Container spacing="dense" justify="space-between">
                <Grid.Item xs={12}>
                  <DivisionFilter
                    label="Divisions"
                    divisions={divisions}
                    setDivisions={(value) => {
                      setFieldValue('divisions', value)
                    }}
                    placeholder="Choose Divisions"
                  />
                </Grid.Item>
                <Grid.Item md={6} xs={12}>
                  <Autocomplete
                    id="artDirector"
                    label="Art Director"
                    multiselect
                    allowCustomInputValue={true}
                    chipHeight="dense"
                    className="hc-mb-dense hc-mt-dense"
                    onUpdate={handleEmailFieldUpdate}
                    value={artDirector}
                    options={[]}
                    noResultsMessage="Use Outlook Name"
                  />
                </Grid.Item>
                <Grid.Item md={6} xs={12}>
                  <Autocomplete
                    id="copyWriter"
                    label="Kickoff Copywriter"
                    multiselect
                    allowCustomInputValue={true}
                    chipHeight="dense"
                    className="hc-mb-dense hc-mt-dense"
                    onUpdate={handleEmailFieldUpdate}
                    value={copyWriter}
                    options={[]}
                    noResultsMessage="Use Outlook Name"
                  />
                </Grid.Item>
                {!weeklyAd.midweek && (
                  <>
                    <Grid.Item xs={6}>
                      <Autocomplete
                        id="postMprCopyWriter"
                        multiselect
                        allowCustomInputValue={true}
                        chipHeight="dense"
                        className="hc-mb-dense hc-mt-dense"
                        onUpdate={handleEmailFieldUpdate}
                        value={postMprCopyWriter}
                        options={[]}
                        label="Post-MPR Copywriter"
                        noResultsMessage="Use Outlook Name"
                      />
                    </Grid.Item>
                    <Grid.Item xs={6}>
                      <DatePicker
                        required
                        id="lockoutDate"
                        label="Lockout Date"
                        value={lockoutDate}
                        onUpdate={setFieldValue}
                        location="bottom-left-left"
                      />
                    </Grid.Item>
                  </>
                )}
                <Grid.Item xs={12}>
                  <Form.Field
                    id="messaging"
                    label="Messaging"
                    onChange={handleChange}
                    value={messaging}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="bookOneOldPageNumber"
                    label="Book One Old Page Number"
                    onChange={handleChange}
                    value={bookOneOldPageNumber}
                  />
                </Grid.Item>
                {!weeklyAd.midweek && (
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="bookTwoOldPageNumber"
                      label="Book Two Old Page Number"
                      onChange={handleChange}
                      value={bookTwoOldPageNumber}
                    />
                  </Grid.Item>
                )}
              </Grid.Container>
              <Grid.Container>
                <Grid.Item>
                  <p className="errorEmailMessage">{errors.invalidEmails}</p>
                  <ul className="errorEmailList">
                    {values.invalidEmails.map((email) => (
                      <li>{email}</li>
                    ))}
                  </ul>
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDelete={setDeleteDialog}
                deleteDisabled={!page.body.deletable}
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
              />
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText="Are you sure want to delete this page?"
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText="Delete"
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditPageDetails
