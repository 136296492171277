import contactMapper from './contactMapper'
import pageMapper from './pageMapper'

const weeklyAdMapper = (weeklyAd) => ({
  audioAcd: weeklyAd.audioAcd,
  bookOneMarkets: weeklyAd.bookOneMarkets,
  bookTwoMarkets: weeklyAd.bookTwoMarkets,
  colorApprovalDate: weeklyAd.colorApprovalDate,
  contact: weeklyAd.contact ? contactMapper(weeklyAd.contact) : {},
  creativeKickoffDate: weeklyAd.creativeKickoffDate,
  creativeLockedOut: weeklyAd.creativeLockedOut,
  creativeLockoutDate: weeklyAd.creativeLockoutDate,
  deleted: weeklyAd.deleted,
  digitalPageCount: weeklyAd.digitalPageCount,
  epCaptain: weeklyAd.epCaptain,
  eventWeek: weeklyAd.eventWeek,
  headlineWriter: weeklyAd.headlineWriter,
  id: weeklyAd.id,
  insertPageCount: weeklyAd.insertPageCount,
  lastUpdatedTimestamp: weeklyAd.lastUpdatedTimestamp,
  lockedOut: weeklyAd.lockedOut,
  lockoutDate: weeklyAd.lockoutDate,
  merchantPartnerDate: weeklyAd.merchantPartnerDate,
  midweek: weeklyAd.midweek,
  midweekPageCount: weeklyAd.midweekPageCount,
  pages: weeklyAd.pages
    ? weeklyAd.pages
        .map(pageMapper)
        .sort((a, b) => a.pageOrdering - b.pageOrdering)
        .filter((page) => page.deleted === false)
    : [],
  pmmApparel: weeklyAd.pmmApparel,
  pmmBeauty: weeklyAd.pmmBeauty,
  pmmEssentials: weeklyAd.pmmEssentials,
  pmmFoodBev: weeklyAd.pmmFoodBev,
  pmmHardlines: weeklyAd.pmmHardlines,
  pmmHome: weeklyAd.pmmHome,
  postPhotoLayoutDate: weeklyAd.postPhotoLayoutDate,
  prePhotoLayoutDate: weeklyAd.prePhotoLayoutDate,
  printedPageCount: weeklyAd.printedPageCount,
  projectName: weeklyAd.projectName,
  published: weeklyAd.published,
  revision: weeklyAd.revision,
  sampleMerchantPartners: weeklyAd.sampleMerchantPartners,
  socialDigitalDwaAcd: weeklyAd.socialDigitalDwaAcd,
  startDate: weeklyAd.startDate,
  videoAcd: weeklyAd.videoAcd,
  wrapPageCount: weeklyAd.wrapPageCount,
})
export default weeklyAdMapper
