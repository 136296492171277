export default class OptionsManager {
  static hasOption(value, option) {
    return value.some((valueOption) => valueOption.id === option.id)
  }

  static findIndex(value, option) {
    return value.findIndex((valueOption) => valueOption.id === option.id)
  }

  static add(value, option) {
    value = Array.isArray(value) ? value : []
    if (OptionsManager.hasOption(value, option)) {
      return value
    }

    return [...value, option]
  }

  static remove(value, option) {
    if (!OptionsManager.hasOption(value, option)) {
      return value
    }

    const optionIndex = OptionsManager.findIndex(value, option)

    return [...value.slice(0, optionIndex), ...value.slice(optionIndex + 1)]
  }
}
