import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import cutMapper from '../mappers/cutMapper'

const usePostCut = (
  setLoading,
  setWeeklyAd,
  setToast,
  setEditCut,
  divisions
) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.cut)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const postCut = async () => {
      mounted && setLoading(true)
      try {
        let response = await axios.post(url.current, request.body)
        let thisCut = cutMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            w.pages
              .find((page) => page.id === request.page.id)
              .stories.find((story) => story.id === request.storyId)
              .cuts.push(thisCut)
            return w
          })
        if (setToast && mounted) {
          setToast({
            heading: 'Cut Added',
            isVisible: true,
            message: 'Nice.',
            type: 'success',
          })
        }
        if (thisCut.offers.length) {
          handleCutDialog(thisCut, request, divisions, setEditCut, mounted)
        }
      } catch (e) {
        if (setToast && mounted) {
          setToast({
            heading: 'Cut not added',
            isVisible: true,
            message: 'Something went wrong, try again...',
            type: 'error',
          })
          console.log(e)
        }
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      postCut()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd, setToast, divisions, setEditCut])

  return setRequest
}

function handleCutDialog(cut, request, divisions, setEditCut, mounted) {
  const hasPrimary = cut.cutPatches.length > 0
  const primary = hasPrimary ? cut.cutPatches[0] : {}
  mounted &&
    setEditCut({
      body: {
        copy: primary.copy,
        coreDataLastUpdatedField: cut.coreDataLastUpdatedField,
        coreDataLastUpdatedTimestamp: cut.coreDataLastUpdatedTimestamp,
        divisionId: cut.divisionId,
        id: cut.id,
        lastUpdatedTimestamp: cut.lastUpdatedTimestamp,
        linerOffers: cut.linerOffers,
        name: cut.name,
        offerLastLinkedTimestamp: cut.offerLastLinkedTimestamp,
        offers: cut.offers,
        primaryPatchIndex: hasPrimary ? primary.marketIndex : null,
        priority: cut.priority,
        promoUnitRetail: primary.promoUnitRetail,
        promoUnitRetailCallout: primary.promoUnitRetailCallout,
        promoUnitRetailLastUpdatedTimestamp:
          cut.promoUnitRetailLastUpdatedTimestamp,
        saleCallout: primary.saleCallout,
      },
      cutValid: cut.valid,
      divisions: divisions,
      followers: [],
      //directly after creating a cut there won't be followers
      hasEditPrivileges: request.hasEditPrivileges,
      hasItems: Boolean(cut.items.length),
      isArtPatch: Boolean(cut.artPatchLeaderId),
      page: request.page,
      show: true,
      storyId: request.storyId,
      storyLastUpdatedTimestamp: request.storyLastUpdatedTimestamp,
      valid: cut.valid,
      weeklyAdStartDate: request.weeklyAdStartDate,
    })
}
export default usePostCut
