import { Modal, Form } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'

import CancelSubmitButtons from '../CancelSubmitButtons'
import { extractEmailAddresses } from '../util/emailUtil'

import { CommentField } from './CommentItem'

export default function AddComment({ handleDialogClose, onAddComment }) {
  const [comment, setComment] = useState('')

  const onSubmit = () => {
    var sendEmailList = extractEmailAddresses(comment)
    onAddComment(comment, sendEmailList)
    handleDialogClose()
  }

  const handleSetComment = (e) => {
    setComment(e.target.value)
  }

  return (
    <Modal
      isVisible={true}
      headingText="Add Comment"
      onRefuse={handleDialogClose}
    >
      <div className="hc-pa-normal hc-pl-lg">
        <CommentField
          value={comment}
          handleSetComment={handleSetComment}
          setValue={setComment}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleCommentChange,
            handleBlur,
            values,
            errors,
          }) => (
            <>
              <Form.Field
                id="comment"
                type="textarea"
                handleBlur={handleBlur}
                onChange={handleCommentChange}
                value={values.comment}
                error={errors.comment}
                errorText={errors.comment}
                autoFocus
              />
              <div style={{ color: 'red' }}>{errors.comment}</div>
              <CancelSubmitButtons
                canDelete={false}
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={errors?.comment}
              />
            </>
          )}
        </CommentField>
      </div>
    </Modal>
  )
}
