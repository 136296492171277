import { Chip } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import { DIVISION_LIST } from '../util/DivisionUtils'

const DivisionFilter = ({
  divisions,
  setDivisions,
  placeholder,
  label,
  maxSelectedOptions,
  disabled,
}) => (
  <div>
    <Autocomplete
      id="division-filter"
      onUpdate={(id, value) => setDivisions(value)}
      disabled={disabled}
      filter={(searchText, division) => {
        const compareString = (
          division.label +
          ' ' +
          division.shortCode
        ).toLowerCase()
        searchText = searchText.toString().toLowerCase()
        return compareString.indexOf(searchText) !== -1
      }}
      multiselect
      maxSelectedOptions={maxSelectedOptions}
      options={DIVISION_LIST}
      renderChip={(value, onRequestDelete) => (
        <Chip
          size="dense"
          onRequestDelete={onRequestDelete}
          style={{
            backgroundColor: value.color,
            border: '0px',
            color: 'white',
          }}
        >
          {value?.shortCode?.toUpperCase()}
        </Chip>
      )}
      placeholder={placeholder ? placeholder : 'Filter Divisions'}
      value={divisions}
      label={label ? label : null}
    />
  </div>
)
export default DivisionFilter
