import { Input } from '@enterprise-ui/canvas-ui-react'

import { priorityOptions } from '../util/CreativeHubGlobalUtil'

const MarketingPriorityFilter = ({
  selectedMarketingPriorityFilter,
  setMarketingPriorityFilter,
  setMarketingPyramidFilter,
  disabled,
  handleFilterUpdate,
}) => (
  <>
    <Input.Label data-cy="mktgPriorityFilter">Priority Filter</Input.Label>
    <Input.Select
      id="marketingPrioritySelect"
      options={priorityOptions}
      onUpdate={(id, value) => {
        setMarketingPriorityFilter(value)
        if (value === 'ent') {
          setMarketingPyramidFilter('')
        }
        handleFilterUpdate()
      }}
      value={selectedMarketingPriorityFilter}
      defaultValue=""
      data-testid="marketingPriority"
      disabled={disabled}
    />
  </>
)

export default MarketingPriorityFilter
