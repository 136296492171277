import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect } from 'react'

import { TARGET_WEB_DOMAIN } from '../../util/Constants'
import '../../planning/scss/InlineEditInput.scss'

const ContentDetailsRow = ({
  destinationUrlRow,
  title,
  value,
  updateValue,
  disabled,
  textarea = false,
  id,
  setChangeDetected,
}) => {
  const [editingValue, setEditingValue] = useState('')

  useEffect(() => {
    setEditingValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Grid.Container className="category-container-row">
      <Grid.Item className="category-label" xs={2}>
        {title}
      </Grid.Item>
      <Grid.Item className="category-input" xs={10}>
        <Form.Field
          value={editingValue}
          onBlur={(event) => {
            if (event.target.value !== value) {
              updateValue(editingValue)
              setChangeDetected(true)
            }
          }}
          onChange={(event) => setEditingValue(event.target.value)}
          data-testid={`${title}-input-${id}`}
          placeholder=""
          disabled={disabled}
          error={destinationUrlRow === false}
          type={textarea ? '' : 'text'}
          size={textarea ? 'dense' : null}
          id={`${title}-input-${id}`}
        />
        {destinationUrlRow === false ? (
          <p className="error-text">
            Destination URL must start with {TARGET_WEB_DOMAIN}
          </p>
        ) : (
          ''
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default ContentDetailsRow
