import {
  Avatar,
  Button,
  Dropdown,
  Grid,
  Layout,
  Caption,
  Divider,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '@praxis/component-auth'

import HeaderBreadCrumbs from '../globalComponents/HeaderBreadCrumbs'
import { isVendor } from '../util/CheckRole'

const getInitials = (userInfo) =>
  userInfo
    ? `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`
    : ''

const getCompany = (identity) => `${identity.company_name}`
const getUserName = (userInfo) => `${userInfo.fullName}`

const Header = (props) => {
  const { onSideNavToggle, effectiveRole, isSideNavOpen } = props
  const auth = useAuth()
  const { logout, session } = auth
  const gridColumn = isSideNavOpen ? '2/span 2' : '1/span 3'

  return (
    <>
      <Layout.Header style={{ gridColumn }} fixed={true}>
        <Grid.Container spacing="dense">
          <Grid.Item>
            {!isVendor(effectiveRole) && (
              <Button
                className="C-MenuButton"
                onClick={onSideNavToggle}
                iconOnly
                data-testid="headerMenuButton"
                aria-label="toggle side nav menu"
                style={{
                  backgroundColor: `#333333`,
                  color: 'white',
                  zIndex: 1000,
                }}
              >
                <FontAwesomeIcon icon={faBars} />
              </Button>
            )}
          </Grid.Item>
          <Grid.Item className="hc-pt-dense">
            <HeaderBreadCrumbs />
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
      <Layout.GlobalActions>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Dropdown size="dense" location="bottom-right">
              <Avatar
                aria-label="profile picture"
                style={{ cursor: 'pointer' }}
              >
                {getInitials(session.userInfo)}
              </Avatar>
              <Dropdown.Menu>
                <Caption
                  style={{
                    backgroundColor: `#dae4f2`,
                    padding: `12px`,
                    width: '100%',
                  }}
                  below={getCompany(session.identity)}
                >
                  <Heading size={5}>{getUserName(session.userInfo)}</Heading>
                </Caption>
                <Divider width={2} />
                <Dropdown.MenuItem
                  data-testid="headerLogoutButton"
                  onClick={logout}
                >
                  Log Out
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
    </>
  )
}
export default Header
