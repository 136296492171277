import { useAuth } from '@praxis/component-auth'

export const User = () => {
  const ctx = useAuth()
  const session = !ctx.noProvider && ctx.session
  return session?.userInfo?.fullName
}

export const Email = () => {
  const ctx = useAuth()
  const session = !ctx.noProvider && ctx.session
  return session?.userInfo?.email
}
