import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import propItemMapper from '../mappers/propItemMapper'

const usePutPropItem = (setLoading, setWeeklyAd) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.props)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const putPropItem = async () => {
      mounted && setLoading(true)
      try {
        const id = request.body.id
        const response = await axios.put(url.current + id, request.body)
        const thisPropItem = propItemMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            const pages = w.pages
            for (let i in pages) {
              if (pages[i].id === request.pageId) {
                const stories = pages[i].stories
                for (let j in stories) {
                  if (stories[j].id === request.storyId) {
                    const cuts = stories[j].cuts
                    for (let k in cuts) {
                      if (cuts[k].id === request.cutId) {
                        const props = cuts[k].props
                        for (let l in props) {
                          if (props[l].id === id) {
                            props[l] = thisPropItem
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            return w
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }
    if (request !== null) {
      putPropItem()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd])

  return setRequest
}
export default usePutPropItem
