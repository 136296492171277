import { sortBy } from 'lodash'

export const sortItemsAndAddDetails = (
  items = [],
  picturedItems = [],
  adPatches = [],
  ivy_offer_id = 0,
  pyramidName = '',
  offer_order = 0
) => {
  let itemMap = new Map()
  items.forEach((item) => {
    // eslint-disable-next-line no-loop-func
    if (!Array.from(itemMap.keys()).includes(item.tcin)) {
      var subsetResult = picturedItems.find((pItem) => pItem.tcin === item.tcin)
      item.id = item.item_id
      item.ivy_offer_id = ivy_offer_id
      item.pyramid_name = pyramidName
      if (offer_order) {
        item.offer_order = offer_order
      }
      const adPatchResult = adPatches.filter((patch) =>
        patch.pictured_items?.find((pItem = {}) => pItem.tcin === item.tcin)
      )

      if (subsetResult && subsetResult.subset_id) {
        item.subset_id = subsetResult.subset_id
      }
      if (adPatchResult) {
        const adPatchMarkets = adPatchResult.map((adPatch) =>
          adPatch.markets?.join(', ')
        )
        item.art_patch_markets = adPatchMarkets?.join(', ')
      }
      itemMap.set(item.tcin, item)
    }
  })
  const updatedItems = Array.from(itemMap.values())

  if (offer_order) {
    return sortBy(updatedItems, ['offer_order', 'item_order']) ?? []
  }

  return sortBy(updatedItems, (a) => a.item_order) ?? []
}

export const fetchAllPicturedItems = (selectedOffer = {}) => {
  const { ad_patches = [] } = selectedOffer
  let itemMap = new Map()

  ad_patches.forEach((x = {}, i) => {
    if (x.pictured_items && x.pictured_items?.length > 0) {
      x.pictured_items?.forEach((pi, i) =>
        itemMap.set(pi.tcin, {
          ...pi,
          ivy_offer_id: selectedOffer.ivy_offer_id,
          pyramid_name: selectedOffer.pyramid_name,
        })
      )
    }
  })
  return Array.from(itemMap.values())
}

export const assethubImageCheck = (imageUrl, placeHolderImage) =>
  imageUrl !== placeHolderImage &&
  imageUrl !== '' &&
  (imageUrl?.match('(/assethub_assets/[^ "]+$)+') ||
    imageUrl?.match('(/vault_digital_assets/[^ "]+$)+'))

export const promoPrice = (pricePatches = [], item = {}) => {
  var result = pricePatches?.find(
    (pricePatch) => pricePatch?.price_subset_id?.toString() === item.subset_id
  )
  if (result) return result.price_patch.toString()
  return ''
}
