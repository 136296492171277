import {
  Grid,
  Card,
  Timeline,
  Button,
  ProgressBar,
  Form,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState, Fragment, useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import CatalogApi from '../../api/pop-catalog/CatalogApi'
import ExportApi from '../../api/pop-catalog/ExportApi'
import HistoryApi from '../../api/pop-catalog/HistoryApi'
import CustomTimelineItem from '../../components/CustomTimelineItem'
import PaginationWidget from '../../components/PaginationWidget'
import appConfig from '../../config/appConfig'
import { isAdminOrCatalogCaptain } from '../../util/CheckRole'
import { setPageFilterFromQuery } from '../../util/filterOptions'
import {
  dateFormatForHistoryViewFilter,
  formatToUi,
} from '../../util/MomentUtil'
import { HISTORY_EXPORT_URL } from '../../util/urlUtil'

import '../../scss/ProductHistory.scss'

const approvedOptions = [
  { id: 1, label: 'Reviewed', value: 'true' },
  { id: 2, label: 'Not Reviewed', value: 'false' },
]

const ProductHistoryView = function (props) {
  const { role } = props
  const isCatCaptain = isAdminOrCatalogCaptain(role)
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()
  const catalogId = useParams().catalogId

  const [enableApplyButton, setEnableApplyButton] = useState(false)
  const [refreshData, setRefreshData] = useState(true)
  const [productHistory, setProductHistory] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [transport, setTransport] = useState(0)
  const [catalog, setCatalog] = useState({})
  const [, setCatalogLoading] = useState(false)
  const [gettingExport, setGettingExport] = useState(false)

  const productHistoryApi = new HistoryApi()
  const catalogApi = new CatalogApi()

  const config = appConfig()
  const exportApi = new ExportApi()

  // Load catalog data
  useEffect(() => {
    setCatalogLoading(true)
    catalogApi
      .getCatalog(catalogId)
      .then((res) => setCatalog(res))
      .finally(() => setCatalogLoading(false))
    // eslint-disable-next-line
  }, [setCatalog, setCatalogLoading])

  // Loading product data
  useEffect(() => {
    if (refreshData) {
      query.set('catalogId', catalogId)
      productHistoryApi
        .getHistoryBatch(query)
        .then((res) => setProductHistory(res))
        .finally(() => {
          setLoading(false)
          setRefreshData(false)
        })
    }
    // eslint-disable-next-line
  }, [catalogId, setLoading, refreshData])

  // Utility changing pages
  useEffect(() => {
    if (transport !== 0) {
      let oldPage = parseInt(query.get('pageNo'))
      let newPage = oldPage ? oldPage + transport : transport
      query.set('pageNo', newPage.toString())
      setTransport(0)
      //   setLoadItems(true)
      navigate(`/catalog/${catalogId}/history?${query.toString()}`)
    }
  }, [query, navigate, transport, catalogId])

  const pageList = formatPages(catalog?.pages)

  const approvedIntialVal = approvedOptions.find(
    (app) => app.value === query.get('approved')
  )

  return catalog ? (
    <Fragment>
      {isLoading ? (
        <div className="hc-pa-normal">
          <ProgressBar indeterminate />
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            approved: approvedIntialVal ? approvedIntialVal : '',
            date: query.get('date') ? formatToUi(query.get('date')) : '',
            dpci: query.get('dpci') ? query.get('dpci') : '',
            fieldName: query.get('fieldName') ? query.get('fieldName') : '',
            page: query.get('pageId')
              ? setPageFilterFromQuery(query, pageList)
              : '',
            productName: query.get('productName')
              ? query.get('productName')
              : '',
            query: query.toString(),
            updatedBy: query.get('updatedBy') ? query.get('updatedBy') : '',
          }}
          onSubmit={(values) => {
            !isEmpty(values.dpci)
              ? query.set('dpci', values.dpci)
              : query.delete('dpci')

            !isEmpty(values.updatedBy)
              ? query.set('updatedBy', values.updatedBy)
              : query.delete('updatedBy')

            !isEmpty(values.page)
              ? query.set(
                  'pageId',
                  values.page.map((page) => page.id).join(',')
                )
              : query.delete('pageId')

            !isEmpty(values.productName)
              ? query.set('productName', values.productName)
              : query.delete('productName')

            !isEmpty(values.fieldName)
              ? query.set('fieldName', values.fieldName)
              : query.delete('fieldName')

            !isEmpty(values.date)
              ? query.set('date', dateFormatForHistoryViewFilter(values?.date))
              : query.delete('date')

            !isEmpty(values.approved)
              ? query.set('approved', values.approved.value)
              : query.delete('approved')

            navigate(`/catalog/${catalogId}/history?${query.toString()}`)
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
            errors,
            touched,
          }) => (
            <>
              <div className="hc-pa-normal hc-pa-none">
                <Grid.Container
                  padding="dense"
                  spacing="none"
                  justify="flex-end"
                  align="flex-end"
                >
                  <Grid.Item>
                    <PaginationWidget
                      corners="top"
                      pages={productHistory}
                      setTransport={setTransport}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Card>
                      <div className="hc-pa-normal">
                        <Grid.Container
                          padding="dense"
                          spacing="none"
                          justify="flex-end"
                          align="flex-end"
                        >
                          <Grid.Item xs={12} className="hc-ta-center">
                            <Heading>History: {catalog.name}</Heading>
                            <p>Run Date: {catalog.runDate}</p>
                          </Grid.Item>
                          <Grid.Item xs={12}>
                            <div className="hc-pa-normal">
                              <Grid.Container
                                align="center"
                                justify="center"
                                padding="dense"
                                className="historyFilters"
                              >
                                <Grid.Item xs={2}>
                                  <DatePicker
                                    error={errors.date && touched.date}
                                    id="date"
                                    label=""
                                    aria-label="select date"
                                    placeholder="Date"
                                    className="datepicker-icon"
                                    value={values.date}
                                    onUpdate={(id, value) => {
                                      setFieldValue(id, value)
                                      values.date !== value &&
                                        setEnableApplyButton(true)
                                    }}
                                    location="bottom-left-left"
                                  />
                                  <Form.Field
                                    id="productName"
                                    placeholder="Product Name"
                                    value={values.productName}
                                    onChange={handleChange}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        handleSubmit()
                                      }
                                      if (e.key === 'Tab') {
                                        handleSubmit()
                                      }
                                    }}
                                    className="bottom-input-spacing"
                                  />
                                </Grid.Item>
                                <Grid.Item xs={2}>
                                  <Form.Field
                                    id="dpci"
                                    placeholder="DPCI"
                                    value={values.dpci}
                                    onChange={handleChange}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        handleSubmit()
                                      }
                                      if (e.key === 'Tab') {
                                        handleSubmit()
                                      }
                                    }}
                                  />
                                  <Form.Field
                                    id="updatedBy"
                                    placeholder="Updated by"
                                    value={values.updatedBy}
                                    onChange={handleChange}
                                    className="bottom-input-spacing"
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        handleSubmit()
                                      }
                                      if (e.key === 'Tab') {
                                        handleSubmit()
                                      }
                                    }}
                                  />
                                </Grid.Item>
                                <Grid.Item
                                  xs={2}
                                  className="autocomplete-alignment"
                                >
                                  <Autocomplete
                                    id="page"
                                    multiselect
                                    chipHeight="dense"
                                    onUpdate={(id, value) => {
                                      setFieldValue(id, value)
                                      value.length !== values.page.length &&
                                        setEnableApplyButton(true)
                                    }}
                                    value={values.page}
                                    options={pageList}
                                    placeholder="Page"
                                  />
                                  <Form.Field
                                    id="fieldName"
                                    placeholder="Field Name"
                                    value={values.fieldName}
                                    onChange={handleChange}
                                    className="plv-input-alignment bottom-input-spacing"
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        handleSubmit()
                                      }
                                      if (e.key === 'Tab') {
                                        handleSubmit()
                                      }
                                    }}
                                  />
                                </Grid.Item>
                                <Grid.Item xs={2}>
                                  {isCatCaptain && (
                                    <Autocomplete
                                      id="approved"
                                      onUpdate={(id, vendor) => {
                                        setFieldValue(id, vendor)
                                        handleSubmit()
                                      }}
                                      chipHeight="dense"
                                      value={values.approved}
                                      options={approvedOptions}
                                      placeholder="Review Status"
                                    />
                                  )}
                                </Grid.Item>
                                <Grid.Item xs={2}>
                                  <Button
                                    type="primary"
                                    disabled={!enableApplyButton}
                                    onClick={handleSubmit}
                                  >
                                    Apply
                                  </Button>{' '}
                                  <Button
                                    type="secondary"
                                    onClick={() => {
                                      navigate(`/catalog/${catalogId}/history`)
                                      handleReset()
                                    }}
                                  >
                                    Clear
                                  </Button>
                                </Grid.Item>
                                <Grid.Item xs={2} justify-content="end">
                                  <Button
                                    type="secondary"
                                    onClick={() => {
                                      const newQ = new URLSearchParams(
                                        values?.query
                                      )
                                      setGettingExport(true)
                                      newQ.set('key', config.catapi.apikey)
                                      exportApi
                                        .getExport(
                                          HISTORY_EXPORT_URL(catalogId) +
                                            newQ.toString()
                                        )
                                        .finally(() => {
                                          setGettingExport(false)
                                        })
                                    }}
                                  >
                                    Export
                                  </Button>
                                </Grid.Item>
                                <Grid.Item xs={12}>
                                  {gettingExport && (
                                    <ProgressBar indeterminate />
                                  )}
                                </Grid.Item>
                              </Grid.Container>
                            </div>
                          </Grid.Item>
                        </Grid.Container>
                      </div>
                    </Card>
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <div className="hc-pa-none">
                      <Card elevation={2} corners="top" className="hc-pa-dense">
                        <Timeline>
                          {productHistory?.history?.map((hty) => (
                            <CustomTimelineItem
                              key={`custom-timeline-${hty.id}`}
                              history={hty}
                              isAllowed={isCatCaptain}
                              setProductHistory={setProductHistory}
                            />
                          ))}
                        </Timeline>
                      </Card>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <PaginationWidget
                      corners="bottom"
                      pages={productHistory}
                      setTransport={setTransport}
                    />
                  </Grid.Item>
                </Grid.Container>
              </div>
            </>
          )}
        </Formik>
      )}
    </Fragment>
  ) : null
}

function formatPages(pages) {
  return !isEmpty(pages)
    ? pages
        ?.map((page) => ({
          id: page.id,
          label: `Page ${page.pageOrdering + 1}`,
          value: page.id,
        }))
        .concat([{ id: -1, label: 'TBD', value: -1 }])
    : []
}
export default ProductHistoryView
