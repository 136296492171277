import * as yup from 'yup'

/// For EditWeeklyAdDetails.js

export const editWeeklyAdSchema = yup.object().shape({
  eventWeek: yup.string().required('Required'),
  invalidEmails: yup
    .array()
    .test(
      'valid contacts',
      'All contacts must be valid emails.  Please update the following invalid contacts:',
      (invalidEmails) => (invalidEmails.length === 0 ? true : false)
    ),
  projectName: yup.string().required('Required!'),
  revision: yup
    .number()
    .integer()
    .min(0)
    .typeError('Must be a number')
    .required('Required'),
})

export const emailSchema = yup.object().shape({
  invalidEmails: yup
    .array()
    .test(
      'valid contacts',
      'All contacts must be valid emails. Please update the following invalid contacts:',
      (invalidEmails) => (invalidEmails.length === 0 ? true : false)
    ),
})

export const createWeeklyAdEmailPayload = (values) => {
  let payload = ''
  const nameFields = [
    'aahomeProductProducer',
    'apparelIhp',
    'apparelKids',
    'apparelMens',
    'apparelRtw',
    'babyMerchantDivisionCaptain',
    'cscSupport',
    'digitalEp',
    'digitalWriters',
    'epManager',
    'epTeam',
    'frequencyhlProductProducer',
    'hardlinesElec',
    'hardlinesEnt',
    'hardlinesSprt',
    'hardlinesToys',
    'homeDec',
    'homeKtn',
    'homeStat',
    'projectManager',
    'epCaptain',
    'headlineWriter',
    'pmmApparel',
    'pmmBeauty',
    'pmmEssentials',
    'pmmFoodBev',
    'pmmHardlines',
    'pmmHome',
    'plannerAa',
    'plannerBeauty',
    'plannerEssentials',
    'plannerFb',
    'plannerHardlines',
    'plannerHome',
  ]
  for (const field of nameFields) {
    for (const item of values[field]) {
      payload = payload + item.value + '@target.com, '
    }
  }
  return payload
}

export const addEmail = (name) =>
  name.split('@')[1] === 'target.com' ? name : `${name}@target.com`

export const createWeeklyAdEmailPayloadContacts = (values, keysArr) => {
  let payload = ''
  for (const field of keysArr) {
    for (const item of values[field]) {
      payload = payload + addEmail(item.value) + ', '
    }
  }
  return payload
}

export const createProjectEmailPayload = (values) => {
  let payload = ''
  const nameFields = [
    'audioCopywriter',
    'dynamicDigitalArtDirector',
    'dynamicDigitalCopywriter',
    'dynamicVideoArtDirector',
    'dynamicAudioCopywriter',
    'dwaArtDirector',
    'dwaKickoffCopywriter',
    'dwaPostMprCopywriter',
    'prodDesignCaptain',
    'socialDigitalArtDirector',
    'socialDigitalCopywriter',
    'videoArtDirector',
    'videoCopywriter',
  ]
  for (const field of nameFields) {
    for (const item of values[field]) {
      payload = payload + item.value + '@target.com, '
    }
  }
  return payload
}

export const validateWeeklyAdEmails = async (values, api) => {
  const emailPayload = createWeeklyAdEmailPayload(values)
  return await api.getEmailData(emailPayload).then((res) => res.unknowns)
}

export const validateWeeklyAdEmailsContacts = async (values, api, keysArr) => {
  try {
    const emailPayload = createWeeklyAdEmailPayloadContacts(values, keysArr)
    return await api.getEmailData(emailPayload).then((res) => res.unknowns)
  } catch (e) {
    console.log('e', e.message)
    return []
  }
}

export const validateProjectEmails = async (values, api) => {
  const emailPayload = createProjectEmailPayload(values)
  if (emailPayload) {
    return await api.getEmailData(emailPayload).then((res) => res)
  }
  return undefined
}

// For EditPageDetails.js
export const editWeeklyAdPageSchema = yup.object().shape({
  invalidEmails: yup
    .array()
    .test(
      'valid contacts',
      'All contacts must be valid emails.  Please update the following invalid contacts:',
      (invalidEmails) => (invalidEmails.length === 0 ? true : false)
    ),
})

export const createWeeklyAdPageEmailPayload = (values) => {
  let payload = ''
  const nameFields = ['artDirector', 'copyWriter', 'postMprCopyWriter']
  for (const field of nameFields) {
    for (const item of values[field]) {
      payload = payload + item.value + '@target.com, '
    }
  }
  return payload
}

export const validateWeeklyAdPageEmails = async (values, api) => {
  const emailPayload = createWeeklyAdPageEmailPayload(values)
  if (emailPayload !== '') {
    return await api.getEmailData(emailPayload).then((res) => res.unknowns)
  }
  return []
}

export const validateEmails = async (values, api) => {
  const emails = values
  return await api.getEmailData(emails).then((res) => res)
}
