/* eslint-disable import-order-alphabetical/order */
import {
  faPlusCircle,
  faTimesCircle,
  faSave,
} from '@fortawesome/free-solid-svg-icons'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Input,
  Grid,
  Card,
  Heading,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import { mapPmm } from '../../util/mapPmmHelper'
import * as yup from 'yup'
import PlatformApi from '../../api/platform-notifications/EmailVerification'
import ProjectApi from '../../api/creative-hub/ProjectApi'
import { validateProjectEmails } from '../../forms/EmailVerificationHelper'
import {
  weekOptions,
  monthOptions,
  quarterOptions,
  formatContacts,
} from '../util/CreateEditProjectUtil.js'
import { formatDateForDb, formatDateForCanvas } from '../util/DateUtil.js'
import { EffectiveRoleContext } from '../../context/Context'
import {
  canEditSystemAndProjectContacts,
  isInCanEditPlanningViewGroup,
} from '../../util/CheckRole'

import '../scss/CreateEditProject.scss'

const CreateEditProject = () => {
  const platformApi = new PlatformApi()
  const api = new ProjectApi()

  const makeToast = ToastProvider.useToaster()

  const [project, setProject] = useState({})
  const [contactsByEntitlement, setContactsByEntitlement] = useState()
  const [isProcessing, setIsProcessing] = useState(false)

  const [selectedProjectId] = useState(useParams().projectId)
  const navigate = useNavigate()
  const { effectiveRole } = useContext(EffectiveRoleContext)

  const canEdit = effectiveRole?.some((role) =>
    isInCanEditPlanningViewGroup(role)
  )

  const canEditProjectContacts = effectiveRole?.some((role) =>
    canEditSystemAndProjectContacts(role)
  )

  const editProjectEntitlements = [
    'APP-OAUTH2-CrtvHub-CreativeArt',
    'APP-OAUTH2-CrtvHub-Copywriter',
    'APP-OAUTH2-CrtvHub-CreativeProd',
  ]
  const entitlements = []

  useEffect(() => {
    async function fetchData() {
      if (selectedProjectId) {
        api
          .fetchProjectDetails(selectedProjectId)
          .then((data) => {
            setProject(data)
          })
          .catch((e) => console.log('error', e))
      }

      for (let i = 0; i < editProjectEntitlements.length; i++) {
        await platformApi
          .getContactsByEntitlement(editProjectEntitlements[i])
          .then((data) => {
            entitlements.push(data)
          })
          .catch((e) => console.log('error', e))
      }
      setContactsByEntitlement(entitlements)
    }
    fetchData()
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])

  const contactOpions = formatContacts(contactsByEntitlement)
  const { Copywriter = [], CreativeArt = [], CreativeProd = [] } = contactOpions

  const validationSchema = yup.object().shape({
    endDate: yup
      .date()
      .min(yup.ref('startDate'), 'End date cannot be before the start date'),
    invalidEmails: yup
      .array()
      .test('valid contacts', 'Invalid contacts:', (invalidEmails) =>
        invalidEmails.length === 0 ? true : false
      ),
    projectMonth: yup.string().required('Month is required'),
    projectQuarter: yup.string().required('Quarter is required'),
    projectWeek: yup.string().required('Week is required'),
    startDate: yup.date().required('Start date is required'),
  })

  return (
    <Card className="createEditProjectCard" data-testid="projectCard">
      <Grid.Container>
        <Grid.Item xs={12} align="right">
          <Button
            aria-label="go back "
            iconOnly
            onClick={() => {
              navigate('/planning')
            }}
          >
            <FontAwesomeIcon size="lg" icon={faTimesCircle} color="#000000" />
          </Button>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container spacing="none" justify="flex-end" align="flex-end">
        <Grid.Item className="hc-ta-center" xs={12}>
          <Heading>
            {selectedProjectId ? 'Edit Project' : 'Create Project'}
          </Heading>
        </Grid.Item>
      </Grid.Container>

      <Formik
        initialValues={{
          audioCopywriter: mapPmm(project?.audio_copywriter) || [],
          dwaArtDirector: mapPmm(project?.dwa_art_director) || [],
          dwaKickoffCopywriter: mapPmm(project?.dwa_kickoff_copywriter) || [],
          dwaPostMprCopywriter: mapPmm(project?.dwa_post_mpr_copywriter) || [],
          dynamicAudioCopywriter:
            mapPmm(project?.dynamic_audio_copywriter) || [],
          dynamicDigitalArtDirector:
            mapPmm(project?.dynamic_digital_art_director) || [],
          dynamicDigitalCopywriter:
            mapPmm(project?.dynamic_digital_copywriter) || [],
          dynamicVideoArtDirector:
            mapPmm(project?.dynamic_video_art_director) || [],
          dynamicVideoCopywriter:
            mapPmm(project?.dynamic_video_copywriter) || [],
          endDate: formatDateForCanvas(project?.end_date) || '',
          invalidEmails: [],
          prodDesignCaptain: mapPmm(project?.prod_design_captain) || [],
          projectMonth: project?.month || '',
          projectName: project?.project_name || '',
          projectQuarter: project?.quarter || '',
          projectWeek: project?.week?.toString() || '',
          socialDigitalArtDirector:
            mapPmm(project?.social_digital_art_director) || [],
          socialDigitalCopywriter:
            mapPmm(project?.social_digital_copywriter) || [],
          startDate: formatDateForCanvas(project?.start_date) || '',
          strategicIntent: project?.strategic_intent || '',
          updatedDate: project?.updated_date || '',
          videoArtDirector: mapPmm(project?.video_art_director) || [],
          videoCopywriter: mapPmm(project?.video_copywriter) || [],
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async ({
          endDate,
          projectMonth,
          prodDesignCaptain,
          projectName,
          projectQuarter,
          socialDigitalArtDirector,
          audioCopywriter,
          dynamicDigitalArtDirector,
          dynamicDigitalCopywriter,
          dynamicVideoArtDirector,
          dynamicVideoCopywriter,
          dynamicAudioCopywriter,
          socialDigitalCopywriter,
          dwaArtDirector,
          startDate,
          dwaKickoffCopywriter,
          strategicIntent,
          dwaPostMprCopywriter,
          updatedDate,
          videoArtDirector,
          projectWeek,
          videoCopywriter,
        }) => {
          setIsProcessing(true)
          const projectId = project?.project_id

          let payload = {
            audio_copywriter: audioCopywriter.map((option) => option.value),
            dwa_art_director: dwaArtDirector.map((option) => option.value),
            dwa_kickoff_copywriter: dwaKickoffCopywriter.map(
              (option) => option.value
            ),
            dwa_post_mpr_copywriter: dwaPostMprCopywriter.map(
              (option) => option.value
            ),
            dynamic_audio_copywriter: dynamicAudioCopywriter.map(
              (option) => option.value
            ),
            dynamic_digital_art_director: dynamicDigitalArtDirector.map(
              (option) => option.value
            ),
            dynamic_digital_copywriter: dynamicDigitalCopywriter.map(
              (option) => option.value
            ),
            dynamic_video_art_director: dynamicVideoArtDirector.map(
              (option) => option.value
            ),
            dynamic_video_copywriter: dynamicVideoCopywriter.map(
              (option) => option.value
            ),
            end_date: formatDateForDb(endDate),
            month: projectMonth,
            prod_design_captain: prodDesignCaptain.map(
              (option) => option.value
            ),
            project_name: projectName,
            quarter: projectQuarter,
            social_digital_art_director: socialDigitalArtDirector.map(
              (option) => option.value
            ),
            social_digital_copywriter: socialDigitalCopywriter.map(
              (option) => option.value
            ),
            start_date: formatDateForDb(startDate),
            strategic_intent: strategicIntent,
            updated_date: updatedDate,
            video_art_director: videoArtDirector.map((option) => option.value),
            video_copywriter: videoCopywriter.map((option) => option.value),
            week: projectWeek,
            year: startDate.substring(startDate.length - 4),
          }

          if (projectId && project?.assethub_project_id) {
            payload = {
              ...payload,
              assethub_project_id: project.assethub_project_id,
              assethub_project_uuid: project?.assethub_project_uuid,
            }
          }

          const response = projectId
            ? await api.putProject(
                { ...payload, created_by: project.created_by },
                projectId
              )
            : await api.createProject(payload)
          if (response.error_message) {
            setIsProcessing(false)
            makeToast({
              heading: 'Error',
              isVisible: true,
              message: response.error_message,
              type: 'error',
            })
          } else {
            setIsProcessing(false)
            makeToast({
              heading: 'Project was created.',
              isVisible: true,
              message: 'Nice.',
              type: 'success',
            })
            navigate('/planning')
          }
        }}
      >
        {({
          values,
          errors,
          isValid,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          const verifyEmails = async function (values) {
            const response = await validateProjectEmails(values, platformApi)
            if (response !== undefined) {
              setFieldValue('invalidEmails', response.unknowns)
            } else {
              setFieldValue('invalidEmails', [])
            }
          }

          const handleEmailFieldUpdate = (id, value) => {
            setFieldValue(id, value)
            let valuesCopy = { ...values }
            valuesCopy[id] = value
            verifyEmails(valuesCopy)
          }

          const {
            audioCopywriter,
            dwaArtDirector,
            dwaKickoffCopywriter,
            dwaPostMprCopywriter,
            dynamicDigitalArtDirector,
            dynamicDigitalCopywriter,
            dynamicVideoArtDirector,
            dynamicVideoCopywriter,
            dynamicAudioCopywriter,
            prodDesignCaptain,
            socialDigitalArtDirector,
            socialDigitalCopywriter,
            videoArtDirector,
            videoCopywriter,
          } = values

          return (
            <Grid.Container className="form">
              <Grid.Item xs={4} data-testid="projectStartDate">
                <DatePicker
                  id="startDate"
                  error={errors.startDate && touched.startDate}
                  errorText={errors.startDate}
                  label="Start Date"
                  required
                  onUpdate={(id, value) => setFieldValue(id, value)}
                  value={values.startDate}
                  data-cy="project-startdate"
                  location="bottom-left-left"
                  disabled={!canEdit}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <DatePicker
                  id="endDate"
                  error={errors.endDate}
                  errorText={errors.endDate}
                  label="End Date"
                  required
                  onUpdate={(id, value) => setFieldValue(id, value)}
                  value={values.endDate}
                  data-testid="projectEndDate"
                  data-cy="project-enddate"
                  disabled={!canEdit}
                  location="bottom-left"
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Input.Label>Optional Project Name</Input.Label>
                <Input.Text
                  id="projectName"
                  onChange={handleChange}
                  value={values.projectName}
                  data-cy="project-name"
                  disabled={!canEdit}
                />
              </Grid.Item>
              <Grid.Item xs={12}>
                <Grid.Container xs={12}>
                  <Grid.Item xs={4}>
                    <Input.Label required>Quarter</Input.Label>
                    <Input.Select
                      id="projectQuarter"
                      options={quarterOptions}
                      onUpdate={(id, value) => setFieldValue(id, value)}
                      error={errors.projectQuarter && touched.projectQuarter}
                      required
                      defaultValue={values.projectQuarter}
                      data-testid="quarter"
                      data-cy="project-quarter"
                      disabled={!canEdit}
                    />
                  </Grid.Item>
                  <Grid.Item xs={4}>
                    <Input.Label required>Month</Input.Label>
                    <Input.Select
                      id="projectMonth"
                      options={monthOptions}
                      onUpdate={(id, value) => setFieldValue(id, value)}
                      error={errors.projectMonth && touched.projectMonth}
                      required
                      defaultValue={values.projectMonth}
                      data-testid="month"
                      data-cy="project-month"
                      disabled={!canEdit}
                    />
                  </Grid.Item>
                  <Grid.Item xs={4}>
                    <Input.Label required>Week</Input.Label>
                    <Input.Select
                      id="projectWeek"
                      options={weekOptions}
                      onUpdate={(id, value) => setFieldValue(id, value)}
                      error={errors.projectWeek && touched.projectWeek}
                      required
                      defaultValue={values.projectWeek}
                      data-testid="week"
                      data-cy="project-week"
                      disabled={!canEdit}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item xs={12}></Grid.Item>
              <Grid.Container
                spacing="none"
                justify="flex-end"
                align="flex-end"
              >
                <Grid.Item className="hc-ta-center" xs={12}>
                  <Heading>Creative Contacts</Heading>
                </Grid.Item>
              </Grid.Container>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="prodDesignCaptain"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={prodDesignCaptain}
                  options={CreativeProd}
                  label="Prod Design Captain"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="socialDigitalArtDirector"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={socialDigitalArtDirector}
                  options={CreativeArt}
                  label="Social Digital Art Director"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="socialDigitalCopywriter"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={socialDigitalCopywriter}
                  options={Copywriter}
                  label="Social Digital Copywriter"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="videoArtDirector"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={videoArtDirector}
                  options={CreativeArt}
                  label="Video Art Director"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="videoCopywriter"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={videoCopywriter}
                  options={Copywriter}
                  label="Video Copywriter"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="audioCopywriter"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={audioCopywriter}
                  options={Copywriter}
                  label="Audio Copywriter"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="dynamicDigitalArtDirector"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={dynamicDigitalArtDirector}
                  options={CreativeArt}
                  label="Dynamic Digital Art Director"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="dynamicDigitalCopywriter"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={dynamicDigitalCopywriter}
                  options={Copywriter}
                  label="Dynamic Digital Copywriter"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="dynamicVideoArtDirector"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={dynamicVideoArtDirector}
                  options={CreativeArt}
                  label="Dynamic Digital Video Art Director"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="dynamicVideoCopywriter"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={dynamicVideoCopywriter}
                  options={Copywriter}
                  label="Dynamic Digital Video Copywriter"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="dynamicAudioCopywriter"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={dynamicAudioCopywriter}
                  options={Copywriter}
                  label="Dynamic Digital Audio Copywriter"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="dwaArtDirector"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={dwaArtDirector}
                  options={CreativeArt}
                  label="DWA Art Director"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Autocomplete
                  id="dwaKickoffCopywriter"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={dwaKickoffCopywriter}
                  options={Copywriter}
                  label="DWA Kickoff Copywriter"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>

              <Grid.Item xs={4}>
                <Autocomplete
                  id="dwaPostMprCopywriter"
                  multiselect
                  allowCustomInputValue={true}
                  chipHeight="dense"
                  className="hc-mb-dense hc-mt-dense"
                  onUpdate={handleEmailFieldUpdate}
                  value={dwaPostMprCopywriter}
                  options={Copywriter}
                  label="DWA Post-MPR Copywriter"
                  noResultsMessage="Use Outlook Name"
                  disabled={!canEditProjectContacts}
                />
              </Grid.Item>
              <Grid.Item xs={4}>
                <Grid.Container xs={4} justify="flex-end">
                  <Grid.Item xs={4}>
                    {isProcessing ? (
                      <Button
                        className="primary"
                        fullWidth
                        data-testid="processingButton"
                        disabled
                        data-cy="project-submit"
                        aria-label="Submit button"
                      >
                        <Spinner className="hc-mh-normal" size="dense" />
                      </Button>
                    ) : (
                      <Button
                        className="redButton"
                        fullWidth
                        onClick={(e) => {
                          handleSubmit(e)
                        }}
                        data-testid="submitButton"
                        disabled={values.invalidEmails.length > 0}
                        data-cy="project-submit"
                        aria-label="Submit button"
                      >
                        <FontAwesomeIcon
                          icon={project?.project_id ? faSave : faPlusCircle}
                        />
                        <div>{project?.project_id ? 'Save' : 'Create'}</div>
                      </Button>
                    )}
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>

              <Grid.Container>
                <Grid.Item>
                  <p className="errorEmailMessage">{errors.invalidEmails}</p>
                  <ul className="errorEmailList">
                    {values.invalidEmails.map((email, index) => (
                      <li key={index}>{email}</li>
                    ))}
                  </ul>
                </Grid.Item>
              </Grid.Container>
            </Grid.Container>
          )
        }}
      </Formik>
    </Card>
  )
}
export default CreateEditProject
