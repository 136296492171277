import {
  Link,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  Path,
  Tspan,
} from '@react-pdf/renderer'
import { format, parseISO } from 'date-fns'

import appConfig from '../../config/appConfig'
import {
  getChipValues,
  getPyramidAbbrByGroupName,
  getPyramidColor,
} from '../util/ProjectDetailsUtil'
import { filterUmbrellasByChannel } from '../util/UmbrellaUtil'

const config = appConfig()
const cellWidth = 80
const labelContentWidth = 350

const MarketChannelPdf = ({
  assigned,
  channels = [],
  channelFilters = [],
  enterpriseLinkedUmbrellas,
  projectTitle,
  projectEndDate,
  projectStartDate,
  pyramidTitle,
  pyramidElements,
  umbrellas = [],
}) => {
  let pyramidId = ''
  let storyId = ''
  let pyramidElementIndex = 0
  const filteredUmbrellas = filterUmbrellasByChannel(umbrellas, channelFilters)

  // Create styles
  const styles = StyleSheet.create({
    cell: {
      backgroundColor: 'white',
      borderColor: 'black',
      borderWidth: 0,
      flexDirection: 'row',
      fontSize: 9,
      justifyContent: 'center',
      padding: 0,
      textAlign: 'center',
      width: cellWidth,
    },
    cellContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      width: 80,
    },
    checkedIcon: {
      borderColor: '#b85300',
      borderRadius: '50%',
      borderWidth: 6,
      height: 21,
      width: 21,
    },
    chip: {
      backgroundColor: 'red',
      borderRadius: 12,
      color: 'white',
      marginRight: 4,
      padding: '3px 7px',
    },
    labelContent: {
      alignItems: 'center',
      flexDirection: 'row',
      fontSize: 9,
      justifyContent: 'flex-start',
      padding: 9,
      width: 350,
    },
    mainContainer: {
      alignItems: 'flex-start',
      backgroundColor: 'white',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 10,
    },
    newOutcomeSubTitle: {
      fontSize: 11,
      fontStyle: 'italic',
      fontWeight: 'normal',
      opacity: 0.5,
    },
    newOutcomeTitle: {
      fontStyle: 'italic',
      fontWeight: 700,
    },
    outcomes: {
      alignItems: 'center',
      backgroundColor: 'lightgray',
      flexDirection: 'row',
      flexGrow: 1,
      fontSize: 11,
      justifyContent: 'flex-start',
      padding: 3,
      width: labelContentWidth + cellWidth * channels.length,
    },
    page: {
      alignItems: 'flex-start',
      backgroundColor: '#E4E4E4',
      padding: 10,
    },
    section: {
      flexDirection: 'row',
      flexGrow: 1,
      marginBottom: 5,
    },
    sectionTop: {
      alignItems: 'flex-end',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'space-between',
      margin: 10,
      padding: 10,
      textAlign: 'center',
      width: labelContentWidth + cellWidth * channels.length,
    },
    story: {
      alignItems: 'center',
      backgroundColor: '#eeeeee',
      flexDirection: 'row',
      flexGrow: 1,
      fontSize: 10,
      justifyContent: 'flex-start',
      padding: 6,
      width: labelContentWidth + cellWidth * channels.length,
    },
    title: {
      justifyContent: 'center',
    },
    unCheckedIcon: {
      borderColor: 'black',
      borderRadius: '50%',
      borderWidth: 1,
      height: 21,
      width: 21,
    },
  })
  return (
    <Document useOC>
      <Page size="A2" orientation="landscape" style={styles.page}>
        <View style={styles.mainContainer}>
          <View style={styles.sectionTop}>
            <Text style={{ fontSize: 10 }}>
              {`${pyramidTitle} - ${projectTitle} ${format(
                parseISO(projectStartDate),
                'MM/dd/yyyy'
              )} to ${format(parseISO(projectEndDate), 'MM/dd/yyyy')}`}
            </Text>
            <Text>CreativeHub M&D Planning Info</Text>
            <Text style={{ fontSize: 10 }}>
              {`Created At: ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}
            </Text>
          </View>
          <View style={styles.section}>
            <View style={styles.labelContent}>
              <Text>Umbrella</Text>
            </View>
            {channels.map((channel) => (
              <Text key={channel.channel_d} style={styles.cell}>
                {channel.channel_name}
              </Text>
            ))}
          </View>
          <>
            {filteredUmbrellas.map((umbrella, index) => {
              const isEnterpriseLinked =
                enterpriseLinkedUmbrellas?.linkedUmbrellas?.find(
                  (entLinkedUmbrella) =>
                    entLinkedUmbrella.ivy_umbrella_id ===
                    umbrella.ivy_umbrella_id
                )
              const pyramidName =
                getPyramidAbbrByGroupName(
                  umbrella.merchant_category?.group_name
                ) || ''
              const pyramidColor = getPyramidColor(pyramidName).colorCode
              const pyramidDisplayName = getChipValues(pyramidName)

              const isKey = umbrella.key

              const isNewStory = storyId !== umbrella.storyId
              const outcomes = umbrella.guest_outcomes
              const isNewOutcomes =
                !!outcomes && pyramidId !== umbrella.pyramid_id
              const storyName = umbrella.story_name

              pyramidElementIndex =
                isNewOutcomes && index !== 0
                  ? pyramidElementIndex + 1
                  : pyramidElementIndex
              storyId = umbrella.story_id
              pyramidId = umbrella.pyramid_id
              return (
                <>
                  {isNewOutcomes ? (
                    <View style={styles.outcomes} key="row-new-outcomes">
                      <Text style={styles.newOutcomeTitle}>
                        {pyramidElements[pyramidElementIndex]?.pyramid_name}:{' '}
                        <Tspan style={styles.newOutcomeSubTitle}>
                          {outcomes}
                        </Tspan>
                      </Text>
                    </View>
                  ) : null}
                  {isNewStory ? (
                    <View
                      style={styles.story}
                      key="row-new-story"
                      className={
                        isNewOutcomes ? `story-style` : 'story-lone-style'
                      }
                    >
                      <Text>
                        <b>Story Name:</b> {storyName}
                      </Text>
                    </View>
                  ) : null}
                  <View key={umbrella.ivy_umbrella_id} style={styles.section}>
                    <View style={styles.labelContent}>
                      {isEnterpriseLinked && pyramidTitle !== 'Enterprise' ? (
                        <Svg
                          viewBox="0 0 512 512"
                          style={{ marginRight: 5 }}
                          height={15}
                          width={15}
                        >
                          <Path
                            fill="darkorange"
                            d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                          />
                        </Svg>
                      ) : null}
                      {pyramidDisplayName && pyramidTitle === 'Enterprise' ? (
                        <View
                          style={[
                            styles.chip,
                            {
                              alignItems: 'center',
                              backgroundColor:
                                pyramidColor === 'darkGrey'
                                  ? '#eeeeee'
                                  : pyramidColor,
                              justifyContent: 'center',
                              minWidth: 55,
                            },
                          ]}
                        >
                          <Text style={{ textAlign: 'center' }}>
                            {pyramidDisplayName}
                          </Text>
                        </View>
                      ) : null}
                      <View>
                        {isKey ? (
                          <Svg
                            viewBox="0 0 512 512"
                            style={{ marginRight: 5 }}
                            height={15}
                            width={15}
                          >
                            <Path
                              fill="black"
                              d="M 512 176.001 C 512 273.203 433.202 352 336 352 c -11.22 0 -22.19 -1.062 -32.827 -3.069 l -24.012 27.014 A 23.999 23.999 0 0 1 261.223 384 H 224 v 40 c 0 13.255 -10.745 24 -24 24 h -40 v 40 c 0 13.255 -10.745 24 -24 24 H 24 c -13.255 0 -24 -10.745 -24 -24 v -78.059 c 0 -6.365 2.529 -12.47 7.029 -16.971 l 161.802 -161.802 C 163.108 213.814 160 195.271 160 176 C 160 78.798 238.797 0.001 335.999 0 C 433.488 -0.001 512 78.511 512 176.001 Z M 336 128 c 0 26.51 21.49 48 48 48 s 48 -21.49 48 -48 s -21.49 -48 -48 -48 s -48 21.49 -48 48 Z"
                            />
                          </Svg>
                        ) : null}
                      </View>
                      <Text>
                        <View>
                          {isEnterpriseLinked &&
                          pyramidTitle !== 'Enterprise' ? (
                            <Svg
                              viewBox="0 0 512 512"
                              style={{ marginRight: 5 }}
                              height={15}
                              width={15}
                            >
                              <Path
                                fill="darkorange"
                                d="M282.3 343.7L248.1 376.1C244.5 381.5 238.4 384 232 384H192V424C192 437.3 181.3 448 168 448H128V488C128 501.3 117.3 512 104 512H24C10.75 512 0 501.3 0 488V408C0 401.6 2.529 395.5 7.029 391L168.3 229.7C162.9 212.8 160 194.7 160 176C160 78.8 238.8 0 336 0C433.2 0 512 78.8 512 176C512 273.2 433.2 352 336 352C317.3 352 299.2 349.1 282.3 343.7zM376 176C398.1 176 416 158.1 416 136C416 113.9 398.1 96 376 96C353.9 96 336 113.9 336 136C336 158.1 353.9 176 376 176z"
                              />
                            </Svg>
                          ) : null}
                        </View>
                        <Link
                          src={`${config.ivy.uiUrl}umbrella/${umbrella.ivy_umbrella_id}`}
                        >
                          {umbrella.name}
                        </Link>
                      </Text>
                    </View>
                    {channels.map((channel) => {
                      const isChecked =
                        assigned?.find(
                          (assignee) =>
                            assignee.channel_id === channel.channel_id &&
                            assignee.umbrella_id === umbrella.id
                        ) !== undefined
                      return (
                        <View
                          key={channel.channel_id}
                          style={styles.cellContainer}
                        >
                          <View
                            style={
                              isChecked
                                ? styles.checkedIcon
                                : styles.unCheckedIcon
                            }
                          />
                        </View>
                      )
                    })}
                  </View>
                </>
              )
            })}
          </>
        </View>
      </Page>
    </Document>
  )
}

export default MarketChannelPdf
