import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import appConfig from '../config/appConfig'

const useDeleteWad = (setLoading) => {
  const config = appConfig()
  const url = config.backend.root + config.backend.weeklyAd
  const [request, setRequest] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    let mounted = true
    const deleteWad = async () => {
      mounted && setLoading(true)
      try {
        await axios.delete(url + request.id)
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
      navigate('/weeklyAd')
    }
    if (request !== null) {
      deleteWad()
    }
    return () => {
      mounted = false
    }
  }, [navigate, setLoading, request, url])

  return setRequest
}
export default useDeleteWad
