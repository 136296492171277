import { GridItem, Heading } from '@enterprise-ui/canvas-ui-react'
import { ModalDrawer } from 'nicollet-react'

import '../scss/ProjectContacts.scss'

const ProjectContacts = ({
  setIsDrawerOpen,
  isDrawerOpen,
  selectedProject = {},
}) => {
  const {
    prod_design_captain = [],
    video_art_director = [],
    video_copywriter = [],
    audio_copywriter = [],
    dynamic_digital_art_director = [],
    dynamic_digital_copywriter = [],
    dynamic_video_art_director = [],
    dynamic_video_copywriter = [],
    dynamic_audio_copywriter = [],
    dwa_art_director = [],
    dwa_kickoff_copywriter = [],
    dwa_post_mpr_copywriter = [],
  } = selectedProject

  return (
    <ModalDrawer
      isOpen={isDrawerOpen}
      iconButtonCloseOnClick={() => setIsDrawerOpen(false)}
      scroll={true}
      onRequestClose={() => {
        setIsDrawerOpen(false)
      }}
      headingText={
        <div>
          <Heading size={3}>{selectedProject.project_description}</Heading>
          <Heading size={4}>Project Contacts</Heading>
        </div>
      }
    >
      <div className="hc-pa-normal">
        <GridItem xs={12} className="contacts-padding">
          <h4>Prod Design Captain:</h4>
          {prod_design_captain.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>Video Art Director:</h4>
          {video_art_director.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>Video Copywriter:</h4>
          {video_copywriter.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>Audio Copywriter:</h4>
          {audio_copywriter.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>Dynamic Digital Art Director:</h4>
          {dynamic_digital_art_director.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>Dynamic Digital Copywriter:</h4>
          {dynamic_digital_copywriter.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>Dynamic Digital Video Art Director:</h4>
          {dynamic_video_art_director.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>Dynamic Digital Video Copywriter:</h4>
          {dynamic_video_copywriter.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>Dynamic Digital Audio Copywriter:</h4>
          {dynamic_audio_copywriter.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>DWA Art Director:</h4>
          {dwa_art_director.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>DWA Kickoff Copywriter:</h4>
          {dwa_kickoff_copywriter.join(', ')}
        </GridItem>
        <GridItem xs={12} className="contacts-padding">
          <h4>DWA Post-MPR Copywriter:</h4>
          {dwa_post_mpr_copywriter.join(', ')}
        </GridItem>
      </div>
    </ModalDrawer>
  )
}

export default ProjectContacts
