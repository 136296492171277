import { Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { formatToServer } from '../util/MomentUtil'

let weeklyAdSchema = yup.object().shape({
  digitalPageCount: yup.number().typeError('Page quantity must be a number'),
  eventWeek: yup.string().required('Required'),
  insertPageCount: yup.number().oneOf([0, 4]),
  midweek: yup.boolean(),
  printedPageCount: yup.number().typeError('Page quantity must be a number'),
  projectName: yup.string().required('Required'),
  startDate: yup.string().required(),
  wrapPageCount: yup.number().oneOf([0, 4, 8]),
})

const AddWad = (props) => {
  const { show, setShow, postWad } = props

  const wipeState = () => {
    setShow(false)
  }
  return (
    show && (
      <Formik
        initialValues={{
          digitalPageCount: '',
          eventWeek: '',
          insertPageCount: 0,
          midweek: false,
          printedPageCount: '',
          projectName: '',
          startDate: '',
          wrapPageCount: 0,
        }}
        validationSchema={weeklyAdSchema}
        onSubmit={(values, funcs) => {
          postWad({
            body: {
              deleted: [],
              eventWeek: values.eventWeek,
              insertPageCount: values.insertPageCount,
              midweek: values.midweek,
              postMprCopyWriter: [],
              printedPageCount: values.printedPageCount,
              projectName: values.projectName,
              startDate: formatToServer(values.startDate),
              wrapPageCount: values.wrapPageCount,
            },
          })
          funcs.resetForm()
          wipeState()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          submitCount,
        }) => {
          const handleDialogClose = () => {
            handleReset()
            wipeState()
          }

          const handleMidweekAd = (id, value) => {
            setFieldValue(id, value)
            if (value) {
              setFieldValue('digitalPageCount', 0)
              setFieldValue('wrapPageCount', 0)
              setFieldValue('printedPageCount', 4)
              setFieldValue('insertPageCount', 0)
            } else {
              setFieldValue('printedPageCount', '')
              setFieldValue('digitalPageCount', '')
            }
          }
          return (
            <Modal
              isVisible={show}
              headingText="Create Pagination"
              onRefuse={handleDialogClose}
              className="datepicker_modal"
            >
              <div className="hc-pa-normal">
                <Grid.Container spacing="dense">
                  <Grid.Container spacing="dense">
                    <Grid.Item md={6} xs={12}>
                      <DatePicker
                        required
                        id="startDate"
                        label="Start Date"
                        value={values.startDate}
                        onUpdate={setFieldValue}
                        location="bottom-left-left"
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Form.Field
                        id="projectName"
                        label="Project Name"
                        onChange={handleChange}
                        value={values.projectName}
                        error={
                          errors.projectName &&
                          (submitCount > 0 || touched.projectName)
                        }
                        onBlur={handleBlur}
                        errorText={errors.projectName}
                      />
                    </Grid.Item>
                    <Grid.Item xs={12}>
                      <Form.Field
                        id="eventWeek"
                        label="Event Week"
                        onChange={handleChange}
                        value={values.eventWeek}
                        error={
                          errors.eventWeek &&
                          (submitCount > 0 || touched.eventWeek)
                        }
                        onBlur={handleBlur}
                        errorText={errors.eventWeek}
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Form.Field
                        id="printedPageCount"
                        label="Print Page Quantity"
                        onChange={handleChange}
                        value={values.printedPageCount}
                        disabled={values.midweek}
                        error={
                          errors.printedPageCount &&
                          (submitCount > 0 || touched.printedPageCount)
                        }
                        onBlur={handleBlur}
                        errorText={errors.printedPageCount}
                      />
                    </Grid.Item>
                    <Grid.Item md={6} xs={12}>
                      <Form.Field
                        id="digitalPageCount"
                        label="DWA Page Quantity"
                        onChange={handleChange}
                        disabled={values.midweek}
                        value={values.digitalPageCount}
                        error={
                          errors.digitalPageCount &&
                          (submitCount > 0 || touched.digitalPageCount)
                        }
                        onBlur={handleBlur}
                        errorText={errors.digitalPageCount}
                      />
                    </Grid.Item>
                    <Grid.Item md={4} xs={12}>
                      <Form.Field
                        type="select"
                        id="wrapPageCount"
                        label="Wrap"
                        errorText="Please indicate whether this has a wrap."
                        value={values.wrapPageCount}
                        disabled={values.midweek}
                        options={[
                          { label: 'Yes (4 pg)', value: 4 },
                          { label: 'Yes (8 pg)', value: 8 },
                          { label: 'No', value: 0 },
                        ]}
                        onUpdate={setFieldValue}
                      />
                    </Grid.Item>
                    <Grid.Item md={4} xs={12}>
                      <Form.Field
                        type="select"
                        id="insertPageCount"
                        label="Insert (+4)"
                        errorText="Please state whether this has an insert."
                        value={values.insertPageCount}
                        disabled={values.midweek}
                        options={[
                          { label: 'Yes', value: 4 },
                          { label: 'No', value: 0 },
                        ]}
                        onUpdate={setFieldValue}
                      />
                    </Grid.Item>
                    <Grid.Item md={4} xs={12}>
                      <Form.Field
                        type="select"
                        id="midweek"
                        label="Grocery Midweek Ad"
                        errorText="Please state whether this is a mid week."
                        value={values.midweek}
                        options={[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ]}
                        onUpdate={handleMidweekAd}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Container>
                <CancelSubmitButtons
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                />
              </div>
            </Modal>
          )
        }}
      </Formik>
    )
  )
}
export default AddWad
