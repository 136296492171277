import { Grid, Form } from '@enterprise-ui/canvas-ui-react'

const ChannelCheckboxes = ({
  title,
  onChange,
  disabled,
  audioChannelObj,
  videoChannelObj,
  bannerChannelObj,
  circleChannelObj,
  priorityChannelObj,
  id,
  setChangeDetected,
  isPriorityCategory,
}) => {
  const updateChannel = (checked, channelType) => {
    onChange(checked, 'channel', channelType)
    setChangeDetected(true)
  }

  return (
    <Grid.Container className="category-container-row">
      <Grid.Item className="category-label" xs={2}>
        {title}
      </Grid.Item>
      <Grid.Item className="checkbox-input" xs={10}>
        {isPriorityCategory ? (
          <Form.Field
            data-testid="priority-checkbox"
            className="checkbox-media-channels"
            label="Priority"
            type="checkbox"
            onChange={(e) => updateChannel(e.target.checked, 'Priority')}
            checked={
              priorityChannelObj !== null && priorityChannelObj !== undefined
            }
            disabled={true}
            id={`priority-checkbox-${id}`}
          />
        ) : (
          <>
            <Form.Field
              className="checkbox-media-channels"
              label="Banner"
              type="checkbox"
              onChange={(e) => updateChannel(e.target.checked, 'Banner')}
              checked={
                bannerChannelObj !== null && bannerChannelObj !== undefined
              }
              disabled={true}
              id={`banner-checkbox-${id}`}
            />
            <Form.Field
              data-testid="banner-checkbox"
              className="checkbox-media-channels"
              label="Audio"
              type="checkbox"
              onChange={(e) => updateChannel(e.target.checked, 'Audio')}
              checked={
                audioChannelObj !== null && audioChannelObj !== undefined
              }
              disabled={disabled}
              id={`audio-checkbox-${id}`}
            />
            <Form.Field
              className="checkbox-media-channels"
              label="Video"
              type="checkbox"
              onChange={(e) => updateChannel(e.target.checked, 'Video')}
              checked={
                videoChannelObj !== null && videoChannelObj !== undefined
              }
              disabled={disabled}
              id={`video-checkbox-${id}`}
            />
            <Form.Field
              className="checkbox-media-channels"
              label="Circle"
              type="checkbox"
              onChange={(e) => updateChannel(e.target.checked, 'Circle')}
              checked={
                circleChannelObj !== null && circleChannelObj !== undefined
              }
              disabled={disabled}
              id={`circle-checkbox-${id}`}
            />
          </>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default ChannelCheckboxes
