import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'
import historyMapper from '../../mappers/historyMapper'
import paginationMapper from '../../mappers/paginationMapper'

export default class HistoryApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_history_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.catalog,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getHistoryBatch = async (query) => {
    query.set('key', this.config.catapi.apikey)
    return await this.init_history_api()
      .get(this.config.backend.catalogs.history + query.toString())
      .then((res) => paginationMapper(res.data, historyMapper, 'history'))
      .catch((e) => console.log(e))
  }
  putProductHistory = async (id, historyData) =>
    await this.init_history_api()
      .put(`history/${id}?key=${this.config.catapi.apikey}`, historyData)
      .then((res) => historyMapper(res.data))
      .catch((e) => console.log(e))
}
