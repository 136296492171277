import { Dialog, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { User } from '../util/AuthUtils'

let editItemPatchSchema = yup.object().shape({
  edit_itemPatch_marketString: yup.string(),
  edit_itemPatch_regPriceCallout: yup.bool(),
  edit_itemPatch_regUnitRetail: yup
    .string()
    .matches(/^\d*(?:\.(?!00)\d{2})?$/, {
      message: 'Retail Price in format 99.99',
    }),
})

const EditItemPatch = (props) => {
  const {
    editItemPatch,
    setEditItemPatch,
    putItemPatch,
    putItem,
    setViewItemPatches,
    hasEditPrivileges,
  } = props
  const [isDeleteDialog, setDeleteDialog] = useState(false)
  const user = User()

  const wipeState = () => {
    setEditItemPatch({
      body: {},
      cutId: null,
      itemId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
    setDeleteDialog(false)
  }

  return editItemPatch.body ? (
    <Formik
      enableReinitialize
      validationSchema={editItemPatchSchema}
      initialValues={{
        edit_itemPatch_marketString: editItemPatch.body.marketString || '',
        edit_itemPatch_regPriceCallout: Boolean(
          editItemPatch.body.regPriceCallout
        ),
        edit_itemPatch_regUnitRetail: editItemPatch.body.regUnitRetail || '',
      }}
      onSubmit={(values, funcs) => {
        if (editItemPatch.isItemPatch) {
          putItemPatch({
            body: {
              id: editItemPatch.body.id,
              lastUpdatedTimestamp: editItemPatch.body.lastUpdatedTimestamp,
              marketString: values.edit_itemPatch_marketString,
              regPriceCallout: values.edit_itemPatch_regPriceCallout,
              regUnitRetail: values.edit_itemPatch_regUnitRetail,
            },
            cutId: editItemPatch.cutId,
            itemId: editItemPatch.itemId,
            pageId: editItemPatch.pageId,
            setViewItemPatches: editItemPatch.setViewItemPatches,
            storyId: editItemPatch.storyId,
          })
        } else {
          putItem({
            body: {
              id: editItemPatch.body.id,
              lastUpdatedTimestamp: editItemPatch.body.lastUpdatedTimestamp,
              marketString: values.edit_itemPatch_marketString,
              regPriceCallout: values.edit_itemPatch_regPriceCallout,
              regUnitRetail: values.edit_itemPatch_regUnitRetail,
            },
            cutId: editItemPatch.cutId,
            pageId: editItemPatch.pageId,
            setViewItemPatches: editItemPatch.setViewItemPatches,
            storyId: editItemPatch.storyId,
            user,
          })
        }
        funcs.resetForm()
        wipeState()
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        const onDelete = () => {
          putItem({
            body: {
              id: editItemPatch.itemId,
              itemPatches: [
                {
                  deleted: true,
                  id: editItemPatch.body.id,
                  lastUpdatedTimestamp: editItemPatch.body.lastUpdatedTimestamp,
                },
              ],
              lastUpdatedTimestamp: editItemPatch.itemLastUpdatedTimestamp,
            },
            cutId: editItemPatch.cutId,
            pageId: editItemPatch.pageId,
            setViewItemPatches,
            storyId: editItemPatch.storyId,
            user,
          })
          handleDialogClose()
        }

        return (
          <Modal
            isVisible={editItemPatch.show}
            headingText={'Edit Item Patch'}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <fieldset disabled={!hasEditPrivileges}>
                <Grid.Container
                  align="center"
                  justify="space-between"
                  spacing="dense"
                >
                  <Grid.Item xs={2}>
                    <Form.Field
                      id="edit_itemPatch_regUnitRetail"
                      value={values.edit_itemPatch_regUnitRetail}
                      label="Reg Unit Retail"
                      onChange={handleChange}
                      error={errors.edit_itemPatch_regUnitRetail}
                      errorText={errors.edit_itemPatch_regUnitRetail}
                    />
                  </Grid.Item>
                  <Grid.Item xs={9}>
                    <Form.Field
                      id="edit_itemPatch_regPriceCallout"
                      type="toggle"
                      value={values.edit_itemPatch_regPriceCallout}
                      label="Reg $ callout"
                      onChange={() =>
                        setFieldValue(
                          'edit_itemPatch_regPriceCallout',
                          !values.edit_itemPatch_regPriceCallout,
                          false
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      type="textarea"
                      grow
                      id="edit_itemPatch_marketString"
                      value={values.edit_itemPatch_marketString}
                      label="Markets"
                      onChange={handleChange}
                      error={errors.edit_itemPatch_marketString}
                      errorText={errors.edit_itemPatch_marketString}
                    />
                  </Grid.Item>
                </Grid.Container>
              </fieldset>
              <CancelSubmitButtons
                deleteDisabled={
                  !editItemPatch.isItemPatch || !hasEditPrivileges
                }
                handleDelete={setDeleteDialog}
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!hasEditPrivileges}
              />
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText="Are you sure want to delete this item patch?"
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText="Delete"
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditItemPatch
