import {
  Dialog,
  Form,
  Heading,
  Modal,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import isEqual from 'lodash.isequal'
import { useEffect, useState } from 'react'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import partition from '../util/partition'
import removeDuplicates from '../util/removeDuplicates'

const AddOffer = (props) => {
  const { getOffers, addOffers, setAddOffers } = props

  const [offers, setOffers] = useState([])
  const [alertDialog, setAlertDialog] = useState({
    show: false,
    text: '',
  })

  const removeSomeOffersDialogText =
    'You are removing one or more pictured offers previously linked to this cut. Removing these offers will remove the items linked to the offers. Are you sure you want to do this?'

  const removeAllOffersDialogText =
    'You are removing all pictured offers previously linked to this cut. Replacing these offers will remove all items, props, placeholder items, and cut patches currently linked to this cut. Are you sure you want to do this?'

  const wipeState = () => {
    setOffers([])
    setAlertDialog({ show: false, text: '' })
    setAddOffers({})
  }

  useEffect(() => {
    if (addOffers.divisionId && !addOffers.show) {
      getOffers({
        cutId: addOffers.cutId,
        divisionId: addOffers.divisionId,
        setOffers: setOffers,
        startDate: addOffers.startDate,
        then: () =>
          setAddOffers((cur) => ({
            ...cur,
            show: true,
          })),
      })
    }
  }, [addOffers, getOffers, setAddOffers])
  const getMarketIds = (offer) =>
    removeDuplicates(
      offer?.offerDetails?.map((detail) =>
        detail.markets.map((market) => market.id)
      )
    )

  const [picturedOffers, linerOffers] = partition(
    offers,
    (offer) => offer.pictured
  )

  return (
    <Formik
      enableReinitialize
      initialValues={{
        curTab: 'pictured',
        liner: addOffers?.linerOfferIds || [],
        pictured: addOffers?.offerIds || [],
      }}
      onSubmit={(values, funcs) => {
        addOffers.cleanup(values.pictured, values.liner)
        funcs.resetForm()
        wipeState()
      }}
    >
      {({ values, handleSubmit, handleReset, setFieldValue }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        const isPictured = values.curTab === 'pictured'
        const curMarketIds = values.pictured.length
          ? getMarketIds(
              offers.find((offer) => values.pictured[0] === offer.id)
            )
          : []
        const isOfferDisabled = (offer) =>
          curMarketIds.length && !isEqual(getMarketIds(offer), curMarketIds)
        const tabOffers = isPictured ? picturedOffers : linerOffers
        const [linkedOffers, unlinkedOffers] = partition(
          tabOffers,
          (offer) => offer.linkedToCut
        )

        return (
          <Modal
            isVisible={addOffers.show}
            headingText={'Add Offer'}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <Tabs
                activeTab={values.curTab}
                onTabSelect={(_, tab) => {
                  setFieldValue('curTab', tab.name)
                }}
              >
                <Tabs.Item name="pictured">Pictured Offers</Tabs.Item>
                <Tabs.Item name="liner">Liner Offers</Tabs.Item>
              </Tabs>
              {tabOffers.length ? (
                <div className="hc-pa-normal">
                  {unlinkedOffers.length ? (
                    <div className="hc-pa-none">
                      <Heading size={4}>Not Yet Linked:</Heading>
                      <Form.Field
                        type="checkboxes"
                        id="addUnlinkedOffer"
                        value={isPictured ? values.pictured : values.liner}
                        options={unlinkedOffers
                          .filter((offer) => offer.weeklyAd)
                          .map((offer) => ({
                            disabled: isPictured && isOfferDisabled(offer),
                            key: offer.id,
                            label: offer.name,
                            value: offer.id,
                          }))}
                        onUpdate={(_, selected) => {
                          setFieldValue(values.curTab, selected)
                        }}
                      />
                    </div>
                  ) : null}
                  {linkedOffers.length ? (
                    <div className="hc-pa-none">
                      <Heading size={4}>Already Linked:</Heading>
                      <Form.Field
                        type="checkboxes"
                        id="addLinkedOffer"
                        value={isPictured ? values.pictured : values.liner}
                        options={linkedOffers.map((offer) => ({
                          disabled: isPictured && isOfferDisabled(offer),
                          key: offer.id,
                          label: offer.name,
                          value: offer.id,
                        }))}
                        onUpdate={(_, selected) => {
                          setFieldValue(values.curTab, selected)
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <Heading size={3}>No {values.curTab} offers available</Heading>
              )}
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={() => {
                  const intersection = addOffers?.offerIds?.filter((id) =>
                    values.pictured.includes(id)
                  )
                  if (addOffers.offerIds.length === intersection.length) {
                    handleSubmit()
                  } else if (intersection.length) {
                    setAlertDialog({
                      show: true,
                      text: removeSomeOffersDialogText,
                    })
                  } else {
                    setAlertDialog({
                      show: true,
                      text: removeAllOffersDialogText,
                    })
                  }
                }}
                submitDisabled={
                  !values.pictured.length ||
                  (isEqual(
                    addOffers.offerIds?.sort(),
                    values.pictured.sort()
                  ) &&
                    isEqual(
                      addOffers.linerOfferIds?.sort(),
                      values.liner.sort()
                    ))
                }
              />
            </div>
            <Dialog
              isVisible={alertDialog?.show}
              headingText={alertDialog.text}
              onRefuse={() =>
                setAlertDialog({
                  show: false,
                  text: '',
                })
              }
              refuseButtonText="Cancel"
              onApprove={handleSubmit}
              approveButtonText="Update"
            />
          </Modal>
        )
      }}
    </Formik>
  )
}
export default AddOffer
