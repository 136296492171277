import { sortBy } from 'lodash'

const excludeDivisions = [
  'NON RETAIL',
  'OPEN',
  'LTO',
  'SPECIAL PROJECT',
  'SYSTEMS SERVICES',
  'CHECKLANES',
  'CAFE',
  'STARBUCKS',
  'OPERATIONS',
  'UNIDENTIFIED PURCHASES',
  'OPTICAL',
  'PORTRAIT STUDIO',
  'CLINIC',
  'MPD',
  'AMC',
  'UNIDENTIFIED',
]

const priorityDivisions = [
  'HOME ELECTRONICS',
  'ENTERTAINMENT',
  'MOBILE/SERVICES',
]

export const divisionSelection = (divisions = {}) => {
  let divisionOptions = []
  if (divisions) {
    const divisionList = Object.values(divisions)
    sortDivisionsByName(divisionList)?.forEach((division = {}) => {
      if (priorityDivisions.includes(division.division_name)) {
        divisionOptions.unshift({
          id: division.division_id,
          label: division.division_name,
          value: division.division_id?.toString(),
        })
      } else if (!excludeDivisions.includes(division.division_name)) {
        divisionOptions.push({
          id: division.division_id,
          label: division.division_name,
          value: division.division_id?.toString(),
        })
      }
    })
  }

  //console.log(divisionOptions)
  return divisionOptions
}

export const sortDivisionsByName = (divisions = []) =>
  sortBy(divisions, (a) => a.division_name) ?? []

export const getDivisionName = (divisions = {}, divisionId = '') => {
  if (divisions && divisionId) {
    return divisions[divisionId]?.division_name
  }
  return ''
}
