import {
  Dropdown,
  Button,
  Chip,
  Form,
  Modal,
  Grid,
  ToastProvider,
  ProgressBar,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import {
  faVideoCamera,
  faVolumeUp,
  faEllipsisH,
  faAngleDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextField from '@mui/material/TextField'
import { useAuth } from '@praxis/component-auth'
import { useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import OfferApi from '../../api/creative-hub/OfferApi'
import PublishingApi from '../../api/creative-hub/PublishingApi'
import PublishItemsBucket from '../components/PublishItemsBucket'
import {
  buildUrl,
  buildAutoVersionBody,
  consolidateMediaUnitOffers,
  convertMarketOptions,
  expandMarkets,
  getMarketsNickName,
  isChannelCheck,
  responseErrorCheck,
  offerToMediaUnitXfer,
  returnDateNoTime,
  setOfferMarkets,
  sortMarkets,
  sortVersions,
  adPatchCheck,
} from '../util/helpers'

import CreativeContent from './CreativeContent'
import MarketingDetails from './MarketingDetails'
import Offer from './Offer'

import '../scss/Category.scss'

const MediaUnit = ({
  projectId,
  mediaUnitData = {},
  fetchMediaUnits,
  index,
  categoryTypeId,
  canEdit = false,
  marketOptions,
  categoryOptions,
  setSelectedOffer,
  setIsOfferDrawerOpen,
  setIsMediaUnitDraggable,
  setIsItemImageModalOpen,
  setItemSelected,
  updatedItemImage,
  selectedProject,
  isPriorityCategory,
}) => {
  const { session } = useAuth()
  const api = useMemo(
    () => new PublishingApi(session.userInfo.lanId),
    [session.userInfo.lanId]
  )
  const makeToast = ToastProvider.useToaster()
  const [dropDownVersions, setDropDownVersions] = useState([])
  const mediaUnitId = useParams().mediaUnitId
  const [toggleExpand, setToggleExpand] = useState(
    mediaUnitId == mediaUnitData.id
  )
  const [mediaUnits, setMediaUnits] = useState([mediaUnitData])
  const [mediaUnitArrIndex, setMediaUnitArrIndex] = useState(0)
  const [offerData, setOfferData] = useState(mediaUnitData.offers || [])
  const [isOver, setIsOver] = useState(false)
  const [addedVersion, setAddedVersion] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalHeaderText, setModalHeaderText] = useState()
  const [modalBodyText, setModalBodyText] = useState()
  const [showMediaUnitFlyout, setShowMediaUnitFlyout] = useState(false)
  const [startDate, setStartDate] = useState(mediaUnitData?.start_date)
  const [endDate, setEndDate] = useState(mediaUnitData?.end_date)

  const offerApi = useMemo(() => new OfferApi(), [])

  const {
    name = '',
    landing_url = '',
    eyebrow = '',
    body_copy = '',
    promotion_message = '',
    project_id = '',
    images = [],
    feature_offer = '',
    markets,
    categories,
    notes = '',
    channels,
    order = '',
    boosted_tcins = '',
  } = mediaUnitData

  const [disabled, setDisabled] = useState(true)
  const [creativeImage, setCreativeImage] = useState(images[0]?.external_url)
  const [imageId, setImageId] = useState(images[0]?.id)
  const [destinationUrl, setDestinationUrl] = useState(landing_url)
  const [eyebrowInput, setEyebrowInput] = useState(eyebrow)
  const [boostedTcins, setBoostedTcins] = useState(boosted_tcins)
  const [featureOffer, setFeatureOffer] = useState(feature_offer)
  const [bodyCopyInput, setBodyCopyInput] = useState(body_copy)
  const [nameInput, setNameInput] = useState(name)
  const [promoMessage, setPromoMessage] = useState(promotion_message)
  const [marketsSelected, setMarketsSelected] = useState(null)
  const [changeDetected, setChangeDetected] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [categoriesSelected, setCategoriesSelected] = useState(categories)
  const [notesInput, setNotesInput] = useState(notes)
  const [audioChannelObj, setAudioChannelObj] = useState(
    isChannelCheck(channels, 10)
  )
  const [videoChannelObj, setVideoChannelObj] = useState(
    isChannelCheck(channels, 9)
  )
  const [circleChannelObj, setCircleChannelObj] = useState(
    isChannelCheck(channels, 15)
  )
  const [bannerChannelObj, setBannerChannelObj] = useState(
    isChannelCheck(channels, 8)
  )
  const [priorityChannelObj, setPriorityChannelObj] = useState(
    isChannelCheck(channels, 6)
  )

  const [isOffersEmpty, setIsOffersEmpty] = useState(mediaUnitData.length === 0)

  const [hasFeaturedItems, setHasFeaturedItems] = useState(false)
  const [mediaUnitHeight, setMediaUnitHeight] = useState(0)
  const [editMarkets, setEditMarkets] = useState(false)
  const [editCategories, setEditCategories] = useState(false)

  const ref = useRef(null)

  const marketsToString = (marketArr) => {
    if (!marketArr || !marketArr.length) {
      return ''
    }

    const marketShortName = getMarketsNickName(marketArr, marketOptions)

    const sortedMarkets = sortMarkets(marketArr)

    const marketString = sortedMarkets
      .reduce((acc, item) => {
        if (!item.name) {
          return acc
        }
        acc.push(item.name)
        return acc
      }, [])
      .join(' ')

    return marketShortName !== null
      ? ` | ${marketShortName}`
      : `| ${marketString}`
  }

  useEffect(() => {
    if (!mediaUnits?.length) return

    setDisabled(false)

    return mediaUnits[mediaUnitArrIndex]?.offers?.length
      ? setOfferData(mediaUnits[mediaUnitArrIndex]?.offers)
      : setOfferData([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaUnitArrIndex, mediaUnitData])

  useEffect(() => {
    const handleResize = () => {
      setMediaUnitHeight(ref.current.offsetHeight)
    }
    handleResize()
    window.addEventListener('click', handleResize)

    return () => {
      window.removeEventListener('click', handleResize)
    }
  })

  const handleMediaUnitsAndVersions = (mediaUnitDetails) => {
    const { version_batch_id, versions } = mediaUnitDetails

    if (version_batch_id && versions?.length) {
      const dropdowns = [
        {
          disabled: false,
          label: `Version 1 ${marketsToString(mediaUnitDetails.markets)}`,
          value: 0,
        },
      ]

      const sortedVersions = sortVersions(versions)

      sortedVersions.forEach((version, i) =>
        dropdowns.push({
          disabled: false,
          label: `Version ${i + 2} ${marketsToString(version?.markets)}`,
          value: i + 1,
        })
      )

      const mediaUnitAllVersions = versions.reduce(
        (acc, version) => {
          acc.push(version)
          return acc
        },
        [mediaUnitDetails]
      )
      setMediaUnits(mediaUnitAllVersions)
      setDropDownVersions(dropdowns)
    } else {
      if (dropDownVersions.length) setDropDownVersions([])
      setMediaUnits([mediaUnitDetails])
    }
    setDisabled(false)
    setUpdating(false)
  }

  const autoVersion = async () => {
    setIsModalVisible(false)
    if (offerData.length === 0) {
      setModalHeaderText(`Cannot Auto Generate Versions!`)
      setModalBodyText(`First drag an offer into the Media Unit`)
      setIsModalVisible(true)
    } else if (offerData.length > 1) {
      setModalHeaderText(`Cannot Auto Generate Versions!`)
      setModalBodyText(`More Than one Offer assigned to Media Unit`)
      setIsModalVisible(true)
    } else if (
      offerData[0].price_patches?.length ||
      offerData[0].ad_patches?.length > 1
    ) {
      createVersion()
    } else {
      setModalHeaderText(`Cannot Auto Generate Versions!`)
      setModalBodyText(
        `No Ad Patches or Price Patching found in Offer: ${offerData[0].ivy_offer_id}`
      )
      setIsModalVisible(true)
    }
  }

  const handleDelete = async () => {
    try {
      setUpdating(true)
      const mediaUnitId = mediaUnits[mediaUnitArrIndex]?.id
      await api.deleteMediaUnit(mediaUnitId, categoryTypeId, projectId)
      await fetchMediaUnits()
      setMediaUnitArrIndex(0)
      setUpdating(false)
    } catch (e) {
      console.log('e', e)
      setUpdating(false)
    }
  }

  useEffect(() => {
    if (toggleExpand && addedVersion) {
      setToggleExpand(true)
      setIsMediaUnitDraggable(false)
      setAddedVersion(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedVersion])

  const createVersion = async () => {
    setUpdating(true)
    var data = buildAutoVersionBody(offerData[0], marketOptions, mediaUnitData)
    try {
      const createdVersion = await api.createNewVersion(
        mediaUnits[0].id,
        categoryTypeId,
        data
      )
      setAddedVersion(true)

      if (createdVersion) {
        await fetchMediaUnits()
        return
      }
    } catch (e) {
      console.log('e', e)
      setUpdating(false)
    }
    setUpdating(false)
  }

  const dragCheck = (event) => {
    if (
      event.dataTransfer.types.includes('drag-offer') ||
      event.dataTransfer.types.includes('drag-umbrella')
    ) {
      okayToDrop(event)
    }
  }
  const okayToDrop = (event) => {
    event.preventDefault()
    setIsOver(true)
  }

  const dragLeave = (event) => {
    setIsOver(false)
  }

  const updateChannel = (value, type, channelName) => {
    let state = ''
    let channel = ''
    let channelNum = 0
    let channelObj = {}
    let setObj = {}

    switch (channelName.toLowerCase()) {
      case 'video': {
        channelNum = 9
        setObj = setVideoChannelObj
        break
      }
      case 'audio': {
        channelNum = 10
        setObj = setAudioChannelObj
        break
      }
      case 'circle': {
        channelNum = 15
        setObj = setCircleChannelObj
        break
      }
      case 'banner': {
        channelNum = 8
        setObj = setBannerChannelObj
        break
      }
      case 'priority': {
        channelNum = 6
        setObj = setPriorityChannelObj
        break
      }
      default: {
        channelNum = 0
      }
    }

    if (type === 'state') {
      state = value
      channel = channelNum

      channelObj.state = state
      channelObj.channel_id = channel
    } else if (type === 'channel') {
      if (value) {
        channel = channelNum
        state = `${channelName.toLowerCase()}+ChannelObj`?.state
          ? `${channelName.toLowerCase()}+ChannelObj`.state
          : ''

        channelObj.state = state
        channelObj.channel_id = channel
      } else {
        channelObj = null
      }
    }

    setObj(channelObj)
  }

  const extractDroppedOfferInfo = (sortedOffers) => {
    if (sortedOffers?.length > 0) {
      setDestinationUrl(buildUrl(sortedOffers, mediaUnitData))
      var offerMarkets = setOfferMarkets(sortedOffers)
      var offerDataArray = offerToMediaUnitXfer(sortedOffers)

      offerDataArray.forEach((update) => {
        switch (update) {
          case 'markets':
            setMarketsSelected(
              convertMarketOptions(
                expandMarkets(
                  offerMarkets,
                  sortedOffers[0].location_group_id,
                  marketOptions
                )
              )
            )
            break
          case 'sale_handle':
            setEyebrowInput('Sale')
            break
          default:
            break
        }
      })
    } else {
      setDestinationUrl('')
      setFeatureOffer(feature_offer)
      setMarketsSelected(marketsSelected)
      setEyebrowInput(eyebrowInput)
    }

    sortedOffers.forEach((offer) => {
      if (offer?.circle_offer && offer?.circle_offer === true) {
        setCircleChannelObj({
          channel_id: 15,
          state: '',
        })
        return
      }
    })
  }

  const handleDrop = async (event) => {
    let dragOffers = []
    if (event.dataTransfer.types.includes('drag-offer')) {
      dragOffers = event.dataTransfer.getData('drag-offer')
    } else if (event.dataTransfer.types.includes('drag-umbrella')) {
      dragOffers = event.dataTransfer.getData('drag-umbrella')
      let offersObj = JSON.parse(dragOffers)

      // call and get promo message v1/offer/promoMessage
      await offerApi
        .getOfferPromoMessage(offersObj[0].umbrella_id, projectId)
        .then((res) => {
          if (res.error) throw new Error(res.error)
          setPromoMessage(res.promo_message)
        })
        .catch((e) => {
          console.log('error pulling umbrella project: ' + e)
        })
    }
    setDisabled(false)
    addOffers(dragOffers)
  }

  const addOffers = async (offers) => {
    setUpdating(true)
    let offersObj = JSON.parse(offers)
    let offer_ids = offersObj.map((offer = {}) => offer.id)
    const offerDetails = await offerApi
      .getAllOfferDetailsByUUID(offer_ids)
      .then((res) => {
        if (res.error) throw new Error(res.error)
        return res
      })
      .catch((e) => {
        console.log('error pulling offer details: ' + e)
      })

    const newOffers = consolidateMediaUnitOffers(
      offerDetails,
      offersObj,
      offerData
    )

    let sortOffers = offerData.concat(newOffers)

    sortOffers.sort((a, b) => (a.offer_order > b.offer_order ? 1 : -1))

    setStartDate(returnDateNoTime(sortOffers[0].start_date))
    setEndDate(returnDateNoTime(sortOffers[0].end_date))
    setOfferData(sortOffers)
    extractDroppedOfferInfo(sortOffers)
    setUpdating(false)
    setIsOver(false)
    setChangeDetected(true)
  }

  const removeOffer = (e, offerId) => {
    const afterDeleteOffers = offerData.filter((offer) => offer.id !== offerId)
    mediaUnitData.offers = afterDeleteOffers
    setOfferData(afterDeleteOffers)
    extractDroppedOfferInfo(afterDeleteOffers)
    setChangeDetected(true)
  }

  useEffect(() => {
    if (changeDetected === true) {
      handleMediaUnitSave(false)
      setChangeDetected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDetected])

  // eliminates converting markets multiple times as the media unit is rendering
  useEffect(() => {
    setMarketsSelected(convertMarketOptions(markets))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateMediaUnitIndex = (id, value) => setMediaUnitArrIndex(value)

  const handleMediaUnitUpdate = (field, value) => {
    // only call for update if the value has changed
    if (mediaUnitData[field] !== value) {
      handleMediaUnitSave(false)
    }
  }

  const getMediaUnitDetails = async (afterSaveEdit) => {
    try {
      setUpdating(true)
      const mediaUnitId = mediaUnits[mediaUnitArrIndex]?.id
      const mediaUnitDetails = await api.getMediaUnitDetails(
        mediaUnitId,
        categoryTypeId
      )

      if (responseErrorCheck(mediaUnitDetails) === false) {
        handleMediaUnitsAndVersions(mediaUnitDetails)
        setOfferData(
          mediaUnitDetails.offers.sort((a, b) =>
            a.offer_order > b.offer_order ? 1 : -1
          )
        )

        if (afterSaveEdit) {
          await fetchMediaUnits()
          setUpdating(false)
        } else {
          setUpdating(false)
        }
      } else {
        setUpdating(false)
        makeToast({
          heading: `Error Getting Details:  ${mediaUnitDetails.status}`,
          links: [{}],
          message: `${mediaUnitDetails.guest_facing}`,
          onRefuse: () => {
            console.log('onRefuse called')
          },
          type: 'error',
        })
      }
    } catch (e) {
      console.log(e)
      makeToast({
        heading: `Error Getting Details:  ${e.status}`,
        links: [{}],
        message: `${e.message}`,
        onRefuse: () => {
          console.log('error acknowledged')
        },
        type: 'error',
      })
      setUpdating(false)
    }
  }

  const handleMediaUnitSave = async (fetchData) => {
    try {
      setUpdating(true)
      const mediaUnitId = mediaUnits[mediaUnitArrIndex]?.id
      const projectId = project_id
      const payloadChannels = []
      audioChannelObj &&
        audioChannelObj !== undefined &&
        payloadChannels.push(audioChannelObj)
      videoChannelObj &&
        videoChannelObj !== undefined &&
        payloadChannels.push(videoChannelObj)
      circleChannelObj &&
        circleChannelObj !== undefined &&
        payloadChannels.push(circleChannelObj)
      bannerChannelObj &&
        bannerChannelObj !== undefined &&
        payloadChannels.push(bannerChannelObj)

      const payloadImages = [
        {
          external_url: creativeImage,
          id: imageId || null,
          internal_url: '',
        },
      ]

      const payload = {
        body_copy: bodyCopyInput ? bodyCopyInput : '',
        boosted_tcins: boostedTcins ? boostedTcins : '',
        categories: categoriesSelected ? categoriesSelected : null,
        channels: payloadChannels ? payloadChannels : '',
        creative_image: creativeImage ? creativeImage : '',
        end_date: endDate ? endDate : null,
        eyebrow: eyebrowInput ? eyebrowInput : '',
        feature_offer: featureOffer ? featureOffer : '',
        id: mediaUnitId ? mediaUnitId : '',
        images: payloadImages ? payloadImages : '',
        landing_url: destinationUrl ? destinationUrl : '',
        markets: marketsSelected ? marketsSelected : null,
        name: nameInput ? nameInput : null,
        notes: notesInput ? notesInput : '',
        offers: offerData ? offerData : null,
        order: order ? order : index,
        project_id: projectId ? projectId : '',
        promotion_message: promoMessage ? promoMessage : '',
        start_date: startDate ? startDate : null,
      }

      // this will update the name in case they reorder the media units before fetching the data
      mediaUnitData.name = nameInput

      let updatedMediaUnitList = []

      updatedMediaUnitList.push(payload)

      await api.updateMediaUnits(updatedMediaUnitList, categoryTypeId)

      if (fetchData) {
        await getMediaUnitDetails(true)
        setUpdating(false)
      } else {
        setDisabled(false)
        setUpdating(false)
      }
    } catch (e) {
      resetValues()
      setUpdating(false)
      console.log('e', e)
    }
  }

  const expandMediaUnit = async () => {
    if (!toggleExpand) {
      setToggleExpand(true)
      getMediaUnitDetails(false)
      setShowMediaUnitFlyout(false)
      setIsMediaUnitDraggable(false)

      let nextURL
      nextURL = `/publishing/${projectId}/${categoryTypeId}/${mediaUnitData.id}`
      return window.history.replaceState(null, '', nextURL)
    } else {
      let nextURL
      nextURL = `/publishing/${projectId}/${categoryTypeId}`
      window.history.replaceState(null, '', nextURL)
      setToggleExpand(false)
      setIsMediaUnitDraggable(true)

      if (!mediaUnitData) await fetchMediaUnits()
    }
  }

  const resetValues = async () => {
    if (mediaUnits?.length) {
      const mu = mediaUnits[mediaUnitArrIndex]
      mu.landing_url ? setDestinationUrl(mu.landing_url) : setDestinationUrl('')
      mu.eyebrow ? setEyebrowInput(mu.eyebrow) : setEyebrowInput('')
      mu.feature_offer ? setFeatureOffer(mu.feature_offer) : setFeatureOffer('')
      mu.body_copy ? setBodyCopyInput(mu.body_copy) : setBodyCopyInput('')
      mu.promotion_message
        ? setPromoMessage(mu.promotion_message)
        : setPromoMessage('')
      convertMarketOptions(mu.markets)
        ? setMarketsSelected(convertMarketOptions(mu.markets))
        : setMarketsSelected([])
      mu.categories
        ? setCategoriesSelected(mu.categories)
        : setCategoriesSelected([])
      mu.notes ? setNotesInput(mu.notes) : setNotesInput('')
      if (mu.images[0]?.external_url) {
        setCreativeImage(mu.images[0]?.external_url)
        setImageId(mu.images[0]?.id)
      } else {
        setCreativeImage('')
        setImageId('')
      }
      setOfferData(mu.offers)
    }
  }

  useEffect(() => {
    if (mediaUnitData.offers.length === 0 && offerData.length === 0) {
      setIsOffersEmpty(true)
    } else {
      setIsOffersEmpty(false)
    }

    var hasAdPatches = false

    mediaUnits.forEach((mediaUnit) => {
      mediaUnit.offers?.forEach((offer) => {
        if (offer.ad_patches?.length > 0) {
          hasAdPatches = true
        }
      })
    })

    hasAdPatches = adPatchCheck(offerData)

    setHasFeaturedItems(hasAdPatches)
  }, [mediaUnitData.offers, mediaUnits, offerData])

  return (
    <div className={`media-unit-container`} data-testid="media-unit">
      <Grid.Container>
        <Grid.Item className="media-unit-name" xs={6}>
          <Grid.Container>
            <Grid.Item
              data-testid="media-unit-expand-button"
              onClick={expandMediaUnit}
              xs={1}
            >
              <FontAwesomeIcon
                style={{ fill: 'black' }}
                className={`media-unit-expand-icon ${
                  toggleExpand
                    ? 'rotate-clockwise-45'
                    : 'rotate-counter-clockwise-45'
                }`}
                icon={faAngleDown}
                size="lg"
              />
            </Grid.Item>
            <Grid.Item xs={11} className="media-unit-name-grid-item">
              <Form.Field
                data-testid="media-name-text"
                className="media-unit-name-input"
                value={nameInput}
                onChange={(e) => {
                  setNameInput(e.target.value)
                }}
                onBlur={(e) => {
                  handleMediaUnitUpdate('name', e.target.value)
                }}
                placeholder={`Media Unit ${index + 1}`}
                disabled={!canEdit}
                id={`media-unit-name ${index}`}
                key={`media-unit-name ${index}`}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item className="media-unit-chips" xs={5}>
          {audioChannelObj && audioChannelObj !== undefined ? (
            <Chip
              size="dense"
              className={
                audioChannelObj.state === 'assigned'
                  ? 'audio-chip-assigned'
                  : 'audio-chip-unassigned'
              }
            >
              {
                <div>
                  <FontAwesomeIcon
                    icon={faVolumeUp}
                    size="sm"
                    className="volume-up-icon"
                  />
                  Audio
                </div>
              }
            </Chip>
          ) : null}
          {videoChannelObj && videoChannelObj !== undefined ? (
            <Chip size="dense" className="audio-chip-unassigned">
              {
                <div>
                  <FontAwesomeIcon
                    icon={faVideoCamera}
                    size="sm"
                    className="volume-up-icon"
                  />{' '}
                  Video
                </div>
              }
            </Chip>
          ) : null}
        </Grid.Item>

        <Grid.Item xs={1}>
          <Grid.Container justify="flex-end">
            <Grid.Item className="media-unit-button-container">
              <Dropdown location="bottom-right">
                <Button className="media-unit-button-elipse">
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    size="lg"
                    onClick={() => {}}
                    id="publishing-dropdown"
                    data-testid="media-unit-options-dropdown"
                    data-cy={`publishing-dropdown`}
                  />
                </Button>
                <Dropdown.Menu className="mu-ellipsis-dropdown">
                  {toggleExpand && (
                    <>
                      <Dropdown.MenuItem
                        disabled={!canEdit}
                        onClick={createVersion}
                      >
                        Add Version
                      </Dropdown.MenuItem>
                      <Dropdown.MenuItem
                        data-testid={'auto-version-btn'}
                        disabled={!canEdit}
                        onClick={autoVersion}
                      >
                        Auto Version
                      </Dropdown.MenuItem>
                    </>
                  )}
                  <Dropdown.MenuItem disabled={!canEdit} onClick={handleDelete}>
                    Delete
                  </Dropdown.MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      {updating ? (
        <Grid.Container justify="space-between">
          <Grid.Item xs={12}>
            <ProgressBar indeterminate error />
          </Grid.Item>
        </Grid.Container>
      ) : null}
      <div
        className={showMediaUnitFlyout ? 'media-unit-container-content' : ''}
        ref={ref}
      >
        {toggleExpand && dropDownVersions.length ? (
          <Grid.Container justify="space-between">
            <Grid.Item xs={12}>
              <Input.Select
                id="marketingPrioritySelect"
                options={dropDownVersions}
                onUpdate={updateMediaUnitIndex}
                value={mediaUnitArrIndex}
                defaultValue=""
                data-testid="marketingPriority"
              />
            </Grid.Item>
          </Grid.Container>
        ) : null}
        {toggleExpand ? (
          <div
            data-testid={'media-unit-offer-bucket'}
            className={'publishing-drag ' + (isOver && 'insideStyle')}
            onDragEnter={(event) => dragCheck(event)}
            onDragOver={(event) => dragCheck(event)}
            onDragLeave={dragLeave}
            onDrop={(event) => handleDrop(event, 1)}
          >
            <Grid.Container className="media-unit-text" justify="space-between">
              {isOffersEmpty ? (
                <Grid.Item xs={12} className="drag-text" justify="center">
                  Drag and drop Offers or Umbrellas here
                </Grid.Item>
              ) : null}

              <Grid.Item xs={12}>
                {!isOffersEmpty && hasFeaturedItems ? (
                  <div className="linked-offers-header">
                    <div className="linked-offers-text">Linked offers</div>
                    <Button
                      data-testid={'show-all-items'}
                      className="publishing-show-all-items-button"
                      onClick={() => {
                        setSelectedOffer(offerData)
                        setShowMediaUnitFlyout(!showMediaUnitFlyout)
                      }}
                    >
                      {showMediaUnitFlyout
                        ? 'Hide All Featured Items'
                        : 'Show All Featured Items'}
                    </Button>
                  </div>
                ) : null}
                <div className="offer-bg">
                  {offerData?.length
                    ? offerData.map((offer, i) => (
                        <div key={i}>
                          <Offer
                            offer={offer}
                            removeOffer={removeOffer}
                            setSelectedOffer={setSelectedOffer}
                            setIsOfferDrawerOpen={setIsOfferDrawerOpen}
                            selectedProject={selectedProject}
                          />
                        </div>
                      ))
                    : null}
                </div>
              </Grid.Item>
              {promoMessage ? (
                <Grid.Item xs={12} className="promo-message-umbrella">
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    id="promoMessage"
                    name="promoMessage"
                    label="Promo Message from Umbrella"
                    value={promoMessage}
                    InputProps={{
                      readOnly: true,
                      style: { fontSize: 14 },
                    }}
                  />
                </Grid.Item>
              ) : null}
            </Grid.Container>
          </div>
        ) : null}
        {toggleExpand ? (
          <>
            <MarketingDetails
              setEditMarkets={setEditMarkets}
              editMarkets={editMarkets}
              setEditCategories={setEditCategories}
              editCategories={editCategories}
              mediaUnitData={mediaUnits[mediaUnitArrIndex]}
              marketOptions={marketOptions}
              categoryOptions={categoryOptions}
              disabled={disabled}
              destinationUrl={destinationUrl}
              setDestinationUrl={setDestinationUrl}
              marketsSelected={marketsSelected}
              setMarketsSelected={setMarketsSelected}
              notesInput={notesInput}
              setNotesInput={setNotesInput}
              categoriesSelected={categoriesSelected}
              setCategoriesSelected={setCategoriesSelected}
              audioChannelObj={audioChannelObj}
              videoChannelObj={videoChannelObj}
              circleChannelObj={circleChannelObj}
              bannerChannelObj={bannerChannelObj}
              priorityChannelObj={priorityChannelObj}
              updateChannel={updateChannel}
              setChangeDetected={setChangeDetected}
              resetValues={resetValues}
              isPriorityCategory={isPriorityCategory}
            />
            <CreativeContent
              updateChannel={updateChannel}
              mediaUnitData={mediaUnits[mediaUnitArrIndex]}
              disabled={disabled}
              eyebrowInput={eyebrowInput}
              setEyebrowInput={setEyebrowInput}
              featureOffer={featureOffer}
              setFeatureOffer={setFeatureOffer}
              bodyCopyInput={bodyCopyInput}
              setBodyCopyInput={setBodyCopyInput}
              creativeImage={creativeImage}
              setCreativeImage={setCreativeImage}
              audioChannelObj={audioChannelObj}
              setChangeDetected={setChangeDetected}
            />
          </>
        ) : null}
        {isModalVisible ? (
          <div data-testid="media-warning-modal">
            <Modal
              headingText={modalHeaderText}
              onRefuse={() => setIsModalVisible(false)}
              isVisible={isModalVisible}
            >
              <Grid.Container>
                <Grid.Item>
                  <p className="modalPyramidMessage">{modalBodyText}</p>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button
                    data-testid={'cancel-modal-btn'}
                    type="secondary"
                    onClick={() => {
                      setIsModalVisible(false)
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    data-testid="add-version-manually-btn"
                    type="primary"
                    onClick={() => {
                      createVersion()
                      setIsModalVisible(false)
                    }}
                  >
                    Add Version Manually
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Modal>
          </div>
        ) : null}
      </div>
      {showMediaUnitFlyout && toggleExpand && (
        <div>
          <div className="media-unit-row-container">
            <div className="media-unit-item-title"> All Featured Items</div>
            <div className="close-item-flyout">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                  setShowMediaUnitFlyout(false)
                }}
                size="lg"
              />
            </div>
          </div>
          <div
            className="media-unit-container-flyout"
            data-testid="media-unit-flyout"
            style={{
              height: mediaUnitHeight - 90,
            }}
          >
            <PublishItemsBucket
              selectedOffer={offerData}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setItemSelected={setItemSelected}
              updatedItemImage={updatedItemImage}
              boostedTcins={boostedTcins}
              setBoostedTcins={setBoostedTcins}
              showPriority={true}
              canEdit={canEdit}
              setChangeDetected={setChangeDetected}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default MediaUnit
