import { Modal, Grid, Form, Chip } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import * as yup from 'yup'

import ProductApi from '../api/pop-catalog/ProductApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { CategoryColorMap } from '../util/CategoryColorMap'
import { CATEGORY_LIST } from '../util/CategoryColorMap'
import { formatToServer, formatToUi } from '../util/MomentUtil'
import '../scss/modal_size.scss'
import ReplaceFirst from '../util/ReplaceFirst'

let productSchema = yup.object().shape({
  category: yup.object().required('Required'),
  dpci: yup.string().matches(/^\d{3}-\d{2}-\d{4}$/, {
    message: 'DPCI in format 000-00-0000',
  }),
  name: yup.string().required('Required'),
})

const EditImageSampleProduct = (props) => {
  const { editProduct, setEditProduct, setProducts } = props
  const wipeState = () => {
    setEditProduct({ body: {}, show: false })
  }
  const productApi = new ProductApi()
  const product = editProduct.body
  const formCategory = product?.category
    ? {
        color: CategoryColorMap[product?.category],
        id: product?.category,
        label: product?.category,
        value: product?.category,
      }
    : null
  return (
    product && (
      <Formik
        enableReinitialize
        initialValues={{
          art_director_request: product.art_director_request || false,
          category: formCategory || '',
          dpci: product.dpci || '',
          fig_model: product.fig_model || false,
          image_approved: product.image_approved || false,
          image_sample_collected: product.image_sample_collected || -1,
          name: product.name || '',
          page_ordering:
            product.page_ordering?.length > 0
              ? product.page_ordering
                  .map((element) => element + 1)
                  .sort()
                  .join(' & ')
              : 'TBD',
          sample_id: product.sample_id || '',
          sample_status: product.sample_status,
          sample_submitted: product.sample_submitted || false,
          sample_turn_in_date: formatToUi(product?.sample_turn_in_date) || '',
          vendor_contact_email: product.vendor_contact_email || '',
          vendor_contact_name: product.vendor_contact_name || '',
        }}
        validationSchema={productSchema}
        onSubmit={(values, funcs) => {
          productApi
            .putProduct({
              ...values,
              category: values?.category?.value,
              id: product.id,
              last_updated_timestamp: product.last_updated_timestamp,
              sample_turn_in_date: formatToServer(values?.sample_turn_in_date),
            })
            .then((thisProduct) =>
              setProducts((products) => {
                ReplaceFirst(products, product.id, thisProduct)
                products = products.filter((product) => !product.deleted)
                return products
              })
            )
          funcs.resetForm()
          wipeState()
        }}
      >
        {({
          values,
          handleSubmit,
          handleReset,
          handleChange,
          handleBlur,
          touched,
          errors,
          setFieldValue,
        }) => {
          const handleDialogClose = () => {
            handleReset()
            wipeState()
          }

          return (
            <Modal
              isVisible={editProduct.show}
              headingText={'Edit Product'}
              onRefuse={handleDialogClose}
              className="edit_product_modal"
            >
              <div className="hc-pa-normal hc-pt-dense">
                <Grid.Container spacing="dense">
                  <Grid.Item xs={3}>
                    <Form.Field
                      disabled={true}
                      id="dpci"
                      label="DPCI"
                      value={values.dpci}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.dpci && errors.dpci}
                      errorText={errors.dpci}
                    />
                  </Grid.Item>
                  <Grid.Item xs={2}>
                    <Autocomplete
                      disabled={true}
                      id="category"
                      chipHeight="dense"
                      label="Category"
                      onUpdate={(_, value) => setFieldValue('category', value)}
                      options={CATEGORY_LIST}
                      error={errors.category}
                      errorText={errors.category}
                      value={values.category}
                      renderChip={(cat, onRequestDelete) => (
                        <Chip
                          size="dense"
                          style={{
                            backgroundColor: cat.color,
                            border: '0px',
                            color: 'white',
                          }}
                          onRequestDelete={onRequestDelete}
                        >
                          {cat.value}
                        </Chip>
                      )}
                    />
                  </Grid.Item>
                  <Grid.Item xs={2}>
                    <Form.Field
                      disabled
                      id="page_ordering"
                      label="Page"
                      value={values.page_ordering}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.page_ordering && errors.page_ordering}
                      errorText={errors.page_ordering}
                    />
                  </Grid.Item>
                  <Grid.Item xs={5}>
                    <Form.Field
                      disabled={true}
                      id="name"
                      label="Product Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && errors.name}
                      errorText={errors.name}
                    />
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="sample_id"
                      label="Sample ID"
                      value={values.sample_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="sample_status"
                      type="select"
                      label="Sample Status"
                      value={values.sample_status}
                      onUpdate={setFieldValue}
                      onBlur={handleBlur}
                      options={[
                        { label: 'Inventory', value: 'inventory' },
                        { label: 'Turn In', value: 'turn-in' },
                        { label: 'Missing', value: 'missing' },
                      ]}
                    />
                  </Grid.Item>

                  <Grid.Item xs={3}>
                    <DatePicker
                      error={
                        errors.sample_turn_in_date &&
                        touched.sample_turn_in_date
                      }
                      errorText="Required"
                      id="sample_turn_in_date"
                      label="Sample Turn In Date"
                      value={values.sample_turn_in_date}
                      onUpdate={setFieldValue}
                      location="bottom-left-left"
                    />
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="sample_submitted"
                      type="toggle"
                      value={values.sample_submitted}
                      label="Sample Submitted"
                      onChange={() =>
                        setFieldValue(
                          'sample_submitted',
                          !values.sample_submitted
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="fig_model"
                      type="toggle"
                      value={values.fig_model}
                      label="Fig/Model"
                      onChange={() =>
                        setFieldValue('fig_model', !values.fig_model)
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="image_approved"
                      type="toggle"
                      value={values.image_approved}
                      label="Image approved"
                      onChange={() =>
                        setFieldValue('image_approved', !values.image_approved)
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="art_director_request"
                      type="toggle"
                      value={values.art_director_request}
                      label="Shooting"
                      onChange={() =>
                        setFieldValue(
                          'art_director_request',
                          !values.art_director_request
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      type="select"
                      id="image_sample_collected"
                      label="Image sample collected"
                      value={values.image_sample_collected}
                      options={[
                        { label: '', value: '-1' },
                        { label: 'No', value: '0' },
                        { label: 'Yes', value: '1' },
                        { label: 'Canceled', value: '2' },
                      ]}
                      onUpdate={setFieldValue}
                    />
                  </Grid.Item>
                </Grid.Container>
                <CancelSubmitButtons
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                  canDelete={false}
                />
                <p
                  className="hc-ta-center hc-clr-grey03 hc-pb-dense"
                  style={{ fontSize: 12 }}
                >
                  {`Vendor: ${product.vendor_contact_name} (${product.vendor_contact_email})`}
                </p>
              </div>
            </Modal>
          )
        }}
      </Formik>
    )
  )
}
export default EditImageSampleProduct
