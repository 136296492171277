import { Grid, Modal, Spinner } from '@enterprise-ui/canvas-ui-react'

const LoadingSpinner = (props) => (
  <Modal isVisible={props.show} className="loading-modal" onRefuse={() => {}}>
    <Grid.Container justify="center" spacing="expanded">
      <Grid.Item>
        <Spinner />
      </Grid.Item>
    </Grid.Container>
  </Modal>
)
export default LoadingSpinner
