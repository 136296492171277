import { Card, Grid, Layout } from '@enterprise-ui/canvas-ui-react'

import SectionHeader from './SectionHeader'

const ErrorContent = (props) => {
  const { error } = props
  return (
    <>
      <SectionHeader pageHeading="Error" />
      <Layout.Body data-testid="layoutWithErrorContent">
        <Card>
          <div className="hc-pa-dense">
            <Grid.Container>
              <Grid.Item>
                <p>Your error content: {error.message}</p>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Card>
      </Layout.Body>
      <Layout.Rail data-testid="layoutWithErrorRailContent">
        <Card>
          <div className="hc-pa-dense">
            <Grid.Container>
              <Grid.Item>
                <p>Your help content</p>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Card>
      </Layout.Rail>
    </>
  )
}
export default ErrorContent
