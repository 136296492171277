import { Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

const EditSampleProp = (props) => {
  const {
    setEditSampleProp,
    putSampleProp,
    editSampleProp: { show, body: editSampleProp },
  } = props

  const wipeState = () => {
    setEditSampleProp({
      body: {},
      show: false,
    })
  }

  return editSampleProp ? (
    <Formik
      enableReinitialize
      initialValues={{
        edit_sampleProp_notes: editSampleProp.notes || '',
        edit_sampleProp_samplePulled:
          editSampleProp.samplePulled || editSampleProp.samplePulled === 0
            ? editSampleProp.samplePulled
            : -1,
      }}
      onSubmit={(values, funcs) => {
        putSampleProp({
          body: {
            id: editSampleProp.id,
            lastUpdatedTimestamp: editSampleProp.lastUpdatedTimestamp,
            notes: values.edit_sampleProp_notes,
            samplePulled: values.edit_sampleProp_samplePulled,
          },
        })
        wipeState()
        funcs.resetForm()
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={show}
            headingText={`Edit ${editSampleProp.description}`}
            onRefuse={wipeState}
          >
            <div className="hc-pa-normal">
              <Grid.Container align="center" spacing="dense">
                <Grid.Item xs={6}>
                  <Form.Field
                    type="select"
                    id="edit_sampleProp_samplePulled"
                    label="Sample/Image Collected"
                    value={values.edit_sampleProp_samplePulled}
                    options={[
                      { label: '', value: -1 },
                      { label: 'No', value: 0 },
                      { label: 'Yes', value: 1 },
                      { label: 'Canceled', value: 2 },
                    ]}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    id="edit_sampleProp_notes"
                    value={values.edit_sampleProp_notes}
                    label="Notes"
                    onChange={handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditSampleProp
