import {
  Grid,
  Card,
  Button,
  ProgressBar,
  Form,
  Heading,
  Timeline,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState, Fragment, useMemo, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ChangeMgmtApi from '../../api/creative-hub/ChangeMgmtApi'
import ChannelApi from '../../api/creative-hub/ChannelApi'
import MerchandiseApi from '../../api/creative-hub/MerchandiseApi'
import ProjectApi from '../../api/creative-hub/ProjectApi'
import CustomTimelineChangeHistory from '../../components/CustomTimelineChangeHistory'
import {
  BreadcrumbContext,
  CanEditContext,
  EffectiveRoleContext,
} from '../../context/Context'
import {
  CHANGE_SUMMARY_PAGE_SIZE,
  createProjectsIdsArray,
  fieldOptions,
  formatProjectOptions,
  isChangeSummaryFirstPage,
  isChangeSummaryLastPage,
  getSelectedChannels,
  getSelectedDivisions,
  makeChangeSummaryPayload,
  pyramidOptions,
} from '../../creativeHubGlobal/util/ChangeSummaryUtil'
import { divisionSelection } from '../../creativeHubGlobal/util/MerchandiseUtil'
import { canEditChangeSummary } from '../../util/CheckRole'
import {
  handleFireflyInit,
  handleFireflyFailure,
  handleFireflySuccess,
} from '../../util/FireflyHelper'
import {
  dateFormatForHistoryViewFilter,
  formatToUi,
} from '../../util/MomentUtil'

import '../../scss/ProductHistory.scss'

const reviewedOptions = [
  { id: 1, label: 'Reviewed', value: 'true' },
  { id: 2, label: 'Not Reviewed', value: 'false' },
]

const ChangeSummary = function ({ session = {} }) {
  const [canEdit, setCanEdit] = useState(false)
  const { effectiveRole } = useContext(EffectiveRoleContext)

  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [isLoading, setLoading] = useState(false)

  const [divisions, setDivisions] = useState([])
  const displayDivisions = divisionSelection(divisions)
  const changeMgmtApi = useMemo(() => new ChangeMgmtApi(), [])
  const channelApi = new ChannelApi()
  const merchandiseApi = useMemo(() => new MerchandiseApi(), [])
  const projectApi = useMemo(() => new ProjectApi(), [])
  // eslint-disable-next-line
  const [channels, setChannels] = useState([])
  const [channelOptions, setChannelOptions] = useState([])
  const [currentProjectIds, setCurrentProjectIds] = useState([])
  const [offerAuditData, setOfferAuditData] = useState()
  const [onFirstPage, setOnFirstPage] = useState(true)
  const [onLastPage, setOnLastPage] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [projectSelectValues, setProjectSelectValues] = useState([])
  // eslint-disable-next-line
  const [pastProject, setPastProject] = useState(false)
  // eslint-disable-next-line
  const [selectedChannels, setSelectedChannels] = useState()
  // eslint-disable-next-line
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const [originalListOfCurrentProjectIds, setOriginalListOfCurrentProjectIds] =
    useState([])
  const viewName = 'Change Summary'

  useEffect(() => {
    setCanEdit(effectiveRole.some((role) => canEditChangeSummary(role)))
  }, [effectiveRole, setCanEdit])

  //Loading chage summary data and getting channels
  useEffect(() => {
    handleFireflyInit('pageload', viewName, 'success', session)
    projectApi
      .findProjects({ past_project: pastProject, project_description: '' })
      .then((data) => {
        const curProjectIds = createProjectsIdsArray(data.projects)
        setProjectSelectValues(formatProjectOptions(data.projects))
        setOriginalListOfCurrentProjectIds(curProjectIds)
        setCurrentProjectIds(curProjectIds)

        fetchChangeSummary({ curProjectIds, query })
        handleFireflySuccess('pageload', viewName, 'success', session)
      })
      .catch((e) => {
        console.log(e)
        handleFireflyFailure('pageload', viewName, 'error', session)
      })
    channelApi.getChannels().then((response) => {
      let chnlOpts = []
      if (response && channelOptions.length === 0) {
        response.forEach((channel = {}) => {
          chnlOpts.push({
            id: channel.channel_id,
            label: channel.channel_name,
            value: channel.channel_id,
          })
        })
        setChannelOptions(chnlOpts)
      }
      setChannels(response)
    })
    merchandiseApi.getDivisions().then((response) => setDivisions(response))
    // eslint-disable-next-line
  }, [pastProject])

  const fetchChangeSummary = ({
    channels,
    changePage,
    curProjectIds,
    query,
  }) => {
    setOfferAuditData([])
    const payload = makeChangeSummaryPayload({
      CHANGE_SUMMARY_PAGE_SIZE,
      changePage,
      channels,
      curProjectIds,
      pageNumber,
      query,
    })
    changeMgmtApi.getChangeSummary(payload).then((res) => {
      setOfferAuditData(res.data)
      setOnFirstPage(isChangeSummaryFirstPage(res.page_num))
      setPageNumber(res.page_num)
      setOnLastPage(
        isChangeSummaryLastPage({
          pageNum: res.page_num,
          totalRecords: res.total,
        })
      )
    })
  }

  const handleRequest = (query, changePage) => {
    fetchChangeSummary({
      changePage,
      channels,
      curProjectIds: currentProjectIds,
      query,
    })
    navigate(`/v1/changeSummary?${query.toString()}`)
  }

  const handleClearFilters = () => {
    fetchChangeSummary({ curProjectIds: originalListOfCurrentProjectIds })
    setCurrentProjectIds(originalListOfCurrentProjectIds)
    setPageNumber(1)
    navigate(`/v1/changeSummary`)
  }

  const handleSubmit = (values = {}) => {
    !isEmpty(values.associated_buyer)
      ? query.set('associated_buyer', values.associated_buyer)
      : query.delete('associated_buyer')

    !isEmpty(values.field_name)
      ? query.set('field_name', values.field_name)
      : query.delete('field_name')

    !isEmpty(values.pyramid_name)
      ? query.set('pyramid_name', values.pyramid_name.value)
      : query.delete('pyramid_name')

    !isEmpty(values.marketing_channels)
      ? query.set(
          'marketing_channels',
          values.marketing_channels.map((option) => option.id).join(',')
        )
      : query.delete('marketing_channels')

    !isEmpty(values.story_name)
      ? query.set('story_name', values.story_name)
      : query.delete('story_name')

    !isEmpty(values.change_date)
      ? query.set(
          'change_date',
          dateFormatForHistoryViewFilter(values?.change_date)
        )
      : query.delete('change_date')

    !isEmpty(values.divisions)
      ? query.set(
          'divisions',
          values?.divisions.map((option) => option.id).join(',')
        )
      : query.delete('divisions')

    !isEmpty(values.offer_name)
      ? query.set('offer_name', values.offer_name)
      : query.delete('offer_name')

    !isEmpty(values.project_id)
      ? query.set('project_id', values.project_id.value)
      : query.delete('project_id')

    !isEmpty(values.changed_by)
      ? query.set('changed_by', values.changed_by)
      : query.delete('changed_by')

    !isEmpty(values.reviewed)
      ? query.set('reviewed', values.reviewed.value)
      : query.delete('reviewed')

    navigate(`/v1/changeSummary?${query.toString()}`)
    handleRequest(query)
  }

  const reviewedIntialVal = reviewedOptions.find(
    (app) => app.value === query.get('reviewed')
  )

  const handlePastProject = () => {
    setPastProject(!pastProject)
  }

  return offerAuditData ? (
    <Fragment>
      {isLoading ? (
        <div className="hc-pa-normal">
          <ProgressBar indeterminate />
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            associated_buyer: query.get('associated_buyer')
              ? query.get('associated_buyer')
              : '',

            change_date: query.get('change_date')
              ? formatToUi(query.get('change_date'))
              : '',

            changed_by: query.get('changed_by') ? query.get('changed_by') : '',
            divisions: query.get('divisions')
              ? getSelectedDivisions(
                  query.get('divisions').split(','),
                  displayDivisions
                )
              : '',

            field_name: query.get('field_name') ? query.get('field_name') : '',

            marketing_channels: query.get('marketing_channels')
              ? getSelectedChannels(
                  query.get('marketing_channels').split(','),
                  channelOptions
                )
              : '',

            offer_name: query.get('offer_name') ? query.get('offer_name') : '',
            project_id: query.get('project_id')
              ? projectSelectValues.find(
                  (opt) => opt.value === query.get('project_id')
                )
              : '',
            pyramid_name: query.get('pyramid_name')
              ? pyramidOptions.find(
                  (opt) => opt.value === query.get('pyramid_name')
                )
              : '',
            query: query.toString(),

            reviewed: reviewedIntialVal ? reviewedIntialVal : '',
            story_name: query.get('story_name') ? query.get('story_name') : '',
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
            errors,
            touched,
          }) => (
            <>
              <div className="hc-pa-normal hc-pa-none">
                <Grid.Container
                  padding="dense"
                  spacing="none"
                  justify="flex-end"
                  align="flex-end"
                >
                  <Grid.Item>
                    <Button
                      disabled={onFirstPage}
                      onClick={() => {
                        handleRequest(query, -1)
                      }}
                    >
                      {`Prev ${CHANGE_SUMMARY_PAGE_SIZE} Changes`}
                    </Button>
                    <Button
                      disabled={onLastPage}
                      onClick={() => {
                        handleRequest(query, 1)
                      }}
                    >
                      {`Next ${CHANGE_SUMMARY_PAGE_SIZE} Changes`}
                    </Button>
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Card>
                      <div className="hc-pa-normal">
                        <Grid.Container
                          padding="dense"
                          spacing="none"
                          justify="flex-end"
                          align="flex-end"
                        >
                          <Grid.Item xs={12} className="hc-ta-center">
                            <Heading>Change Summary</Heading>
                          </Grid.Item>
                          <Grid.Item xs={12} className="hc-ta-center">
                            Default View with no filters shows all changes for
                            all upcoming projects
                          </Grid.Item>
                          <Grid.Item xs={12}>
                            <div className="hc-pa-normal">
                              <Grid.Container
                                align="center"
                                justify="center"
                                padding="dense"
                                spacing="dense"
                              >
                                <Grid.Item
                                  xs={4}
                                  className="autocomplete-alignment"
                                >
                                  <Autocomplete
                                    id="project_id"
                                    data-cy="selectProject"
                                    value={values.project_id}
                                    placeholder="< Projects >"
                                    filter={Autocomplete.filters.contains}
                                    onUpdate={(id, value) => {
                                      if (!value) {
                                        setFieldValue('project_id', '')
                                      } else {
                                        setFieldValue(id, value)
                                        setCurrentProjectIds([value.value])
                                      }
                                    }}
                                    options={projectSelectValues}
                                  />

                                  {/* <Autocomplete
                                    className="pyramid-name-summary"
                                    id="pyramid_name"
                                    placeholder="Pyramid"
                                    onUpdate={(id, value) => {
                                      setFieldValue(id, value)
                                    }}
                                    options={pyramidOptions}
                                    value={values.pyramid_name}
                                    data-testid="pyramidFilter"
                                  /> */}
                                </Grid.Item>

                                <Grid.Item xs={4}>
                                  <Form.Field
                                    id="story_name"
                                    placeholder="Marketing Story Name"
                                    value={values.story_name}
                                    onChange={handleChange}
                                  />
                                </Grid.Item>
                                <Grid.Item
                                  xs={4}
                                  className="autocomplete-alignment"
                                >
                                  <Form.Field
                                    type="select"
                                    id="field_name"
                                    onUpdate={(id, value) => {
                                      setFieldValue(id, value)
                                    }}
                                    options={fieldOptions}
                                    value={values.field_name || ''}
                                    defaultValue=""
                                  />
                                </Grid.Item>
                                <Grid.Item
                                  className="pyramid-and-division-container"
                                  xs={4}
                                >
                                  <Autocomplete
                                    style={{ marginRight: '3px' }}
                                    id="pyramid_name"
                                    placeholder="Pyramid"
                                    onUpdate={(id, value) => {
                                      setFieldValue(id, value)
                                    }}
                                    options={pyramidOptions}
                                    value={values.pyramid_name}
                                    data-testid="pyramidFilter"
                                  />
                                  <Autocomplete
                                    id="divisions"
                                    placeholder="Divisions"
                                    multiselect
                                    grow
                                    onUpdate={(id, value) => {
                                      setFieldValue(id, value)
                                    }}
                                    value={values.divisions || []}
                                    options={displayDivisions}
                                  />
                                </Grid.Item>

                                <Grid.Item xs={4}>
                                  <Form.Field
                                    id="offer_name"
                                    placeholder="Offer Name"
                                    value={values.offer_name}
                                    onChange={handleChange}
                                  />
                                </Grid.Item>
                                <Grid.Item xs={4}>
                                  <Form.Field
                                    id="associated_buyer"
                                    value={values.associated_buyer}
                                    placeholder="Associate Buyer Name"
                                    onChange={handleChange}
                                  />
                                </Grid.Item>

                                <Grid.Item
                                  xs={4}
                                  className="autocomplete-alignment-channel"
                                >
                                  <Autocomplete
                                    allowCustomInputValue={true}
                                    id="marketing_channels"
                                    multiselect
                                    chipHeight="dense"
                                    value={values.marketing_channels}
                                    onUpdate={(id, value) => {
                                      setFieldValue(id, value)
                                      setSelectedChannels(value)
                                    }}
                                    options={channelOptions}
                                    placeholder="Choose Channel"
                                    data-testid="marketingChannelSelect"
                                  />
                                </Grid.Item>
                                <Grid.Item
                                  xs={2}
                                  className="autocomplete-alignment"
                                >
                                  <Form.Field
                                    id="changed_by"
                                    value={values.changed_by}
                                    placeholder="Changed By"
                                    onChange={handleChange}
                                  />
                                </Grid.Item>

                                <Grid.Item
                                  xs={2}
                                  className="autocomplete-alignment"
                                >
                                  <DatePicker
                                    error={errors.date && touched.date}
                                    id="change_date"
                                    label=""
                                    aria-label="select date"
                                    placeholder="Date"
                                    className="datepicker-icon"
                                    value={values.change_date}
                                    onUpdate={(id, value) => {
                                      setFieldValue(id, value)
                                    }}
                                    location="bottom-left"
                                  />
                                </Grid.Item>
                                <Grid.Item xs={2}>
                                  {
                                    <Autocomplete
                                      id="reviewed"
                                      onUpdate={setFieldValue}
                                      chipHeight="normal"
                                      value={values.reviewed}
                                      options={reviewedOptions}
                                      placeholder="Review Status"
                                    />
                                  }
                                </Grid.Item>
                                <Grid.Item
                                  xs={2}
                                  className="autocomplete-alignment"
                                >
                                  <Button type="primary" onClick={handleSubmit}>
                                    Apply Filters
                                  </Button>
                                </Grid.Item>
                                <Grid.Item
                                  xs={2}
                                  className="autocomplete-alignment"
                                >
                                  <Button
                                    type="secondary"
                                    onClick={() => {
                                      handleReset()
                                      handleClearFilters()
                                    }}
                                  >
                                    Clear Filters
                                  </Button>
                                </Grid.Item>
                                <Grid.Item
                                  xs={2}
                                  className="autocomplete-alignment"
                                >
                                  <Button
                                    id="past-project"
                                    className="secondary"
                                    onClick={handlePastProject}
                                  >
                                    {!pastProject ? (
                                      <span> View Past Projects </span>
                                    ) : (
                                      <span> View Upcoming Projects </span>
                                    )}
                                  </Button>
                                </Grid.Item>
                              </Grid.Container>
                            </div>
                          </Grid.Item>
                        </Grid.Container>
                      </div>
                    </Card>
                  </Grid.Item>
                  <CanEditContext.Provider value={{ canEdit }}>
                    <Grid.Item xs={12}>
                      {offerAuditData.length ? (
                        <Card
                          elevation={2}
                          corners="top"
                          className="hc-pa-dense"
                          xs={12}
                        >
                          <Timeline
                            style={{
                              paddingLeft: '20px',
                              paddingRight: '20px',
                            }}
                          >
                            {offerAuditData.map((history, index) => (
                              <CustomTimelineChangeHistory
                                history={history}
                                index={index}
                                uniqueKey={index}
                                key={index}
                                channels={channels}
                                divisions={divisions}
                                fetchChangeSummary={fetchChangeSummary}
                                makeChangeSummaryPayload={
                                  makeChangeSummaryPayload
                                }
                                curProjectIds={currentProjectIds}
                                query={query}
                                pageNumber={pageNumber}
                              />
                            ))}
                          </Timeline>
                        </Card>
                      ) : null}
                    </Grid.Item>
                  </CanEditContext.Provider>
                  <Grid.Item>
                    <Button
                      disabled={onFirstPage}
                      onClick={() => {
                        handleRequest(query, -1)
                      }}
                    >
                      {`Prev ${CHANGE_SUMMARY_PAGE_SIZE} Changes`}
                    </Button>
                    <Button
                      disabled={onLastPage}
                      onClick={() => {
                        handleRequest(query, 1)
                      }}
                    >
                      {`Next ${CHANGE_SUMMARY_PAGE_SIZE} Changes`}
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </>
          )}
        </Formik>
      )}
    </Fragment>
  ) : null
}

export default ChangeSummary
