import {
  Modal,
  Card,
  Grid,
  Button,
  Tooltip,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash.isempty'

const AddCutToArtPatch = (props) => {
  const { addCutToArtPatch, setAddCutToArtPatch, setEditArtPatch, putStory } =
    props
  const cutsNotInPatch = addCutToArtPatch?.body?.story?.cuts?.filter(
    (cut) => !cut.artPatch
  )
  const story = addCutToArtPatch?.body?.story
  const leader = addCutToArtPatch?.body?.leader

  const handleCloseModal = () => {
    setAddCutToArtPatch({ body: {}, show: false })
  }
  return cutsNotInPatch ? (
    <Modal
      isVisible={addCutToArtPatch.show}
      onRefuse={handleCloseModal}
      headingText={'Add Cut to Art Patch: ' + leader.name}
    >
      <div className="hc-pa-normal">
        {isEmpty(cutsNotInPatch) && (
          <Heading size={3}>No cuts available to add</Heading>
        )}
        {cutsNotInPatch.map((cut) => (
          <Card className="hc-ma-dense">
            <Grid.Container
              className="hc-bg-grey06"
              spacing="none"
              align="center"
            >
              <Grid.Item className="hc-pl-normal" xs={11}>
                {cut.name}
              </Grid.Item>
              <Grid.Item xs={1}>
                <Tooltip location="top" content="Add Cut to Patch">
                  <Button
                    iconOnly
                    onClick={() => {
                      putStory({
                        body: {
                          cuts: [
                            {
                              artPatchLeaderId: leader.id,
                              id: cut.id,
                              lastUpdatedTimestamp: cut.lastUpdatedTimestamp,
                              priority: leader.priority,
                            },
                          ],
                          id: story.id,
                          lastUpdatedTimestamp: story.lastUpdatedTimestamp,
                        },
                        pageId: story.pageId,
                      })
                      setEditArtPatch({ body: {}, show: false })
                      handleCloseModal()
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} color="#000000" />
                  </Button>
                </Tooltip>
              </Grid.Item>
            </Grid.Container>
          </Card>
        ))}
        <Grid.Item className="hc-mt-normal hc-ta-right">
          <Button type="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Grid.Item>
      </div>
    </Modal>
  ) : null
}
export default AddCutToArtPatch
