import { Grid } from '@enterprise-ui/canvas-ui-react'

import noImagePlaceholder from '../../media/NoImagePlaceholder.png'

import ChannelDropdown from './ChannelDropdown'
import ContentDetailsRow from './ContentDetailsRow'

const CreativeContent = ({
  updateChannel,
  mediaUnitData,
  disabled,
  creativeImage,
  setCreativeImage,
  eyebrowInput,
  setEyebrowInput,
  featureOffer,
  setFeatureOffer,
  bodyCopyInput,
  setBodyCopyInput,
  audioChannelObj,
  setChangeDetected,
}) => {
  const displayImage = creativeImage?.length
    ? creativeImage
    : noImagePlaceholder

  return (
    <div
      className="media-unit-section-container"
      data-testid="creative-content"
    >
      <Grid.Item className="details-header">Creative Content</Grid.Item>
      <Grid.Container className="category-container-row">
        <Grid.Item xs={2} className="media-unit-image-preview">
          <img src={displayImage} alt="" className="media-unit-image" />
          <div className="media-unit-image-text">Image Preview</div>
        </Grid.Item>
        <Grid.Item className="media-unit-container-rows" xs={10}>
          <ContentDetailsRow
            value={creativeImage}
            updateValue={setCreativeImage}
            title="Creative Image URL"
            disabled={disabled}
            id={mediaUnitData.id}
            setChangeDetected={setChangeDetected}
          />
          <ContentDetailsRow
            value={eyebrowInput}
            updateValue={setEyebrowInput}
            title="Eyebrow"
            disabled={disabled}
            setChangeDetected={setChangeDetected}
          />
          <ContentDetailsRow
            value={featureOffer}
            updateValue={setFeatureOffer}
            title="Feature Offer"
            disabled={disabled}
            split="true"
            setChangeDetected={setChangeDetected}
          />
          <ContentDetailsRow
            value={bodyCopyInput}
            updateValue={setBodyCopyInput}
            title="Body Copy"
            disabled={disabled}
            setChangeDetected={setChangeDetected}
          />
          <ChannelDropdown
            onChange={updateChannel}
            title="Audio"
            disabled={disabled}
            detailsObj={audioChannelObj}
            id={mediaUnitData.id}
            setChangeDetected={setChangeDetected}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default CreativeContent
