import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class ChannelApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_channel_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getChannels = async () =>
    await this.init_channel_api()
      .get(`${this.config.markethub.url}channel`)
      .then((res) => res.data.sort((a, b) => a.channel_order - b.channel_order))
      .catch((e) => {
        console.log(e)
        return []
      })

  addChannelToUmbrella = async ({ channel_id, project_id, umbrella_id }) =>
    await this.init_channel_api()
      .post(`${this.config.markethub.url}umbrella/channel`, {
        channel_id,
        project_id,
        umbrella_id,
      })
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return null
      })

  removeChannelFromUmbrella = async ({
    channel_id,
    project_id,
    umbrella_id,
  }) => {
    await this.init_channel_api()
      .delete(`${this.config.markethub.url}umbrella/channel`, {
        data: {
          channel_id,
          project_id,
          umbrella_id,
        },
      })
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return null
      })
  }
  addAllChannelsToUmbrella = async ({
    project_id,
    pyramid_type,
    channel_id,
    select_all,
  }) => {
    let pyramid_t = encodeURIComponent(pyramid_type)
    await this.init_channel_api()
      .post(
        `${this.config.markethub.url}umbrella/channels?projectId=${project_id}&pyramidType=${pyramid_t}&channelId=${channel_id}&selectAll=${select_all}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return null
      })
  }

  removeAllChannelsFromUmbrella = async ({
    project_id,
    pyramid_type,
    channel_id,
    select_all,
  }) => {
    let pyramid_t = encodeURIComponent(pyramid_type)
    await this.init_channel_api()
      .delete(
        `${this.config.markethub.url}umbrella/channels?projectId=${project_id}&pyramidType=${pyramid_t}&channelId=${channel_id}&selectAll=${select_all}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return null
      })
  }
}
