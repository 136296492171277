import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'

const usePostLateAddition = (setLoading, setLoadLateAdditions) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.lateItems)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const postLateAddition = async () => {
      mounted && setLoading(true)
      try {
        await axios.post(url.current, request)
      } catch (e) {
        console.log(e)
      }
      mounted && setRequest(null)
      mounted && setLoadLateAdditions(true)
    }

    if (request !== null) {
      postLateAddition()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setLoadLateAdditions])

  return setRequest
}
export default usePostLateAddition
