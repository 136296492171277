import { Anchor, Table, Image } from '@enterprise-ui/canvas-ui-react'

import IndicatorChip from '../../components/IndicatorChip'
import '../scss/ImageTrackingItem.scss'
import SortTableHeader from '../../creativeHubGlobal/components/SortTableHeader'
import { assethubImageCheck } from '../../execution/util/ItemUtil'
import noImagePlaceholder from '../../media/NoImagePlaceholder.png'

const ItemTable = ({
  items = [],
  hasEditPrivileges = false,
  setEditItem,
  sortBy,
  sortOrder,
  handleSort,
}) => {
  const columnData = [
    { id: 'image_url', label: 'Thumbnail', size: 1 },
    { id: 'tcin', label: 'TCIN', size: 1 },
    { id: 'dpci', label: 'DPCI', size: 1 },
    { id: 'item_name', label: 'Item Description', size: 2 },
    { id: 'file_image_name', label: 'Image File Name', size: 1 },
    { id: 'image_type', label: 'Image Type', size: 1 },
    { id: 'image_collected', label: 'Image Collected', size: 1 },
    { id: 'offer_name', label: 'Offer Name', size: 1 },
    { id: 'story_name', label: 'Marketing Story', size: 2 },
    { id: 'pyramid_name', label: 'Priority', size: 1 },
  ]

  const sortableColumns = [
    'dpci',
    'image_collected',
    'file_image_name',
    'image_type',
    'pyramid_name',
    'story_name',
    'tcin',
  ]

  const override = (event) => {
    //   This is required to avoid opening and closing
    //   the Expandable section when editing values
    event.stopPropagation()
  }

  function ItemCell(props) {
    const { datum, col } = props
    //const isAssetHubImage = assethubImageCheck(datum[col.id])
    switch (col.id) {
      case 'item_name':
      case 'tcin':
      case 'dpci':
        return (
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            className="itemTitle"
            dangerouslySetInnerHTML={{
              __html: datum[col.id],
            }}
          />
        )
      case 'offer_name':
        return (
          <div
            className="offerTitle"
            dangerouslySetInnerHTML={{
              __html: datum[col.id],
            }}
          />
        )
      case 'image_collected':
        if (!datum[col.id] || datum[col.id] === '') return null
        return <IndicatorChip value={datum[col.id]} field="imageCollected" />
      case 'image_url':
        if (datum[col.id] === null || datum[col.id] === -1) return null
        return (
          <div>
            {assethubImageCheck(datum[col.id]) ? (
              <p style={{ marginBottom: 0 }}>AssetHub Image</p>
            ) : (
              ''
            )}
            <Image
              width="100%"
              type="contain"
              position="center-center"
              src={datum[col.id] || noImagePlaceholder}
              alt="item image"
            />
            {assethubImageCheck(datum[col.id]) ? (
              <Anchor
                href={`${datum[col.id]}&is_download=true`}
                onClick={override}
              >
                Download
              </Anchor>
            ) : null}
          </div>
        )
      case 'file_image_name':
        if (datum[col.id]?.includes('https')) {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <a
                className="C-Anchor"
                target="_blank"
                rel="noreferrer"
                href={datum[col.id]}
              >
                {datum[col.id]}
              </a>
            </div>
          )
        } else {
          return (
            <div
              className="itemTitle"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {datum[col.id]}
            </div>
          )
        }

      default:
        return datum[col.id]
    }
  }

  return items ? (
    <div className="hc-pa-normal hc-pt-none">
      <Table name="ProjectTrackingItemList">
        <Table.Head>
          <Table.Row>
            {columnData.map((col, i) => (
              <SortTableHeader
                key={i}
                col={col}
                sortBy={sortBy}
                sortOrder={sortOrder}
                handleSort={handleSort}
                sortable={sortableColumns.includes(col.id)}
              />
            ))}
          </Table.Row>
        </Table.Head>
        {items.length > 0 ? (
          <Table.Body
            className="image-tracking-table"
            data-testid="items-data"
            key="items-with-data"
          >
            {items.map((datum, index) => (
              <Table.Row
                key={index}
                justify="center"
                onClick={() =>
                  setEditItem({
                    body: datum,
                    show: hasEditPrivileges ? true : false,
                  })
                }
              >
                {columnData.map((col) => (
                  <Table.Data xs={col.size} key={`${datum.item_id}-${col.id}`}>
                    <ItemCell datum={datum} col={col} />
                  </Table.Data>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        ) : (
          <Table.Body
            className="hc-ta-center"
            data-testid="no-items-found"
            key="no-items-found"
          >
            {' '}
            No items found{' '}
          </Table.Body>
        )}
      </Table>
    </div>
  ) : (
    <div />
  )
}
export default ItemTable
