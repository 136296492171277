import axios from 'axios'

import appConfig from '../../config/appConfig'

export default class SystemApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_offer_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.root,
      timeout: 31000,
    })
    return this.client
  }

  getOffers = async () =>
    await this.init_offer_api()
      .get(this.config.backend.system)
      .then((res) => res.data)
      .catch((e) => console.log(e))
}
