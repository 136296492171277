import {
  Grid,
  Heading,
  Input,
  ProgressBar,
  Image,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'

import CancelSubmitButtons from '../../components/CancelSubmitButtons'
import AssetHubApi from '../api/projectExecution/AssetHubApi'

import '../scss/CatalogEditProductModal.scss'

const CatalogEditProductAssetHubModal = (props) => {
  const { assetHubModalOpen, setAssetHubModalOpen, dpci } = props
  const [loading, setLoading] = useState(false)

  const [parentValues, setParentValues] = useState(null)
  const [downloadCount, setDownloadCount] = useState()
  const assetHubApi = new AssetHubApi()

  useEffect(() => {
    fulfilledAssetHub(0, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cleanup = () => {
    setAssetHubModalOpen(false)
  }

  const [alternateImages, setAlternateImages] = useState(null)

  const createAlternateImageArray = (alternateImages, parentValues = null) => {
    let imageArray = formatAssetHubResults(alternateImages)
    imageArray.forEach((image) => {
      let found = parentValues?.find((it) => it.image_url === image.image_url)
      if (found) {
        image.id = found.id
        image.deleted = found.deleted
      }
    })
    return imageArray
  }

  const formatAssetHubResults = (alternateImages) =>
    alternateImages?.map(
      (result) =>
        ({
          asset_key: result.asset_id,
          available_channels: result.available_channels,
          deleted: true,
          expiration_date: moment(result.expiration_date).format(
            'MMM D, YYYY h:mm a'
          ),
          file_name: result.file_name.replace(/\s/g, ''),
          id: null,
          image_url: assetHubApi.storageUrlKey(
            result.storage_url,
            result.file_name.replace(/\s/g, ''),
            result.import_date
          ),
          import_date: result.import_date,
          storage_url: result.storage_url,
        }) || []
    )

  console.log({ alternateImages })
  const fulfilledAssetHub = async (page = 0, weeklyAdToggle = false) => {
    setLoading(true)
    if (dpci !== null && dpci !== '') {
      await assetHubApi
        .postSearch(dpci, page, 21, weeklyAdToggle)
        .then((res) => {
          const imagesArray = createAlternateImageArray(
            res.search,
            parentValues
          )
          setAlternateImages(imagesArray)
        })
        .catch((err) => {
          setAlternateImages(createAlternateImageArray([], parentValues))
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setAlternateImages()
    }
  }

  const setImageChecked = (image_url, value) => {
    let newImagesArray = Array.from(alternateImages)
    let tempDownloadCount = 0
    newImagesArray.forEach((image) => {
      if (image.image_url === image_url) {
        image.deleted = value
        if (image.deleted) {
          image.image_priority = null
        }
      }
      if (image.deleted === false) {
        tempDownloadCount = +1
      }
    })

    setDownloadCount(tempDownloadCount)
    setAlternateImages(newImagesArray)
    setParentValues(newImagesArray)
    tempDownloadCount = 0
  }
  console.log(downloadCount)

  const handleDownload = () => {
    //assetHub alternate Images
    alternateImages.forEach((image) => {
      if (!image.deleted) {
        downloadImage(image.image_url, image.file_name)
      }
    })
  }

  const downloadImage = (url, filename) => {
    if (url.match('(https://target.scene7.com/is/image/Target/[^ "]+$)+')) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = filename
          a.click()
        })
    } else {
      window.open(url + '&is_download=true', '_blank')
    }
  }
  return (
    <div className="hc-pa-normal">
      <Formik
        initialValues={{
          alternate_images: alternateImages || [],
        }}
        //submit no enabled
        onSubmit={() => {
          cleanup()
        }}
      >
        {() => (
          <Modal
            style={{ zIndex: 1080 }}
            data-testid="assethubCatalogModal"
            isVisible={assetHubModalOpen}
            onRefuse={() => {
              cleanup()
            }}
            headingText={'Catalog AssetHub Image Search'}
            className="larger_modal"
          >
            <div className="hc-pa-normal image_modal">
              <Heading size={4}> {dpci && `DPCI  ${dpci}`}</Heading>
              {
                <>
                  <div className="hc-pa-normal">
                    <Grid.Container className="imageGridContainer" align="top">
                      {loading ? (
                        <Grid.Item className="hc-pt-expanded" xs={12}>
                          <ProgressBar indeterminate />
                        </Grid.Item>
                      ) : !loading && alternateImages?.length > 0 ? (
                        <>
                          {alternateImages?.map((result) => (
                            <Grid.Item
                              key={result.asset_key}
                              className="imageContainer"
                            >
                              <Grid.Container className="imageInfo">
                                <Image
                                  position="top-right"
                                  width={'100%'}
                                  height={'100%'}
                                  size={'contain'}
                                  src={result.image_url}
                                  alt={result.file_name}
                                />
                                <Grid.Item xs="6">
                                  <Input.Checkbox
                                    id={result.asset_key}
                                    className="image_check"
                                    onChange={(event) => {
                                      event.target.checked === true
                                        ? setImageChecked(
                                            result.image_url,
                                            false
                                          )
                                        : setImageChecked(
                                            result.image_url,
                                            true
                                          )
                                    }}
                                  />
                                </Grid.Item>
                                <Grid.Item xs="6">
                                  <div
                                    className="downloadButton"
                                    onClick={() =>
                                      downloadImage(
                                        result.image_url,
                                        result.file_name
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="download"
                                      icon={faFileDownload}
                                      size="2x"
                                      color="grey"
                                    />
                                  </div>
                                </Grid.Item>
                                {result.file_name ? (
                                  <p className="imgCaption">
                                    File Name:
                                    <br />
                                    {result.file_name}
                                  </p>
                                ) : null}
                                <div className="break" />
                                {result.import_date ? (
                                  <p className="imgCaption">
                                    Upload Date:
                                    <br />
                                    {moment(result.import_date).format(
                                      'MMM D, YYYY h:mm a'
                                    )}
                                  </p>
                                ) : null}
                                <div className="break" />
                                {result.available_channels ? (
                                  <p className="imgCaption">
                                    Available Channels:
                                    <br />
                                    {result.available_channels}
                                  </p>
                                ) : null}
                                <div className="break" />
                                {result.expiration_date ? (
                                  <p className="imgCaption">
                                    Expiration Date:
                                    <br />
                                    {moment(result.expiration_date).format(
                                      'MMM D, YYYY'
                                    )}
                                  </p>
                                ) : null}
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </>
                      ) : (
                        <>
                          <Grid.Container>
                            <div className="hc-pa-normal noAssets">
                              No AssetHub Results Found
                            </div>
                          </Grid.Container>
                        </>
                      )}
                    </Grid.Container>
                  </div>
                </>
              }
              <Grid.Container align="flex-end">
                <Grid.Item xs={12}>
                  <div className="hc-pa-normal">
                    <CancelSubmitButtons
                      handleDownload={handleDownload}
                      downloadText="Download Selected Images"
                      showDownload={true}
                      handleDialogClose={cleanup}
                      submitDisabled={true}
                      handleSubmit={false}
                      canDelete={false}
                      downloadCount={downloadCount}
                    />
                  </div>
                </Grid.Item>
              </Grid.Container>
            </div>
          </Modal>
        )}
      </Formik>
    </div>
  )
}

export default CatalogEditProductAssetHubModal
