import {
  Anchor,
  Button,
  Chip,
  ExpandableSection,
  Grid,
  Image,
} from '@enterprise-ui/canvas-ui-react'
import {
  faPencilAlt,
  faCommentDollar,
  faGlasses,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { toHyperlink } from './util/linkUtil'

const ExpandableItem = (props) => {
  const {
    page,
    setViewItemPatches,
    item,
    cut,
    setEditItem,
    expands,
    setExpands,
    ids,
    hasEditPrivileges,
  } = props
  if (expands.item[item.id] === undefined) {
    expands.item[item.id] = expands.page[page.id]
  }
  const expanded = expands.item[item.id]
  const assethubImageCheck =
    item?.image?.match('(/assethub_assets/[^ "]+$)+') ||
    item?.image?.match('(/vault_digital_assets/[^ "]+$)+')

  return (
    <ExpandableSection
      padding="none"
      expanded={expanded}
      onExpand={() => {
        let exp = Object.assign({}, expands)
        exp.item[item.id] = !expanded
        setExpands(exp)
      }}
    >
      <Grid.Container align="center" spacing="dense">
        <Grid.Item xs={0.5} align="center">
          {' '}
          <FontAwesomeIcon
            className={`weekly-ad-expand${
              expanded ? 'rotate-clockwise-45' : 'rotate-counter-clockwise-45'
            }`}
            icon={expanded ? faChevronDown : faChevronRight}
          />
        </Grid.Item>
        <Grid.Item xs={4}>{`Item ${item.priority + 1}: ${
          item.dpci
        }`}</Grid.Item>
        <Grid.Item xs={5}> {item.description} </Grid.Item>
        <Grid.Item xs={1}>
          <Button
            iconOnly
            onClick={(event) => {
              event.stopPropagation()
              setViewItemPatches({
                body: item,
                hasEditPrivileges,
                itemId: item.id,
                show: true,
                ...ids,
              })
            }}
          >
            <FontAwesomeIcon
              icon={faCommentDollar}
              color={
                !item.valid
                  ? '#FFFFFF'
                  : item.itemPatches.length
                  ? '#000000'
                  : '#BBBBBB'
              }
            />
          </Button>
        </Grid.Item>
        <Grid.Item xs={1}>
          <Button
            iconOnly
            onClick={(event) => {
              event.stopPropagation()
              setEditItem({
                body: {
                  alternateImages: item.alternateImages,
                  description: item.description,
                  digitalItemPrimaryImage: item.digitalItemPrimaryImage,
                  dpci: item.dpci,
                  figModel: item.figModel,
                  id: item.id,
                  image: item.image,
                  imageFileName: item.imageFileName,
                  imageType: item.imageType,
                  lastUpdatedTimestamp: item.lastUpdatedTimestamp,
                  marketingNotes: item.marketingNotes,
                  merchNotes: item.merchNotes,
                  originalTCINImage: item.digitalItemPrimaryImage,
                  regPriceCallout: item.regPriceCallout,
                  regUnitRetail: item.regUnitRetail,
                  sample: item.sample,
                  sampleId: item.sampleId,
                  sampleImageComments: item.sampleImageComments,
                },
                createdTimestamp: item.createdTimestamp,
                cutLastUpdatedTimestamp: cut.lastUpdatedTimestamp,
                cutValid: cut.valid,
                hasEditPrivileges,
                merchLastUpdatedField: item.merchLastUpdatedField,
                merchLastUpdatedTimestamp: item.merchLastUpdatedTimestamp,
                show: true,
                ...ids,
              })
            }}
          >
            <FontAwesomeIcon
              icon={hasEditPrivileges ? faPencilAlt : faGlasses}
              color={'#000000'}
            />
          </Button>
        </Grid.Item>
      </Grid.Container>
      <ExpandableSection.Content className="hc-pa-normal">
        <Grid.Container>
          {assethubImageCheck ? (
            <Grid.Item xs={3} style={{ outline: 'black dotted' }}>
              <Image
                width="100%"
                type="contain"
                position="center-center"
                src={item.image}
              />
            </Grid.Item>
          ) : (
            <Grid.Item xs={3}>
              <Image
                width="100%"
                type="contain"
                position="center-center"
                src={item.image}
              />
            </Grid.Item>
          )}
          <Grid.Item xs={9}>
            <Grid.Container align="center" spacing="dense">
              <Grid.Item xs={4}>
                Reg $: {item.regUnitRetail}
                <p
                  style={{
                    color: 'red',
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                  }}
                >
                  {!cut.valid ? 'Pictured Status Missing' : ' '}
                </p>
              </Grid.Item>
              <Grid.Item xs={8}>
                <Chip
                  style={{
                    backgroundColor: item.regPriceCallout ? 'red' : 'grey',
                    border: '0px',
                    color: 'white',
                  }}
                  size="dense"
                >
                  {item.regPriceCallout ? 'Reg $' : 'No Reg $'}
                </Chip>
              </Grid.Item>

              <Grid.Item xs={4}>Sample: {item.sample}</Grid.Item>
              <Grid.Item xs={4}>Image Type: {item.imageType}</Grid.Item>
              <Grid.Item xs={4}>
                {item.figModel ? (
                  <Chip
                    style={{
                      backgroundColor: 'violet',
                      border: '0px',
                      color: 'white',
                    }}
                    size="dense"
                  >
                    Fig/Model
                  </Chip>
                ) : null}
              </Grid.Item>
              <Grid.Item xs={12}>
                <div
                  style={{
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    display: '-webkit-box',
                    height: '1.4' /* Fallback for non-webkit */,
                    lineHeight: '1.4',
                    margin: '0 auto',
                    maxWidth: '620px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  File Image Name: {toHyperlink(item.imageFileName)}
                </div>
              </Grid.Item>
              <Grid.Item xs={12}>
                Merch Notes: {toHyperlink(item.merchNotes)}
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          {assethubImageCheck && (
            <Grid.Container>
              <Grid.Item xs={4}>
                AssetHub Image{' '}
                <Anchor href={`${item.image}&is_download=true`}>
                  Download
                </Anchor>
              </Grid.Item>
            </Grid.Container>
          )}
        </Grid.Container>
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}
export default ExpandableItem
