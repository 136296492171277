import {
  Modal,
  List,
  Tabs,
  Grid,
  Form,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import isEmpty from 'lodash.isempty'
import moment from 'moment'
import { useEffect, useState } from 'react'

import OfferApi from '../api/pop-weekly-ad/OfferApi'
import DivisionFilter from '../components/DivisionFilter'
import OfferCard from '../components/OfferCard'
import { formatToIvyDateTime } from '../util/MomentUtil'
import partition from '../util/partition'

const MyOffers = (props) => {
  const {
    myOffers: { show = false },
    setMyOffers,
    email: userEmail,
    startDate,
    timestamp,
  } = props
  const api = new OfferApi()
  const [offersAvailable, setOffersAvailable] = useState({
    linkedOffers: [],
    unlinkedOffers: [],
  })
  const [curTab, setCurTab] = useState('unlinked')
  const [divisions, setDivisions] = useState([])
  const [retrievedOffers, setRetrievedOffers] = useState(false)
  const [email, setEmail] = useState(userEmail)
  useEffect(() => {
    if (show && !retrievedOffers && (!isEmpty(divisions) || !isEmpty(email))) {
      const joinDivs = divisions?.map((division) => division.id)
      api.getOffers(email, joinDivs, startDate).then((offers) => {
        const [linkedOffers, unlinkedOffers] = partition(
          offers,
          (offer) => offer.linkedToCut
        )
        setOffersAvailable({
          linkedOffers,
          unlinkedOffers,
        })
        setRetrievedOffers(true)
      })
    }
  })

  const handleDialogClose = () => {
    setOffersAvailable({
      linkedOffers: [],
      unlinkedOffers: [],
    })
    setRetrievedOffers(false)
    setMyOffers({ show: false })
  }
  return (
    <Modal
      headingText={'Offers pulled in from IVY Promo'}
      isVisible={show}
      onRefuse={handleDialogClose}
    >
      <div className="hc-pa-normal hc-pb-xl">
        <Grid.Container>
          <Grid.Item xs={4}>
            <DivisionFilter
              disabled={!isEmpty(email)}
              maxSelectedOptions={1}
              divisions={divisions}
              setDivisions={(value) => {
                setDivisions(value)
                setEmail('')
                setRetrievedOffers(false)
              }}
              label="Divisions"
            />
          </Grid.Item>
          <Grid.Item xs={4}>
            <Form.Field
              label="AB Email"
              type="text"
              value={email}
              disabled={divisions?.length === 1}
              onChange={(event) => {
                setEmail(event.target.value)
                setRetrievedOffers(false)
              }}
            />
          </Grid.Item>

          <Grid.Item xs={2}>
            <Button
              className="hc-mt-md"
              onClick={() => {
                setEmail('')
                setDivisions([])
                setRetrievedOffers(false)
                setOffersAvailable({
                  linkedOffers: [],
                  unlinkedOffers: [],
                })
              }}
              type="primary"
            >
              Clear
            </Button>
          </Grid.Item>
          <Grid.Item xs={2} className="hc-pa-dense hc-ta-right">
            <p className="hc-fs-sm">
              {'Last Ivy Pull: ' + formatToIvyDateTime(timestamp)}
            </p>
          </Grid.Item>
          <p>
            {' '}
            Select either one division or type in one associate buyer name to
            filter list of offers
          </p>
          <Grid.Item xs={12}>
            <Tabs
              activeTab={curTab}
              onTabSelect={(_, tab) => {
                setCurTab(tab.name)
              }}
            >
              <Tabs.Item name="linked">Linked</Tabs.Item>
              <Tabs.Item name="unlinked">Unlinked</Tabs.Item>
            </Tabs>
          </Grid.Item>

          <>
            <List>
              {curTab === 'linked' ? (
                <>
                  {offersAvailable?.linkedOffers
                    .sortByTime()
                    .filter((offer) => offer.weeklyAd)
                    .map((offer, index) => (
                      <List>
                        <Grid.Container>
                          <Grid.Item xs={1}></Grid.Item>
                          <Grid.Item xs={10}>
                            <OfferCard
                              offer={offer}
                              index={index}
                              liner
                              weeklyAdStartDate={startDate}
                            />
                          </Grid.Item>
                          <Grid.Item xs={1}></Grid.Item>
                        </Grid.Container>
                      </List>
                    ))}
                </>
              ) : (
                <>
                  {offersAvailable?.unlinkedOffers
                    .sortByTime()
                    .filter((offer) => offer.weeklyAd)
                    .map((offer, index) => (
                      <List>
                        <Grid.Container>
                          <Grid.Item xs={1}></Grid.Item>
                          <Grid.Item xs={10}>
                            <OfferCard
                              offer={offer}
                              index={index}
                              liner
                              weeklyAdStartDate={startDate}
                            />
                          </Grid.Item>
                          <Grid.Item xs={1}></Grid.Item>
                        </Grid.Container>
                      </List>
                    ))}
                </>
              )}
            </List>
          </>
        </Grid.Container>
      </div>
    </Modal>
  )
}
export default MyOffers

// eslint-disable-next-line
Array.prototype.sortByTime = function () {
  return this.sort(
    (a, b) => moment(b.lastModifiedTimestamp) - moment(a.lastModifiedTimestamp)
  )
}
