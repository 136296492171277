const filterDeactivatedDeletedOffers = (offers = []) => {
  // Exclude placeholder offers from art patch selection.
  if (offers.length > 0) {
    offers = offers.filter(
      (offer) =>
        offer.ivy_offer_id !== -1 &&
        offer.status !== 'deactivated' &&
        offer.deleted !== true
    )
  }
  return offers
}

export { filterDeactivatedDeletedOffers }
