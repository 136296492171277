import { Button, Chip, Table, Dialog } from '@enterprise-ui/canvas-ui-react'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import EditPageDetails from '../forms/EditPageDetails'
import PageBackgroundColor from '../util/PageBackgroundColor'
import ReorderArray from '../util/ReorderArray'

import DragHandle from './DragHandle'

const SortablePageTable = (props) => {
  const {
    hasEditPrivileges,
    weeklyAd,
    putPage,
    putWad,
    setLoading,
    changePages,
    setChangePagesModal,
  } = props

  const [isEditing, setIsEditing] = useState(false)
  const [pageIdx, setPageIdx] = useState({})
  const [page, setPage] = useState({ body: {}, show: false })
  const [isReorderDialog, setReorderDialog] = useState(false)

  let pages = weeklyAd.pages
  let digitalPages = pages?.filter((page) => page.bookOnePageType === 'DIGITAL')
  let printPages = pages?.filter((page) => page.bookOnePageType === 'PRINT')

  useEffect(() => {
    if (pages) {
      setPageIndexes(pages)
    }
  }, [pages])

  const setPageIndexes = (curPages) => {
    const newIdx = {}
    curPages.forEach((curPage, idx) => {
      newIdx[curPage.id] = idx
    })
    setPageIdx(newIdx)
  }

  function isDigitalOrPrint(page) {
    return (
      (page?.bookOnePageType === 'DIGITAL' ||
        page?.bookOnePageType === 'PRINT') &&
      page?.bookOnePageNumber !== '-'
    )
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      pages = ReorderArray(pages, oldIndex, newIndex, '', true)
      let editing = false
      pages.forEach((currPage, idx) => {
        if (currPage.pageOrdering !== idx) {
          editing = true
        }
      })
      setIsEditing(editing)
      setPageIndexes(pages)
    }
  }
  function handleUpdate(event, value) {
    event.stopPropagation()
    setChangePagesModal({
      digitalPages: digitalPages,
      page: value,
      printPages: printPages,
      show: true,
    })
  }

  const columnData = [
    { id: 'handle', label: '', width: 1 },
    { id: 'bookOnePageNumber', label: 'Book 1 Page', width: 1 },
    { id: 'bookOneOldPageNumber', label: 'Old Page', width: 1 },
    ...(!weeklyAd.midweek
      ? [
          {
            id: 'bookTwoPageNumber',
            label: 'Book 2 Page',
            width: 1,
          },
        ]
      : []),
    ...(!weeklyAd.midweek
      ? [
          {
            id: 'bookTwoOldPageNumber',
            label: 'Old Page',
            width: 1,
          },
        ]
      : []),
    { id: 'divisions', label: 'Division', width: weeklyAd.midweek ? 3 : 2 },
    { id: 'artDirector', label: 'Art Director', width: 1 },
    { id: 'copyWriter', label: 'Kickoff Copywriter', width: 1 },
    ...(!weeklyAd.midweek
      ? [
          {
            id: 'postMprCopyWriter',
            label: 'Post-MPR Copywriter',
            width: 1,
          },
        ]
      : []),
    { id: 'messaging', label: 'Messaging', width: 2 },
  ]

  const onReorderClick = () => {
    if (weeklyAd.midweek) {
      saveReorder()
    } else {
      setReorderDialog(true)
    }
  }

  const saveReorder = () => {
    const pageDifference = []
    pages.forEach((curPage) => {
      if (curPage.pageOrdering !== pageIdx[curPage.id]) {
        pageDifference.push({
          id: curPage.id,
          lastUpdatedTimestamp: curPage.lastUpdatedTimestamp,
          lockoutDate: curPage.lockoutDate,
          pageOrdering: pageIdx[curPage.id],
        })
      }
    })
    putWad({
      contactId: weeklyAd.contact.id,
      id: weeklyAd.id,
      lastUpdatedTimestamp: weeklyAd.lastUpdatedTimestamp,
      pages: pageDifference,
    })
    setIsEditing(false)
    setReorderDialog(false)
  }

  const SortableItem = SortableElement(({ value }) => (
    <Table.Row
      key={value.id}
      className={PageBackgroundColor(value)}
      align="center"
      onClick={
        !isEditing && hasEditPrivileges
          ? () => setPage({ body: value, show: true })
          : undefined
      }
      //Style tag for 'display' needed to keep form when reordering
      style={{ display: 'flex' }}
    >
      {columnData.map((column) => {
        switch (column.id) {
          case 'handle': {
            return (
              <Table.Data
                className="hc-ta-center"
                key={column.id}
                xs={column.width}
              >
                {hasEditPrivileges ? (
                  changePages ? (
                    isDigitalOrPrint(value) ? (
                      <Button
                        iconOnly
                        onClick={(event) => handleUpdate(event, value)}
                        style={{ color: 'grey' }}
                      >
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </Button>
                    ) : null
                  ) : (
                    <DragHandle />
                  )
                ) : null}
              </Table.Data>
            )
          }
          case 'divisions': {
            // Occasionally, divisions will load as null. This prevents a null pointer.
            return (
              <Table.Data key={column.id} xs={column.width}>
                {!isEmpty(value[column.id])
                  ? value[column.id].map((division) => (
                      <Chip
                        size="dense"
                        className="hc-mb-dense"
                        key={division.id}
                        style={{
                          backgroundColor: division.color,
                          border: '0px',
                          color: 'white',
                        }}
                      >
                        {division?.shortCode?.toUpperCase()}
                      </Chip>
                    ))
                  : null}
              </Table.Data>
            )
          }
          case 'bookOneOldPageNumber':
          case 'bookTwoOldPageNumber':
            return (
              <Table.Data
                key={column.id}
                xs={column.width}
                className="hc-clr-error hc-ta-center"
              >
                {value[column.id]}
              </Table.Data>
            )
          case 'postMprCopyWriter':
            return (
              <Table.Data
                key={column.id}
                xs={column.width}
                className="hc-ta-center"
              >
                {value[column.id].toString()}
              </Table.Data>
            )
          default: {
            return (
              <Table.Data
                key={column.id}
                xs={column.width}
                className="hc-ta-center"
              >
                {value[column.id]}
              </Table.Data>
            )
          }
        }
      })}
    </Table.Row>
  ))

  const SortableList = SortableContainer(() => (
    <Table.Body>
      {pages.map((page) =>
        pageIdx[page.id] || pageIdx[page.id] === 0 ? (
          <SortableItem
            key={page.id}
            index={pageIdx[page.id]}
            value={page}
            disabled={!hasEditPrivileges}
          />
        ) : null
      )}
    </Table.Body>
  ))

  return pages && pageIdx && !isEmpty(pageIdx) ? (
    <div>
      <Table name="pages">
        <Table.Head>
          <Table.Row>
            {columnData.map((column) => (
              <Table.Header
                style={{ color: '#000000', fontWeight: 'bold' }}
                className="hc-ta-center"
                key={column.id}
                xs={column.width}
              >
                {column.label}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <SortableList onSortEnd={onSortEnd} useDragHandle />
      </Table>
      <EditPageDetails
        weeklyAd={weeklyAd}
        setPage={setPage}
        page={page}
        putPage={putPage}
        putWad={putWad}
        setLoading={setLoading}
      />
      <Dialog
        isVisible={isReorderDialog}
        headingText={
          'Lockout dates are not updated when pages are re-ordered.  As needed, update the lockouts manually on the Pagination Edit Page screen.'
        }
        onRefuse={() => setReorderDialog(false)}
        refuseButtonText="Cancel"
        onApprove={saveReorder}
        approveButtonText={'Reorder'}
      />

      {isEditing ? (
        <div>
          <Button
            type="primary"
            style={{ bottom: '20px', position: 'fixed', right: '20px' }}
            onClick={onReorderClick}
          >
            Reorder Pages
          </Button>
          <Button
            type="secondary"
            style={{
              background: 'white',
              bottom: '20px',
              position: 'fixed',
              right: '190px',
            }}
            onClick={() => {
              pages.sort((a, b) => a.pageOrdering - b.pageOrdering)
              setPageIndexes(pages)
              setIsEditing(false)
            }}
          >
            Cancel Reorder
          </Button>
        </div>
      ) : null}
    </div>
  ) : null
}
export default SortablePageTable
