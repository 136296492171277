export const isChannelAvailable = (assignedChannels = [], channelId = '') => {
  const isAvailable = assignedChannels.some(
    (assignee) => assignee.channel_id === channelId
  )
  return isAvailable
}

export const isChannelSelected = (assignedChannels = [], channelId = '') => {
  const isSelected = assignedChannels.some(
    (assignee) => assignee.channel_id === channelId && assignee.selected
  )
  return isSelected
}

export const updateChannelSelection = (
  assignedChannels = [],
  channelId,
  selected = false
) =>
  assignedChannels.map((channel = {}) => {
    if (channel.channel_id === channelId) {
      channel.selected = selected
    }
    return channel
  })
