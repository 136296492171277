import isEmpty from 'lodash.isempty'

const catalogPageMapper = (page) => ({
  artDirector: page.art_director || [],
  category: page.category || [],
  copyWriter: page.copy_writer || [],
  deleted: page.deleted,
  dpci_list: page.page_dpci,
  id: page.id,
  lastUpdatedTimestamp: page.last_updated_timestamp,
  merchant: page.merchant || [],
  messaging: page.messaging,
  notes: page.notes,
  pageOrdering: page.page_ordering,
  section: !isEmpty(page.section) ? page.section : [],
})
export default catalogPageMapper
