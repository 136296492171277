import isEmpty from 'lodash.isempty'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'

const usePopCookies = () => {
  const [cookie, setCookie, removeCookie] = useCookies([
    'sample_view_pyramids',
    'auto_login',
  ])
  const pathname = useLocation().pathname
  const wadId = window.location.pathname.split('/')[2] || '-1'
  const sampleQuery = new URLSearchParams()

  if (!isEmpty(cookie.sample_view_pyramids)) {
    sampleQuery.append('pyramids', cookie.sample_view_pyramids)
  }

  const autoLogin = cookie.auto_login
  return {
    autoLogin,
    pathname: pathname,
    removeCookie,
    setCookie,
    toSampleUrl: (id, archive) =>
      !isEmpty(sampleQuery)
        ? `/weeklyad/${id}/sample/items?hasRun=${archive}&${sampleQuery}`
        : `/weeklyad/${id}/sample/items?hasRun=${archive}`,
    wadId,
  }
}
export default usePopCookies
