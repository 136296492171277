import { Button, Heading, Table } from '@enterprise-ui/canvas-ui-react'
import '../scss/Category.scss'
import { ModalDrawer } from 'nicollet-react'

const BCMDetails = ({ setIsBcmDrawerOpen, isBcmDrawerOpen }) => (
  <ModalDrawer
    isOpen={isBcmDrawerOpen}
    iconButtonCloseOnClick={() => setIsBcmDrawerOpen(false)}
    scroll={true}
    onRequestClose={() => {
      setIsBcmDrawerOpen(false)
    }}
    headingText={
      <div>
        <Heading size={3}>{'BCM Category Details'}</Heading>
      </div>
    }
  >
    <span className="bold-header">Category Section Rules</span>
    <ul className="bcm-details-list">
      <li>
        {' If one category makes up >50% of the categories in the asset, '}
        <span className="underline-text">tag the category</span>
      </li>
      <li>
        {
          ' If one category makes up 50% or more of the categories in the asset, '
        }
        <span className="underline-text">tag the pyramid</span>
      </li>
      <li>
        {' If no one category or pyramid makes up 50% or more of the asset, '}
        <span className="underline-text">tag as Cross-Category</span>
      </li>
      <li>
        {' If there are no categories represented, '}
        <span className="underline-text"> tag as Non-Category</span>
      </li>
    </ul>
    <Table className="bcm-category-key-container">
      <Table.Row className="bcm-details-header">
        <Table.Data>BCM Categories</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>AA</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>Baby</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>Beauty</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>CrossCatFrequency</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>CrossCatDiscretionary</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>CrossCatTotal</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>Hardlines All Other</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>Essentials</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>FB</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>Hardlines</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>Home</Table.Data>
      </Table.Row>
      <Table.Row className="bcm-table-rows">
        <Table.Data>NA</Table.Data>
      </Table.Row>
    </Table>
    <Button
      className="modal-button"
      type="primary"
      onClick={() => setIsBcmDrawerOpen(false)}
      fullWidth
    >
      Close
    </Button>
  </ModalDrawer>
)

export default BCMDetails
