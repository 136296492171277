import { useReducer } from 'react'

import { AppContext } from './Context'

export const AppContextProvider = ({ children }) => {
  const storedNotes = JSON.parse(localStorage.getItem('merchNotes')) || []
  const initialState = {
    merchNotes: storedNotes !== undefined ? storedNotes : {},
  }
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_MERCH_NOTES': {
        const updatedState = {
          ...state,
          merchNotes: {
            ...state.merchNotes,
            [action.payload.offerId]: action.payload.value,
          },
        }
        localStorage.setItem(
          'merchNotes',
          JSON.stringify(updatedState.merchNotes)
        )

        return updatedState
      }
      case 'REMOVE_MERCH_NOTES': {
        const { id } = action.payload
        const { [id]: _, ...remainingNotes } = state.merchNotes
        const updatedState = {
          ...state,
          merchNotes: remainingNotes,
        }
        const storedNotes = JSON.parse(localStorage.getItem('merchNotes')) || {}
        if (id in storedNotes) {
          delete storedNotes[id]
          localStorage.setItem('merchNotes', JSON.stringify(storedNotes))
        }
        return updatedState
      }
      default:
        return state
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ dispatch, state }}>
      {children}
    </AppContext.Provider>
  )
}
