import { Anchor } from '@enterprise-ui/canvas-ui-react'

export const toHyperlink = (inputText = '') => {
  let URLpattern1 = /(^|[^])(www\.[\S]+(\b|$))/gim
  let URLpattern2 = /(\b(https?|http|):\/\/)/gi
  let containsURL =
    /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gi
  let parts = inputText.split(' ')
  if (inputText === '' || !containsURL.test(inputText)) return inputText
  for (let i = 0; i < parts.length; i++) {
    if (URLpattern2.test(parts[i])) {
      parts[i] = (
        <Anchor key={'link' + i} target="_blank" href={parts[i]}>
          {parts[i]}{' '}
        </Anchor>
      )
    } else if (URLpattern1.test(parts[i])) {
      parts[i] = (
        <Anchor key={'link' + i} target="_blank" href={'http://' + parts[i]}>
          {parts[i]}{' '}
        </Anchor>
      )
    } else if (parts[i] !== ' ') {
      parts[i] = parts[i] + ' '
    }
  }
  return parts
}
