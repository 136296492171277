const historyMapper = ({
  approved,
  catalog_id,
  deleted,
  id,
  last_updated_timestamp,
  product_id,
  update,
  updated_by,
  product_name,
  dpci,
  page_ordering,
  creation_date,
}) => ({
  approved,
  catalogId: catalog_id,
  creationDate: creation_date,
  deleted,
  dpci,
  id,
  lastUpdatedTimestamp: last_updated_timestamp,
  pageOrdering: page_ordering,
  productId: product_id,
  productName: product_name,
  update,
  updatedBy: updated_by,
})
export default historyMapper
