import { Card, Grid, Divider } from '@enterprise-ui/canvas-ui-react'
import React, { useContext } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { FlatmapArtPatches, MapFollowersToLeaders } from '../util/LeadersUtil'
import ReorderArray from '../util/ReorderArray'
import { WeeklyAdContext } from '../views/weekly-ad/MerchView'

import DragHandle from './DragHandle'
import ExpandableCut from './ExpandableCut'

const SortableCutContainer = (props) => {
  const {
    page,
    story,
    putStory,
    putCut,
    divisions,
    setEditCut,
    setViewCutPatches,
    setViewItemPatches,
    setEditArtPatch,
    setEditItem,
    setEditDummy,
    setEditPropItem,
    expands,
    setExpands,
    hasEditPrivileges,
    lockoutDate,
    eventWeek,
  } = props

  const leaders = story.cuts.filter((cut) => cut.artPatchLeaderId === null)
  const followers = story.cuts.filter((cut) => cut.artPatchLeaderId !== null)
  const leadersWithFollowersArray = MapFollowersToLeaders(leaders, followers)
  const { startDate: weeklyAdStartDate } = useContext(WeeklyAdContext)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newLeaderCuts = ReorderArray(
        leaders,
        oldIndex,
        newIndex,
        'priority'
      )
      const allChangedCuts = FlatmapArtPatches(newLeaderCuts, followers)
      putStory({
        body: {
          cuts: allChangedCuts,
          id: story.id,
          lastUpdatedTimestamp: story.lastUpdatedTimestamp,
        },
        pageId: page.id,
      })
    }
  }

  const SortableItem = SortableElement(({ cut, followers }) => (
    <Card
      className={`hc-ma-dense ${
        !cut.valid || cut.cutHasInvalidOffer
          ? 'hc-bg-grey02 hc-clr-white'
          : 'hc-bg-grey07'
      }`}
    >
      <Grid.Container spacing="none" align="center">
        <Grid.Item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          {hasEditPrivileges ? <DragHandle /> : null}
        </Grid.Item>
        <Grid.Item xs={11}>
          <Grid.Container spacing="none">
            <Grid.Item xs={12}>
              <ExpandableCut
                cut={cut}
                followers={followers}
                putCut={putCut}
                page={page}
                story={story}
                divisions={divisions}
                setEditCut={setEditCut}
                setEditDummy={setEditDummy}
                setEditPropItem={setEditPropItem}
                setViewCutPatches={setViewCutPatches}
                setViewItemPatches={setViewItemPatches}
                setEditArtPatch={setEditArtPatch}
                setEditItem={setEditItem}
                expands={expands}
                setExpands={setExpands}
                hasEditPrivileges={hasEditPrivileges}
                weeklyAdStartDate={weeklyAdStartDate}
                eventWeek={eventWeek}
                lockoutDate={lockoutDate}
              />
            </Grid.Item>
            {followers?.map((follower) => (
              <React.Fragment key={follower.id}>
                <Divider />
                <Grid.Item
                  className={
                    follower.valid
                      ? 'hc-bg-grey07 hc-clr-black'
                      : 'hc-bg-error hc-clr-white'
                  }
                  xs={12}
                >
                  <ExpandableCut
                    cut={follower}
                    putCut={putCut}
                    page={page}
                    story={story}
                    divisions={divisions}
                    setEditCut={setEditCut}
                    setEditDummy={setEditDummy}
                    setEditPropItem={setEditPropItem}
                    setViewCutPatches={setViewCutPatches}
                    setViewItemPatches={setViewItemPatches}
                    setEditArtPatch={setEditArtPatch}
                    setEditItem={setEditItem}
                    expands={expands}
                    setExpands={setExpands}
                    hasEditPrivileges={hasEditPrivileges}
                  />
                </Grid.Item>
              </React.Fragment>
            ))}
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Card>
  ))

  const SortableList = SortableContainer(() => (
    <div className="hc-pa-none">
      {leadersWithFollowersArray.map((leaderAndFollowers) => {
        const [leader, ...followers] = leaderAndFollowers
        return (
          <SortableItem
            key={leader.id}
            index={leader.priority}
            cut={leader}
            followers={followers}
          />
        )
      })}
    </div>
  ))

  return story?.cuts ? (
    <SortableList onSortEnd={onSortEnd} useDragHandle />
  ) : (
    <div />
  )
}
export default SortableCutContainer
