import axios from 'axios'

import appConfig from '../../config/appConfig'

export default class DigitalImagesApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_digitalImages_api = () => {
    this.client = axios.create({
      baseURL: this.config.graphql.url,
      timeout: 310000,
    })

    return this.client
  }

  getDigitalImages = async (tcins) => {
    const data = []
    for (const tcin of tcins) {
      const query = new URLSearchParams({
        key: this.config.graphql.key,
        selection: `AEUBAAAASGNgwAkSGIgHHAA=`,
        tcin: `${tcin}`,
        // Selection code generates when you create a query using https://api-internal.target.com/items/v4/graphiql
        // This selection code was generated with the following query.
        // {
        //   item(tcin: ${tcin}) {
        //     tcin
        //     digitalAssets {
        //       images {
        //         baseUrl
        //         primaryImage
        // 			}
        //     }
        //   }
        // }
      })
      data.push(
        await this.init_digitalImages_api()
          .get(`/compact/item?${query}`)
          .then((res) => res.data.data?.item)
          .catch((e) => {
            console.log(e)
            return []
          })
      )
    }
    return data
  }
}
