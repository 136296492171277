import { cloneDeep, isEmpty } from 'lodash'
export const sortUmbrellasByOrder = (umbrellas = []) => {
  const activeUmbrellas = umbrellas.filter(
    (umbrella) => umbrella.status !== 'DEACTIVATED'
  )
  const deactivatedUmbrellas = umbrellas.filter(
    (umbrella) => umbrella.status === 'DEACTIVATED'
  )
  activeUmbrellas.sort((a, b) => a.umbrella_order - b.umbrella_order)
  return [...activeUmbrellas, ...deactivatedUmbrellas]
}

export const activeOffers = (offerElements = []) =>
  Object.values(offerElements).filter(
    (offer) =>
      !('offer_art_patch_leader_id' in offer) &&
      offer?.offer_order !== -1 &&
      offer?.deleted === false
  ).length

export const isUmbrellaEnterpriseLinked = (
  linkedUmbrellas = [],
  ivyUmbrellaId
) =>
  !isEmpty(
    linkedUmbrellas?.find(
      (entLinkedUmbrella) => entLinkedUmbrella.ivy_umbrella_id === ivyUmbrellaId
    )
  )

export const filteredUmbrellasOrOffers = (
  umbrellaOfferGroups,
  searchInput = ''
) => {
  const filteredUmbrellas = []

  if (umbrellaOfferGroups && umbrellaOfferGroups.length) {
    umbrellaOfferGroups.forEach((umbrella) => {
      const {
        merchant_category = {},
        name: umbrellaName = '',
        offers = [],
      } = umbrella
      const pyramidName = merchant_category.group_name || ''
      if (
        // if umbrella name or pyramid (group name) match then return all offers and umbrella
        umbrellaName.toLowerCase().includes(searchInput.toLowerCase()) ||
        pyramidName.toLowerCase().includes(searchInput.toLowerCase())
      ) {
        filteredUmbrellas.push(umbrella)
      } else {
        // search for matching offers if umbrella name or pyrmaid doesn't match
        let tempUmbrella = cloneDeep(umbrella)

        const filteredOffers = offers.filter(
          ({ name = '', ivy_offer_id }) =>
            name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
            ivy_offer_id?.toString().includes(searchInput)
        )

        if (filteredOffers && filteredOffers.length > 0) {
          tempUmbrella.offers = filteredOffers
          filteredUmbrellas.push(tempUmbrella)
        }
      }
    })
  }
  return filteredUmbrellas
}
