import { Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

let addDummySchema = yup.object().shape({
  add_dummy_description: yup.string().required('Required'),
  add_dummy_keyNotes: yup.string(),
  add_dummy_regPriceCallout: yup.bool(),
  add_dummy_regUnitRetail: yup.string().matches(/^\d*(?:\.(?!00)\d{2})?$/, {
    message: 'Retail Price in format 99.99',
  }),
})

const AddDummy = (props) => {
  const { addDummy, setAddDummy, postDummy, closeAllModals } = props

  const wipeState = () => {
    setAddDummy({
      cutId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={addDummySchema}
      initialValues={{
        add_dummy_description: '',
        add_dummy_keyNotes: '',
        add_dummy_regPriceCallout: '',
        add_dummy_regUnitRetail: '',
      }}
      onSubmit={(values, funcs) => {
        postDummy({
          body: {
            cutId: addDummy.cutId,
            description: values.add_dummy_description,
            keyNotes: values.add_dummy_keyNotes,
            regPriceCallout: values.add_dummy_regPriceCallout,
            regUnitRetail: values.add_dummy_regUnitRetail,
          },
          cutId: addDummy.cutId,
          pageId: addDummy.pageId,
          storyId: addDummy.storyId,
        })
        wipeState()
        closeAllModals()
        funcs.resetForm()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={addDummy.show}
            headingText={`Add Placeholder Item`}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <Grid.Container align="center" spacing="dense">
                <Grid.Item xs={12}>
                  <Form.Field
                    id="add_dummy_description"
                    value={values.add_dummy_description}
                    label="Placeholder Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.add_dummy_description &&
                      errors.add_dummy_description
                    }
                    errorText={errors.add_dummy_description}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="add_dummy_regUnitRetail"
                    value={values.add_dummy_regUnitRetail}
                    label="Regular Unit Retail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.add_dummy_regUnitRetail &&
                      errors.add_dummy_regUnitRetail
                    }
                    errorText={errors.add_dummy_regUnitRetail}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="add_dummy_regPriceCallout"
                    type="toggle"
                    value={values.add_dummy_regPriceCallout}
                    label="Regular $ Callout"
                    onChange={() =>
                      setFieldValue(
                        'add_dummy_regPriceCallout',
                        !values.add_dummy_regPriceCallout
                      )
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    type="textarea"
                    grow
                    id="add_dummy_keyNotes"
                    value={values.add_dummy_keyNotes}
                    label="Key Notes"
                    onChange={handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  )
}
export default AddDummy
