import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'

const useDeleteLateAddition = (setLoading, setLoadLateAdditions) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.lateItems)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const deleteLateAddition = async () => {
      mounted && setLoading(true)
      try {
        await axios.delete(url.current + request.id)
        mounted && setLoadLateAdditions(true)
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }
    if (request !== null) {
      deleteLateAddition()
    }
    return () => {
      mounted = false
    }
  }, [setLoading, request, setLoadLateAdditions])

  return setRequest
}
export default useDeleteLateAddition
