import { Button, ButtonGroup, Grid } from '@enterprise-ui/canvas-ui-react'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CancelSubmitButtons = (props) => {
  const {
    canDelete,
    cancelText,
    className,
    deleteDisabled,
    downloadCount = 0,
    downloadText,
    handleDelete,
    handleDownload,
    handleSubmit,
    handleDialogClose,
    showDownload,
    submitDisabled,
    cancelDisabled,
    submitText,
    ...otherProps
  } = props

  const showSubmit = !!handleSubmit
  const cancelButtonText = cancelText || 'Cancel'
  const submitButtonText = submitText || 'Submit'
  const finalClassName = className
    ? `hc-pt-normal ${className}`
    : 'hc-pt-normal'
  const showDelete = canDelete === undefined ? true : !!canDelete

  return (
    <Grid.Container
      justify="space-between"
      direction="row-reverse"
      align="center"
      className={finalClassName}
      {...otherProps}
    >
      <Grid.Item>
        <ButtonGroup>
          <Button
            type="secondary"
            onClick={handleDialogClose}
            disabled={cancelDisabled}
          >
            {cancelButtonText}
          </Button>
          <>
            {showDownload ? (
              <Button
                type={
                  downloadCount !== null && downloadCount > 0
                    ? 'primary'
                    : 'secondary'
                }
                onClick={handleDownload}
              >
                {downloadCount == null || downloadCount === 0
                  ? downloadText
                  : `${downloadText} (${downloadCount})`}
              </Button>
            ) : null}
          </>
          <>
            {showSubmit ? (
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                {submitButtonText}
              </Button>
            ) : null}
          </>
        </ButtonGroup>
      </Grid.Item>
      {showDelete && (
        <Grid.Item
          onClick={(event) => {
            handleDelete(event)
          }}
        >
          <FontAwesomeIcon
            className="labelledIcon"
            icon={faTrashAlt}
            size="lg"
            data-cy={`delete-edit-item`}
            disabled={deleteDisabled}
          />
        </Grid.Item>
      )}
    </Grid.Container>
  )
}
export default CancelSubmitButtons
