import {
  Card,
  CardActions,
  CardMedia,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  cardActions: {
    padding: '5px !important',
  },
  cardContent: {
    textAlign: 'center',
  },
  displayTCINContent: {
    paddingTop: '5px',
    textAlign: 'center',
  },
  media: {
    height: '120px',
    margin: '0 auto',
    width: '105px',
  },
  root: {
    margin: '5px',
    padding: '20px 10px',
  },
}))

export const ImageCard = (boostedTcin = {}) => {
  const classes = useStyles()
  const {
    tcin,
    handleImageCardChange,
    canDisplayCheckBox = false,
  } = boostedTcin
  const { checked, url, id } = tcin

  const handleChange = () => {
    handleImageCardChange({ checked: !checked, id: id, url: url })
  }

  return id ? (
    <Card className={classes.root} data-testid="image-card">
      <CardMedia
        className={classes.media}
        image={url}
        data-testid={'image-url-' + id}
      />
      {canDisplayCheckBox ? (
        <CardActions disableSpacing className={classes.cardActions}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checked} onClick={handleChange} />}
              label={id}
              labelPlacement="end"
            />
          </FormGroup>
        </CardActions>
      ) : (
        <div className={classes.displayTCINContent}>{id}</div>
      )}
    </Card>
  ) : null
}
