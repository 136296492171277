// For each umbrella, we will now have offers organized as an array of arrays.
// each array will have the leader as index 0, and each follower will be appended onto the array.

export const MapFollowersToLeaders = (leaders, followers) =>
  leaders.map((leader) => [
    leader,
    ...followers.filter(
      (follower) => follower.offer_art_patch_leader_id === leader.id
    ),
  ])

export const groupArtPatchOffers = ({
  displayOffers = [],
  projectDescription = '',
  projectName = '',
  pyramidName = '',
  pyramidNameId = {},
  pyramidType = '',
  storyId = '',
  storyName = '',
  umbrella = {},
}) => {
  let leaders
  let followers

  leaders = Object.values(displayOffers).filter(
    (offer) => !('offer_art_patch_leader_id' in offer)
  )
  leaders.forEach((leader) => {
    leader.index = leaders.indexOf(leader) + 1
    leader.project_name = projectName
    leader.project_desc = projectDescription
    leader.story_id = storyId
    leader.story_name = storyName
    leader.umbrella_name = umbrella.name
    leader.pyramid_id = pyramidNameId.pyramidId
    leader.channels = umbrella.channels
    leader.pyramid_name =
      pyramidType === 'ent'
        ? pyramidName.toUpperCase()
        : pyramidType.toUpperCase() // e.g. A&A
    leader.priority = pyramidNameId.pyramidName // description e.g. (Home Top Offers)
    if (leader.offer_order !== leader.index) {
      leader.offer_order = leader.index
    }
  })
  followers = Object.values(displayOffers).filter(
    (offer) => 'offer_art_patch_leader_id' in offer
  )
  followers.forEach((follower) => {
    follower.index = followers.indexOf(follower) + 1
    follower.project_name = projectName
    follower.project_desc = projectDescription
    follower.story_id = storyId
    follower.story_name = storyName
    follower.umbrella_name = umbrella.name
    follower.pyramid_id = pyramidNameId.pyramidId
    follower.channels = umbrella.channels
    follower.pyramid_name =
      pyramidType === 'ent'
        ? pyramidName.toUpperCase()
        : pyramidType.toUpperCase() // e.g. A&A
    follower.priority = pyramidNameId.pyramidName // description e.g. (Home Top Offers)
    if (follower.offer_order !== follower.index) {
      follower.offer_order = follower.index
    }
  })
  return { followers, leaders }
}

export const groupOffersByArtPatchId = (offers = []) =>
  offers.reduce((group, offer) => {
    const { offer_art_patch_leader_id = '' } = offer
    group[offer_art_patch_leader_id] = group[offer_art_patch_leader_id] ?? []
    group[offer_art_patch_leader_id].push(offer)
    return group
  }, {})
