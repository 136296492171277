import { Grid } from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect, useMemo } from 'react'

import PublishingApi from '../../api/creative-hub/PublishingApi'
import { convertMarketOptions } from '../util/helpers'

import Category from './Category'
import '../scss/Category.scss'

const CategorySection = ({
  showEssence,
  categoriesSelected,
  setCategoriesSelected,
  canEdit,
  channelOptions,
  projectId,
  setSelectedOffer,
  setIsOfferDrawerOpen,
  selectedProject,
  setIsItemImageModalOpen,
  setItemSelected,
  startDate,
  endDate,
  updatedItemImage,
  contactOptions,
  isPriorityCategory,
}) => {
  const api = useMemo(() => new PublishingApi(), [])
  const [marketOptions, setMarketOptions] = useState([])
  const [mediaUnitCounts, setMediaUnitCounts] = useState([])

  const getMediaUnitCountByCategory = async () => {
    try {
      const getMediaUnitCountByCategory =
        await api.getMediaUnitCountsByCategory(projectId)

      setMediaUnitCounts(getMediaUnitCountByCategory)
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  const getMediaUnitCount = (id) =>
    mediaUnitCounts?.find((mu) => mu?.category_id === id)

  const getMarketOptions = async () => {
    try {
      const markets = await api.getMarketOptions()
      setMarketOptions(convertMarketOptions(markets))
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  useEffect(() => {
    getMarketOptions()
    getMediaUnitCountByCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showCategories = () => {
    if (!categoriesSelected?.length) {
      return channelOptions
    }
    return channelOptions.reduce((acc, chan) => {
      if (categoriesSelected.includes(chan)) {
        acc.push(chan)
      }
      return acc
    }, [])
  }

  useEffect(() => {
    if (showEssence) {
      setCategoriesSelected(
        channelOptions.filter((category) => {
          const countCategory = mediaUnitCounts.find(
            (muc) => muc.category_id === category.id
          )
          return countCategory !== undefined && countCategory.count > 0
        })
      )
    } else {
      setCategoriesSelected([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEssence])

  useEffect(() => {
    showCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesSelected])

  return (
    <div className="hc-pa-normal" data-testid="category-section">
      <Grid.Item xs={12}>
        <div className="category-container">
          {showCategories()?.map((categoryInfo) => (
            <Category
              showEssence={showEssence}
              mediaUnitCount={getMediaUnitCount(categoryInfo.id)}
              categoryInfo={categoryInfo}
              projectId={projectId}
              marketOptions={marketOptions}
              categoryOptions={channelOptions}
              key={categoryInfo.id}
              index={categoryInfo.index}
              setSelectedOffer={setSelectedOffer}
              setIsOfferDrawerOpen={setIsOfferDrawerOpen}
              selectedProject={selectedProject}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setItemSelected={setItemSelected}
              startDate={startDate}
              endDate={endDate}
              updatedItemImage={updatedItemImage}
              canEdit={canEdit}
              contactOptions={contactOptions}
              isPriorityCategory={isPriorityCategory}
            />
          ))}
        </div>
      </Grid.Item>
    </div>
  )
}

export default CategorySection
