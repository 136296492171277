export const mapPmm = (pmmArray = []) =>
  pmmArray.map((option, index) => ({
    id: index,
    label: option,
    value: option,
  }))
export const mapPmmContacts = (pmmArray = []) =>
  pmmArray.map((option, index) => ({
    id: index,
    label: option.replace('@target.com', ''),
    value: option.replace('@target.com', ''),
  }))
