import { Dialog, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

let editDummySchema = yup.object().shape({
  edit_dummy_description: yup.string().required('Required'),
  edit_dummy_keyNotes: yup.string(),
  edit_dummy_regPriceCallout: yup.bool(),
  edit_dummy_regUnitRetail: yup.string().matches(/^\d*(?:\.(?!00)\d{2})?$/, {
    message: 'Retail Price in format 99.99',
  }),
})

const EditDummy = (props) => {
  const { editDummy, setEditDummy, putDummy, putCut } = props

  const [isDeleteDialog, setDeleteDialog] = useState(false)

  const wipeState = () => {
    setEditDummy({
      body: {},
      cutId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
    setDeleteDialog(false)
  }

  return editDummy.body ? (
    <Formik
      enableReinitialize
      validationSchema={editDummySchema}
      initialValues={{
        edit_dummy_description: editDummy.body.description || '',
        edit_dummy_keyNotes: editDummy.body.keyNotes || '',
        edit_dummy_regPriceCallout: Boolean(editDummy.body.regPriceCallout),
        edit_dummy_regUnitRetail: editDummy.body.regUnitRetail || '',
      }}
      onSubmit={(values) => {
        putDummy({
          body: {
            description: values.edit_dummy_description,
            id: editDummy.body.id,
            keyNotes: values.edit_dummy_keyNotes,
            lastUpdatedTimestamp: editDummy.body.lastUpdatedTimestamp,
            regPriceCallout: values.edit_dummy_regPriceCallout,
            regUnitRetail: values.edit_dummy_regUnitRetail,
          },
          cutId: editDummy.cutId,
          pageId: editDummy.pageId,
          storyId: editDummy.storyId,
        })
        wipeState()
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        const onDelete = () => {
          putCut({
            body: {
              dummies: [
                {
                  deleted: true,
                  id: editDummy.body.id,
                  lastUpdatedTimestamp: editDummy.body.lastUpdatedTimestamp,
                },
              ],
              id: editDummy.cutId,
              lastUpdatedTimestamp: editDummy.cutLastUpdatedTimestamp,
            },
            pageId: editDummy.pageId,
            storyId: editDummy.storyId,
          })
          handleDialogClose()
        }
        return (
          <Modal
            isVisible={editDummy.show}
            headingText={`Edit Placeholder ${editDummy.body.description}`}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <fieldset disabled={!editDummy.hasEditPrivileges}>
                <Grid.Container align="center" spacing="dense">
                  <Grid.Item xs={12}>
                    <Form.Field
                      id="edit_dummy_description"
                      value={values.edit_dummy_description}
                      label="Placeholder Name"
                      onChange={handleChange}
                      error={errors.edit_dummy_description}
                      errorText={errors.edit_dummy_description}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_dummy_regUnitRetail"
                      value={values.edit_dummy_regUnitRetail}
                      label="Regular Unit Retail"
                      onChange={handleChange}
                      error={errors.edit_dummy_regUnitRetail}
                      errorText={errors.edit_dummy_regUnitRetail}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_dummy_regPriceCallout"
                      type="toggle"
                      value={values.edit_dummy_regPriceCallout}
                      label="Regular $ Callout"
                      onChange={() =>
                        setFieldValue(
                          'edit_dummy_regPriceCallout',
                          !values.edit_dummy_regPriceCallout
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      type="textarea"
                      grow
                      id="edit_dummy_keyNotes"
                      value={values.edit_dummy_keyNotes}
                      label="Key Notes"
                      onChange={handleChange}
                    />
                  </Grid.Item>
                </Grid.Container>
              </fieldset>
              <CancelSubmitButtons
                handleDelete={setDeleteDialog}
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!editDummy.hasEditPrivileges}
                deleteDisabled={!editDummy.hasEditPrivileges}
              />
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText="Are you sure want to delete this Placeholder?"
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText="Delete"
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditDummy
