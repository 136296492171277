import {
  Button,
  Chip,
  ExpandableSection,
  Grid,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import {
  faPencilAlt,
  faMapMarkedAlt,
  faGlasses,
  faPalette,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash.isempty'

import { formatToDateTime } from '../util/MomentUtil'

import ExpandableCutComment from './ExpandableCutComment/index'
import ExpandableDummy from './ExpandableDummy'
import ExpandablePropItem from './ExpandablePropItem'
import SortableItemContainer from './SortableItemContainer'

const ExpandableCut = (props) => {
  const {
    cut,
    followers,
    putCut,
    page,
    story,
    divisions,
    setEditCut,
    setViewCutPatches,
    setViewItemPatches,
    setEditArtPatch,
    setEditItem,
    setEditDummy,
    setEditPropItem,
    expands,
    setExpands,
    hasEditPrivileges,
    lockoutDate,
    eventWeek,
    weeklyAdStartDate,
  } = props

  const hasPrimary = cut.cutPatches.length > 0
  const primary = hasPrimary ? cut.cutPatches[0] : {}
  const expanded = expands.cut[cut.id]
  const promoUnitRetailUpdateAfterLockoutDate =
    lockoutDate && lockoutDate < cut?.promoUnitRetailLastUpdatedTimestamp
  const cutPreviousDefaultPromoUnitRetail = hasPrimary
    ? primary.previousDefaultPromoUnitRetail
    : null

  return (
    <ExpandableSection
      padding="none"
      expanded={expanded}
      onExpand={() => {
        let exp = Object.assign({}, expands)
        exp.cut[cut.id] = !expanded
        setExpands(exp)
      }}
    >
      <Grid.Container align="center" spacing="dense">
        <Grid.Item xs={0.5} align="center">
          {' '}
          <FontAwesomeIcon
            className={`weekly-ad-expand${
              expanded ? 'rotate-clockwise-45' : 'rotate-counter-clockwise-45'
            }`}
            icon={expanded ? faChevronDown : faChevronRight}
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          {cut.artPatchLeaderId === null ? `Cut ${cut.priority + 1}` : null}
        </Grid.Item>
        <Grid.Item xs={2}>
          <Chip
            size="dense"
            style={{
              backgroundColor: cut.division.color,
              border: '0px',
              color: 'white',
            }}
          >
            {cut.division.shortCode.toUpperCase()}
          </Chip>
        </Grid.Item>
        <Grid.Item xs={4}>
          {cut.name}
          {!isEmpty(followers) && (
            <Tooltip location="top" content="Edit Art Patch">
              <Button
                iconOnly
                onClick={(event) => {
                  event.stopPropagation()
                  setEditArtPatch({
                    body: {
                      followers: followers,
                      leader: cut,
                      story,
                    },
                    show: true,
                  })
                }}
              >
                <FontAwesomeIcon icon={faPalette} color={'#000000'} />
              </Button>
            </Tooltip>
          )}
        </Grid.Item>
        <Grid.Item xs={1}>
          {cut.valid && cut.cutPatches.length > 1 ? (
            <Button
              iconOnly
              onClick={(event) => {
                event.stopPropagation()
                setViewCutPatches({
                  body: cut.cutPatches,
                  cutId: cut.id,
                  cutName: cut.name,
                  hasEditPrivileges,
                  lockoutDate: lockoutDate,
                  offers: cut.offers,
                  pageId: page.id,
                  show: true,
                  storyId: story.id,
                })
              }}
            >
              <FontAwesomeIcon icon={faMapMarkedAlt} color={'#000000'} />
            </Button>
          ) : null}
        </Grid.Item>
        <Grid.Item xs={2}>
          {cut.cutHasInvalidOffer && (
            <div
              style={{
                color: 'darkred',
                float: 'right',
                fontStyle: 'italic',
                fontWeight: 'bold',
              }}
            >
              Offers invalid{!cut.valid ? '; ' : ''}
            </div>
          )}
          {!cut.valid && (
            <div
              style={{
                color: 'darkred',
                float: 'right',
                fontStyle: 'italic',
                fontWeight: 'bold',
              }}
            >
              Deactivated Offer
            </div>
          )}
        </Grid.Item>
        <Grid.Item xs={1}>
          <Button
            iconOnly
            onClick={(event) => {
              event.stopPropagation()
              setEditCut({
                body: {
                  copy: primary.copy,
                  coreDataLastUpdatedField: cut.coreDataLastUpdatedField,
                  coreDataLastUpdatedTimestamp:
                    cut.coreDataLastUpdatedTimestamp,
                  divisionId: cut.divisionId,
                  id: cut.id,
                  lastUpdatedTimestamp: cut.lastUpdatedTimestamp,
                  linerOffers: cut.linerOffers,
                  name: cut.name,
                  offerLastLinkedTimestamp: cut.offerLastLinkedTimestamp,
                  offers: cut.offers,
                  primaryPatchIndex: hasPrimary ? primary.marketIndex : null,
                  priority: cut.priority,
                  promoUnitRetail: primary.promoUnitRetail,
                  promoUnitRetailCallout: primary.promoUnitRetailCallout,
                  promoUnitRetailLastUpdatedTimestamp:
                    cut.promoUnitRetailLastUpdatedTimestamp,
                  saleCallout: primary.saleCallout,
                },
                cutValid: cut.valid,
                divisions: divisions,
                followers: followers || [],
                hasEditPrivileges,
                hasItems: Boolean(cut.items.length),
                isArtPatch: Boolean(cut.artPatchLeaderId),
                lockoutDate: lockoutDate,
                page: page,
                show: true,
                storyId: story.id,
                storyLastUpdatedTimestamp: story.lastUpdatedTimestamp,
                weeklyAdStartDate,
              })
            }}
          >
            <FontAwesomeIcon
              icon={hasEditPrivileges ? faPencilAlt : faGlasses}
              color={'#000000'}
            />
          </Button>
        </Grid.Item>
      </Grid.Container>

      <ExpandableSection.Content className="hc-mb-dense hc-pb-dense hc-ml-dense hc-pl-dense hc-mr-dense hc-pr-dense">
        {hasPrimary ? (
          <Grid.Container>
            <Grid.Item xs={12} className="hc-ws-pre-wrap">
              Copy: {primary.copy}
            </Grid.Item>
            <Grid.Item xs={2}>
              <Chip size="dense">
                {primary.saleCallout ? 'SALE' : 'NO SALE'}
              </Chip>
            </Grid.Item>
            <Grid.Item xs={10}>
              Promo $:
              <span
                style={{
                  color: promoUnitRetailUpdateAfterLockoutDate ? 'red' : '',
                }}
              >
                {primary.promoUnitRetailCallout ? (
                  ` ${primary.promoUnitRetail} `
                ) : (
                  <s> {primary.promoUnitRetail}</s>
                )}
              </span>
              {cutPreviousDefaultPromoUnitRetail &&
                ` Was ${cutPreviousDefaultPromoUnitRetail} `}
              {promoUnitRetailUpdateAfterLockoutDate &&
                `Revised: ${formatToDateTime(
                  cut.promoUnitRetailLastUpdatedTimestamp
                )}`}
            </Grid.Item>
          </Grid.Container>
        ) : null}
        <SortableItemContainer
          cut={cut}
          page={page}
          putCut={putCut}
          expands={expands}
          setExpands={setExpands}
          setEditItem={setEditItem}
          setViewItemPatches={setViewItemPatches}
          ids={{ cutId: cut.id, pageId: page.id, storyId: story.id }}
          hasEditPrivileges={hasEditPrivileges}
        />
        {cut.dummies.map((dummy) => (
          <ExpandableDummy
            key={dummy.id}
            page={page}
            dummy={dummy}
            cut={cut}
            setEditDummy={setEditDummy}
            expands={expands}
            setExpands={setExpands}
            ids={{ cutId: cut.id, pageId: page.id, storyId: story.id }}
            hasEditPrivileges={hasEditPrivileges}
          />
        ))}
        {cut.props.map((prop) => (
          <ExpandablePropItem
            key={prop.id}
            propItem={prop}
            page={page}
            cut={cut}
            setEditPropItem={setEditPropItem}
            expands={expands}
            setExpands={setExpands}
            ids={{ cutId: cut.id, pageId: page.id, storyId: story.id }}
            hasEditPrivileges={hasEditPrivileges}
          />
        ))}
        <ExpandableCutComment
          cut={cut}
          page={page}
          story={story}
          eventWeek={eventWeek}
          hasEditPrivileges={hasEditPrivileges}
        />
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}
export default ExpandableCut
