import { Table, Button, Heading, Chip } from '@enterprise-ui/canvas-ui-react'
import { isEmpty } from 'lodash'
import { useState, useEffect } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import CatalogApi from '../api/pop-catalog/CatalogApi'
import DragHandle from '../components/DragHandle'
import EditCatalogPage from '../forms/EditCatalogPage'
import { CategoryColorMap } from '../util/CategoryColorMap'
import { canEditPastCatalogs, canEditUpcomingCatalogs } from '../util/CheckRole'
import ReorderArray from '../util/ReorderArray'

const CatalogPaginationTable = (props) => {
  const { catalog, setCatalog, role, archive } = props

  const [isVisible, setIsVisible] = useState(false)
  const [selectedPage, setSelectedPage] = useState(null)
  const [pageIdx, setPageIdx] = useState({})
  const [isReordering, setIsReordering] = useState(false)
  const catalogApi = new CatalogApi()
  const pages = catalog?.pages
  const editAccess = archive
    ? role.some((r) => canEditPastCatalogs(r))
    : role.some((r) => canEditUpcomingCatalogs(r))

  useEffect(() => {
    if (catalog?.pages) {
      setPageIndexes(catalog?.pages)
    }
  }, [catalog])

  const setPageIndexes = (curPages) => {
    const newIdx = {}
    curPages.forEach((curPage, idx) => {
      newIdx[curPage.id] = idx
    })
    setPageIdx(newIdx)
  }

  function onSortEnd({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      const p = ReorderArray(pages, oldIndex, newIndex, '', true)
      let editing = false
      p.forEach((currPage, idx) => {
        if (currPage.pageOrdering !== idx) {
          editing = true
        }
      })
      setIsReordering(editing)
      setPageIndexes(p)
    }
  }

  const CategoryChip = ({ category }) => {
    var chips = []
    for (var i = 0; i < category?.length; i++) {
      chips.push(
        <Chip
          size="dense"
          style={{
            backgroundColor: CategoryColorMap[category[i]],
            border: '0px',
            color: 'white',
          }}
        >
          {category[i]}
        </Chip>
      )
      if (i === 2 && category.length > 3) {
        chips.push(<div style={{ paddingBottom: '5px' }}></div>)
      }
    }
    return chips
  }

  const SortableItem = SortableElement(({ page }) => (
    <Table.Row
      disabled={!editAccess}
      onClick={() => {
        setIsVisible(true)
        setSelectedPage(page)
      }}
      style={
        (!editAccess && {
          color: 'black',
          cursor: 'default',
          display: 'flex',
        }) || { display: 'flex' }
      }
      className="hc-ta-center"
    >
      <Table.Header className="hc-ta-center" xs={1}>
        {' '}
        {editAccess && <DragHandle />}
      </Table.Header>
      <Table.Header xs={1}> {page.pageOrdering + 1} </Table.Header>
      <Table.Data xs={2}>
        <CategoryChip category={page?.category} />
      </Table.Data>
      <Table.Header xs={1}>{page.section.join(', ')}</Table.Header>
      <Table.Data xs={1}> {page.artDirector.join(', ')} </Table.Data>
      <Table.Data xs={1}> {page.copyWriter.join(', ')} </Table.Data>
      <Table.Data xs={1}> {page.merchant.join(', ')} </Table.Data>
      <Table.Header xs={2}>{page.notes}</Table.Header>
    </Table.Row>
  ))
  const SortableList = SortableContainer(({ pages }) => (
    <Table.Body>
      {pages?.map((page, index) => (
        <SortableItem key={`page-${page.id}`} index={index} page={page} />
      ))}
    </Table.Body>
  ))

  return !isEmpty(pages) ? (
    <div>
      <Table>
        <Table.Row>
          <Table.Header xs={1}></Table.Header>
          <Table.Header xs={1}>Page Number</Table.Header>
          <Table.Header xs={2}>Category</Table.Header>
          <Table.Header xs={1}>Section</Table.Header>
          <Table.Header xs={1}>Art Director</Table.Header>
          <Table.Header xs={1}>Copy Writer</Table.Header>
          <Table.Header xs={1}>Merchant</Table.Header>
          <Table.Header xs={2}>Notes</Table.Header>
        </Table.Row>
        <SortableList useDragHandle pages={pages} onSortEnd={onSortEnd} />
      </Table>
      <EditCatalogPage
        catalog={catalog}
        show={isVisible}
        setShow={setIsVisible}
        page={selectedPage}
        setCatalog={setCatalog}
        role={role}
      />
      {isReordering ? (
        <div>
          <Button
            type="primary"
            style={{ bottom: '20px', position: 'fixed', right: '20px' }}
            onClick={() => {
              const pageDifference = []
              pages.forEach((curPage) => {
                if (curPage.pageOrdering !== pageIdx[curPage.id]) {
                  pageDifference.push({
                    id: curPage.id,
                    last_updated_timestamp: curPage.lastUpdatedTimestamp,
                    page_ordering: pageIdx[curPage.id],
                  })
                }
              })
              catalogApi
                .putCatalog(catalog.id, {
                  id: catalog.id,
                  last_updated_timestamp: catalog.lastUpdatedTimestamp,
                  pages: pageDifference,
                })
                .then((response) => setCatalog(response))
              setIsReordering(false)
            }}
          >
            Reorder Pages
          </Button>
          <Button
            type="secondary"
            style={{
              background: 'white',
              bottom: '20px',
              position: 'fixed',
              right: '190px',
            }}
            onClick={() => {
              pages.sort((a, b) => a.pageOrdering - b.pageOrdering)
              setPageIndexes(pages)
              setIsReordering(false)
            }}
          >
            Cancel Reorder
          </Button>
        </div>
      ) : null}
    </div>
  ) : (
    <Heading className="hc-ta-center hc-pt-expanded">
      No pages currently available.
    </Heading>
  )
}
export default CatalogPaginationTable
