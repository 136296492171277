import { Table, Button, Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  FileIcon,
  CameraIcon,
  ListUnorderedIcon,
} from '@enterprise-ui/icons'
import '../scss/catalog-table.scss'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

const CatalogTable = ({ catalogs, archive }) =>
  !isEmpty(catalogs) && (
    <>
      <Table>
        <Table.Row>
          <Table.Header xs={3}>Name</Table.Header>
          <Table.Header xs={3}>Start Date</Table.Header>
          <Table.Header xs={3}>End Date</Table.Header>
        </Table.Row>
        <Table.Body className="POP-table">
          {catalogs?.map((catalog) => (
            <Table.Row
              id={catalog.id}
              key={catalog.id}
              align="center"
              clickable
              as={Link}
              to={{
                pathname: `/catalog/${catalog.id}`,
              }}
              state={{ archive: archive }}
            >
              <Table.Data xs={3}>{catalog.name}</Table.Data>
              <Table.Data xs={3}>{catalog.runDate}</Table.Data>
              <Table.Data xs={3}>{catalog.endDate}</Table.Data>
              <Table.Data xs={3}>
                <Grid.Container>
                  <Grid.Item>
                    <Tooltip content="Product List View" location="top">
                      <Button
                        aria-label="product list view"
                        type="primary"
                        iconOnly
                        as={Link}
                        to={'/catalog/' + catalog.id + '/product-list'}
                      >
                        <EnterpriseIcon icon={ListUnorderedIcon} />
                      </Button>
                    </Tooltip>
                  </Grid.Item>
                  <Grid.Item>
                    <Tooltip content="Creative Page View" location="top">
                      <Button
                        aria-label="creative page view"
                        type="primary"
                        iconOnly
                        as={Link}
                        to={'/catalog/' + catalog.id + '/creative'}
                      >
                        <EnterpriseIcon icon={FileIcon} />
                      </Button>
                    </Tooltip>
                  </Grid.Item>
                  <Grid.Item>
                    <Tooltip content="Image and Sample View" location="top">
                      <Button
                        aria-label="image and sample view"
                        type="primary"
                        iconOnly
                        as={Link}
                        to={'/catalog/' + catalog.id + '/sample'}
                      >
                        <EnterpriseIcon icon={CameraIcon} />
                      </Button>
                    </Tooltip>
                  </Grid.Item>
                </Grid.Container>
              </Table.Data>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
export default CatalogTable
