import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useEffect, useState } from 'react'

import { formatChannels } from '../../../src/ImageTracking/util/ItemUtil'

const MarketingChannelSelect = ({
  selectedMarketingChannelFilter,
  setMarketingChannelFilter,
  channels,
  handleFilterUpdate,
  disabled,
  label,
}) => {
  const [currentSelectedChannels, setCurrentSelectedChannels] = useState(
    selectedMarketingChannelFilter?.length > 0
      ? formatChannels(selectedMarketingChannelFilter)
      : []
  )
  useEffect(() => {
    setCurrentSelectedChannels(formatChannels(selectedMarketingChannelFilter))
  }, [selectedMarketingChannelFilter])

  const channelOptions = channels?.map((channel = {}) => ({
    id: channel.channel_id,
    label: channel.channel_name,
    value: channel.channel_name,
  }))

  return (
    <Autocomplete
      allowCustomInputValue={true}
      id="marketingChannelSelect"
      multiselect
      chipHeight="dense"
      label={label === '' ? label : 'Channel'}
      value={currentSelectedChannels}
      onUpdate={(_, value) => {
        if (currentSelectedChannels && currentSelectedChannels !== value) {
          setCurrentSelectedChannels(value)
          setMarketingChannelFilter(value)
          handleFilterUpdate && handleFilterUpdate(_, value)
        }
      }}
      options={channelOptions}
      placeholder="Choose Channel"
      data-testid="marketingChannelSelect"
      disabled={disabled}
    />
  )
}

export default MarketingChannelSelect
