import axios from 'axios'

import appConfig from '../../config/appConfig'
import paginationMapper from '../../mappers/paginationMapper'
import weeklyAdMapper from '../../mappers/weeklyAdMapper'
export default class HistoryApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_history_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.root,
      timeout: 31000,
    })
    return this.client
  }

  getWeeklyAds = async (startDate, endDate) => {
    const query = new URLSearchParams()
    query.set('startDate', startDate)
    query.set('endDate', endDate)

    return await this.init_history_api()
      .get(this.config.backend.weeklyAd + 'search?' + query.toString())
      .then((res) => res.data.map((ad) => weeklyAdMapper(ad)))
      .catch((e) => console.log(e))
  }

  getItemHistory = async (wadId, query) => {
    query.set('wadId', wadId)
    return await this.init_history_api()
      .get(this.config.backend.weeklyAdItemHistory + `?${query.toString()}`)
      .then((res) => paginationMapper(res.data, null, 'history'))
      .catch((e) => console.log(e))
  }
}
