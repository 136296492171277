import {
  TimelineItem,
  Table,
  Input,
  Spinner,
  Chip,
} from '@enterprise-ui/canvas-ui-react'
import isEmpty from 'lodash.isempty'
import { useState } from 'react'

import HistoryApi from '../api/pop-catalog/HistoryApi'
import { DIVISION_LIST } from '../util/DivisionUtils'
import GetPageTitle from '../util/GetPageTitle'
import { formatToDateTime } from '../util/MomentUtil'

const CustomTimelineItem = (props) => {
  const { history: hist, isAllowed, type = 'product' } = props

  const data = getFormattedHistory(hist.update)
  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState(hist)
  const historyApi = new HistoryApi()

  const getPageOrdering = () => {
    if (Array.isArray(hist.pageOrdering)) {
      return !isEmpty(hist.pageOrdering)
        ? hist.pageOrdering
            .map((element) => element + 1)
            .sort()
            .join(' & ')
        : 'TBD'
    }
    return GetPageTitle({
      bookOnePageNumber: hist.bookOnePageNumber,
      bookTwoPageNumber: hist.bookTwoPageNumber,
    })
  }

  const isProduct = type === 'product'

  return (
    <TimelineItem type="success">
      <div className="timeline-item">
        {isAllowed ? (
          loading ? (
            <Spinner className="timeline-spinner" size="dense" />
          ) : (
            <Input.Checkbox
              id={`hist-checkbox-${hist.id}`}
              className="hc-clr-grey04 timeline-approved"
              type="checkbox"
              checked={history.approved}
              onClick={() => {
                setLoading(true)
                historyApi
                  .putProductHistory(hist.id, {
                    approved: !history.approved,
                    lastUpdatedTimestamp: history.lastUpdatedTimestamp,
                    updatedBy: history.updatedBy,
                  })
                  .then((res) => {
                    setHistory(res)
                  })
                  .catch(() => setHistory((oldHistory) => oldHistory))
                  .finally(() => setLoading(false))
              }}
            />
          )
        ) : null}
        <h3 className="timeline-item--date">
          {formatToDateTime(history.lastUpdatedTimestamp)}
        </h3>
        <h5 className="timeline-item--title">{history.updatedBy}</h5>
        <div className="timeline-item--content">
          {isProduct ? (
            <h5 className="timeline-item--title">
              Product name:{' '}
              <span className="timeline-item--value">
                {history.productName},
              </span>
            </h5>
          ) : (
            <h5 className="timeline-item--title">
              Item description:{' '}
              <span className="timeline-item--value">
                {history.itemDescription},
              </span>
            </h5>
          )}
          <h5 className="timeline-item--title">
            DPCI: <span className="timeline-item--value">{history.dpci},</span>
          </h5>
          <h5 className="timeline-item--title">
            Page:{' '}
            <span className="timeline-item--value">{getPageOrdering()}</span>
          </h5>
        </div>
        {!isProduct ? (
          <div>
            {!isEmpty(hist.divisions)
              ? hist.divisions.map((divisionId) => {
                  const divElement = DIVISION_LIST.find(
                    (dv) => dv.id === divisionId
                  )

                  if (divElement) {
                    return (
                      <Chip
                        size="dense"
                        className="hc-mb-dense"
                        key={divElement.id}
                        style={{
                          backgroundColor: divElement.color,
                          border: '0px',
                          color: 'white',
                        }}
                      >
                        {divElement?.shortCode?.toUpperCase()}
                      </Chip>
                    )
                  }
                  return null
                })
              : null}
          </div>
        ) : null}
        <Table alternateRowColor={false}>
          <Table.Head>
            <Table.Row>
              <Table.Header xs={3}>
                <strong>Field</strong>
              </Table.Header>
              <Table.Header xs={3}>
                <strong>Old Value</strong>
              </Table.Header>
              <Table.Header xs={3}>
                <strong>New Value</strong>
              </Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body className="POP-table">
            {data?.map((row, idx) => (
              <Table.Row key={idx} align="center" clickable={false}>
                <Table.Data xs={3}>{row.field}</Table.Data>
                <Table.Data xs={3}>{row.oldVal}</Table.Data>
                <Table.Data xs={3}>{row.newVal}</Table.Data>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </TimelineItem>
  )
}

function getFormattedHistory(history) {
  const fields = history.split('||')
  const data = fields.map((field) => {
    const data = field.split('~')

    return { field: data[0], newVal: data[2], oldVal: data[1] }
  })
  return data
}
export default CustomTimelineItem
