import marketMapper from './marketMapper'

const cutPatchMapper = (patch) => ({
  copy: patch.copy,
  cutId: patch.cutId,
  defaultPromoUnitRetail: patch.defaultPromoUnitRetail,
  defaultPromoUnitRetailUpdatedTimestamp:
    patch.defaultPromoUnitRetailUpdatedTimestamp,
  deleted: patch.deleted,
  id: patch.id,
  lastUpdatedTimestamp: patch.lastUpdatedTimestamp,
  marketIndex: patch.marketIndex,
  markets: patch.markets
    ? patch.markets.map(marketMapper).sort((a, b) => a.priority - b.priority)
    : [],
  previousDefaultPromoUnitRetail: patch.previousDefaultPromoUnitRetail,
  priority: patch.priority,
  promoMessaging: patch.promoMessaging,
  promoUnitRetail: patch.defaultPromoUnitRetail,
  promoUnitRetailCallout: patch.promoUnitRetailCallout,
  promoUnitRetailLastUpdatedTimestamp:
    patch.promoUnitRetailLastUpdatedTimestamp,
  saleCallout: patch.saleCallout,
})
export default cutPatchMapper
