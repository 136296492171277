const offerItemMapper = (ivyItem) => ({
  deleted: ivyItem.deleted,
  description: ivyItem.description,
  dpci: ivyItem.dpci,
  offerId: ivyItem.offerId,
  priority: ivyItem.ivyPriority,
  promoUnitRetail: ivyItem.promoUnitRetail,
  tcin: ivyItem.tcin,
})
export default offerItemMapper
