import { Dialog, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import CatalogApi from '../api/pop-catalog/CatalogApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { isAdminOrMarketing } from '../util/CheckRole'
import { formatToServer, formatToUi } from '../util/MomentUtil'

let edit_catalogSchema = yup.object().shape({
  creativeCaptain: yup.array(),
  end_date: yup
    .string()
    .required('Required')
    .test(
      'valid end date',
      'End Date must occur after the Run Date',
      function (end_date) {
        return end_date > this.parent.run_date
      }
    ),
  merchantCaptain: yup.array(),
  name: yup.string().required('Required'),
  projectCaptain: yup.array(),
  run_date: yup.string().required('Required'),
})

const EditCatalog = (props) => {
  const { catalog, setCatalog, show, setShow, role } = props
  const api = new CatalogApi()
  const navigate = useNavigate()
  const [isDeleteDialog, setDeleteDialog] = useState(false)
  const wipeState = () => {
    setDeleteDialog(false)
    setShow(false)
  }

  return show ? (
    <Formik
      initialValues={{
        creative_captain: formCaptains(catalog?.creativeCaptain) || [],
        end_date: formatToUi(catalog.endDate) || '',
        merchant_captain: formCaptains(catalog?.merchantCaptain) || [],
        name: catalog.name || '',
        project_captain: formCaptains(catalog?.projectCaptain) || [],
        run_date: formatToUi(catalog.runDate) || '',
      }}
      validationSchema={edit_catalogSchema}
      onSubmit={(values, funcs) => {
        const creativeCaptainValue = values?.creative_captain?.map(
          (creative_captain) => creative_captain.value
        )
        const merchantCaptainValue = values?.merchant_captain?.map(
          (merchant_captain) => merchant_captain.value
        )
        const projectCaptainValue = values?.project_captain?.map(
          (project_captain) => project_captain.value
        )
        api
          .putCatalog(catalog.id, {
            creative_captain: creativeCaptainValue,
            end_date: formatToServer(values.end_date),
            id: catalog.id,
            last_updated_timestamp: catalog.lastUpdatedTimestamp,
            merchant_captain: merchantCaptainValue,
            name: values.name,
            project_captain: projectCaptainValue,
            run_date: formatToServer(values.run_date),
          })
          .then((response) => {
            funcs.resetForm()
            wipeState()
            setCatalog(response)
          })
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
        submitCount,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }

        const onDelete = () => {
          api.deleteCatalog(catalog.id).then(() => {
            handleDialogClose()
            navigate('/catalog')
          })
        }
        return (
          <Modal
            isVisible={show}
            headingText="Edit Catalog"
            onRefuse={handleDialogClose}
            data-testid="modalContainer"
          >
            <div className="hc-pa-normal">
              <Grid.Container spacing="dense">
                <Grid.Container spacing="dense">
                  <Grid.Item md={6} xs={12}>
                    <DatePicker
                      required
                      id="run_date"
                      label="Run Date"
                      value={values.run_date}
                      onUpdate={setFieldValue}
                      error={errors.run_date}
                      errorText={errors.run_date}
                      location="bottom-left-left"
                    />
                  </Grid.Item>
                  <Grid.Item md={6} xs={12}>
                    <DatePicker
                      required
                      id="end_date"
                      label="End Date"
                      value={values.end_date}
                      error={errors.end_date}
                      errorText={errors.end_date}
                      onUpdate={setFieldValue}
                      location="bottom-left-left"
                    />
                  </Grid.Item>
                  <Grid.Item md={6} xs={12}>
                    <Form.Field
                      id="name"
                      label="Catalog Name"
                      onChange={handleChange}
                      value={values.name}
                      error={errors.name && (submitCount > 0 || touched.name)}
                      onBlur={handleBlur}
                      errorText={errors.name}
                    />
                  </Grid.Item>
                  <Grid.Item md={6} xs={12}>
                    <Autocomplete
                      id="creative_captain"
                      multiselect
                      allowCustomInputValue={true}
                      chipHeight="dense"
                      className="hc-mb-dense hc-mt-dense"
                      onUpdate={(_, value) =>
                        setFieldValue('creative_captain', value)
                      }
                      value={values.creative_captain}
                      options={values.creative_captain}
                      label="Creative Captain"
                      noResultsMessage="Use Outlook Name"
                    />
                  </Grid.Item>
                  <Grid.Item md={6} xs={12}>
                    <Autocomplete
                      id="merchant_captain"
                      multiselect
                      allowCustomInputValue={true}
                      chipHeight="dense"
                      className="hc-mb-dense hc-mt-dense"
                      onUpdate={(_, value) =>
                        setFieldValue('merchant_captain', value)
                      }
                      value={values.merchant_captain}
                      options={values.merchant_captain}
                      label="Merchant Captain"
                      noResultsMessage="Use Outlook Name"
                    />
                  </Grid.Item>
                  <Grid.Item md={6} xs={12}>
                    <Autocomplete
                      id="project_captain"
                      multiselect
                      allowCustomInputValue={true}
                      chipHeight="dense"
                      className="hc-mb-dense hc-mt-dense"
                      onUpdate={(_, value) =>
                        setFieldValue('project_captain', value)
                      }
                      value={values.project_captain}
                      options={values.project_captain}
                      label="Project Captain"
                      noResultsMessage="Use Outlook Name"
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                canDelete={isAdminOrMarketing(role)}
                handleDelete={setDeleteDialog}
              />
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText={'Are you sure want to delete this Catalog?'}
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText={'Delete'}
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}

function formCaptains(captain) {
  return captain?.map((creative_captain, idx) => ({
    id: idx,
    label: creative_captain,
    value: creative_captain,
  }))
}
export default EditCatalog
