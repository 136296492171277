import { Divider, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { isEmpty } from 'lodash'
import { useState } from 'react'

import ExpandableProduct from '../components/ExpandableProduct'
import AssignProductToPage from '../forms/AssignProductToPage'
import { inCreativePageView } from '../util/CheckLocation'
import { sortProductByStatus } from '../util/ProductListUtils'

const ProductList = (props) => {
  const {
    catalog = {},
    canEdit = false,
    products = {},
    setProducts,
    isAssignPage = true,
    page = {},
    role = '',
    view = '',
    isStatusUpdate = false,
    selectedProducts = [],
    setSelectedProducts,
    isBulkProductModal = false,
    approvedVendors,
  } = props
  const [assignProduct, setAssignProduct] = useState({
    product: {},
    show: false,
  })
  const sortedProducts = inCreativePageView(view)
    ? sortProductByStatus(products)
    : products
  return !isEmpty(products) ? (
    <>
      {sortedProducts.map((product) => (
        <Grid.Item xs={12} key={product.id}>
          <ExpandableProduct
            approvedVendors={approvedVendors}
            canEdit={canEdit}
            view={view}
            product={product}
            setAssignProduct={setAssignProduct}
            isAssignPage={isAssignPage}
            page={page}
            role={role}
            catalog={catalog}
            setProducts={setProducts}
            isStatusUpdate={isStatusUpdate}
            setSelectedProducts={setSelectedProducts}
            isProductSelected={Boolean(selectedProducts[product.id])}
            isBulkProductModal={isBulkProductModal}
          />
          <Divider />
        </Grid.Item>
      ))}
      {view === 'product' && (
        <AssignProductToPage
          pages={catalog?.pages}
          assignProduct={assignProduct}
          setAssignProduct={setAssignProduct}
          setProducts={setProducts}
        />
      )}
    </>
  ) : (
    <div className="hc-pa-normal">
      <Heading className="hc-ta-center hc-pt-expanded">
        No products currently available..
      </Heading>
    </div>
  )
}
export default ProductList
