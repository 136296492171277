import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  bulletPoint: {
    fontSize: 10,
  },
  container: {
    flex: 4,
    paddingLeft: 0,
    paddingTop: 10,
  },
  date: {
    fontSize: 11,
  },
  detailLeftColumn: {
    backgroundColor: '#dddddd',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    margin: 'auto',
    paddingLeft: 10,
  },
  detailRightColumn: {
    borderBottom: '1px solid black',
    display: 'flex',
    flex: 8,
    flexDirection: 'column',
    margin: 'auto',
    paddingLeft: 10,
  },
  details: {
    fontSize: 10,
  },
  detailsContainer: {
    flexDirection: 'row',
    marginBottom: 2,
    padding: 2,
  },
  entryContainer: {
    marginBottom: 10,
  },
  headerContainer: {
    borderBottom: '1px solid #000000',
    flexDirection: 'row',
    marginBottom: 10,
    paddingBottom: 10,
  },
  mediaUnitContainerRows: {
    borderBottom: '1px solid black',
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
  },
  mediaUnitContainerRowsInfo: {
    display: 'flex',
    flex: 8,
    justifyContent: 'center',
    paddingLeft: 4,
  },
  mediaUnitContainerRowsInfoURL: {
    display: 'flex',
    flex: 8,
    justifyContent: 'center',
    minHeight: 30,
    paddingLeft: 4,
  },
  mediaUnitContainerRowsLabel: {
    backgroundColor: 'lightgrey',
    borderRight: '1px solid black',
    display: 'flex',
    flex: 2,
    fontWeight: '700',
    justifyContent: 'center',
    paddingLeft: 4,
  },
  title: {
    color: 'black',
    flexWrap: 'wrap',
    fontSize: 14,
    maxLines: 3,
    textDecoration: 'none',
    textOverflow: 'ellipsis',
  },
  version: {
    color: 'black',
    fontSize: 20,
    textDecoration: 'none',
  },
})

const returnMarkets = (markets) => {
  if (markets !== undefined && markets.length > 0) {
    return markets.length === 31
      ? ' -  All Markets'
      : markets.length === 29
      ? ' -  All Mainland Markets'
      : markets.length === 0
      ? ' -  No Markets Selected'
      : ' -  ' + markets.map((market) => market.name).join(', ')
  }
  return ''
}

const convertChannel = (id) => {
  switch (id) {
    case 10:
      return 'Audio'
    case 9:
      return 'Video'
    case 15:
      return null //ignore circle
    case 8:
      return 'Banner'
    default:
      return 'Unknown Id: ' + id
  }
}

const MediaUnitDetails = ({ mediaUnit, versionNumber }) => {
  const version = `Version ${versionNumber} ${returnMarkets(mediaUnit.markets)}`
  const channels = mediaUnit.channels
    .map((channel) => convertChannel(channel.channel_id))
    .join(', ')

  return (
    <View style={styles.entryContainer}>
      <View style={styles.headerContainer}>
        <Text style={styles.version}>{version}</Text>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.detailLeftColumn}>
          <Text style={styles.title}>EYEBROW</Text>
        </View>
        <View style={styles.detailRightColumn}>
          <Text style={styles.title}>
            {mediaUnit.eyebrow ? mediaUnit.eyebrow : ' '}
          </Text>
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.detailLeftColumn}>
          <Text style={styles.title}>FEATURE OFFER</Text>
        </View>
        <View style={styles.detailRightColumn}>
          <Text style={styles.title}>
            {mediaUnit.feature_offer ? mediaUnit.feature_offer : ' '}
          </Text>
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.detailLeftColumn}>
          <Text style={styles.title}>BODY COPY</Text>
        </View>
        <View style={styles.detailRightColumn}>
          <Text style={styles.title}>
            {mediaUnit.body_copy ? mediaUnit.body_copy : ' '}
          </Text>
        </View>
      </View>

      <View style={styles.detailsContainer}>
        <View style={styles.detailLeftColumn}>
          <Text style={styles.title}>CHANNELS</Text>
        </View>
        <View style={styles.detailRightColumn}>
          <Text style={styles.title}>{channels ? channels : ' '}</Text>
        </View>
      </View>

      <View style={styles.detailsContainer}>
        <View style={styles.detailLeftColumn}>
          <Text style={styles.title}>NOTES FOR CREATIVE</Text>
        </View>
        <View style={styles.detailRightColumn}>
          <Text style={styles.title}>
            {mediaUnit.notes ? mediaUnit.notes : ' '}
          </Text>
        </View>
      </View>
    </View>
  )
}

const Details = (props) => {
  const { mediaUnit, versionNumber } = props

  return (
    <View style={styles.container}>
      <MediaUnitDetails mediaUnit={mediaUnit} versionNumber={versionNumber} />
    </View>
  )
}

export default Details
