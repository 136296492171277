import DateFnsUtils from '@date-io/date-fns'
import { Heading, Layout, Table } from '@enterprise-ui/canvas-ui-react'
import { LocalizationProvider } from '@mui/lab'
import { Grid, LinearProgress } from '@mui/material'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import PublishingApi from '../../api/creative-hub/PublishingApi'
import appConfig from '../../config/appConfig'
import { IvyChannels } from '../../creativeHubGlobal/util/CreativeHubGlobalUtil'
import { handleChangeBoostedIds } from '../util/GroupedOfferHelper'
import { GROUPED_OFFER_CATEGORY } from '../util/GroupedOfferUtils'

import { ImageCard } from './ImageCard'

const theme = createTheme({
  palette: {
    primary: {
      main: '#33691e',
    },
    secondary: {
      main: '#bdbdbd',
    },
  },
})

const useStyles = makeStyles({
  header: {
    fontWeight: 'bold',
  },
  heading: {
    margin: '30px 0 40px 0',
  },
  margin: 0,
  offerDetails: {
    background: '#FFFFFF',
  },
  padding: 0,
})

export const GroupedOfferDetails = (props) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [refreshData, setRefreshData] = useState(true)
  const [offers, setOffers] = useState(true)
  const [selectedBoostedIds, setSelectedBoostedIds] = useState(true)
  const [groupedOffer, setGroupedOffer] = useState([])
  const { groupId = '' } = useParams()
  const mediaUnitApi = useMemo(() => new PublishingApi(), [])
  const config = appConfig()

  useEffect(() => {
    if (refreshData) {
      setIsLoading(true)
      mediaUnitApi
        .getMediaUnitDetails(groupId, GROUPED_OFFER_CATEGORY)
        .then((response) => {
          const { boosted_tcins = '' } = response
          setGroupedOffer(response)

          //remove spacing between commas in string
          let boostedIds = boosted_tcins.split(',').map((value) => value.trim())

          handleChangeBoostedIds(
            boostedIds,
            boostedIds,
            null,
            setSelectedBoostedIds
          )
          setOffers(response.offers)
        })
        .finally(() => {
          setRefreshData(false)
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const offerRow = (offer, index) => (
    <Table.Row
      data-testid={'offer-row-' + index}
      key={'offer-' + offer.ivy_offer_id + '-' + index}
    >
      <Table.Data key={'id-' + offer.ivy_offer_id} xs={1}>
        <a
          target="_blank"
          href={`${config.ivy.uiUrl}promotion/${offer.ivy_offer_id}/edit/general`}
          rel="noreferrer"
          className="offerLink"
        >
          {offer.ivy_offer_id}
        </a>
      </Table.Data>
      <Table.Data key={'status-' + offer.ivy_offer_id} xs={2}>
        {offer.status}
      </Table.Data>
      <Table.Data key={'channel-' + offer.ivy_offer_id} xs={2}>
        {IvyChannels[offer.ivy_channel]}
      </Table.Data>
      <Table.Data key={'start-date-' + offer.ivy_offer_id} xs={2}>
        {new Date(offer.start_date).toDateString()}
      </Table.Data>
      <Table.Data key={'end-date-' + offer.ivy_offer_id} xs={2}>
        {new Date(offer.end_date).toDateString()}
      </Table.Data>
      <Table.Data key={'name-' + offer.ivy_offer_id} xs={3}>
        {offer.name}
      </Table.Data>
    </Table.Row>
  )

  return (
    <div className={classes.offerDetails}>
      <Layout.Body data-testid="homePage" includeRail>
        {isLoading ? (
          <div data-testid="loadingContainer" className="hc-pa-normal">
            <LinearProgress />
          </div>
        ) : (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={DateFnsUtils}>
                <Grid container className="weeklyad-create">
                  <Grid item xs={12}>
                    <Heading size={3} className={classes.heading}>
                      GO Id: {groupedOffer.go_id}
                    </Heading>
                  </Grid>
                  <Grid item xs={12}>
                    <strong>Created By:</strong> {groupedOffer.created_by}
                  </Grid>
                  {groupedOffer.title ? (
                    <Grid item xs={12}>
                      <strong>Title:</strong> {groupedOffer.title}
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Grid container>
                      {offers?.length > 0 ? (
                        <Grid item xs={12}>
                          <Heading size={4} className={classes.heading}>
                            Offer Id Details
                          </Heading>
                          <Table style={{ marginBottom: '20px' }}>
                            <Table.Head>
                              <Table.Row
                                key={'header-row' + groupedOffer.ivy_offer_id}
                              >
                                <Table.Header
                                  key={'header-id' + groupedOffer.id}
                                  xs={1}
                                  className={classes.header}
                                >
                                  Offer Id
                                </Table.Header>
                                <Table.Header
                                  key={'header-status' + groupedOffer.id}
                                  xs={2}
                                  className={classes.header}
                                >
                                  Status
                                </Table.Header>
                                <Table.Header
                                  key={'header-channel' + groupedOffer.id}
                                  xs={2}
                                  className={classes.header}
                                >
                                  Qualifying Channel
                                </Table.Header>
                                <Table.Header
                                  key={'header-start' + groupedOffer.id}
                                  xs={2}
                                  className={classes.header}
                                >
                                  Start Date
                                </Table.Header>
                                <Table.Header
                                  key={'header-end' + groupedOffer.id}
                                  xs={2}
                                  className={classes.header}
                                >
                                  End Date
                                </Table.Header>
                                <Table.Header
                                  key={'header-name' + groupedOffer.id}
                                  xs={3}
                                  className={classes.header}
                                >
                                  Offer Name
                                </Table.Header>
                              </Table.Row>
                            </Table.Head>
                            <Table.Body>
                              {offers.map((offer, index) =>
                                offerRow(offer, index)
                              )}
                            </Table.Body>
                          </Table>
                        </Grid>
                      ) : null}
                      {selectedBoostedIds?.length > 0 ? (
                        <Grid item xs={12}>
                          <Heading size={4} className={classes.heading}>
                            Boosted TCIN Images
                          </Heading>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selectedBoostedIds?.map((tcin) => (
                              <ImageCard
                                key={tcin.id}
                                id={tcin.id}
                                tcin={tcin}
                                canDisplayCheckBox={false}
                              />
                            ))}
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        )}
      </Layout.Body>
    </div>
  )
}
