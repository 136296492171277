import { Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import * as yup from 'yup'

import CatalogApi from '../api/pop-catalog/CatalogApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { formatToServer } from '../util/MomentUtil'

let add_catalogSchema = yup.object().shape({
  endDate: yup
    .string()
    .required('Required')
    .test(
      'valid end date',
      'End Date must occur after the Run Date',
      function (endDate) {
        return endDate > this.parent.runDate
      }
    ),
  name: yup.string().required('Required'),
  pageCount: yup
    .number()
    .typeError('Page Quantity has invalid number')
    .required('Required'),
  runDate: yup.string().required('Required'),
})

const AddCatalog = (props) => {
  const { show, setShow, setCatalogs } = props
  const api = new CatalogApi()

  const wipeState = () => {
    setShow(false)
  }
  return show ? (
    <Formik
      initialValues={{
        endDate: '',
        name: '',
        pageCount: '',
        runDate: '',
      }}
      validationSchema={add_catalogSchema}
      onSubmit={(values, funcs) => {
        api
          .postCatalog({
            end_date: formatToServer(values.endDate),
            name: values.name,
            page_count: values.pageCount,
            run_date: formatToServer(values.runDate),
          })
          .then((response) => {
            funcs.resetForm()
            wipeState()
            setCatalogs((cat) => cat.concat(response))
          })
          .catch((e) => {
            funcs.resetForm()
            wipeState()
          })
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
        submitCount,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={show}
            headingText="Create Catalog"
            onRefuse={handleDialogClose}
            className="datepicker_modal"
          >
            <div className="hc-pa-normal">
              <Grid.Container className="hc-pb-expanded " spacing="dense">
                <Grid.Item md={6} xs={12}>
                  <DatePicker
                    error={errors.runDate}
                    errorText={errors.runDate}
                    id="runDate"
                    label="Run Date"
                    value={values.runDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item md={6} xs={12}>
                  <DatePicker
                    error={errors.endDate}
                    errorText={errors.endDate}
                    id="endDate"
                    label="End Date"
                    value={values.endDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item md={6} xs={12}>
                  <Form.Field
                    id="name"
                    label="Catalog Name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name && (submitCount > 0 || touched.name)}
                    onBlur={handleBlur}
                    errorText={errors.name}
                  />
                </Grid.Item>
                <Grid.Item md={6} xs={12}>
                  <Form.Field
                    id="pageCount"
                    label="Page Quantity"
                    onChange={handleChange}
                    value={values.pageCount}
                    error={
                      errors.pageCount && (submitCount > 0 || touched.pageCount)
                    }
                    onBlur={handleBlur}
                    errorText={errors.pageCount}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                id="cancel"
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                canDelete={false}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default AddCatalog
