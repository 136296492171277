import { Divider, Grid, Card, Heading } from '@enterprise-ui/canvas-ui-react'
import { isEmpty } from 'lodash'

import ExpandableCreativePage from '../components/ExpandableCreativePage'

const CreativePageList = (props) => {
  const { catalog, pages, role } = props

  return !isEmpty(pages) ? (
    <>
      <div className="hc-pa-dense">
        <Card>
          <Grid.Container spacing="dense">
            {pages?.map((page) => (
              <Grid.Item xs={12}>
                <ExpandableCreativePage
                  catalog={catalog}
                  page={page}
                  role={role}
                />
                <Divider />
              </Grid.Item>
            ))}
          </Grid.Container>
        </Card>
      </div>
    </>
  ) : (
    <div className="hc-pa-normal">
      <Heading className="hc-ta-center hc-pt-expanded">
        No pages currently available.
      </Heading>
    </div>
  )
}
export default CreativePageList
