import { Tooltip } from '@enterprise-ui/canvas-ui-react'
import isEmpty from 'lodash.isempty'

import removeDuplicates from '../util/removeDuplicates'

const MarketListTooltip = (props) => {
  const { markets, location, size } = props
  const definedSize = size || 1
  if (isEmpty(markets)) return ''
  const allMarkets = removeDuplicates(markets.map((market) => market.shortCode))
  return allMarkets.length > definedSize ? (
    <Tooltip location={location || 'top-right'} content={allMarkets.join(', ')}>
      {allMarkets.slice(0, definedSize).join(', ')}, ...
    </Tooltip>
  ) : (
    allMarkets.join(', ')
  )
}
export default MarketListTooltip
