import { Divider, Input } from '@enterprise-ui/canvas-ui-react'

const SelectAllCheckbox = (props) => {
  const { fieldName, id, setFieldValue, targetArr, valueArr } = props
  const checked = valueArr.every((value) => targetArr.includes(value))

  return valueArr.length > 1 ? (
    <>
      <Input.Checkbox
        id={id}
        type="checkbox"
        checked={checked}
        label="Select / Unselect All"
        onUpdate={() =>
          checked
            ? setFieldValue(
                fieldName,
                targetArr.filter((elem) => !valueArr.includes(elem))
              )
            : setFieldValue(
                fieldName,
                targetArr.concat(
                  valueArr.filter((value) => !targetArr.includes(value))
                )
              )
        }
      />
      <Divider />
    </>
  ) : (
    <></>
  )
}
export default SelectAllCheckbox
