import { Grid, GridItem } from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { faTv, faImages } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'

import {
  sortStoriesByOrder,
  filterStoriesById,
  storiesByPyramidFilter,
} from '../util/StoryUtil'

import StoryList from './StoryList'
import UmbrellaList from './UmbrellaList'

const StoriesTable = ({
  stories = [],
  projectStartDate,
  projectEndDate,
  projectDescription,
  outcomePriorityId = '',
  projectId = '',
  projectName = '',
  pyramidNameId = '',
  pyramidType,
  selectedMarketingChannelFilter,
  selectedMarketingPyramidFilter,
  handleToggleChannelDialog,
  handleSetOfferUmbrellaChannels,
  setIsOfferDrawerOpen,
  setSelectedOffer,
  selectedStoryId = '',
  setSelectedStoryId,
  selectedUmbrella,
  setSelectedUmbrella,
  selectedUmbrellaId,
  recentlyClickedOffer,
  setRecentlyClickedOffer,
  setSelectedUmbrellaId,
  config,
  selectedOffer,
  session,
  setIsItemImageModalOpen,
  setItemSelected,
  updatedItemImage,
}) => {
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null)

  let selectedStory = filterStoriesById(stories, selectedStoryId)

  const storyTableRef = useRef(outcomePriorityId)

  const handleStorySelection = (id, ind) => {
    setSelectedStoryIndex(ind)
    setSelectedStoryId(id)
  }
  if (stories && selectedMarketingPyramidFilter) {
    stories = storiesByPyramidFilter(stories, selectedMarketingPyramidFilter)
  }

  const displayedStories = sortStoriesByOrder(stories)
  selectedStory = stories.filter(
    (story) => story.story_id === selectedStoryId
  )?.[0]

  useEffect(() => {
    // scroll to the stories section when the user select outcome/pririoty
    if (outcomePriorityId && stories?.length > 0) {
      if (selectedStoryId) {
        handleStorySelection(selectedStoryId, 1)
      }
      storyTableRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoadUmbrellaOffersChannel = () => {
    handleToggleChannelDialog()
    handleSetOfferUmbrellaChannels(selectedStory.umbrellas)
  }

  const handleStoryUmbrellaMessage = (updatedUmbrella, storyId) => {
    selectedStory?.umbrellas.forEach((umbrella = {}) => {
      if (umbrella.ivy_umbrella_id === updatedUmbrella.ivy_umbrella_id) {
        umbrella.promo_message = updatedUmbrella.promoMessage
      }
    })
  }

  const handleOpenCreativeSummary = () => {
    const channelIds = selectedMarketingChannelFilter.map(
      (channel) => channel.id
    )
    let creativeSummaryUrl = `${config.ui.url}/execution/creativeSummary/${projectId}/${selectedStoryId}`
    if (channelIds.length > 0) {
      creativeSummaryUrl = `${creativeSummaryUrl}?channelIds=${channelIds}`
    }
    window.open(creativeSummaryUrl, '_blank')
  }

  return outcomePriorityId ? (
    <Grid.Container spacing="none" className="stories-umbrellas-container">
      <Grid.Container
        xs={12}
        className="umbrellas-container"
        justify="flex-end"
      >
        {/* stories contaier area */}
        <Grid.Item xs={2} className="stories-container">
          <h4>Stories ({stories.length})</h4>
          <StoryList
            stories={displayedStories}
            selectedStoryId={selectedStoryId}
            handleStorySelection={handleStorySelection}
          />
        </Grid.Item>

        {/* umbrellas header area */}
        <Grid.Item xs={10} className="story-umbrella-container">
          <Grid.Container
            xs={10}
            ref={storyTableRef}
            justify="space-between"
            align="flex-start"
          >
            <GridItem ref={storyTableRef} className="story-name-containter">
              <h4>
                {selectedStory
                  ? `Story ${selectedStoryIndex}: ${selectedStory.story_name}`
                  : 'Select a story'}
              </h4>
            </GridItem>

            <Grid.Item className="umbrellas-date-icon-container">
              <h4>
                <span>
                  Start Date:
                  <DateFormatter date={projectStartDate} format="MM/DD/YYYY" />
                </span>
              </h4>

              {selectedStory ? (
                <>
                  <FontAwesomeIcon
                    icon={faImages}
                    className="labelledIcon clickable"
                    color="#000000"
                    size="lg"
                    onClick={() => {
                      handleOpenCreativeSummary()
                    }}
                  />

                  <FontAwesomeIcon
                    className="labelledIcon clickable"
                    onClick={handleLoadUmbrellaOffersChannel}
                    icon={faTv}
                    size="lg"
                  />
                </>
              ) : null}
            </Grid.Item>
          </Grid.Container>

          <div className="umbrellas-divider"></div>
          {/* umbrellas list area */}
          <UmbrellaList
            umbrellas={selectedStory?.umbrellas}
            handleStoryUmbrellaMessage={handleStoryUmbrellaMessage}
            setIsOfferDrawerOpen={setIsOfferDrawerOpen}
            setSelectedOffer={setSelectedOffer}
            marketingChannelFilter={selectedMarketingChannelFilter}
            outcomePriorityId={outcomePriorityId}
            storyId={selectedStoryId}
            storyName={selectedStory?.story_name}
            projectName={projectName}
            projectDescription={projectDescription}
            pyramidNameId={pyramidNameId} // { pyramidId, pyramidName }
            pyramidType={pyramidType}
            projectId={projectId}
            projectStartDate={projectStartDate}
            projectEndDate={projectEndDate}
            selectedUmbrella={selectedUmbrella}
            setSelectedUmbrella={setSelectedUmbrella}
            selectedUmbrellaId={selectedUmbrellaId}
            recentlyClickedOffer={recentlyClickedOffer}
            setRecentlyClickedOffer={setRecentlyClickedOffer}
            setSelectedUmbrellaId={setSelectedUmbrellaId}
            selectedOffer={selectedOffer}
            setIsItemImageModalOpen={setIsItemImageModalOpen}
            setItemSelected={setItemSelected}
            updatedItemImage={updatedItemImage}
            selectedStoryId={selectedStoryId}
            session={session}
          />
        </Grid.Item>
      </Grid.Container>
    </Grid.Container>
  ) : null
}

export default StoriesTable
