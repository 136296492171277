export function convertImagetoBase64(event, cb) {
  let file = event.target?.files[0]

  let reader = new FileReader()
  if (file) {
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log(error)
    }
  }
}

export function errorCheckImage(event) {
  let sizeImageError = false
  let nullImageError = false
  let file = event.target.files[0]
  if (!file) {
    nullImageError = true
    sizeImageError = false
  } else if (file.size >= 1000000) {
    sizeImageError = true
    nullImageError = false
  } else {
    nullImageError = false
    sizeImageError = false
  }
  return { nullImageError, sizeImageError }
}
