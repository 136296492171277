import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import itemMapper from '../mappers/itemMapper'
import ReplaceFirst from '../util/ReplaceFirst'

const usePutEditItem = (setLoading, setWeeklyAd, setToast) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.item)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const putItem = async () => {
      mounted && setLoading(true)
      try {
        const id = request.body.id
        const response = await axios.put(url.current + id, request.body, {
          headers: { user: request.user },
        })
        const thisItem = itemMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            ReplaceFirst(
              w.pages
                .find((page) => page.id === request.pageId)
                .stories.find((story) => story.id === request.storyId)
                .cuts.find((cut) => cut.id === request.cutId).items,
              id,
              thisItem
            )
            return w
          })
        /* As the data is not passed from expandable item and we are not closing & opening the modal
           and expecting to see the updated data, the only way it is working is when we are pushing directly with hook */
        if (request.setViewItemPatches) {
          mounted &&
            request.setViewItemPatches({
              body: thisItem,
              cutId: request.cutId,
              itemId: id,
              pageId: request.pageId,
              show: true,
              storyId: request.storyId,
            })
        }
        if (setToast && mounted) {
          setToast({
            heading: 'Item Edited',
            isVisible: true,
            message: 'Good work.',
            type: 'success',
          })
        }
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      putItem()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd, setToast])

  return setRequest
}
export default usePutEditItem
