import { Button, Input, Form, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

import AddOffer from './AddOffer'

let addCutSchema = yup.object().shape({
  add_cut_divisionId: yup.number().required('Required').typeError('Required'),
  add_cut_name: yup.string(),
})

const AddCut = (props) => {
  const {
    editStory,
    show,
    setShow,
    prevCleanup,
    postCut,
    getOffers,
    weeklyAd,
    hasEditPrivileges,
  } = props

  const story = editStory.body
  const divisions = editStory.divisions

  const [addOffers, setAddOffers] = useState({})

  const wipeState = () => {
    setShow(false)
    setAddOffers({})
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        add_cut_divisionId: null,
        add_cut_name: '',
      }}
      validationSchema={addCutSchema}
      onSubmit={(values, funcs) => {
        postCut({
          body: {
            divisionId: values.add_cut_divisionId,
            name: values.add_cut_name,
            storyId: story.id,
          },
          hasEditPrivileges: hasEditPrivileges,
          page: editStory.page,
          storyId: story.id,
        })
        wipeState()
        funcs.resetForm()
        prevCleanup()
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal
            isVisible={show}
            headingText={`Add Cut to story ${story.priority + 1}`}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <Input.Label error={errors.add_cut_divisionId}>
                Select a division{' '}
                {errors.add_cut_divisionId
                  ? String(errors.add_cut_divisionId)
                  : null}
              </Input.Label>
              {divisions ? (
                <Input.Chips
                  id="add_cut_divisionId"
                  singleSelect
                  options={divisions.map((division) => ({
                    label: division.shortCode,
                    value: division.id,
                  }))}
                  value={values.add_cut_divisionId}
                  onUpdate={setFieldValue}
                />
              ) : null}
              <Form.Field
                id="add_cut_name"
                label="Cut Name"
                value={values.add_cut_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Button
                disabled={!values.add_cut_divisionId}
                type="primary"
                onClick={() =>
                  setAddOffers({
                    cleanup: (pictured, liner) => {
                      postCut({
                        body: {
                          divisionId: values.add_cut_divisionId,
                          linerOffers: liner,
                          name: values.add_cut_name,
                          offers: pictured,
                          storyId: story.id,
                        },
                        hasEditPrivileges: hasEditPrivileges,
                        page: editStory.page,
                        storyId: story.id,
                        storyLastUpdatedTimestamp: story.lastUpdatedTimestamp,
                        weeklyAdStartDate: weeklyAd.startDate,
                      })
                      handleDialogClose()
                      prevCleanup()
                    },
                    divisionId: values.add_cut_divisionId,
                    linerOfferIds: [],
                    offerIds: [],
                    show: false, //show set to true after useGetOffers completes
                    startDate: weeklyAd.startDate,
                  })
                }
              >
                Link Offers
              </Button>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!values.add_cut_divisionId}
              />
            </div>
            <AddOffer
              getOffers={getOffers}
              addOffers={addOffers}
              setAddOffers={setAddOffers}
            />
          </Modal>
        )
      }}
    </Formik>
  )
}
export default AddCut
