import { Input } from '@enterprise-ui/canvas-ui-react'

import { pyramidOptions } from '../util/CreativeHubGlobalUtil'

const MarketingPyramidSelect = ({
  selectedMarketingPriorityFilter,
  selectedMarketingPyramidFilter,
  setMarketingPriorityFilter,
  setMarketingPyramidFilter,
  disabled,
  handleFilterUpdate,
}) => (
  <>
    <Input.Label>Pyramid</Input.Label>
    <Input.Select
      id="marketingPyramidSelect"
      options={pyramidOptions}
      onUpdate={(id, value) => {
        setMarketingPyramidFilter(value)
        handleFilterUpdate()
      }}
      value={selectedMarketingPyramidFilter}
      disabled={selectedMarketingPriorityFilter === 'ent' || disabled}
      defaultValue=""
      required
      data-testid="marketingPyramidSelect"
    />
  </>
)

export default MarketingPyramidSelect
