import {
  Card,
  Form,
  Grid,
  Heading,
  ProgressBar,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import CatalogApi from '../../api/pop-catalog/CatalogApi'
import CreativePageList from '../../tables/CreativePageList'
import { CATEGORY_LIST } from '../../util/CategoryColorMap'
import { setPageFilterFromQuery } from '../../util/filterOptions'

// Vendor Screen
const CatalogPageView = (props) => {
  const { role } = props
  const query = new URLSearchParams(useLocation().search)
  const catalogId = useParams().catalogId
  const [catalog, setCatalog] = useState({})

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [filteredPages, setFilteredPages] = useState([])

  const catalogApi = new CatalogApi()
  useEffect(() => {
    setLoading(true)
    catalogApi
      .getCatalog(catalogId, `&${query.toString()}`)
      .then((res) => {
        setCatalog(res)
        setFilteredPages(getFilteredPages(res?.pages, query))
      })
      .catch((e) => setFilteredPages([], query))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [setCatalog, setLoading])

  const pageList = formatPages(catalog?.pages)
  return loading ? (
    <div className="hc-pa-normal">
      <ProgressBar indeterminate />
    </div>
  ) : (
    <Formik
      enableReinitialize
      initialValues={{
        category: query.get('category')
          ? CATEGORY_LIST.find(
              (category) => String(category.value) === query.get('category')
            )
          : '',
        dpci: query.get('dpci') ? query.get('dpci') : '',
        page: query.get('pageId')
          ? setPageFilterFromQuery(query, pageList)
          : '',
        product_name: query.get('product_name')
          ? query.get('product_name')
          : '',
      }}
      onSubmit={(values) => {
        !isEmpty(values.page)
          ? query.set('pageId', values.page.map((page) => page.id).join(','))
          : query.delete('pageId')

        !isEmpty(values.category)
          ? query.set('category', values.category?.value)
          : query.delete('category')

        !isEmpty(values.product_name)
          ? query.set('product_name', values.product_name)
          : query.delete('product_name')
        !isEmpty(values.dpci)
          ? query.set('dpci', values.dpci)
          : query.delete('dpci')
        navigate(`/catalog/${catalogId}/creative?${query.toString()}`)
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
        <>
          <div className="hc-pa-normal hc-pa-none">
            <Grid.Container spacing="none" justify="flex-end" align="flex-end">
              <Grid.Item xs={12}>
                <Card>
                  {catalog?.pages ? (
                    <div className="hc-pa-normal">
                      <Grid.Container
                        spacing="normal"
                        justify="center"
                        align="center"
                      >
                        <Grid.Item xs={12} className="hc-ta-center">
                          <Heading>{catalog.name}</Heading>
                          <p>Run Date: {catalog.runDate}</p>
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Autocomplete
                            id="category"
                            onUpdate={(id, category) => {
                              setFieldValue(id, category)
                              handleSubmit()
                            }}
                            value={values.category}
                            options={CATEGORY_LIST}
                            placeholder="Category"
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Autocomplete
                            id="page"
                            multiselect
                            chipHeight="dense"
                            onUpdate={(_, value) => {
                              setFieldValue('page', value)
                              value.length !== values.page.length &&
                                handleSubmit()
                            }}
                            value={values.page}
                            options={pageList}
                            placeholder="Page"
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Form.Field
                            className="hc-pt-dense"
                            id="dpci"
                            placeholder="DPCI"
                            value={values.dpci}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSubmit()
                              }
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Form.Field
                            className="hc-pt-dense"
                            id="product_name"
                            placeholder="Product Name"
                            value={values.product_name}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSubmit()
                              }
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item
                          xs={4}
                          className="hc-pt-sm hc-pl-none hc-pr-none"
                        >
                          <Button
                            type="primary"
                            onClick={() => {
                              navigate(`/catalog/${catalogId}/creative`)
                              handleReset()
                            }}
                          >
                            Clear
                          </Button>
                        </Grid.Item>
                        <Grid.Item xs={12}>
                          <CreativePageList
                            catalog={catalog}
                            pages={filteredPages}
                            role={role}
                          />
                        </Grid.Item>
                      </Grid.Container>
                    </div>
                  ) : null}
                </Card>
              </Grid.Item>
            </Grid.Container>
          </div>
        </>
      )}
    </Formik>
  )
}
function formatPages(pages) {
  return !isEmpty(pages)
    ? pages
        ?.map((page) => ({
          id: page.id,
          label: `Page ${page.pageOrdering + 1}`,
          value: page.id,
        }))
        .concat([{ id: -1, label: 'TBD', value: -1 }])
    : []
}

const getFilteredPages = (pages, query) => {
  let filteredPages = pages
  const catQuery = query.get('category'),
    dpciQuery = query.get('dpci'),
    pageQuery = query.get('pageId')

  if (catQuery) {
    filteredPages = filteredPages?.filter((page) =>
      page.category.includes(catQuery)
    )
  }
  if (pageQuery) {
    let pagesIds = pageQuery.split(',').map((page) => +page)
    filteredPages = filteredPages?.filter((page) => pagesIds.includes(page.id))
  }

  if (dpciQuery) {
    filteredPages = filteredPages?.filter((page) =>
      page.dpci_list?.some((dpci) => dpci.includes(dpciQuery))
    )
  }
  return filteredPages
}
export default CatalogPageView
