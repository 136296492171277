import {
  Dialog,
  Form,
  Grid,
  Modal,
  ExpandableSection,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { Formik } from 'formik'
import { useState } from 'react'

import PlatformApi from '../api/platform-notifications/EmailVerification'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { formatToServer, formatToUi } from '../util/MomentUtil'

import {
  editWeeklyAdSchema,
  validateWeeklyAdEmails,
} from './EmailVerificationHelper'
import '../scss/EditWeeklyAdDetails.scss'

const EditWeeklyAdDetails = (props) => {
  const { show, setShow, putWad, deleteWad, weeklyAd, canDelete } = props
  const {
    contact: {
      aahomeProductProducer,
      apparelIhp,
      apparelKids,
      apparelMens,
      apparelRtw,
      audioAcd,
      babyMerchantDivisionCaptain,
      cscSupport,
      digitalEp,
      digitalWriters,
      epManager,
      epTeam,
      frequencyhlProductProducer,
      hardlinesElec,
      hardlinesEnt,
      hardlinesSprt,
      hardlinesToys,
      homeDec,
      homeKtn,
      homeStat,
      projectManager,
      copyWriter,
    } = {},
    bookOneMarkets,
    bookTwoMarkets,
    colorApprovalDate,
    contact,
    creativeKickoffDate,
    epCaptain,
    eventWeek,
    headlineWriter,
    lockoutDate,
    merchantPartnerDate,
    pmmApparel,
    pmmBeauty,
    pmmEssentials,
    pmmFoodBev,
    pmmHardlines,
    pmmHome,
    postPhotoLayoutDate,
    prePhotoLayoutDate,
    projectName,
    revision,
    socialDigitalDwaAcd,
    startDate,
    videoAcd,
  } = weeklyAd
  const [isDeleteDialog, setDeleteDialog] = useState(false)
  const wipeState = () => {
    setDeleteDialog(false)
    setShow(false)
  }
  const api = new PlatformApi()

  const mapPmm = (pmmArray = []) =>
    pmmArray.map((option, index) => ({
      id: index,
      label: option,
      value: option,
    }))

  return weeklyAd.id ? (
    <Formik
      enableReinitialize
      initialValues={{
        aahomeProductProducer: mapPmm(aahomeProductProducer) || [],
        apparelIhp: mapPmm(apparelIhp) || [],
        apparelKids: mapPmm(apparelKids) || [],
        apparelMens: mapPmm(apparelMens) || [],
        apparelRtw: mapPmm(apparelRtw) || [],
        audioAcd: mapPmm(audioAcd) || [],
        babyMerchantDivisionCaptain: mapPmm(babyMerchantDivisionCaptain) || [],
        bookOneMarkets: bookOneMarkets || '',
        bookTwoMarkets: bookTwoMarkets || '',
        colorApprovalDate: formatToUi(colorApprovalDate) || '',
        copyWriter: mapPmm(copyWriter) || [],
        creativeKickoffDate: formatToUi(creativeKickoffDate) || '',
        cscSupport: mapPmm(cscSupport) || [],
        digitalEp: mapPmm(digitalEp) || [],
        digitalWriters: mapPmm(digitalWriters) || [],
        epCaptain:
          (epCaptain && [{ label: epCaptain, value: epCaptain }]) || [],
        epManager: mapPmm(epManager) || [],
        epTeam: mapPmm(epTeam) || [],
        eventWeek: eventWeek || '',
        frequencyhlProductProducer: mapPmm(frequencyhlProductProducer) || [],
        hardlinesElec: mapPmm(hardlinesElec) || [],
        hardlinesEnt: mapPmm(hardlinesEnt) || [],
        hardlinesSprt: mapPmm(hardlinesSprt) || [],
        hardlinesToys: mapPmm(hardlinesToys) || [],
        headlineWriter:
          (headlineWriter && [
            { label: headlineWriter, value: headlineWriter },
          ]) ||
          [],
        homeDec: mapPmm(homeDec) || [],
        homeKtn: mapPmm(homeKtn) || [],
        homeStat: mapPmm(homeStat) || [],
        invalidEmails: [],
        lockoutDate: formatToUi(lockoutDate) || '',
        merchantPartnerDate: formatToUi(merchantPartnerDate) || '',
        pmmApparel: mapPmm(pmmApparel) || [],
        pmmBeauty: mapPmm(pmmBeauty) || [],
        pmmEssentials: mapPmm(pmmEssentials) || [],
        pmmFoodBev: mapPmm(pmmFoodBev) || [],
        pmmHardlines: mapPmm(pmmHardlines) || [],
        pmmHome: mapPmm(pmmHome) || [],
        postPhotoLayoutDate: formatToUi(postPhotoLayoutDate) || '',
        prePhotoLayoutDate: formatToUi(prePhotoLayoutDate) || '',
        projectManager: mapPmm(projectManager) || [],
        projectName: projectName || '',
        revision: revision || 0,
        socialDigitalDwaAcd: mapPmm(socialDigitalDwaAcd) || [],
        spellCheck: false,
        startDate: formatToUi(startDate) || '',
        videoAcd: mapPmm(videoAcd) || [],
      }}
      validationSchema={editWeeklyAdSchema}
      onSubmit={(values = {}, funcs) => {
        const {
          aahomeProductProducer,
          apparelIhp,
          apparelKids,
          apparelMens,
          apparelRtw,
          audioAcd,
          babyMerchantDivisionCaptain,
          colorApprovalDate,
          copyWriter,
          creativeKickoffDate,
          cscSupport,
          digitalEp,
          digitalWriters,
          epManager,
          epTeam,
          frequencyhlProductProducer,
          hardlinesElec,
          hardlinesEnt,
          hardlinesSprt,
          hardlinesToys,
          homeDec,
          homeKtn,
          homeStat,
          pmmApparel,
          pmmBeauty,
          pmmEssentials,
          pmmFoodBev,
          pmmHardlines,
          pmmHome,
          postPhotoLayoutDate,
          prePhotoLayoutDate,
          projectManager,
          startDate,
          lockoutDate,
          merchantPartnerDate,
          headlineWriter,
          epCaptain,
          socialDigitalDwaAcd,
          videoAcd,
          ...otherValues
        } = values

        putWad({
          aahomeProductProducer: aahomeProductProducer.map(
            (option) => option.value
          ),
          apparelIhp: apparelIhp.map((option) => option.value),
          apparelKids: apparelKids.map((option) => option.value),
          apparelMens: apparelMens.map((option) => option.value),
          apparelRtw: apparelRtw.map((option) => option.value),
          audioAcd: audioAcd.map((option) => option.value),
          babyMerchantDivisionCaptain: babyMerchantDivisionCaptain.map(
            (option) => option.value
          ),
          colorApprovalDate: formatToServer(colorApprovalDate),
          contactId: contact.id,
          copyWriter: copyWriter.map((option) => option.value),
          creativeKickoffDate: formatToServer(creativeKickoffDate),
          cscSupport: cscSupport.map((option) => option.value),
          digitalEp: digitalEp.map((option) => option.value),
          digitalWriters: digitalWriters.map((option) => option.value),
          epCaptain: (epCaptain.length && epCaptain[0].value) || '',
          epManager: epManager.map((option) => option.value),
          epTeam: epTeam.map((option) => option.value),
          frequencyhlProductProducer: frequencyhlProductProducer.map(
            (option) => option.value
          ),
          hardlinesElec: hardlinesElec.map((option) => option.value),
          hardlinesEnt: hardlinesEnt.map((option) => option.value),
          hardlinesSprt: hardlinesSprt.map((option) => option.value),
          hardlinesToys: hardlinesToys.map((option) => option.value),
          headlineWriter:
            (headlineWriter.length && headlineWriter[0].value) || '',
          homeDec: homeDec.map((option) => option.value),
          homeKtn: homeKtn.map((option) => option.value),
          homeStat: homeStat.map((option) => option.value),
          id: weeklyAd.id,
          lastUpdatedTimestamp: weeklyAd.lastUpdatedTimestamp,
          lockoutDate: formatToServer(lockoutDate),
          merchantPartnerDate: formatToServer(merchantPartnerDate),
          ...otherValues,
          pmmApparel: pmmApparel.map((option) => option.value),
          pmmBeauty: pmmBeauty.map((option) => option.value),
          pmmEssentials: pmmEssentials.map((option) => option.value),
          pmmFoodBev: pmmFoodBev.map((option) => option.value),
          pmmHardlines: pmmHardlines.map((option) => option.value),
          pmmHome: pmmHome.map((option) => option.value),
          postPhotoLayoutDate: formatToServer(postPhotoLayoutDate),
          prePhotoLayoutDate: formatToServer(prePhotoLayoutDate),
          projectManager: projectManager.map((option) => option.value),
          socialDigitalDwaAcd: socialDigitalDwaAcd.map(
            (option) => option.value
          ),
          startDate: formatToServer(startDate),
          videoAcd: videoAcd.map((option) => option.value),
        })
        funcs.resetForm()
        wipeState()
      }}
    >
      {({
        values = {},
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        const onDelete = () => {
          deleteWad({ id: weeklyAd.id })
          handleDialogClose()
        }
        const {
          startDate,
          projectName,
          eventWeek,
          headlineWriter,
          epCaptain,
          creativeKickoffDate,
          prePhotoLayoutDate,
          merchantPartnerDate,
          postPhotoLayoutDate,
          colorApprovalDate,
          lockoutDate,
          revision,
          bookOneMarkets,
          bookTwoMarkets,
          pmmBeauty,
          pmmEssentials,
          pmmFoodBev,
          copyWriter,
          pmmApparel,
          pmmHardlines,
          pmmHome,
          apparelMens,
          apparelKids,
          apparelRtw,
          apparelIhp,
          audioAcd,
          babyMerchantDivisionCaptain,
          hardlinesElec,
          hardlinesEnt,
          hardlinesSprt,
          hardlinesToys,
          homeKtn,
          homeStat,
          homeDec,
          digitalWriters,
          projectManager,
          epManager,
          epTeam,
          digitalEp,
          cscSupport,
          aahomeProductProducer,
          frequencyhlProductProducer,
          socialDigitalDwaAcd,
          videoAcd,
        } = values

        const onSingleSelectAutocompleteChange = (id, value) => {
          if (value.length <= 1) {
            handleEmailFieldUpdate(id, value)
          }
        }

        const verifyEmails = async function (values) {
          const response = await validateWeeklyAdEmails(values, api)
          setFieldValue('invalidEmails', response)
        }

        const handleEmailFieldUpdate = (id, value) => {
          setFieldValue(id, value)
          let valuesCopy = { ...values }
          valuesCopy[id] = value
          verifyEmails(valuesCopy)
        }

        return (
          <Modal
            isVisible={show}
            headingText="Edit Weekly Ad"
            onRefuse={handleDialogClose}
            className="larger_modal"
          >
            <div className="hc-pa-normal">
              <Grid.Container spacing="dense">
                <Grid.Item xs={3}>
                  <DatePicker
                    required
                    id="startDate"
                    label="Start Date"
                    value={startDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="projectName"
                    label="Project Name"
                    onChange={handleChange}
                    value={projectName}
                    error={errors.projectName && touched.projectName}
                    onBlur={handleBlur}
                    errorText={errors.projectName}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="eventWeek"
                    label="Event Week"
                    onChange={handleChange}
                    value={eventWeek}
                    error={errors.eventWeek && touched.eventWeek}
                    onBlur={handleBlur}
                    errorText={errors.eventWeek}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <DatePicker
                    required
                    id="postPhotoLayoutDate"
                    label="Post-Photo Layout Date"
                    value={postPhotoLayoutDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <DatePicker
                    required
                    id="creativeKickoffDate"
                    label="Creative Kickoff Date"
                    value={creativeKickoffDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <DatePicker
                    required
                    id="prePhotoLayoutDate"
                    label="Pre-Photo Layout Date"
                    value={prePhotoLayoutDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <DatePicker
                    required
                    id="merchantPartnerDate"
                    label="Merchant Partner Date"
                    value={merchantPartnerDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <DatePicker
                    required
                    id="colorApprovalDate"
                    label="Color Approval Date"
                    value={colorApprovalDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <DatePicker
                    required
                    id="lockoutDate"
                    label="Lockout Date"
                    value={lockoutDate}
                    onUpdate={setFieldValue}
                    location="bottom-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="revision"
                    label="Revision"
                    onChange={handleChange}
                    value={revision}
                    error={errors.revision && touched.revision}
                    onBlur={handleBlur}
                    errorText={errors.revision}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="bookOneMarkets"
                    label="Book One Markets"
                    onChange={handleChange}
                    value={bookOneMarkets}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="bookTwoMarkets"
                    label="Book Two Markets"
                    onChange={handleChange}
                    value={bookTwoMarkets}
                  />
                </Grid.Item>
                <Grid.Item xs={6}></Grid.Item>
                {!weeklyAd.midweek && (
                  <ExpandableSection padding="none">
                    <p>Merch Contacts</p>
                    <ExpandableSection.Content>
                      <div className="hc-pa-normal">
                        <Grid.Container spacing="dense">
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="apparelIhp"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={apparelIhp}
                              options={[]}
                              label="Apparel IHP"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="apparelMens"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={apparelMens}
                              options={[]}
                              label="Apparel Mens"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="apparelKids"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={apparelKids}
                              options={[]}
                              label="Apparel Kids"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="apparelRtw"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={apparelRtw}
                              options={[]}
                              label="Apparel RTW"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="babyMerchantDivisionCaptain"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={babyMerchantDivisionCaptain}
                              options={[]}
                              label="Baby Merchant Division Captain"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="homeDec"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={homeDec}
                              options={[]}
                              label="Home Dec"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="homeKtn"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={homeKtn}
                              options={[]}
                              label="Home KTN"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="homeStat"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={homeStat}
                              options={[]}
                              label="Home Stat"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="hardlinesSprt"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={hardlinesSprt}
                              options={[]}
                              label="Hardlines Sporting Goods"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="hardlinesToys"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={hardlinesToys}
                              options={[]}
                              label="Hardlines Toys"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="hardlinesElec"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={hardlinesElec}
                              options={[]}
                              label="Hardlines Elec"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                          <Grid.Item xs={3}>
                            <Autocomplete
                              id="hardlinesEnt"
                              multiselect
                              allowCustomInputValue={true}
                              chipHeight="dense"
                              className="hc-mb-dense hc-mt-dense"
                              onUpdate={handleEmailFieldUpdate}
                              value={hardlinesEnt}
                              options={[]}
                              label="Hardlines Ent"
                              noResultsMessage="Use Outlook Name"
                            />
                          </Grid.Item>
                        </Grid.Container>
                      </div>
                    </ExpandableSection.Content>
                  </ExpandableSection>
                )}
                <ExpandableSection padding="none">
                  <p>Marketing &#38; Creative Contacts</p>
                  <ExpandableSection.Content>
                    <div className="hc-pa-normal">
                      <Grid.Container spacing="dense">
                        {!weeklyAd.midweek && (
                          <>
                            <Grid.Item xs={3}>
                              <Autocomplete
                                id="pmmApparel"
                                multiselect
                                allowCustomInputValue={true}
                                chipHeight="dense"
                                className="hc-mb-dense hc-mt-dense"
                                onUpdate={handleEmailFieldUpdate}
                                value={pmmApparel}
                                options={[]}
                                label="A&A PMM"
                                noResultsMessage="Use Outlook Name"
                              />
                            </Grid.Item>
                            <Grid.Item xs={3}>
                              <Autocomplete
                                id="pmmHardlines"
                                multiselect
                                allowCustomInputValue={true}
                                chipHeight="dense"
                                className="hc-mb-dense hc-mt-dense"
                                onUpdate={handleEmailFieldUpdate}
                                value={pmmHardlines}
                                options={[]}
                                label="Hardlines PMM"
                                noResultsMessage="Use Outlook Name"
                              />
                            </Grid.Item>
                            <Grid.Item xs={3}>
                              <Autocomplete
                                id="pmmHome"
                                multiselect
                                allowCustomInputValue={true}
                                chipHeight="dense"
                                className="hc-mb-dense hc-mt-dense"
                                onUpdate={handleEmailFieldUpdate}
                                value={pmmHome}
                                options={[]}
                                label="Home PMM"
                                noResultsMessage="Use Outlook Name"
                              />
                            </Grid.Item>
                            <Grid.Item xs={3}>
                              <Autocomplete
                                id="copyWriter"
                                multiselect
                                allowCustomInputValue={true}
                                chipHeight="dense"
                                className="hc-mb-dense hc-mt-dense"
                                onUpdate={handleEmailFieldUpdate}
                                value={copyWriter}
                                options={[]}
                                label="Copy Writer"
                                noResultsMessage="Use Outlook Name"
                              />
                            </Grid.Item>
                            <Grid.Item xs={3}>
                              <Autocomplete
                                id="aahomeProductProducer"
                                multiselect
                                allowCustomInputValue={true}
                                chipHeight="dense"
                                className="hc-mb-dense hc-mt-dense"
                                onUpdate={handleEmailFieldUpdate}
                                value={aahomeProductProducer}
                                options={[]}
                                label="A&A/Home Product Producer"
                                noResultsMessage="Use Outlook Name"
                              />
                            </Grid.Item>
                            <Grid.Item xs={3}>
                              <Autocomplete
                                id="digitalWriters"
                                multiselect
                                allowCustomInputValue={true}
                                chipHeight="dense"
                                className="hc-mb-dense hc-mt-dense"
                                onUpdate={handleEmailFieldUpdate}
                                value={digitalWriters}
                                options={[]}
                                label="Digital Writers"
                                noResultsMessage="Use Outlook Name"
                              />
                            </Grid.Item>
                          </>
                        )}
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="epCaptain"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={onSingleSelectAutocompleteChange}
                            value={epCaptain}
                            options={[]}
                            label="EP Captain"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="frequencyhlProductProducer"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={frequencyhlProductProducer}
                            options={[]}
                            label="Frequency/HL Product Producer"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmFoodBev"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmFoodBev}
                            options={[]}
                            label="F&B PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmBeauty"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmBeauty}
                            options={[]}
                            label="Beauty PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="pmmEssentials"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={pmmEssentials}
                            options={[]}
                            label="Eessentials PMM"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="projectManager"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={projectManager}
                            options={[]}
                            label="Project Manager"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="headlineWriter"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={onSingleSelectAutocompleteChange}
                            value={headlineWriter}
                            options={[]}
                            label="Headline Writer"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="cscSupport"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={cscSupport}
                            options={[]}
                            label="DWA CSC Support"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="epTeam"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={epTeam}
                            options={[]}
                            label="EP Team"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="digitalEp"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={digitalEp}
                            options={[]}
                            label="Digital EP"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="epManager"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={epManager}
                            options={[]}
                            label="EP Manager"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="socialDigitalDwaAcd"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={socialDigitalDwaAcd}
                            options={[]}
                            label="Social/Digital/DWA Associate Creative Director"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="videoAcd"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={videoAcd}
                            options={[]}
                            label="Video Associate Creative Director"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Autocomplete
                            id="audioAcd"
                            multiselect
                            allowCustomInputValue={true}
                            chipHeight="dense"
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={handleEmailFieldUpdate}
                            value={audioAcd}
                            options={[]}
                            label="Audio Associate Creative Director"
                            noResultsMessage="Use Outlook Name"
                          />
                        </Grid.Item>
                      </Grid.Container>
                    </div>
                  </ExpandableSection.Content>
                </ExpandableSection>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item>
                  <p className="errorEmailMessage">{errors.invalidEmails}</p>
                  <ul className="errorEmailList">
                    {values.invalidEmails.map((email) => (
                      <li>{email}</li>
                    ))}
                  </ul>
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                canDelete={canDelete}
                handleDelete={setDeleteDialog}
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
              />
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText="Are you sure want to delete this Weekly Ad?"
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText="Delete"
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditWeeklyAdDetails
