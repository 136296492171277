import { Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

const AddLateAddition = (props) => {
  const { postLateAddition, showAddDialog, setShowAddDialog, weeklyAdId } =
    props

  let addLateAdditionSchema = yup.object().shape({
    add_lateItem_dpci: yup.string().matches(/^(?:\d{3}-\d{2}-\d{4})?$/, {
      message: 'DPCI in format 000-00-0000',
    }),
    add_lateItem_sampleId: yup.string().when('add_lateItem_sample', {
      is: (sample) => sample === 'inventory',
      otherwise: yup.string(),
      then: yup.string().required('SampleID required when status: inventory'),
    }),
  })

  return (
    <Formik
      enableReinitialize
      validationSchema={addLateAdditionSchema}
      initialValues={{
        add_lateItem_description: '',
        add_lateItem_dpci: '',
        add_lateItem_jiraFormNumber: '',
        add_lateItem_notes: '',
        add_lateItem_pageNumber: '',
        add_lateItem_sample: '',
        add_lateItem_sampleId: '',
        add_lateItem_samplePulled: -1,
      }}
      onSubmit={(values, funcs) => {
        postLateAddition({
          description: values.add_lateItem_description,
          dpci: values.add_lateItem_dpci,
          jiraFormNumber: values.add_lateItem_jiraFormNumber,
          notes: values.add_lateItem_notes,
          pageNumber: values.add_lateItem_pageNumber,
          sample: values.add_lateItem_sample,
          sampleId: values.add_lateItem_sampleId,
          samplePulled: values.add_lateItem_samplePulled,
          weeklyAdId,
        })
        funcs.resetForm()
        setShowAddDialog(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          setShowAddDialog(false)
        }
        return (
          <Modal
            isVisible={showAddDialog}
            headingText={'Add Late Addition'}
            onRefuse={handleDialogClose}
          >
            <div className="hc-pa-normal">
              <Grid.Container spacing="dense" align="center">
                <Grid.Item xs={6}>
                  <Form.Field
                    id="add_lateItem_pageNumber"
                    value={values.add_lateItem_pageNumber}
                    label="Page no"
                    onChange={handleChange}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="add_lateItem_dpci"
                    value={values.add_lateItem_dpci}
                    label="DPCI"
                    onChange={handleChange}
                    error={errors.add_lateItem_dpci}
                    errorText={errors.add_lateItem_dpci}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    id="add_lateItem_description"
                    value={values.add_lateItem_description}
                    label="Item Name"
                    onChange={handleChange}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="add_lateItem_sample"
                    label="Sample Status"
                    value={values.add_lateItem_sample}
                    onUpdate={setFieldValue}
                    options={[
                      { label: '', value: '' },
                      { label: 'Inventory', value: 'inventory' },
                      { label: 'Turn-in', value: 'turnIn' },
                      { label: 'Missing', value: 'missing' },
                    ]}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="add_lateItem_sampleId"
                    value={values.add_lateItem_sampleId}
                    label="Sample ID"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.add_lateItem_sampleId &&
                      errors.add_lateItem_sampleId
                    }
                    errorText={errors.add_lateItem_sampleId}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="add_lateItem_samplePulled"
                    label="Sample/Image Collected"
                    value={values.add_lateItem_samplePulled}
                    options={[
                      { label: '', value: -1 },
                      { label: 'No', value: 0 },
                      { label: 'Yes', value: 1 },
                      { label: 'Canceled', value: 2 },
                    ]}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="add_lateItem_jiraFormNumber"
                    value={values.add_lateItem_jiraFormNumber}
                    label="Jira Form Number"
                    onChange={handleChange}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    id="add_lateItem_notes"
                    value={values.add_lateItem_notes}
                    label="Notes"
                    onChange={handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  )
}
export default AddLateAddition
