import {
  Button,
  Card,
  Form,
  Grid,
  Heading,
  Spotlight,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { useEffect, useState, createContext } from 'react'
import { useCookies } from 'react-cookie'
import { useParams } from 'react-router-dom'

import SystemApi from '../../api/pop-weekly-ad/SystemApi'
import Alert from '../../components/Alert'
import DivisionFilter from '../../components/DivisionFilter'
import ExpandablePage from '../../components/ExpandablePage'
import LoadingSpinner from '../../components/LoadingSpinner'
import appConfig from '../../config/appConfig'
import MyOffers from '../../containers/MyOffers'
import ViewCutPatches from '../../containers/ViewCutPatches'
import ViewItemPatches from '../../containers/ViewItemPatches'
import AddArtPatch from '../../forms/AddArtPatch'
import AddCutToArtPatch from '../../forms/AddCutToArtPatch'
import EditArtPatch from '../../forms/EditArtPatch'
import EditCut from '../../forms/EditCut'
import EditDummy from '../../forms/EditDummy'
import EditItem from '../../forms/EditItem'
import EditPageMerch from '../../forms/EditPageMerch'
import EditPropItem from '../../forms/EditPropItem'
import EditStory from '../../forms/EditStory'
import MoveCut from '../../forms/MoveCut'
import useExport from '../../hooks/useGetExport'
import useGetItem from '../../hooks/useGetItem'
import useGetOffer from '../../hooks/useGetOffer'
import useGetPage from '../../hooks/useGetPage'
import useGetWeeklyAd from '../../hooks/useGetWeeklyAd'
import usePostCut from '../../hooks/usePostCut'
import usePostDummy from '../../hooks/usePostDummy'
import usePostItemPatch from '../../hooks/usePostItemPatch'
import usePostPropItem from '../../hooks/usePostPropItem'
import usePostStory from '../../hooks/usePostStory'
import usePutCut from '../../hooks/usePutCut'
import usePutCutPatch from '../../hooks/usePutCutPatch'
import usePutDummy from '../../hooks/usePutDummy'
import usePutEditItem from '../../hooks/usePutEditItem'
import usePutItemPatch from '../../hooks/usePutItemPatch'
import usePutMoveCut from '../../hooks/usePutMoveCut'
import usePutPage from '../../hooks/usePutPage'
import usePutPropItem from '../../hooks/usePutPropItem'
import usePutStory from '../../hooks/usePutStory'
import useSpawnArtPatch from '../../hooks/useSpawnArtPatch'
import { DIVISION_LIST } from '../../util/DivisionUtils'
import { formatToIvyDateTime } from '../../util/MomentUtil'
import '../../scss/PageView.scss'

export const WeeklyAdContext = createContext({})

const MerchView = (props) => {
  const { role, email } = props
  const wadId = useParams().wadId
  const [loading, setLoading] = useState(false)
  const [cookie, setCookie] = useCookies(['merch_filters'])
  const [divisions, setDivisions] = useState([])

  const [pageFilter, setPageFilter] = useState('')

  const makeToast = ToastProvider.useToaster()

  const onDivisionChange = (newDivision) => {
    const mapped = newDivision.map((div) => div.id)
    setCookie('merch_filters', mapped, {
      maxAge: 3.154e7,
      path: '/',
    })
  }
  const systemApi = new SystemApi()
  const [timestamp, setTimestamp] = useState()

  useEffect(() => {
    systemApi
      .getOffers()
      .then((result) => setTimestamp(result.lastIvyTimestamp))
      .catch(() => setTimestamp())
  })

  useEffect(() => {
    const ids = cookie.merch_filters
    ids
      ? setDivisions(DIVISION_LIST.filter((div) => ids.includes(div.id)))
      : setDivisions([])
  }, [cookie])

  const [weeklyAd, setWeeklyAd] = useGetWeeklyAd(wadId, setLoading, makeToast)

  const getPage = useGetPage(setLoading, setWeeklyAd, makeToast)
  const putPage = usePutPage(setLoading, setWeeklyAd)
  const postStory = usePostStory(setLoading, setWeeklyAd, makeToast)
  const putStory = usePutStory(setLoading, setWeeklyAd)
  const postDummy = usePostDummy(setLoading, setWeeklyAd)
  const putMoveCut = usePutMoveCut(setLoading, setWeeklyAd)
  const postPropItem = usePostPropItem(setLoading, setWeeklyAd)
  const putCut = usePutCut(setLoading, setWeeklyAd)
  const getItems = useGetItem(setLoading)
  const getOffers = useGetOffer(setLoading)
  const putItem = usePutEditItem(setLoading, setWeeklyAd, makeToast)
  const putDummy = usePutDummy(setLoading, setWeeklyAd)
  const putPropItem = usePutPropItem(setLoading, setWeeklyAd)
  const putCutPatch = usePutCutPatch(setLoading, setWeeklyAd)
  const postItemPatch = usePostItemPatch(setLoading, setWeeklyAd)
  const putItemPatch = usePutItemPatch(setLoading, setWeeklyAd, makeToast)
  const spawnArtPatch = useSpawnArtPatch(setLoading, setWeeklyAd)
  const [moveCut, setMoveCut] = useState({
    show: false,
  })
  const [expands, setExpands] = useState({
    cut: {},
    dummy: {},
    item: {},
    page: {},
    propItem: {},
    story: {},
  })
  const [myOffers, setMyOffers] = useState({
    show: false,
  })
  const [editPage, setEditPage] = useState({
    body: {},
    show: false,
  })

  const [editStory, setEditStory] = useState({
    body: {},
    divisions: [],
    pageId: null,
    show: false,
  })

  const [editCut, setEditCut] = useState({
    body: {},
    pageId: null,
    show: false,
    storyId: null,
  })

  const postCut = usePostCut(
    setLoading,
    setWeeklyAd,
    makeToast,
    setEditCut,
    divisions
  )

  const [editItem, setEditItem] = useState({
    body: {},
    cutId: null,
    pageId: null,
    show: false,
    storyId: null,
  })

  const [addArtPatch, setAddArtPatch] = useState({
    show: false,
    story: {},
  })

  const [addCutToArtPatch, setAddCutToArtPatch] = useState({
    show: false,
    story: {},
  })

  const [editArtPatch, setEditArtPatch] = useState({
    show: false,
    story: {},
  })

  const [editDummy, setEditDummy] = useState({
    body: {},
    cutId: null,
    pageId: null,
    show: false,
    storyId: null,
  })

  const [editPropItem, setEditPropItem] = useState({
    body: {},
    cutId: null,
    pageId: null,
    show: false,
    storyId: null,
  })

  const [viewCutPatches, setViewCutPatches] = useState({
    body: [],
    cutId: null,
    cutName: '',
    offers: [],
    pageId: null,
    show: false,
    storyId: null,
  })

  const [viewItemPatches, setViewItemPatches] = useState({
    body: {},
    cutId: null,
    pageId: null,
    show: false,
    storyId: null,
  })
  const config = appConfig()
  const whiteBoxUrl =
    config.backend.root +
    config.backend.export +
    config.backend.whitebox +
    wadId
  const [getExport] = useExport(makeToast)
  const [disableExport, setDisableExport] = useState(false)
  const getFilteredPages = () => {
    if (pageFilter) {
      return weeklyAd.pages.filter((page) => {
        if (pageFilter === 'PRINT') {
          return (
            (page.bookOnePageType === 'PRINT' &&
              Number(page.bookOnePageNumber) <= 60) ||
            (page.bookTwoPageType === 'PRINT' &&
              Number(page.bookTwoPageNumber) <= 60)
          )
        } else if (pageFilter === 'WRAP') {
          return (
            page.bookOnePageType === 'PRINT' &&
            Number(page.bookOnePageNumber) > 60
          )
        } else {
          //Digital
          return (
            page.bookOnePageType === pageFilter ||
            page.bookTwoPageType === pageFilter
          )
        }
      })
    }
    return weeklyAd.pages
  }

  const pageFilterOptions = [
    {
      label: pageFilter ? '' : 'Page Type',
      value: '',
    },
    weeklyAd.printedPageCount
      ? {
          label: 'Print',
          value: 'PRINT',
        }
      : null,
    weeklyAd.digitalPageCount
      ? {
          label: 'Digital',
          value: 'DIGITAL',
        }
      : null,
    weeklyAd.wrapPageCount
      ? {
          label: 'Wrap',
          value: 'WRAP',
        }
      : null,
  ].filter((x) => x !== null)

  return (
    <div>
      <Card elevation={2} className="hc-pa-dense">
        <Heading size={2} className="hc-ta-center">
          {weeklyAd.eventWeek}
        </Heading>
        <div>
          <Heading size={4} className="hc-ta-center">
            Start Date:{' '}
            <DateFormatter date={weeklyAd.startDate} format="ddd MMM DD YYYY" />
          </Heading>
          <Heading size={4} className="hc-ta-center hc-pb-dense">
            Project Name: {weeklyAd.projectName}
          </Heading>
          <Grid.Item>
            <Heading size={6} className="hc-ta-center hc-pb-dense hc-ma-none">
              {'Last Ivy Pull: ' + formatToIvyDateTime(timestamp)}
            </Heading>
          </Grid.Item>
          <Grid.Container
            spacing="dense"
            justify="center"
            style={{ width: '100%' }}
          >
            {weeklyAd.lockoutDate ? (
              weeklyAd.lockedOut ? (
                <Alert toggle={false} color="danger">
                  <p className="hc-fs-subheading ">
                    This ad was locked at 8pm on{' '}
                    <DateFormatter
                      date={weeklyAd.lockoutDate}
                      format="ddd MMM DD YYYY"
                    />
                  </p>
                </Alert>
              ) : (
                <Alert toggle={false}>
                  <p className="hc-fs-subheading ">
                    This ad will be locked at 8pm on{' '}
                    <DateFormatter
                      date={weeklyAd.lockoutDate}
                      format="ddd MMM DD YYYY"
                    />
                  </p>
                </Alert>
              )
            ) : null}
            <Grid.Item xs={3}>
              <DivisionFilter
                divisions={divisions}
                setDivisions={onDivisionChange}
              />
            </Grid.Item>
            <Grid.Item xs={2} className="hc-pt-normal">
              <Form.Field
                type="select"
                id="pageFilter"
                value={pageFilter}
                onUpdate={(_, value) => {
                  setPageFilter(value)
                }}
                options={pageFilterOptions}
              />
            </Grid.Item>
            <Grid.Item className="hc-pt-normal">
              <Button
                type="primary"
                onClick={() => {
                  onDivisionChange([])
                  setPageFilter('')
                }}
              >
                Clear
              </Button>
            </Grid.Item>
            <Grid.Item className="hc-pt-normal">
              <Spotlight
                spotlightLocation="top-right"
                contentData={[
                  {
                    copy: "Click here to view offers pulled in from IVY Promo. If they aren't listed, there may be an issue with setup or a delay in data pulled.",
                    title: 'New Feature: Offers',
                  },
                ]}
                contentLocation="bottom-right"
                size="normal"
                spotlightAnimation
                spotlightAnimationDuration="infinite"
              >
                <Button
                  disabled={disableExport}
                  type="primary"
                  onClick={() => setMyOffers({ show: true })}
                >
                  Offers
                </Button>
              </Spotlight>
            </Grid.Item>
            <Grid.Item className="hc-pt-normal">
              <Button
                disabled={disableExport}
                type="secondary"
                onClick={() =>
                  getExport({ setDisable: setDisableExport, url: whiteBoxUrl })
                }
              >
                Whitebox Export
              </Button>
            </Grid.Item>
          </Grid.Container>

          {weeklyAd && weeklyAd.pages ? (
            <div className="hc-pa-dense">
              <WeeklyAdContext.Provider value={weeklyAd}>
                {getFilteredPages().map((page) => (
                  <ExpandablePage
                    key={page.id}
                    role={role}
                    isMidweek={weeklyAd?.midweek}
                    adStartDate={weeklyAd?.startDate}
                    page={page}
                    getPage={getPage}
                    putPage={putPage}
                    putStory={putStory}
                    putCut={putCut}
                    setEditPage={setEditPage}
                    setEditStory={setEditStory}
                    setEditCut={setEditCut}
                    setEditArtPatch={setEditArtPatch}
                    setAddArtPatch={setAddArtPatch}
                    setViewCutPatches={setViewCutPatches}
                    setViewItemPatches={setViewItemPatches}
                    setEditItem={setEditItem}
                    setEditDummy={setEditDummy}
                    setEditPropItem={setEditPropItem}
                    divisionFilters={divisions}
                    expands={expands}
                    setExpands={setExpands}
                    getExport={getExport}
                    lockoutDate={weeklyAd?.lockoutDate}
                    eventWeek={weeklyAd.projectName}
                  />
                ))}
              </WeeklyAdContext.Provider>
            </div>
          ) : null}
        </div>
        <AddArtPatch
          addArtPatch={addArtPatch}
          setAddArtPatch={setAddArtPatch}
          putStory={putStory}
        />
        <EditArtPatch
          editArtPatch={editArtPatch}
          setEditArtPatch={setEditArtPatch}
          putStory={putStory}
          addCutToArtPatch={addCutToArtPatch}
          setAddCutToArtPatch={setAddCutToArtPatch}
        />
        <AddCutToArtPatch
          putStory={putStory}
          setAddCutToArtPatch={setAddCutToArtPatch}
          addCutToArtPatch={addCutToArtPatch}
          setEditArtPatch={setEditArtPatch}
        />
        <EditCut
          editCut={editCut}
          setEditCut={setEditCut}
          putPage={putPage}
          putCut={putCut}
          putStory={putStory}
          getItems={getItems}
          getOffers={getOffers}
          weeklyAd={weeklyAd}
          postDummy={postDummy}
          postPropItem={postPropItem}
          setMoveCut={setMoveCut}
          spawnArtPatch={spawnArtPatch}
        />
        <EditDummy
          editDummy={editDummy}
          setEditDummy={setEditDummy}
          putDummy={putDummy}
          putCut={putCut}
        />
        <EditItem
          editItem={editItem}
          setEditItem={setEditItem}
          putItem={putItem}
          putCut={putCut}
        />
        <EditPageMerch
          editPage={editPage}
          setEditPage={setEditPage}
          putPage={putPage}
          postStory={postStory}
        />
        <EditPropItem
          editPropItem={editPropItem}
          setEditPropItem={setEditPropItem}
          putPropItem={putPropItem}
          putCut={putCut}
        />
        <EditStory
          editStory={editStory}
          setEditStory={setEditStory}
          putStory={putStory}
          postCut={postCut}
          getOffers={getOffers}
          weeklyAd={weeklyAd}
          putPage={putPage}
        />
        {weeklyAd && weeklyAd?.pages && (
          <MoveCut
            moveCut={moveCut}
            setMoveCut={setMoveCut}
            setEditCut={setEditCut}
            weeklyAd={weeklyAd}
            getPage={getPage}
            putMoveCut={putMoveCut}
          />
        )}
        <ViewCutPatches
          viewCutPatches={viewCutPatches}
          setViewCutPatches={setViewCutPatches}
          putCutPatch={putCutPatch}
        />
        <ViewItemPatches
          viewItemPatches={viewItemPatches}
          setViewItemPatches={setViewItemPatches}
          putItem={putItem}
          putItemPatch={putItemPatch}
          postItemPatch={postItemPatch}
        />
        <MyOffers
          myOffers={myOffers}
          setMyOffers={setMyOffers}
          getOffers={getOffers}
          email={email}
          startDate={weeklyAd.startDate}
          timestamp={timestamp}
        />
        <LoadingSpinner show={loading} />
      </Card>
    </div>
  )
}
export default MerchView
