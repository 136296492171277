import axios from 'axios'
import { useEffect, useState } from 'react'

const useGetExport = () => {
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const getExport = async () => {
      try {
        request.setDisable && request.setDisable(true)
        const response = await axios.get(request.url, {
          responseType: 'arraybuffer',
        })
        let blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        let filename = response.headers['content-disposition']
          ? response.headers['content-disposition'].match(
              /application; filename="(?<filename>.*)"/
            ).groups.filename
          : ''
        const href = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', filename ? filename : 'export.xlsx')
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(href)
        link.remove()
      } catch (error) {
        console.log(error)
      }
      request.setDisable && mounted && request.setDisable(false)
      mounted && setRequest(null)
    }
    if (request) {
      getExport()
    }
    return () => {
      mounted = false
    }
  }, [request])

  return [setRequest]
}
export default useGetExport
