const ReorderArray = (array, oldIndex, newIndex, propName, all) => {
  if (oldIndex === newIndex) return all ? array : []
  let min = all ? 0 : Math.min(oldIndex, newIndex)
  let max = all ? array.length - 1 : Math.max(oldIndex, newIndex)
  if (min < 0) throw new Error()
  if (max >= array.length) throw new Error()

  let element = array.splice(oldIndex, 1)[0]
  array.splice(newIndex, 0, element)

  let newArray = []
  for (let i = min; i <= max; i++) {
    let curElem = array[i]
    let newElem = all
      ? curElem
      : {
          id: curElem.id,
          lastUpdatedTimestamp: curElem.lastUpdatedTimestamp,
        }
    if (propName) newElem[propName] = i
    newArray.push(newElem)
  }
  return newArray
}
export default ReorderArray
