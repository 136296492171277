import {
  Button,
  Form,
  Heading,
  Modal,
  Grid,
  Dialog,
} from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import MarketListTooltip from '../components/MarketListTooltip'
import SelectAllCheckbox from '../components/SelectAllCheckbox'
import appConfig from '../config/appConfig'

import AddDummy from './AddDummy'

const AddItem = (props) => {
  const {
    getItems,
    putCut,
    addItems,
    setAddItems,
    postDummy,
    closeEditCutModal,
    canSpawnArtPatches,
    spawnArtPatch,
    editCut,
    cutTimestampRef,
  } = props

  const [spawnArtPatchDialog, setSpawnArtPatchDialog] = useState(false)
  const [itemOffers, setItemOffers] = useState([])
  const [addDummy, setAddDummy] = useState({
    cutId: null,
    pageId: null,
    show: false,
    storyId: null,
  })

  const spawnArtPatches = () => {
    spawnArtPatch({
      body: {
        cuts: [
          {
            id: editCut?.body?.id,
            lastUpdatedTimestamp: cutTimestampRef.current,
          },
        ],
        id: editCut?.storyId,
        lastUpdatedTimestamp: editCut?.storyLastUpdatedTimestamp,
      },
      id: editCut?.storyId,
    })
  }

  const config = appConfig()

  const wipeState = () => {
    setItemOffers([])
    setAddItems({})
    setSpawnArtPatchDialog(false)
  }

  useEffect(() => {
    if (addItems.cutId) {
      getItems({
        cutId: addItems.cutId,
        setItemOffers: setItemOffers,
        then: () => setAddItems((cur) => ({ ...cur, show: true })),
      })
    }
  }, [addItems.cutId, getItems, setAddItems])

  const discountVaries =
    addItems?.offers?.length > 1 ||
    addItems?.offers?.some((offer) => offer.discountVaries)

  return (
    <Formik
      enableReinitialize
      initialValues={{
        addItem_newItems: [],
      }}
      onSubmit={(values, funcs) => {
        putCut({
          body: {
            id: addItems.cutId,
            lastUpdatedTimestamp: addItems.cutTimestampRef.current,
            newItems: values.addItem_newItems
              .map(JSON.parse)
              .sort(
                (a, b) =>
                  //sort by priority then by description
                  a.priority - b.priority || a.description - b.description
              )
              .map((item) => ({
                dpci: item.dpci,
                offerId: item.offerId,
                tcin: item.tcin,
              })),
          },
          pageId: addItems.pageId,
          storyId: addItems.storyId,
          storyLastUpdatedTimestamp: addItems.storyLastUpdatedTimestamp,
        })
        closeEditCutModal()
        funcs.resetForm()
        wipeState()
      }}
    >
      {({ values, handleSubmit, handleReset, setFieldValue }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }

        const closeAllModals = () => {
          closeEditCutModal()
          handleDialogClose()
        }

        const patchContainer = (patches) =>
          patches.map((patch) => (
            <React.Fragment key={patch.id}>
              {patches.length > 1 && (
                <Heading size={4}>
                  Patch for:{' '}
                  <MarketListTooltip markets={patch.markets} size={3} />
                </Heading>
              )}
              <SelectAllCheckbox
                fieldName="addItem_newItems"
                id={`${patch.id}_select_all`}
                setFieldValue={setFieldValue}
                targetArr={values.addItem_newItems}
                valueArr={patch.ivyItems.map((item) => JSON.stringify(item))}
              />
              <Form.Field
                id={patch.id}
                type="checkboxes"
                value={values.addItem_newItems}
                options={patch.ivyItems.map((item) => ({
                  key: item.tcin,
                  label: (
                    <React.Fragment>
                      {discountVaries && `${item.promoUnitRetail}. `}
                      {item.dpci} {item.description}
                    </React.Fragment>
                  ),
                  value: JSON.stringify(item),
                }))}
                onUpdate={(_, newArray) => {
                  setFieldValue('addItem_newItems', newArray)
                }}
              />
            </React.Fragment>
          ))

        const offerContainer = (offers) =>
          offers.map((offer) => (
            <React.Fragment key={offer.id}>
              {offers.length > 1 && <Heading size={3}>{offer.name}</Heading>}
              <div spacing="dense" className="hc-pa-normal">
                {patchContainer(offer.offerArtPatches)}
              </div>
            </React.Fragment>
          ))

        return (
          <Modal isVisible={addItems.show} onRefuse={handleDialogClose}>
            <div className="hc-pa-normal">
              {itemOffers.length ? (
                <div>
                  <Grid.Container justify="space-between">
                    <Grid.Item>
                      <Heading size={4}>Add Items</Heading>
                    </Grid.Item>
                    {canSpawnArtPatches && (
                      <Grid.Item>
                        <Button
                          className="hc-ta-right"
                          type="primary"
                          onClick={() => setSpawnArtPatchDialog(true)}
                        >
                          Spawn Art Patches
                        </Button>
                      </Grid.Item>
                    )}
                  </Grid.Container>

                  {offerContainer(itemOffers)}
                </div>
              ) : (
                <div className="hc-pa-none">
                  <Heading size={3}>
                    Any pictured items in this offer were already assigned
                  </Heading>
                  <br />
                  {config.dummyDivisions.includes(addItems.divisionId) ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        setAddDummy({
                          cutId: addItems.cutId,
                          pageId: addItems.pageId,
                          show: true,
                          storyId: addItems.storyId,
                        })
                      }}
                    >
                      Add Placeholder Item
                    </Button>
                  ) : null}
                </div>
              )}
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!values.addItem_newItems.length}
              />
            </div>
            <AddDummy
              addDummy={addDummy}
              setAddDummy={setAddDummy}
              postDummy={postDummy}
              closeAllModals={closeAllModals}
            />
            <Dialog
              headingText="Are you sure you want to spawn art patches?"
              isVisible={spawnArtPatchDialog}
              onApprove={() => {
                spawnArtPatches()
                closeAllModals()
              }}
              onRefuse={() => {
                setSpawnArtPatchDialog(false)
              }}
              approveButtonText="Yes"
              refuseButtonText="No"
            />
          </Modal>
        )
      }}
    </Formik>
  )
}

export default AddItem
