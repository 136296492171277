import {
  Grid,
  Heading,
  Card,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import ProductApi from '../api/pop-catalog/ProductApi'
import ReorderArray from '../util/ReorderArray'

import ExpandableProduct from './ExpandableProduct'

const SortableProductContainer = (props) => {
  const {
    catalog = {},
    products = {},
    page = {},
    triggerReorder,
    role = '',
    calltheProductApi,
  } = props
  const [reOrdering, setReordering] = useState(false)
  const [sortedProducts, setSortedProducts] = useState([])

  const productApi = new ProductApi()

  useEffect(() => {
    setSortedProducts(
      products.sort((a, b) => a.productOrdering - b.productOrdering)
    )
  }, [products, page, page.id])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setReordering(true)
    if (oldIndex !== newIndex) {
      const newOrder = ReorderArray(
        sortedProducts,
        oldIndex,
        newIndex,
        'productOrdering',
        true
      ).map((product) => ({
        page_id: page.id,
        product_id: product.id,
        product_ordering: product.productOrdering,
      }))
      setReordering(false)
      productApi.putProductOrdering(newOrder).then(() => {
        triggerReorder()
      })
    }
  }

  const SortableItem = SortableElement(({ product }) => (
    <>
      <Card className={`hc-ma-dense hc-bg-grey07`}>
        <ExpandableProduct
          product={product}
          page={page}
          role={role}
          catalog={catalog}
          view="creative"
          setProducts={setSortedProducts}
          calltheProductApi={calltheProductApi}
        />
      </Card>
    </>
  ))

  const SortableList = SortableContainer(() => (
    <div className="hc-pa-none">
      {sortedProducts.map((product) => (
        <SortableItem
          key={product.id}
          index={product.productOrdering}
          product={product}
          disabled={product.status === 'denied'}
        />
      ))}
    </div>
  ))

  if (!isEmpty(sortedProducts)) {
    return (
      <>
        <Grid.Container>
          <Grid.Item xs={12}>
            {reOrdering && <ProgressBar indeterminate />}
          </Grid.Item>
          <Grid.Item xs={12}>
            <SortableList onSortEnd={onSortEnd} useDragHandle />
          </Grid.Item>
        </Grid.Container>
      </>
    )
  } else {
    return (
      <div className="hc-pa-normal">
        <Heading className="hc-ta-center hc-pt-expanded">
          No products have been assigned to this page.
        </Heading>
      </div>
    )
  }
}
export default SortableProductContainer
