import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'
import coronaItemMapper from '../../mappers/coronaItemMapper'
import paginationMapper from '../../mappers/paginationMapper'
import productMapper from '../../mappers/productMapper'
import productOrderMapper from '../../mappers/productOrderMapper'

export default class ProductApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_items_api = () => {
    this.client = axios.create({
      baseURL: this.config.graphql.url,
    })
    delete this.client.defaults.headers.common['Authorization']
    delete this.client.defaults.headers.common['x-id-token']
    return this.client
  }

  init_product_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.catalog,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
    })
    return this.client
  }

  getProductBatch = async (query, catalogId) => {
    query.set('catalogId', catalogId)
    query.set('key', this.config.catapi.apikey)
    return await this.init_product_api()
      .get(this.config.backend.catalog + 'products?' + query)
      .then((res) => paginationMapper(res.data, productMapper, 'products'))
      .catch((e) => console.log(e))
  }

  getProductPageJoins = async (pageId) =>
    await this.init_product_api()
      .get(
        this.config.backend.catalog +
          `products/order/${pageId}?key=${this.config.catapi.apikey}`
      )
      .then((res) => paginationMapper(res.data, productOrderMapper, 'products'))
      .catch((e) => console.log(e))

  postProduct = async (productData) =>
    await this.init_product_api()
      .post(
        this.config.backend.catalogs.product +
          `?key=${this.config.catapi.apikey}`,
        productData
      )
      .then((res) => productMapper(res.data))

  putProduct = async (productData) =>
    await this.init_product_api()
      .put(
        this.config.backend.catalogs.product +
          productData?.id +
          `?key=${this.config.catapi.apikey}`,
        productData
      )
      .then((res) => productMapper(res.data))
      .catch((e) => console.log(e))

  getProductImage = async (query, productId) => {
    query.set('productId', productId)
    query.set('key', this.config.catapi.apikey)
    return await this.init_product_api()
      .get(this.config.backend.catalogs.product + 'image?' + query)
      .catch((e) => console.log(e))
  }
  putProductOrdering = async (data) =>
    await this.init_product_api()
      .put(
        this.config.backend.catalogs.product +
          `order?key=${this.config.catapi.apikey}`,
        data
      )
      .catch((e) => console.log(e))

  getCoronaInfo = async (dpci) =>
    await this.init_items_api()
      .post(this.config.graphql.url + `?key=${this.config.graphql.key}`, {
        query: `
        query ($dpci: String!) {
          items(type: DPCI, value: $dpci) {
            tcin
            alternateIdentifiers {
              dpci
            }
            brands {
              name
            }
            description {
              title
            }
            variations {
              themes {
                name 
                value
              }
            }
            merchandising {
              intendedSellingChannels
              merchandiseTypeAttributes {
                name
                values {
                  id
                  name
                  unitOfMeasure
                }
              }
            }
            operational {
              packageDimensions {
                measurements {
                  dimensions {
                    depth
                    width
                    height
                    units
                  }
                }
              }
            }
          }
        }`,
        variables: {
          dpci: dpci,
        },
      })
      .then((res) => coronaItemMapper(res?.data))
      .catch((e) => console.log(e))

  getPriceInfo = async (tcin) =>
    await axios
      .get(
        this.config.backend.catalog +
          'products/price/' +
          tcin +
          `?key=${this.config.catapi.apikey}`
      )
      .then((result) => result)
      .catch((e) => console.log(e))

  getLastPlaceholdDpci = async (catalogId) =>
    await this.init_product_api()
      .get(
        this.config.backend.catalogs.lastPlaceholdDpci +
          catalogId +
          `?key=${this.config.catapi.apikey}`
      )
      .then((res) => res.data)
      .catch((e) => console.log(e))

  putAcceptProducts = async (payload) =>
    await axios
      .put(
        this.config.backend.catalog +
          'products' +
          `?key=${this.config.catapi.apikey}`,
        payload
      )
      .then((result) => result)
      .catch((e) => console.log(e))
}
