import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'
import approvedVendorMapper from '../../mappers/approvedVendorMapper'
import catalogMapper from '../../mappers/catalogMapper'
import paginationMapper from '../../mappers/paginationMapper'

export default class CatalogApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_catalog_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.catalog,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getCatalogBatch = async (query) =>
    await this.init_catalog_api()
      .get(
        this.config.backend.catalogs.catalog +
          `?${query.toString()}` +
          `&key=${this.config.catapi.apikey}`
      )
      .then((res) => paginationMapper(res.data, catalogMapper, 'catalogs'))
      .catch((e) => console.log(e))

  getCatalog = async (catalogId, search = '') =>
    await this.init_catalog_api()
      .get(
        this.config.backend.catalogs.catalog +
          catalogId +
          `?key=${this.config.catapi.apikey}${search}`
      )
      .then((res) => catalogMapper(res.data))
      .catch((e) => console.log(e))

  getDistinctVendors = async (catalogId) =>
    await this.init_catalog_api()
      .get(
        this.config.backend.catalogs.distinctVendors +
          catalogId +
          `?key=${this.config.catapi.apikey}`
      )
      .then((res) => res.data)
      .catch((e) => console.log(e))

  postCatalog = async (catalogData) =>
    await this.init_catalog_api()
      .post(
        this.config.backend.catalogs.catalog +
          `?key=${this.config.catapi.apikey}`,
        catalogData
      )
      .then((res) => catalogMapper(res.data))
      .catch((e) => console.log(e))

  // Adding a new page to the catalog
  postCatalogPage = async (catalogId, catalogData) =>
    await this.init_catalog_api()
      .post(
        this.config.backend.catalogs.catalog +
          'catalogs/' +
          catalogId +
          '/pages' +
          `?key=${this.config.catapi.apikey}`,
        catalogData
      )
      .then((res) => catalogMapper(res.data))
      .catch((e) => console.log(e))

  putCatalog = async (catalogId, catalogData) =>
    await this.init_catalog_api()
      .put(
        this.config.backend.catalogs.catalog +
          catalogId +
          `?key=${this.config.catapi.apikey}`,
        catalogData
      )
      .then((res) => catalogMapper(res.data))
      .catch((e) => console.log(e))

  deleteCatalog = async (catalogId) =>
    await this.init_catalog_api()
      .delete(
        this.config.backend.catalogs.catalog +
          catalogId +
          `?key=${this.config.catapi.apikey}`
      )
      .catch((e) => console.log(e))

  getApprovedVendors = async () =>
    await this.init_catalog_api()
      .get(
        this.config.backend.catalogs.catalog +
          '/approvedVendors' +
          `?key=${this.config.catapi.apikey}`
      )
      .then((res) => res.data.map((vendor) => approvedVendorMapper(vendor)))
      .catch((e) => console.error(e))
}
