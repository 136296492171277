import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import storyMapper from '../mappers/storyMapper'
import ReplaceFirst from '../util/ReplaceFirst'

const useSpawnArtPatch = (setLoading, setWeeklyAd) => {
  const config = appConfig()
  const url = useRef(
    config.backend.root + config.backend.story + config.backend.spawnArtPatches
  )
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const spawnArtPatches = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.put(url.current + request.id, request.body)
        const thisStory = storyMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            ReplaceFirst(
              w.pages.find((page) => page.id === thisStory.pageId).stories,
              thisStory.id,
              thisStory
            )
            return w
          })
      } catch (e) {
        console.log(e)
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }
    if (request !== null) {
      spawnArtPatches()
    }
    return () => {
      mounted = false
    }
  }, [setLoading, request, setWeeklyAd])

  return setRequest
}
export default useSpawnArtPatch
