import {
  Dialog,
  Form,
  Grid,
  Heading,
  Modal,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { User } from '../util/AuthUtils'
import { formatToDateTime } from '../util/MomentUtil'

import EditItemImages from './EditItemImages'

let editItemSchema = yup.object().shape({
  edit_item_description: yup.string().required('Required'),
  edit_item_image_type: yup.string().oneOf(['pickup', 'vendor']),
  edit_item_regUnitRetail: yup.string().matches(/^\d*(?:\.(?!00)\d{2})?$/, {
    message: 'Retail Price in format 99.99',
  }),
  edit_item_sample: yup.string().oneOf(['inventory', 'turnIn', 'missing']),
  edit_item_sampleId: yup.string().when('edit_item_sample', {
    is: (sample) => sample === 'inventory',
    otherwise: yup.string(),
    then: yup.string().required('SampleID required when status: inventory'),
  }),
})

const EditItem = (props) => {
  const { editItem, setEditItem, putItem, putCut } = props
  const [isDeleteDialog, setDeleteDialog] = useState(false)
  const user = User()

  const wipeState = () => {
    setEditItem({
      body: {},
      cutId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
    setDeleteDialog(false)
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={editItemSchema}
      initialValues={{
        edit_item_alternate_images: editItem.body.alternateImages || [],
        edit_item_description: editItem.body.description || 'test',
        edit_item_digital_item_primary_image:
          editItem.body.edit_item_image_file_name || null,
        edit_item_figModel: Boolean(editItem.body.figModel),
        edit_item_image: editItem.body.image || null,
        edit_item_image_file_name: editItem.body.imageFileName || '',
        edit_item_image_type: editItem.body.imageType || '',
        edit_item_merch_notes: editItem.body.merchNotes || '',
        edit_item_original_image: editItem.body.originalTCINImage || null,
        edit_item_regPriceCallout: Boolean(editItem.body.regPriceCallout),
        edit_item_regUnitRetail: editItem.body.regUnitRetail || '',
        edit_item_sample: editItem.body.sample || '',
        edit_item_sampleId: editItem.body.sampleId || '',
        edit_item_sampleImageComments: editItem.body.notes || '',
      }}
      onSubmit={(values, funcs) => {
        putItem({
          body: {
            alternateImages: values.edit_item_alternate_images,
            description: values.edit_item_description,
            figModel: values.edit_item_figModel,
            id: editItem.body.id,
            image: values.edit_item_image,
            imageFileName: values.edit_item_image_file_name,
            imageType: values.edit_item_image_type,
            lastUpdatedTimestamp: editItem.body.lastUpdatedTimestamp,
            merchNotes: values.edit_item_merch_notes,
            regPriceCallout: values.edit_item_regPriceCallout,
            regUnitRetail: values.edit_item_regUnitRetail,
            sample: values.edit_item_sample,
            sampleId: values.edit_item_sampleId,
            sampleImageComments: values.edit_item_sampleImageComments,
          },
          cutId: editItem.cutId,
          pageId: editItem.pageId,
          storyId: editItem.storyId,
          user,
        })
        funcs.resetForm()
        wipeState()
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        const onDelete = () => {
          putCut({
            body: {
              id: editItem.cutId,
              items: [
                {
                  deleted: true,
                  id: editItem.body.id,
                  lastUpdatedTimestamp: editItem.body.lastUpdatedTimestamp,
                },
              ],
              lastUpdatedTimestamp: editItem.cutLastUpdatedTimestamp,
            },
            pageId: editItem.pageId,
            storyId: editItem.storyId,
          })
          handleDialogClose()
        }

        return (
          <Modal isVisible={editItem.show} onRefuse={handleDialogClose}>
            <Heading
              size={4}
              className="hc-pt-expanded hc-pl-expanded hc-pr-expanded"
            >
              {editItem.hasEditPrivileges
                ? `Edit Item ${editItem.body.dpci} ${editItem.body.description}`
                : 'Item details'}
              {!editItem?.cutValid && (
                <p
                  style={{
                    color: 'red',
                    fontSize: '15px',
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  Pictured Status Missing
                </p>
              )}
              <Tooltip
                location={'left'}
                style={{ float: 'right' }}
                content={`Item Revised: ${
                  editItem.merchLastUpdatedField
                } at ${formatToDateTime(editItem.merchLastUpdatedTimestamp)}`}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  size="xs"
                  className="hc-clr-grey03"
                />
              </Tooltip>
            </Heading>
            <div className="hc-pa-normal hc-pb-none">
              <fieldset disabled={!editItem.hasEditPrivileges}>
                <Grid.Container align="center" spacing="dense">
                  <Grid.Item xs={12}>
                    <Form.Field
                      id="edit_item_description"
                      value={values.edit_item_description}
                      label="Item Name"
                      onChange={handleChange}
                      error={errors.edit_item_description}
                      errorText={errors.edit_item_description}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <EditItemImages
                      parentSetFieldValue={setFieldValue}
                      parentValues={values}
                      editItem={editItem}
                      disabled={!editItem.hasEditPrivileges}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_item_regUnitRetail"
                      value={values.edit_item_regUnitRetail}
                      label="Regular Unit Retail"
                      onChange={handleChange}
                      error={errors.edit_item_regUnitRetail}
                      errorText={errors.edit_item_regUnitRetail}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_item_regPriceCallout"
                      type="toggle"
                      value={values.edit_item_regPriceCallout}
                      label="Regular $ Callout"
                      onChange={() =>
                        setFieldValue(
                          'edit_item_regPriceCallout',
                          !values.edit_item_regPriceCallout
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      type="select"
                      id="edit_item_image_type"
                      label="Image Type"
                      value={values.edit_item_image_type}
                      onUpdate={setFieldValue}
                      options={[
                        { label: '', value: '' },
                        { label: 'Pickup Image', value: 'pickup' },
                        { label: 'Vendor Image', value: 'vendor' },
                      ]}
                      error={errors.edit_item_image_type}
                      errorText={errors.edit_item_image_type}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_item_image_file_name"
                      label="Image File Name"
                      value={values.edit_item_image_file_name}
                      onChange={handleChange}
                      error={errors.edit_item_image_file_name}
                      errorText={errors.edit_item_image_file_name}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      type="select"
                      id="edit_item_sample"
                      label="Sample Status"
                      value={values.edit_item_sample}
                      onUpdate={setFieldValue}
                      options={[
                        { label: '', value: '' },
                        { label: 'Inventory', value: 'inventory' },
                        { label: 'Turn-in', value: 'turnIn' },
                        { label: 'Missing', value: 'missing' },
                      ]}
                      error={errors.edit_item_sample}
                      errorText={errors.edit_item_sample}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_item_sampleId"
                      value={values.edit_item_sampleId}
                      label="Sample ID"
                      onChange={handleChange}
                      error={errors.edit_item_sampleId}
                      errorText={errors.edit_item_sampleId}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_item_figModel"
                      type="toggle"
                      value={values.edit_item_figModel}
                      label="Fig Model"
                      onChange={() =>
                        setFieldValue(
                          'edit_item_figModel',
                          !values.edit_item_figModel
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      id="edit_item_merch_notes"
                      value={values.edit_item_merch_notes}
                      label="Merch Notes"
                      onChange={handleChange}
                    />
                  </Grid.Item>
                </Grid.Container>
              </fieldset>
              <CancelSubmitButtons
                handleDelete={setDeleteDialog}
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!editItem.hasEditPrivileges}
                deleteDisabled={!editItem.hasEditPrivileges}
              />
            </div>
            <Dialog
              isVisible={isDeleteDialog}
              headingText="Are you sure want to delete this item?"
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText="Delete"
            />
            <p
              className="hc-ta-center hc-clr-grey03 hc-pb-dense"
              style={{ fontSize: 12 }}
            >
              {`Item Added: ${formatToDateTime(editItem.createdTimestamp)}`}
            </p>
          </Modal>
        )
      }}
    </Formik>
  )
}
export default EditItem
