/**
 * This filter looks for a block match in possible values.
 *
 * Example: "cat" matches "cat & jack", but not "purrfect cat"
 */
export default function startsWithFilter(searchText, { label }) {
  const compareString = label.toString().toLowerCase()
  searchText = searchText.toString().toLowerCase()

  return compareString.indexOf(searchText) === 0
}
