import axios from 'axios'
import { useEffect, useState } from 'react'

import appConfig from '../config/appConfig'
import lateAdditionMapper from '../mappers/lateAdditionMapper'
import paginationMapper from '../mappers/paginationMapper'

const useGetLateAddition = (setLoading, query, wadId) => {
  const config = appConfig()
  const url =
    config.backend.root +
    config.backend.lateItems +
    `?${query.toString()}&wadId=${wadId}`
  const [lateAdditions, setLateAdditions] = useState({})
  const [loadLateAdditions, setLoadLateAdditions] = useState(true)

  useEffect(() => {
    let mounted = true
    const getLateAdditions = async () => {
      mounted && setLoading(true)
      try {
        const response = await axios.get(url)
        mounted &&
          setLateAdditions(
            paginationMapper(response.data, lateAdditionMapper, 'lateItems')
          )
      } catch (e) {
        console.log(e)
      }
      mounted && setLoadLateAdditions(false)
      mounted && setLoading(false)
    }

    if (loadLateAdditions) {
      getLateAdditions()
    }
    return () => {
      mounted = false
    }
  }, [url, setLoading, loadLateAdditions])

  return [lateAdditions, setLoadLateAdditions]
}
export default useGetLateAddition
