import { Button } from '@enterprise-ui/canvas-ui-react'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../scss/DialogView.scss'

const DialogView = ({ isVisible = false, onClose, children, toolbar }) => {
  const onDialogClose = (event) => {
    if (event.target.id === 'filter') {
      onClose(false)
    }
  }

  return isVisible ? (
    <div className="dialog-view-filter" onClick={onDialogClose}>
      <div className="dialog-view-container">{children}</div>
      <div className="dialog-view-close">
        {toolbar ? toolbar : null}
        <Button iconOnly onClick={() => onClose(false)}>
          <FontAwesomeIcon size="lg" icon={faTimesCircle} color="#000000" />
        </Button>
      </div>
    </div>
  ) : null
}

export default DialogView
