import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

export default function TextFieldWrapper(props) {
  return (
    <TextField
      {...props}
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start"> </InputAdornment>,
      }}
    />
  )
}
