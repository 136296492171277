import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect } from 'react'

import '../../planning/scss/InlineEditInput.scss'

const ContentDetailsHalfRow = ({
  title,
  value,
  updateValue,
  disabled,
  textarea = false,
  id,
  setChangeDetected,
}) => {
  const [editingValue, setEditingValue] = useState('')

  useEffect(() => {
    setEditingValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <Grid.Item className="category-label" xs={2}>
        {title}
      </Grid.Item>
      <Grid.Item className="category-input" xs={4}>
        <Form.Field
          value={editingValue}
          onBlur={(event) => {
            if (event.target.value !== value) {
              updateValue(editingValue)
              setChangeDetected(true)
            }
          }}
          onChange={(event) => setEditingValue(event.target.value)}
          data-testid={`${title}-input-${id}`}
          placeholder=""
          disabled={disabled}
          type={textarea ? '' : 'text'}
          size={textarea ? 'dense' : null}
          id={`${title}-input-${id}`}
        />
      </Grid.Item>
    </>
  )
}

export default ContentDetailsHalfRow
