import { Card, Grid, Heading, Button } from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import CatalogApi from '../../api/pop-catalog/CatalogApi'
import ExportApi from '../../api/pop-catalog/ExportApi'
import LoadingSpinner from '../../components/LoadingSpinner'
import AddCatalogPage from '../../forms/AddCatalogPage'
import EditCatalog from '../../forms/EditCatalog'
import CatalogPaginationTable from '../../tables/CatalogPaginationTable'
import {
  canEditUpcomingCatalogs,
  canEditPastCatalogs,
} from '../../util/CheckRole'
import { CATALOG_PAGINATION_EXPORT_URL } from '../../util/urlUtil'

// Pagination Screen

const CatalogPagination = (props) => {
  const { role } = props

  const catalogId = useParams().catalogId
  const [catalogLoading, setCatalogLoading] = useState(false)
  const [editCatalogVisible, setEditCatalogVisible] = useState(false)
  const [isAddCatalogVisible, setAddCatalogVisible] = useState(false)
  const [catalog, setCatalog] = useState({})

  const location = useLocation()

  const archive = location.state?.archive

  const canEdit = archive
    ? role.some((r) => canEditPastCatalogs(r))
    : role.some((r) => canEditUpcomingCatalogs(r))

  const catalogApi = new CatalogApi()
  const exportApi = new ExportApi()

  useEffect(() => {
    setCatalogLoading(true)
    catalogApi
      .getCatalog(catalogId)
      .then((res) => setCatalog(res))
      .finally(() => setCatalogLoading(false))
    // eslint-disable-next-line
  }, [setCatalog, setCatalogLoading])

  return catalog ? (
    <>
      <Card>
        <div className="hc-pa-dense">
          <Grid.Container spacing="dense">
            <Grid.Item xs={12} className="hc-ta-center">
              <Heading size={2}> {catalog?.name}</Heading>
            </Grid.Item>
            <Grid.Container justify="center"></Grid.Container>
            <Grid.Item xs={12} className="hc-ta-center">
              <strong>Run Date:</strong> {catalog?.runDate}
              {' | '} <strong>End Date: </strong>
              {catalog?.endDate}
            </Grid.Item>
            <Grid.Item xs={12} className="hc-ta-center">
              <strong>Project Captain: </strong>
              {catalog?.projectCaptain?.join(', ')}
            </Grid.Item>
            <Grid.Item xs={12} className="hc-ta-center">
              <strong>Merchant Captain: </strong>
              {catalog?.merchantCaptain?.join(', ')}
            </Grid.Item>
            <Grid.Item xs={12} className="hc-ta-center">
              <strong>Creative Captain: </strong>
              {catalog?.creativeCaptain?.join(', ')}
            </Grid.Item>
            <Grid.Item xs={12} className="hc-ta-center">
              <Grid.Container justify="center" spacing="dense">
                <Grid.Item>
                  <Button
                    type="secondary"
                    onClick={() =>
                      exportApi.getExport(
                        CATALOG_PAGINATION_EXPORT_URL(catalogId)
                      )
                    }
                  >
                    EXPORT
                  </Button>
                </Grid.Item>

                {canEdit && (
                  <>
                    <Grid.Item>
                      <Button
                        className="hc-mb-none hc-mt-none"
                        type="primary"
                        onClick={() => setEditCatalogVisible(true)}
                      >
                        Edit Catalog
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        className="hc-mb-none hc-mt-none"
                        type="primary"
                        onClick={() => setAddCatalogVisible(true)}
                      >
                        Add Page
                      </Button>
                    </Grid.Item>
                  </>
                )}
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
        <div className="hc-pa-normal">
          <CatalogPaginationTable
            catalog={catalog}
            role={role}
            setCatalog={setCatalog}
            archive={archive}
          />
        </div>
      </Card>
      <LoadingSpinner show={catalogLoading} />
      <EditCatalog
        catalog={catalog}
        show={editCatalogVisible}
        setShow={setEditCatalogVisible}
        role={role}
        setCatalog={setCatalog}
      />
      <AddCatalogPage
        catalogId={catalog.id}
        show={isAddCatalogVisible}
        setShow={setAddCatalogVisible}
        setCatalog={setCatalog}
      />
    </>
  ) : (
    <>
      <Card>
        <Heading>This catalog is not valid</Heading>
      </Card>
    </>
  )
}
export default CatalogPagination
