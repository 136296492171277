import {
  Button,
  Card,
  Grid,
  Heading,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import ExpandableMarkets from '../../components/ExpandableMarkets'
import LoadingSpinner from '../../components/LoadingSpinner'
import SortablePageTable from '../../components/SortablePageTable'
import appConfig from '../../config/appConfig'
import AddRemovePages from '../../forms/AddRemovePages'
import ChangePageType from '../../forms/ChangePageType'
import EditWeeklyAdDetails from '../../forms/EditWeeklyAdDetails'
import useDeleteWad from '../../hooks/useDeleteWad'
import useExport from '../../hooks/useGetExport'
import useGetWeeklyAd from '../../hooks/useGetWeeklyAd'
import usePutPage from '../../hooks/usePutPage'
import usePutWeeklyAd from '../../hooks/usePutWeeklyAd'
import {
  canEditWeeklyAdsPast,
  canEditWeeklyAdsUpcoming,
} from '../../util/CheckRole'

const WeeklyAd = (props) => {
  const { role } = props
  const wadId = useParams().wadId
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  const hasEditPrivileges = location.search.includes('hasRun=true')
    ? role.some((r) => canEditWeeklyAdsPast(r))
    : role.some((r) => canEditWeeklyAdsUpcoming(r))

  const deleteWad = useDeleteWad(setLoading)
  const [changePagesModal, setChangePagesModal] = useState({
    digitalPages: [],
    page: {},
    printPages: [],
    show: false,
  })

  const makeToast = ToastProvider.useToaster()
  const [weeklyAd, setWeeklyAd] = useGetWeeklyAd(wadId, setLoading, makeToast)
  const putPage = usePutPage(setLoading, setWeeklyAd, makeToast)

  const [showEditDetails, setShowEditDetails] = useState(false)
  const [showAddRemovePages, setShowAddRemovePages] = useState(false)

  const [expandMilestones, setExpandMilestones] = useState(false)

  const putWad = usePutWeeklyAd(wadId, setLoading, setWeeklyAd, makeToast)
  const config = appConfig()
  const exportUrl =
    config.backend.root +
    config.backend.export +
    config.backend.pagination +
    wadId
  const [getExport] = useExport(makeToast)
  const [disableExport, setDisableExport] = useState(false)
  const [changePages, setChangePages] = useState(false)

  const flipPages = () => {
    setChangePages(false)
  }

  return (
    <Card elevation={2} className="hc-pa-dense hc-ta-center">
      <Grid.Container align="center">
        <Grid.Item xs={12} className="hc-pt-none hc-ta-center">
          <Heading size={2}>{weeklyAd.eventWeek}</Heading>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pt-none">
          <Grid.Container
            className="hc-ta-center hc-pa-none"
            spacing="dense"
            justify="center"
          >
            {hasEditPrivileges ? (
              <React.Fragment>
                <Grid.Item>
                  <Button
                    type="primary"
                    onClick={() => setShowEditDetails(true)}
                  >
                    Edit Book Info
                  </Button>
                </Grid.Item>

                <Grid.Item>
                  <Button
                    type="primary"
                    onClick={() => setShowAddRemovePages(true)}
                  >
                    Add/Remove Pages
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      changePages ? flipPages() : setChangePages(true)
                    }}
                  >
                    Update Page Type
                  </Button>
                </Grid.Item>
              </React.Fragment>
            ) : null}{' '}
            <Grid.Item>
              <Button
                disabled={disableExport}
                type="secondary"
                onClick={() =>
                  getExport({ setDisable: setDisableExport, url: exportUrl })
                }
              >
                Export
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item className="hc-pa-none" xs={12}>
          <Heading size={4}>
            Start Date:{' '}
            <DateFormatter date={weeklyAd.startDate} format="ddd MMM DD YYYY" />
          </Heading>
        </Grid.Item>

        <Grid.Item className="hc-pa-none" xs={12}>
          <Heading size={4}>Project Name: {weeklyAd.projectName}</Heading>
        </Grid.Item>
        {weeklyAd.midweek ? (
          <>
            <Grid.Item xs={12} className="hc-pa-none">
              <strong>Headline Writer: </strong>
              {weeklyAd.headlineWriter} {'|'} <strong>EP Captain: </strong>
              {weeklyAd.epCaptain}
              <Grid.Item spacing="none" className="hc-ta-center">
                <strong> Revision: </strong>
                {weeklyAd.revision}
              </Grid.Item>
            </Grid.Item>
          </>
        ) : (
          <>
            <Grid.Item xs={12} className="hc-pa-none">
              <strong>Project Manager: </strong>
              {weeklyAd?.contact?.projectManager} {'|'}{' '}
              <strong>EP Captain: </strong>
              {weeklyAd.epCaptain}
              <Grid.Item xs={12} className="hc-pa-none">
                <strong>Headline Writer: </strong>
                {weeklyAd.headlineWriter} {'|'}{' '}
                <strong>Digital Writers: </strong>
                {weeklyAd?.contact?.digitalWriters}
                <Grid.Item spacing="none" className="hc-ta-center">
                  <strong> Revision: </strong>
                  {weeklyAd.revision}
                </Grid.Item>
              </Grid.Item>
            </Grid.Item>
          </>
        )}
      </Grid.Container>

      <div className="hc-ta-left" padding="none">
        <div className="expandable-milestones" style={{ display: 'flex' }}>
          <FontAwesomeIcon
            display={'inline-block'}
            className={`clickable milestones-expander ${
              expandMilestones
                ? 'rotate-clockwise-45'
                : 'rotate-counter-clockwise-45'
            }`}
            icon={faAngleDown}
            onClick={(e) => {
              e.preventDefault()
              setExpandMilestones(!expandMilestones)
            }}
            size="lg"
            data-testid="expand-milestones"
          />
          <p style={{ paddingLeft: '10px' }}>Milestone Dates</p>
        </div>
        {expandMilestones && (
          <div>
            <Grid.Container spacing="dense">
              <Grid.Item xs={2} className="hc-ta-right">
                <strong>Kickoff Meeting: </strong>
              </Grid.Item>
              <Grid.Item xs={2}>
                <DateFormatter
                  date={weeklyAd.creativeKickoffDate}
                  format="ddd MMM DD YYYY"
                />
              </Grid.Item>
              <Grid.Item xs={2} className="hc-ta-right">
                <strong>Pre-Photo Review: </strong>
              </Grid.Item>
              <Grid.Item xs={2}>
                <DateFormatter
                  date={weeklyAd.prePhotoLayoutDate}
                  format="ddd MMM DD YYYY"
                />
              </Grid.Item>
              <Grid.Item xs={2} className="hc-ta-right">
                <strong>M/P Review: </strong>
              </Grid.Item>
              <Grid.Item xs={2}>
                <DateFormatter
                  date={weeklyAd.merchantPartnerDate}
                  format="ddd MMM DD YYYY"
                />
              </Grid.Item>
              <Grid.Item xs={2} className="hc-ta-right">
                <strong>Lockout: </strong>
              </Grid.Item>
              <Grid.Item xs={2}>
                <DateFormatter
                  date={weeklyAd.lockoutDate}
                  format="ddd MMM DD YYYY"
                />
              </Grid.Item>
              <Grid.Item xs={2} className="hc-ta-right">
                <strong>Post-Photo Review: </strong>
              </Grid.Item>
              <Grid.Item xs={2}>
                <DateFormatter
                  date={weeklyAd.postPhotoLayoutDate}
                  format="ddd MMM DD YYYY"
                />
              </Grid.Item>
              <Grid.Item xs={2} className="hc-ta-right">
                <strong>Color Approval: </strong>
              </Grid.Item>
              <Grid.Item xs={2}>
                <DateFormatter
                  date={weeklyAd.colorApprovalDate}
                  format="ddd MMM DD YYYY"
                />
              </Grid.Item>
            </Grid.Container>
          </div>
        )}
      </div>

      <ExpandableMarkets weeklyAd={weeklyAd} />

      <div>
        <SortablePageTable
          weeklyAd={weeklyAd}
          setWeeklyAd={setWeeklyAd}
          putWad={putWad}
          putPage={putPage}
          setLoading={setLoading}
          hasEditPrivileges={hasEditPrivileges}
          changePages={changePages}
          setChangePagesModal={setChangePagesModal}
        />
        <EditWeeklyAdDetails
          canDelete={hasEditPrivileges}
          show={showEditDetails}
          setShow={setShowEditDetails}
          weeklyAd={weeklyAd}
          putWad={putWad}
          deleteWad={deleteWad}
        />
        <AddRemovePages
          show={showAddRemovePages}
          setShowModal={setShowAddRemovePages}
          weeklyAd={weeklyAd}
          putWad={putWad}
        />
        <ChangePageType
          putWad={putWad}
          changePages={changePagesModal}
          setChangePagesModal={setChangePagesModal}
          setChangePages={setChangePages}
          weeklyAd={weeklyAd}
        />
        <LoadingSpinner show={loading} />
      </div>
    </Card>
  )
}
export default WeeklyAd
