import { Button, Dialog, Form, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import { useState } from 'react'

import CancelSubmitButtons from '../components/CancelSubmitButtons'

import AddCut from './AddCut'

const EditStory = (props) => {
  const {
    editStory,
    setEditStory,
    putStory,
    postCut,
    getOffers,
    weeklyAd,
    putPage,
  } = props

  const [isDeleteDialog, setDeleteDialog] = useState(false)
  const [showAddCut, setShowAddCut] = useState(false)

  const wipeState = () => {
    setEditStory({
      body: {},
      divisions: [],
      pageId: null,
      show: false,
    })
    setDeleteDialog(false)
    setShowAddCut(false)
  }

  return editStory ? (
    <Formik
      enableReinitialize
      initialValues={{
        edit_story_name: editStory.body.name || '',
        edit_story_promoMessaging: editStory.body.promoMessaging || '',
      }}
      onSubmit={(values, funcs) => {
        putStory({
          body: {
            id: editStory.body.id,
            lastUpdatedTimestamp: editStory.body.lastUpdatedTimestamp,
            name: values.edit_story_name,
            promoMessaging: values.edit_story_promoMessaging,
          },
          pageId: editStory.page.id,
        })
        funcs.resetForm()
        wipeState()
      }}
    >
      {({ values, handleBlur, handleChange, handleSubmit, handleReset }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        const onDelete = () => {
          putPage({
            id: editStory.page.id,
            lastUpdatedTimestamp: editStory.page.lastUpdatedTimestamp,
            stories: [
              {
                deleted: true,
                id: editStory.body.id,
                lastUpdatedTimestamp: editStory.body.lastUpdatedTimestamp,
              },
            ],
          })
          handleDialogClose()
        }
        return (
          <Modal
            isVisible={editStory.show}
            headingText={`Edit Story ${editStory.body.priority + 1}`}
            onRefuse={handleDialogClose}
          >
            <fieldset disabled={!editStory.hasEditPrivileges}>
              <div className="hc-pa-normal">
                <Form.Field
                  id="edit_story_name"
                  onChange={handleChange}
                  label="Story Name"
                  value={values.edit_story_name}
                  onBlur={handleBlur}
                />
                <Form.Field
                  id="edit_story_promoMessaging"
                  value={values.edit_story_promoMessaging}
                  label="Promo Message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Button
                  type="primary"
                  disabled={!editStory.hasEditPrivileges}
                  onClick={() => setShowAddCut(true)}
                >
                  Add Cut
                </Button>
                <CancelSubmitButtons
                  handleDelete={setDeleteDialog}
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                  submitDisabled={!editStory.hasEditPrivileges}
                  deleteDisabled={!editStory.hasEditPrivileges}
                />
              </div>
            </fieldset>
            <Dialog
              isVisible={isDeleteDialog}
              headingText="Are you sure want to delete this story?"
              onRefuse={() => setDeleteDialog(false)}
              refuseButtonText="Cancel"
              onApprove={onDelete}
              approveButtonText="Delete"
            />
            <AddCut
              editStory={editStory}
              show={showAddCut}
              setShow={setShowAddCut}
              prevCleanup={wipeState}
              postCut={postCut}
              getOffers={getOffers}
              weeklyAd={weeklyAd}
              hasEditPrivileges={editStory.hasEditPrivileges}
            />
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditStory
