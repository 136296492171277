import { sortBy } from 'lodash'

import { formatter } from '../util/ProjectDetailsUtil'

export const getTotalPromoValue = (umbrellas) =>
  umbrellas
    .map((umbrella) => umbrella.forecast.total_promo_sales)
    .filter((element) => typeof element === 'number')
    .reduce((previous, current) => previous + current, 0) > 0
    ? formatter.format(
        umbrellas
          .map((umbrella) => umbrella.forecast.total_promo_sales)
          .filter((element) => typeof element === 'number')
          .reduce((previous, current) => previous + current, 0)
      )
    : ' ---'

export const sortStoriesByOrder = (stories = []) =>
  sortBy(stories, (a) => a.story_order) ?? []
