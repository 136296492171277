import { Table, Chip, Heading } from '@enterprise-ui/canvas-ui-react'
import { faChild } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'
import { useState } from 'react'

import IndicatorChip from '../components/IndicatorChip'
import '../scss/POP-table.scss'
import EditImageSampleProduct from '../forms/EditImageSampleProduct'
import { CategoryColorMap } from '../util/CategoryColorMap'

const columnData = [
  { id: 'page_ordering', label: 'Page No.', size: 1 },
  { id: 'category', label: 'Category', size: 1 },
  { id: 'dpci', label: 'DPCI', size: 1 },
  { id: 'name', label: 'Product Name', size: 2 },
  { id: 'sample_status', label: 'Sample Status', size: 1 },
  { id: 'fig_model', label: 'Fig Model', size: 1 },
  { id: 'art_director_request', label: 'Shooting', size: 1 },
  { id: 'image_sample_collected', label: 'Image/Sample Collected', size: 1 },
  { id: 'image_approved', label: 'Image Approved', size: 1 },
  { id: 'sample_submitted', label: 'Sample submitted', size: 1 },
]

const ProductListTable = (props) => {
  const { products, setProducts } = props
  const [editProduct, setEditProduct] = useState({ body: {}, show: false })

  return !isEmpty(products) ? (
    <>
      <Table>
        <Table.Head className="product-list-table-header">
          <Table.Row className="product-list-table-row">
            {columnData.map((col) => (
              <Table.Header key={col.id} xs={col.size} className="hc-ta-center">
                {col.label}
              </Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body className="POP-table">
          {products.map((product) => (
            <Table.Row
              key={product.id}
              onClick={(event) => {
                event.stopPropagation()
                setEditProduct({ body: { ...product }, show: true })
              }}
            >
              {columnData.map((col) => (
                <Table.Data
                  xs={col.size}
                  key={`${product.id}-${col.id}`}
                  className="hc-ta-center"
                >
                  <ItemCell product={product} col={col} />
                </Table.Data>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <EditImageSampleProduct
        editProduct={editProduct}
        setEditProduct={setEditProduct}
        setProducts={setProducts}
      />
    </>
  ) : (
    <div className="hc-pa-normal">
      <Heading className="hc-ta-center hc-pt-expanded">
        No products currently available.
      </Heading>
    </div>
  )

  function ItemCell(props) {
    const { product, col } = props

    switch (col.id) {
      case 'fig_model':
        return product[col.id] ? (
          <Chip
            style={{ backgroundColor: 'violet', border: '0px', color: 'white' }}
            size="dense"
            className="hc-ma-none"
          >
            <FontAwesomeIcon icon={faChild} color="#ffffff" />
          </Chip>
        ) : null
      case 'art_director_request':
        return product[col.id] ? (
          <IndicatorChip value={product[col.id]} field="ad" />
        ) : null
      case 'image_approved':
        return product[col.id] ? (
          <IndicatorChip value={product[col.id]} field="ad" />
        ) : null
      case 'sample_submitted':
        return product[col.id] ? (
          <IndicatorChip value={product[col.id]} field="ad" />
        ) : null
      case 'image_sample_collected':
        if (product[col.id] === null || +product[col.id] === -1) return null
        return <IndicatorChip value={+product[col.id]} field="pulled" />
      case 'category':
        return product[col.id] ? (
          <Chip
            size="dense"
            style={{
              backgroundColor: CategoryColorMap[product[col.id]],
              border: '0px',
              color: 'white',
            }}
          >
            {product[col.id]}
          </Chip>
        ) : null
      case 'page_ordering':
        return (
          <>
            {product.status === 'denied'
              ? 'N/A'
              : product.page_ordering.length > 0
              ? product.page_ordering
                  .map((element) => element + 1)
                  .sort()
                  .join(' & ')
              : 'TBD'}
          </>
        )

      default:
        return product[col.id]
    }
  }
}
export default ProductListTable
