import { Chip } from '@enterprise-ui/canvas-ui-react'
import {
  faBan,
  faCheck,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IndicatorChip = (props) => {
  const { value, field } = props
  const icon = (() => {
    switch (value) {
      case true:
      case 1:
      case '1':
      case 'Yes':
        return faCheck
      case false:
      case 2:
      case '2':
        return faBan
      case 0:
      case '0':
      case 'No':
      default:
        return faExclamationTriangle
    }
  })()

  const fillColor = (() => {
    switch (field) {
      case 'ad':
        switch (value) {
          case true:
            return 'success'
          case false:
            return 'orange'
          default:
            return 'default'
        }
      case 'pulled':
      case 'imageCollected':
        switch (value) {
          case 0:
          case 'No':
            return '#e2ba0c'
          case 1:
          case 'Yes':
            return '#3838a3'
          case 2:
            return '#b92c09'
          default:
            return 'default'
        }
      default:
        return 'default'
    }
  })()

  const textColor = (() =>
    field === 'pulled' && value === 0 ? '000000' : 'FFFFF')()

  return (
    <Chip
      size="dense"
      style={{ backgroundColor: fillColor, border: '0px', color: 'white' }}
    >
      <FontAwesomeIcon icon={icon} color={textColor} type="icon" />
    </Chip>
  )
}
export default IndicatorChip
