import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import ReorderArray from '../util/ReorderArray'

import DragHandle from './DragHandle'
import ExpandableStory from './ExpandableStory'

const SortableStoryContainer = (props) => {
  const {
    role,
    page,
    putPage,
    putStory,
    putCut,
    setEditStory,
    setEditCut,
    setViewCutPatches,
    setViewItemPatches,
    setEditArtPatch,
    setEditItem,
    setEditDummy,
    setEditPropItem,
    setAddArtPatch,
    expands,
    setExpands,
    hasEditPrivileges,
    lockoutDate,
    eventWeek,
  } = props

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let newStories = ReorderArray(
        page.stories,
        oldIndex,
        newIndex,
        'priority'
      )

      putPage({
        id: page.id,
        lastUpdatedTimestamp: page.lastUpdatedTimestamp,
        stories: newStories,
      })
    }
  }

  const SortableItem = SortableElement(({ story }) => (
    <Card
      className={`hc-ma-dense ${hasEditPrivileges ? null : 'hc-pa-normal'}`}
    >
      <Grid.Container
        spacing="none"
        align="center"
        justify="space-between"
        className="sortable-item-container"
        style={{ paddingLeft: '5px' }}
      >
        <Grid.Item xs={0.5}>
          {hasEditPrivileges ? <DragHandle /> : null}
        </Grid.Item>
        <Grid.Item xs={11}>
          <ExpandableStory
            role={role}
            story={story}
            putStory={putStory}
            putCut={putCut}
            page={page}
            divisions={page.divisions}
            setEditStory={setEditStory}
            setEditCut={setEditCut}
            setAddArtPatch={setAddArtPatch}
            setEditArtPatch={setEditArtPatch}
            setViewCutPatches={setViewCutPatches}
            setViewItemPatches={setViewItemPatches}
            setEditItem={setEditItem}
            setEditDummy={setEditDummy}
            setEditPropItem={setEditPropItem}
            expands={expands}
            setExpands={setExpands}
            hasEditPrivileges={hasEditPrivileges}
            lockoutDate={lockoutDate}
            eventWeek={eventWeek}
          />
        </Grid.Item>
      </Grid.Container>
    </Card>
  ))

  const SortableList = SortableContainer(() => (
    <div className="hc-pa-none">
      {page.stories.map((story) => (
        <SortableItem key={story.id} index={story.priority} story={story} />
      ))}
    </div>
  ))

  if (page?.stories) {
    return <SortableList onSortEnd={onSortEnd} useDragHandle />
  } else {
    return <div />
  }
}
export default SortableStoryContainer
