import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import { getCategoryLabels } from '../util/helpers'

const CategorySelect = ({
  categoriesSelected,
  setCategoriesSelected,
  categoryOptions,
  disabled,
  setChangeDetected,
}) => (
  <Autocomplete
    id="categorySelect"
    multiselect
    chipHeight="dense"
    value={getCategoryLabels(categoriesSelected, categoryOptions)}
    onUpdate={(_, value) => {
      if (value !== categoriesSelected) {
        setCategoriesSelected(value)
        if (setChangeDetected !== undefined) {
          setChangeDetected(true)
        }
      }
    }}
    options={categoryOptions}
    placeholder="Filter Categories"
    data-testid="category-section-select"
    disabled={disabled}
  />
)

export default CategorySelect
