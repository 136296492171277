const coronaItemMapper = (result) =>
  result.data.items.map((item) => ({
    age_range: item?.merchandising?.merchandiseTypeAttributes?.find(
      (attribute) => attribute.name === 'Manufacturer Suggested Age'
    )?.values[0]?.name,
    assembly_required: item?.merchandising?.merchandiseTypeAttributes?.find(
      (attribute) => attribute.name === 'Assembly details'
    )?.values[0]?.name,
    assorted: item?.merchandising?.merchandiseTypeAttributes?.includes(
      (attribute) => attribute.name === 'Assortment Type'
    ),
    available_instore:
      item?.merchandising?.intendedSellingChannels.includes('Sold in Store'),
    available_online:
      item?.merchandising?.intendedSellingChannels.includes('Sold Online'),
    brand: item.brands.map((brand) => brand.name).join(', '),
    depth:
      item?.operational?.packageDimensions?.measurements?.dimensions?.depth,
    height:
      item?.operational?.packageDimensions?.measurements?.dimensions?.height,
    name: item?.description?.title,
    size: item?.variations?.themes?.find((theme) => theme.name === 'Size')
      ?.value,
    target_exclusive: item.merchandising.merchandiseTypeAttributes
      .map((merchandiseTypeAttributes) => merchandiseTypeAttributes.name)
      .join(', ')
      .includes('Internal Use Only: Shop By'),
    tcin: item.tcin,
    units:
      item?.operational?.packageDimensions?.measurements?.dimensions?.units,
    width:
      item?.operational?.packageDimensions?.measurements?.dimensions?.width,
  }))
export default coronaItemMapper
