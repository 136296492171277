import { Grid, Button, Modal, Chip, Card } from '@enterprise-ui/canvas-ui-react'
import { faPencilAlt, faGlasses } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import AddItemPatch from '../forms/AddItemPatch'
import EditItemPatch from '../forms/EditItemPatch'

const ViewItemPatches = (props) => {
  const {
    viewItemPatches,
    setViewItemPatches,
    putItem,
    putItemPatch,
    postItemPatch,
  } = props

  const { body, pageId, storyId, cutId, show, itemId, hasEditPrivileges } =
    viewItemPatches

  const [editItemPatch, setEditItemPatch] = useState({
    body: {},
    cutId: null,
    pageId: null,
    show: false,
    storyId: null,
  })

  const [addItemPatch, setAddItemPatch] = useState({
    body: {},
    cutId: null,
    pageId: null,
    show: false,
    storyId: null,
  })

  const handleDialogClose = () => {
    setViewItemPatches({
      body: {},
      cutId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
  }

  return (
    <Modal
      isVisible={show}
      headingText="Item Patches"
      onRefuse={handleDialogClose}
    >
      <div style={{ position: 'relative' }} className="hc-pa-normal">
        <Grid.Container align="center" spacing="dense">
          {body ? (
            <Grid.Item xs={12}>
              <Card
                className={'hc-pa-dense hc-mt-dense hc-mb-dense hc-bg-grey06'}
              >
                <Grid.Container>
                  <Grid.Item xs={2}>Reg $: {body.regUnitRetail}</Grid.Item>
                  <Grid.Item xs={2}>
                    <Chip
                      size="dense"
                      style={{
                        backgroundColor: body.regPriceCallout ? 'red' : 'grey',
                        border: '0px',
                        color: 'white',
                      }}
                    >
                      {body.regPriceCallout ? 'Reg $' : 'No Reg $'}
                    </Chip>
                  </Grid.Item>
                  <Grid.Item className="hc-ta-right" xs={2}>
                    Markets:
                  </Grid.Item>
                  <Grid.Item xs={5}>{body.marketString}</Grid.Item>
                  <Grid.Item xs={1}>
                    <Button
                      iconOnly
                      onClick={(event) => {
                        event.stopPropagation()
                        setEditItemPatch({
                          body: {
                            id: body.id,
                            lastUpdatedTimestamp: body.lastUpdatedTimestamp,
                            marketString: body.marketString,
                            regPriceCallout: body.regPriceCallout,
                            regUnitRetail: body.regUnitRetail,
                          },
                          cutId: cutId,
                          isItemPatch: false,
                          itemId,
                          pageId: pageId,
                          setViewItemPatches,
                          show: true,
                          storyId: storyId,
                        })
                      }}
                    >
                      <FontAwesomeIcon
                        icon={hasEditPrivileges ? faPencilAlt : faGlasses}
                        color="#000000"
                      />
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </Card>
            </Grid.Item>
          ) : null}
          {body.itemPatches ? (
            body.itemPatches.map((itemPatch, index) => (
              <Grid.Item key={index} xs={12}>
                <Card className={'hc-pa-dense hc-mt-dense hc-mb-dense'}>
                  <Grid.Container>
                    <Grid.Item xs={2}>
                      Reg $: {itemPatch.regUnitRetail}
                    </Grid.Item>
                    <Grid.Item xs={2}>
                      <Chip
                        size="dense"
                        style={{
                          backgroundColor: itemPatch.regPriceCallout
                            ? 'red'
                            : 'grey',
                          border: '0px',
                          color: 'white',
                        }}
                      >
                        {itemPatch.regPriceCallout ? 'Reg $' : 'No Reg $'}
                      </Chip>
                    </Grid.Item>
                    <Grid.Item className="hc-ta-right" xs={2}>
                      Markets:
                    </Grid.Item>
                    <Grid.Item xs={5}>{itemPatch.marketString}</Grid.Item>
                    <Grid.Item xs={1}>
                      <Button
                        iconOnly
                        onClick={(event) => {
                          event.stopPropagation()
                          setEditItemPatch({
                            body: {
                              id: itemPatch.id,
                              lastUpdatedTimestamp:
                                itemPatch.lastUpdatedTimestamp,
                              marketString: itemPatch.marketString,
                              regPriceCallout: itemPatch.regPriceCallout,
                              regUnitRetail: itemPatch.regUnitRetail,
                            },
                            cutId: cutId,
                            isItemPatch: true,
                            itemId,
                            itemLastUpdatedTimestamp: body.lastUpdatedTimestamp,
                            pageId: pageId,
                            setViewItemPatches,
                            show: true,
                            storyId: storyId,
                          })
                        }}
                      >
                        <FontAwesomeIcon
                          icon={hasEditPrivileges ? faPencilAlt : faGlasses}
                          color="#000000"
                        />
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Card>
              </Grid.Item>
            ))
          ) : (
            <React.Fragment />
          )}
        </Grid.Container>
        <Button
          type="primary"
          disabled={!hasEditPrivileges}
          onClick={() =>
            setAddItemPatch({
              body: {
                itemId: itemId,
                marketString: '',
                regPriceCallout: false,
                regUnitRetail: '',
              },
              cutId: cutId,
              itemId,
              pageId: pageId,
              show: true,
              storyId: storyId,
            })
          }
        >
          Add Reg $ Patch
        </Button>
      </div>
      <EditItemPatch
        editItemPatch={editItemPatch}
        setEditItemPatch={setEditItemPatch}
        putItem={putItem}
        putItemPatch={putItemPatch}
        setViewItemPatches={setViewItemPatches}
        hasEditPrivileges={hasEditPrivileges}
      />
      <AddItemPatch
        addItemPatch={addItemPatch}
        setAddItemPatch={setAddItemPatch}
        postItemPatch={postItemPatch}
      />
    </Modal>
  )
}
export default ViewItemPatches
