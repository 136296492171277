import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import ReorderArray from '../util/ReorderArray'

import DragHandle from './DragHandle'
import ExpandableItem from './ExpandableItem'

const SortableItemContainer = (props) => {
  const {
    page,
    cut,
    putCut,
    expands,
    setExpands,
    setEditItem,
    ids,
    setViewItemPatches,
    hasEditPrivileges,
  } = props

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let newItems = ReorderArray(cut.items, oldIndex, newIndex, 'priority')

      putCut({
        body: {
          id: cut.id,
          items: newItems,
          lastUpdatedTimestamp: cut.lastUpdatedTimestamp,
        },
        ...ids,
      })
    }
  }

  const SortableItem = SortableElement(({ item }) => (
    <Card
      className={`hc-ma-dense ${
        !item.valid
          ? 'hc-bg-grey03 hc-clr-white'
          : !item.complete
          ? 'hc-bg-highlight hc-clr-black'
          : 'hc-clr-black'
      }`}
    >
      <Grid.Container spacing="none" align="center">
        <Grid.Item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          {hasEditPrivileges ? <DragHandle /> : null}
        </Grid.Item>
        <Grid.Item xs={11}>
          <ExpandableItem
            page={page}
            item={item}
            cut={cut}
            setEditItem={setEditItem}
            expands={expands}
            setExpands={setExpands}
            setViewItemPatches={setViewItemPatches}
            ids={{ ...ids, itemId: item.id }}
            hasEditPrivileges={hasEditPrivileges}
          />
        </Grid.Item>
      </Grid.Container>
    </Card>
  ))

  const SortableList = SortableContainer(() => (
    <div className="hc-pa-none">
      {cut.items.map((item) => (
        <SortableItem key={item.id} index={item.priority} item={item} />
      ))}
    </div>
  ))

  if (cut?.items) {
    return <SortableList onSortEnd={onSortEnd} useDragHandle />
  } else {
    return <div />
  }
}
export default SortableItemContainer
