export const CATEGORY_LIST = [
  {
    color: '#00796b',
    id: 0,
    label: 'Toys',
    value: 'Toys',
  },
  {
    color: '#857400',
    id: 1,
    label: 'Entertainment',
    value: 'Ent',
  },
  {
    color: '#b92c09',
    id: 2,
    label: 'A&A',
    value: 'A&A',
  },
  {
    color: '#400856',
    id: 3,
    label: 'ESS/BTY',
    value: 'ESS/BTY',
  },
  {
    color: '#3838a3',
    id: 4,
    label: 'Electronics',
    value: 'Elec',
  },
  {
    color: '#6493ae',
    id: 5,
    label: 'F&B',
    value: 'F&B',
  },
  {
    color: '#a16807',
    id: 6,
    label: 'Sporting Goods',
    value: 'SPRT',
  },
  {
    color: '#6e23b3',
    id: 7,
    label: 'Home',
    value: 'Home',
  },
]

export const CategoryColorMap = {
  'A&A': '#b92c09',
  'ESS/BTY': '#400856',
  Elec: '#3838a3',
  Ent: '#857400',
  'F&B': '#6493ae',
  Home: '#6e23b3',
  SPRT: '#a16807',
  Toys: '#00796b',
}
