import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import cutMapper from '../mappers/cutMapper'
import ReplaceFirst from '../util/ReplaceFirst'

const usePutCut = (setLoading, setWeeklyAd) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.cut)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    const putCut = async () => {
      setLoading(true)
      try {
        const pageId = request?.page?.id || request.pageId
        const id = request.body.id
        const response = await axios.put(url.current + id, request.body)
        const thisCut = cutMapper(response.data)
        setWeeklyAd((w) => {
          ReplaceFirst(
            w.pages
              .find((page) => page.id === pageId)
              .stories.find((story) => story.id === request.storyId).cuts,
            id,
            thisCut
          )
          return w
        })
        if (request.setEditCut) {
          const hasPrimary = thisCut.cutPatches.length > 0
          const primary = hasPrimary ? thisCut.cutPatches[0] : {}
          request.setEditCut({
            body: {
              copy: primary.copy,
              coreDataLastUpdatedField: thisCut.coreDataLastUpdatedField,
              coreDataLastUpdatedTimestamp:
                thisCut.coreDataLastUpdatedTimestamp,
              divisionId: thisCut.divisionId,
              id: thisCut.id,
              lastUpdatedTimestamp: thisCut.lastUpdatedTimestamp,
              linerOffers: thisCut.linerOffers,
              name: thisCut.name,
              offerLastLinkedTimestamp: thisCut.offerLastLinkedTimestamp,
              offers: thisCut.offers,
              primaryPatchIndex: hasPrimary ? primary.marketIndex : null,
              priority: thisCut.priority,
              promoUnitRetail: primary.promoUnitRetail,
              promoUnitRetailCallout: primary.promoUnitRetailCallout,
              promoUnitRetailLastUpdatedTimestamp:
                thisCut.promoUnitRetailLastUpdatedTimestamp,
              saleCallout: primary.saleCallout,
              valid: thisCut.valid,
            },
            cutValid: thisCut.valid,
            divisions: request.divisions,
            followers: request.followers,
            hasEditPrivileges: request.hasEditPrivileges,
            hasItems: Boolean(thisCut.items.length),
            isArtPatch: Boolean(thisCut.artPatchLeaderId),
            page: request.page,
            show: request.show,
            storyId: request.storyId,
            storyLastUpdatedTimestamp: request.storyLastUpdatedTimestamp,
            valid: thisCut.valid,
            weeklyAdStartDate: request.weeklyAdStartDate,
          })
        }
      } catch (e) {
        console.log(e)
      }
      setLoading(false)
      setRequest(null)
    }

    if (request !== null) {
      let thenFunc =
        typeof request.then === 'function' ? request.then : () => {}
      putCut().then(() => thenFunc())
    }
  }, [request, setLoading, setWeeklyAd])

  return setRequest
}
export default usePutCut
