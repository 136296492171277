import {
  Button,
  Card,
  ExpandableSection,
  Grid,
} from '@enterprise-ui/canvas-ui-react'
import { faPencilAlt, faGlasses } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ExpandablePropItem = (props) => {
  const {
    page,
    propItem,
    cut,
    setEditPropItem,
    expands,
    setExpands,
    ids,
    hasEditPrivileges,
  } = props

  if (expands.propItem[propItem.id] === undefined) {
    expands.propItem[propItem.id] = expands.page[page.id]
  }

  const expanded = expands.propItem[propItem.id]

  return (
    <Card className="hc-ma-dense hc-bg-success hc-clr-contrast-weak">
      <Grid.Container spacing="none" align="center">
        <Grid.Item xs={1} />
        <Grid.Item xs={11}>
          <ExpandableSection
            padding="none"
            expanded={expanded}
            onExpand={() => {
              let exp = Object.assign({}, expands)
              exp.propItem[propItem.id] = !expanded
              setExpands(exp)
            }}
          >
            <Grid.Container align="center" spacing="dense">
              <Grid.Item
                xs={11}
              >{`Prop Item: ${propItem.description}`}</Grid.Item>
              <Grid.Item xs={1}>
                <Button
                  iconOnly
                  onClick={(event) => {
                    event.stopPropagation()
                    setEditPropItem({
                      body: {
                        description: propItem.description,
                        dpci: propItem.dpci,
                        id: propItem.id,
                        keyNotes: propItem.keyNotes,
                        lastUpdatedTimestamp: propItem.lastUpdatedTimestamp,
                        sample: propItem.sample,
                        sampleId: propItem.sampleId,
                      },
                      cutLastUpdatedTimestamp: cut.lastUpdatedTimestamp,
                      hasEditPrivileges,
                      show: true,
                      ...ids,
                    })
                  }}
                >
                  <FontAwesomeIcon
                    icon={hasEditPrivileges ? faPencilAlt : faGlasses}
                    color="#000000"
                  />
                </Button>
              </Grid.Item>
            </Grid.Container>
            <ExpandableSection.Content className="hc-mb-dense hc-pb-dense hc-ml-dense hc-pl-dense hc-mr-dense hc-pr-dense">
              <Grid.Container align="center">
                <Grid.Item xs={4}>Dpci: {propItem.dpci}</Grid.Item>
                <Grid.Item xs={4}> Sample:{propItem.sample}</Grid.Item>
                <Grid.Item xs={4}>SampleId: {propItem.sampleId}</Grid.Item>
                <Grid.Item xs={12}>Keynotes: {propItem.keyNotes}</Grid.Item>
              </Grid.Container>
            </ExpandableSection.Content>
          </ExpandableSection>
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}
export default ExpandablePropItem
