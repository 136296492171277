import {
  Form,
  Grid,
  Heading,
  Input,
  Modal,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import MarketListTooltip from '../components/MarketListTooltip'
import OfferCard from '../components/OfferCard'
import { formatToDateTime } from '../util/MomentUtil'

const EditCutPatch = (props) => {
  const { editCutPatch, setEditCutPatch, putCutPatch, hasEditPrivileges } =
    props

  const defaultPromoUnitRegUpdatedPostLockout =
    editCutPatch?.lockoutDate <
    editCutPatch?.body?.defaultPromoUnitRetailUpdatedTimestamp

  const wipeState = () => {
    setEditCutPatch({
      body: {},
      cutId: null,
      pageId: null,
      show: false,
      storyId: null,
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        edit_cutPatch_copy: editCutPatch?.body?.copy || '',
        edit_cutPatch_promoUnitRetailCallout:
          editCutPatch?.body?.promoUnitRetailCallout || true,
        edit_cutPatch_saleCallout: editCutPatch?.body?.saleCallout || false,
      }}
      onSubmit={(values, funcs) => {
        putCutPatch({
          body: {
            copy: values.edit_cutPatch_copy,
            id: editCutPatch?.body?.id,
            lastUpdatedTimestamp: editCutPatch?.body?.lastUpdatedTimestamp,
            promoUnitRetailCallout: values.edit_cutPatch_promoUnitRetailCallout,
            saleCallout: values.edit_cutPatch_saleCallout,
          },
          cutId: editCutPatch?.cutId,
          pageId: editCutPatch?.pageId,
          storyId: editCutPatch?.storyId,
        })
        funcs.resetForm()
        wipeState()
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }

        return (
          <Modal isVisible={editCutPatch.show} onRefuse={handleDialogClose}>
            <Heading
              size={4}
              className="hc-pt-expanded hc-pl-expanded hc-pr-expanded"
            >
              {`Edit Cut Patch for `}
              <MarketListTooltip
                location="bottom"
                markets={editCutPatch?.body?.markets}
              />
            </Heading>
            <div className="hc-pa-normal">
              <fieldset disabled={!hasEditPrivileges}>
                <Grid.Container align="center" spacing="dense">
                  <Grid.Item xs={12}>
                    <Input.Label disabled>
                      Promo Unit Retail
                      <Tooltip
                        style={{ float: 'right' }}
                        location={'left'}
                        content={`Promo Unit Retail Revised: ${formatToDateTime(
                          editCutPatch.body
                            .defaultPromoUnitRetailUpdatedTimestamp
                        )}`}
                      >
                        <FontAwesomeIcon
                          icon={faClock}
                          size="sm"
                          className="hc-clr-grey03"
                        />
                      </Tooltip>
                    </Input.Label>
                    <Form.Field
                      disabled
                      style={{
                        color: defaultPromoUnitRegUpdatedPostLockout
                          ? 'red'
                          : '',
                      }}
                      id="edit_cutPatch_promoUnitRetail"
                      value={editCutPatch.body.promoUnitRetail}
                      hintText="Promo Unit Retail edits must be made in IVY Promo"
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      id="edit_cutPatch_saleCallout"
                      type="toggle"
                      value={values.edit_cutPatch_saleCallout}
                      label="Sale callout"
                      onChange={() =>
                        setFieldValue(
                          'edit_cutPatch_saleCallout',
                          !values.edit_cutPatch_saleCallout
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Form.Field
                      type="toggle"
                      id="edit_cutPatch_promoUnitRetailCallout"
                      label="List Promo Unit Retail?"
                      value={values.edit_cutPatch_promoUnitRetailCallout}
                      onChange={() =>
                        setFieldValue(
                          'edit_cutPatch_promoUnitRetailCallout',
                          !values.edit_cutPatch_promoUnitRetailCallout
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      type="textarea"
                      grow
                      id="edit_cutPatch_copy"
                      value={values.edit_cutPatch_copy}
                      label="Copy"
                      onChange={handleChange}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Input.Label>Offer Info:</Input.Label>
                    {editCutPatch?.offers?.map((offer) => (
                      <OfferCard
                        key={offer.id}
                        offer={offer}
                        lockoutDate={editCutPatch?.lockoutDate}
                        index={editCutPatch.body.marketIndex}
                      />
                    ))}
                  </Grid.Item>
                </Grid.Container>
              </fieldset>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!hasEditPrivileges}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  )
}
export default EditCutPatch
