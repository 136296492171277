import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'
import { addURLSeparator } from '../../creativeHubGlobal/util/CommonUtil'
import assetHubSearchMapper from '../../mappers/assetHubSearchMapper'

export default class AssetHubApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }
  init_assethub_api = () => {
    this.client = axios.create({
      baseURL: this.config.assethub.url,
    })
    return this.client
  }

  storageUrlKey = (inputString, fileName, importDate) => {
    let urlKey = `${inputString}${addURLSeparator(inputString)}hei=300&key=${
      this.config.assethub.key
    }&make_square=true&wid=300`
    if (fileName !== undefined) urlKey += `&file_name=${fileName}`
    if (importDate !== undefined) urlKey += `&import_date=${importDate}`
    return urlKey
  }

  postSearch = async (
    dpci = '',
    page = 0,
    size = 21,
    weeklyAd_toggle = false
  ) =>
    await this.init_assethub_api()
      .post(
        this.config.assethub.url + `?page=${page}&size=${size}`,
        {
          filters: {
            'product_info.dpci': [dpci],
            'project_info.channel': weeklyAd_toggle ? [] : ['Weekly Ad'],
          },
          search_term: dpci,
          sort_fields: { import_date: 'desc' },
        },
        {
          headers: {
            Authorization: fetchAuthorizationToken(),
            'Content-Type': 'application/json',
            'x-api-key': this.config.assethub.key,
            'x-id-token': fetchIdToken(),
          },
        }
      )
      .then((res) => assetHubSearchMapper(res.data))
      .catch((e) => console.log(e))
}
