import {
  Card,
  Chip,
  ExpandableSection,
  Grid,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'

import GetPageTitle from '../util/GetPageTitle'

const ExpandableMoveCutStory = (props) => {
  const { pageId, getPage, weeklyAd, checked, setFieldValue, cutMoving } = props
  let page = weeklyAd.pages.find((wadPages) => wadPages.id === pageId)
  const [expanded, setExpanded] = useState(false)

  return (
    <Card className="hc-ma-dense hc-bg-grey07">
      <ExpandableSection
        padding="dense"
        expanded={expanded || pageId === cutMoving?.page?.id}
        onExpand={() => {
          if (!expanded) {
            getPage(pageId)
          }
          setExpanded(!expanded)
        }}
      >
        <Grid.Container align="center" spacing="none">
          <Grid.Item xs={2}>{GetPageTitle(page)}</Grid.Item>
          <Grid.Item xs={8}>
            {page.divisions
              ? page.divisions.map((division) => (
                  <Chip size="dense" color={division.color} key={division.id}>
                    {division.shortCode.toUpperCase()}
                  </Chip>
                ))
              : null}
          </Grid.Item>
        </Grid.Container>
        <ExpandableSection.Content className="hc-pa-normal">
          {page?.stories?.map(
            (story) =>
              story.id !== cutMoving?.storyId && (
                <Card className="hc-pa-dense hc-ma-dense" key={story.id}>
                  <Input.Checkbox
                    id={story.id}
                    checked={checked === story.id}
                    label={`Story ${story.priority + 1}: ${story.name}`}
                    value={story.id}
                    onChange={(event) => {
                      event.target.checked
                        ? setFieldValue('checked', parseInt(event.target.value))
                        : setFieldValue('checked', '')
                    }}
                  />
                </Card>
              )
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </Card>
  )
}
export default ExpandableMoveCutStory
