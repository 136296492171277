import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import storyMapper from '../mappers/storyMapper'

const usePostStory = (setLoading, setWeeklyAd, setToast) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.story)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const postStory = async () => {
      mounted && setLoading(true)
      try {
        let response = await axios.post(url.current, request.body)
        let thisStory = storyMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            w.pages
              .find((page) => page.id === request.pageId)
              .stories.push(thisStory)
            return w
          })
        if (setToast && mounted) {
          setToast({
            heading: 'Story Added',
            isVisible: true,
            message: 'Nice.',
            type: 'success',
          })
        }
      } catch (e) {
        console.log(e)
        if (setToast && mounted) {
          setToast({
            heading: 'Story not added',
            isVisible: true,
            message: 'Something went wrong, try again.',
            type: 'error',
          })
        }
      }
      mounted && setLoading(false)
      mounted && setRequest(null)
    }

    if (request !== null) {
      postStory()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd, setToast])

  return setRequest
}
export default usePostStory
