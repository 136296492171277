import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      //marginTop: theme.spacing(2),
    },
    width: '100%',
  },
}))

export const CustomSnackbar = ({ severity, message }) => {
  const duration = 6000
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        data-testid="customSnackbar"
      >
        <div>
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </div>
      </Snackbar>
    </div>
  )
}
