const GetPageTitle = (page) => {
  if (page.bookOnePageNumber !== '-') {
    return 'Page ' + page.bookOnePageNumber
  } else if (page.bookTwoPageNumber) {
    return 'Book 2 Page ' + page.bookTwoPageNumber
  } else {
    return 'Page'
  }
}
export default GetPageTitle
