import { Form, Grid, Heading, Modal } from '@enterprise-ui/canvas-ui-react'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'

import CancelSubmitButtons from '../../components/CancelSubmitButtons'

const UpdateImageTracking = (props) => {
  const {
    setEditItem,
    updateItem,
    hasEditPrivileges,
    editItem: { show, body: editItem },
  } = props

  const wipeState = () => {
    setEditItem({
      body: {},
      show: false,
    })
  }
  return !isEmpty(editItem) ? (
    <Formik
      enableReinitialize
      initialValues={{
        image_collected:
          editItem.image_collected || editItem.image_collected !== ''
            ? editItem.image_collected
            : '',
      }}
      onSubmit={(values, funcs) => {
        updateItem({
          id: editItem.item_id,
          imageCollected: values.image_collected,
          ivyOfferId: editItem.ivy_offer_id,
          offerId: editItem.offer_id,
        })
        funcs.resetForm() //must be before wipeState call, which causes rerender
        wipeState()
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal isVisible={show} onRefuse={handleDialogClose}>
            <Heading
              size={4}
              className="hc-pt-expanded hc-pl-expanded hc-pr-expanded"
            >
              <div
                className="itemTitle"
                dangerouslySetInnerHTML={{
                  __html: `Edit ${editItem.item_name}  ${editItem.dpci}`,
                }}
              />
            </Heading>
            <div className="hc-pa-normal">
              <Grid.Container spacing="dense">
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="image_collected"
                    label="Image Collected"
                    value={values.image_collected}
                    disabled={!hasEditPrivileges}
                    options={[
                      { label: '', value: '' },
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                    ]}
                    onUpdate={setFieldValue}
                    data-testid="imageCollected"
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                handleDialogClose={handleDialogClose}
                handleSubmit={handleSubmit}
                submitDisabled={!hasEditPrivileges}
                canDelete={false}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default UpdateImageTracking
