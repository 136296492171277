import {
  Button,
  ButtonGroup,
  Modal,
  Card,
  Grid,
  Form,
  Heading,
  Input,
  Tooltip,
  Spinner,
  Dialog,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import {
  faExclamationTriangle,
  faExclamationCircle,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import ProductApi from '../api/pop-catalog/ProductApi'
import ProductList from '../tables/ProductList'
import '../scss/modal_size.scss'
import { CATEGORY_LIST } from '../util/CategoryColorMap'
import {
  convertImagetoBase64,
  errorCheckImage,
} from '../util/ConvertProductImage'
import { finalCopyString } from '../util/finalCopyUtil'
import { formatToServer } from '../util/MomentUtil'

import {
  formatDPCI,
  parseSetplaceholdDpci,
  handlePostProductError,
} from './util/ProductUtil'

const dpciRegex = /^\d{3}-\d{2}-\d{4}$/

let addProductSchema = yup.object().shape({
  buyer: yup.string().required('Required'),
  category: yup.object().required('Required'),
  dpci: yup.string().required('Required').matches(dpciRegex, {
    message: 'DPCI in format 000-00-0000',
  }),
  name: yup.string().required('Required'),
  reference_image: yup.mixed().required('Required'),
  reg_unit_retail: yup.string().required('Required'),
})

const AddMultipleProduct = (props) => {
  const {
    addProduct = {},
    setAddProduct,
    isAdminOrMerch,
    catalog = '',
    role = '',
    setRefreshData,
  } = props
  const productApi = new ProductApi()
  const {
    catalogId = null,
    vendorName = '',
    displayBulkProductModal = false,
  } = addProduct
  const [queryingCorona, setQueryingCorona] = useState(false)
  const [placeholdDpci, setPlaceholdDpci] = useState()
  const [placeholdBuyer, setPlaceholdBuyer] = useState()
  const [placeholdCategory, setPlaceholdCategory] = useState({
    color: '',
    id: 0,
    label: '',
    value: '',
  })
  const [dialogVisible, setDialogVisible] = useState(false)
  const [dialogContent, setDialogContent] = useState()
  const [alertDialog, setAlertDialog] = useState({
    show: false,
    text: '',
  })
  const [loading, setLoading] = useState(false)
  const [imageErrors, setImageErrors] = useState([])

  const catalog_Id = useParams()?.catalogId
  displayBulkProductModal &&
    catalog_Id &&
    placeholdDpci === undefined &&
    parseSetplaceholdDpci(catalog_Id, setPlaceholdDpci)

  const [addedProducts, setAddedProducts] = useState([])

  return catalogId ? (
    <Formik
      id="addMultipleProductForm"
      enableReinitialize={true}
      validationSchema={addProductSchema}
      initialValues={{
        add_assorted: false,
        add_available_instore: false,
        add_available_online: false,
        add_list_reg_retail: false,
        add_mapped_item: false,
        add_target_exclusive: false,
        add_vendor_funded: false,
        additional_product_info: '',
        age_range: '',
        art_director_notes: '',
        assembly_required: '',
        brand: '',
        buyer: placeholdBuyer ? placeholdBuyer : '',
        catalogId: catalogId,
        category: placeholdCategory ? placeholdCategory : '',
        coupon_retail: '',
        deleted: false,
        dpci: placeholdDpci ? placeholdDpci : '000-00-0000',
        features: '',
        fig_model: false,
        final_copy: '',
        final_product_name: '',
        gender_age_preference: '',
        imageErrors: null,
        ivy_offer_id: '',
        map_floor: '',
        model_needs: '',
        name: '',
        nullImageError: false,
        page_type: '',
        photo_notes: '',
        promo_retail: '',
        prop_needs: '',
        reference_image: '',
        reg_unit_retail: '',
        sample_id: '',
        sample_status: '',
        sample_submitted: false,
        sample_turn_in_date: '',
        set_date: '',
        sizeImageError: false,
        size_range: '',
        status: isAdminOrMerch ? 'accepted' : 'pending',
        tcin: '',
        tcin_type: '',
        validated_dpci: false,
        vendorName: vendorName,
        vendor_comments: '',
      }}
      onSubmit={(values, funcs) => {
        const {
          additional_product_info = '',
          age_range = '',
          art_director_notes = '',
          assembly_required = '',
          assorted = false,
          available_instore = false,
          available_online = false,
          brand = '',
          buyer = addProduct.buyer,
          category = '',
          coupon_retail = '',
          dpci = placeholdDpci || '',
          features = '',
          fig_model = false,
          final_product_name = '',
          gender_age_preference = '',
          ivy_offer_id = '',
          list_reg_retail = false,
          map_floor = '',
          add_mapped_item = false,
          model_needs = '',
          name = '',
          page_type = '',
          photo_notes = '',
          promo_retail = '',
          prop_needs = '',
          reference_image = '',
          reg_unit_retail = '',
          sample_id = '',
          sample_status,
          sample_submitted = false,
          sample_turn_in_date = '',
          set_date = '',
          size_range = '',
          status = '',
          add_target_exclusive = false,
          tcin = '',
          tcin_type = '',
          validated_dpci = false,
          vendor_comments = '',
          add_vendor_funded = false,
        } = values

        if (isEmpty(imageErrors)) {
          setLoading(true)
          productApi
            .postProduct({
              additional_product_info,
              age_range,
              art_director_notes,
              assembly_required,
              assorted,
              available_instore,
              available_online,
              brand,
              buyer,
              catalog_id: catalogId,
              category: (category && category.value) || '',
              coupon_retail,
              dpci,
              features,
              fig_model,
              final_copy: finalCopyString(values, false, 'add_'),
              final_product_name,
              gender_age_preference,
              ivy_offer_id,
              list_reg_retail,
              map_floor,
              mapped_item: add_mapped_item,
              model_needs,
              name: name,
              page_type,
              photo_notes,
              promo_retail,
              prop_needs: prop_needs,
              reference_image: reference_image,
              reg_unit_retail: reg_unit_retail,
              sample_id: sample_id,
              sample_status: sample_status,
              sample_submitted: sample_submitted,
              sample_turn_in_date: formatToServer(sample_turn_in_date),
              set_date: formatToServer(set_date),
              size_range,
              status,
              target_exclusive: add_target_exclusive,
              tcin,
              tcin_type,
              validated_dpci,
              vendor_comments,
              vendor_funded: add_vendor_funded,
              vendor_name: vendorName,
            })
            .then((res) => {
              setAddedProducts((oldProducts) => {
                oldProducts.unshift(res)
                return oldProducts
              })
              funcs.resetForm()

              setPlaceholdBuyer(res.buyer)
              setPlaceholdCategory(
                CATEGORY_LIST.find((cat) => cat.value === res.category)
              )

              res && parseSetplaceholdDpci(catalog_Id, setPlaceholdDpci)
              setRefreshData(true)
            })

            .catch((e) => {
              handlePostProductError(e, setDialogContent, setDialogVisible)
            })
            .finally(() => {
              setLoading(false)
            })
        }
      }}
    >
      {({
        values,
        handleSubmit,
        handleReset,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
      }) => {
        const {
          buyer = '',
          category = '',
          dpci = '',
          name = '',
          nullImageError = false,
          reg_unit_retail = '',
          sizeImageError = false,
          validated_dpci = false,
        } = values
        const handleDialogClose = () => {
          handleReset()
          setAddProduct({ displayBulkProductModal: false })
        }
        function handleImage(event) {
          let { nullImageError, sizeImageError } = errorCheckImage(event)
          if (nullImageError) {
            setImageErrors(['Required'])
          } else if (sizeImageError) {
            setImageErrors(['Image must be under 1MB'])
          } else {
            setImageErrors([])
          }
          if (isEmpty(imageErrors)) {
            convertImagetoBase64(event, (result) => {
              setFieldValue('reference_image', result?.split('base64,')[1])
            })
          }
        }

        let dpciFormatter = () => {
          setFieldValue('dpci', formatDPCI(dpci))
          if (dpciRegex.test(dpci)) {
            productApi
              .getCoronaInfo(dpci)
              .then((items) => {
                setFieldValue('validated_dpci', !isEmpty(items))
                if (!isEmpty(items)) {
                  const { tcin, name } = items[0]
                  setFieldValue('name', name)
                  productApi.getPriceInfo(tcin).then((result) => {
                    const { reg_retail, map_floor } = result?.data || {}
                    setFieldValue('reg_unit_retail', reg_retail)
                    if (map_floor) {
                      setFieldValue('map_floor', map_floor)
                      setFieldValue('mapped_item', true)
                    }
                  })
                }
              })
              .finally(() => setQueryingCorona(false))
          } else {
            setFieldValue('validated_dpci', false)
          }
        }

        return (
          catalogId && (
            <Modal
              isVisible={displayBulkProductModal}
              headingText={'Add Multiple Products'}
              onRefuse={handleDialogClose}
              className="larger_modal"
            >
              <div className="hc-pa-normal hc-pt-dense">
                <Grid.Container spacing="dense" className="hc-mb-dense">
                  <Grid.Item
                    xs={12}
                    className="hc-ta-left hc-pa-dense"
                    style={{ background: '#757575', marginBottom: '15px' }}
                  >
                    <Heading size="5" style={{ color: '#fff' }}>
                      Product Information
                    </Heading>
                  </Grid.Item>
                  <Grid.Item xs={2}>
                    <Autocomplete
                      required
                      id="category"
                      chipHeight="dense"
                      label="Category"
                      onUpdate={(_, value) => setFieldValue('category', value)}
                      options={CATEGORY_LIST}
                      error={errors.category && touched.category}
                      errorText={errors.category}
                      value={category}
                    />
                  </Grid.Item>
                  <Grid.Item xs={2}>
                    <Form.Field
                      required
                      id="buyer"
                      label="Buyer Name"
                      value={buyer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.buyer && errors.buyer}
                      errorText={errors.buyer}
                    />
                  </Grid.Item>
                  <Grid.Item xs={2}>
                    <Form.Field
                      required
                      id="dpci"
                      label="DPCI"
                      value={dpci}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.dpci && errors.dpci}
                      errorText={errors.dpci}
                      onKeyUp={dpciFormatter}
                    />
                  </Grid.Item>
                  <Grid.Item className="hc-ta-left hc-pv-xl">
                    {' '}
                    {queryingCorona ? (
                      <Spinner size="inline" />
                    ) : validated_dpci ? (
                      <Tooltip
                        content="DPCI found in Digital Item"
                        location="top"
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          size="sm"
                          color="green"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        content="DPCI not found in Digital Item"
                        location="top"
                      >
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          size="sm"
                          color="red"
                        />
                      </Tooltip>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={2}>
                    <Form.Field
                      required
                      id="name"
                      label="Product Name"
                      value={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && errors.name}
                      errorText={errors.name}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1}>
                    <Form.Field
                      required
                      id="reg_unit_retail"
                      label="Reg Retail"
                      value={reg_unit_retail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.reg_unit_retail && touched.reg_unit_retail}
                      errorText={errors.reg_unit_retail}
                    />
                  </Grid.Item>
                  <Grid.Item xs={2}>
                    <Input.Label
                      error={errors.reference_image && touched.reference_image}
                      required
                      style={{ fontSize: '12px' }}
                    >
                      Upload Reference Image
                    </Input.Label>
                    <input
                      id="reference_image"
                      label="Upload Reference Image"
                      type="file"
                      value={undefined} // https://stackoverflow.com/questions/66876022/setfieldvalue-formik-and-invalidstateerror-failed-to-set-the-value-property
                      onChange={handleImage}
                      onBlur={handleBlur}
                      errorText="Required"
                      error={errors.reference_image && touched.reference_image}
                      required
                    />
                    {!isEmpty(imageErrors) && (
                      <div style={{ color: '#b85300' }}>
                        <FontAwesomeIcon
                          color="#b85300"
                          icon={faExclamationCircle}
                          style={{ marginRight: '5px' }}
                        />{' '}
                        {imageErrors}
                      </div>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Grid.Container
                      justify="space-between"
                      direction="row-reverse"
                      align="center"
                      spacing="none"
                    >
                      <Grid.Item>
                        <ButtonGroup>
                          <Button
                            disabled={loading || !isEmpty(imageErrors)}
                            type="primary"
                            onClick={handleSubmit}
                          >
                            Save & Add another
                          </Button>
                          <Button type="secondary" onClick={handleDialogClose}>
                            Close
                          </Button>
                        </ButtonGroup>
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                </Grid.Container>
                {/* <Grid.Container
                justify="space-between"
                direction="row-reverse"
                align="center"
                style={{ padding: '20px' }}
              >

              </Grid.Container> */}
                {(nullImageError || sizeImageError) && (
                  <div style={{ color: '#b85300', float: 'right' }}>
                    <FontAwesomeIcon
                      color="#b85300"
                      icon={faExclamationCircle}
                      style={{ marginRight: '5px' }}
                    />
                    Errors somewhere on page. Please double check input.
                  </div>
                )}
                <Grid.Container spacing="dense">
                  <Grid.Item
                    xs={12}
                    className="hc-ta-left hc-pa-dense"
                    style={{ background: '#757575' }}
                  >
                    <Heading size="5" style={{ color: '#fff' }}>
                      Product List
                    </Heading>
                  </Grid.Item>
                  {loading ? (
                    <div className="hc-pa-dense">
                      <Card>
                        <Heading>
                          <Spinner size="inline" /> Adding product and image,
                          this may take a moment...
                        </Heading>
                      </Card>
                    </div>
                  ) : (
                    <ProductList
                      catalog={catalog}
                      products={addedProducts}
                      setProducts={setAddedProducts}
                      role={role}
                      view="vendor"
                      isBulkProductModal={true}
                    />
                  )}
                </Grid.Container>
              </div>

              <Dialog
                isVisible={dialogVisible}
                headingText={dialogContent}
                onRefuse={() => setDialogVisible(false)}
                refuseButtonText="Close"
              />
              <Dialog
                isVisible={alertDialog?.show}
                headingText={alertDialog.text}
                onRefuse={() =>
                  setAlertDialog({
                    show: false,
                    text: '',
                  })
                }
                refuseButtonText="Cancel"
                onApprove={handleDialogClose}
                approveButtonText="Yes"
              />
            </Modal>
          )
        )
      }}
    </Formik>
  ) : null
}
export default AddMultipleProduct
