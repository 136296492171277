import { Table } from '@enterprise-ui/canvas-ui-react'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SortTableHeader = (props) => {
  const {
    col = {},
    sortBy = '',
    sortOrder,
    handleSort,
    sortable = false,
  } = props
  const { id: columnId, label: columnLabel, size: columnSize } = col

  const getIcon = (sortOrder) => {
    if (sortOrder === '') {
      return faSort
    } else if (sortOrder === 'DESC') {
      return faSortDown
    } else {
      return faSortUp
    }
  }

  const sortResults = (sortBy) => {
    handleSort({
      sortBy,
      sortOrder: sortOrder === 'ASC' ? 'DESC' : 'ASC',
    })
  }

  return sortable ? (
    <Table.Header
      key={columnId}
      xs={columnSize}
      className="hc-ta-center"
      onClick={() => sortResults(columnId)}
      style={{ cursor: 'pointer' }}
      data-testid="sortable-header"
    >
      {columnLabel}
      <FontAwesomeIcon
        size="lg"
        style={{ paddingLeft: '4px' }}
        icon={columnId === sortBy ? getIcon(sortOrder) : faSort}
      />
    </Table.Header>
  ) : (
    <Table.Header
      key={columnId}
      xs={columnSize}
      className="hc-ta-center"
      data-testid="un-sortable-header"
    >
      {columnLabel}
    </Table.Header>
  )
}

export default SortTableHeader
