import {
  Button,
  Grid,
  GridContainer,
  Modal,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import {
  faUmbrella,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useContext, useEffect, useRef } from 'react'

import StoryApi from '../../api/creative-hub/StoryApi'
import UmbrellaApi from '../../api/creative-hub/UmbrellaApi'
import appConfig from '../../config/appConfig'
import { CanEditContext } from '../../context/Context'
import { sortUmbrellasByOrder } from '../../creativeHubGlobal/util/UmbrellaUtil.js'
import { handleFireflyClickInteraction } from '../../util/FireflyHelper'
import { deriveNoOfDaysFromCurrentDate } from '../util/DateUtil'
import { getTotalPromoValue } from '../util/StoryUtil'
import {
  createUmbrellaRequest,
  updateUmbrellaReqeust,
  appendForecastObjectWhenEmpty,
  getLinkedUmbrellaNames,
} from '../util/UmbrellaUtil'

import InlineEditInput from './InlineEditInput'
import Umbrella from './Umbrella.js'

import '../scss/Story.scss'

const Story = ({
  channelFilters = [],
  setRefreshData,
  story,
  projectStartDate = '',
  projectTitle = '',
  pyramidType,
  pyramidName,
  setUmbrellaIsDropped,
  setSelectedUmbrellas,
  session = {},
}) => {
  const umbrellaRef = useRef(null)
  appendForecastObjectWhenEmpty(story.umbrellas)
  const [isOver, setIsOver] = useState(false)
  const [dropStatus, setDropStatus] = useState('initial')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [preventToast, setPreventToast] = useState(false)
  const makeToast = ToastProvider.useToaster()
  const umbrellaApi = new UmbrellaApi()
  const storyApi = new StoryApi()
  const { canEdit } = useContext(CanEditContext)
  const storyPyramidType = pyramidType !== 'ent' ? 'pyramid' : 'ent'
  const [storyUmbrellas, setStoryUmbrellas] = useState(
    sortUmbrellasByOrder(story.umbrellas)
  )
  const [ascendingStoryUmbrellas, setAscendingStoryUmbrellas] = useState(false)
  const [sortHasBeenClicked, setSortHasBeenClicked] = useState(false)
  const config = appConfig()
  const sortStoryUmbrellas = () => {
    if (ascendingStoryUmbrellas) {
      storyUmbrellas.sort(
        (a, b) => a.forecast.total_promo_sales - b.forecast.total_promo_sales
      )
    } else {
      storyUmbrellas.sort(
        (a, b) => b.forecast.total_promo_sales - a.forecast.total_promo_sales
      )
    }
    let updatedUmbrellasSortList = []
    for (let index = 0; index < storyUmbrellas.length; index++) {
      storyUmbrellas[index].umbrella_order = index + 1
      updatedUmbrellasSortList.push(
        updateUmbrellaReqeust(storyUmbrellas[index], story.story_id)
      )
    }
    umbrellaApi.updateUmbrellas(updatedUmbrellasSortList)
    setAscendingStoryUmbrellas(!ascendingStoryUmbrellas)
    setStoryUmbrellas(storyUmbrellas)
  }
  const okayToDrop = (event) => {
    setDropStatus('add')
    setIsOver(true)
    event.preventDefault()
  }
  const okayToReorder = (event) => {
    setDropStatus('reorder')
    event.preventDefault()
  }
  const notOkayToDrop = (event, storyId) => {
    let header = 'Umbrella Type Mismatch!'
    let UserMessage =
      storyPyramidType !== 'ent'
        ? 'Umbrellas for Enterprise Mktg Stories cannot be dropped in a Pyramid story'
        : 'Umbrellas for Pyramid Mktg Stories cannot be dropped in an Enterprise story'
    if (event.dataTransfer.types.includes('story-id')) {
      UserMessage = 'Umbrella is already associated with another story.'
      header = 'Error'
    }
    setPreventToast(true)
    makeToast({
      heading: `${header}`,
      isVisible: true,
      links: [
        {
          onClick: () => {
            setPreventToast(false)
          },
        },
      ],
      message: UserMessage,
      onComplete: () => {
        setPreventToast(false)
      },
      onRefuse: () => {
        setPreventToast(false)
      },
      type: 'error',
    })
    setDropStatus('noDrop')
    setIsOver(false)
  }
  const dragCheck = (event, storyId) => {
    if (!event.dataTransfer.types.includes('drag-story')) {
      event.dataTransfer.types.includes('drag-umbrella')
        ? event.dataTransfer.types.includes(storyId)
          ? okayToReorder(event)
          : event.dataTransfer.types.includes(
              'allow_drop_in_' + storyPyramidType
            )
          ? okayToDrop(event)
          : notOkayToDrop(event, storyId)
        : notOkayToDrop(event, storyId)
    }
  }
  const dragLeave = (event) => {
    setIsOver(false)
  }
  useEffect(() => {
    setStoryUmbrellas(sortUmbrellasByOrder(story.umbrellas))
  }, [story.umbrellas])

  const updateUmbrellaOrder = async (
    dragStartPosition,
    dragEndPosition,
    umbrellasCopy
  ) => {
    let end, start
    if (dragStartPosition < dragEndPosition) {
      start = dragStartPosition
      end = dragEndPosition
    } else {
      start = dragEndPosition
      end = dragStartPosition
    }
    //only update changed indicies
    let updatedUmbrellasList = []
    for (let index = start; index <= end; index++) {
      umbrellasCopy[index].umbrella_order = index + 1
      updatedUmbrellasList.push(
        updateUmbrellaReqeust(umbrellasCopy[index], story.story_id)
      )
    }
    updatedUmbrellasList.length > 0 &&
      umbrellaApi.updateUmbrellas(updatedUmbrellasList)
    setStoryUmbrellas(umbrellasCopy)
  }

  const drop = async (event, dragEndPosition) => {
    const umbrella = event.dataTransfer.getData('drag-umbrella')
    // this stops the drop call from triggering if you are dropping the first umbrella into the story
    event.stopPropagation()
    setIsOver(false)
    if (umbrella && dropStatus === 'add') {
      const umbrellaRequest = createUmbrellaRequest(
        JSON.parse(umbrella),
        story.story_id,
        storyUmbrellas.length + 1
      )
      setUmbrellaIsDropped(true)
      await umbrellaApi.linkUmbrella(umbrellaRequest)
      setRefreshData(true)
      setSelectedUmbrellas([])

      let noOfDaysBeforeProjectStartDate =
        deriveNoOfDaysFromCurrentDate(projectStartDate)

      // Only trigger the firefly event if the linked umbrella(s) are 8 weeks (57 days) from the project start date.
      if (noOfDaysBeforeProjectStartDate <= config.lateLinkUnlinkUmbrellaDays) {
        let linkedUmbrellas = getLinkedUmbrellaNames(JSON.parse(umbrella))

        handleFireflyClickInteraction(
          'pageload',
          'Marketing & Digital: Link Umbrella',
          'success',
          session,
          {
            message: `project_title: ${projectTitle} || pyramid_type: ${pyramidType} || pyramid_name: ${pyramidName} || story_name: ${story.story_name} || linked_umbrella(s): ${linkedUmbrellas}`,
            start_date: projectStartDate,
          }
        )
      }
    } else if (umbrella && dropStatus === 'reorder') {
      let umbrellasCopy = [...storyUmbrellas]
      const dragStartPosition = parseInt(
        event.dataTransfer.getData(story.story_id)
      )
      umbrellasCopy.splice(dragStartPosition, 1)
      umbrellasCopy.splice(dragEndPosition, 0, JSON.parse(umbrella))

      if (
        dragEndPosition !== dragStartPosition &&
        umbrellasCopy[dragEndPosition] &&
        umbrellasCopy[dragStartPosition]
      ) {
        updateUmbrellaOrder(dragStartPosition, dragEndPosition, umbrellasCopy)
      }
    }
    umbrellaRef.current.scrollIntoView()
  }

  const deleteStory = (story) => {
    storyApi.deleteStory(story).finally(() => {
      handleFireflyClickInteraction(
        'pageload',
        'Marketing & Digital: Delete Story',
        'success',
        session,
        {
          message: `pyramidType: ${pyramidType}`,
          start_date: projectStartDate,
        }
      )
      setRefreshData(true)
    })
  }
  return (
    <>
      <div key={'div' + story.storyId} ref={umbrellaRef}>
        {isModalVisible && (
          <div data-testid="storyConfirmationModal">
            <Modal
              headingText="Are you sure you want to delete this story?"
              onRefuse={() => setIsModalVisible(false)}
              isVisible={isModalVisible}
            >
              <div className="hc-pa-normal">
                <Grid.Container>
                  <Grid.Item xs>
                    <p className="modalStoryMessage">
                      Deleting{' '}
                      <strong>{story?.story_name || 'this story'}</strong> will
                      unlink all umbrellas assigned to the story.
                    </p>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container direction="row-reverse" spacing="dense">
                  <Grid.Item>
                    <Button
                      data-testid={'delete-button-' + story.story_order}
                      type="primary"
                      onClick={() => {
                        setIsModalVisible(false)
                        deleteStory(story)
                      }}
                    >
                      Yes
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      type="secondary"
                      onClick={() => setIsModalVisible(false)}
                    >
                      No
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </Modal>
          </div>
        )}

        <GridContainer
          data-testid="pyramidList"
          spacing="none"
          align="center"
          xs={12}
        >
          <Grid.Item xs={0.5} align="left">
            {story.story_order}
          </Grid.Item>
          <Grid.Item xs={10} align="left">
            <h4 data-cy="marketing-story-name">
              <InlineEditInput
                data={story}
                field="story_name"
                value={story.story_name}
                placeholder="New Story"
                multiline={false}
              />
            </h4>
          </Grid.Item>
          <Grid.Item xs={1}>
            <span> </span>
          </Grid.Item>
          <Grid.Item xs={0.5} className="trashIcon">
            {canEdit ? (
              <FontAwesomeIcon
                color="black"
                icon={faTrashAlt}
                data-testid={'delete-story-' + story.story_order}
                onClick={() => {
                  setIsModalVisible(true)
                }}
              />
            ) : null}
          </Grid.Item>

          <Grid.Item xs={3} align="left">
            <h5 style={{ fontSize: '12px', margin: '8px 0 0 15px' }}>
              Umbrellas {'  '}{' '}
              <FontAwesomeIcon color="#111" icon={faUmbrella} />
            </h5>
          </Grid.Item>
          <Grid.Item xs={6} align="right">
            <h5 style={{ fontSize: '12px' }}>
              ${getTotalPromoValue(storyUmbrellas)}
            </h5>
          </Grid.Item>
          {canEdit ? (
            <Grid.Item xs={3} align="right">
              <Button
                className="dkgrey-btn-sort"
                onClick={() => {
                  sortStoryUmbrellas()
                  storyUmbrellas.length > 0 &&
                    updateUmbrellaOrder(
                      0,
                      storyUmbrellas.length - 1,
                      storyUmbrellas
                    )
                  if (sortHasBeenClicked) {
                    return
                  } else {
                    setSortHasBeenClicked(true)
                  }
                }}
              >
                {sortHasBeenClicked ? (
                  <FontAwesomeIcon
                    icon={ascendingStoryUmbrellas ? faArrowUp : faArrowDown}
                  />
                ) : null}
                <span style={{ marginLeft: sortHasBeenClicked ? '10px' : '' }}>
                  Sort by Promo $
                </span>
              </Button>
            </Grid.Item>
          ) : null}
          <Grid.Item xs={4}></Grid.Item>
        </GridContainer>

        <Grid.Container
          xs={12}
          className={'umbrellaBucket ' + (isOver && 'insideStyle')}
          onDragOver={(event) => {
            if (!preventToast) {
              dragCheck(event, story.story_id)
            } else {
              event.nativeEvent.stopImmediatePropagation()
            }
          }}
          onDragLeave={dragLeave}
          onDrop={(event) => drop(event, 1)}
          data-testid={'story-' + story.story_order}
        >
          {storyUmbrellas?.map((umbrella, index) => (
            <div
              key={'umbrella' + index}
              data-testid={'umbrella-' + index}
              onDrop={(event) => drop(event, index)}
              className="storyUmbrellaDiv"
            >
              <Umbrella
                index={index}
                storyId={story.story_id}
                storyName={story.story_name}
                key={'umbrella' + index}
                data={umbrella}
                setRefreshData={setRefreshData}
                draggable={canEdit}
                canEdit={canEdit}
                isPyramid={pyramidType !== 'ent'}
                setSelectedUmbrellas={setSelectedUmbrellas}
                projectTitle={projectTitle}
                projectStartDate={projectStartDate}
                session={session}
                pyramidName={pyramidName}
                pyramidType={pyramidType}
              />
            </div>
          ))}
        </Grid.Container>
      </div>
    </>
  )
}
export default Story
