import { Grid } from '@enterprise-ui/canvas-ui-react'
import React, { useRef } from 'react'

const MultipleInput = (
  {
    disabled,
    'data-testid': dataTestId,
    id,
    isExpanded,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    renderChip,
    selectedOption,
    searchText,
    value,
  },
  ref
) => {
  const innerRef = useRef(null)
  if (ref) {
    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  }

  return (
    <Grid.Container spacing="none" align="center">
      {value &&
        value.map((item, index) => (
          <Grid.Item key={typeof item.id !== 'undefined' ? item.id : index}>
            {renderChip(item)}
          </Grid.Item>
        ))}
      <Grid.Item xs>
        <input
          autoComplete="off"
          data-test={id}
          aria-controls={`ariaControls_${id}`}
          data-testid={dataTestId ? `${dataTestId}_input` : null}
          className="C-Autocomplete__Input"
          id={id}
          ref={innerRef}
          disabled={disabled}
          value={searchText}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength}
          placeholder={placeholder}
          type="text"
          role="combobox"
          aria-expanded={isExpanded}
          aria-activedescendant={selectedOption ? selectedOption.id : ''}
        />
      </Grid.Item>
    </Grid.Container>
  )
}

MultipleInput.displayName = 'MultipleInput'

export default React.forwardRef(MultipleInput)
