import { Card, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import isEmpty from 'lodash.isempty'
import { useState, useEffect } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import ReorderArray from '../util/ReorderArray'

import DWAOffer from './DWAOffer'

const SortableOfferContainer = (props) => {
  const { category, putDWAOffer, lockedOut } = props
  const [offerIdx, setOfferIdx] = useState({})
  let offers = category?.offers
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (offers) {
      setOfferIndexes(offers.sort((a, b) => a.priority - b.priority))
    }
  }, [offers])
  const setOfferIndexes = (curOffers) => {
    const newIdx = {}
    curOffers.forEach((curOffer, idx) => {
      newIdx[curOffer.id] = idx
    })
    setOfferIdx(newIdx)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIsEditing(true)
    if (oldIndex !== newIndex) {
      offers = ReorderArray(offers, oldIndex, newIndex, '', true)
      let editing = false
      offers.forEach((currOffer, idx) => {
        if (currOffer.priority !== idx) {
          editing = true
        }
      })
      setIsEditing(editing)
      setOfferIndexes(offers)
    }
  }

  const SortableItem = SortableElement(({ offer }) => (
    <Card className="hc-ma-dense hc-pa-dense">
      <DWAOffer lockedOut={lockedOut} offer={offer} />
    </Card>
  ))

  const SortableList = SortableContainer(() => (
    <div className="hc-pa-none">
      {offers.map((offer) => (
        <SortableItem
          key={offer.priority}
          index={offerIdx[offer.id]}
          offer={offer}
        />
      ))}
    </div>
  ))

  if (offers && !isEmpty(offers)) {
    return (
      <div>
        {' '}
        <SortableList onSortEnd={onSortEnd} useDragHandle />
        {isEditing ? (
          <div>
            <Button
              type="primary"
              style={{ bottom: '20px', position: 'fixed', right: '20px' }}
              onClick={() => {
                const offerDifference = []
                offers.forEach((curOffer) => {
                  if (curOffer.priority !== offerIdx[curOffer.id]) {
                    offerDifference.push({
                      id: curOffer.id,
                      offerId: curOffer.offerId,
                      priority: offerIdx[curOffer.id],
                    })
                  }
                })
                putDWAOffer({
                  body: offerDifference,
                  categoryId: category.categoryId,
                })
                setIsEditing(false)
              }}
            >
              Reorder Offers
            </Button>
            <Button
              type="secondary"
              style={{
                background: 'white',
                bottom: '20px',
                position: 'fixed',
                right: '190px',
              }}
              onClick={() => {
                offers.sort((a, b) => a.priority - b.priority)
                setOfferIndexes(offers)
                setIsEditing(false)
              }}
            >
              Cancel Reorder
            </Button>
          </div>
        ) : null}
      </div>
    )
  } else {
    return (
      <div>
        <div className="hc-pa-normal">
          <Grid.Container padding="none">
            <Grid.Item>No offers to show.</Grid.Item>
          </Grid.Container>
        </div>
      </div>
    )
  }
}
export default SortableOfferContainer
