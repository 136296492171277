import marketMapper from './marketMapper'
import offerItemMapper from './offerItemMapper'

const offerArtPatchMapper = (offerArtPatch) => ({
  deleted: offerArtPatch.deleted,
  id: offerArtPatch.id,
  ivyItems: offerArtPatch.ivyItems
    ? offerArtPatch.ivyItems
        .map(offerItemMapper)
        .sort(
          (a, b) =>
            a.ivyPriority - b.ivyPriority || a.description - b.description
        )
        .filter((ivyItem) => ivyItem.deleted === false)
    : [],
  markets: offerArtPatch.markets
    ? offerArtPatch.markets
        .map(marketMapper)
        .sort((a, b) => a.priority - b.priority)
    : [],
  offerId: offerArtPatch.offerId,
  priority: offerArtPatch.priority,
})
export default offerArtPatchMapper
