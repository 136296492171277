const PlanningViewMessage = () => (
  <div className="planning-note">
    <strong>About this view</strong>: Monthly planning space where the Promo
    Marketing team curates weekly marketing stories, bundles IVY umbrellas and
    recommends marketing channel amplification based on promo priorities.
    <p>
      <strong>Primary Inputs from these user groups</strong>: Marketing and
      Admin
    </p>
  </div>
)

export default PlanningViewMessage
