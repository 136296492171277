import {
  Grid,
  Tabs,
  Divider,
  Heading,
  Input,
  ProgressBar,
  Image,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import { sortBy } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import OfferApi from '../../api/creative-hub/OfferApi'
import CancelSubmitButtons from '../../components/CancelSubmitButtons'
import noImagePlaceholder from '../../media/NoImagePlaceholder.png'
import AssetHubApi from '../api/projectExecution/AssetHubApi'

const AssetHubExecutionModal = (props) => {
  const {
    isItemImageModalOpen,
    setIsItemImageModalOpen,
    itemSelected,
    setItemSelected,
    setUpdatedItemImage,
  } = props
  const [alternateImages, setAlternateImages] = useState(
    itemSelected
      ? sortBy(itemSelected?.creativehub_alt_images, (a) => a.imagePriority)
      : null
  )
  const [weeklyAdToggle, setWeeklyAdToggle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentActiveTab, setCurrentActiveTab] = useState('primaryImage')
  const [primaryImageSelected, setPrimaryImageSelected] = useState(
    itemSelected.primary_image_url
  )
  const [imageAvailable, setImageAvailable] = useState(true)
  const [primaryDBImage, setPrimaryDBImage] = useState(
    itemSelected.primary_image_url
  )
  const [parentValues, setParentValues] = useState(
    itemSelected?.creativehub_alt_images
  )
  const offerApi = new OfferApi()
  const assetHubApi = new AssetHubApi()

  useEffect(() => {
    fulfilledAssetHub(0, weeklyAdToggle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitItem = async (itemData) => {
    try {
      const resp = await offerApi.updateOfferItems([itemData])
      setUpdatedItemImage(resp[0])
      return resp
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const cleanup = () => {
    setWeeklyAdToggle(false)
    setAlternateImages()
    setCurrentActiveTab('primaryImage')
    setParentValues()
    setIsItemImageModalOpen(false)
    setPrimaryDBImage()
  }

  const findImage = (alternateImages) =>
    alternateImages?.find((result) => primaryDBImage === result.image_url)

  const createAlternateImageArray = (alternateImages, parentValues = null) => {
    let imageArray = formatAssetHubResults(alternateImages)
    imageArray.forEach((image) => {
      let found = parentValues?.find((it) => it.image_url === image.image_url)
      if (found) {
        image.id = found.id
        image.deleted = found.deleted
      }
    })
    return imageArray
  }

  const formatAssetHubResults = (alternateImages) =>
    alternateImages?.map(
      (result) =>
        ({
          asset_key: result.asset_id,
          deleted: true,
          file_name: result.file_name.replace(/\s/g, ''),
          id: null,
          image_url: assetHubApi.storageUrlKey(
            result.storage_url,
            result.file_name.replace(/\s/g, ''),
            result.import_date
          ),
          import_date: result.import_date,
          storage_url: result.storage_url,
        }) || []
    )

  const fulfilledAssetHub = async (page = 0, weeklyAdToggle = false) => {
    setLoading(true)
    if (itemSelected.dpci) {
      await assetHubApi
        .postSearch(itemSelected.dpci, page, 21, weeklyAdToggle)
        .then((res) => {
          const imagesArray = createAlternateImageArray(
            res.search,
            parentValues
          )
          setAlternateImages(imagesArray)
        })
        .catch((err) => {
          setAlternateImages(createAlternateImageArray([], parentValues))
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      await assetHubApi
        .postSearchTCIN(itemSelected?.tcin, page, 21, weeklyAdToggle)
        .then((res) => {
          const imagesArray = createAlternateImageArray(
            res.search,
            parentValues
          )
          setAlternateImages(imagesArray)
        })
        .catch((err) => {
          setAlternateImages(createAlternateImageArray([], parentValues))
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const setImageChecked = (image_url, value) => {
    let newImagesArray = Array.from(alternateImages)
    newImagesArray.forEach((image) => {
      if (image.image_url === image_url) {
        image.deleted = value
        if (image.deleted) {
          image.image_priority = null
        }
      }
    })
    setAlternateImages(newImagesArray)
    setParentValues(newImagesArray)
  }

  const handleDownload = () => {
    //assetHub alternate Images
    alternateImages.forEach((image) => {
      if (!image.deleted) {
        downloadImage(image.image_url, image.file_name)
      }
    })
    //assetHub image as primary image
    if (
      primaryImageSelected.match('(/assethub_assets/[^ "]+$)+') ||
      primaryImageSelected.match('(/vault_digital_assets/[^ "]+$)+')
    ) {
      const foundImage = alternateImages?.find(
        (result) => primaryImageSelected === result.image_url
      )
      downloadImage(foundImage.image_url, foundImage.fileName)
    }
  }

  const downloadImage = (url, filename) => {
    if (url.match('(https://target.scene7.com/is/image/Target/[^ "]+$)+')) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = filename
          a.click()
        })
    } else {
      window.open(url + '&is_download=true', '_blank')
    }
  }
  return (
    <div className="hc-pa-normal">
      <Formik
        initialValues={{
          alternate_images: alternateImages || [],
        }}
        onSubmit={(values) => {
          submitItem({
            ...itemSelected,
            alternate_images: alternateImages,
            id: itemSelected.item_id,
            item_id: itemSelected.item_id,
            primary_image_url: primaryImageSelected,
          })
          setItemSelected()
          cleanup()
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Modal
            style={{ zIndex: 1080 }}
            data-testid="assethubModal"
            isVisible={isItemImageModalOpen && itemSelected != null}
            onRefuse={() => {
              cleanup()
            }}
            headingText={'AssetHub Image Search'}
            className="larger_modal"
          >
            <Tabs
              activeTab={currentActiveTab}
              onTabSelect={(event, tab) => {
                setCurrentActiveTab(tab.name)
              }}
            >
              <Tabs.Item name="primaryImage">Primary Image</Tabs.Item>
              <Tabs.Item name="alternateImages">Alternate Images</Tabs.Item>
              <Divider />
            </Tabs>
            <div className="hc-pa-normal image_modal">
              <Heading>
                {currentActiveTab === 'primaryImage'
                  ? `Select Primary Image`
                  : `Select Alternate Images`}
              </Heading>
              <Heading size={4}>
                {' '}
                {(itemSelected.dpci && `DPCI  ${itemSelected.dpci}`) ||
                  (itemSelected.tcin && `TCIN  ${itemSelected.tcin}`)}
              </Heading>
              <Input.Toggle
                id="assethub_non_weeklyad_toggle"
                label="Non-Weekly Ad Images Search"
                checked={weeklyAdToggle}
                onChange={() => {
                  fulfilledAssetHub(0, !weeklyAdToggle)
                  setWeeklyAdToggle(!weeklyAdToggle)
                }}
              />
              {currentActiveTab === 'primaryImage' ? (
                //Primary Image Tab
                <>
                  <div className="hc-pa-normal">
                    <Grid.Container className="imageGridContainer" align="top">
                      {itemSelected.digital_image_url ? (
                        <Grid.Item
                          key="originalImage"
                          className="imageContainer"
                        >
                          <Grid.Container className="imageInfo">
                            <Image
                              position="top-right"
                              width={'100%'}
                              height={'100%'}
                              size={'contain'}
                              src={itemSelected.digital_image_url}
                              alt="filename"
                            />
                            <Grid.Item xs="6">
                              <Input.Checkbox
                                id="originalImageUrl"
                                className="image_check"
                                disabled={
                                  primaryImageSelected &&
                                  primaryImageSelected !==
                                    itemSelected.digital_image_url
                                }
                                onChange={(event) => {
                                  if (event.target.checked === true) {
                                    setPrimaryImageSelected(
                                      itemSelected.digital_image_url
                                    )
                                  } else {
                                    setPrimaryImageSelected(null)
                                  }
                                }}
                                checked={
                                  primaryImageSelected &&
                                  primaryImageSelected ===
                                    itemSelected.digital_image_url
                                }
                              />
                            </Grid.Item>
                            <Grid.Item xs="6">
                              <div
                                className="downloadButton"
                                onClick={() =>
                                  downloadImage(
                                    itemSelected?.digital_image_url,
                                    itemSelected?.digital_image_url
                                      .split('/')
                                      .pop() + '.jpg'
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  className="download"
                                  icon={faFileDownload}
                                  size="2x"
                                  color="grey"
                                />
                              </div>
                            </Grid.Item>
                            <p className="caption">TCIN Original Image</p>
                          </Grid.Container>
                        </Grid.Item>
                      ) : (
                        <Grid.Item
                          key="noOriginalImage"
                          className="imageContainer"
                        >
                          <Grid.Container className="imageInfo">
                            <Image
                              className="noImageThumbnail"
                              position="top-left"
                              width={'100%'}
                              height={'100%'}
                              type={'contain'}
                              src={noImagePlaceholder}
                              alt="filename"
                            />
                            <Grid.Item xs="6">
                              <Input.Checkbox
                                id="originalImageUrl"
                                className="image_check"
                                onChange={(event) => {
                                  if (event.target.checked === true) {
                                    setImageAvailable(false)
                                  } else {
                                    setImageAvailable(true)
                                  }
                                  setPrimaryImageSelected(null)
                                }}
                                checked={!imageAvailable}
                                disabled={
                                  primaryImageSelected &&
                                  primaryImageSelected !== primaryDBImage &&
                                  imageAvailable
                                }
                              />
                            </Grid.Item>
                            <p className="caption">TCIN Original Image</p>
                          </Grid.Container>
                        </Grid.Item>
                      )}
                      {loading ? (
                        <Grid.Item className="hc-pt-expanded" xs={12}>
                          <ProgressBar indeterminate />
                        </Grid.Item>
                      ) : !loading && alternateImages?.length > 0 ? (
                        <>
                          {!findImage(alternateImages) &&
                            primaryDBImage !== itemSelected.digital_image_url &&
                            (primaryImageSelected !== '' ||
                              primaryImageSelected) &&
                            primaryDBImage && (
                              <>
                                <Grid.Item
                                  key="primaryImage"
                                  className="imageContainer"
                                >
                                  <Grid.Container className="imageInfo">
                                    <Image
                                      position="top-right"
                                      width={'100%'}
                                      height={'100%'}
                                      type={'contain'}
                                      src={primaryDBImage}
                                      alt="filename"
                                    />
                                    <Grid.Item xs="6">
                                      <Input.Checkbox
                                        id="primarySelectedUrl"
                                        className="image_check"
                                        disabled={
                                          primaryImageSelected &&
                                          primaryImageSelected !==
                                            primaryDBImage
                                        }
                                        onChange={(event) => {
                                          if (event.target.checked === true) {
                                            setPrimaryImageSelected(
                                              primaryDBImage
                                            )
                                          } else {
                                            setPrimaryImageSelected(null)
                                          }
                                        }}
                                        checked={
                                          primaryImageSelected &&
                                          primaryImageSelected ===
                                            primaryDBImage
                                        }
                                      />
                                    </Grid.Item>
                                    <Grid.Item xs="6">
                                      <div
                                        className="downloadButton"
                                        onClick={() =>
                                          downloadImage(
                                            primaryDBImage,
                                            primaryDBImage.split('/').pop() +
                                              '.jpg'
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="download"
                                          icon={faFileDownload}
                                          size="2x"
                                          color="grey"
                                        />
                                      </div>
                                    </Grid.Item>
                                    <p className="caption">
                                      Current Primary Image
                                    </p>
                                    <div className="break" />
                                  </Grid.Container>
                                </Grid.Item>
                              </>
                            )}
                          {alternateImages?.map((result) => (
                            <Grid.Item
                              key={result.asset_key}
                              className="imageContainer"
                            >
                              <Grid.Container className="imageInfo">
                                <Image
                                  position="top-right"
                                  width={'100%'}
                                  height={'100%'}
                                  size={'contain'}
                                  src={result.image_url}
                                  alt={result.file_name}
                                />
                                <Grid.Item xs="6">
                                  <Input.Checkbox
                                    id={result.asset_key}
                                    className="image_check"
                                    disabled={
                                      primaryImageSelected &&
                                      primaryImageSelected !== result.image_url
                                    }
                                    onChange={(event) => {
                                      if (event.target.checked === true) {
                                        setPrimaryImageSelected(
                                          result.image_url
                                        )
                                      } else {
                                        setPrimaryImageSelected(null)
                                      }
                                    }}
                                    checked={
                                      primaryImageSelected &&
                                      primaryImageSelected === result.image_url
                                    }
                                  />
                                </Grid.Item>
                                <Grid.Item xs="6">
                                  <div
                                    className="downloadButton"
                                    onClick={() =>
                                      downloadImage(
                                        result.image_url,
                                        result.file_name
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="download"
                                      icon={faFileDownload}
                                      size="2x"
                                      color="grey"
                                    />
                                  </div>
                                </Grid.Item>
                                {result.import_date ? (
                                  <p className="caption">
                                    Upload Date:
                                    <br />
                                    {moment(result.import_date).format(
                                      'MMM D, YYYY h:mm a'
                                    )}
                                  </p>
                                ) : null}
                                <div className="break" />
                                {result.file_name ? (
                                  <p className="caption">
                                    File Name:
                                    <br />
                                    {result.file_name}
                                  </p>
                                ) : null}
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </>
                      ) : (
                        <>
                          {primaryImageSelected !==
                            itemSelected?.digital_image_url &&
                            primaryDBImage !==
                              itemSelected?.digital_image_url &&
                            (primaryImageSelected !== '' ||
                              primaryImageSelected) && (
                              <Grid.Item
                                key="primaryImage"
                                className="imageContainer"
                              >
                                <Grid.Container className="imageInfo">
                                  <Image
                                    position="top-right"
                                    width={'100%'}
                                    height={'100%'}
                                    type={'contain'}
                                    src={primaryDBImage}
                                    alt="filename"
                                  />
                                  <Grid.Item xs="6">
                                    <Input.Checkbox
                                      id="primarySelectedUrl"
                                      className="image_check"
                                      disabled={
                                        primaryImageSelected &&
                                        primaryImageSelected !== primaryDBImage
                                      }
                                      onChange={(event) => {
                                        if (event.target.checked === true) {
                                          setPrimaryImageSelected(
                                            primaryImageSelected
                                          )
                                        } else {
                                          setPrimaryImageSelected(null)
                                        }
                                      }}
                                      checked={
                                        primaryImageSelected &&
                                        primaryImageSelected === primaryDBImage
                                      }
                                    />
                                  </Grid.Item>
                                  <Grid.Item xs="6">
                                    <div
                                      className="downloadButton"
                                      onClick={() =>
                                        downloadImage(
                                          primaryDBImage,
                                          primaryDBImage
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className="download"
                                        icon={faFileDownload}
                                        size="2x"
                                        color="grey"
                                      />
                                    </div>
                                  </Grid.Item>
                                  <p className="caption">
                                    Current Primary Image
                                  </p>
                                  <div className="break" />
                                </Grid.Container>
                              </Grid.Item>
                            )}
                          <Grid.Container>
                            <div className="hc-pa-normal noAssets">
                              No AssetHub Results Found
                            </div>
                          </Grid.Container>
                        </>
                      )}
                    </Grid.Container>
                  </div>
                </>
              ) : (
                //Alternate Images Tab
                <>
                  <div className="hc-pa-normal">
                    <Grid.Container className="imageGridContainer">
                      {loading ? (
                        <Grid.Item className="hc-pt-expanded" xs={12}>
                          <ProgressBar indeterminate />
                        </Grid.Item>
                      ) : !loading && alternateImages?.length > 0 ? (
                        <>
                          {alternateImages?.map((result) => (
                            <Grid.Item
                              className="imageContainer"
                              key={result.asset_key}
                            >
                              <Grid.Container className="imageInfo">
                                <Image
                                  position="top-right"
                                  width={'100%'}
                                  height={'100%'}
                                  size={'contain'}
                                  src={result.image_url}
                                  alt={result.file_name}
                                />
                                <Grid.Item xs="6">
                                  <Input.Checkbox
                                    id={result.image_url}
                                    className="image_check"
                                    onChange={(event) => {
                                      event.target.checked === true
                                        ? setImageChecked(
                                            result.image_url,
                                            false
                                          )
                                        : setImageChecked(
                                            result.image_url,
                                            true
                                          )
                                    }}
                                    checked={!result.deleted}
                                  />
                                </Grid.Item>
                                <Grid.Item xs="6">
                                  <div
                                    className="downloadButton"
                                    onClick={() =>
                                      downloadImage(
                                        result.image_url,
                                        result.file_name
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="download"
                                      icon={faFileDownload}
                                      size="2x"
                                      color="grey"
                                    />
                                  </div>
                                </Grid.Item>
                                {result.importDate ? (
                                  <p className="caption">
                                    Upload Date:
                                    <br />
                                    {moment(result.import_date).format(
                                      'MMM D, YYYY h:mm a'
                                    )}
                                  </p>
                                ) : null}
                                <div className="break" />
                                {result.file_name ? (
                                  <p className="caption">
                                    File Name:
                                    <br />
                                    {result.file_name}
                                  </p>
                                ) : null}
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </>
                      ) : (
                        <>
                          <Grid.Container>
                            <div className="hc-pa-normal noAssets">
                              No AssetHub Results Found
                            </div>
                          </Grid.Container>
                        </>
                      )}
                    </Grid.Container>
                  </div>
                </>
              )}
              <Grid.Container>
                <div className="hc-pa-normal">
                  <CancelSubmitButtons
                    handleDownload={handleDownload}
                    downloadText="Download Selected Images"
                    showDownload={true}
                    handleDialogClose={cleanup}
                    submitDisabled={!primaryImageSelected && imageAvailable}
                    handleSubmit={handleSubmit}
                    canDelete={false}
                  />
                </div>
              </Grid.Container>
            </div>
          </Modal>
        )}
      </Formik>
    </div>
  )
}

export default AssetHubExecutionModal
