import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class ExportApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_export_api = () => {
    this.client = axios.create({
      baseURL: this.config.backend.catalog,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getExport = async (url) =>
    this.init_export_api()
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        let filename = response.headers['content-disposition']
          ? response.headers['content-disposition'].match(
              /application; filename="(?<filename>.*)"/
            ).groups.filename
          : ''
        const href = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', filename ? filename : 'export.xlsx')
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(href)
        link.remove()
      })
      .catch((e) => console.log(e))
}
