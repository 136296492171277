import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useEffect, useMemo, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import MerchandiseApi from '../../api/creative-hub/MerchandiseApi'
import ProjectApi from '../../api/creative-hub/ProjectApi'
import '../scss/StickySelectAndFilter.scss'
import { BreadcrumbContext } from '../../context/Context'
import MarketingChannelSelect from '../../creativeHubGlobal/components/MarketingChannelSelect'
import MarketingPriorityFilter from '../../creativeHubGlobal/components/MarketingPriorityFilter'
import MarketingPyramidSelect from '../../creativeHubGlobal/components/MarketingPyramidSelect'
import { divisionSelection } from '../../creativeHubGlobal/util/MerchandiseUtil'
import {
  handleFireflyInit,
  handleFireflyFailure,
  handleFireflySuccess,
} from '../../util/FireflyHelper'

const StickySelectAndFilter = (props) => {
  const {
    selectedProject,
    setSelectedProject,
    selectedMarketingPriorityFilter,
    selectedMarketingPyramidFilter,
    selectedMarketingChannelFilter,
    setMarketingPriorityFilter,
    setMarketingPyramidFilter,
    setMarketingChannelFilter,
    selectedDivisionFilter,
    setSelectedDivisionFilter,
    pyramidsApiLoading,
    channels,
    handleFilterUpdate,
    pastProject,
    setPastProject,
    session = {},
    viewName = '',
  } = props
  const projectApi = useMemo(() => new ProjectApi(), [])
  const merchandiseApi = useMemo(() => new MerchandiseApi(), [])
  const [projects, setProjects] = useState([])
  const [divisions, setDivisions] = useState([])
  const displayDivisions = divisionSelection(divisions)
  const [projectSelectValues, setProjectSelectValues] = useState([])
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const navigate = useNavigate()

  useEffect(() => {
    handleFireflyInit('pageload', viewName, 'success', session)
    projectApi
      .findProjects({ past_project: pastProject, project_description: '' })
      .then((data) => {
        setProjects(data.projects)
        handleFireflySuccess('pageload', viewName, 'success', session)
      })
      .catch((e) => {
        console.log(e)
        handleFireflyFailure('pageload', viewName, 'error', session)
      })
    merchandiseApi.getDivisions().then((response) => setDivisions(response))
  }, [projectApi, pastProject, session, viewName, merchandiseApi])

  useEffect(() => {
    setProjectSelectValues(formatOptions(projects))
  }, [projects])

  const formatOptions = (projects) =>
    projects?.map((project) => ({
      disabled: false,
      label: project.project_description + ' | ' + project.project_name,
      value: project.project_id,
    }))
  const formatOption = (project) => ({
    disabled: false,
    label: project.project_description + ' | ' + project.project_name,
    value: project.project_id,
  })

  const handleProjectChange = (id, value) => {
    if (value != null) {
      const newProject = projects.find(
        (project) => project.project_id === value.value
      )
      setSelectedProject(newProject)
      navigate(`/execution/${newProject.project_id}`, {
        state: newProject,
      })
      setBreadcrumbContext({
        row: {
          project_id: newProject.project_id,
          project_name: newProject.project_name,
        },
        setBreadcrumbContext,
      })
    }
  }

  const handlePastProject = () => {
    setPastProject(!pastProject)
  }

  return (
    <Grid.Container
      className="stickybar-container-execution"
      data-testid="stickyBar"
    >
      <Grid.Item xs={12}>
        <Grid.Container xs={12}>
          <Grid.Item xs={6} data-cy="selectProject">
            <Autocomplete
              id="selectProject"
              label={
                pastProject ? 'Select Past Project' : 'Select Upcoming Project'
              }
              //data-cy="selectProject"
              value={selectedProject ? formatOption(selectedProject) : null}
              placeholder="< Find Projects >"
              filter={Autocomplete.filters.contains}
              onUpdate={handleProjectChange}
              options={projectSelectValues}
              disabled={pyramidsApiLoading}
            />
          </Grid.Item>
          <Grid.Item xs={3}>
            <MarketingPriorityFilter
              selectedMarketingPriorityFilter={selectedMarketingPriorityFilter}
              setMarketingPriorityFilter={setMarketingPriorityFilter}
              setMarketingPyramidFilter={setMarketingPyramidFilter}
              disabled={pyramidsApiLoading}
              handleFilterUpdate={handleFilterUpdate}
            />
          </Grid.Item>
          <Grid.Item xs={3}>
            <MarketingPyramidSelect
              selectedMarketingPriorityFilter={selectedMarketingPriorityFilter}
              selectedMarketingPyramidFilter={selectedMarketingPyramidFilter}
              setMarketingPriorityFilter={setMarketingPriorityFilter}
              setMarketingPyramidFilter={setMarketingPyramidFilter}
              disabled={pyramidsApiLoading}
              handleFilterUpdate={handleFilterUpdate}
            />
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={3}>
        <Autocomplete
          id="divisions"
          label={'Division'}
          value={selectedDivisionFilter || ''}
          placeholder="Select Division"
          multiselect
          grow
          filter={Autocomplete.filters.contains}
          onUpdate={(id, value) => {
            setSelectedDivisionFilter(value)
          }}
          options={displayDivisions}
          disabled={pyramidsApiLoading}
        />
      </Grid.Item>
      <Grid.Item xs={6}>
        <MarketingChannelSelect
          selectedMarketingChannelFilter={selectedMarketingChannelFilter}
          setMarketingChannelFilter={setMarketingChannelFilter}
          channels={channels}
          handleFilterUpdate={handleFilterUpdate}
          disabled={pyramidsApiLoading}
        />
      </Grid.Item>
      <Grid.Item xs={3}>
        <Button className="gray-btn" onClick={handlePastProject}>
          {!pastProject ? (
            <span> View Past Projects </span>
          ) : (
            <span> View Upcoming Projects </span>
          )}
        </Button>
      </Grid.Item>
    </Grid.Container>
  )
}
export default StickySelectAndFilter
