import { GridContainer, Grid } from '@enterprise-ui/canvas-ui-react'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import appConfig from '../../config/appConfig'
import { isOfferAddedAfterProjectKODate } from '../../creativeHubGlobal/util/OfferUtil'
import { formatToUi } from '../../util/MomentUtil'
import '../scss/OfferBasic.scss'

const OffersBasic = (props) => {
  const { offer = {}, selectedProject } = props
  const {
    deleted = false,
    ivy_offer_id = -1,
    key = false,
    name,
    created_date,
  } = offer

  //  query params used when user landing through email links
  const config = appConfig()

  return (
    <div padding="none">
      <GridContainer
        data-testid="offer"
        spacing="none"
        align="left"
        justify="space-around"
        xs={12}
      >
        <Grid.Item xs={3} align="left" className="headerRow">
          <a
            target="_blank"
            href={`${config.ivy.uiUrl}promotion/${ivy_offer_id}/edit/general`}
            rel="noreferrer"
            draggable={false}
            className="offerLink"
            onClick={(e) => e.stopPropagation()}
          >
            {key ? (
              <FontAwesomeIcon
                icon={faKey}
                className="fa-duotone key"
                size="lg"
                color="black"
              />
            ) : null}
            {deleted ? ivy_offer_id : ivy_offer_id}
          </a>
        </Grid.Item>
        <Grid.Item xs={9} align="left" className="headerRow offer-name">
          {isOfferAddedAfterProjectKODate(
            selectedProject?.offers_due_date,
            created_date
          ) ? (
            <span
              title={`Added to CH ${formatToUi(created_date)}`}
              className="late_offer_background"
            >
              {name}
            </span>
          ) : (
            name
          )}
        </Grid.Item>
      </GridContainer>
    </div>
  )
}

export default OffersBasic
