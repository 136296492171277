import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import '../scss/Category.scss'

const MarketSelect = ({
  marketsSelected,
  setMarketsSelected,
  marketOptions,
  disabled,
  setChangeDetected,
}) => (
  <Autocomplete
    id="marketSelect"
    multiselect
    chipHeight="dense"
    label=""
    value={marketsSelected}
    onUpdate={(_, value) => {
      if (value.filter((e) => e.label === 'Select All').length > 0) {
        value = marketOptions
      } else if (value.filter((e) => e.label === 'Deselect All').length > 0) {
        value = []
      } else if (value.filter((e) => e.label === 'Mainland').length > 0) {
        value = marketOptions.filter((e) => !['AK', 'HI'].includes(e.name))
      } else if (value.filter((e) => e.label === 'Offshore').length > 0) {
        value = marketOptions.filter((e) => ['AK', 'HI'].includes(e.name))
      }
      if (value !== marketsSelected) {
        setChangeDetected(true)
        setMarketsSelected(value)
      }
    }}
    options={[
      { label: 'Select All' },
      { label: 'Deselect All' },
      { label: 'Mainland' },
      { label: 'Offshore' },
    ].concat(marketOptions)}
    placeholder="Choose Markets"
    data-testid="marketSectionSelect"
    disabled={disabled}
  />
)

export default MarketSelect
