import { formatToDateTime } from './MomentUtil'

export function getPromoUnitRetail(patch, isMWMarket) {
  if (isMWMarket) {
    return patch.defaultPromoUnitRetail
  } else if (patch.defaultPromoUnitRetailUpdatedTimestamp === null) {
    return `${
      patch.defaultPromoUnitRetail
    } price patch added ${formatToDateTime(patch.lastUpdatedTimestamp)}`
  } else if (
    patch.defaultPromoUnitRetailUpdatedTimestamp !== null &&
    patch.defaultPromoUnitRetailUpdatedTimestamp !==
      patch.promoUnitRetailLastUpdatedTimestamp
  ) {
    return `${patch.defaultPromoUnitRetail} was ${
      patch.previousDefaultPromoUnitRetail
    }, revised ${formatToDateTime(patch.lastUpdatedTimestamp)}`
  }
  return patch.defaultPromoUnitRetail
}
