import DateFnsUtils from '@date-io/date-fns'
import { Image } from '@enterprise-ui/canvas-ui-react'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import { LocalizationProvider } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

import PublishingApi from '../../api/creative-hub/PublishingApi'
import { editGroupedOfferFormSchema } from '../util/GroupedOfferHelper'
import { CREATIVEHUB_GO_PREFIX } from '../util/GroupedOfferUtils'

import TextFieldWrapper from './TextFieldWrapper'

export const useStyles = makeStyles({
  button: {
    height: '55px',
    width: '100%',
  },
  checkbox: {
    '& .Mui-checked': {
      color: '#33691e',
    },
  },
  inputTouched: {
    backgroundColor: 'rgba(178, 216, 178, 0.5)',
    width: '100%',
  },
  listingFormInput: {
    '& .MuiFormLabel-root': {
      color: '#33691e',
    },
    width: '100%',
  },
  select: {
    '& .MuiFormLabel-root': {
      color: '#33691e',
    },
    width: '100%',
  },
})

export const GroupedOfferDetailsForm = (props) => {
  const {
    disabled,
    setSnackbar,
    closeGroupOfferModal,
    activeGroupedOffer,
    resetData,
  } = props
  const creativeHubApi = useMemo(() => new PublishingApi(), [])

  const classes = useStyles()

  const updateCreativeHubGroupedOffer = async (values) => {
    const payloadImages = [
      {
        external_url: values.primary_image,
        id: null,
        internal_url: '',
      },
    ]

    const payload = {
      body_copy: values.body_copy ? values.body_copy : '',
      boosted_tcins: values.boosted_tcins ? values.boosted_tcins : '',
      feature_offer: values.feature_offer ? values.feature_offer : '',
      images: payloadImages ? payloadImages : '',
      offers: values.offer_ids.split(',').map((id) => id.trim()),
    }

    await creativeHubApi.updateGroupedOffer(payload, values.go_id.substring(5))
    resetData(true)
    setSnackbar({
      key: activeGroupedOffer.toString(),
      message: 'Your Grouped Offer was updated: ' + values.go_id,
      severity: 'success',
    })
    closeGroupOfferModal(true)
  }

  return !isEmpty(activeGroupedOffer) ? (
    <Formik
      validationSchema={editGroupedOfferFormSchema}
      initialValues={{
        ad_id: activeGroupedOffer.ad_id,
        body_copy: activeGroupedOffer.body_copy,
        boosted_tcins: activeGroupedOffer.boosted_tcins,
        dpci: activeGroupedOffer.dpci,
        feature_offer: activeGroupedOffer.feature_offer,
        go_id: CREATIVEHUB_GO_PREFIX + '-' + activeGroupedOffer.go_id,
        offer_ids: activeGroupedOffer.offers
          .map((offer) => offer.ivy_offer_id)
          .join(', '),
        primary_image: activeGroupedOffer.images[0].external_url,
      }}
      onSubmit={(values) => {
        updateCreativeHubGroupedOffer(values)
      }}
    >
      {({
        initialValues,
        values,
        isValid,
        dirty,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
      }) => {
        const {
          primary_image = '',
          go_id = '',
          offer_ids = '',
          boosted_tcins = '',
          body_copy = '',
          feature_offer = '',
        } = values
        const handleChange = (field, value) => {
          setFieldValue(field, value)
        }
        const assignClass = (label) =>
          initialValues[label] !== values[label]
            ? classes.inputTouched
            : classes.listingFormInput

        return (
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <div data-testid="listingDetailsContainer" className="hc-pa-normal">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Image
                    src={primary_image}
                    height="100px"
                    width="100px"
                    type="contain"
                    alt={primary_image}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    id="primary_image"
                    label="Image Link"
                    value={primary_image}
                    onChange={(event) =>
                      handleChange('primary_image', event.target.value)
                    }
                    className={assignClass('primary_image')}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    id="go_id"
                    label="Grouped Offer (GO) ID"
                    value={go_id}
                    className={assignClass('go_id')}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    multiline
                    rows={3}
                    id="offer_ids"
                    label="Offer Ids"
                    value={offer_ids}
                    key="offer_ids+go_id"
                    required
                    onChange={(event) => {
                      setFieldValue('offer_ids', event.target.value)
                    }}
                    className={assignClass('offer_ids')}
                    error={Boolean(errors.offer_ids)}
                    helperText={errors.offer_ids}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    id="boosted_tcins"
                    label="Boosted Tcins"
                    multiline={true}
                    onChange={(event) =>
                      handleChange('boosted_tcins', event.target.value)
                    }
                    value={boosted_tcins}
                    className={assignClass('boosted_tcins')}
                    key="boosted_tcins+go_id"
                    error={Boolean(errors.boosted_tcins)}
                    helperText={'Boosted TCINs are pulled from Ivy'}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    id="bodyCopy"
                    label="Body Copy"
                    value={body_copy}
                    error={Boolean(touched.title && errors.title)}
                    onChange={(event) =>
                      handleChange('body_copy', event.target.value)
                    }
                    className={assignClass('body_copy')}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWrapper
                    id="featureOffer"
                    label="Feature Offer"
                    value={feature_offer}
                    onChange={(event) =>
                      handleChange('feature_offer', event.target.value)
                    }
                    className={assignClass('feature_offer')}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                    className={classes.button}
                    disabled={!dirty || !isValid || disabled}
                  >
                    Update Grouped Offer
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<CancelIcon />}
                    onClick={() => closeGroupOfferModal(true)}
                    data-testid="cancel-btn"
                    id="cancelBtn"
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </LocalizationProvider>
        )
      }}
    </Formik>
  ) : null
}
