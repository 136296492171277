import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import { CATEGORY_LIST } from '../util/CategoryColorMap'

const divisionFilter = ({
  category,
  setCategory,
  placeholder,
  label,
  disabled,
  setFieldValue,
}) => (
  <>
    <Autocomplete
      id="category"
      onUpdate={(id, value) => {
        setCategory(value)
        setFieldValue(value)
      }}
      disabled={disabled}
      options={CATEGORY_LIST}
      placeholder={placeholder ? placeholder : 'Category '}
      value={category}
      label={label ? label : null}
    />
  </>
)
export default divisionFilter
