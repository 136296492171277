import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import appConfig from '../config/appConfig'
import itemPatchMapper from '../mappers/itemPatchMapper'

const usePostItemPatch = (setLoading, setWeeklyAd, setToast) => {
  const config = appConfig()
  const url = useRef(config.backend.root + config.backend.itemPatch)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    let mounted = true
    const postItemPatch = async () => {
      mounted && setLoading(true)
      try {
        let response = await axios.post(url.current, request.body)
        let thisItemPatch = itemPatchMapper(response.data)
        mounted &&
          setWeeklyAd((w) => {
            w.pages
              .find((page) => page.id === request.pageId)
              .stories.find((story) => story.id === request.storyId)
              .cuts.find((cut) => cut.id === request.cutId)
              .items.find((item) => item.id === request.itemId)
              .itemPatches.push(thisItemPatch)
            return w
          })
        if (setToast && mounted) {
          setToast({
            heading: 'Item updated',
            isVisible: true,
            message: 'Nice.',
            type: 'success',
          })
        }
      } catch (e) {
        console.log(e)
        if (setToast && mounted) {
          setToast({
            heading: 'Item not updated',
            isVisible: true,
            message: 'Something went wronmg, try again.',
            type: 'error',
          })
        }
      }
      setLoading(false)
      setRequest(null)
    }

    if (request !== null) {
      postItemPatch()
    }
    return () => {
      mounted = false
    }
  }, [request, setLoading, setWeeklyAd, setToast])

  return setRequest
}
export default usePostItemPatch
