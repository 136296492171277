import DateFnsUtils from '@date-io/date-fns'
import {
  Heading,
  Layout,
  Form,
  ButtonGroup,
  ProgressBar,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Button, Grid } from '@mui/material'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import PublishingApi from '../../api/creative-hub/PublishingApi'
import { TARGET_WEB_DOMAIN } from '../../util/Constants'
import {
  createGroupOfferSchema,
  validateOffers,
  validateUmbrella,
} from '../util/GroupedOfferHelper'
import {
  buildGroupedOfferRequest,
  getInitialValues,
} from '../util/GroupedOfferUtils'

import { CustomSnackbar } from './CustomSnackbar'
import { ImageCard } from './ImageCard'
import TextFieldWrapper from './TextFieldWrapper'

const theme = createTheme({
  palette: {
    primary: {
      main: '#33691e',
    },
    secondary: {
      main: '#bdbdbd',
    },
  },
})

const grey = '#888888'
const useStyles = makeStyles({
  button: {
    margin: '25px 25px 0 0',
    padding: '14px 0 14px 0',
    width: '100%',
  },
  buttonGroup: {
    '& .C-ButtonGroup__ListItem': {
      marginRight: '4%',
      width: '38%',
    },
  },
  createOffer: {
    background: '#FFFFFF',
    maxWidth: '100%',
    padding: '0px 24px',
  },
  gridItem: {
    '& .Mui-disabled': {
      '& .MuiInputBase-input': {
        color: grey,
      },
      color: grey,
    },
    '& .MuiInputBase-formControl': {
      margin: '18px 0',
    },
  },
  header: {
    fontWeight: 'bold',
  },
  heading: {
    margin: '20px 0 40px 0',
  },
  margin: 0,

  marketingIdForm: {
    '& .MuiFormLabel-root': {
      margin: '20px 0 0 0',
    },
    margin: '30px 0 10px 0',
    width: '80%',
  },
  offerFormInput: {
    '& .MuiFormLabel-root': {
      margin: '20px 0 0 0',
    },
    width: '80%',
  },
  padding: 0,
})

export const CreateGroupedOffer = () => {
  const { sourcePromoUUID } = useParams() || null
  const classes = useStyles()
  const [snackbar, setSnackbar] = useState()
  const [marketingId, setMarketingId] = useState('')
  const [landingUrl, setLandingUrl] = useState('')
  useEffect(() => {}, [snackbar])
  const initialValues = getInitialValues(new Date(), sourcePromoUUID)
  const mediaUnitApi = useMemo(() => new PublishingApi(), [])
  const [isLoading, setIsLoading] = useState(false)

  const createGo = async (values) => {
    const groupedOffer = await mediaUnitApi.createGroupedOffer(
      buildGroupedOfferRequest(values)
    )
    if (groupedOffer) {
      setSnackbar({
        key: initialValues.toString(),
        message: 'Your Grouped Offer ID is: 8675-' + groupedOffer.go_id,
        severity: 'success',
      })
      setMarketingId('8675-' + groupedOffer.go_id)
      setLandingUrl(
        `${TARGET_WEB_DOMAIN}pl/8675-${groupedOffer.go_id}?marketing_id=8675-${groupedOffer.go_id}`
      )
    } else {
      setSnackbar({
        key: initialValues.toString(),
        message: 'Error creating Grouped Offer',
        severity: 'error',
      })
      setMarketingId('Error creating Grouped Offer')
      setLandingUrl('')
    }
  }

  const goLabel = () => (
    <span title="Placeholder Offer">
      <FontAwesomeIcon
        icon={faClipboard}
        className="fa-duotone key"
        size="lg"
        color="#aaaaaa"
        onClick={() => {
          navigator.clipboard.writeText(marketingId)
        }}
      />{' '}
      Grouped Offer ID
    </span>
  )

  const urlLabel = () => (
    <span title="Placeholder Offer">
      <FontAwesomeIcon
        icon={faClipboard}
        className="fa-duotone key"
        size="lg"
        color="#aaaaaa"
        onClick={() => {
          navigator.clipboard.writeText(landingUrl)
        }}
      />{' '}
      Link
    </span>
  )

  const offerRow = (offer) => (
    <Table.Row key={offer.id}>
      <Table.Data key="id" xs={2}>
        {offer.id}
      </Table.Data>
      <Table.Data key="status" xs={2}>
        {offer.status}
      </Table.Data>
      <Table.Data key="date" xs={3}>
        {new Date(offer.start_date).toDateString()}
      </Table.Data>
      <Table.Data key="name" xs={5}>
        {offer.name}
      </Table.Data>
    </Table.Row>
  )

  return (
    <div className={classes.createOffer}>
      {snackbar ? (
        <CustomSnackbar
          key={snackbar.key}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      ) : null}
      <Layout.Body data-testid="homePage" includeRail>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            createGo(values)
          }}
          validateOnChange={true}
          validationSchema={createGroupOfferSchema}
        >
          {({
            values,
            isValid,
            errors,
            handleChange,
            handleReset,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => {
            const {
              bodyCopy = '',
              groupImageURL = '',
              featureOffer = '',
            } = values

            const handleImageCardChange = (newBoostedId) => {
              let newSelectedBoostedIds = values.selectedBoostedIds.map(
                (tcin) => (tcin.id === newBoostedId.id ? newBoostedId : tcin)
              )
              setFieldValue('selectedBoostedIds', newSelectedBoostedIds)
            }

            const handleClearForm = () => {
              setMarketingId('')
              setLandingUrl('')
              resetForm()
            }

            return (
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                    <Form onSubmit={handleSubmit} data-testid="submittingForm">
                      <Grid container className="weeklyad-create">
                        <Grid item xs={5}>
                          <Heading size={3} className={classes.heading}>
                            Create Grouped Offer
                          </Heading>
                          <Grid container className={classes.container}>
                            <Grid item className={classes.gridItem} xs={12}>
                              <TextFieldWrapper
                                id="umbrellaId"
                                value={values.umbrellaId}
                                label="Umbrella Id"
                                onChange={(event) => {
                                  setFieldValue(
                                    'umbrellaId',
                                    event.target.value
                                  )
                                  validateUmbrella(
                                    event.target.value,
                                    values,
                                    setFieldValue,
                                    setIsLoading
                                  )
                                }}
                                className={classes.offerFormInput}
                              />
                            </Grid>
                            <Grid item className={classes.gridItem} xs={12}>
                              <TextFieldWrapper
                                multiline
                                rows={3}
                                id="offerIds"
                                label="Offer Ids"
                                value={values.offerIds}
                                required
                                onChange={(event) => {
                                  setFieldValue('offerIds', event.target.value)
                                  setIsLoading(true)
                                  validateOffers(
                                    event.target.value,
                                    values,
                                    setFieldValue,
                                    setIsLoading
                                  )
                                }}
                                className={classes.offerFormInput}
                                error={Boolean(errors.offerIds)}
                                helperText={
                                  errors.offerIds
                                    ? errors.offerIds +
                                      values.invalidOfferIds.join(', ')
                                    : ''
                                }
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                              <TextFieldWrapper
                                id="bodyCopy"
                                name="bodyCopy"
                                className={classes.offerFormInput}
                                label="Body Copy"
                                value={bodyCopy}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                              <TextFieldWrapper
                                id="groupImageURL"
                                name="groupImageURL"
                                className={classes.offerFormInput}
                                label="Image URL"
                                value={groupImageURL}
                                onChange={handleChange}
                                error={Boolean(errors.groupImageURL)}
                                helperText={errors.groupImageURL}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                              <TextFieldWrapper
                                id="featureOffer"
                                name="featureOffer"
                                className={classes.offerFormInput}
                                label="Feature Offer"
                                value={featureOffer}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <ButtonGroup className={classes.buttonGroup}>
                                <Button
                                  className={classes.button}
                                  color="secondary"
                                  variant="contained"
                                  onClick={handleClearForm}
                                  data-testid="cancelButton"
                                >
                                  Clear
                                </Button>
                                <Button
                                  className={classes.button}
                                  type="submit"
                                  onSubmit={handleSubmit}
                                  color="primary"
                                  variant="contained"
                                  data-testid="submitButton"
                                  disabled={!isValid}
                                >
                                  Create
                                </Button>
                              </ButtonGroup>
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                              <TextFieldWrapper
                                id="marketingId"
                                name="marketingId"
                                disabled={true}
                                className={classes.marketingIdForm}
                                label={goLabel()}
                                value={marketingId}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                              <TextFieldWrapper
                                id="landingUrl"
                                name="landingUrl"
                                disabled={true}
                                className={classes.offerFormInput}
                                label={urlLabel()}
                                value={landingUrl}
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={7}>
                          <Grid container>
                            {values.verifiedOffers.length > 0 ? (
                              <Grid item xs={12}>
                                <Heading size={3} className={classes.heading}>
                                  Offer Id Details
                                </Heading>
                                <Table style={{ marginBottom: '20px' }}>
                                  <Table.Head>
                                    <Table.Row>
                                      <Table.Header
                                        xs={2}
                                        className={classes.header}
                                      >
                                        Offer Id
                                      </Table.Header>
                                      <Table.Header
                                        xs={2}
                                        className={classes.header}
                                      >
                                        Status
                                      </Table.Header>
                                      <Table.Header
                                        xs={3}
                                        className={classes.header}
                                      >
                                        Start Date
                                      </Table.Header>
                                      <Table.Header
                                        xs={5}
                                        className={classes.header}
                                      >
                                        Offer Name
                                      </Table.Header>
                                    </Table.Row>
                                  </Table.Head>
                                  <Table.Body>
                                    {values.verifiedOffers.map((offer) =>
                                      offerRow(offer)
                                    )}
                                  </Table.Body>
                                </Table>
                              </Grid>
                            ) : null}
                            {values.boostedIds && !isLoading ? (
                              <Grid item xs={12}>
                                <Heading size={3} className={classes.heading}>
                                  Boosted (Featured) Items
                                </Heading>
                                <div
                                  style={{ display: 'flex', flexWrap: 'wrap' }}
                                >
                                  {values.selectedBoostedIds?.map(
                                    (tcin, index) => (
                                      <ImageCard
                                        key={index + '-' + tcin.id}
                                        id={tcin.id}
                                        tcin={tcin}
                                        handleImageCardChange={
                                          handleImageCardChange
                                        }
                                        setFieldValue={setFieldValue}
                                      />
                                    )
                                  )}
                                </div>
                              </Grid>
                            ) : isLoading ? (
                              <ProgressBar indeterminate />
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  </LocalizationProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            )
          }}
        </Formik>
      </Layout.Body>
    </div>
  )
}
