import { useAuth, ProtectedElement } from '@praxis/component-auth'
import { ErrorBoundary } from '@praxis/component-logging'
import { Routes, Route, useLocation } from 'react-router-dom'

import authUtil from '../components/Auth/authUtil'
import appConfig from '../config/appConfig'
import ProjectSummary from '../execution/components/ProjectSummary'
import CreativeSummaryPage from '../execution/creativeSummary/CreativeSummaryPage'
import ExecutionPage from '../execution/ExecutionPage'
import ErrorContent from '../globalComponents/ErrorContent'
import { GroupedOfferDetails } from '../groupedOffers/components/GroupedOfferDetails'
import { GroupedOffers } from '../groupedOffers/components/GroupedOffers'
import ImageTrackingPage from '../ImageTracking/ImageTrackingPage'
import CreateEditProject from '../planning/components/CreateEditProject'
import ProjectDetails from '../planning/components/ProjectDetails'
import PlanningPage from '../planning/PlanningPage'
import PriorityOffers from '../publishing/PriorityOffers'
import PublishingPage from '../publishing/PublishingPage'
import { handleFireflySuccess } from '../util/FireflyHelper'
import CatalogHome from '../views/catalog/CatalogHome'
import CatalogPageView from '../views/catalog/CatalogPageView'
import CatalogPagination from '../views/catalog/CatalogPagination'
import CatalogSampleView from '../views/catalog/CatalogSampleView'
import ProductHistoryView from '../views/catalog/ProductHistoryView'
import ProductListView from '../views/catalog/ProductListView'
import { VendorView } from '../views/catalog/VendorView'
import AdminCenter from '../views/global/AdminCenter'
import BundledChangeSummary from '../views/global/BundledChangeSummary'
import ChangeSummary from '../views/global/ChangeSummary'
import Contacts from '../views/global/Contacts'
import GoNoAccess from '../views/global/GoNoAccess'
import Landing from '../views/global/Landing'
import Unauthorized from '../views/global/Unauthorized'
import DigitalWeeklyAdView from '../views/weekly-ad/DigitalWeeklyAdView'
import DPCISearch from '../views/weekly-ad/DPCISearch'
import ItemHistory from '../views/weekly-ad/ItemHistory'
import LateAdditionsView from '../views/weekly-ad/LateAdditionsView'
import MerchView from '../views/weekly-ad/MerchView'
import PropView from '../views/weekly-ad/PropView'
import SampleView from '../views/weekly-ad/SampleView'
import WeeklyAd from '../views/weekly-ad/WeeklyAd'
import WeeklyAdView from '../views/weekly-ad/WeeklyAdHistory'
import WeeklyAdHome from '../views/weekly-ad/WeeklyAdHome'

export const MainRouter = (props) => {
  const { effectiveRole, toSampleUrl, isVendor, archive } = props
  const config = appConfig()
  let location = useLocation()

  if (isVendor) {
    location.pathname = '/vendor'
  }
  const { isAuthenticated, isAuthorized, login, session } = useAuth()
  const isSessionValid = authUtil(session)
  const email = session?.userInfo.email

  const unauthorizedRedirectUrl = '/unauthorized'
  const unauthorizedGoRedirectUrl = '/noaccess'
  const planningViewGroups = config.planningViewGroups
  const executionViewGroups = config.executionViewGroups

  if (
    isAuthorized() &&
    isAuthenticated() &&
    isSessionValid &&
    location.pathname === '/'
  ) {
    handleFireflySuccess('pageload', 'Login', 'success', session)
  }

  return (
    <div>
      <>
        <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
          {isAuthorized() && isAuthenticated() && isSessionValid ? (
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <Landing role={effectiveRole} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/weeklyad"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <WeeklyAdHome
                      role={effectiveRole}
                      toSampleUrl={toSampleUrl}
                      archive={archive}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/weeklyad/dpci"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                    archive={archive}
                  >
                    <DPCISearch
                      key={Math.random()}
                      role={effectiveRole}
                      archive={archive}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/weeklyad/:wadId"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                    archive={archive}
                  >
                    <WeeklyAd
                      role={effectiveRole}
                      toSampleUrl={toSampleUrl}
                      archive={archive}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/weeklyad/:wadId/DWA"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <DigitalWeeklyAdView
                      role={effectiveRole}
                      toSampleUrl={toSampleUrl}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/weeklyad/:wadId/merchant"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <MerchView
                      key={Math.random()}
                      role={effectiveRole}
                      toSampleUrl={toSampleUrl}
                      email={email}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/weeklyad/:wadId/sample/items"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                    archive={archive}
                  >
                    <SampleView
                      toSampleUrl={toSampleUrl}
                      key={Math.random()}
                      role={effectiveRole}
                      archive={archive}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/weeklyad/:wadId/sample/props"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <PropView role={effectiveRole} toSampleUrl={toSampleUrl} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/weeklyad/:wadId/sample/late-additions"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <LateAdditionsView
                      role={effectiveRole}
                      toSampleUrl={toSampleUrl}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/pastads"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <WeeklyAdHome
                      role={effectiveRole}
                      archive
                      toSampleUrl={toSampleUrl}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/change-tracking"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <WeeklyAdView />
                  </ProtectedElement>
                }
              />
              <Route
                path="/change-tracking/:wadId/:type"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <ItemHistory session={session} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/catalog"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <CatalogHome role={effectiveRole} session={session} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/catalog/:catalogId"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <CatalogPagination role={effectiveRole} session={session} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/catalog/:catalogId/product-list"
                element={
                  <ProtectedElement allowed={config.adGroups}>
                    <ProductListView
                      key={Math.random()}
                      role={effectiveRole}
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/catalog/:catalogId/creative"
                element={
                  <ProtectedElement allowed={config.adGroups}>
                    <CatalogPageView
                      key={Math.random()}
                      role={effectiveRole}
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/catalog/:catalogId/sample"
                element={
                  <ProtectedElement allowed={config.adGroups}>
                    <CatalogSampleView
                      key={Math.random()}
                      role={effectiveRole}
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/catalog/:catalogId/history"
                element={
                  <ProtectedElement allowed={config.adGroups}>
                    <ProductHistoryView
                      key={Math.random()}
                      role={effectiveRole}
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/past-catalogs"
                element={
                  <ProtectedElement
                    allowed={config.adGroups}
                    unauthorizedRoute={unauthorizedGoRedirectUrl}
                  >
                    <CatalogHome
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/noaccess"
                element={
                  <ProtectedElement>
                    <GoNoAccess role={effectiveRole} session={session} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/unauthorized"
                element={
                  <ProtectedElement>
                    <Unauthorized role={effectiveRole} session={session} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/vendor"
                element={
                  <ProtectedElement
                    allowed={config.vendorPlusGroup}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <VendorView role={effectiveRole} session={session} />
                  </ProtectedElement>
                }
              />

              <Route
                path="/planning"
                element={
                  <ProtectedElement
                    allowed={planningViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <PlanningPage archive session={session} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/planning/:projectId"
                element={
                  <ProtectedElement
                    allowed={planningViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <ProjectDetails
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/createEditProject"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <CreateEditProject
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/createEditProject/:projectId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <CreateEditProject
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />

              <Route
                path="/execution/:projectId/:pyramidId/:storyId/:umbrellaId/:offerId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <ExecutionPage archive session={session} />
                  </ProtectedElement>
                }
              />

              <Route
                path="/execution/items/:projectId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <ImageTrackingPage
                      role={effectiveRole}
                      archive={archive}
                      session={session}
                    />
                  </ProtectedElement>
                }
              />

              <Route
                path="/execution/projectSummary/:projectId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <ProjectSummary
                      role={effectiveRole}
                      archive={archive}
                      session={session}
                    />
                  </ProtectedElement>
                }
              />

              <Route
                path="/execution/creativeSummary/:projectId/:storyId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <CreativeSummaryPage
                      role={effectiveRole}
                      archive={archive}
                      session={session}
                    />
                  </ProtectedElement>
                }
              />

              <Route
                path="/execution/creativeSummary/:projectId/:storyId/:umbrellaId/:umbrellaOrder"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <CreativeSummaryPage
                      role={effectiveRole}
                      archive={archive}
                      session={session}
                    />
                  </ProtectedElement>
                }
              />

              <Route
                path="/execution"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <ExecutionPage archive session={session} />
                  </ProtectedElement>
                }
              >
                <Route
                  path=":projectId"
                  element={
                    <ProtectedElement
                      allowed={executionViewGroups}
                      unauthorizedRoute={unauthorizedRedirectUrl}
                    >
                      <ExecutionPage archive session={session} />
                    </ProtectedElement>
                  }
                >
                  <Route
                    path=":pyramidId"
                    element={
                      <ProtectedElement
                        allowed={executionViewGroups}
                        unauthorizedRoute={unauthorizedRedirectUrl}
                      >
                        <ExecutionPage archive session={session} />
                      </ProtectedElement>
                    }
                  >
                    <Route
                      path=":storyId"
                      element={
                        <ProtectedElement
                          allowed={executionViewGroups}
                          unauthorizedRoute={unauthorizedRedirectUrl}
                        >
                          <ExecutionPage archive session={session} />
                        </ProtectedElement>
                      }
                    >
                      <Route
                        path=":umbrellaId"
                        element={
                          <ProtectedElement
                            allowed={executionViewGroups}
                            unauthorizedRoute={unauthorizedRedirectUrl}
                          >
                            <ExecutionPage archive session={session} />
                          </ProtectedElement>
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route
                path="/publishing/:projectId/:categoryId/"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <PublishingPage archive session={session} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/publishing/:projectId/:categoryId/:mediaUnitId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <PublishingPage archive session={session} />
                  </ProtectedElement>
                }
              />

              <Route
                path="/publishing"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <PublishingPage archive session={session} />
                  </ProtectedElement>
                }
              >
                <Route
                  path=":projectId"
                  element={
                    <ProtectedElement
                      allowed={executionViewGroups}
                      unauthorizedRoute={unauthorizedRedirectUrl}
                    >
                      <PublishingPage archive session={session} />
                    </ProtectedElement>
                  }
                />
              </Route>

              <Route
                path="/priorityoffers/:projectId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <PriorityOffers archive session={session} />
                  </ProtectedElement>
                }
              />

              <Route
                path="/priorityoffers"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <PriorityOffers archive session={session} />
                  </ProtectedElement>
                }
              >
                <Route
                  path=":projectId"
                  element={
                    <ProtectedElement
                      allowed={executionViewGroups}
                      unauthorizedRoute={unauthorizedRedirectUrl}
                    >
                      <PriorityOffers archive session={session} />
                    </ProtectedElement>
                  }
                />
              </Route>

              <Route
                path="/groupedOffers/*"
                element={
                  <ProtectedElement
                    allowed={config.groupedOfferAllowedGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <GroupedOffers archive session={session} />
                  </ProtectedElement>
                }
              />

              <Route
                path="/groupedOffers/view/:groupId"
                element={
                  <ProtectedElement
                    allowed={config.groupedOfferAllowedGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <GroupedOfferDetails archive session={session} />
                  </ProtectedElement>
                }
              />

              <Route
                path="/admin"
                element={
                  <ProtectedElement
                    allowed={config.adminGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <AdminCenter
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/contacts"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <Contacts role={effectiveRole} archive session={session} />
                  </ProtectedElement>
                }
              />
              <Route
                path="/changeSummary"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <BundledChangeSummary
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/changeSummary/:projectId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <BundledChangeSummary
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/v1/changeSummary"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <ChangeSummary
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
              <Route
                path="/v1/changeSummary/:projectId"
                element={
                  <ProtectedElement
                    allowed={executionViewGroups}
                    unauthorizedRoute={unauthorizedRedirectUrl}
                  >
                    <ChangeSummary
                      role={effectiveRole}
                      archive
                      session={session}
                    />
                  </ProtectedElement>
                }
              />
            </Routes>
          ) : (
            login({ redirect: `${window.location.href}` })
          )}
        </ErrorBoundary>
      </>
    </div>
  )
}
