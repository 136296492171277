/**
 * This filter looks for all the letters somewhere in possible values.
 *
 * Example: "ctc" matches "cat & jack", but not "cars & cats"
 */
export default function fuzzyFilter(searchText, { label }) {
  if (searchText === null || label === null) {
    return false
  }
  const compareString = label.toString().toLowerCase()
  const searchString = searchText.toString().toLowerCase().trim()

  let compareIndex = 0
  for (let index = 0; index < compareString.length; index++) {
    if (compareString[index] === searchString[compareIndex]) {
      compareIndex++
    }
  }

  return compareIndex === searchString.length
}
