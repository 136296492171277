import {
  Font,
  Link,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Svg,
  Path,
} from '@react-pdf/renderer'
import moment from 'moment'
import { Fragment } from 'react'

import appConfig from '../../config/appConfig'
import { getDivisionName } from '../../creativeHubGlobal/util/MerchandiseUtil'
import { makeOfferDetails } from '../../creativeHubGlobal/util/OfferUtil'
import HelveticaForTarget from '../../fonts/HelveticaForTarget/HelveticaforTarget.otf'
import noImagePlaceholder from '../../media/NoImagePlaceholder.png'
import { assethubImageCheck, promoPrice } from '../util/ItemUtil'

Font.register({
  family: 'HelveticaForTarget',
  src: HelveticaForTarget,
})

Font.registerHyphenationCallback((word) => [word])

const styles = StyleSheet.create({
  content: {
    color: '#777777',
    fontFamily: 'HelveticaForTarget',
    fontSize: 14,
    fontWeight: 900,
    paddingTop: 5,
  },
  contentPromo: {
    color: '#777777',
    fontFamily: 'HelveticaForTarget',
    fontSize: 14,
    fontWeight: 900,
    paddingTop: 5,
    whiteSpace: 'pre',
  },
  footer: {
    borderColor: 'gray',
    borderStyle: 'dashed',
    borderWidth: 3,
    fontSize: 12,
    marginTop: 15,
    paddingTop: 5,
    textAlign: 'center',
  },
  hContainer: {
    alignItems: 'stretch',
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    flexDirection: 'row',
  },
  image: {
    marginBottom: 10,
  },
  itemColumns: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemImage: {
    aspectRatio: '1/1',
    borderRadius: '8',
    height: '200px',
    marginLeft: 5,
    marginRight: 5,
    width: '200px',
  },
  itemUnit: {
    backgroundColor: '#ededed',
    border: '1px solid black',
    borderRadius: '8',
    margin: 10,
    padding: 10,
    width: '32%',
  },
  itemsContainer: {
    border: '1px solid gray',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: 5,
    padding: 10,
  },
  label: {
    color: 'black',
    fontSize: 14,
    fontWeight: 100,
    marginBottom: 5,
  },
  labelContent: {
    alignItems: 'center',
    color: '#555555',
    flexDirection: 'row',
    fontFamily: 'HelveticaForTarget',
    fontSize: 14,
    fontWeight: 900,
    justifyContent: 'flex-start',
    paddingTop: 5,
  },
  leftColumn: {
    paddingRight: 15,
    width: 200,
  },
  link: {
    alignSelf: 'flex-end',
    color: 'blue',
    fontSize: 16,
    justifySelf: 'flex-end',
    textDecoration: 'none',
  },
  linkColumn: {
    alignSelf: 'flex-end',
    flexDirection: 'column',
    flexGrow: 2,
    justifySelf: 'flex-end',
  },
  name: {
    alignItems: 'center',
    fontSize: 28,
    justifyContent: 'center',
  },
  offerUnit: {
    backgroundColor: 'white',
    border: '1px solid gray',
    borderRadius: '8',
    height: 'auto',
    margin: 10,
    padding: 10,
    width: 'auto',
  },
  page: {
    fontFamily: 'HelveticaForTarget',
    padding: 30,
  },
  projectName: {
    alignContent: 'right',
    alignItems: 'right',
    fontSize: 18,
    paddingTop: 10,
  },
  rightColumn: {
    paddingRight: 15,
    width: 270,
  },
  subtitle: {
    fontSize: 18,
    justifySelf: 'flex-end',
    paddingTop: 10,
  },
  umbrellaUnit: {
    backgroundColor: '#dddddd',
    borderBottom: '2px solid #ededed',
    borderLeft: '2px solid #ededed',
    borderRadius: '8',
    height: 'auto',
    margin: 10,
    padding: 10,
    width: '100%',
  },
  underlineLabel: {
    borderBottom: '1px solid gray',
    color: 'black',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 500,
    justifyContent: 'flex-start',
    marginBottom: 5,
  },
})

const CreativeSummaryPDF = ({ divisions = {}, projectId, summaryData }) => {
  const config = appConfig()
  const Header = () => (
    <View
      fixed
      key={`pdf-header + ${summaryData?.story?.story_id}`}
      style={styles.hContainer}
    >
      <View style={styles.detailColumn}>
        <Text style={styles.name}>Story: {summaryData?.story?.story_name}</Text>
        <Text style={styles.subtitle}>
          Project:{' '}
          <Link
            target="_blank"
            href={`${config.ui.url}/execution/${projectId}/${summaryData?.story?.pyramid_id}/${summaryData?.story?.story_id}`}
            style={styles.link}
          >
            {summaryData?.project_description}
          </Link>{' '}
          - Start Date:{' '}
          {summaryData.project_start_date
            ? moment(
                summaryData?.project_start_date
                  ? summaryData.project_start_date.split('.')[0]
                  : ''
              ).format('MM/DD/YY')
            : ' '}{' '}
          - Priority Name: {summaryData?.priority_name}
        </Text>
      </View>
    </View>
  )

  const convertUnicode = (text) => {
    let start = text?.indexOf('&#')
    if (start) {
      let end = text?.indexOf(';')
      let unicode = text?.substring(start, end + 1)
      let unicodeNumber = parseInt(unicode?.substring(2, unicode?.length - 1))
      return text?.replace(
        unicode,
        String.fromCharCode(parseInt(unicodeNumber))
      )
    }
    return text
  }

  const getJpegImageUrl = (url) =>
    url.includes('?') ? `${url}&bfc=off` : `${url}?bfc=off`

  const Item = ({ item = {}, index, offer = {} }) => (
    <View wrap={false} style={styles.itemUnit} key={item.item_id + '-' + index}>
      <View style={styles.itemColumns}>
        <View style={styles.leftColumn}>
          {assethubImageCheck(item.primary_image_url, '') && (
            <Text style={styles.label}>AssetHub Image</Text>
          )}
          <Image
            src={
              item.primary_image_url
                ? getJpegImageUrl(item.primary_image_url)
                : noImagePlaceholder
            }
            styles={styles.itemImage}
          />
        </View>
        <View style={styles.rightColumn}>
          {item?.markets?.length > 0 && (
            <>
              <Text style={styles.label}>
                {`Markets:  `}
                <Text style={styles.content}>
                  {item.markets?.length > 0 ? item.markets.join('; ') : ''}
                </Text>
              </Text>
              {item.art_patch_copy ? (
                <Text style={styles.label}>
                  {`Art Patch Copy:  `}
                  <Text style={styles.content}>{item.art_patch_copy}</Text>
                </Text>
              ) : null}
            </>
          )}
          <Text style={styles.label}>
            {`DPCI:  `}
            <Text style={styles.content}>{item.dpci}</Text>
          </Text>
          <Text style={styles.label}>
            {`TCIN:  `}
            <Text style={styles.content}>{item.tcin}</Text>
          </Text>
          <Text style={styles.label}>
            {`Item Desc: `}
            <Text style={styles.content}>{convertUnicode(item.item_name)}</Text>
          </Text>
          <Text style={styles.label}>
            {`Reg. Callout:  `}
            <Text style={styles.content}>{item.reg_callout}</Text>
          </Text>
          <Text style={styles.label}>
            {`Reg: `}
            <Text style={styles.content}>{item.reg_price}</Text>
          </Text>
          {offer.mixed_discount ? (
            <Text style={styles.label}>
              {`Promo $ (Mixed Discount): `}
              <Text style={styles.content}>
                {promoPrice(offer.price_patches, item)}
              </Text>
            </Text>
          ) : null}
          <Text style={styles.label}>
            {`Image Status: `}
            <Text style={styles.content}>{item.image_type}</Text>
          </Text>
          <Text style={styles.label}>
            {`Merch Product Notes: `}
            <Text style={styles.content}>{item.merch_notes}</Text>
          </Text>
        </View>
      </View>
    </View>
  )

  const Offer = ({ divisions = {}, offer, index }) => (
    <Fragment key={offer.id + '-' + index}>
      <View wrap={true} break={index > 0} style={styles.offerUnit}>
        <View style={styles.underlineLabel}>
          <Text style={styles.label}>
            {offer.ivy_offer_id === -1 ? 'Placeholder ' : ''}
            {`Offer ${index > -1 ? offer.offer_order : ''}: `}{' '}
          </Text>
          {offer.is_key ? (
            <Svg
              viewBox="0 0 512 512"
              style={{ marginBottom: 5, marginRight: 5 }}
              height={15}
              width={15}
            >
              <Path
                fill="darkorange"
                d="M282.3 343.7L248.1 376.1C244.5 381.5 238.4 384 232 384H192V424C192 437.3 181.3 448 168 448H128V488C128 501.3 117.3 512 104 512H24C10.75 512 0 501.3 0 488V408C0 401.6 2.529 395.5 7.029 391L168.3 229.7C162.9 212.8 160 194.7 160 176C160 78.8 238.8 0 336 0C433.2 0 512 78.8 512 176C512 273.2 433.2 352 336 352C317.3 352 299.2 349.1 282.3 343.7zM376 176C398.1 176 416 158.1 416 136C416 113.9 398.1 96 376 96C353.9 96 336 113.9 336 136C336 158.1 353.9 176 376 176z"
              />
            </Svg>
          ) : null}
          <Text>
            <Text
              style={(styles.content, { marginBottom: 5 })}
            >{`${offer.name}`}</Text>
          </Text>
        </View>
        <Text style={styles.label}>
          {`Ivy Offer Id:  `}
          <Text style={styles.content}>{offer.ivy_offer_id}</Text>
        </Text>
        <Text style={styles.label}>
          {`Division:  `}
          <Text style={styles.content}>
            {getDivisionName(divisions, offer.division)}
          </Text>
        </Text>
        <Text style={styles.label}>
          {`Offer Details:  `}
          <Text style={styles.content}>
            {makeOfferDetails(offer.circle_offer, offer.mass_offer)}
          </Text>
        </Text>
        {offer.ivy_offer_id !== -1 ? (
          <>
            <Text style={styles.label}>
              {`Promo $:  `}
              <Text style={styles.contentPromo}>{offer.promo_content}</Text>
            </Text>

            <Text style={styles.label}>
              {`Sale Callout:  `}
              <Text style={styles.content}>{offer.sale_handle}</Text>
            </Text>

            <Text style={styles.label}>
              {`Mktg Channels:  `}

              {offer.channels?.map((chan, i) => (
                <Text
                  key={i + '-' + chan.channel_name}
                  style={styles.content}
                  index={i}
                >
                  {chan.channel_name}
                  {'; '}
                </Text>
              ))}
            </Text>

            <Text style={styles.label}>
              {`Copy Direction: `}
              <Text style={styles.content}>{offer.copy}</Text>
            </Text>

            <Text style={styles.label}>
              {`Merch Notes: `}
              <Text style={styles.content}>{offer.merch_notes}</Text>
            </Text>

            <Text style={styles.label}>
              {`Markets:  `}
              <Text style={styles.content}>{offer.markets?.join('; ')}</Text>
            </Text>

            {offer?.price_patches?.length > 0 && (
              <>
                <Text style={styles.label}>{`Price Patching: `}</Text>
                {offer.price_patches?.map((patch = {}, index) => (
                  <Fragment key={'patch-' + index}>
                    {patch.price_markets?.length > 0 ? (
                      <Text style={styles.label}>
                        {`Price: `}{' '}
                        <Text style={styles.content}>{patch.price_patch}</Text>
                        <Text style={styles.label}>
                          {`   Markets: `}{' '}
                          <Text style={styles.content}>
                            {patch.price_markets.join('; ')}
                          </Text>
                        </Text>
                      </Text>
                    ) : null}
                  </Fragment>
                ))}
              </>
            )}
          </>
        ) : null}
        <View wrap={true} style={styles.itemsContainer}>
          {offer.items.length > 0 ? (
            <>
              {offer.items?.map((item, i) => (
                <Item key={'item-' + i} item={item} index={i} offer={offer} />
              ))}
            </>
          ) : (
            <>
              {offer.ivy_offer_id !== -1 ? (
                <Text style={styles.label}>No Featured Items Found.</Text>
              ) : null}
            </>
          )}
        </View>
      </View>
    </Fragment>
  )

  const Umbrella = ({ divisions = {}, umb, index, length }) => (
    <Fragment key={umb.id + '-' + index}>
      <View style={styles.umbrellaUnit} index={index}>
        <View style={styles.labelContent}>
          <Text style={styles.label}>{`Umbrella ${umb.umbrella_order}: `}</Text>
          {umb.key ? (
            <Svg
              viewBox="0 0 512 512"
              style={{ marginBottom: 5, marginRight: 5 }}
              height={12}
              width={12}
            >
              <Path
                fill="darkorange"
                d="M282.3 343.7L248.1 376.1C244.5 381.5 238.4 384 232 384H192V424C192 437.3 181.3 448 168 448H128V488C128 501.3 117.3 512 104 512H24C10.75 512 0 501.3 0 488V408C0 401.6 2.529 395.5 7.029 391L168.3 229.7C162.9 212.8 160 194.7 160 176C160 78.8 238.8 0 336 0C433.2 0 512 78.8 512 176C512 273.2 433.2 352 336 352C317.3 352 299.2 349.1 282.3 343.7zM376 176C398.1 176 416 158.1 416 136C416 113.9 398.1 96 376 96C353.9 96 336 113.9 336 136C336 158.1 353.9 176 376 176z"
              />
            </Svg>
          ) : null}
          <Text
            style={(styles.content, { marginBottom: 5 })}
          >{`${umb.name}`}</Text>{' '}
        </View>
        <Text style={styles.label}>
          {`Pyramid:  `}
          <Text style={styles.content}>
            {umb.merchant_category?.group_name}{' '}
          </Text>
        </Text>
        <Text style={styles.label}>
          {`Mktg Channels:  `}
          {umb.channels?.map((chan, i) => (
            <Text
              key={i + '-' + chan.channel_name}
              style={styles.content}
              index={i}
            >
              {chan.channel_name}
              {'; '}
            </Text>
          ))}
        </Text>
        <Text style={styles.label}>
          {`Promo Message:  `}{' '}
          <Text style={styles.content}>{umb.promo_message}</Text>
        </Text>
      </View>
      {umb?.offers.length > 0 ? (
        <>
          {umb?.offers?.map((offer, i) => (
            <>
              <Offer
                key={'offer-' + i}
                offer={offer}
                index={i}
                divisions={divisions}
              />
              {umb.artPatchedOffers[offer.id]?.length > 0 ? (
                <>
                  {umb.artPatchedOffers[offer.id]?.map(
                    (artPatchOffer = {}, artPatchIndex) => (
                      <Offer
                        key={'offer-' + i + artPatchIndex}
                        offer={artPatchOffer}
                        index={-1}
                        divisions={divisions}
                      />
                    )
                  )}
                </>
              ) : null}
            </>
          ))}

          {/* this will break to a new page after each umbrella (except the last one) */}
          {index < length - 1 && <View break></View>}
        </>
      ) : (
        <Text style={styles.label}>No Offers Found.</Text>
      )}
    </Fragment>
  )

  return (
    <Document>
      <Page size="A2" orientation="landscape" style={styles.page}>
        <Header />
        {summaryData?.story?.umbrellas.length > 0 ? (
          <>
            {summaryData?.story?.umbrellas?.map((umb, i) => (
              <Umbrella
                key={'umbrella-' + i}
                umb={umb}
                index={i}
                length={summaryData?.story?.umbrellas?.length}
                divisions={divisions}
              />
            ))}
          </>
        ) : (
          <Text style={styles.itemsContainer}>No Umbrellas Found.</Text>
        )}
      </Page>
    </Document>
  )
}

export default CreativeSummaryPDF
