import {
  Modal,
  Grid,
  Form,
  Heading,
  Input,
  Tooltip,
  Spinner,
  Dialog,
  GridContainer,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import {
  faExclamationTriangle,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import ProductApi from '../api/pop-catalog/ProductApi'
import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { CATEGORY_LIST } from '../util/CategoryColorMap'
import {
  inVendorView,
  inCreativePageView,
  inProductListView,
} from '../util/CheckLocation'
import '../scss/modal_size.scss'
import {
  convertImagetoBase64,
  errorCheckImage,
} from '../util/ConvertProductImage'
import { finalCopyString } from '../util/finalCopyUtil'
import { formatToServer } from '../util/MomentUtil'

import {
  formatDPCI,
  parseSetplaceholdDpci,
  handlePostProductError,
} from './util/ProductUtil'

const dpciRegex = /^\d{3}-\d{2}-\d{4}$/

const AddProduct = (props) => {
  const {
    addProduct = {},
    setAddProduct,
    isVendor,
    isAdminOrMerch = false,
    view = '',
    setProducts,
    approvedVendors = [],
  } = props
  const productApi = new ProductApi()
  const {
    catalogId = null,
    vendorName = '',
    show = false,
    vendorContactName = '',
    vendorContactEmail = '',
  } = addProduct

  const contactEmail = addProduct?.vendor_contact_email || ''
  const editVendor = {
    id: addProduct?.vendor_name || '',
    label: addProduct?.vendor_name || '',
    value: addProduct?.vendor_name || '',
    vendorEmails: [
      {
        id: contactEmail,
        label: contactEmail,
        value: contactEmail,
      },
    ],
  }
  useEffect(() => {
    if (editVendor.value !== 'Target Enterprise Inc') {
      setApprovedVendor(editVendor)
      setSingleVendorEmail(editVendor.vendorEmails[0])
    }
    // eslint-disable-next-line
  }, [addProduct.vendor_name])

  let vendorSchema = yup.object().shape({
    vendor_contact_email: yup.object().required('Required'),
    vendor_name: yup.object().required('Required'),
  })

  var addProductSchema = yup.object().shape({
    buyer: yup.string().required('Required'),
    category: yup.object().required('Required').nullable(),
    dpci: yup.string().required('Required').matches(dpciRegex, {
      message: 'DPCI in format 000-00-0000',
    }),
    name: yup.string().required('Required'),
    reference_image: yup.mixed().required('Required'),
    reg_unit_retail: yup
      .string()
      .required('Required')
      .matches(/(^\$(\d*)$)|(\d*(\.\d{2})$)/, {
        message: 'Reg unit retail format is either $99 or $99.99',
      }),
  })

  if (!isVendor) {
    addProductSchema = addProductSchema.concat(vendorSchema)
  }

  const [queryingCorona, setQueryingCorona] = useState(false)
  const [placeholdDpci, setPlaceholdDpci] = useState()
  const [dialogVisible, setDialogVisible] = useState(false)
  const [dialogContent, setDialogContent] = useState()
  const [approvedVendor, setApprovedVendor] = useState()
  const [approvedVendorEmails, setApprovedVendorEmails] = useState([])
  const [singleVendorEmail, setSingleVendorEmail] = useState()
  const [copyIncludePrice, setCopyIncludePrice] = useState(false)

  var imageErrors = []
  const catalog_Id = useParams()?.catalogId
  show &&
    placeholdDpci === undefined &&
    ((catalog_Id && parseSetplaceholdDpci(catalog_Id, setPlaceholdDpci)) ||
      (catalogId && parseSetplaceholdDpci(catalogId, setPlaceholdDpci)))

  const wipeState = () => {
    setPlaceholdDpci()
    setAddProduct({ body: {}, show: false })
  }
  return catalogId ? (
    <Formik
      enableReinitialize={true}
      validationSchema={addProductSchema}
      initialValues={{
        add_assorted: false,
        add_available_instore: false,
        add_available_online: false,
        add_list_reg_retail: false,
        add_mapped_item: false,
        add_target_exclusive: false,
        add_vendor_funded: false,
        additional_product_info: '',
        age_range: '',
        art_director_notes: '',
        assembly_required: '',
        brand: '',
        buyer: '',
        catalogId: catalogId,
        category: '',
        coupon_retail: '',
        deleted: false,
        depth: '',
        dpci: placeholdDpci ? placeholdDpci : '000-00-0000',
        features: '',
        fig_model: false,
        final_copy: '',
        final_product_name: '',
        gender_age_preference: '',
        height: '',
        imageErrors: null,
        ivy_offer_id: '',
        map_floor: '',
        model_needs: '',
        name: '',
        nullImageError: false,
        page_type: '',
        photo_notes: '',
        promo_retail: '',
        prop_needs: '',
        reference_image: '',
        reg_unit_retail: '',
        sample_id: '',
        sample_status: '',
        sample_submitted: false,
        sample_turn_in_date: '',
        set_date: '',
        sizeImageError: false,
        size_range: '',
        status: isAdminOrMerch ? 'accepted' : 'pending',
        tcin: '',
        tcin_type: '',
        units: '',
        validated_dpci: false,
        vendor_comments: '',
        vendor_contact_email: editVendor.vendorEmails[0] || '',
        vendor_name: vendorName !== 'Target Enterprise Inc' ? editVendor : '',
        width: '',
      }}
      onSubmit={(values, funcs) => {
        const {
          additional_product_info = '',
          age_range = '',
          art_director_notes = '',
          assembly_required = '',
          assorted = false,
          add_available_instore = false,
          add_available_online = false,
          brand = '',
          buyer = '',
          category = '',
          coupon_retail = '',
          dpci = '',
          features = '',
          fig_model = false,
          final_product_name = '',
          gender_age_preference = '',
          ivy_offer_id = '',
          list_reg_retail = false,
          map_floor = '',
          add_mapped_item = false,
          model_needs = '',
          name = '',
          page_type = '',
          photo_notes = '',
          promo_retail = '',
          prop_needs = '',
          reference_image = '',
          reg_unit_retail = '',
          sample_id = '',
          sample_status,
          sample_submitted = false,
          sample_turn_in_date = '',
          set_date = '',
          size_range = '',
          status = '',
          add_target_exclusive = false,
          tcin = '',
          tcin_type = '',
          validated_dpci = false,
          vendor_comments = '',
          add_vendor_funded = false,
          depth = '',
          width = '',
          height = '',
          units = '',
        } = values

        if (isEmpty(imageErrors)) {
          productApi
            .postProduct({
              additional_product_info,
              age_range,
              art_director_notes,
              assembly_required,
              assorted,
              available_instore: add_available_instore,
              available_online: add_available_online,
              brand,
              buyer,
              catalog_id: catalogId,
              category: (category && category.value) || '',
              coupon_retail,
              depth,
              dpci,
              features,
              fig_model,
              final_copy: finalCopyString(values, copyIncludePrice, 'add_'),
              final_product_name,
              gender_age_preference,
              height,
              ivy_offer_id,
              list_reg_retail,
              map_floor,
              mapped_item: add_mapped_item,
              model_needs,
              name: name,
              page_type,
              photo_notes,
              promo_retail,
              prop_needs: prop_needs,
              reference_image: reference_image,
              reg_unit_retail: reg_unit_retail,
              sample_id: sample_id,
              sample_status: sample_status,
              sample_submitted: sample_submitted,
              sample_turn_in_date: formatToServer(sample_turn_in_date),
              set_date: formatToServer(set_date),
              size_range,
              status,
              target_exclusive: add_target_exclusive,
              tcin,
              tcin_type,
              units,
              validated_dpci,
              vendor_comments,
              vendor_contact_email:
                singleVendorEmail?.value || vendorContactEmail,
              vendor_contact_name: vendorContactName,
              vendor_funded: add_vendor_funded,
              vendor_name: approvedVendor?.value || vendorName,
              width,
            })
            .then((res) => {
              setProducts((oldProducts) => {
                oldProducts.unshift(res)
                return oldProducts
              })
              funcs.resetForm()
              wipeState()
            })
            .catch((e) => {
              handlePostProductError(e, setDialogContent, setDialogVisible)
            })
        }
      }}
    >
      {({
        values,
        handleSubmit,
        handleReset,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
      }) => {
        const {
          additional_product_info = '',
          age_range = false,
          art_director_notes = '',
          assembly_required = false,
          add_assorted = false,
          add_available_instore = false,
          add_available_online = false,
          brand = '',
          buyer = '',
          category = '',
          coupon_retail = '',
          dpci = '',
          gender_age_preference = '',
          ivy_offer_id = '',
          features = '',
          fig_model = '',
          final_product_name = '',
          add_list_reg_retail = false,
          map_floor = '',
          add_mapped_item = false,
          model_needs = '',
          name = '',
          nullImageError = false,
          page_type = '',
          photo_notes = '',
          prop_needs = '',
          promo_retail = '',
          reference_image = '',
          reg_unit_retail = '',
          sample_id = '',
          sample_status = '',
          add_sample_submitted = false,
          sample_turn_in_date = '',
          set_date = '',
          sizeImageError = false,
          size_range = '',
          status = '',
          add_target_exclusive = false,
          tcin = '',
          vendor_comments = '',
          validated_dpci = false,
          add_vendor_funded = false,
          depth = '',
          width = '',
          height = '',
          units = '',
        } = values
        const handleDialogClose = () => {
          handleReset()
          setAddProduct({ show: false })
          wipeState()
        }

        const handleRegUnitRetail = (evt) => {
          const newVal = evt.target.value
          const num = newVal.includes('$') ? newVal.split('$')[1] : newVal

          if (num.includes('.')) {
            setFieldValue('reg_unit_retail', num)
          } else {
            setFieldValue('reg_unit_retail', `$${num}`)
          }
        }
        function handleImage(event) {
          let { nullImageError, sizeImageError } = errorCheckImage(event)
          if (nullImageError) {
            imageErrors = ['Required']
          } else if (sizeImageError) {
            imageErrors = ['Image too large, please select another']
          } else {
            imageErrors = []
          }
          if (isEmpty(imageErrors)) {
            convertImagetoBase64(event, (result) => {
              setFieldValue('reference_image', result?.split('base64,')[1])
            })
          }
        }
        let dpciFormatter = () => {
          setFieldValue('dpci', formatDPCI(dpci))
          if (dpciRegex.test(dpci)) {
            productApi
              .getCoronaInfo(dpci)
              .then((items) => {
                setFieldValue('validated_dpci', !isEmpty(items))
                if (!isEmpty(items)) {
                  const {
                    brand = '',
                    size = '',
                    age_range = '',
                    available_instore = false,
                    available_online = false,
                    assembly_required = '',
                    target_exclusive = false,
                    tcin = '',
                    name = '',
                    assorted = '',
                    depth = '',
                    width = '',
                    height = '',
                    units = '',
                  } = items[0]
                  setFieldValue('brand', brand)
                  setFieldValue('size_range', size)
                  setFieldValue('add_available_instore', available_instore)
                  setFieldValue('add_available_online', available_online)
                  setFieldValue('assorted', assorted)
                  setFieldValue('assembly_required', assembly_required)
                  setFieldValue('add_target_exclusive', target_exclusive)
                  setFieldValue('tcin', tcin)
                  setFieldValue('name', name)
                  setFieldValue('age_range', age_range)
                  setFieldValue('depth', depth)
                  setFieldValue('width', width)
                  setFieldValue('height', height)
                  setFieldValue('units', units)

                  productApi
                    .getPriceInfo(tcin)
                    .catch(() => {
                      setFieldValue('reg_unit_retail', '')
                      setFieldValue('map_floor', '')
                      setFieldValue('mapped_item', false)
                    })
                    .then((result) => {
                      if (result?.data) {
                        const { reg_retail = '', map_floor = '' } =
                          result?.data || {}
                        setFieldValue('reg_unit_retail', reg_retail)
                        setFieldValue('map_floor', map_floor)
                        setFieldValue('mapped_item', true)
                      }
                    })
                }
              })
              .finally(() => {
                setQueryingCorona(false)
              })
          } else {
            setFieldValue('validated_dpci', false)
          }
        }

        return (
          catalogId && (
            <Modal
              isVisible={show}
              headingText={'Add Product'}
              onRefuse={handleDialogClose}
              className="larger_modal"
            >
              <div className="hc-pa-normal hc-pt-dense">
                <Grid.Container spacing="dense">
                  <Grid.Item
                    xs={12}
                    className="hc-ta-left hc-pa-dense"
                    style={{ background: '#757575' }}
                  >
                    <Heading size="5" style={{ color: '#fff' }}>
                      Product Information
                    </Heading>
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      required
                      id="dpci"
                      label="DPCI"
                      value={dpci}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.dpci && errors.dpci}
                      errorText={errors.dpci}
                      onKeyUp={dpciFormatter}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} className="hc-ta-left hc-pv-xl">
                    {' '}
                    {queryingCorona ? (
                      <Spinner size="inline" />
                    ) : validated_dpci ? (
                      <Tooltip
                        content="DPCI found in Digital Item"
                        location="top"
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          size="lg"
                          color="green"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        content="DPCI not found in Digital Item"
                        location="top"
                      >
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          size="lg"
                          color="red"
                        />
                      </Tooltip>
                    )}
                  </Grid.Item>

                  <Grid.Item md={3} xs={3}>
                    <Form.Field
                      required
                      id="name"
                      label="Product Name"
                      value={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && errors.name}
                      errorText={errors.name}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1}></Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="brand"
                      label="Product Brand"
                      value={brand}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.brand && errors.brand}
                      errorText={errors.brand}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="tcin"
                      label="TCIN"
                      value={tcin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item md={3} xs={3}>
                    <Autocomplete
                      required
                      id="category"
                      chipHeight="dense"
                      label="Category"
                      onUpdate={(_, value) => setFieldValue('category', value)}
                      options={CATEGORY_LIST}
                      error={errors.category && touched.category}
                      errorText={errors.category}
                      value={category}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item xs={3}>
                    {!inVendorView(view) && (
                      <Form.Field
                        id="page_type"
                        type="select"
                        label="Page Type"
                        value={page_type}
                        onUpdate={setFieldValue}
                        onBlur={handleBlur}
                        options={[
                          { label: 'Full', value: 'full' },
                          { label: 'Half', value: 'half' },
                          { label: 'Item', value: 'item' },
                        ]}
                      />
                    )}
                    {inVendorView(view) && (
                      <Form.Field
                        required
                        id="reg_unit_retail"
                        label="Reg Unit Retail"
                        value={reg_unit_retail}
                        onChange={handleRegUnitRetail}
                        onBlur={handleBlur}
                        error={
                          errors.reg_unit_retail && touched.reg_unit_retail
                        }
                        errorText={errors.reg_unit_retail}
                      />
                    )}
                  </Grid.Item>
                  <Grid.Item xs={1}> </Grid.Item>
                  {!inVendorView(view) && (
                    <Grid.Item xs={3}>
                      <Form.Field
                        required
                        id="reg_unit_retail"
                        label="Reg Unit Retail"
                        value={reg_unit_retail}
                        onChange={handleRegUnitRetail}
                        onBlur={handleBlur}
                        error={
                          errors.reg_unit_retail && touched.reg_unit_retail
                        }
                        errorText={errors.reg_unit_retail}
                      />
                    </Grid.Item>
                  )}
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item xs={3}>
                    {!inVendorView(view) && (
                      <Form.Field
                        id="promo_retail"
                        label="Promo Retail"
                        value={promo_retail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </Grid.Item>
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item xs={3}>
                    {!inVendorView(view) && (
                      <Form.Field
                        id="coupon_retail"
                        label="Coupon Retail"
                        value={coupon_retail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    {!inVendorView(view) && (
                      <Form.Field
                        id="add_list_reg_retail"
                        type="toggle"
                        value={add_list_reg_retail}
                        label="List Reg Retail?"
                        onChange={() =>
                          setFieldValue(
                            'add_list_reg_retail',
                            !add_list_reg_retail
                          )
                        }
                      />
                    )}
                  </Grid.Item>
                  <Grid.Item xs={1}> </Grid.Item>
                  {inProductListView(view) && (
                    <>
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="ivy_offer_id"
                          label="Ivy Offer ID"
                          value={ivy_offer_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1}> </Grid.Item>
                    </>
                  )}
                  {inCreativePageView(view) && (
                    <>
                      <Grid.Item xs={7}>
                        <Form.Field
                          id="additional_product_info"
                          label="Additional product info"
                          value={additional_product_info}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </>
                  )}
                  <Grid.Item xs={3}>
                    {inProductListView(view) && (
                      <DatePicker
                        error={errors.set_date && touched.set_date}
                        errorText="Required"
                        id="set_date"
                        label="Set Date"
                        value={set_date}
                        onUpdate={setFieldValue}
                        location="bottom-left-left"
                      />
                    )}
                  </Grid.Item>
                  <Grid.Item xs={1}> </Grid.Item>
                  {inProductListView(view) && <Grid.Item xs={8}> </Grid.Item>}
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item xs={12}> </Grid.Item>
                  {!inCreativePageView(view) && (
                    <>
                      <Grid.Item xs={3}>
                        <Input.Label
                          error={
                            errors.reference_image && touched.reference_image
                          }
                          required
                          style={{ fontSize: '12px' }}
                        >
                          Upload Reference Image
                        </Input.Label>
                        <input
                          id="reference_image"
                          label="Upload Reference Image"
                          type="file"
                          value={undefined} // https://stackoverflow.com/questions/66876022/setfieldvalue-formik-and-invalidstateerror-failed-to-set-the-value-property
                          onChange={handleImage}
                          onBlur={handleBlur}
                          errorText="Required"
                          error={
                            errors.reference_image && touched.reference_image
                          }
                          required
                        />
                        <Input.Label style={{ marginTop: '20px' }}>
                          Please choose a JPEG file under 1MB
                        </Input.Label>
                      </Grid.Item>
                      <Grid.Item xs={1}> </Grid.Item>
                      <Grid.Item xs={3}>
                        <Input.Label style={{ fontSize: '12px' }}>
                          Reference Image
                        </Input.Label>
                        {reference_image && (
                          <img
                            src={`data:image/png;base64,${reference_image}`}
                            width="150px"
                            alt="n/a"
                          />
                        )}
                        {!reference_image && <>No image uploaded</>}
                      </Grid.Item>
                      <Grid.Item xs={1}> </Grid.Item>
                    </>
                  )}
                  <Grid.Item xs={3}>
                    <Form.Field
                      id="features"
                      type="textarea"
                      label="Product Features"
                      value={features}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  {inProductListView(view) && (
                    <GridContainer justify="flex-end">
                      <Grid.Item xs={1}>
                        <Form.Field
                          id="depth"
                          label="Depth"
                          value={depth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1}>
                        <Form.Field
                          id="width"
                          label="Width"
                          value={width}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1}>
                        <Form.Field
                          id="height"
                          label="Height"
                          value={height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1}>
                        <Form.Field
                          id="units"
                          label="Unit Measure"
                          value={units}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                    </GridContainer>
                  )}
                  {inCreativePageView(view) && <Grid.Item xs={8}> </Grid.Item>}
                  <Grid.Item xs={1}> </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="add_assorted"
                      type="toggle"
                      value={add_assorted}
                      label="Assortment"
                      onChange={() =>
                        setFieldValue('add_assorted', !add_assorted)
                      }
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="assembly_required"
                      label="Assembly Required"
                      value={assembly_required}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="age_range"
                      label="Age Range"
                      value={age_range}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="size_range"
                      label="Size Range"
                      value={size_range}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="add_target_exclusive"
                      type="toggle"
                      value={add_target_exclusive}
                      label="Sold only at Target"
                      onChange={() =>
                        setFieldValue(
                          'add_target_exclusive',
                          !add_target_exclusive
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="add_available_instore"
                      type="toggle"
                      value={add_available_instore}
                      label="Sold Instore"
                      onChange={() =>
                        setFieldValue(
                          'add_available_instore',
                          !add_available_instore
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      required
                      id="buyer"
                      label="Buyer Name"
                      value={buyer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.buyer && errors.buyer}
                      errorText={errors.buyer}
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="map_floor"
                      label="Map Floor"
                      value={map_floor}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="add_mapped_item"
                      type="toggle"
                      value={add_mapped_item}
                      label="Mapped Item"
                      onChange={() =>
                        setFieldValue('add_mapped_item', !add_mapped_item)
                      }
                    />
                  </Grid.Item>
                  <Grid.Item md={3} xs={6}>
                    <Form.Field
                      id="add_available_online"
                      type="toggle"
                      value={add_available_online}
                      label="Sold Online"
                      onChange={() =>
                        setFieldValue(
                          'add_available_online',
                          !add_available_online
                        )
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    {inProductListView(view) && (
                      <>
                        <Autocomplete
                          required
                          id="vendor_name"
                          allowCustomInputValue
                          label="Approved Vendors"
                          onUpdate={(_, value) => {
                            setFieldValue('vendor_name', value)
                            setApprovedVendor(value)
                            setApprovedVendorEmails(value?.vendorEmails)
                            if (value?.vendorEmails?.length === 1) {
                              setSingleVendorEmail(value.vendorEmails[0])
                              setFieldValue(
                                'vendor_contact_email',
                                value.vendorEmails[0]
                              )
                            } else {
                              setSingleVendorEmail()
                            }
                          }}
                          options={approvedVendors}
                          value={approvedVendor}
                          error={errors.vendor_name && touched.vendor_name}
                          errorText="Please enter a vendor"
                        />
                        <Autocomplete
                          required
                          allowCustomInputValue
                          id="vendor_contact_email"
                          value={singleVendorEmail || vendorContactEmail}
                          label="Vendor Email"
                          options={approvedVendorEmails || []}
                          onUpdate={(_, value) => {
                            setFieldValue('vendor_contact_email', value)
                            setSingleVendorEmail(value)
                          }}
                          error={
                            errors.vendor_contact_email &&
                            touched.vendor_contact_email
                          }
                          errorText="Please enter a vendor email"
                        />
                        <Form.Field
                          id="add_vendor_funded"
                          type="toggle"
                          value={add_vendor_funded}
                          label="Vendor Funded"
                          onChange={() =>
                            setFieldValue(
                              'add_vendor_funded',
                              !add_vendor_funded
                            )
                          }
                        />
                      </>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={3}>
                    {inProductListView(view) && isAdminOrMerch && (
                      <Form.Field
                        id="status"
                        type="select"
                        label="Status"
                        value={status}
                        onUpdate={setFieldValue}
                        onBlur={handleBlur}
                        options={[
                          { label: 'Approved', value: 'accepted' },
                          { label: 'Pending', value: 'pending' },
                          { label: 'Rejected', value: 'denied' },
                        ]}
                        defaultValue={status}
                      />
                    )}
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      id="vendor_comments"
                      label="Vendor Comments"
                      value={vendor_comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Item>
                  {!inCreativePageView(view) && (
                    <>
                      {' '}
                      <Grid.Item
                        xs={12}
                        className="hc-ta-left hc-pa-dense"
                        style={{ background: '#757575' }}
                      >
                        <Heading size="5" style={{ color: '#fff' }}>
                          Sample Information
                        </Heading>
                      </Grid.Item>
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="sample_status"
                          type="select"
                          label="Sample Status"
                          value={sample_status}
                          onUpdate={setFieldValue}
                          onBlur={handleBlur}
                          options={[
                            { label: 'Inventory', value: 'inventory' },
                            { label: 'Turn In', value: 'turn-in' },
                            { label: 'Missing', value: 'missing' },
                          ]}
                        />
                      </Grid.Item>
                      {inProductListView(view) && (
                        <Grid.Item xs={3}>
                          <Form.Field
                            id="sample_id"
                            label="Sample ID"
                            value={sample_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid.Item>
                      )}
                      <Grid.Item xs={3}>
                        <DatePicker
                          error={
                            errors.sample_turn_in_date &&
                            touched.sample_turn_in_date
                          }
                          errorText="Required"
                          id="sample_turn_in_date"
                          label=" Sample Turn In Date"
                          value={sample_turn_in_date}
                          onUpdate={setFieldValue}
                          location="bottom-left-left"
                        />
                      </Grid.Item>
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="add_sample_submitted"
                          type="toggle"
                          value={add_sample_submitted}
                          label="Sample Submitted"
                          onChange={() =>
                            setFieldValue(
                              'sample_submitted',
                              !add_sample_submitted
                            )
                          }
                        />
                      </Grid.Item>
                    </>
                  )}
                  {inCreativePageView(view) && (
                    <>
                      {' '}
                      <Grid.Item
                        xs={12}
                        className="hc-ta-left hc-pa-dense"
                        style={{ background: '#757575' }}
                      >
                        <Heading size="5" style={{ color: '#fff' }}>
                          Art Direction Information
                        </Heading>
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <Form.Field
                          id="art_director_notes"
                          label="Art Director Notes"
                          value={art_director_notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <Form.Field
                          id="gender_age_preference"
                          label="Gender/Age Preference"
                          value={gender_age_preference}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <Form.Field
                          id="photo_notes"
                          label="Photo Notes"
                          value={photo_notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <Form.Field
                          id="prop_needs"
                          label="Prop Needs"
                          value={prop_needs}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <Form.Field
                          id="model_needs"
                          label="Model Needs"
                          value={model_needs}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>
                      <Grid.Item xs={3}>
                        <Form.Field
                          id="fig_model"
                          type="toggle"
                          value={fig_model}
                          label="Fig/Model"
                          onChange={() =>
                            setFieldValue('fig_model', !fig_model)
                          }
                        />
                      </Grid.Item>
                    </>
                  )}

                  {!inVendorView(view) && (
                    <>
                      <Grid.Container
                        xs={12}
                        justify="space-between"
                        className="copyWriter-Header"
                        style={{ background: '#757575' }}
                      >
                        <Grid.Item>
                          <Heading size="5" style={{ color: '#fff' }}>
                            Copy Writer Information
                          </Heading>
                        </Grid.Item>
                        <Grid.Item>
                          <Form.Field
                            id="price_in_copy"
                            type="toggle"
                            value={copyIncludePrice}
                            label="Include Price in Copy"
                            onChange={() =>
                              setCopyIncludePrice(!copyIncludePrice)
                            }
                          />
                        </Grid.Item>
                      </Grid.Container>
                      <Grid.Item xs={6}>
                        <Form.Field
                          id="additional_product_info"
                          label="Additional Product Info"
                          value={additional_product_info}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid.Item>

                      <Grid.Item xs={6}>
                        <Form.Field
                          id="final_copy"
                          label="Final Copy"
                          value={finalCopyString(
                            values,
                            copyIncludePrice,
                            'add_'
                          )}
                          onBlur={handleBlur}
                          disabled
                        />
                        <Form.Field
                          id="final_product_name"
                          label="Final Product Name"
                          value={final_product_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={inProductListView(view)}
                        />
                      </Grid.Item>
                    </>
                  )}
                </Grid.Container>
                {(nullImageError || sizeImageError) && (
                  <div style={{ color: '#b85300', float: 'right' }}>
                    <FontAwesomeIcon
                      color="#b85300"
                      icon={faExclamationCircle}
                      style={{ marginRight: '5px' }}
                    />
                    Errors somewhere on page. Please double check input.
                  </div>
                )}
                <CancelSubmitButtons
                  handleDialogClose={handleDialogClose}
                  handleSubmit={handleSubmit}
                  canDelete={false}
                />
              </div>
              <Dialog
                isVisible={dialogVisible}
                headingText={dialogContent}
                onRefuse={() => setDialogVisible(false)}
                refuseButtonText="Close"
              />
            </Modal>
          )
        )
      }}
    </Formik>
  ) : null
}
export default AddProduct
